import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { propType } from 'graphql-anywhere';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import { shape, string } from 'prop-types';

function MilestoneDescriptionAndDeliverables({ milestone, classes }) {
  const { t } = useTranslation();
  const { deliverables, description } = milestone;

  function renderDeliverables() {
    return (
      <div>
        <div className="fw-bold td-u">
          {t('contract.milestone.deliverables')}
        </div>
        <div>
          {deliverables.map(
            ({
              id,
              title: deliverableTitle,
              description: deliverableDescription,
            }) => (
              <div key={id} className={classes.deliverable}>
                <p>
                  <span className="fw-bold">{deliverableTitle}</span>
                  <br />
                  {deliverableDescription}
                </p>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  function renderDescription() {
    return (
      <>
        <div className="fw-bold">{t('contract.milestone.description')}</div>
        <div>{description}</div>
        <br />
      </>
    );
  }

  return (
    <div>
      {description && renderDescription()}
      {deliverables.length !== 0 && renderDeliverables()}
    </div>
  );
}

MilestoneDescriptionAndDeliverables.propTypes = {
  classes: shape({
    deliverable: string,
  }),
  milestone: propType(contractMilestoneFragment).isRequired,
};

MilestoneDescriptionAndDeliverables.defaultProps = {
  classes: { deliverable: null },
};

export default MilestoneDescriptionAndDeliverables;
