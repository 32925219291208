import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { createRecommendation } from '@/api/Company/Recommendation/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import RecommendModal from './RecommendModal';

export default compose(
  graphql(createRecommendation, { name: 'createRecommendation' }),
  reduxForm({ form: 'company_recommend' })
)(RecommendModal);
