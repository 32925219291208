import Entity from './Entity';
import {
  ARCHIVED,
  ACCEPTED,
  DRAFT,
  REQUEST_FOR_UPDATE,
  REVIEW_ADMIN,
  REVIEW_CLIENT,
} from '@/constants/proposalStatuses';

class Proposal extends Entity {
  static get modelName() {
    return 'Proposal';
  }

  get kind() {
    return this.get('quotation.kind');
  }

  hasContract() {
    return !!this.get('candidacy.contract');
  }

  isArchived() {
    return this.get('status') === ARCHIVED;
  }

  isDraft() {
    return this.get('status') === DRAFT;
  }

  isAccepted() {
    return this.get('status') === ACCEPTED;
  }

  isRequestForUpdate() {
    return this.get('status') === REQUEST_FOR_UPDATE;
  }

  isReviewAdmin() {
    return this.get('status') === REVIEW_ADMIN;
  }

  isReviewClient() {
    return this.get('status') === REVIEW_CLIENT;
  }
}

export default Proposal;
