import React from 'react';
import { func } from 'prop-types';
import {
  LANDING_CANDIDAT_FAQ,
  LANDING_COMPANY_FAQ,
  LANDING_CONTACT,
  LANDING_PARTNERSHIPS,
  LANDING_RECRUITMENT,
  HELP,
} from '@/constants/router';
import Section from '../Section';
import List from '../List';

const links = [
  {
    id: 'cgvu',
    label: 'footer.about.link.cgvu',
    href: HELP,
  },
  {
    id: 'company_faq',
    label: 'footer.about.link.company_faq',
    href: LANDING_COMPANY_FAQ,
    target: '_blank',
  },
  {
    id: 'candidat_faq',
    label: 'footer.about.link.candidat_faq',
    href: LANDING_CANDIDAT_FAQ,
    target: '_blank',
  },
  {
    id: 'contact',
    label: 'footer.about.link.contact',
    href: LANDING_CONTACT,
    target: '_blank',
  },
  {
    id: 'partnership',
    label: 'footer.about.link.partnership',
    href: LANDING_PARTNERSHIPS,
    target: '_blank',
  },
  {
    id: 'recruitment',
    label: 'footer.about.link.recruitment',
    href: LANDING_RECRUITMENT,
    target: '_blank',
  },
];

function About({ t }) {
  return (
    <Section name="About" title={t('footer.about.title')}>
      <List items={links} t={t} />
    </Section>
  );
}

About.propTypes = {
  t: func.isRequired,
};

export default About;
