import { graphql } from 'react-apollo';
import { uploadAttachment } from '@/api/Attachment/mutations';
import { get } from 'lodash';
import FileInput from './FileInput';

export default graphql(uploadAttachment, {
  options: (props) => ({
    variables: {
      offline: get(props, 'offline'),
    },
  }),
})(FileInput);
