import { withStateMachine } from 'react-automata';
import Img from './Img';

export const statechart = {
  initial: 'idle',
  states: {
    idle: {
      onEntry: 'beforeLoad',
      on: {
        NO_FILE: 'empty',
        FETCH: 'loading',
      },
    },
    empty: {
      on: {
        REFRESH: 'idle',
      },
    },
    loading: {
      onEntry: 'loadImage',
      on: {
        SUCCESS: 'fetched',
        FAILURE: 'error',
      },
    },
    fetched: {
      on: {
        REFRESH: 'idle',
      },
    },
    error: {
      on: {
        REFRESH: 'idle',
      },
    },
  },
};

export default withStateMachine(statechart)(Img);
