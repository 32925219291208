import Cookies from 'js-cookie';

export const COOKIE_TOKEN_NAME = 'T_SID';
export const COOKIE_SCOPES_NAME = 'S_SID';

export const getToken = () => Cookies.get(COOKIE_TOKEN_NAME);
export const getScopes = () => Cookies.get(COOKIE_SCOPES_NAME);

const setCookie = (name, value) => {
  Cookies.set(name, value);
};

export const setToken = (token) => {
  setCookie(COOKIE_TOKEN_NAME, token);
};
export const setScopes = (scopes) => setCookie(COOKIE_SCOPES_NAME, scopes);

export const handleCookie = (token, scopes) => {
  setToken(token);
  setScopes(scopes);
};

export const deleteCookies = () => {
  Cookies.remove(COOKIE_SCOPES_NAME);
  Cookies.remove(COOKIE_TOKEN_NAME);
};

export const isTokenExpired = (token = getToken()) => !token;

export const isAdmin = (scopes = getScopes()) => {
  if (!scopes) return false;
  return scopes.includes('admin');
};
