import React from 'react';
import { UPDATE } from '@/constants/modes';
import { get } from 'lodash';
import { hasRating } from './helpers/handler';
import { SKILL } from '@/constants/tagKind';
import config from '@/_config';

const TagsContext = React.createContext({
  mode: UPDATE,
  withRating: hasRating(),
  ratingWithNumberField: false,
  inlineDisplay: false,
  withCreatable: true, // use withCreatable HOC or not for TagsSuggestionsInput export
  kind: SKILL, // default tag kind (could be individual_certification, skill, certification...)
  max: get(config, 'validation.expertProfile.minSkillsLength'), // default is skill, feel free to create config for your own type of tag
  highlightMaxValues: get(config, 'skills.highlightMax'), // default is skill, feel free to create config for your own type of tag
  tagColor: null, // if tagColor is set, it will override highlight behaviour
});

export default TagsContext;
