/**
 * Modes to handle missions
 *
 * @type {{CREATE: string, EDIT: string, VIEW: string}}
 */
const MODES = {
  CREATE: 'create',
  EDIT: 'edit',
  VIEW: 'view',
};

export { MODES };
