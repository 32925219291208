import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import { updateSkills } from '@/api/Company/mutations';
import SkillsModal from './SkillsModal';

export default compose(
  graphql(updateSkills, { name: 'update' }),
  translate()
)(SkillsModal);
