import React, { useContext } from 'react';
import { func, objectOf, any } from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import useTranslation from '@/hooks/useTranslation';
import BoardSettings from '@/scenes/MyMissionBoard/components/BoardHeading/BoardSettings';
import { getMyMissionsRoute } from '@/helpers/router';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import config from '@/_config';

function InformationModal({ mission, onArchiveMission, closeModal }) {
  const { t } = useTranslation();
  const status = get(mission, 'status');
  const userContext = useContext(UserContext);
  const user = new User(userContext);

  return (
    <Modal
      name="myMission_board_information"
      title={t(`myMissionBoard.info.${status}.title`)}
      hideActions
      closable={false}
      topActions={
        <BoardSettings {...mission} onArchiveMission={onArchiveMission} />
      }
      buttonClassName="MyMissionBoard__heading-action"
    >
      <p className="m-b-m">{t(`myMissionBoard.custom_info.${status}`)}</p>
      <div className="d-f jc-c w-100">
        <a
          href={get(
            process.env,
            `REACT_APP_PAYMENT_LINK_${user
              .get('kind')
              .toUpperCase()}_${config.name.toUpperCase()}`
          )}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button className="m-b-m">
            {t('myMissionBoard.info.landing_link')}
          </Button>
        </a>
      </div>
      <p className="m-t-none">{t(`myMissionBoard.info2.${status}`)}</p>
      <div className="d-f jc-fe w-100">
        <Link
          onClick={() => closeModal('myMission_board_information')}
          to={getMyMissionsRoute()}
        >
          <Button variants={['outline']}>{t('myMissionBoard.info.cta')}</Button>
        </Link>
      </div>
    </Modal>
  );
}

InformationModal.propTypes = {
  mission: objectOf(any).isRequired,
  onArchiveMission: func.isRequired,
  closeModal: func.isRequired,
};

export default InformationModal;
