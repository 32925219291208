import { compose, mapProps } from 'recompose';
import { graphql } from 'react-apollo';
import { getStoredLocale } from '@/api/User/queries';
import { get } from 'lodash';

export default compose(
  graphql(getStoredLocale, { options: { fetchPolicy: 'cache-first' } }),
  mapProps(({ data: { currentUser }, ...props }) => ({
    ...props,
    locale: get(currentUser, 'language_alpha2'),
  }))
);
