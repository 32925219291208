import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Choice from '../index';
import './styles.scss';

const Toggle = ({ onText, offText, ...props }) => (
  <Choice {...props}>
    {({ checked, disabled, toggle }) => (
      <button
        type="button"
        onClick={toggle}
        className={cx('Toggle', { 'Toggle--checked': checked })}
        disabled={disabled}
      >
        <div className="Toggle__track">
          <div className="Toggle__side Toggle__side--on">{onText}</div>
          <div className="Toggle__side Toggle__side--off">{offText}</div>
        </div>
        <div className="Toggle__thumb" />
      </button>
    )}
  </Choice>
);

Toggle.propTypes = {
  defaultChecked: PropTypes.bool,
  onText: PropTypes.string,
  offText: PropTypes.string,
};

Toggle.defaultProps = {
  defaultChecked: false,
  onText: '',
  offText: '',
};

export default Toggle;
