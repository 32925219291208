import React from 'react';
import { func } from 'prop-types';
import { RequiredField, RfHelper, RfNumber } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import displaySection from '@/helpers/handleExperienceSection';
import config from '@/_config';

const ExperienceSection = ({ t }) => (
  <RfHelper values={['contract_types', 'kind']}>
    {({ contract_types, kind }) => {
      if (displaySection(contract_types, kind)) return null;
      return (
        <FormSection title={t('company.modal.modalities.experience.title')}>
          <RequiredField
            inline
            component={RfNumber}
            name="exp_time"
            className="Modalities__experience"
            max={config.company.experienceTime.max}
            min={config.company.experienceTime.min}
            step={1}
            label={t('company.modal.modalities.experience.label')}
          />
        </FormSection>
      );
    }}
  </RfHelper>
);

ExperienceSection.propTypes = {
  t: func.isRequired,
};

export default ExperienceSection;
