import React from 'react';
import { get } from 'lodash';
import cx from 'classnames';
import Choice from '../index';
import './styles.scss';

const CheckboxEntry = (props) => (
  <Choice {...props} type="checkbox">
    {({ checked, disabled, focused, toggle }) => {
      const classes = cx('Checkbox', {
        'Checkbox--checked': checked,
        'Checkbox--focus': focused,
        'Checkbox--disabled': disabled,
        'Checkbox--indeterminate': get(props, 'indeterminate'),
      });
      return (
        <div className={classes} onClick={toggle} role="button" tabIndex="-1" />
      );
    }}
  </Choice>
);

export default CheckboxEntry;
