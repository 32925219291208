import React, { useCallback, useEffect, useRef, useState } from 'react';
import { arrayOf, objectOf, object, any, string, bool, func } from 'prop-types';
import { noop } from 'lodash';
import Dropdown from '@/components/Dropdown';
import withAsync from '@/components/Dropdown/withAsync';
import withCreatable from '@/components/Dropdown/withCreatable';
import TextInput from '@/components/Fields/TextField/TextInput';
import { lowerWithoutAccent } from '@/helpers/string';
import withLimitedList from '../../Dropdown/withLimitedList';
import { isLimitedlistActived } from '@/helpers/limitedList';
import './styles.scss';

const SuggestionsInput = (props) => {
  const inputRef = useRef();
  const AsyncCreatableDropdown = withAsync(withCreatable(Dropdown));
  const AsyncDropdown = withAsync(Dropdown);
  const limitedListDropdown = withLimitedList(Dropdown);
  const [selectItemsLength, setSelectItemsLength] = useState(
    props.selectedItems.length
  );
  const handleHocDropdown = () => {
    const { withCreatable: withCreatableProps } = props;

    if (isLimitedlistActived(props.name)) {
      return limitedListDropdown;
    }
    return withCreatableProps ? AsyncCreatableDropdown : AsyncDropdown;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWrapperFocus = useCallback(() => {
    setTimeout(() => {
      inputRef.current.focus();
    }, 1);
  });

  useEffect(
    () => {
      if (selectItemsLength !== props.selectedItems.length) {
        handleWrapperFocus();
        setSelectItemsLength(props.selectedItems.length);
      }
    },
    [handleWrapperFocus, props.selectedItems.length, selectItemsLength]
  );

  const handleSelect = (value, utils) => {
    utils.reset();
    if (props.onSelect) props.onSelect(value, utils);
  };

  const filterOptions = (option) =>
    !props.selectedItems.find(
      (i) =>
        i.id === option.id ||
        lowerWithoutAccent(i.name) === lowerWithoutAccent(option.name)
    );

  const { placeholder, value, inputProps } = props;

  const DropdownComponent = handleHocDropdown();

  // Function to compare input value and options to know if
  // Input value should be or not added to options
  const inputValueComparator = (val1, val2) =>
    lowerWithoutAccent(val1.name) === lowerWithoutAccent(val2.name);
  return (
    <DropdownComponent
      {...props}
      defaultSelectedItems={value}
      filterOptions={filterOptions}
      multi
      onSelect={handleSelect}
      className="SuggestionsDropdown"
      inputValueComparator={inputValueComparator}
    >
      {({ getInputProps, selectedItems, inputValue }) => {
        const {
          invalidCharacters,
          disableCopyPaste: shouldDisableCopyPaste,
          ...textInputProps
        } = getInputProps({
          ...inputProps,
          ref: inputRef,
        });

        function onChange(e) {
          if (
            !invalidCharacters ||
            (invalidCharacters && !e.target.value.match(invalidCharacters))
          )
            textInputProps.onChange(e);
        }
        function onClick(e) {
          if (isLimitedlistActived(props.name)) onChange(e);
        }

        function disableCopyPaste(e) {
          if (shouldDisableCopyPaste) e.preventDefault();
        }

        // focus input on key press instead of selection
        function onKeyPress() {
          return true;
        }

        return (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="SuggestionsDropdown__suggestions-wrapper"
            onClick={handleWrapperFocus}
          >
            <TextInput
              {...textInputProps}
              onBlur={() => null}
              onClick={onClick}
              onChange={onChange}
              onKeyPress={onKeyPress}
              placeholder={placeholder}
              onPaste={disableCopyPaste}
              onCopy={disableCopyPaste}
              onCut={disableCopyPaste}
            />
            {placeholder &&
              !inputValue.length &&
              !selectedItems.length && (
                <span className="SuggestionsDropdown__placeholder">
                  {placeholder}
                </span>
              )}
          </div>
        );
      }}
    </DropdownComponent>
  );
};

SuggestionsInput.propTypes = {
  className: string,
  onSelect: func,
  placeholder: string,
  onFocus: func,
  onBlur: func,
  value: arrayOf(object),
  inputProps: objectOf(any),
  itemToString: func,
  highlight: bool,
  withCreatable: bool,
};

SuggestionsInput.defaultProps = {
  className: null,
  onSelect: null,
  placeholder: null,
  onFocus: noop,
  onBlur: noop,
  value: [],
  inputProps: {},
  itemToString: (item) => (item ? item.label : ''),
  highlight: false,
  withCreatable: true,
};

export default SuggestionsInput;
