import React from 'react';
import pp from 'prop-types';
import { Link } from 'react-router-dom';
import { getNotificationsRoute } from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';
import NotificationsList from '@/containers/NotificationsList';

function NotificationsPopper({ close }) {
  const { t } = useTranslation();
  return (
    <>
      <NotificationsList className="HeaderNotifications" useWindow={false} />
      <footer className="HeaderNotifications__footer">
        <Link to={getNotificationsRoute()} onClick={close}>
          {t('notifications.see_all')}
        </Link>
      </footer>
    </>
  );
}

NotificationsPopper.propTypes = {
  close: pp.func.isRequired,
};

export default NotificationsPopper;
