import React from 'react';
import { string } from 'prop-types';
import Field from '../Field';
import TagsSuggestionsInput from './TagsSuggestionsInput';

const TagsSuggestionsField = ({ onBlur, label, ...props }) => (
  <Field
    {...props}
    onBlur={() => onBlur(props.value)}
    render={({ getInputProps }) => (
      <TagsSuggestionsInput {...getInputProps({ label })} />
    )}
  />
);

TagsSuggestionsField.propTypes = {
  label: string,
};

TagsSuggestionsField.defaultProps = {
  label: null,
};

export default TagsSuggestionsField;
