import { get, keys, pick } from 'lodash';
import React from 'react';
import { RequiredField, Field } from '@/containers/ReduxForm';
import { fieldTypes } from '@/types/field';
import { element, func, oneOfType } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { isFunction } from 'react-dnd/lib/utils/js_utils';

/**
 *  Simple component to handle Section fields
 */
const SectionField = ({ fieldComponent, ...props }) => {
  const { t } = useTranslation();

  const {
    cbProps = [],
    inputProps = {},
    label,
    addLabel,
    helper,
    required,
    placeholder,
    validate,
  } = props;

  // Defines the component according to whether the field is required or not
  // Or just take the props fieldComponent
  const Component = fieldComponent || (required ? RequiredField : Field);

  const propsKeys = keys(props);

  const translatedValidate = isFunction(validate) ? validate(t) : validate;

  const updatedProps = pick(
    {
      ...props,
      validate: translatedValidate,
      label: t(label),
      placeholder: t(placeholder),
      addLabel: t(addLabel),
      helper: t(helper),
      cbProps: cbProps.map((item) => ({ ...item, label: t(item.label) })),
      inputProps: {
        ...inputProps,
        label: t(get(inputProps, 'label')),
        placeholder: t(get(inputProps, 'placeholder')),
      },
    },
    propsKeys
  );

  return <Component {...updatedProps} />;
};

SectionField.propTypes = {
  ...fieldTypes,
  fieldComponent: oneOfType([func, element]),
};

SectionField.defaultProps = {
  fieldComponent: null,
};

export default SectionField;
