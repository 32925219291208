import { get } from 'lodash';

export default function getCounter(data) {
  return {
    'current_user.company.missions': get(
      data,
      'current_user.company.missions_count'
    ),
    'current_user.company.matched_missions.items': get(
      data,
      'current_user.company.matched_missions.count'
    ),
    'current_user.company.lists': get(
      data,
      'current_user.company.manual_lists_count'
    ),
    'current_user.notifications': get(data, 'current_user.notifications_count'),
    'current_user.channels.items': get(data, 'current_user.channels.count'),
    'current_user.candidacies': get(data, 'current_user.candidacies_count'),
    'current_user.proposals': get(data, 'current_user.proposals_count'),
    'current_user.e_signature_procedures': get(
      data,
      'current_user.e_signature_procedures_count'
    ),
    'current_user.company.invitations_received_pending.items': get(
      data,
      'current_user.company.invitations_received_pending.count'
    ),
    'mission.proposals.items': get(data, 'mission.proposals.count'),
    'proposal.milestones': get(data, 'proposal.milestones_count'),
    'contract.contract_milestones.items': get(
      data,
      'contract.contract_milestones.count'
    ),
    'proposal.quotation.milestones': get(
      data,
      'proposal.quotation.milestones_count'
    ),
    'list.favorites_entities': get(data, 'list.favorites_entities_count'),
    'mission.matched_companies.items': get(
      data,
      'mission.matched_companies.count'
    ),
    'mission.invitations': get(data, 'mission.invitations_count'),
    'mission.nda_attachment.e_signature_procedures': get(
      data,
      'mission.nda_attachment.e_signature_procedures_count'
    ),
    'current_user.company.client_invoices.items': get(
      data,
      'current_user.company.client_invoices.count'
    ),
    'current_user.expert_invoices.items': get(
      data,
      'current_user.expert_invoices.count'
    ),
    'current_user.company.client_contracts.items': get(
      data,
      'current_user.company.client_contracts.count'
    ),
    'current_user.expert_contracts.items': get(
      data,
      'current_user.expert_contracts.count'
    ),
    'company.documents.items': get(data, 'company.documents_count'),
    'company.client_pool_companies': get(
      data,
      'company.client_pool_companies_count'
    ),
  };
}
