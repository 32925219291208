import React from 'react';
import pp from 'prop-types';
import Modal from '@/components/Modal';
import AddToLists from './AddToLists';
import NewList from './NewList';

function BookmarkAddModal({ t, close, id, name }) {
  return (
    <Modal
      name="BookmarkAdd"
      size="medium"
      close={close}
      onOk={close}
      onCancel={close}
      title={t('bookmarks.modal.add.title', { name })}
      subtitle={t('bookmarks.modal.add.subtitle')}
      okText={t('bookmarks.modal.add.ok')}
    >
      <AddToLists id={id} t={t} />
      <NewList id={id} t={t} />
    </Modal>
  );
}

BookmarkAddModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  id: pp.string.isRequired,
  name: pp.string.isRequired,
};

export default BookmarkAddModal;
