import React from 'react';
import { func } from 'prop-types';
import {
  LANDING_CLIENT_BLOG,
  LANDING_EBOOKS,
  LANDING_FREELANCER,
  LANDING_GLOBAL,
  LANDING_HIRE,
  LANDING_OPEN,
  LANDING_PLATFORM,
} from '@/constants/router';
import Section from '../Section';
import List from '../List';

const links = [
  {
    id: 'hire',
    label: 'footer.discover.link.hire',
    href: LANDING_HIRE,
    target: '_blank',
  },
  {
    id: 'freelancer',
    label: 'footer.discover.link.freelancer',
    href: LANDING_FREELANCER,
    target: '_blank',
  },
  {
    id: 'global',
    label: 'footer.discover.link.global',
    href: LANDING_GLOBAL,
    target: '_blank',
  },
  {
    id: 'open',
    label: 'footer.discover.link.open',
    href: LANDING_OPEN,
    target: '_blank',
  },
  {
    id: 'platform',
    label: 'footer.discover.link.platform',
    href: LANDING_PLATFORM,
    target: '_blank',
  },
  {
    id: 'client_blog',
    label: 'footer.discover.link.client_blog',
    href: LANDING_CLIENT_BLOG,
    target: '_blank',
  },
  {
    id: 'ebooks',
    label: 'footer.discover.link.ebooks',
    href: LANDING_EBOOKS,
    target: '_blank',
  },
];

function Discover({ t }) {
  return (
    <Section name="Companies" title={t('footer.companies.title')}>
      <List items={links} t={t} />
    </Section>
  );
}

Discover.propTypes = {
  t: func.isRequired,
};

export default Discover;
