import { formatModalities as format } from '@/selectors/profile/common';
import contractTypeHandler from '../helpers/contractTypeHandler';
import { get, isObject } from 'lodash';

export const formatModalities = ({
  salary,
  contract_types,
  salary_confidentiality,
  daily_rate,
  billings,
  job_name,
  ...values
}) => ({
  ...format(values),
  // the job_name is a select for expert, but the api requires a string
  job_name: isObject(job_name) ? get(job_name, 'value') : job_name,
  ...contractTypeHandler(
    contract_types,
    salary,
    daily_rate,
    billings,
    salary_confidentiality
  ),
});
