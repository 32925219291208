import React from 'react';
import pp from 'prop-types';
import './styles.scss';

const Badge = ({ children, count, overflowCount, showZero }) => (
  <span className="Badge">
    {children}
    {(count || (count === 0 && showZero)) && (
      <sup className="Badge__count" title={count}>
        <span className="Badge__count-inner" title={count}>
          {count > overflowCount ? `${overflowCount}+` : count}
        </span>
      </sup>
    )}
  </span>
);

Badge.propTypes = {
  children: pp.node,
  count: pp.number,
  overflowCount: pp.number,
  showZero: pp.bool,
};

Badge.defaultProps = {
  children: null,
  count: null,
  overflowCount: 99,
  showZero: false,
};

export default Badge;
