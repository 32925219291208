import React from 'react';
import { string, func, arrayOf, oneOfType } from 'prop-types';
import cx from 'classnames';
import './styles.scss';
import { get } from 'lodash';

const toLabelledChoice = (Choice) => {
  const LabelledChoice = ({
    className,
    id,
    icon: Icon,
    label,
    componentLabel,
    subLabel,
    variants,
    ...props
  }) => {
    // TODO need to be investigated (we should use default props, but it does not work, for some reason)
    const disabled = get(props, 'disabled', false) || false;
    const checked = get(props, 'checked', false) || false;
    return (
      <label
        htmlFor={id}
        className={cx(
          'LabelledChoice',
          variants.map((v) => `LabelledChoice--${v}`),
          className,
          disabled && !checked && 'LabelledChoice--disabled'
        )}
      >
        <Choice id={id} {...props} className="LabelledChoice__checkbox" />
        {Icon &&
          (Icon instanceof Function ? (
            <Icon className="LabelledChoice__icon" fill={get(props, 'fill')} />
          ) : (
            <img src={Icon} className="LabelledChoice__icon" alt="" />
          ))}
        <div>
          {label && <div className="LabelledChoice__label">{label}</div>}
          {componentLabel && (
            <div className="LabelledChoice__component-label">
              {componentLabel}
            </div>
          )}
          {subLabel && (
            <div className="LabelledChoice__subLabel">{subLabel}</div>
          )}
        </div>
      </label>
    );
  };

  LabelledChoice.propTypes = {
    className: string,
    id: string.isRequired,
    icon: oneOfType([string, func]),
    label: string.isRequired,
    subLabel: string,
    variants: arrayOf(string),
  };

  LabelledChoice.defaultProps = {
    className: null,
    icon: null,
    subLabel: null,
    variants: [],
  };

  return LabelledChoice;
};

export default toLabelledChoice;
