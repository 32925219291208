import { compose } from 'recompose';
import { connect } from 'react-redux';
import { hideFlash } from '@/redux/modules/flash';
import translate from '@/helpers/enhancers/translate';
import FlashManager from './FlashManager';

export default compose(
  connect(
    (state) => state.flash,
    { hideFlash }
  ),
  translate()
)(FlashManager);
