import React, { useContext } from 'react';
import TagsList from '@/components/Tags/components/TagsList';
import useTranslation from '@/hooks/useTranslation';
import {
  string,
  any,
  bool,
  func,
  arrayOf,
  instanceOf,
  number,
} from 'prop-types';
import TagsContext from '@/components/Tags/TagsContext';
import Tooltip from '@/components/Tooltip';
import { ReactComponent as InfoIcon } from '@/images/c-info.svg';

/**
 * Tags
 *
 *    - Autocompletes word searching
 *    - Display word added or chosen from search
 *    - Allow or not word added from search to be rated
 *
 * Often used for :
 *     - Profile (edition/show) Feature [SKILL, CERTIFICATION, KEYWORD...]
 *     - Mission (creation/edition/show) Feature [SKILL, CERTIFICATION, KEYWORD...]
 *     - Post Signup (creation/edition/show) Feature [SKILL, CERTIFICATION, KEYWORD...]
 *
 */
function Tags({
  description,
  title,
  value,
  onChange,
  name,
  kind,
  form,
  helperText,
  required,
  withTooltip,
  withCreatable,
  withIcon,
  invalidCharacters,
  itemValidate,
  disableCopyPaste,
  inlineDisplay,
  disabledVariant,
  minCharsSuggestion,
}) {
  const { t } = useTranslation();
  const tagsContext = useContext(TagsContext);
  const TitleComponent = () => (
    <>{title && <h4 className="fs-medium fw-bold">{t(title)}</h4>}</>
  );

  return (
    <div title={t(title)} className="m-b-l">
      {withTooltip ? (
        <div className="d-f">
          <TitleComponent />
          <span className="p-l-s">
            <Tooltip title={t('tags.title.tooltip')}>
              <InfoIcon />
            </Tooltip>
          </span>
        </div>
      ) : (
        <>
          <TitleComponent />
        </>
      )}
      {description && (
        <p className={required ? 'wildcard' : ''}>
          {t(description, { count: tagsContext.max })}
        </p>
      )}
      {helperText && (
        <p className="grey">
          {t(helperText, { count: tagsContext.highlightMaxValues })}
        </p>
      )}
      <TagsList
        tags={value}
        formName={form}
        fieldName={name}
        tagKind={kind}
        withCreatable={withCreatable}
        withIcon={withIcon}
        onChange={onChange}
        required={required}
        invalidCharacters={invalidCharacters}
        itemValidate={itemValidate}
        disableCopyPaste={disableCopyPaste}
        inlineDisplay={inlineDisplay}
        disabledVariant={disabledVariant}
        minCharsSuggestion={minCharsSuggestion}
      />
    </div>
  );
}

Tags.propTypes = {
  description: string,
  disableCopyPaste: bool,
  form: string.isRequired,
  helperText: string,
  invalidCharacters: instanceOf(RegExp),
  itemValidate: arrayOf(func),
  name: string.isRequired,
  kind: string,
  onChange: func.isRequired,
  required: bool,
  title: string,
  value: arrayOf(any),
  withTooltip: bool,
  withCreatable: bool,
  inlineDisplay: bool,
  withIcon: bool,
  disabledVariant: bool,
  minCharsSuggestion: number,
};

Tags.defaultProps = {
  description: null,
  kind: null,
  disableCopyPaste: false,
  helperText: null,
  invalidCharacters: null,
  itemValidate: [],
  required: false,
  title: null,
  value: [],
  withTooltip: false,
  withCreatable: null,
  inlineDisplay: null,
  withIcon: false,
  disabledVariant: false,
  minCharsSuggestion: 2,
};

export default Tags;
