import { LOCATION } from '@/components/Matching/Filters/presets';

/**
 * Factory for nested filters to be more atomic and avoiding specific condition inside handler
 * Some of the components mounted by nested filter might need specific logical attention
 * Implements in case statements specifics logical
 * @param value
 * @param key
 * @param meta
 * @returns {*}
 */
function factory(value, key, meta) {
  switch (key) {
    case LOCATION:
      return meta;
    default:
      return value;
  }
}

/**
 * Handle selected nested filter
 * @param value
 * @param key
 * @param meta
 * @returns {*}
 */
export function handler(value, key, meta) {
  return {
    [key]: factory(value, key, meta),
  };
}
