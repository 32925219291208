import React from 'react';
import { RequiredField, RfDate, RfHelper } from '@/containers/ReduxForm';
import { bool } from 'prop-types';
import { date } from '@/helpers/validation';
import {
  today,
  endOfMonthFromBeginDate,
  startOfMonthFromBeginDate,
} from '@/helpers/date';
import { isDate } from 'lodash';
import useTranslation from '@/hooks/useTranslation';

const DatesInput = ({
  isCreationContext,
  milestone,
  restrictionDateAllowed,
}) => {
  const { t } = useTranslation();
  const beginDate =
    milestone && milestone.begin_date
      ? startOfMonthFromBeginDate(milestone.begin_date)
      : today;
  const beforeDate = isCreationContext ? today : beginDate;

  return (
    <>
      {isCreationContext && (
        <p className="fw-bold">
          {t('milestone.technical_assistance.date.title')}
        </p>
      )}
      <div className="grid">
        <div className="grid__item mobileL--one-whole one-half m-b-s">
          <RequiredField
            component={RfDate}
            id="begin_date"
            name="begin_date"
            label={t('milestone.technical_assistance.beginDate.label')}
            placeholder={t('milestone.technical_assistance.date.placeholder')}
            autoComplete="off"
            dayPickerProps={
              restrictionDateAllowed && {
                disabledDays: {
                  before: beforeDate,
                  ...(!isCreationContext && {
                    after: endOfMonthFromBeginDate(beginDate),
                  }),
                },
              }
            }
            validate={
              restrictionDateAllowed
                ? [
                    date({
                      '>=': beforeDate,
                      ...(!isCreationContext && {
                        '<=': endOfMonthFromBeginDate(beginDate),
                        format: date,
                      }),
                    }),
                  ]
                : []
            }
          />
        </div>
        <div className="grid__item mobileL--one-whole one-half m-b-s">
          <RfHelper values={['begin_date']}>
            {({ begin_date }) => (
              <RequiredField
                component={RfDate}
                id="end_date"
                name="end_date"
                label={t('milestone.technical_assistance.endDate.label')}
                placeholder={t(
                  'milestone.technical_assistance.date.placeholder'
                )}
                autoComplete="off"
                dayPickerProps={{
                  disabledDays: {
                    before: isDate(begin_date) && begin_date,
                    ...(restrictionDateAllowed && {
                      after: endOfMonthFromBeginDate(begin_date),
                    }),
                  },
                }}
                validate={[
                  date({
                    '>=': begin_date || today,
                    ...(restrictionDateAllowed && {
                      '<=': endOfMonthFromBeginDate(begin_date),
                    }),
                    format: date,
                  }),
                ]}
              />
            )}
          </RfHelper>
        </div>
      </div>
    </>
  );
};

DatesInput.propTypes = {
  restrictionDateAllowed: bool,
};

DatesInput.defaultProps = {
  restrictionDateAllowed: true,
};

export default DatesInput;
