import React from 'react';
import { bool, objectOf, any, func } from 'prop-types';
import { get } from 'lodash';
import { RequiredField, RfText } from '@/containers/ReduxForm';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import { isCreateContext } from '@/components/Contract/helpers/isCreateContext';
import DatesInput from '@/components/Input/DatesInput';
import { DaysWorkedInput, HoursWorkedInput } from '@/components/Input';
import Charges from '@/components/Charges';
import FormSection from '@/components/FormSection';
import { UNIT, HOUR } from '@/constants/costPerKind';
import config from '@/_config';
import './styles.scss';

// Technical Assistance milestone
function MilestoneTechnicalAssistanceModal({
  close,
  change,
  handleSubmit,
  submitting,
  createMilestone,
  updateMilestone,
  entity,
  edit,
  initialValues,
}) {
  const { t } = useTranslation();

  function getPeriodWorkedKind(context) {
    switch (config.proposal.costPer) {
      case UNIT: {
        return (
          <>
            <h4 className="Form__label fw-bold m-b-s m-t-m">
              {t('milestone.technical_assistance.days_worked.title')}
            </h4>
            <DaysWorkedInput context={context} />
          </>
        );
      }
      case HOUR: {
        return (
          <>
            <h4 className="Form__label fw-bold m-b-s m-t-m">
              {t('milestone.technical_assistance.hours_worked.title')}
            </h4>
            <HoursWorkedInput context={context} withInformation={false} />
          </>
        );
      }
      default:
        return null;
    }
  }

  async function onSubmit({ ...values }) {
    const { charges } = values;
    const formattedValues = {
      ...values,
      total_duty: +get(values, 'pricing.amount', null),
      charges_attributes: charges
        ? charges.map(({ __typename: cType, total_duty, ...charge }) => ({
            ...charge,
            cost_per_unit: +charge.cost_per_unit,
            quantity: +charge.quantity,
          }))
        : [],
    };

    if (edit) {
      await updateMilestone({
        variables: formattedValues,
        refetchQueries: ['getProposal'],
        awaitRefetchQueries: true,
      });
    } else {
      await createMilestone({
        variables: {
          quotation_id: entity.id,
          ...formattedValues,
        },
        refetchQueries: ['getProposal'],
        awaitRefetchQueries: true,
      });
    }

    close();
  }

  const context = 'update';

  const isCreationContext = isCreateContext(context);

  const isMilestoneGenerated = edit
    ? !!initialValues.title.match(/month_(\d+)/)
    : false;

  return (
    <Modal
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      name="Milestone"
      title={t(`proposal.modal.milestone.title${edit ? '.edit' : ''}`)}
      disableActions={submitting}
      closable={!submitting}
      okText={t(`proposal.modal.milestone.ok${edit ? '.edit' : ''}`)}
    >
      <FormSection>
        {!edit && (
          <>
            <h4 className="Form__label fw-bold m-b-s">
              {t('proposal.modal.milestone.description.label')}
            </h4>
            <RequiredField
              id="title"
              name="title"
              component={RfText}
              type="text"
              placeholder={t('milestone.milestone.title.placeholder')}
              label={t('proposal.modal.milestone.title.label')}
              className="one-half mobileL--one-whole"
            />
          </>
        )}
        <h4 className="Form__label fw-bold m-b-m m-t-l">
          {t('milestone.technical_assistance.dates.title')}
        </h4>
        <DatesInput
          isCreationContext={isCreationContext}
          milestone={initialValues}
          // allowed to not restrict dates if we creat and edit milestones not generated
          restrictionDateAllowed={!!edit && isMilestoneGenerated}
        />
        {getPeriodWorkedKind(context)}
        <Charges change={change} displayOnMount />
      </FormSection>
    </Modal>
  );
}

MilestoneTechnicalAssistanceModal.propTypes = {
  close: func.isRequired,
  change: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  createMilestone: func.isRequired,
  updateMilestone: func.isRequired,
  entity: objectOf(any),
  edit: bool,
  initialValues: objectOf(any),
};

MilestoneTechnicalAssistanceModal.defaultProps = {
  entity: null,
  edit: false,
  initialValues: null,
};

export default MilestoneTechnicalAssistanceModal;
