export const ARCHIVE_REASON_WON = {
  id: 'won',
  value: 'won',
  label: 'myMissionBoard.modal.archive.kind.won',
};

export const ARCHIVE_REASON_CANCELLED = {
  id: 'cancelled',
  value: 'cancelled',
  label: 'myMissionBoard.modal.archive.kind.cancelled',
};

export const ARCHIVE_REASON_CANCELLED_BY_CLENT = {
  id: 'cancelled_by_client',
  value: 'cancelled_by_client',
  label: 'myMissionBoard.modal.archive.kind.cancelled_by_client',
};

export const ARCHIVE_REASON_CANCELLED_BY_OWNER_WITH_USER = (user) => ({
  id: 'cancelled_by_owner',
  value: 'cancelled_by_owner',
  label: {
    keyName: 'myMissionBoard.modal.archive.kind.cancelled_by_owner',
    variable: user.get('company.name'),
  },
});

export const ARCHIVE_REASON_CANCELLED_BY_OWNER = {
  id: 'cancelled_by_owner',
  value: 'cancelled_by_owner',
  label: 'myMissionBoard.modal.archive.kind.cancelled_by_owner',
};

export const ARCHIVE_REASON_STAFFED_BY_COMPETITOR = {
  id: 'staffed_by_competitor',
  value: 'staffed_by_competitor',
  label: 'myMissionBoard.modal.archive.kind.staffed_by_competitor',
};

export const ARCHIVE_REASON_NO_NEWS_FROM_CLIENT = {
  id: 'no_news_from_client',
  value: 'no_news_from_client',
  label: 'myMissionBoard.modal.archive.kind.no_news_from_client',
};

export const ARCHIVE_REASON_FILLED_INTERNALLY = {
  id: 'filled_internally',
  value: 'filled_internally',
  label: 'myMissionBoard.modal.archive.kind.filled_internally',
};

export const ARCHIVE_REASON_FILLED_BY_EXTERNAL_RECRUITER = {
  id: 'filled_by_external_recruiter',
  value: 'filled_by_external_recruiter',
  label: 'myMissionBoard.modal.archive.kind.filled_by_external_recruiter',
};

export const ARCHIVE_REASON_LOST = {
  id: 'lost',
  value: 'lost',
  label: 'myMissionBoard.modal.archive.kind.lost',
};
