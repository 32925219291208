import { graphql } from 'react-apollo';
import { updateTools } from '@/api/Company/mutations';
import { compose } from 'recompose';
import ToolsModal from './ToolsModal';
import translate from '@/helpers/enhancers/translate';

export default compose(
  graphql(updateTools, { name: 'update' }),
  translate()
)(ToolsModal);
