import { AbilityBuilder, Ability } from '@casl/ability';
import defineAbilityForCandidacy from './candidacy';
import defineAbilityForMission from '@/permissions/abilities/mission';
import defineAbilityForUser from '@/permissions/abilities/user/user';
import defineAbilityForProposal from '@/permissions/abilities/proposal';

export default function defineAbilityFor(user, config) {
  const { can, build } = new AbilityBuilder(Ability);

  defineAbilityForMission(can, user, config);
  defineAbilityForCandidacy(can);
  defineAbilityForUser(can, config);
  defineAbilityForProposal(can);

  return build();
}
