import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { createBoardList } from '@/api/MyMission/mutations';
import translate from '@/helpers/enhancers/translate';
import CreateListModal from './CreateListModal';

export default compose(
  graphql(createBoardList),
  translate()
)(CreateListModal);
