/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/

import { FREELANCE } from '@/constants/companyKind';

export const freelance = {
  label: 'fields.companyKinds.freelance',
  value: FREELANCE,
  id: FREELANCE,
  selectable: true,
  solo: true,
};

export const startup = {
  label: 'fields.companyKinds.startup',
  value: 'startup',
  id: 'startup',
  selectable: true,
  solo: false,
};

export const esn = {
  label: 'fields.companyKinds.esn',
  value: 'esn',
  id: 'esn',
  selectable: true,
  solo: false,
};

export const designOffice = {
  label: 'fields.companyKinds.design_office',
  value: 'design_office',
  id: 'design_office',
  selectable: true,
  solo: false,
};

export const agency = {
  label: 'fields.companyKinds.agency',
  value: 'agency',
  id: 'agency',
  selectable: true,
  solo: false,
};

export const recruitmentCandidate = {
  label: 'fields.companyKinds.recruitment_candidate',
  value: 'recruitment_candidate',
  id: 'recruitment_candidate',
  selectable: false,
  solo: true,
};

export const consulting = {
  label: 'fields.companyKinds.consulting',
  value: 'consulting',
  id: 'consulting',
  selectable: true,
  solo: false,
};

export const smes = {
  label: 'fields.companyKinds.smes',
  value: 'smes',
  id: 'smes',
  selectable: true,
  solo: false,
};

export const majorCompany = {
  label: 'fields.companyKinds.major_company',
  value: 'major_company',
  id: 'major_company',
  selectable: true,
  solo: false,
};

export const unknown = {
  label: 'fields.companyKinds.unknown',
  value: 'unknown',
  id: 'unknown',
  selectable: false,
  solo: true,
};

export default [
  freelance,
  startup,
  esn,
  designOffice,
  agency,
  recruitmentCandidate,
  consulting,
  smes,
  majorCompany,
  unknown,
];
