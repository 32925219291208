import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import { getNewRequests } from '@/constants/algolia';

const compareCriticalProps = (prev, next) =>
  isEqual(prev.searchState, next.searchState) && prev.query === next.query;
// On peut estimer que les autres props sont juste set une fois au premier render et ne vont pas bouger apres

// Do not remove memo, otherwise the app can crashed
const Algolia = memo(
  ({
    children,
    filters,
    hitsPerPage,
    clientOptions,
    maxValuesPerFacet,
    query,
    searchState,
    restrictSearchableAttributes,
    ...instantSearchProps
  }) => {
    const algoliaClient = algoliasearch(
      process.env.REACT_APP_ALGOLIA_APP_ID,
      process.env.REACT_APP_ALGOLIA_API_KEY,
      clientOptions
    );

    // Implemented for the multiChoices filter, remove it if the filter is not used
    const algoliaSearchClient = {
      async search(requests) {
        const newRequests = await getNewRequests(requests);
        return algoliaClient.search(newRequests);
      },
      async searchForFacetValues(requests) {
        const newRequests = await algoliaClient.searchForFacetValues(requests);
        return newRequests;
      },
    };

    return (
      <InstantSearch
        searchClient={algoliaSearchClient}
        searchState={searchState}
        {...instantSearchProps}
      >
        <Configure
          filters={filters}
          hitsPerPage={hitsPerPage}
          maxValuesPerFacet={maxValuesPerFacet}
          query={query}
          {...restrictSearchableAttributes && {
            restrictSearchableAttributes,
          }}
        />
        {children}
      </InstantSearch>
    );
  },
  compareCriticalProps
);

Configure.defaultProps = {
  hitsPerPage: 20,
};

export default Algolia;
