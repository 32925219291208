import {
  CheckboxList,
  Localization as LocalizationComponent,
} from './components';

export const FACET = 'facet';
export const NESTED = 'nested';
export const LOCATION = 'location';
export const DISTANCE = 'distance';
export const LOCALIZATION = 'localization';

export const Localization = {
  name: LOCALIZATION,
  Component: LocalizationComponent,
  title: 'filters.localization.title',
  kind: NESTED,
  items: [{ key: LOCATION }, { key: DISTANCE }],
  scrollable: false,
  displayCount: false,
  searchable: false,
};

export const CompanyKinds = {
  name: 'companyKinds',
  Component: CheckboxList,
  title: 'filters.companyKinds.title',
  kind: FACET,
  scrollable: true,
  displayCount: true,
  searchable: true,
};

export const Sectors = {
  name: 'sectors',
  Component: CheckboxList,
  title: 'filters.sectors.title',
  kind: FACET,
  scrollable: true,
  displayCount: true,
  searchable: true,
};

export const ContractTypes = {
  name: 'contractTypes',
  Component: CheckboxList,
  title: 'filters.contractTypes.title',
  kind: FACET,
  scrollable: true,
  displayCount: true,
  searchable: true,
};

export const StartTypes = {
  name: 'startTypes',
  Component: CheckboxList,
  title: 'filters.startTypes.title',
  kind: FACET,
  scrollable: true,
  displayCount: true,
  searchable: true,
};

export const AvailabilityTypes = {
  name: 'availabilityTypes',
  Component: CheckboxList,
  title: 'filters.availabilityTypes.title',
  kind: FACET,
  scrollable: false,
  displayCount: true,
  searchable: false,
};
