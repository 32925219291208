import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import { ReactComponent as Plus } from '@/images/plus.svg';
import './styles.scss';

function Action({ children, className, ...otherProps }) {
  return (
    <button
      type="button"
      className={cx('Proposal__action', className)}
      {...otherProps}
    >
      <Plus />
      {children}
    </button>
  );
}

Action.propTypes = {
  children: pp.node.isRequired,
  className: pp.string,
};

Action.defaultProps = {
  className: null,
};

export default Action;
