import React, { useContext } from 'react';
import { func, string } from 'prop-types';
import { Mutation } from 'react-apollo';
import { isObject } from 'lodash';
import { archiveMission } from '@/api/Mission/mutations';
import {
  RequiredField,
  Field,
  RfArea,
  RfChoices,
} from '@/containers/ReduxForm';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import BoardModal from '../../BoardModal';
import config from '@/_config';

function ArchiveMissionModal({ t, id, ...otherProps }) {
  const userContext = useContext(UserContext);
  const user = new User(userContext);
  const radioProps = config.mission.archiveReasons(user);
  return (
    <Mutation mutation={archiveMission} variables={{ id }}>
      {(mutate) => (
        <BoardModal
          size="medium"
          name="Board-ArchiveMission"
          title={t('myMissionBoard.modal.archive.title')}
          subtitle={t('myMissionBoard.modal.archive.subtitle')}
          form="board_archiveMisison"
          mutate={mutate}
          {...otherProps}
        >
          <RequiredField
            id="archived_kind"
            name="archived_kind"
            type="radioGroup"
            component={RfChoices}
            checkbox={LabelledRadio}
            cbProps={radioProps.map((p) => ({
              ...p,
              label: isObject(p.label)
                ? t(p.label.keyName, { entity: p.label.variable })
                : t(p.label),
            }))}
            className="m-b-m"
          />
          <Field
            id="archived_text"
            name="archived_text"
            label={t('myMissionBoard.modal.archive.text.label')}
            placeholder={t('myMissionBoard.modal.archive.text.placeholder')}
            component={RfArea}
          />
        </BoardModal>
      )}
    </Mutation>
  );
}

ArchiveMissionModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  id: string.isRequired,
};

export default ArchiveMissionModal;
