import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { createChannel } from '@/api/Conversation/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import ConverseModal from './ConverseModal';

export default compose(
  graphql(createChannel, { name: 'createChannel' }),
  reduxForm({ form: 'collaborate_converse' }),
  withRouter
)(ConverseModal);
