import React from 'react';
import { objectOf, any, func, string, oneOfType, object } from 'prop-types';
import { noop, get } from 'lodash';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import { getLocation } from '@/selectors/location';
import { AutoComplete } from '@/components/Fields/AutoCompleteField';
import withAsync from '@/components/Dropdown/withAsync';
import LoadingCircle from '@/components/LoadingCircle';
import { ReactComponent as Marker } from '@/images/marker.svg';
import debouncePromise from '@/helpers/debouncePromise';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

const AsyncAutoComplete = withAsync(AutoComplete);

function LocationInput(props) {
  const internalPlacesService = null;
  const {
    inputProps,
    onFocus,
    onBlur,
    value,
    maps,
    onChange,
    searchOptions,
    withDetails,
    ...otherProps
  } = props;
  const autocompleteService = new maps.places.AutocompleteService();

  const loadOptions = debouncePromise(
    (query) =>
      new Promise((resolve, reject) => {
        if (query.length) {
          autocompleteService.getPlacePredictions(
            {
              ...searchOptions,
              types: get(searchOptions, 'types', ['(regions)']),
              input: query,
            },
            (predictions, status) => {
              if (status !== maps.places.PlacesServiceStatus.OK) {
                reject(status);
              }
              const places = predictions
                ? predictions.map((p) => ({ ...p, address: p.description }))
                : predictions;
              resolve(places);
            }
          );
        } else {
          resolve([]);
        }
      }),
    500
  );

  const placesService = () =>
    internalPlacesService ||
    new maps.places.PlacesService(document.createElement('div'));

  const itemToString = (item) => getLocation(item) || get(item, 'address');

  const loadDetails = (placeId) =>
    new Promise((resolve, reject) => {
      placesService().getDetails(
        {
          placeId,
          fields: ['geometry', 'address_components'],
        },
        (details, status) => {
          if (status !== maps.places.PlacesServiceStatus.OK) {
            reject(status);
          }

          const { location, viewport } = details.geometry;
          const northEast = viewport.getNorthEast();
          const southWest = viewport.getSouthWest();

          const provinceComponent = details.address_components.find(
            (component) =>
              component.types.includes('administrative_area_level_1')
          );

          const province = provinceComponent ? provinceComponent.long_name : '';

          resolve({
            lat: location.lat(),
            lng: location.lng(),
            boundingBox: {
              northEast: {
                lat: northEast.lat(),
                lng: northEast.lng(),
              },
              southWest: {
                lat: southWest.lat(),
                lng: southWest.lng(),
              },
            },
            province,
          });
        }
      );
    });

  const handleChange = async (currentValue) => {
    if (withDetails && get(currentValue, 'place_id')) {
      const details = await loadDetails(currentValue.place_id);
      onChange({ ...currentValue, ...details });
    } else {
      onChange(currentValue);
    }
  };

  return (
    <AsyncAutoComplete
      loadOptions={loadOptions}
      value={value}
      minChars={3}
      itemToString={itemToString}
      onFocus={onFocus}
      onBlur={() => onBlur(value)}
      inputProps={{
        ...inputProps,
        clearable: true,
        icon: <Marker />,
      }}
      {...otherProps}
      onChange={handleChange}
    />
  );
}

LocationInput.propTypes = {
  searchOptions: objectOf(any),
  inputProps: objectOf(any),
  onBlur: func,
  onFocus: func,
  value: oneOfType([string, object]),
};

LocationInput.defaultProps = {
  searchOptions: {},
  inputProps: {},
  onFocus: noop,
  onBlur: noop,
  value: {},
};

export default function(props) {
  const { i18n } = useTranslation();
  return (
    <ReactGoogleMapLoader
      params={{
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: props.withDetails ? 'places,geometry' : 'places',
        language: i18n.language,
      }}
      render={(maps) =>
        !maps ? <LoadingCircle /> : <LocationInput {...props} maps={maps} />
      }
    />
  );
}
