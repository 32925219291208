import React, { useEffect } from 'react';
import { string, objectOf, any, bool } from 'prop-types';
import cx from 'classnames';
import { filter, last } from 'lodash';
import { Field } from 'redux-form';
import { ReactComponent as Plus } from '@/images/plus.svg';
import { ReactComponent as Minus } from '@/images/minus.svg';
import { RequiredField, RfLocation } from '@/containers/ReduxForm';
import './styles.scss';

const LocationFields = ({
  className,
  fields,
  useSpacer,
  addLabel,
  searchOptions,
  inputProps,
  required,
}) => {
  const addField = () => {
    fields.push(null);
  };

  useEffect(() => {
    if (!fields.length) addField();
  });

  const deleteField = (index) => {
    fields.remove(index);
  };

  const handleClear = (index) => {
    const anyEmpty =
      filter(fields.getAll(), (f, i) => index !== i && !f).length > 0;

    // Only allow one empty field
    if (anyEmpty) deleteField(index);
  };

  const FieldComponent = required ? RequiredField : Field;

  return (
    <div className={cx('DynamicFields', className)}>
      {fields.map((field, i) => (
        <div
          className="DynamicFields__field"
          key={field}
          data-testid={`nested-location-${i}`}
        >
          {i ? (
            <button
              type="button"
              onClick={() => deleteField(i)}
              className="DynamicFields__action DynamicFields__action--delete"
            >
              <Minus />
            </button>
          ) : null}

          {!i && useSpacer ? <span className="DynamicFields__spacer" /> : null}

          <FieldComponent
            required={required}
            inputProps={{
              ...inputProps,
              onClear: () => handleClear(i),
            }}
            className="flex-auto"
            name={field}
            component={RfLocation}
            searchOptions={searchOptions}
          />
        </div>
      ))}
      {last(fields.getAll()) && (
        <div
          className="DynamicFields__field DynamicFields__field--last"
          onClick={addField}
          role="button"
          tabIndex={-1}
          data-testid="locations-add"
        >
          <button
            type="button"
            className="DynamicFields__action DynamicFields__action--add"
          >
            <Plus />
          </button>
          <span className="fs-small">{addLabel}</span>
        </div>
      )}
    </div>
  );
};

LocationFields.propTypes = {
  className: string,
  fields: objectOf(any).isRequired,
  useSpacer: bool,
  inputProps: objectOf(any),
  addLabel: string.isRequired,
  searchOptions: objectOf(any),
  required: bool,
};

LocationFields.defaultProps = {
  className: null,
  useSpacer: true,
  searchOptions: null,
  inputProps: null,
  required: false,
};

export default LocationFields;
