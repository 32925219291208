import React from 'react';
import config from '@/_config';
import useTranslation from '@/hooks/useTranslation';
import ProfileModal from '../../ProfileModal';

const PlatformRecommendationModal = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal.platform_recommendation.title', {
        companyName: config.displayName,
      })}
      form="company_recommendation_platform"
      {...props}
    >
      <p>{t('company.modal.platform_recommendation.description')}</p>
      <p className="fw-bold">
        {t('company.modal.platform_recommendation.question')}
      </p>
    </ProfileModal>
  );
};

export default PlatformRecommendationModal;
