import React from 'react';
import { number, func, bool, string } from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import mime from 'mime-types';
import { propType } from 'graphql-anywhere';
import attachmentFragment from '@/api/Attachment/fragment';
import prettyBytes from '@/helpers/prettyBytes';
import { ReactComponent as Download } from '@/images/download.svg';
import { ReactComponent as Delete } from '@/images/delete.svg';
import { ReactComponent as Lock } from '@/images/padlock.svg';
import { ReactComponent as Wallet } from '@/images/wallet.svg';
import { ReactComponent as Link } from '@/images/go-out.svg';
import ProgressBar from '@/components/ProgressBar';
import Tooltip from '@/components/Tooltip';
import { STATUS } from '@/constants/file';
import './styles.scss';

const { ACCESS, EDIT, INACTIVE, LOCKED, READ, UPLOAD } = STATUS;

function File({ className, file, index, onActionClick, asset }) {
  const { status } = file;

  function renderAction() {
    if (status === LOCKED) {
      return (
        <span className="File__action">
          <Tooltip title="fields.file.tooltip.locked.title">
            <Lock />
          </Tooltip>
        </span>
      );
    }

    if (status === UPLOAD || status === EDIT) {
      return (
        <button
          type="button"
          onClick={() => onActionClick(index, file)}
          className="File__action"
        >
          <Tooltip title="fields.file.tooltip.delete.title">
            <Delete />
          </Tooltip>
        </button>
      );
    }

    if (status === ACCESS) {
      return (
        <a
          href={file.read_url}
          target="_blank"
          rel="noopener noreferrer"
          className="File__action"
        >
          <Tooltip title="fields.file.tooltip.access.title">
            <Link />
          </Tooltip>
        </a>
      );
    }

    if (status !== ACCESS && status !== INACTIVE && status !== READ) {
      return (
        <a
          href={file.download_url || file.read_url}
          download={file.name}
          rel="noopener noreferrer"
          className="File__action"
        >
          <Tooltip title="fields.file.tooltip.download.title">
            <Download />
          </Tooltip>
        </a>
      );
    }

    return null;
  }

  return (
    <div className={cx('File', `File--${status}`, className)}>
      <a
        className="File__inner"
        href={status !== UPLOAD && status !== INACTIVE ? file.read_url : null}
        target="_blank"
        rel="noopener noreferrer"
        title={file.name}
        download={file.name}
      >
        <div className="File__icon">
          {asset ? <Wallet /> : mime.extension(file.mime_type)}
        </div>
        <div className="File__info">
          <span className="File__name">{file.name}</span>

          {status === UPLOAD && file.percentCompleted < 100 ? (
            <ProgressBar progress={file.percentCompleted} />
          ) : (
            <>
              {file.size !== null && (
                <span className="File__size">{prettyBytes(file.size)}</span>
              )}
            </>
          )}
        </div>
      </a>

      {renderAction()}
    </div>
  );
}

File.propTypes = {
  className: string,
  file: propType(attachmentFragment).isRequired,
  index: number,
  onActionClick: func,
  asset: bool,
};

File.defaultProps = {
  className: null,
  index: null,
  onActionClick: _.noop,
  asset: false,
};

export default File;
