import React from 'react';
import { func, string, objectOf, any } from 'prop-types';
import { Field, RfEditor } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';

const JoinUsModal = ({ t, kind, leader, ...props }) => (
  <ProfileModal
    title={t('company.modal.join_us.title')}
    form="company_join_us"
    {...props}
  >
    <FormSection>
      <Field
        component={RfEditor}
        name="join_us"
        label={t('company.modal.join_us.label')}
      />
    </FormSection>
  </ProfileModal>
);

JoinUsModal.propTypes = {
  t: func.isRequired,
  kind: string.isRequired,
  leader: objectOf(any).isRequired,
};

export default JoinUsModal;
