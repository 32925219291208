import React, { useEffect } from 'react';
import { func } from 'prop-types';
import { graphql } from 'react-apollo';
import { viewAllNotifications } from '@/api/Notification/mutations';
import { getNotifications } from '@/api/Notification/queries';
import useTranslation from '@/hooks/useTranslation';
import InfiniteScroll from '@/components/InfiniteScroll';
import Notification from '@/components/Notification';

function NotificationsList({ mutate, ...otherProps }) {
  const { t } = useTranslation();

  useEffect(() => {
    mutate();
  }, []);

  return (
    <InfiniteScroll
      {...otherProps}
      query={getNotifications}
      itemsPath="current_user.notifications"
      renderItem={(notification, current_user) => (
        <Notification {...current_user} {...notification} />
      )}
      noResultsText={t('notifications.empty')}
    />
  );
}

NotificationsList.propTypes = {
  mutate: func.isRequired,
};

export default graphql(viewAllNotifications)(NotificationsList);
