import React from 'react';
import { func } from 'prop-types';
import {
  LANDING_BLOG_PLATFORM,
  LANDING_EBOOKS_PLATFORM,
  LANDING_FREELANCERS_MANAGEMENT,
  LANDING_INTELLIGENT_STAFFING,
  LANDING_PRICING,
  LANDING_SKILLS_MAPPING,
  LANDING_WORKLOAD_MANAGEMENT,
  LANDING_PLATFORM,
} from '@/constants/router';
import Section from '../Section';
import List from '../List';

const links = [
  {
    id: 'platform',
    label: 'footer.services.link.platform',
    href: LANDING_PLATFORM,
    target: '_blank',
  },
  {
    id: 'skills_mapping',
    label: 'footer.services.link.skills_mapping',
    href: LANDING_SKILLS_MAPPING,
    target: '_blank',
  },
  {
    id: 'intelligent_staffing',
    label: 'footer.services.link.intelligent_staffing',
    href: LANDING_INTELLIGENT_STAFFING,
    target: '_blank',
  },
  {
    id: 'workload_management',
    label: 'footer.services.link.workload_management',
    href: LANDING_WORKLOAD_MANAGEMENT,
    target: '_blank',
  },
  {
    id: 'freelancers_management',
    label: 'footer.services.link.freelancers_management',
    href: LANDING_FREELANCERS_MANAGEMENT,
    target: '_blank',
  },
  {
    id: 'pricing',
    label: 'footer.services.link.pricing',
    href: LANDING_PRICING,
    target: '_blank',
  },
  {
    id: 'blog_platform',
    label: 'footer.services.link.blog_platform',
    href: LANDING_BLOG_PLATFORM,
    target: '_blank',
  },
  {
    id: 'ebooks_platform',
    label: 'footer.services.link.ebooks_platform',
    href: LANDING_EBOOKS_PLATFORM,
    target: '_blank',
  },
];

function Platform({ t }) {
  return (
    <Section name="Services" title={t('footer.platform.title')}>
      <List items={links} t={t} />
    </Section>
  );
}

Platform.propTypes = {
  t: func.isRequired,
};

export default Platform;
