import { get } from 'lodash';
import axios from 'axios';
import i18n from '@/i18n';
import client from '@/helpers/apollo';
import config from '@/_config';
import { fileToAttachment } from '@/helpers/file';
import { isTokenExpired, handleCookie } from '@/helpers/auth';
import { formatCompany } from '@/selectors/postSignup';
import { getStoredUser, getCurrentUser } from '@/api/User/queries';
import { createCompany, updateUser } from '@/api/User/mutations';
import { createRecommendation } from '@/api/Company/Recommendation/mutations';
import { uploadAttachment } from '@/api/Attachment/mutations';
import { api, graphqlAccessToken } from '@/constants/environment';
import { handleRestErrors } from '@/helpers/error';
import { REQUESTED } from '@/constants/platformRecommendation';

export default {
  Query: {
    logoutUser: async (__, { token }) => {
      const { data } = await axios.get(`${api}/sign_out`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { error } = data;
      return { error, isLoggedOut: true };
    },
  },
  Mutation: {
    registerUser: async (__, { credentials }) => {
      const { data } = await axios
        .post(
          `//${api}/users/register`,
          {
            ...credentials,
          },
          {
            headers: {
              Authorization: `Bearer ${graphqlAccessToken}`,
            },
          }
        )
        .catch((error) => {
          handleRestErrors(get(error, 'response.data.errors'));
        });
      return data;
    },
    loginUser: async (__, { credentials }) => {
      try {
        const { data } = await axios.post(`${api}/sign_in`, credentials);

        const { token, scopes } = data;

        if (token && scopes) {
          handleCookie(token, scopes);
        }
        return { token };
      } catch (e) {
        const error = get(e, 'response.data.error', e.message);

        return { error };
      }
    },
    initUser: async (__, ___, { cache }) => {
      let currentUser = null;
      try {
        const state = cache.readQuery({
          query: getStoredUser,
          fetchPolicy: 'cache-first',
        });
        currentUser = state.currentUser; // eslint-disable-line prefer-destructuring
      } catch (e) {
        // Needed to prevent warning on /logout
      }

      if (isTokenExpired() || currentUser) return currentUser;

      const {
        data: { current_user },
      } = await client.query({
        query: getCurrentUser,
      });

      if (current_user.language_alpha2) {
        i18n.changeLanguage(current_user.language_alpha2);
      }

      await cache.writeData({ data: { currentUser: current_user } });

      return current_user;
    },
    registerCompany: async (__, { userKind, values }, { cache }) => {
      const {
        data: { registerUser: currentUser },
      } = await client.mutate({
        mutation: createCompany,
        variables: formatCompany(values, userKind),
      });
      if (
        config.company.showPlatformRecommendation &&
        get(values, 'company_platform_recommendation') === REQUESTED
      ) {
        await client.mutate({
          mutation: createRecommendation,
          variables: { company_id: get(currentUser, 'company.id') },
        });
      }

      cache.writeData({ data: { currentUser } });

      return currentUser;
    },
    updateUserAvatar: async (__, { original, updated }) => {
      let avatarId = null;
      const originalAvatarId = get(original, 'id');

      if (updated) {
        const attachment = fileToAttachment(updated);
        const {
          data: { uploadAttachment: uploadRes },
        } = await client.mutate({
          mutation: uploadAttachment,
          variables: {
            attachment,
            kind: 'avatar',
          },
        });

        avatarId = uploadRes.id;
      } else {
        avatarId = originalAvatarId;
      }

      const {
        data: { updateUser: updatedUser },
      } = await client.mutate({
        mutation: updateUser,
        variables: {
          original_avatar_id: originalAvatarId,
          avatar_id: avatarId,
        },
      });

      return updatedUser;
    },
  },
};
