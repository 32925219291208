import React from 'react';
import pp from 'prop-types';
import { Field } from 'redux-form';
import { picture } from '@/helpers/validation';
import { ReactComponent as Camera } from '@/images/camera.svg';
import { RfImage } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';

const PictureModal = ({ t, createAttachment, ...props }) => (
  <ProfileModal
    title={t('company.modal.picture.title')}
    form="company_picture"
    formatValues={({ picture: companyPicture, ...values }) => ({
      ...values,
      ...companyPicture,
    })}
    {...props}
  >
    <FormSection>
      <div className="d-f jc-c">
        <Field
          component={RfImage}
          id="picture"
          name="picture"
          edit
          icon={Camera}
          text={t('company.modal.picture.dropzone.text')}
          infoText={t('company.modal.picture.dropzone.infoText')}
          rejectText={t('company.modal.picture.dropzone.rejectText')}
          newText={t('company.modal.picture.dropzone.newText')}
          attachmentKind="original_logo"
          validate={[picture()]}
        />
      </div>
    </FormSection>
  </ProfileModal>
);

PictureModal.propTypes = {
  t: pp.func.isRequired,
  createAttachment: pp.func.isRequired,
};

export default PictureModal;
