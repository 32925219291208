import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { applyToMission } from '@/api/Mission/mutations';
import translate from '@/helpers/enhancers/translate';
import reduxForm from '@/helpers/enhancers/reduxForm';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import ApplyModal from './ApplyModal';

export default compose(
  withCurrentUser,
  graphql(applyToMission, { name: 'applyToMission' }),
  reduxForm({
    form: 'mission_apply',
    initialValues: {
      attachment: [],
    },
  }),
  connect((state) => ({
    formValues: getFormValues('mission_apply')(state),
  })),
  translate()
)(ApplyModal);
