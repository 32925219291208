import React from 'react';
import { objectOf, any, func } from 'prop-types';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import FileHeader from '@/scenes/Settings/components/Documents/modals/FileHeader';
import FileCard from '@/scenes/Settings/components/Documents/modals/FileCard';

import './styles.scss';

const ShowFileModal = ({ document, close }) => {
  const { t } = useTranslation();
  const files = get(document, 'document_files', []);
  const postSignupDisplay = get(document, 'post_signup_display');

  return (
    <Modal
      name="show_file"
      close={close}
      onCancel={close}
      size="large"
      hideActions
    >
      <div className="ShowFile">
        <FileHeader document={document} />
        <div className="ShowFile__header">
          <div>{t('settings.documents.file.created_at.label')}</div>
          <div>{t('settings.documents.file.status')}</div>
          <div>
            {postSignupDisplay
              ? t('settings.documents.bar.file.organization.label')
              : t('settings.documents.file.organization.label')}
          </div>
        </div>
        {files.map((file) => <FileCard key={file.id} file={file} />)}
      </div>
    </Modal>
  );
};

ShowFileModal.propTypes = {
  document: objectOf(any).isRequired,
  close: func.isRequired,
};

export default ShowFileModal;
