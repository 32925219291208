import { compose, mapProps } from 'recompose';
import { graphql } from 'react-apollo';
import { getStoredUser } from '@/api/User/queries';

export default compose(
  graphql(getStoredUser, { options: { fetchPolicy: 'cache-first' } }),
  mapProps(({ data: { currentUser }, ...props }) => ({ ...props, currentUser }))
);

// TODO
// [PERFORM ISSUE], refactor withCurrentUser HOC with customize variable to avoid useless attributes fetching from User Fragment
// Those commented lines may constitute a solution
// const withCurrentUser = (withVariables = {}) =>
//   compose(
//     graphql(getStoredUser, { options: {
//       fetchPolicy: 'cache-first',
//         variables: {
//          ...withVariables
//         },
//       }
//     }),
//     mapProps(({ data: { currentUser }, ...props }) => ({ ...props, currentUser }))
//   );
