import React from 'react';
import pp from 'prop-types';
import Modal from '@/components/Modal';
import { ReactComponent as WarningIcon } from '@/images/c-warning.svg';
import { ReactComponent as ErrorIcon } from '@/images/c-error.svg';
import { ReactComponent as CheckIcon } from '@/images/c-check.svg';
import { ReactComponent as InfoIcon } from '@/images/c-info.svg';
import './styles.scss';

const iconTypes = {
  info: InfoIcon,
  confirm: CheckIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

const Alert = ({ className, type, text, ...props }) => (
  <Modal {...props} name="Alert" size="small">
    {type in iconTypes && iconTypes[type]({ className: 'Alert__icon' })}
    <p className="Alert__text">{text}</p>
  </Modal>
);

Alert.propTypes = {
  type: pp.oneOf(Object.keys(iconTypes)),
  text: pp.string,
};

Alert.defaultProps = {
  type: null,
  text: '',
};

export default Alert;
