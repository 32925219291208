import React, { useContext } from 'react';
import { func, objectOf, any } from 'prop-types';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import { getMyApplicationsRoute } from '@/helpers/router';
import Button from '@/components/Button';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import config from '@/_config';

function CustomInformationModal({ close, history }) {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);
  const user = new User(userContext);

  return (
    <Modal
      name="CustomInformation"
      className="CustomInformationModal"
      close={close}
      onOk={() => {
        close();
        history.push(getMyApplicationsRoute());
      }}
      onCancel={close}
      title={t('mission.modal.applied.title')}
      customValidateButton={
        <Button
          variants={['link']}
          onClick={() => {
            close();
            history.push(getMyApplicationsRoute());
          }}
        >
          {t('mission.modal.applied.ok')}
        </Button>
      }
    >
      <p>{t(`mission.modal.applied.pricing`)}</p>
      <div className="d-f jc-c w-100">
        <a
          href={get(
            process.env,
            `REACT_APP_PAYMENT_LINK_${user
              .get('kind')
              .toUpperCase()}_${config.name.toUpperCase()}`
          )}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button className="m-v-m">
            {t('myMissionBoard.info.landing_link')}
          </Button>
        </a>
      </div>
      <p>{t(`mission.modal.applied.pricing2`)}</p>
    </Modal>
  );
}

CustomInformationModal.propTypes = {
  history: objectOf(any).isRequired,
  close: func.isRequired,
};

export default CustomInformationModal;
