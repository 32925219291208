import React from 'react';
import { node, string, any, objectOf, oneOfType } from 'prop-types';
import cx from 'classnames';
import { omit, isString } from 'lodash';
import { NavLink } from 'react-router-dom';
import './styles.scss';

class Link extends React.Component {
  parseTo = (to) => {
    const href = isString(to) ? to : to.pathname;
    const parser = document.createElement('a');
    parser.href = href;
    return parser;
  };

  isInternal = (toLocation) => window.location.host === toLocation.host;

  render() {
    const { to, children, className, ...otherProps } = this.props;
    const toLocation = this.parseTo(to);
    const isInternal = this.isInternal(toLocation);
    const classNames = cx('Link', className);
    if (isInternal) {
      return (
        <NavLink to={to} className={classNames} {...otherProps}>
          {children}
        </NavLink>
      );
    }

    return (
      <a
        href={toLocation}
        className={classNames}
        {...omit(otherProps, ['exact', 'activeClassName'])}
      >
        {children}
      </a>
    );
  }
}

Link.propTypes = {
  children: node.isRequired,
  className: string,
  to: oneOfType([string, objectOf(any)]).isRequired,
};

Link.defaultProps = {
  className: null,
};

export default Link;
