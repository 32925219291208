import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import { getCategories } from '@/api/Category/queries.gql';
import renderWhileLoading from '@/helpers/enhancers/renderWhileLoading';
import translate from '@/helpers/enhancers/translate';
import CategoriesSection from '@/components/CategoriesSection';
import { multipleMaxLengthValidator } from '@/helpers/company';
import { SUB_CATEGORIES } from '@/constants/fields/subCategory.js';
import config from '@/_config';

export default compose(
  withProps({
    baseKey: 'company.modal.categories',
    baseName: '',
    validate: multipleMaxLengthValidator([
      {
        maxLength: config.validation.expertProfile.maxCategoriesLength,
        msg: 'categories.error.too_many',
        fieldName: 'category_ids',
      },
      {
        maxLength: config.validation.expertProfile.maxSubCategoriesLength,
        msg: 'sub_categories.error.too_many',
        fieldName: SUB_CATEGORIES,
      },
    ]),
    required: true,
  }),
  graphql(getCategories),
  renderWhileLoading(),
  translate()
)(CategoriesSection);
