import React from 'react';
import ProfileModal from '../../ProfileModal';
import { formatTags } from '@/components/Tags/helpers/handler';
import { PROFILE_INDIVIDUAL_CERTIFICATION_SKILLS } from '@/constants/forms';
import IndividualCertificationSkillsSection from '@/components/Sections/IndividualCertificationSkillsSection';
import { INDIVIDUAL_CERTIFICATION } from '@/constants/tagKind';
import config from '@/_config';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

const IndividualCertificationSkillsModal = ({ contractTypes, ...props }) => {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal.individual_certification_skills.title')}
      form={PROFILE_INDIVIDUAL_CERTIFICATION_SKILLS}
      formatValues={({ individual_certification_skills }) => ({
        individual_certification_skills: formatTags(
          individual_certification_skills
        ),
      })}
      initialValues={{
        individual_certification_skills: [],
      }}
      overlayClassName="Modal__overlay Individual__certification__skills__modal"
      {...props}
    >
      <div className="Individual__certification__skills__modal">
        <IndividualCertificationSkillsSection
          mode={props.mode}
          inlineDisplay
          kind={INDIVIDUAL_CERTIFICATION}
          withCreatable={false}
          withRating={false}
          title="company.modal.form.individual_certification_skills.title"
          contractTypes={contractTypes}
          minSkillsLength={
            config.validation.expertProfile
              .minIndividualCertificationSkillsLength
          }
        />
      </div>
    </ProfileModal>
  );
};

export default IndividualCertificationSkillsModal;
