import * as Sentry from '@sentry/browser';
import config from '@/_config';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
});

Sentry.configureScope((scope) => {
  scope.setTag('tenant', config.name);
});

export default Sentry;
