import React from 'react';
import SignInLinkedinLogo from '@/images/linkedin-logo.png';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';
import { getLoginRoute } from '@/helpers/router';
import useReactRouter from 'use-react-router';
import { api } from '@/constants/environment';

function SignInLinkedinButton() {
  const { t } = useTranslation();
  const { history } = useReactRouter();

  return (
    <div className="SignupForm__linkedin-button">
      <button
        type="button"
        className="d-b text-center"
        onClick={() => {
          const { state = {} } = history.location;
          const { from, search } = state;

          const urlOrigin = window.location.origin;
          const failureUrl = `${urlOrigin}${getLoginRoute()}`;
          const successUrl = from
            ? `${urlOrigin}${from}${search && search}`
            : `${urlOrigin}${getLoginRoute()}`;

          window.location = `${api}/auth/linkedin?successUrl=${successUrl}&failureUrl=${failureUrl}`;
        }}
      >
        <div className="SignupForm__linkedin-logo">
          <img src={SignInLinkedinLogo} alt="SignInLinkedinLogo" />
        </div>
        <div className="SignupForm__linkedin-label">
          {t('signin.linkedin.button.label')}
        </div>
      </button>
    </div>
  );
}

export default SignInLinkedinButton;
