import React from 'react';
import { propType } from 'graphql-anywhere';
import { string, shape, arrayOf } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import priceFormat from '@/helpers/priceFormat';

function MilestoneCharges({ charges, primary, classes, modifiedChargesId }) {
  const { t } = useTranslation();

  if (charges.length === 0) return null;

  return (
    <>
      {charges.map((charge) => {
        const withModification = modifiedChargesId.includes(charge.id);
        const spanClassname = withModification ? 'td-lt' : '';
        if (!charge.title || !charge.quantity || !charge.cost_per_unit)
          return null;
        return (
          <div className={`${classes.root} ${primary}`} key={charge.id}>
            <span className={`${classes.title} ${spanClassname}`}>
              <span>{charge.title}</span>
            </span>
            <span className={spanClassname}>
              {t(
                `proposal.modal.quote.pricing.unit_${
                  charge.unit ? charge.unit : 'units'
                }`
              )}
            </span>
            <span className={spanClassname}>
              {priceFormat(charge.quantity, { minDigits: 2, maxDigits: 2 })}
            </span>
            <span className={spanClassname}>
              {priceFormat(charge.cost_per_unit, {
                minDigits: 2,
                maxDigits: 2,
              })}
              <span className="p-l-xxs">{t('currency.symbol')}</span>
            </span>
            <span className={spanClassname}>
              {priceFormat(charge.cost_per_unit * charge.quantity, {
                minDigits: 2,
                maxDigits: 2,
              })}
              <span className="p-l-xxs">{t('currency.symbol')}</span>
            </span>
            {/* These two spans (even empty) are important to keep the layout 
          similar to the MilestoneInformation */}
            <span />
            <span className="MilestoneCard__toggle-placeholder" />
          </div>
        );
      })}
    </>
  );
}

MilestoneCharges.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  charges: arrayOf({}).isRequired,
  primary: string,
  classes: shape({
    title: string,
  }),
  modifiedChargesId: arrayOf(string),
};
MilestoneCharges.defaultProps = {
  primary: 'primary',
  classes: { title: null },
  modifiedChargesId: [],
};

export default MilestoneCharges;
