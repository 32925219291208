import React, { useContext } from 'react';
import { get } from 'lodash';
import { getSettingsDocumentsRoute } from '@/helpers/router';
import { ReactComponent as DocumentIcon } from '@/images/file-text.svg';
import Badge from '@/components/Badge';
import Tooltip from '@/components/Tooltip';
import HeaderLink from './Link';
import CountersContext from '@/contexts/CountersContext';

function Documents() {
  const countersContext = useContext(CountersContext);
  return (
    <HeaderLink to={getSettingsDocumentsRoute()} exact={false}>
      <Tooltip title="header.nav.documents.tooltip.title">
        <Badge
          count={get(
            countersContext,
            'documents_companies_not_validated_count',
            0
          )}
        >
          <DocumentIcon />
        </Badge>
      </Tooltip>
    </HeaderLink>
  );
}

export default Documents;
