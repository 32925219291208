import React from 'react';
import cx from 'classnames';
import { bool, string, oneOfType, func } from 'prop-types';
import Choice from '../index';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

const BaseChoice = ({
  compact,
  label,
  icon: Icon,
  className,
  innerRef,
  title,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Choice {...props}>
      {({ checked, disabled, focused, readOnly, toggle }) => {
        const classes = cx(
          'BaseChoice',
          {
            'BaseChoice--compact': compact,
            'BaseChoice--checked': checked,
            'BaseChoice--focus': focused,
            'BaseChoice--disabled': disabled,
            'BaseChoice--readOnly': readOnly,
          },
          className
        );
        return (
          <div
            className={classes}
            onClick={toggle}
            role="button"
            tabIndex="-1"
            ref={innerRef}
          >
            {Icon &&
              (Icon instanceof Function ? (
                <Icon />
              ) : (
                <img src={Icon} alt={label} />
              ))}
            {title && <h2 className="m-b-m fw-bold primary">{t(title)}</h2>}
            <span className="ha ow-bw">{label}</span>
          </div>
        );
      }}
    </Choice>
  );
};

BaseChoice.propTypes = {
  compact: bool,
  label: string.isRequired,
  icon: oneOfType([string, func]),
  className: string,
  title: string,
};

BaseChoice.defaultProps = {
  compact: false,
  icon: null,
  className: '',
  title: null,
};

export default BaseChoice;
