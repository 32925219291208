import uuid from 'uuid/v4';

export const fileToAttachment = (file) => ({
  id: null,
  file_uuid: uuid(),
  name: file.name,
  read_url: file.preview,
  download_url: null,
  mime_type: file.type,
  size: file.size,
  updated_at: file.lastModifiedDate,
  file,
});

export const createDownloadElementAndClick = (fileURL, fileName) => {
  const anchorElement = window.document.createElement('a');
  anchorElement.href = fileURL;
  anchorElement.target = '_blank';
  anchorElement.download = fileName;

  const event = window.document.createEvent('MouseEvents');

  event.initEvent('click', true, false);
  anchorElement.dispatchEvent(event);
};

export const getBloBFile = (file) => {
  const byteCharacters = atob(file);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'application/pdf;base64' });
};
