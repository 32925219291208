import { get } from 'lodash';
import {
  DISTANCE,
  LOCALIZATION,
  LOCATION,
} from '@/components/Matching/Filters/presets';

export function getLocalizationFilter(state) {
  const presets = get(state, 'matching.filters.presets', []);
  const items = get(
    presets.find((preset) => preset.name === LOCALIZATION),
    'items',
    []
  );
  return {
    location: get(items.find(({ key }) => key === LOCATION), 'meta'),
    distance: get(items.find(({ key }) => key === DISTANCE), 'value'),
  };
}

export function getFilterPresets(state) {
  return get(state, 'matching.filters.presets', []) || [];
}

export function getQueryParameters(state) {
  return get(state, 'matching.queryParameters', {}) || {};
}

export function getMatchingResultCount(state) {
  return get(state, 'matching.count');
}

export function getResetParameters(state) {
  return get(state, 'matching.reset');
}

export function getMatchingResults(state) {
  return get(state, 'matching.results', []) || [];
}

export function displayFilters(state) {
  return get(state, 'matching.displayFilters');
}
