import React from 'react';
import { get } from 'lodash';
import { node, func } from 'prop-types';
import { propType } from 'graphql-anywhere';
import useTranslation from '@/hooks/useTranslation';
import documentFragment from '@/api/Document/fragment';
import Tag from '@/components/Tag';
import Button from '@/components/Button';
import { ARCHIVED } from '@/constants/document';
import './styles.scss';

function DocumentContent({ document, children, openModal, closeModal }) {
  const { t } = useTranslation();
  const status = get(document, 'status', 'pending');
  const kindNameDocument = get(document, 'kind.name');

  return (
    <section className="Document__content" key={document.id}>
      <div className="Document__content__name">
        {/* The first span is only displayed in mobile mode */}
        <span>{t('settings.documents.name')}</span>
        <span>{document.name}</span>
      </div>
      <div className="Document__content__categorie">
        <span>{t('settings.documents.kind_name')}</span>
        {kindNameDocument && <Tag>{kindNameDocument}</Tag>}
      </div>
      <div className="Document__content__status">
        <span>{t('settings.documents.status')}</span>
        <span className={`Documents__status Documents__status--${status}`}>
          {t(`settings.document.status.${status}`)}
        </span>
      </div>
      <div className="Document__content__add">
        <Button
          variants={['small', 'primary']}
          onClick={() => openModal('add_file', { document })}
          close={closeModal()}
          disabled={get(document, 'status') === ARCHIVED}
        >
          {t('settings.documents.button.add_file')}
        </Button>
      </div>
      <div className="Document__content__see">
        {get(document, 'document_files', []).length > 0 ? (
          <Button
            variants={['small', 'outline']}
            onClick={() => openModal('show_file', { document })}
            close={closeModal()}
          >
            {t('settings.documents.button.see_file')}
          </Button>
        ) : (
          <>{t('settings.documents.empty_files')}</>
        )}
      </div>
      {children}
    </section>
  );
}

DocumentContent.propTypes = {
  document: propType(documentFragment),
  children: node,
  openModal: func.isRequired,
  closeModal: func.isRequired,
};

DocumentContent.defaultProps = {
  document: null,
  children: null,
};

export default DocumentContent;
