import React from 'react';
import pp from 'prop-types';
import client from '@/helpers/apollo';
import { picture } from '@/helpers/validation';
import { ReactComponent as Camera } from '@/images/camera.svg';
import { RequiredField, RfImage } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';

function AvatarModal({ t, close, handleSubmit, submitting, updateUserAvatar }) {
  async function onSubmit({ avatar, ...values }) {
    const {
      data: { updateUserAvatar: updatedUser },
    } = await updateUserAvatar({ variables: { ...values, ...avatar } });
    client.cache.writeData({ data: { currentUser: updatedUser } });
    close();
  }

  return (
    <Modal
      name="AccountAvatar"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('settings.account.modal.avatar.title')}
      okText={t('settings.account.modal.avatar.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection>
        <div className="d-f jc-c">
          <RequiredField
            component={RfImage}
            id="avatar"
            name="avatar"
            edit
            icon={Camera}
            text={t('settings.account.modal.avatar.dropzone.text')}
            infoText={t('settings.account.modal.avatar.dropzone.infoText')}
            rejectText={t('settings.account.modal.avatar.dropzone.rejectText')}
            newText={t('settings.account.modal.avatar.dropzone.newText')}
            attachmentKind="original_avatar"
            validate={[picture()]}
          />
        </div>
      </FormSection>
    </Modal>
  );
}

AvatarModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  updateUserAvatar: pp.func.isRequired,
};

export default AvatarModal;
