import React from 'react';
import { objectOf, any, arrayOf, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get, first } from 'lodash';
import { getMissionCompanyMatching } from '@/api/Mission/queries';
import FormSection from '@/components/FormSection';
import useTranslation from '@/hooks/useTranslation';
import HourlyRate from '@/components/HourlyRate';

const HourlyRateSection = ({
  data,
  formValues,
  company,
  mission,
  technicalAssistanceUnits,
}) => {
  const { t } = useTranslation();

  const simulatorParams = {
    expTime: get(company, 'exp_time'),
    categoryIds: get(company, 'categories', []).map((c) => get(c, 'id')),
    practiceCulture: get(company, 'practice_culture'),
    locationRegion: get(company, 'location.province', ''),
    languages: get(company, 'languages'),
    // Hourly_rate value has to come from the form
    // to handle dynamic logic in the HourlyRate component
    hourlyRate: get(formValues, 'cost_per_unit'),
    missionData: {
      workDays: get(mission, 'work_days'),
      workHours: get(mission, 'work_hours'),
      languages: get(mission, 'languages'),
      billing: get(mission, 'billings'),
      beginAt: get(mission, 'begin_at'),
      endAt: get(mission, 'end_at'),
      endMonths: get(mission, 'end_months'),
      // Get the matching score from the matched_companies object
      matchingScore: first(
        get(data, 'mission.matched_companies.items', []).map((c) =>
          get(c, '_score')
        )
      ),
    },
  };

  return (
    <FormSection title={t('proposal.modal.quote.cost_per_hour.title')}>
      <HourlyRate
        name="cost_per_unit"
        simulatorParams={simulatorParams}
        withInformationText={false}
        technicalAssistanceUnits={technicalAssistanceUnits}
        displayUnit
      />
    </FormSection>
  );
};

HourlyRateSection.propTypes = {
  data: propType(getMissionCompanyMatching).isRequired,
  formValues: objectOf(any).isRequired,
  mission: objectOf(any).isRequired,
  company: objectOf(any).isRequired,
  technicalAssistanceUnits: arrayOf(string),
};

HourlyRateSection.defaultProps = {
  technicalAssistanceUnits: [],
};

export default HourlyRateSection;
