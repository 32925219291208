import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import AvatarEditor from 'react-avatar-editor';
import { FileInput, FileDropzone } from '@/components/Fields/FileField';
import { Slider } from '@/components/Fields/SliderField';
import './styles.scss';

class ImageInput extends React.Component {
  state = {
    file: _.get(this.props.value, 'original'),
    scale: 1,
  };

  onFileChange = (files) => {
    if (!files.length) return;
    const file = files[0];
    this.setState({ file, scale: 1 });
    this.props.onChange({ original: file, updated: null });
  };

  onScaleChange = ([scale]) => {
    this.setState({ scale }, this.onPositionChange);
  };

  onPositionChange = _.throttle(() => {
    if (!this.editor) return;

    const canvas = this.editor.getImage();
    canvas.toBlob((blob) => {
      blob.name = this.state.file.name; // eslint-disable-line
      blob.preview = window.URL.createObjectURL(blob); // eslint-disable-line
      blob.lastModifiedDate = new Date(); // eslint-disable-line
      this.props.onChange({ original: this.state.file, updated: blob });
    });
  }, 1000);

  setEditorRef = (editor) => {
    this.editor = editor;
  };

  renderPreview = () => {
    const { file, scale } = this.state;
    const { edit, newText, ...otherProps } = this.props;
    const fileInputProps = _.omit(
      otherProps,
      ['icon', 'text', 'infoText', 'rejectText', 'error', 'errorText'], // FileDropzone props,
      ['value']
    );
    return (
      <div className="ImageInput">
        {edit ? (
          <AvatarEditor
            image={file.read_url}
            width={200}
            height={200}
            border={24}
            scale={scale}
            ref={this.setEditorRef}
            onPositionChange={this.onPositionChange}
            crossOrigin="anonymous"
          />
        ) : (
          <img
            src={file.read_url}
            alt="Uploaded file"
            className="ImageInput__preview"
          />
        )}

        <FileInput
          {...fileInputProps}
          onChange={this.onFileChange}
          className="ImageInput__button"
        >
          {newText}
        </FileInput>

        {edit && (
          <Slider
            step={0.1}
            domain={[1, 3]}
            value={[scale]}
            onUpdate={this.onScaleChange}
            className="ImageInput__slider"
          />
        )}
      </div>
    );
  };

  render() {
    const { edit, newText, ...otherProps } = this.props;
    const { file } = this.state;

    if (file && file.read_url) {
      return this.renderPreview();
    }

    return (
      <div className="ImageInput">
        <FileDropzone
          {...otherProps}
          onChange={this.onFileChange}
          multiple={false}
        />
      </div>
    );
  }
}

ImageInput.propTypes = {
  id: pp.string.isRequired,
  value: pp.shape({
    original: pp.oneOfType([pp.objectOf(pp.any), pp.string]),
    updated: pp.objectOf(pp.any),
  }),
  onChange: pp.func,
  accept: pp.string,
  newText: pp.string,
  edit: pp.bool,
};

ImageInput.defaultProps = {
  value: { original: null, updated: null },
  onChange: _.noop,
  accept: 'image/*',
  newText: null,
  edit: false,
};

export default ImageInput;
