import _ from 'lodash';

export default function throttleAction(action, wait, options) {
  const throttled = _.throttle(
    (dispatch, actionArgs) => dispatch(action(...actionArgs)),
    wait,
    options
  );

  const thunk = (...actionArgs) => (dispatch) =>
    throttled(dispatch, actionArgs);

  thunk.cancel = throttled.cancel;
  thunk.flush = throttled.flush;

  return thunk;
}
