import React from 'react';
import pp from 'prop-types';
import { Link } from 'react-router-dom';
import { getLoginRoute } from '@/helpers/router';

function Login({ t, className }) {
  return (
    <Link to={getLoginRoute()} className={className}>
      {t('header.nav.login')}
    </Link>
  );
}

Login.propTypes = {
  t: pp.func.isRequired,
  className: pp.string,
};

Login.defaultProps = {
  className: null,
};

export default Login;
