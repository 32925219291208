import React, { useState, useEffect, useCallback } from 'react';
import { objectOf, any, arrayOf, string, bool } from 'prop-types';
import { get, isNull } from 'lodash';
import { Trans } from 'react-i18next';
import { propType } from 'graphql-anywhere';
import categoryFragment from '@/api/Category/fragment';
import { RequiredField, RfText, RfSelect } from '@/containers/ReduxForm';
import { minOrMaxValue } from '@/helpers/validation';
import {
  hourlyRateSimulator,
  BASE_HOURLY_RATE,
} from '@/helpers/hourlyRateSimulator';
import useTranslation from '@/hooks/useTranslation';
import Tag from '@/components/Tag';
import config from '@/_config';
import {
  clientKeysVariables,
  expertKeysVariables,
  missionKeysVariables,
} from '@/helpers/contextKeys';
import './styles.scss';

const HourlyRate = ({
  simulatorParams,
  data,
  name,
  withInformationText,
  label,
  technicalAssistanceUnits,
  displayUnit,
  kind,
}) => {
  const { t } = useTranslation();
  const [displayAlert, setDisplayAlert] = useState(false);
  const expertKind = kind === 'expert';

  const estimatedHourlyRate = !isNull(simulatorParams)
    ? hourlyRateSimulator({
        ...simulatorParams,
        categories: get(data, 'categories'),
      })
    : null;

  const expertEstimatedHourlyRate = estimatedHourlyRate * 1.75;

  const teamsOrExpertEstimatedHourlyRate = expertKind
    ? expertEstimatedHourlyRate
    : estimatedHourlyRate;

  const triggerAlert = useCallback(
    (currentValue) => {
      setDisplayAlert(currentValue > teamsOrExpertEstimatedHourlyRate);
    },
    [teamsOrExpertEstimatedHourlyRate]
  );

  useEffect(
    () => {
      if (!isNull(simulatorParams))
        triggerAlert(get(simulatorParams, 'hourlyRate'));
    },
    [simulatorParams, triggerAlert]
  );

  return (
    <div className="HourlyRate">
      <div>
        {withInformationText && (
          <p>{t('postSignup.remuneration.hourly_rate.label')}</p>
        )}
        {!isNull(simulatorParams) && (
          <p>
            <Trans
              i18nKey={label}
              values={{
                estimated_hourly_rate: teamsOrExpertEstimatedHourlyRate,
                currency: t('currency.symbol'),
                ...clientKeysVariables(t),
                ...expertKeysVariables(t),
                ...missionKeysVariables(t),
              }}
              // eslint-disable-next-line
              components={{ span: <span /> }}
            />
          </p>
        )}
        <div className="grid">
          <div className="grid__item one-half mobileL--one-whole">
            <RequiredField
              component={RfText}
              type="number"
              className="Signup__experience"
              id={name}
              name={name}
              max={config.company.hourlyRate.max}
              min={config.company.hourlyRate.min}
              step={5}
              placeholder={t(
                'company.modal.apply.remuneration.hourly_rate.placeholder',
                {
                  currency: t('currency.symbol'),
                  value:
                    (expertKind
                      ? expertEstimatedHourlyRate
                      : estimatedHourlyRate) || BASE_HOURLY_RATE,
                }
              )}
              validate={[
                minOrMaxValue({
                  max: config.company.hourlyRate.max,
                  min: config.company.hourlyRate.min,
                }),
              ]}
              onChange={(e) => {
                if (!isNull(simulatorParams))
                  triggerAlert(e.currentTarget.value);
              }}
            />
          </div>
          {displayUnit && (
            <div className="grid__item one-half mobileL--one-whole">
              <RequiredField
                id="unit"
                name="unit"
                component={RfSelect}
                options={technicalAssistanceUnits.map((unit, index) => ({
                  id: index,
                  value: unit,
                  label: t(`proposal.modal.quote.pricing.unit_${unit}`),
                }))}
                value="hours"
                placeholder={t(
                  'proposal.section.quote.pricing.unit.placeholder'
                )}
              />
            </div>
          )}
          {displayAlert && (
            <div className="grid__item three-quarter">
              <Tag variants={['alert']}>
                {t('company.remuneration.estimated_hourly_rate.alert')}
              </Tag>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

HourlyRate.propTypes = {
  simulatorParams: objectOf(any),
  data: arrayOf(propType(categoryFragment)).isRequired,
  name: string.isRequired,
  withInformationText: bool,
  label: string,
  displayUnit: bool,
  technicalAssistanceUnits: arrayOf(string),
  kind: string,
};

HourlyRate.defaultProps = {
  simulatorParams: null,
  withInformationText: true,
  label: 'company.remuneration.estimated_hourly_rate',
  displayUnit: false,
  technicalAssistanceUnits: [],
  kind: null,
};

export default HourlyRate;
