import { generatePath } from 'react-router-dom';
import * as routeConstants from '@/constants/router';
import { generateQuery } from '@/helpers/query';

export const getHomeRoute = () => routeConstants.HOME;

export const getLoginRoute = () => routeConstants.LOGIN;

export const getResendEmailConfirmationRoute = () =>
  routeConstants.RESEND_EMAIL_CONFIRMATION;

export const getForgotPasswordRoute = () => routeConstants.FORGOT_PASSWORD;

export const getResetPasswordRoute = () => routeConstants.RESET_PASSWORD;

export const getLogoutRoute = () => routeConstants.LOGOUT;

export const getSignupRoute = () => routeConstants.SIGNUP;

export const getAcceptInvitationRoute = () => routeConstants.ACCEPT_INVITATION;

export const getPostSignupRoute = () => routeConstants.POST_SIGNUP;

export const getPostSignupCvParsingRoute = () =>
  routeConstants.POST_SIGNUP_CV_PARSING;

export const getProfileRoute = (edit) => ({
  pathname: routeConstants.PROFILE,
  search: edit ? `?edit=${edit}` : null,
});

export const getBookmarksRoute = () => routeConstants.BOOKMARKS;

export const getClientPoolRoute = () => routeConstants.CLIENT_POOL;

export const getClientPoolsRoute = () => routeConstants.CLIENT_POOLS;

export const getBookmarkRoute = (id) =>
  generatePath(routeConstants.BOOKMARK, { id });

export const getSettingsRoute = (hash) => ({
  pathname: routeConstants.SETTINGS,
  hash: hash ? `#${hash}` : null,
});

export const getSettingsDocumentsRoute = () =>
  routeConstants.SETTINGS_DOCUMENTS;

export const getHelpRoute = () => routeConstants.HELP;

export const getMissionsMatchingRoute = () => routeConstants.MISSIONS_MATCHING;

export const getNewMissionRoute = (template) =>
  template
    ? {
        pathname: routeConstants.NEW_MISSION,
        search: `?${generateQuery({ template })}`,
      }
    : routeConstants.NEW_MISSION;

export const getMissionRoute = (id) =>
  generatePath(routeConstants.MISSION, { id });

export const getNewProposalRoute = (id) =>
  generatePath(routeConstants.NEW_PROPOSAL, { id });

export const getProposalsRoute = () => routeConstants.PROPOSALS;

export const getProposalRoute = (id, hash) => ({
  pathname: generatePath(routeConstants.PROPOSAL, { id }),
  hash: hash ? `#${hash}` : null,
});

export const getProposalsFromMissionRoute = (id) => ({
  pathname: routeConstants.PROPOSALS,
  search: id ? `?mission=${id}` : null,
});

export const getInvoicesRoute = () => routeConstants.INVOICES;

export const getContractsRoute = () => routeConstants.CONTRACTS;

export const getContractRoute = (id) =>
  generatePath(routeConstants.CONTRACT, { id });

export const getClientsRoute = () => routeConstants.CLIENTS;

export const getClientRoute = (id) =>
  generatePath(routeConstants.CLIENT, { id });

export const getExpertsRoute = () => routeConstants.EXPERTS;

export const getMissionsRoute = () => routeConstants.MISSIONS;

export const getExpertRoute = (id) =>
  generatePath(routeConstants.EXPERT, { id });

export const getCompanyRoute = (id, kind) =>
  ({
    client: getClientRoute(id),
    expert: getExpertRoute(id),
    mixed: getExpertRoute(id),
  }[kind]);

export const getMyMissionsRoute = () => routeConstants.MY_MISSIONS;

export const getMyMissionBoardRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_BOARD, { id });

export const getMyMissionProposalsRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_PROPOSALS, { id });

export const getMyMissionMatchesRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_MATCHES, { id });

export const getMyMissionInvitesRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_INVITES, { id });

export const getMyMissionEditRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_EDIT, { id });

export const getMyApplicationsRoute = (hash) => ({
  pathname: routeConstants.MY_APPLICATIONS,
  hash: hash ? `#${hash}` : null,
});

export const getConversationsRoute = () => routeConstants.CONVERSATIONS;

export const getConversationRoute = (id, meta) =>
  generatePath(`${routeConstants.CONVERSATION}/:meta?`, { id, meta });

export const getWalletRoute = () => routeConstants.WALLET;

export const getNotificationsRoute = () => routeConstants.NOTIFICATIONS;

export const getNdaRoute = () => routeConstants.NDA;

export const getMyMissionNdaRoute = (id) =>
  generatePath(routeConstants.MY_MISSION_NDA, { id });
