import React from 'react';
import pp from 'prop-types';
import { Mutation } from 'react-apollo';
import { archiveProposal } from '@/api/Proposal/mutations';
import { getProposalsRoute } from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';
import Alert from '@/components/Alert';

function ArchiveModal({ close, history, proposalId }) {
  const { t } = useTranslation();
  return (
    <Mutation mutation={archiveProposal} variables={{ id: proposalId }}>
      {(mutate, { loading }) => (
        <Alert
          type="confirm"
          close={close}
          onOk={async () => {
            await mutate();
            close();
            history.push(getProposalsRoute());
          }}
          onCancel={close}
          text={t('proposal.modal.archive.text')}
          disableActions={loading}
          closable={!loading}
        />
      )}
    </Mutation>
  );
}

ArchiveModal.propTypes = {
  close: pp.func.isRequired,
  history: pp.objectOf(pp.any).isRequired,
  proposalId: pp.string.isRequired,
};

export default ArchiveModal;
