import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import Dropdown from '@/components/Dropdown';
import Toggle from './Toggle';
import Clear from './Clear';
import { getAssetImagePath, getFolderName } from '@/helpers/assets';
import './styles.scss';

class Select extends React.PureComponent {
  state = {
    inputValue: '',
  };

  textInput = React.createRef();

  handleSelect = (value, utils) => {
    if (!this.props.multi) {
      utils.reset();
    }
    if (this.props.onSelect) this.props.onSelect(value, utils);
  };

  onInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleWrapperClick = () => {
    if (this.textInput && this.textInput.current) {
      this.textInput.current.focus();
    }
  };

  handleStateChange = (changes, downshiftStateAndHelpers) => {
    if ('isOpen' in changes) {
      if (this.props.searchable) return;
      if (changes.isOpen) {
        this.props.onFocus();
      } else {
        this.props.onBlur();
      }
    }
    if (!downshiftStateAndHelpers.isOpen) {
      this.setState({ inputValue: '' });
    }
  };

  renderSelect = (childProps) => {
    const {
      placeholder,
      searchable,
      clearable,
      ClearComp,
      ToggleComp,
      onFocus,
      onBlur,
      disabled,
      multi,
      withIcon,
      fieldName,
    } = this.props;
    const {
      getInputProps,
      getToggleButtonProps,
      inputValue = '',
      selectedItem,
      selectedItems,
      clearSelection,
      itemToString,
    } = childProps;

    const { type, ...buttonProps } = getToggleButtonProps({
      onClick: this.handleWrapperClick,
      disabled,
    });
    const hasValue = multi ? selectedItems.length : selectedItem;
    const displayedValue = multi
      ? selectedItems.map((item, index) => (
          <span key={itemToString(item)} className="Select__item">
            {withIcon && (
              <img
                src={getAssetImagePath(
                  getFolderName(fieldName),
                  itemToString(item)
                )}
                alt="logo"
                className="Dropdown__menu-item--icon p-r-s p-l-s"
              />
            )}
            {itemToString(item)}
            {index < selectedItems.length - 1 && ', '}
          </span>
        ))
      : itemToString(selectedItem);

    return (
      <div className="Select__control">
        <div
          className="Select__value-wrapper"
          role="button"
          tabIndex={-1}
          {...buttonProps}
        >
          {searchable && (
            <input
              {...getInputProps({
                className: 'Select__input',
                ref: this.textInput,
                onChange: this.onInputChange,
                onFocus,
                onBlur,
                disabled,
              })}
            />
          )}
          {!inputValue &&
            (hasValue ? (
              <span className="Select__value">{displayedValue}</span>
            ) : (
              placeholder && (
                <span className="Select__placeholder">{placeholder}</span>
              )
            ))}
        </div>
        {clearable &&
          searchable &&
          selectedItem && <ClearComp onClick={clearSelection} />}
        {<ToggleComp {...buttonProps} />}
      </div>
    );
  };

  render() {
    const { inputValue } = this.state;
    const {
      className,
      onFocus,
      onBlur,
      value,
      disabled,
      ...props
    } = this.props;
    return (
      <Dropdown
        {...props}
        onStateChange={this.handleStateChange}
        onSelect={this.handleSelect}
        initialHighlightedIndex={0}
        selectedItem={value}
        defaultSelectedItems={value}
        inputValue={inputValue}
        className={cx('Select', className)}
      >
        {this.renderSelect}
      </Dropdown>
    );
  }
}

Select.propTypes = {
  placeholder: pp.string,
  className: pp.string,
  options: pp.arrayOf(pp.any),
  searchable: pp.bool,
  clearable: pp.bool,
  onChange: pp.func,
  onBlur: pp.func,
  onFocus: pp.func,
  name: pp.string,
  value: pp.oneOfType([pp.number, pp.string, pp.object, pp.arrayOf(pp.any)]),
  itemToString: pp.func,
  ToggleComp: pp.func,
  ClearComp: pp.func,
  filterOptions: pp.func,
  disabled: pp.bool,
};

Select.defaultProps = {
  className: '',
  options: [],
  searchable: false,
  clearable: false,
  onChange: _.noop,
  onBlur: _.noop,
  onFocus: _.noop,
  name: null,
  value: null,
  placeholder: '',
  itemToString: (item) => (item ? item.label : ''),
  ToggleComp: Toggle,
  ClearComp: Clear,
  filterOptions: (option, { inputValue }) =>
    !inputValue ||
    option.label.toLowerCase().includes(inputValue.toLowerCase()),
  disabled: false,
};

export default Select;
