import React, { useState, useCallback } from 'react';
import { ReactComponent as Preview } from '@/images/preview.svg';
import { ReactComponent as HidePreview } from '@/images/hide-preview.svg';
import { TextInput } from '@/components/Fields/TextField';
import Tooltip from '@/components/Tooltip';
import './styles.scss';

function PasswordInput(props) {
  const [type, setType] = useState('password');

  const handlePreviewClick = useCallback(
    () => {
      setType(type === 'password' ? 'input' : 'password');
    },
    [type]
  );

  return (
    <TextInput
      type={type}
      className="PasswordInput"
      data-private
      icon={
        <button
          type="button"
          onClick={handlePreviewClick}
          className="PasswordInput__preview"
        >
          <Tooltip
            title={
              type === 'password'
                ? 'fields.password.tooltip.show.title'
                : 'fields.password.tooltip.hide.title'
            }
          >
            {type === 'password' ? <Preview /> : <HidePreview />}
          </Tooltip>
        </button>
      }
      {...props}
    />
  );
}

export default PasswordInput;
