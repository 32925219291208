import {
  isContractTypeMissionOrFulltime,
  isContractTypeMissions,
} from '@/selectors/contractTypes';

// if contract types are different of missions and fulltime contract,
// do not display entity section
export default (contract_type) =>
  !(
    !isContractTypeMissions(contract_type) &&
    !isContractTypeMissionOrFulltime(contract_type)
  );
