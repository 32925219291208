import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { updateUserAvatar } from '@/api/User/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import translate from '@/helpers/enhancers/translate';
import AvatarModal from './AvatarModal';

export default compose(
  graphql(updateUserAvatar, { name: 'updateUserAvatar' }),
  reduxForm({ form: 'account_avatar' }),
  translate()
)(AvatarModal);
