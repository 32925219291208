import React from 'react';
import { string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import '../styles.scss';

const CompaniesListContents = ({ className }) => {
  const { t } = useTranslation();
  return (
    <ul className={`ShowCaseBar__listlink-dropdown ${className}`}>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/07/Kicklox-Offre-Hire-icon_GRADIENT.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/kicklox-hire/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.hire.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.hire.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/07/Kicklox-Offre-Freelancer-icon_GRADIENT.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/kicklox-freelancer/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.freelancer.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.freelancer.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/07/Kicklox-Offre-Open-icon_GRADIENT.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/open/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.open.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.open.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/07/Kicklox-Offre-Global-icon_GRADIENT.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/kicklox-global/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.global.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.global.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/07/Kicklox-Offre-Plarform-icon_GRADIENT.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/kicklox-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.platform.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.platform.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
    </ul>
  );
};

CompaniesListContents.propTypes = {
  className: string,
};

CompaniesListContents.defaultProps = {
  className: null,
};

export default CompaniesListContents;
