import React from 'react';
import {
  RequiredField,
  RfSlider,
  RfText,
  RfSelect,
} from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import HourlyRateSection from './HourlyRateSection';
import { arrayOf, func, string } from 'prop-types';
import config from '@/_config';
import { UNIT, HOUR } from '@/constants/costPerKind';

const getCostPerFormSection = (
  t,
  mission,
  company,
  chargeUnits,
  technicalAssistanceUnits
) => {
  switch (config.proposal.costPer) {
    case HOUR: {
      return (
        <HourlyRateSection
          mission={mission}
          company={company}
          technicalAssistanceUnits={technicalAssistanceUnits}
        />
      );
    }
    case UNIT: {
      return (
        <FormSection title={t('proposal.modal.quote.tjm.title')}>
          <div className="grid">
            <div className="grid__item one-half mobileL--one-whole">
              <RequiredField
                id="cost_per_unit"
                name="cost_per_unit"
                component={RfText}
                type="number"
                min={0}
                max={10000}
                label={t('proposal.modal.quote.tjm.label')}
                placeholder={t('proposal.modal.quote.tjm.placeholder')}
                icon={t('currency.symbol')}
              />
            </div>
            <div className="grid__item one-half mobileL--one-whole">
              <RequiredField
                id="unit"
                name="unit"
                component={RfSelect}
                options={technicalAssistanceUnits.map((unit, index) => ({
                  id: index,
                  value: unit,
                  label: t(`proposal.modal.quote.pricing.unit_${unit}`),
                }))}
                value="days"
                placeholder={t(
                  'proposal.section.quote.pricing.unit.placeholder'
                )}
              />
            </div>
          </div>
        </FormSection>
      );
    }
    default:
      return null;
  }
};

function ATSections({
  t,
  mission,
  company,
  chargeUnits,
  technicalAssistanceUnits,
}) {
  return (
    <>
      <FormSection title={t('proposal.modal.quote.workRate.title')}>
        <RequiredField
          id="quantity"
          name="quantity"
          component={RfSlider}
          domain={[config.proposal.workDays.min, config.proposal.workDays.max]}
          step={config.proposal.workDays.step}
          label={t('proposal.modal.quote.workRate.label')}
          valueKey={([value]) =>
            value === config.proposal.workDays.max
              ? 'proposal.modal.quote.workRate.fullTime.value'
              : 'proposal.modal.quote.workRate.partTime.value'
          }
        />
      </FormSection>
      {config.proposal.costPer === HOUR && (
        <FormSection title={t('proposal.modal.quote.workHours.title')}>
          <RequiredField
            id="work_hours"
            name="work_hours"
            component={RfSlider}
            domain={[
              config.proposal.workHours.min,
              config.proposal.workHours.max,
            ]}
            step={config.proposal.workHours.step}
            label={t('proposal.modal.quote.workHours.label')}
            valueKey={([value]) =>
              value === config.proposal.workHours.max
                ? 'proposal.modal.quote.workHours.max_and_more.value'
                : 'proposal.modal.quote.workHours.value'
            }
          />
        </FormSection>
      )}
      {getCostPerFormSection(
        t,
        mission,
        company,
        chargeUnits,
        technicalAssistanceUnits
      )}
    </>
  );
}

ATSections.propTypes = {
  t: func.isRequired,
  chargeUnits: arrayOf(string),
  technicalAssistanceUnits: arrayOf(string),
};

ATSections.defaultProps = {
  chargeUnits: [],
  technicalAssistanceUnits: [],
};

export default ATSections;
