import React from 'react';
import { arrayOf, string } from 'prop-types';
import { values } from 'lodash';
import {
  RequiredField,
  RfText,
  RfSelect,
  RfHelper,
} from '@/containers/ReduxForm';
import config from '@/_config';
import useTranslation from '@/hooks/useTranslation';
import {
  MISSIONS,
  MISSION_OR_FULLTIME,
  FULLTIME_CONTRACT,
  FIXED_TERM_CONTRACT,
} from '@/constants/contractTypes';
import { minOrMaxValue } from '@/helpers/validation';

export const DailyRateComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="p-t-m">
      <RequiredField
        component={RfText}
        type="number"
        id="daily_rate"
        name="daily_rate"
        max={config.company.dailyRate.max}
        min={config.company.dailyRate.min}
        step={10}
        label={t('company.modal.apply.remuneration.tjm.label')}
        helper={t('company.modal.apply.remuneration.tjm.helper')}
        placeholder={t('company.modal.apply.remuneration.tjm.placeholder', {
          currency: t('currency.symbol'),
        })}
        validate={[
          minOrMaxValue({
            max: config.company.dailyRate.max,
            min: config.company.dailyRate.min,
          }),
        ]}
      />
    </div>
  );
};

export const SalaryComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="p-t-m">
      <RequiredField
        component={RfText}
        type="number"
        id="salary"
        name="salary"
        max={config.company.salary.max}
        min={config.company.salary.min}
        step={500}
        label={t('company.modal.apply.remuneration.salary.label')}
        helper={t('company.modal.apply.remuneration.salary.helper')}
        placeholder={t('company.modal.apply.remuneration.salary.placeholder', {
          currency: t('currency.symbol'),
        })}
        validate={[
          minOrMaxValue({
            max: config.company.salary.max,
            min: config.company.salary.min,
          }),
        ]}
      />
    </div>
  );
};

const SalarySection = ({ missionContractTypes }) => {
  const { t } = useTranslation();

  const missionOrFulltimeContract = {
    mission: {
      label: 'fields.missions',
      value: 'missions',
      id: 'missions',
    },
    fulltime: {
      label: 'fields.fulltime_contract',
      value: 'fulltime_contract',
      id: 'fulltime_contract',
    },
  };

  return (
    <div>
      {missionContractTypes.includes(MISSION_OR_FULLTIME) &&
        !config.company.contractTypeIsAlwaysMissions && (
          <>
            <div>
              <RequiredField
                component={RfSelect}
                name="contract_type"
                label={t('company.modal.apply.contract_type.label')}
                placeholder={t('company.modal.apply.contract_type.placeholder')}
                options={values(missionOrFulltimeContract).map((contract) => ({
                  ...contract,
                  label: t(`${contract.label}`),
                }))}
                className="m-t-m"
              />
            </div>
            <RfHelper if="contract_type">
              {({ contract_type }) => {
                if (contract_type === MISSIONS) return DailyRateComponent();
                return SalaryComponent();
              }}
            </RfHelper>
          </>
        )}
      {(missionContractTypes.includes(MISSIONS) ||
        config.company.contractTypeIsAlwaysMissions) &&
        DailyRateComponent()}
      {(missionContractTypes.includes(FULLTIME_CONTRACT) ||
        missionContractTypes.includes(FIXED_TERM_CONTRACT)) &&
        !config.company.contractTypeIsAlwaysMissions &&
        SalaryComponent()}
    </div>
  );
};

SalarySection.propTypes = {
  missionContractTypes: arrayOf(string).isRequired,
};

export default SalarySection;
