import React from 'react';
import { node, string, objectOf, any } from 'prop-types';
import './styles.scss';

function TagContainer({ children, classnames, tagRef }) {
  return (
    <div className={`TagContainer ${classnames}`} ref={tagRef}>
      {children}
    </div>
  );
}

TagContainer.propTypes = {
  children: node.isRequired,
  classnames: string,
  tagRef: objectOf(any),
};

TagContainer.defaultProps = {
  classnames: null,
  tagRef: null,
};

export default TagContainer;
