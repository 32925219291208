import React from 'react';
import { bool, objectOf, any, func } from 'prop-types';
import { get, isDate } from 'lodash';
import config from '@/_config';
import { date, length, milestonePricing } from '@/helpers/validation';
import { today } from '@/helpers/date';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Trash } from '@/images/trash.svg';
import {
  Field,
  FieldArray,
  RequiredField,
  RfHelper,
  RfText,
  RfArea,
  RfDate,
} from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import FormSection from '@/components/FormSection';
import MilestonePricing from './MilestonePricing';
import MilestoneDeliverables from './MilestoneDeliverables';
import './styles.scss';

// Forfeit milestone
function MilestoneForfeitModal({
  close,
  change,
  handleSubmit,
  submitting,
  createMilestone,
  updateMilestone,
  deleteMilestone,
  entity,
  edit,
}) {
  const { t } = useTranslation();

  async function onSubmit({ ...values }) {
    const formattedValues = {
      ...values,
      total_duty: +get(values, 'pricing.amount', null),
    };
    if (edit) {
      await updateMilestone({
        variables: formattedValues,
        refetchQueries: ['getProposal'],
        awaitRefetchQueries: true,
      });
    } else {
      await createMilestone({
        variables: {
          quotation_id: entity.id,
          ...formattedValues,
        },
        refetchQueries: ['getProposal'],
        awaitRefetchQueries: true,
      });
    }

    close();
  }

  async function onDelete({ id }) {
    await deleteMilestone({
      variables: { id },
      refetchQueries: ['getProposal'],
      awaitRefetchQueries: true,
    });
    close();
  }

  const endDate = entity && entity.end_date ? new Date(entity.end_date) : '';
  const beginDate =
    entity && entity.begin_date ? new Date(entity.begin_date) : today;

  return (
    <Modal
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      name="Milestone"
      title={t(`proposal.modal.milestone.title${edit ? '.edit' : ''}`)}
      disableActions={submitting}
      closable={!submitting}
      okText={t(`proposal.modal.milestone.ok${edit ? '.edit' : ''}`)}
      leftAction={
        edit &&
        !submitting && (
          <Button
            variants={['link']}
            icon={Trash}
            onClick={handleSubmit(onDelete)}
          >
            {t('modal.delete')}
          </Button>
        )
      }
    >
      <FormSection title={t('proposal.modal.milestone.general.title')}>
        <RequiredField
          id="title"
          name="title"
          label={t('proposal.modal.milestone.title.label')}
          className="m-b-m"
          component={RfText}
          validate={[length({ max: 50 })]}
        />
        <div className="grid">
          <div className="grid__item mobileL--one-whole one-half">
            <RequiredField
              component={RfDate}
              name="begin_date"
              label={t('proposal.modal.milestone.beginDate.label')}
              placeholder={t('proposal.modal.milestone.beginDate.placeholder')}
              className="m-b-m"
              autoComplete="off"
              dayPickerProps={{
                disabledDays: {
                  before: beginDate,
                  after: endDate,
                },
              }}
              validate={[
                date({
                  '>=': beginDate,
                  format: date,
                }),
              ]}
            />
          </div>
          <div className="grid__item mobileL--one-whole one-half">
            <RfHelper values={['begin_date']}>
              {({ begin_date }) => (
                <RequiredField
                  component={RfDate}
                  name="end_date"
                  label={t('proposal.modal.milestone.endDate.label')}
                  placeholder={t(
                    'proposal.modal.milestone.endDate.placeholder'
                  )}
                  autoComplete="off"
                  dayPickerProps={{
                    disabledDays: {
                      before: isDate(begin_date) ? begin_date : beginDate,
                      after: endDate,
                    },
                  }}
                  validate={[
                    date({
                      '>=': begin_date || beginDate,
                      '<=': endDate,
                      granularity: 'day',
                      format: date,
                    }),
                  ]}
                />
              )}
            </RfHelper>
          </div>
        </div>
        <Field
          id="description"
          name="description"
          label={t('proposal.modal.milestone.description.label')}
          className="m-b-m"
          component={RfArea}
          validate={[length({ max: 10000 })]}
          max={10000}
        />
      </FormSection>

      {config.proposal.showQuote.billingChoice && (
        <FormSection title={t('proposal.modal.milestone.pricing.title')}>
          <RequiredField
            name="pricing"
            component={MilestonePricing}
            label={t('proposal.modal.milestone.pricing.label')}
            validate={[milestonePricing()]}
            totalAmount={entity.total_duty}
          />
        </FormSection>
      )}
      <FormSection
        title={t('proposal.modal.milestone.deliverables.title')}
        className="m-t-m"
      >
        <FieldArray
          name="deliverables_attributes"
          component={MilestoneDeliverables}
          change={change}
        />
      </FormSection>
    </Modal>
  );
}

MilestoneForfeitModal.propTypes = {
  close: func.isRequired,
  change: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  createMilestone: func.isRequired,
  updateMilestone: func.isRequired,
  deleteMilestone: func.isRequired,
  entity: objectOf(any),
  edit: bool,
};

MilestoneForfeitModal.defaultProps = {
  entity: null,
  edit: false,
};

export default MilestoneForfeitModal;
