import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

const WithLabels = (Toggle) => {
  const ToggleWithLabels = ({
    className,
    onChange,
    leftLabel,
    rightLabel,
    ...props
  }) => (
    <div className={cx('ToggleField-wrapper', className)}>
      <button
        type="button"
        className="fs-small"
        onClick={() => onChange(false)}
      >
        {leftLabel}
      </button>
      <Toggle
        className="ToggleField"
        onText={null}
        offText={null}
        onChange={onChange}
        {...props}
      />
      <button type="button" className="fs-small" onClick={() => onChange(true)}>
        {rightLabel}
      </button>
    </div>
  );

  ToggleWithLabels.propTypes = {
    leftLabel: pp.string.isRequired,
    rightLabel: pp.string.isRequired,
    onChange: pp.func.isRequired,
    className: pp.string,
  };

  ToggleWithLabels.defaultProps = {
    className: null,
  };

  return ToggleWithLabels;
};

export default WithLabels;
