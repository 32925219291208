import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import PlatformRecommendationModal from './PlatformRecommendationModal';
import {
  createRecommendation,
  updateRecommendation,
} from '@/api/Company/Recommendation/mutations';

export default compose(
  graphql(createRecommendation, { name: 'create' }),
  graphql(updateRecommendation, { name: 'update' })
)(PlatformRecommendationModal);
