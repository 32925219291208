import React from 'react';
import { get, isNull } from 'lodash';
import { ReactComponent as Search } from '@/images/search.svg';
import Drawer from '@/components/Drawer';
import SearchBar from '../../SearchBar';
import SearchBarMixed from '../../SearchBarMixed';
import HeaderLink from '../Link';
import getSearchQueryURICreate from '@/components/Header/SearchBar/helpers/getSearchQueryURICreate';
import { CLIENT, EXPERT } from '@/constants/userKind';
import config from '@/_config';

const userKindFromOfflineRoute = {
  missions: EXPERT,
  experts: CLIENT,
};

const SearchDrawer = (props) => {
  const userKind = isNull(get(props, 'currentUser.kind', null))
    ? userKindFromOfflineRoute[
        window.location.pathname.slice(1).replace(/\/+$/, '')
      ] // offline case, use kind according to the route
    : get(props, 'currentUser.kind') || CLIENT; // online case, If user is logged then use his kind, else use client kind

  const SearchBarComponent = config.global.hasMixedUsers
    ? SearchBarMixed
    : SearchBar;

  return (
    <Drawer
      renderReference={({ open }) => (
        <HeaderLink onClick={open}>
          <Search />
        </HeaderLink>
      )}
    >
      <Drawer.Screen
        name="default"
        header={({ close }) => (
          <SearchBarComponent
            className="m-r-s"
            close={close}
            {...props}
            getSearchQueryURI={getSearchQueryURICreate(userKind)}
          />
        )}
      />
    </Drawer>
  );
};

export default SearchDrawer;
