import React from 'react';
import { string, bool, number } from 'prop-types';
import { CREATE } from '@/constants/modes';
import { RfTags } from '@/containers/ReduxForm';
import TagsContext from '@/components/Tags/TagsContext';
import SectionField from '@/components/SectionField';
import FormSection from '@/components/FormSection';

/**
 * CompanyValuesSkillsSection
 *
 *    - Display @/components/Tags components
 *
 * Often used for :
 *     - Section in Profile Feature
 */
const CompanyValuesSection = ({
  mode,
  withCreatable,
  inlineDisplay,
  kind,
  minSkillsLength,
  title,
}) => (
  <div>
    <TagsContext.Provider
      value={{
        mode,
        withCreatable,
        inlineDisplay,
        kind,
        max: minSkillsLength,
        highlightMaxValues: 0,
        tagColor: 'lightCancel',
      }}
    >
      <FormSection>
        <SectionField
          id="company_value_skills"
          name="company_value_skills"
          component={RfTags}
          description="company.company_value_skills.description"
          title={title}
        />
      </FormSection>
    </TagsContext.Provider>
  </div>
);

CompanyValuesSection.propTypes = {
  mode: string,
  withCreatable: bool.isRequired,
  inlineDisplay: bool.isRequired,
  kind: string.isRequired,
  minSkillsLength: number,
  title: string,
};

CompanyValuesSection.defaultProps = {
  mode: CREATE,
  minSkillsLength: 0,
  title: '',
};

export default CompanyValuesSection;
