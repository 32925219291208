import React from 'react';
import { node, bool, string } from 'prop-types';
import cx from 'classnames';
import LoadingCircle from '@/components/LoadingCircle';
import './styles.scss';

function FormSection({ title, children, className, loading, showTitle }) {
  return (
    <section className={cx('Form__section', className)}>
      {showTitle &&
        title && (
          <h4 className="Form__label">
            {title}
            <span className="m-l-s" />
            {loading && <LoadingCircle />}
          </h4>
        )}
      {children}
    </section>
  );
}

FormSection.propTypes = {
  title: node,
  children: node,
  className: string,
  loading: bool,
  showTitle: bool,
};

FormSection.defaultProps = {
  title: null,
  children: null,
  className: null,
  loading: false,
  showTitle: true,
};

export default FormSection;
