import React from 'react';
import pp from 'prop-types';
import LinkToWebsite from '../LinkToWebsite';
import ShowCaseLinks from '../ShowCaseLinks';
import Signup from '../Signup';
import Login from '../Login';
import SignupClient from '../SignupClient';

function OfflineDrawerScreen({ t }) {
  return (
    <>
      <div className="OfflineDrawerScreen__head">
        <SignupClient className="m-b-m" t={t} />
        <Signup className="m-b-m" t={t} />
        <Login className="m-b-m tc" t={t} />
        <div className="Separator" />
      </div>
      <ShowCaseLinks className="m-b-m tl" t={t} />
      <LinkToWebsite className="m-t-m" t={t} />
    </>
  );
}

OfflineDrawerScreen.propTypes = {
  t: pp.func.isRequired,
};

export default OfflineDrawerScreen;
