import { get } from 'lodash';
import client from '@/helpers/apollo';
import { fileToAttachment } from '@/helpers/file';
import { uploadAttachment } from '@/api/Attachment/mutations';
import { updateLogo } from '@/api/Company/mutations';

export default {
  Mutation: {
    updateCompanyLogo: async (
      __,
      { id, original, updated, original_logo_id }
    ) => {
      let logoId = null;
      const originalLogoId = get(original, 'id', original_logo_id);

      if (updated) {
        const attachment = fileToAttachment(updated);
        const {
          data: { uploadAttachment: uploadRes },
        } = await client.mutate({
          mutation: uploadAttachment,
          variables: {
            attachment,
            kind: 'logo',
          },
        });

        logoId = uploadRes.id;
      } else {
        logoId = originalLogoId;
      }

      return client.mutate({
        mutation: updateLogo,
        variables: {
          entity_id: id,
          original_logo_id: originalLogoId,
          logo_id: logoId,
        },
      });
    },
  },
};
