import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { inviteToCreateProposalMission } from '@/api/Conversation/mutations';
import InviteToCreateProposalModal from './InviteToCreateProposalModal';

export default compose(
  graphql(inviteToCreateProposalMission, {
    name: 'inviteToCreateProposalMission',
  }),
  reduxForm({
    form: 'invite_to_create_proposal',
  })
)(InviteToCreateProposalModal);
