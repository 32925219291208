import storageKeys from './keys';
import { get } from 'lodash';
import { suggestionFilters } from '@/components/Header/SearchBar/helpers/suggestions';

const SET_STORAGE_VALUE = 'storage/SET_STORAGE_VALUE';
const TOGGLE_TRANSLATION_HELP = 'storage/TOGGLE_TRANSLATION_HELP';

const initialState = {
  [storageKeys.searchHistory]: [],
  [storageKeys.guestSearchCount]: 0,
  [storageKeys.selectedSuggestionFilter]: suggestionFilters.find(
    (filter) => !!get(filter, 'isDefault')
  ),
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STORAGE_VALUE:
      return {
        ...state,
        [action.key]: action.value,
      };
    default:
      return state;
  }
};

export const setStorageValue = (key, value) => ({
  type: SET_STORAGE_VALUE,
  key,
  value,
});

export const toggleTranslationHelp = () => ({
  type: TOGGLE_TRANSLATION_HELP,
});
