import { filter, isArray, values, merge, get } from 'lodash';
import tenantCompanyTypes from '@/constants/fields/companyTypes';
import practiceCultureChoices from '@/constants/fields/practiceCulture';
import { max, minFavoriteLength } from '@/helpers/validation';
import { MIN_FAVORITE_LENGTH } from '@/constants/validatorName';

/**
 * @param kind - Parameter to get only company type for given user kind
 * @param filterOptions - Options to filter on kinds (Ex: {selectable: false}
 * @returns {Array}
 */
export function getCompanyTypes(kind = null, filterOptions = {}) {
  /**
   * Always get company types as an array either is first an object (Ex: company types by user kind)
   */
  const companyTypeArray = ((companyTypes) => {
    // If 'companyTypes' is an object and kind is provided
    if (kind && !isArray(companyTypes) && companyTypes[kind])
      return values(companyTypes[kind]);
    // If 'companyTypes' is an object and kind is not provided
    if (!isArray(companyTypes))
      return values(
        // Reduce company types by role, to get a simple list without duplicates
        values(companyTypes).reduce((acc, curr) => merge(acc, curr), {})
      );
    return companyTypes;
  })(tenantCompanyTypes);

  // Filter company types with options,
  // see https://lodash.com/docs/latest#filter
  return filter(companyTypeArray, filterOptions);
}

/**
 * @param filterOptions - Options to filter on kinds (Ex: {selectable: false}
 * @returns {Array}
 */
export function getPracticeCulture(filterOptions = {}) {
  /**
   * Get practice culture choices
   */
  const practiceCultureArray = ((practiceCulture) => values(practiceCulture))(
    practiceCultureChoices
  );

  // Filter practice culture choices with options,
  // see https://lodash.com/docs/latest#filter
  return filter(practiceCultureArray, filterOptions);
}

/**
 *
 * @param companyType
 * @returns {boolean}
 */
export function isSolo(companyType) {
  return get(
    getCompanyTypes().find(
      (companyTypeItem) => companyTypeItem.value === companyType
    ),
    'solo',
    false
  );
}

/**
 *
 * @param type {String}
 * @returns {*}
 */
export function getCompanyType(type) {
  return getCompanyTypes().find((companyType) => companyType.id === type);
}

/**
 *
 * @param maxLength
 * @param msg
 * @param fieldName
 * @param otherValidators
 * @returns {function(*): *}
 */
export const maxLengthValidator = (
  maxLength,
  msg,
  fieldName = null,
  otherValidators = []
) => (t) => {
  if (!maxLength) return otherValidators;
  return [
    ...otherValidators,
    max({
      ...(fieldName && { fieldName }),
      max: maxLength,
      msg: t(msg, { max: maxLength }),
    }),
  ];
};

/**
 *
 * @param minLength
 * @param msg
 * @param fieldName
 * @param otherValidators
 * @returns {function(*): *}
 */
export const minFavoriteLengthValidator = (
  minLength,
  msg,
  fieldName = null,
  otherValidators = []
) => (t) => {
  if (!minLength) return otherValidators;
  return [
    ...otherValidators,
    minFavoriteLength({
      ...(fieldName && { fieldName }),
      min: minLength,
      msg: t(msg, { min: minLength }),
    }),
  ];
};

/**
 *
 * @param rules
 * @returns {function(*): *}
 */
export const multipleMaxLengthValidator = (rules) => (t) => {
  const validators = rules.map((rule) => {
    const { maxLength, msg, fieldName } = rule;
    return maxLengthValidator(maxLength, msg, fieldName);
  });
  return validators.flatMap((validator) => validator(t));
};

/**
 * To handle several rules validation
 * to reuse, add ruleName and specific rule according to your case
 * ex: maximum of categories and minimum of subCategories favorites in the missionForm
 * @param rules
 * @returns {function(*): *}
 */
export const multipleRulesLengthValidator = (rules) => (t) => {
  const validators = rules.map((rule) => {
    const { ruleName, ruleValue, msg, fieldName } = rule;
    if (ruleName === MIN_FAVORITE_LENGTH) {
      return minFavoriteLengthValidator(ruleValue, msg, fieldName);
    }
    return maxLengthValidator(ruleValue, msg, fieldName);
  });
  return validators.flatMap((validator) => validator(t));
};
