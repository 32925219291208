import React, { useState } from 'react';
import { func, bool, string } from 'prop-types';
import { get } from 'lodash';
import { getLoginRoute } from '@/helpers/router';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Link from '@/components/Link';
import './styles.scss';
import logo from '@/images/logo-big.png';
import { LANDING } from '@/constants/router.kicklox';
import useTranslation from '@/hooks/useTranslation';
import SignupForm from '@/components/SignupForm/SignupForm';

function SignupModal({
  close,
  handleSubmit,
  submitting,
  registerUser,
  className,
}) {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);

  async function onSubmit(credentials) {
    try {
      const response = await registerUser({ variables: { credentials } });
      if (get(response, 'data')) {
        setSubmitted(true);
      }
    } catch (e) {
      console.error('Error: ', e); // eslint-disable-line no-console
    }
  }

  return (
    <Modal
      name="Signup"
      size="medium"
      close={close}
      onOk={close}
      onCancel={close}
      hideActions
      closable={!submitting}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tc">
          <a href={LANDING} className={className}>
            <img className="SignupModal__logo" src={logo} alt="Logo" />
          </a>
          {submitted ? (
            <>
              <h2 className="AuthView__signup-title">
                {t('auth.signup.success_with_user_confirmation.title')}
              </h2>
              <p>
                {t('auth.signup.success_with_user_confirmation.description')}
              </p>
            </>
          ) : (
            <SignupForm />
          )}
        </div>
        <div className="m-v-l tc">
          {!submitted && (
            <Button type="submit" disabled={submitting}>
              {t('signup.modal.submit')}
            </Button>
          )}
        </div>
        <p className="tc m-all-none">
          <span>{t('signup.modal.already')} </span>
          <Link onClick={close} to={getLoginRoute()}>
            {t('signup.modal.login')}
          </Link>
        </p>
      </form>
    </Modal>
  );
}

SignupModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  registerUser: func.isRequired,
  className: string,
};

SignupModal.defaultProps = {
  className: null,
};
export default SignupModal;
