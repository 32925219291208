import React from 'react';
import pp from 'prop-types';
import LabelledToggle from '@/components/Choice/LabelledToggle';
import Field from '..';

const LabelledToggleField = (
  { label, ...props } // Dans le cas du LabelledToggleField, c'est le LabelledToggle qui est responsable de son label
) => (
  <Field
    {...props}
    render={({ getInputProps }) => (
      <LabelledToggle {...getInputProps({ label })} />
    )}
  />
);

LabelledToggleField.propTypes = {
  label: pp.string,
};

LabelledToggleField.defaultProps = {
  label: null,
};

export default LabelledToggleField;
