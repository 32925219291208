import {
  ONE_MONTH,
  THREE_MONTHS,
  ASAP,
  PRECISE,
} from '@/constants/availabilities';

const asap = {
  id: ASAP,
  value: ASAP,
  label: 'fields.availabilityTypes.asap',
};

const precise = {
  id: PRECISE,
  value: PRECISE,
  label: 'fields.availabilityTypes.precise',
};

const oneMonth = {
  id: 'one-month',
  value: ONE_MONTH,
  label: 'fields.availabilityTypes.oneMonth',
};
const threeMonth = {
  id: 'three-months',
  value: THREE_MONTHS,
  label: 'fields.availabilityTypes.threeMonths',
};

export const lightAvailabilities = [asap, precise];
export const fullAvailabilities = [asap, precise, oneMonth, threeMonth];
