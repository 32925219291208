import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

function MenuSeparator({ className }) {
  return <li className={cx('Menu__separator', className)} />;
}

MenuSeparator.propTypes = {
  className: pp.string,
};

MenuSeparator.defaultProps = {
  className: null,
};

export default MenuSeparator;
