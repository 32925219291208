import React from 'react';
import toField from '../toField';
import Select from './Select';

const singleChangeHandler = (func) => (value) => {
  func(value ? value.value : '');
};

const multiChangeHandler = (func) => (values) => {
  func(values.map((value) => value.value));
};

const transformValue = (value, options, multi) => {
  if (multi && typeof value === 'string') return [];

  const filteredOptions = options.filter(
    (option) =>
      multi ? value.indexOf(option.value) !== -1 : option.value === value
  );

  return multi ? filteredOptions : filteredOptions[0];
};

const SelectToField = ({
  name,
  options,
  multi,
  onChange,
  value,
  onBlur,
  onFocus,
  native,
  initialValue,
  ...restProps
}) => {
  const transformedValue = transformValue(
    initialValue || value,
    options,
    multi
  );

  return (
    <Select
      options={options}
      name={name}
      value={transformedValue}
      multi={multi}
      onChange={
        multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
      }
      onBlur={() => onBlur(value || initialValue)}
      onFocus={onFocus}
      native={native}
      {...restProps}
    />
  );
};

SelectToField.propTypes = Select.propTypes;

SelectToField.defaultProps = Select.defaultProps;

export default toField(SelectToField);
