import React from 'react';
import { func, bool, string, objectOf, any } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { FieldArray } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import InvitationsField from './InvitationsField';

function CollaborateModal({
  close,
  showFlash,
  handleSubmit,
  submitting,
  inviteToMissions,
  id,
  name,
  ability,
  context,
  idList,
}) {
  const { t } = useTranslation();
  const inviteList = context === 'list';

  async function onSubmit({ invitationables }) {
    if (!invitationables.length) return;
    await inviteToMissions({
      variables: {
        recipient_id: inviteList ? idList : id,
        recipient_type: inviteList ? 'List' : 'Company',
        invitationables: invitationables.map((missionId) => ({
          invitationable_id: missionId,
          invitationable_type: 'Mission',
        })),
      },
    });
    close();
    showFlash({
      kind: 'success',
      text: 'company.modal.invite.success.text',
    });
  }

  return (
    <Modal
      name="inviteToCollaborate"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={
        inviteList
          ? t('company.modal.invite.list.title')
          : t('company.modal.invite.title', { name })
      }
      subtitle={
        inviteList
          ? t('company.modal.invite.list.subtitle')
          : t('company.modal.invite.subtitle')
      }
      okText={t('company.modal.invite.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FieldArray
        name="invitationables"
        component={InvitationsField}
        id={id}
        list_id={idList}
        inviteList={inviteList}
        ability={ability}
      />
    </Modal>
  );
}

CollaborateModal.propTypes = {
  close: func.isRequired,
  showFlash: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  inviteToMissions: func.isRequired,
  id: string.isRequired,
  idList: string.isRequired,
  name: string.isRequired,
  ability: objectOf(any).isRequired,
  context: string.isRequired,
};

export default CollaborateModal;
