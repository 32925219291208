import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { updateInformations } from '@/api/Company/mutations.gql';
import reduxForm from '@/helpers/enhancers/reduxForm';
import ContractTypesModal from './ContractTypesModal';
import { connect } from 'react-redux';
import { showFlash } from '@/redux/modules/flash';

export default compose(
  graphql(updateInformations, { name: 'update' }),
  reduxForm({
    form: 'mission_contract_types',
  }),
  connect(
    null,
    { showFlash }
  )
)(ContractTypesModal);
