import React from 'react';
import { func, bool, string, objectOf, any } from 'prop-types';
import { getConversationRoute } from '@/helpers/router';
import { length } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfArea } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';

function ConverseModal({
  close,
  handleSubmit,
  submitting,
  createChannel,
  id,
  history,
}) {
  const { t } = useTranslation();

  async function onSubmit({ message_content }) {
    const { data } = await createChannel({
      variables: { id, message_content },
    });
    close();
    history.push(getConversationRoute(data.createDirectMessageChannel.id));
  }

  return (
    <Modal
      name="inviteToConverse"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('company.modal.converse.title')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection>
        <RequiredField
          component={RfArea}
          name="message_content"
          label={t('company.modal.converse.label')}
          helper={t('company.modal.converse.helper')}
          validate={[length({ max: 500 })]}
        />
      </FormSection>
    </Modal>
  );
}

ConverseModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  createChannel: func.isRequired,
  id: string.isRequired,
  history: objectOf(any).isRequired,
};

export default ConverseModal;
