import React, { useState } from 'react';
import { arrayOf, object, func, string, bool } from 'prop-types';
import { isEmpty, toLower, get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import { TextInput } from '@/components/Fields/TextField';
import FilterCount from '@/components/AlgoliaFilters/FilterCount';
import './styles.scss';

function CheckboxList({
  name,
  items,
  scrollable,
  displayCount,
  searchable,
  setActiveFilter,
  kind,
}) {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(toLower(get(e, 'currentTarget.value')));
  };

  const itemsFiltered = searchTerm
    ? items.filter((item) =>
        toLower(t(get(item, 'label'))).includes(searchTerm)
      )
    : items;

  return (
    <div className="CheckboxList">
      {searchable && (
        <TextInput
          placeholder={t('filters.list.placeholder')}
          value={searchTerm}
          onChange={handleSearch}
          className="CheckboxList__searchBar"
        />
      )}
      {isEmpty(itemsFiltered) ? (
        <p className="placeholder">{t('filters.empty')}</p>
      ) : (
        <div
          className={`${scrollable && 'ScrollableFilter'} CheckboxList__items`}
        >
          {itemsFiltered.map(({ label, value, count, selected, key }) => (
            <React.Fragment key={`cb_${name}_${label}`}>
              <LabelledCheckbox
                name={name}
                id={key}
                label={
                  <FilterCount
                    label={label}
                    displayCount={displayCount}
                    count={count}
                  />
                }
                checked={selected}
                onChange={(event) => {
                  setActiveFilter({
                    name,
                    key,
                    value: event.currentTarget.value,
                    selected,
                    kind,
                  });
                }}
                value={value}
              />
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

CheckboxList.propTypes = {
  name: string.isRequired,
  setActiveFilter: func.isRequired,
  displayCount: bool,
  items: arrayOf(object),
  scrollable: bool,
  kind: string.isRequired,
  searchable: bool,
};

CheckboxList.defaultProps = {
  displayCount: false,
  scrollable: false,
  searchable: false,
  items: [],
};

export default CheckboxList;
