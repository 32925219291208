import React from 'react';
import pp from 'prop-types';
import { Transition, config } from 'react-spring';
import Flash from '@/components/Flash';
import './styles.scss';

function FlashManager({ t, queue, hideFlash }) {
  const spring = { ...config.default };
  return (
    <div className="Flashes">
      <Transition
        items={queue}
        keys={(item) => item.id}
        from={{ opacity: 1, transform: 'translate3d(100%, 0, 0)', height: 0 }}
        enter={[
          { height: 'auto' },
          { opacity: 1, transform: 'translate3d(0%, 0, 0)' },
        ]}
        leave={{ opacity: 0 }}
        config={(_, state) => {
          switch (state) {
            case 'enter':
              return [{ duration: 200 }, spring];
            default:
              return spring;
          }
        }}
      >
        {(flash) => (style) => (
          <Flash
            key={flash.id}
            flash={flash}
            style={style}
            t={t}
            hide={() => hideFlash(flash.id)}
          />
        )}
      </Transition>
    </div>
  );
}

FlashManager.propTypes = {
  t: pp.func.isRequired,
  hideFlash: pp.func.isRequired,
  queue: pp.arrayOf(
    pp.shape({
      id: pp.string.isRequired,
      kind: pp.string.isRequired,
      text: pp.string.isRequired,
    })
  ).isRequired,
};

export default FlashManager;
