import React from 'react';
import pp from 'prop-types';
import PhoneInput from './PhoneInput';
import Field from '..';

const PhoneField = ({ label, ...props }) => (
  <Field
    {...props}
    render={({ getInputProps }) => <PhoneInput {...getInputProps({ label })} />}
  />
);

PhoneField.propTypes = {
  label: pp.string,
};

PhoneField.defaultProps = {
  label: null,
};

export default PhoneField;
