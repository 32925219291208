import React from 'react';
import pp from 'prop-types';
import Toggle from '@/components/Choice/Toggle';
import toField from '@/components/Fields/toField';
import withLabels from './withLabels';
import './styles.scss';

const LabelledToggle = withLabels(Toggle);

const ToggleField = ({ labels, ...props }) => {
  const ToggleComponent = labels ? LabelledToggle : Toggle;
  return <ToggleComponent {...props} />;
};

ToggleField.propTypes = {
  labels: pp.bool,
};

ToggleField.defaultProps = {
  labels: false,
};

export default toField(ToggleField);
