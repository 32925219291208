import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import 'intl';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/en';
import config from '@/_config';
import priceFormat from '@/helpers/priceFormat';
import { formatDate } from '@/helpers/date';

function loadLocales(locale, options, callback) {
  if (locale !== moment.locale()) {
    moment.locale(locale);
  }

  function importLocale(name, defaultTranslations) {
    import(/* webpackChunkName: "[request]" */ `./static/${name}/${locale}`)
      .then((translations) => {
        callback(translations, { status: '200' });
      })
      .catch(() => callback(defaultTranslations, { status: 200 }));
  }

  try {
    if (config.name === 'default') {
      importLocale(config.name);
    } else {
      import(/* webpackChunkName: "[request]" */ `./default/${locale}`).then(
        (defaultLocale) => {
          importLocale(config.name, defaultLocale);
        }
      );
    }
  } catch (e) {
    callback(null, { status: '404' });
  }
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `{{lng}}`,
      parse: (data) => data,
      ajax: loadLocales,
    },

    load: 'languageOnly',
    whitelist: config.lang,

    keySeparator: '/',
    interpolation: {
      escapeValue: false, // not needed for react!!
      format: (value, format) => {
        switch (format) {
          // Those formats (price, date...) are used inside phraseapp translations key.
          // Ex ('modalities.remuneration.daily_rate').
          case 'price':
            return priceFormat(value, { minDigits: 2, maxDigits: 2 });
          case 'priceWithoutDigits':
            return priceFormat(value, { minDigits: 0, maxDigits: 0 });
          case 'date':
            return formatDate(value);
          default:
            return value;
        }
      },
    },

    react: {
      wait: true,
    },

    debug: false,
  });

export default i18n;
