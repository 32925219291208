import React, { useContext } from 'react';
import {
  arrayOf,
  any,
  func,
  string,
  bool,
  instanceOf,
  number,
} from 'prop-types';
import MediaQuery from 'react-responsive';
import { get, isArray, isEmpty, isNull, isUndefined } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import arrayMove from 'array-move';
import useTranslation from '@/hooks/useTranslation';
import TagsContext from '@/components/Tags/TagsContext';
import TagRow from '@/components/Tags/components/TagRow';
import { Field, RfTagsSuggestions } from '@/containers/ReduxForm';
import { length } from '@/helpers/validation';
import { updateTagsRating } from '../../helpers/handler';
import { ReactComponent as InfoIcon } from '@/images/c-info.svg';
import Tooltip from '@/components/Tooltip';
import { BREAKPOINTS } from '@/constants/screen';
import TagInline from '@/components/Tags/components/TagInline';
import { placeholderField } from '@/helpers/placeholder';
import { EQUIPMENTS, COMPANY_VALUES_SKILLS } from '@/constants/fields';

function TagsList(props) {
  const {
    tags,
    required,
    fieldName,
    tagKind,
    withCreatable,
    withIcon,
    onChange,
    invalidCharacters,
    itemValidate,
    disableCopyPaste,
    inlineDisplay: inlineTags,
    disabledVariant,
    minCharsSuggestion,
  } = props;
  const tagsContext = useContext(TagsContext);
  const inlineDisplay = isNull(inlineTags)
    ? get(tagsContext, 'inlineDisplay', false)
    : inlineTags;
  const isEquipmentsTags = fieldName === EQUIPMENTS;

  const newTagFieldName = `${fieldName}_name`;
  const { t } = useTranslation();

  const TagsListLabel = () => {
    switch (fieldName) {
      case EQUIPMENTS:
        return t('equipments_tags.list.label');
      case COMPANY_VALUES_SKILLS:
        return t('company_value_tags.list.label');
      default:
        return t('tags.list.label');
    }
  };

  function moveTag(previousIndex, currentIndex) {
    const reorderedTag = arrayMove(tags, previousIndex, currentIndex);
    onChange(reorderedTag);
  }

  function handleRemove(id, name) {
    const newTags = tags.filter(
      (item) => (item.id === undefined ? item.name !== name : item.id !== id)
    );
    onChange(newTags);
  }

  function addTag(tag) {
    if (!isArray(tag) && !isUndefined(tag)) {
      const newTags = [
        ...tags,
        { ...tag, rating: tagsContext.ratingWithNumberField ? 1 : null },
      ];
      onChange(newTags);
    }
  }

  function onSelect(tag) {
    addTag(tag);
  }

  function handleRatingChange(id, name, rating) {
    onChange(
      updateTagsRating(tags, id === undefined ? null : id, name, rating)
    );
  }

  function renderTags(mobile) {
    if (isEmpty(tags)) return null;

    if (inlineDisplay) {
      return tags.map((value, i) => {
        const { id, name } = value;
        const hasError = !itemValidate.every((callback) => callback(value));
        return (
          <TagInline
            handleRemove={() => handleRemove(id, name)}
            name={name}
            index={i}
            hasError={hasError}
            required={required}
            withIcon={withIcon}
            fieldName={fieldName}
            disabledVariant={disabledVariant}
          />
        );
      });
    }
    return (
      <DndProvider
        backend={mobile ? TouchBackend : HTML5Backend}
        context={window}
      >
        {tags.map((value, i) => {
          const { id, name, rating } = value;
          const hasError = !itemValidate.every((callback) => callback(value));

          return (
            <TagRow
              key={id}
              moveTag={moveTag}
              handleRemove={() => handleRemove(id, name)}
              handleRatingChange={handleRatingChange}
              id={id}
              name={name}
              rating={rating}
              index={i}
              hasError={hasError}
              required={required}
            />
          );
        })}
      </DndProvider>
    );
  }

  return (
    <div>
      {tags.length > 0 && (
        <div className="d-f jc-sb">
          {!inlineDisplay && (
            <span className="fs-small grey p-b-xs p-t-xs">
              {TagsListLabel()}
            </span>
          )}
          {tagsContext.withRating && (
            <span className="fs-small grey p-b-xs p-t-xs d-f">
              {isEquipmentsTags
                ? t('equipments_tags.list.rating.info')
                : t('tags.list.rating.info')}
              {!isEquipmentsTags && (
                <span>
                  <Tooltip
                    title="tags.rating.tooltip.title"
                    text="tags.rating.tooltip.text"
                  >
                    <InfoIcon className="p-l-xs fs-medium" />
                  </Tooltip>
                </span>
              )}
            </span>
          )}
        </div>
      )}

      <div className={`m-b-m m-t-m ${inlineDisplay ? 'd-f flex-wrap' : ''}`}>
        <MediaQuery maxWidth={BREAKPOINTS.mobileL}>
          {(mobile) => renderTags(mobile)}
        </MediaQuery>
      </div>
      <div className="d-f m-r-s m-l-s">
        <Field
          component={RfTagsSuggestions}
          inputProps={{
            label: t(placeholderField(fieldName, 'tags.add.form.label')()),
            invalidCharacters,
            disableCopyPaste,
          }}
          className="m-b-m p-r-s w-100"
          name={newTagFieldName}
          fieldName={fieldName}
          onSelect={onSelect}
          selectedItems={tags}
          validate={[length({ max: 70 })]}
          withCreatable={
            !isNull(withCreatable) ? withCreatable : tagsContext.withCreatable
          }
          withIcon={withIcon}
          kind={!isNull(tagKind) ? tagKind : tagsContext.kind}
          minCharsSuggestion={minCharsSuggestion}
        />
      </div>
    </div>
  );
}

TagsList.propTypes = {
  disableCopyPaste: bool,
  fieldName: string,
  tagKind: string,
  invalidCharacters: instanceOf(RegExp),
  itemValidate: arrayOf(func),
  onChange: func.isRequired,
  required: bool,
  withCreatable: bool,
  inlineDisplay: bool,
  tags: arrayOf(any),
  withIcon: bool,
  disabledVariant: bool,
  minCharsSuggestion: number,
};

TagsList.defaultProps = {
  disableCopyPaste: false,
  fieldName: null,
  tagKind: null,
  invalidCharacters: null,
  itemValidate: [],
  required: false,
  withCreatable: null,
  inlineDisplay: null,
  tags: [],
  withIcon: false,
  disabledVariant: false,
  minCharsSuggestion: 2,
};

export default TagsList;
