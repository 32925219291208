import config from '@/_config';
import { get, omit } from 'lodash';

/**
 * Determines if feature should render or note RatingTag
 * @param feature
 * @returns {boolean}
 */
export const hasRating = (feature = null) => {
  const showFor = get(config, 'skills.rating.activeFeatures', []);
  return showFor.includes(feature);
};

/**
 * Get the max rating value
 * @type {undefined}
 */
export const ratingMaxValues = get(config, 'skills.rating.max');

/**
 * Update Tags collection with proper rating value
 * @param tags
 * @param id
 * @param name
 * @param key
 * @returns {*}
 */
export const updateTagsRating = (tags, id, name, key) =>
  tags.map((tag) => {
    if (tag.id === id || tag.name === name) {
      return { ...tag, rating: key };
    }
    return tag;
  });

/**
 * Format tags for tag_attributes schema
 * @param tags
 * @returns {*}
 */
export const formatTags = (tags) =>
  tags.map((tag) =>
    omit(tag, ['id', 'description', 'parent', 'childs', '__typename'])
  );
