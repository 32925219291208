import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { cvScrap } from '@/api/User/mutations';
import translate from '@/helpers/enhancers/translate';
import CvModal from './CvModal';

export default compose(
  connect((state) => ({ values: getFormValues('company_cv')(state) })),
  translate(),
  graphql(cvScrap, { name: 'create' })
)(CvModal);
