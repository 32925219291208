import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import LoadingCircle from '@/components/LoadingCircle';

const withAsync = (Downshift) => {
  class AsyncDownshift extends React.Component {
    state = {
      options: [],
      loading: false,
    };

    handleValueChange = (query, helpers) => {
      if (!query) return;
      if (query.length < this.props.minChars) {
        this.setState({ options: [], loading: false }, helpers.closeMenu);
        return;
      }

      this.setState({ loading: true });
      this.props
        .loadOptions(query)
        .then((options) => {
          this.setState({ options, loading: false });
        })
        .catch(() => {
          this.setState({ options: [], loading: false });
        });
      this.props.onInputValueChange(query, helpers);
    };

    renderLoading = () => () => (
      <span className="Dropdown__placeholder">
        <LoadingCircle />
      </span>
    );

    render() {
      const { options } = this.state;
      const { loadOptions, ...props } = this.props;
      return (
        <Downshift
          {...props}
          onInputValueChange={this.handleValueChange}
          options={options}
          renderOptions={this.state.loading ? this.renderLoading() : false}
        />
      );
    }
  }

  AsyncDownshift.propTypes = {
    loadOptions: pp.func.isRequired,
    minChars: pp.number,
    onInputValueChange: pp.func,
    value: pp.oneOfType([pp.string, pp.objectOf(pp.any)]),
  };

  AsyncDownshift.defaultProps = {
    minChars: 1,
    onInputValueChange: _.noop,
    value: '',
  };

  return AsyncDownshift;
};

export default withAsync;
