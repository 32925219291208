import { map } from 'lodash';
import client from '@/helpers/apollo';
import {
  createForfeit,
  createTechnicalAssistance,
  acceptMilestone,
  rejectMilestone,
  cancelMilestone,
  updateTechnicalAssistanceMilestone,
  updateForfeitMilestone,
  deliverMilestone,
  deliverWithUpdateForfeit,
  deliverWithUpdateTechnicalAssistance,
} from '@/api/Contract/mutations';
import { isCreateContext } from '@/components/Contract/helpers/isCreateContext';
import { modifiedChargesId as modifiedChargesIdFunc } from '@/helpers/contractMilestone';

const getIdKey = (create) => (create ? 'contract_id' : 'id');

export default {
  Mutation: {
    createOrUpdateForfeit: async (
      __,
      {
        id,
        action,
        begin_date,
        end_date,
        price,
        title,
        description,
        deliverables_attributes,
      }
    ) => {
      const mutationValues = {
        begin_date,
        end_date,
        price: parseFloat(price),
        title,
        description,
        deliverables_attributes,
      };
      const isCreationContext = isCreateContext(action);
      await client.mutate({
        mutation: isCreationContext ? createForfeit : updateForfeitMilestone,
        variables: {
          ...mutationValues,
          [getIdKey(isCreationContext)]: id,
        },
      });
    },
    createOrUpdateTechnicalAssistance: async (
      __,
      { begin_date, end_date, id, action, charges, units_worked }
    ) => {
      const mutationValues = {
        begin_date,
        end_date,
        units_worked,
      };
      const isCreationContext = isCreateContext(action);
      await client.mutate({
        mutation: isCreationContext
          ? createTechnicalAssistance
          : updateTechnicalAssistanceMilestone,
        variables: {
          ...mutationValues,
          charges_attributes: charges
            ? charges.map((a) => ({
                ...a,
                cost_per_unit: +a.cost_per_unit,
                quantity: +a.quantity,
              }))
            : [],
          [getIdKey(isCreationContext)]: id,
        },
      });
    },
    cancelMilestone: async (__, { id }) => {
      await client.mutate({
        mutation: cancelMilestone,
        variables: {
          id,
        },
      });
    },
    acceptOrRejectMilestone: async (
      __,
      { id, refusal_reason, attachments }
    ) => {
      if (!refusal_reason) {
        return client.mutate({
          mutation: acceptMilestone,
          variables: {
            id,
            attachment_ids: map(attachments, 'id'),
          },
        });
      }
      return client.mutate({
        mutation: rejectMilestone,
        variables: {
          id,
          refusal_reason,
          attachment_ids: map(attachments, 'id'),
        },
      });
    },
    deliverTechnicalAssistanceMilestone: async (
      __,
      {
        id,
        comment,
        attachments,
        begin_date,
        end_date,
        units_worked,
        initialValues,
        charges,
      }
    ) => {
      const initialCharges = initialValues.charges;
      const modifiedChargesId = modifiedChargesIdFunc(initialCharges, charges);

      const hasModifiedCharges =
        initialCharges.length !== charges.length ||
        modifiedChargesId.length > 0;

      const hasModifiedTimeWorked = units_worked === initialValues.units_worked;

      if (hasModifiedTimeWorked && !hasModifiedCharges) {
        return client.mutate({
          mutation: deliverMilestone,
          variables: {
            id,
            comment,
            attachment_ids: map(attachments, 'id'),
          },
        });
      }
      return client.mutate({
        mutation: deliverWithUpdateTechnicalAssistance,
        variables: {
          id,
          comment,
          begin_date,
          end_date,
          units_worked,
          attachment_ids: map(attachments, 'id'),
          charges_attributes: charges
            ? charges.map((a) => ({
                ...a,
                cost_per_unit: +a.cost_per_unit,
                quantity: +a.quantity,
              }))
            : [],
        },
      });
    },
    deliverForfeitMilestone: async (
      __,
      {
        id,
        comment,
        attachments,
        begin_date,
        end_date,
        price,
        title,
        description,
        deliverables_attributes,
        initialValues,
      }
    ) => {
      if (price === initialValues.price) {
        return client.mutate({
          mutation: deliverMilestone,
          variables: {
            id,
            comment,
            attachment_ids: map(attachments, 'id'),
          },
        });
      }
      return client.mutate({
        mutation: deliverWithUpdateForfeit,
        variables: {
          id,
          comment,
          begin_date,
          end_date,
          price: parseFloat(price),
          title,
          description,
          deliverables_attributes,
          attachment_ids: map(attachments, 'id'),
        },
      });
    },
  },
};
