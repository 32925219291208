import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  createProposal,
  createQuote,
  updateQuote,
  createMilestone,
  updateMilestone,
} from '@/api/Proposal/mutations';
import {
  getChargeUnits,
  getTechnicalAssistanceUnits,
} from '@/api/Proposal/queries';
import reduxForm from '@/helpers/enhancers/reduxForm';
import QuoteModal from './QuoteModal';
import renderWhileLoading from '@/helpers/enhancers/renderWhileLoading';

export default compose(
  graphql(createProposal, { name: 'createProposal' }),
  graphql(createQuote, { name: 'createQuote' }),
  graphql(updateQuote, { name: 'updateQuote' }),
  graphql(getChargeUnits, { name: 'chargeUnits' }),
  graphql(getTechnicalAssistanceUnits, { name: 'technicalAssistanceUnits' }),
  graphql(createMilestone, { name: 'createMilestone' }),
  graphql(updateMilestone, { name: 'updateMilestone' }),
  reduxForm({
    form: 'proposal_quote',
  }),
  connect((state) => ({
    formValues: getFormValues('proposal')(state),
  })),
  withRouter,
  renderWhileLoading()
)(QuoteModal);
