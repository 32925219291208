import React from 'react';
import { shape, any } from 'prop-types';
import config from '@/_config';
import useTranslation from '@/hooks/useTranslation';
import CategoriesSection from '../CategoriesSection';
import ProfileModal from '../../ProfileModal';

function CategoriesModal({ leader, categories, required, ...props }) {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal_title.categories.title')}
      form="company_categories"
      {...props}
    >
      {config.company.showCategories(leader.kind) && (
        <CategoriesSection required={required} {...props} />
      )}
    </ProfileModal>
  );
}

CategoriesModal.propTypes = {
  leader: shape(any).isRequired,
};

export default CategoriesModal;
