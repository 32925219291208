import { find, get } from 'lodash';
import Sentry from '@/helpers/sentry';

export default (collection, value) => {
  const item = find(collection, { value });
  if (!item) {
    Sentry.captureException(
      new Error(`mismatch with api constants: value ${value} doesn't exist`)
    );
  }
  return get(item, 'label', '');
};
