import React from 'react';
import { bool, objectOf, any, func, number } from 'prop-types';
import cx from 'classnames';
import { Highlight } from 'react-instantsearch-dom';

const Suggestion = ({
  hightlight,
  item,
  index,
  getItemProps,
  highlightedIndex,
  itemToString,
}) => {
  const getAttribute = (currentItem) => {
    // The hits returned by algolia have an index data, for client kind the suggestion can have an Expert index (equal to the Company index)
    if (currentItem.index === 'Expert' || currentItem.index === 'Company') {
      return 'name_anonymous';
    }
    if (currentItem.index === 'Tag') {
      return 'name';
    }
    // For mission index
    return 'context';
  };

  return (
    <li
      {...getItemProps({
        item,
        className: cx('SearchBar__suggestion', {
          'SearchBar__suggestion--highlighted': highlightedIndex === index,
        }),
      })}
    >
      {hightlight ? (
        <Highlight attribute={getAttribute(item)} hit={item} tagName="strong" />
      ) : (
        itemToString(item)
      )}
    </li>
  );
};

Suggestion.propTypes = {
  hightlight: bool,
  item: objectOf(any).isRequired,
  index: number.isRequired,
  getItemProps: func.isRequired,
  highlightedIndex: number,
  itemToString: func.isRequired,
};

Suggestion.defaultProps = {
  hightlight: true,
  highlightedIndex: null,
};

export default Suggestion;
