import React, { useContext } from 'react';
import useTranslation from '@/hooks/useTranslation';
import { propType } from 'graphql-anywhere';
import { number, oneOfType, string, shape } from 'prop-types';
import { get } from 'lodash';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import priceFormat from '@/helpers/priceFormat';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import { capitalize } from '@/helpers/string';
import { getMonthLabel } from '@/helpers/milestone';
import UserContext from '@/permissions/contexts/UserContext';
import getPricingUnitKindLabel from '@/helpers/pricingUnitKindLabel';

function MilestoneInformation({
  milestone,
  contract,
  costPerUnit,
  classes,
  withModification,
}) {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);
  const { title, parent } = milestone;
  const amount = milestone.amount(contract.kind);

  const unit = get(contract, 'proposal.quotation.unit');

  function renderUnit() {
    if (contract.isForfeit()) {
      return t('contract.milestone.units.forfeit');
    }
    return getPricingUnitKindLabel(t, unit);
  }

  const formattedTitle = capitalize(getMonthLabel(title));
  const spanClassname =
    withModification && currentUser.isExpert() ? 'td-lt' : '';
  const unitsWorked =
    withModification && !currentUser.isExpert()
      ? get(parent, 'units_worked')
      : milestone.get('units_worked');
  const timeWorked = unitsWorked;

  function renderAmount() {
    if (!currentUser.isExpert() && withModification) {
      return contract.isForfeit()
        ? `${(get(parent, 'duty_ratio') * 100).toFixed(2)}%`
        : priceFormat(timeWorked, { minDigits: 2, maxDigits: 2 });
    }
    return amount || '-';
  }

  function renderPrice() {
    if (!currentUser.isExpert() && withModification) {
      return contract.isForfeit()
        ? get(parent, 'price')
        : parseFloat(timeWorked * costPerUnit);
    }
    return contract.isForfeit()
      ? milestone.get('price')
      : parseFloat(timeWorked * costPerUnit);
  }

  return (
    <>
      <span className={`${classes.title} ${spanClassname}`}>
        <span title={formattedTitle}>{t(formattedTitle)}</span>
      </span>
      <span className={spanClassname}>{renderUnit()}</span>
      <span className={spanClassname}>{renderAmount()}</span>
      <span className={spanClassname}>
        {priceFormat(costPerUnit, { minDigits: 2, maxDigits: 2 })}
        <span className="p-l-xxs">{t('currency.symbol')}</span>
      </span>
      <span className={spanClassname}>
        {priceFormat(renderPrice(), { minDigits: 2, maxDigits: 2 })}
        <span className="p-l-xxs">{t('currency.symbol')}</span>
      </span>
    </>
  );
}

MilestoneInformation.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  primary: string,
  costPerUnit: oneOfType([number, string]).isRequired,
  classes: shape({
    title: string,
  }),
};
MilestoneInformation.defaultProps = {
  primary: 'primary',
  classes: { title: null },
};

export default MilestoneInformation;
