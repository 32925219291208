import React from 'react';
import { node, arrayOf, string } from 'prop-types';
import cx from 'classnames';
import './styles.scss';

/**
 * Tag
 *
 *    - Display word inside a chip
 *    - Handle variants
 *
 * Often used for :
 *     - @/components/TagList
 */
const Tag = ({ children, className, variants }) => (
  <span className={cx('Tag', variants.map((v) => `Tag--${v}`), className)}>
    {children}
  </span>
);

Tag.propTypes = {
  children: node,
  variants: arrayOf(string),
  className: string,
};

Tag.defaultProps = {
  children: null,
  variants: [],
  className: null,
};

export default Tag;
