import React from 'react';
import { node, string, func, bool, arrayOf, objectOf, any } from 'prop-types';
import cx from 'classnames';
import { castArray } from 'lodash';
import { getLocation } from '@/selectors/location';
import Tooltip from '@/components/Tooltip';
import './styles.scss';

const wrapWithTooltip = (children, tooltipProps) => (
  <Tooltip {...tooltipProps}>{children}</Tooltip>
);

const wrapWithList = (children, items) => (
  <span className="Modality__wrapper">
    {children}
    <ul>
      {castArray(items).map((item) => {
        const location = getLocation(item);
        return <li key={location}>{location}</li>;
      })}
    </ul>
  </span>
);

const Modality = ({
  children,
  className,
  icon: Icon,
  spacer,
  list,
  variants,
  tooltip,
  label,
  contentClassName,
  fill,
}) => {
  const labelElem = (
    <span className="Modality__label-container">
      <Icon className="Modality__icon" {...fill && { fill }} />
      <span className="Modality__label">{label}</span>
      <span className="Modality__content">{children}</span>
    </span>
  );

  const elem = (
    <span
      className={cx(
        'Modality',
        {
          'Modality--withSpacer': spacer,
        },
        variants.map((v) => `Modality--${v}`),
        className
      )}
    >
      {Icon &&
        // eslint-disable-next-line no-nested-ternary
        (tooltip ? (
          !label ? (
            wrapWithTooltip(<Icon {...fill && { fill }} />, {
              ...tooltip,
              className: 'Modality__icon',
            })
          ) : (
            wrapWithTooltip(labelElem, { ...tooltip })
          )
        ) : label ? (
          labelElem
        ) : (
          <Icon className="Modality__icon" {...fill && { fill }} />
        ))}

      {!label && (
        <span className={`Modality__content ${contentClassName}`}>
          {children}
        </span>
      )}
    </span>
  );

  return list && list.length ? wrapWithList(elem, list) : elem;
};

Modality.propTypes = {
  children: node.isRequired,
  className: string,
  icon: func,
  spacer: bool,
  list: arrayOf(objectOf(any)),
  variants: arrayOf(string),
  contentClassName: string,
  fill: string,
};

Modality.defaultProps = {
  className: null,
  icon: null,
  spacer: false,
  list: [],
  variants: [],
  contentClassName: '',
  fill: null,
};

export default Modality;
