import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { requestForUpdateProposal } from '@/api/Proposal/mutations';
import { showFlash } from '@/redux/modules/flash';
import reduxForm from '@/helpers/enhancers/reduxForm';
import RequestUpdateModal from './RequestUpdateModal';

export default compose(
  graphql(requestForUpdateProposal, { name: 'requestForUpdateProposal' }),
  reduxForm({
    form: 'proposal_request_update',
  }),
  connect(
    null,
    { showFlash }
  )
)(RequestUpdateModal);
