export * from './router_base'; // eslint-disable-line

export const LANDING = '//www.kicklox.com';
export const LANDING_ENGINEER = `${LANDING}/ingenieur`;
export const LANDING_REFERENCES = `${LANDING}/references-liste`;
export const LANDING_CGU = `${LANDING}/conditions-generales-dutilisation`;
export const LANDING_BLOG = `${LANDING}/blog`;
export const LANDING_PRESS = `${LANDING}/presse`;
export const LANDING_VISION = `${LANDING}/vision`;
export const LANDING_SECTORS = `${LANDING}/secteurs`;
export const LANDING_SERVICES = `${LANDING}/#services`;
export const LANDING_LABEL = `${LANDING}/kicklox-label`;
export const LANDING_CGVU = `${LANDING}/cgvu`;
export const LANDING_COMPANY_FAQ = 'https://kicklox.tawk.help/';
export const LANDING_CANDIDAT_FAQ = 'https://candidat.tawk.help/';
export const LANDING_CONTACT = `${LANDING}/contact`;
export const LANDING_PARTNERSHIPS = `${LANDING}/partenariats`;
export const LANDING_PARTNERSHIPS_EXPERT = `${LANDING}/partenariats-experts-kicklox`;
export const LANDING_RECRUITMENT = 'https://kicklox.welcomekit.co/';
export const LANDING_HIRE = `${LANDING}/kicklox-hire`;
export const LANDING_FREELANCER = `${LANDING}/kicklox-freelancer`;
export const LANDING_GLOBAL = `${LANDING}/kicklox-global`;
export const LANDING_OPEN = `${LANDING}/open`;
export const LANDING_PLATFORM = `${LANDING}/kicklox-platform`;
export const LANDING_CLIENT_BLOG = `${LANDING}/blog-client`;
export const LANDING_EBOOKS = `${LANDING}/blog-tag/ebooks`;
export const LANDING_MYKICKLOX = `${LANDING}/mykicklox-offre-services-freelance`;
export const LANDING_SALARY_PORTAGE = `${LANDING}/portage-salarial-kicklox`;
export const LANDING_SKILLS_MAPPING = `${LANDING}/platform-skills-mapping`;
export const LANDING_INTELLIGENT_STAFFING = `${LANDING}/platform-intelligent-staffing`;
export const LANDING_WORKLOAD_MANAGEMENT = `${LANDING}/platform-workload-management`;
export const LANDING_FREELANCERS_MANAGEMENT = `${LANDING}/platform-freelancers-management`;
export const LANDING_PRICING = `${LANDING}/platform-pricing`;
export const LANDING_BLOG_PLATFORM = `${LANDING}/blog-platform`;
export const LANDING_EBOOKS_PLATFORM = `${LANDING}/blog-tag/ebooks-platform`;
export const FULLTIME_CONTRACT =
  '/missions?refinementList%5Bcontract_types%5D%5B0%5D=fulltime_contract&page=1&configure%5Bquery%5D=&query=';
export const MISSIONS_CONTRACT =
  '/missions?refinementList%5Bcontract_types%5D%5B0%5D=missions&page=1&configure%5Bquery%5D=&query=';

/* SOCIAL */
export const WELCOME = `https://www.welcometothejungle.co/companies/kicklox/jobs`;
export const FACEBOOK = 'https://www.facebook.com/kicklox';
export const TWITTER = 'https://twitter.com/kicklox_';
export const LINKEDIN = 'https://fr.linkedin.com/company/makake';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCCqzvGrVpyIAjDnuXcIk86Q';
