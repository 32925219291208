import React from 'react';
import { func } from 'prop-types';
import { Field, RfText } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import { url } from '@/helpers/validation';

const SocialNetworksSection = ({ t }) => (
  <div>
    <FormSection title={t('company.modal.modalities.social_networks.title')}>
      <Field
        id="linkedin"
        name="linkedin"
        component={RfText}
        label={t(`company.modal.modalities.social_networks.linkedin.title`)}
        placeholder={t('company.modal.modalities.social_networks.placeholder')}
        className="p-b-m"
        validate={[url({ allowBlank: true })]}
      />
      <Field
        id="facebook"
        name="facebook"
        component={RfText}
        label={t(`company.modal.modalities.social_networks.facebook.title`)}
        placeholder={t('company.modal.modalities.social_networks.placeholder')}
        className="p-b-m"
        validate={[url({ allowBlank: true })]}
      />
      <Field
        id="instagram"
        name="instagram"
        component={RfText}
        label={t(`company.modal.modalities.social_networks.instagram.title`)}
        placeholder={t('company.modal.modalities.social_networks.placeholder')}
        className="p-b-m"
        validate={[url({ allowBlank: true })]}
      />
      <Field
        id="twitter"
        name="twitter"
        component={RfText}
        label={t(`company.modal.modalities.social_networks.twitter.title`)}
        placeholder={t('company.modal.modalities.social_networks.placeholder')}
        className="p-b-m"
        validate={[url({ allowBlank: true })]}
      />
      <Field
        id="web_site"
        name="web_site"
        component={RfText}
        label={t(`company.modal.modalities.social_networks.web_site.title`)}
        placeholder={t('company.modal.modalities.social_networks.placeholder')}
        className="p-b-m"
        validate={[url({ allowBlank: true })]}
      />
    </FormSection>
  </div>
);

SocialNetworksSection.propTypes = {
  t: func.isRequired,
};

export default SocialNetworksSection;
