import { FACET, NESTED } from '@/components/Matching/Filters/presets';
import { handler as selectedFacetFilterHandler } from '@/redux/modules/matching/helpers/handlers/facet/filters';
import { handler as selectedNestedFilterHandler } from '@/redux/modules/matching/helpers/handlers/nested/filters';

/**
 * Selected filter factory
 * @returns {*}
 * @param kind
 * @param selected
 * @param selectedFilters
 * @param value
 * @param name
 * @param key
 * @param meta
 */
export function factory(
  kind,
  selected,
  selectedFilters,
  value,
  name,
  key,
  meta
) {
  switch (kind) {
    case FACET:
      return selectedFacetFilterHandler(selected, selectedFilters, value, name);
    case NESTED:
      return selectedNestedFilterHandler(value, key, meta);
    default:
      return {};
  }
}
