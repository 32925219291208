import React from 'react';
import { objectOf, any } from 'prop-types';
import { get, isEmpty, map } from 'lodash';
import { Switch, Route as RRoute } from 'react-router-dom';
import i18n from '@/i18n';
import TawkTo from 'tawkto-react';
import ActionCable from 'actioncable';
import { LOGOUT } from '@/constants/router';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PrivateRoute from './PrivateRoute';
import Route from './Route';
import routes from './routes';
import AbilityContext from '@/permissions/contexts/AbilityContext';
import ability from '@/permissions/abilities';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/permissions/../models/User';
import CountersContext from '@/contexts/CountersContext';
import { getToken, isTokenExpired } from '@/helpers/auth';
import { isSolo } from '@/helpers/company';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import { TAWK_TO_IDS } from '@/constants/tawkToIds';
import { api, tenant } from '@/constants/environment';
import config from '@/_config';

class ModalSwitch extends React.Component {
  previousLocation = this.props.location;

  constructor(props) {
    super(props);
    this.hasAlreadyIdentify = false;
    this.cable = null;
    this.tawk = null;
    this.state = {
      counters: {},
      lock: false,
    };
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props;
    if (
      nextProps.history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location;
    }
  }

  componentDidUpdate(prevProps) {
    if (config.global.tawkTo) {
      // When user logout
      if (prevProps.currentUser === null && this.tawk !== null) {
        this.resetTawkToAttributes();
      }

      // Init tawk to and attributes
      if (this.tawk === null && prevProps.currentUser !== null) {
        this.initTawkTo();
      }
    }
  }

  initTawkTo = () => {
    const { propertyId, widgetId } = TAWK_TO_IDS[this.props.currentUser.kind][
      isEmpty(this.props.currentUser.language_alpha2)
        ? 'fr'
        : this.props.currentUser.language_alpha2
    ];
    this.tawk = new TawkTo(propertyId, widgetId);
    this.tawk.setAttributes({
      name: `${get(this.props.currentUser, 'first_name')} ${get(
        this.props.currentUser,
        'last_name'
      )}`,
      email: get(this.props.currentUser, 'email'),
    });
  };

  resetTawkToAttributes = () => {
    this.tawk.endChat();
    this.tawk.minimize();
    this.tawk.hideWidget();
  };

  handleCloseModal = (e) => {
    e.stopPropagation();
    this.props.history.goBack();
  };

  renderRoutes = () =>
    map(
      routes,
      (
        { authenticated, header, footer, component: Component, ...options },
        key
      ) => {
        const RouteComponent = options.path === LOGOUT ? RRoute : Route;
        return (
          <RouteComponent
            {...options}
            {...authenticated && { routeComponent: PrivateRoute }}
            key={key}
            history={this.props.history}
            render={(props) => (
              <>
                {header && <Header history={this.props.history} />}
                <Component {...props} />
                {footer && <Footer />}
              </>
            )}
          />
        );
      }
    );

  renderModalRoutes = () =>
    map(routes, ({ modal, component, ...options }, key) => {
      if (!modal) return null;
      const user = new User(this.props.currentUser);

      // Logic to display the offline or normal component
      const offlineComponent = get(options, 'offlineComponent');
      const Component =
        offlineComponent && !user.get('id') ? offlineComponent : component;

      return (
        <UserContext.Provider value={user}>
          <AbilityContext.Provider value={ability(user, config)}>
            <RRoute
              {...options}
              currentUser={this.props.currentUser}
              key={key}
              history={this.props.history}
              render={(props) => (
                <Component
                  isModal
                  close={this.handleCloseModal}
                  {...props}
                  state={get(this.props, 'location.state')}
                />
              )}
            />
          </AbilityContext.Provider>
        </UserContext.Provider>
      );
    });

  renderLocation = (isModal) => {
    const { location, currentUser } = this.props;
    if (!currentUser) return location;
    return isModal ? this.previousLocation : location;
  };

  render() {
    const { location, currentUser } = this.props;
    const { lock, counters } = this.state;
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    );

    if (currentUser && !this.hasAlreadyIdentify) {
      const { analytics } = window;
      window.usetifulTags = {
        tenant,
        user_type: get(currentUser, 'kind'),
        language: get(currentUser, 'language_alpha2'),
        filled: get(currentUser, 'is_filled').toString(),
        accepted_latest_cgu: get(currentUser, 'accepted_latest_cgu').toString(),
        score: get(currentUser, 'relevance.current_score'),
        solo: isSolo(get(currentUser, 'company.kind')),
        administrator: get(currentUser, 'administrator').toString(),
        signed_in_as_user: get(currentUser, 'signed_in_as_user')
          ? 'admin'
          : 'not_admin',
      };
      if (analytics.initalize) {
        analytics.initalize();
      }
      if (analytics.load) {
        analytics.load(process.env.REACT_APP_SEGMENT_KEY);
      }
      if (analytics.identify) {
        analytics.identify(get(currentUser, 'id'), {
          email: get(currentUser, 'email'),
          createdAt: get(currentUser, 'created_at'),
          language: get(currentUser, 'language_alpha2') || i18n.language,
          tenant,
        });
      }
      if (analytics.page) {
        analytics.page({
          tenant,
        });
      }
      this.hasAlreadyIdentify = true;
    }

    if (!isTokenExpired() && !lock && currentUser) {
      this.setState({ lock: true });
      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      this.cable = ActionCable.createConsumer(
        `${protocol}://${api}/cable?access_token=${getToken()}`
      );
      this.cable.subscriptions.create(
        {
          channel: `CountersChannel`,
        },
        {
          received: (data) => {
            this.setState({ counters: data });
          },
        }
      );
    }

    if (
      this.cable &&
      location.pathname === LOGOUT &&
      Object.values(this.state.counters).length !== 0
    ) {
      this.cable.disconnect();
      this.setState({ lock: false, counters: {} });
    }

    return (
      <CountersContext.Provider value={counters}>
        <Switch location={this.renderLocation(isModal)}>
          {this.renderRoutes()}
        </Switch>
        {isModal ? this.renderModalRoutes() : null}
      </CountersContext.Provider>
    );
  }
}

ModalSwitch.propTypes = {
  location: objectOf(any).isRequired,
  history: objectOf(any).isRequired,
};

export default withCurrentUser(ModalSwitch);
