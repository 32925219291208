import React from 'react';
import pp from 'prop-types';
import { RequiredField, RfText } from '@/containers/ReduxForm';
import BoardModal from '../../BoardModal';

function UpdateListModal({ t, ...otherProps }) {
  return (
    <BoardModal
      size="medium"
      name="Board-UpdateList"
      title={t('myMissionBoard.modal.update.title')}
      form="board_updateList"
      {...otherProps}
    >
      <RequiredField
        id="name"
        name="name"
        label={t('myMissionBoard.modal.update.name.label')}
        component={RfText}
        className="m-b-m"
      />
    </BoardModal>
  );
}

UpdateListModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
};

export default UpdateListModal;
