export const expertKeysVariables = (t) => ({
  expert_naming: t('expert_naming'),
  an_expert_naming: t('an_expert_naming'),
  the_expert_naming: t('the_expert_naming'),
  of_the_expert_naming: t('of_the_expert_naming'),
  of_expert_naming: t('of_expert_naming'),
  of_experts_naming: t('of_experts_naming'),
  this_expert_naming: t('this_expert_naming'),
  to_the_expert_naming: t('to_the_expert_naming'),
  expert_naming_cap: t('expert_naming_cap'),
  an_expert_naming_cap: t('an_expert_naming_cap'),
  the_expert_naming_cap: t('the_expert_naming_cap'),
  of_the_expert_naming_cap: t('of_the_expert_naming_cap'),
  of_expert_naming_cap: t('of_expert_naming_cap'),
  of_experts_naming_cap: t('of_experts_naming_cap'),
  this_expert_naming_cap: t('this_expert_naming_cap'),
  to_the_expert_naming_cap: t('to_the_expert_naming_cap'),
});

export const clientKeysVariables = (t) => ({
  client_naming: t('client_naming'),
  an_client_naming: t('an_client_naming'),
  the_client_naming: t('the_client_naming'),
  of_the_client_naming: t('of_the_client_naming'),
  of_client_naming: t('of_client_naming'),
  of_clients_naming: t('of_clients_naming'),
  this_client_naming: t('this_client_naming'),
  to_the_client_naming: t('to_the_client_naming'),
  client_naming_cap: t('client_naming_cap'),
  an_client_naming_cap: t('an_client_naming_cap'),
  the_client_naming_cap: t('the_client_naming_cap'),
  of_the_client_naming_cap: t('of_the_client_naming_cap'),
  of_client_naming_cap: t('of_client_naming_cap'),
  of_clients_naming_cap: t('of_clients_naming_cap'),
  this_client_naming_cap: t('this_client_naming_cap'),
  to_the_client_naming_cap: t('to_the_client_naming_cap'),
});

export const missionKeysVariables = (t) => ({
  mission_naming: t('mission_naming'),
  an_mission_naming: t('an_mission_naming'),
  the_mission_naming: t('the_mission_naming'),
  of_the_mission_naming: t('of_the_mission_naming'),
  of_mission_naming: t('of_mission_naming'),
  of_missions_naming: t('of_missions_naming'),
  this_mission_naming: t('this_mission_naming'),
  to_the_mission_naming: t('to_the_mission_naming'),
  mission_naming_cap: t('mission_naming_cap'),
  an_mission_naming_cap: t('an_mission_naming_cap'),
  the_mission_naming_cap: t('the_mission_naming_cap'),
  of_the_mission_naming_cap: t('of_the_mission_naming_cap'),
  of_mission_naming_cap: t('of_mission_naming_cap'),
  of_missions_naming_cap: t('of_missions_naming_cap'),
  this_mission_naming_cap: t('this_mission_naming_cap'),
  to_the_mission_naming_cap: t('to_the_mission_naming_cap'),
});

export const companyKeysVariables = (t) => ({
  company_naming: t('company_naming'),
  an_company_naming: t('an_company_naming'),
  the_company_naming: t('the_company_naming'),
  of_the_company_naming: t('of_the_company_naming'),
  of_company_naming: t('of_company_naming'),
  of_companys_naming: t('of_companys_naming'),
  this_company_naming: t('this_company_naming'),
  to_the_company_naming: t('to_the_company_naming'),
  company_naming_cap: t('company_naming_cap'),
  an_company_naming_cap: t('an_company_naming_cap'),
  the_company_naming_cap: t('the_company_naming_cap'),
  of_the_company_naming_cap: t('of_the_company_naming_cap'),
  of_company_naming_cap: t('of_company_naming_cap'),
  of_companys_naming_cap: t('of_companys_naming_cap'),
  this_company_naming_cap: t('this_company_naming_cap'),
  to_the_company_naming_cap: t('to_the_company_naming_cap'),
});

export const skillKeysVariables = (t) => ({
  skill_naming: t('skill_naming'),
  an_skill_naming: t('an_skill_naming'),
  the_skill_naming: t('the_skill_naming'),
  of_the_skill_naming: t('of_the_skill_naming'),
  of_skill_naming: t('of_skill_naming'),
  of_skills_naming: t('of_skills_naming'),
  this_skill_naming: t('this_skill_naming'),
  to_the_skill_naming: t('to_the_skill_naming'),
  skill_naming_cap: t('skill_naming_cap'),
  an_skill_naming_cap: t('an_skill_naming_cap'),
  the_skill_naming_cap: t('the_skill_naming_cap'),
  of_the_skill_naming_cap: t('of_the_skill_naming_cap'),
  of_skill_naming_cap: t('of_skill_naming_cap'),
  of_skills_naming_cap: t('of_skills_naming_cap'),
  this_skill_naming_cap: t('this_skill_naming_cap'),
  to_the_skill_naming_cap: t('to_the_skill_naming_cap'),
});

export const contractKeysVariables = (t) => ({
  contract_naming: t('contract_naming'),
  an_contract_naming: t('an_contract_naming'),
  the_contract_naming: t('the_contract_naming'),
  of_the_contract_naming: t('of_the_contract_naming'),
  of_contract_naming: t('of_contract_naming'),
  of_contracts_naming: t('of_contracts_naming'),
  this_contract_naming: t('this_contract_naming'),
  to_the_contract_naming: t('to_the_contract_naming'),
  contract_naming_cap: t('contract_naming_cap'),
  an_contract_naming_cap: t('an_contract_naming_cap'),
  the_contract_naming_cap: t('the_contract_naming_cap'),
  of_the_contract_naming_cap: t('of_the_contract_naming_cap'),
  of_contract_naming_cap: t('of_contract_naming_cap'),
  of_contracts_naming_cap: t('of_contracts_naming_cap'),
  this_contract_naming_cap: t('this_contract_naming_cap'),
  to_the_contract_naming_cap: t('to_the_contract_naming_cap'),
});

export const applicationKeysVariables = (t) => ({
  application_naming: t('application_naming'),
  an_application_naming: t('an_application_naming'),
  the_application_naming: t('the_application_naming'),
  of_the_application_naming: t('of_the_application_naming'),
  of_application_naming: t('of_application_naming'),
  of_applications_naming: t('of_applications_naming'),
  this_application_naming: t('this_application_naming'),
  to_the_application_naming: t('to_the_application_naming'),
  application_naming_cap: t('application_naming_cap'),
  an_application_naming_cap: t('an_application_naming_cap'),
  the_application_naming_cap: t('the_application_naming_cap'),
  of_the_application_naming_cap: t('of_the_application_naming_cap'),
  of_application_naming_cap: t('of_application_naming_cap'),
  of_applications_naming_cap: t('of_applications_naming_cap'),
  this_application_naming_cap: t('this_application_naming_cap'),
  to_the_application_naming_cap: t('to_the_application_naming_cap'),
});

export const proposalKeysVariables = (t) => ({
  proposal_naming: t('proposal_naming'),
  an_proposal_naming: t('an_proposal_naming'),
  proposal_naming_cap: t('proposal_naming_cap'),
  to_proposal_naming: t('to_proposal_naming'),
  the_proposal_naming: t('the_proposal_naming'),
});

export const otherKeysVariables = (t) => ({
  platform_naming: t('platform_naming'),
});

export const contextKeys = {
  'tags.add.form.label': skillKeysVariables,
  'signup.modal.submit': otherKeysVariables,
  'settings.notifications.projects.mission': missionKeysVariables,
  'salary.confidentiality': clientKeysVariables,
  'relevanceScore.tooltip.text': missionKeysVariables,
  'relevanceScore.actions.company.candidacies': missionKeysVariables,
  'relevance_score_information.modal.explanation': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'proposal.status.review_admin.tooltip': otherKeysVariables,
  'proposal.section.quote.message.technical_assistance.text': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'proposal.section.mission.projectDesc.receiver.label': expertKeysVariables,
  'proposal.section.mission.projectDesc.label': clientKeysVariables,
  'proposal.section.mission.goals.receiver.label': expertKeysVariables,
  'proposal.section.mission.goals.label': clientKeysVariables,
  'proposal.section.mission.deliverables.receiver.label': expertKeysVariables,
  'proposal.section.mission.deliverables.label': clientKeysVariables,
  'proposal.section.mission.comment.receiver.label': expertKeysVariables,
  'proposal.section.indemnity.title': otherKeysVariables,
  'proposal.section.indemnity.message.text': [
    otherKeysVariables,
    missionKeysVariables,
  ],
  'proposal.section.indemnity.choice.without_indemnity': missionKeysVariables,
  'proposal.section.indemnity.choice.with_indemnity': [
    otherKeysVariables,
    missionKeysVariables,
  ],
  'proposal.modal.quote.work_hours.technical_assistance.title_plural': clientKeysVariables,
  'proposal.modal.quote.work_hours.technical_assistance.title': clientKeysVariables,
  'proposal.modal.quote.work_hours.forfeit.title_plural': clientKeysVariables,
  'proposal.modal.quote.work_hours.forfeit.title': clientKeysVariables,
  'profile.form.skills_required.helper': skillKeysVariables,
  'profile.form.skills_required.description': skillKeysVariables,
  'postSignup.you.title': otherKeysVariables,
  'postSignup.tjm.helper': [otherKeysVariables, missionKeysVariables],
  'postSignup.skills.title': skillKeysVariables,
  'postSignup.skills.tip.title2': skillKeysVariables,
  'postSignup.skills.tip.title': skillKeysVariables,
  'postSignup.skills.tip.subtitle': [skillKeysVariables, missionKeysVariables],
  'postSignup.skills.label': skillKeysVariables,
  'postSignup.remuneration.hourly_rate.label': missionKeysVariables,
  'postSignup.platform_recommendation.title': otherKeysVariables,
  'postSignup.platform_recommendation.label': otherKeysVariables,
  'postSignup.personal_skills.title': skillKeysVariables,
  'postSignup.job.tip.subtitle': skillKeysVariables,
  'postSignup.expertises.title': skillKeysVariables,
  'postSignup.expertises.label': skillKeysVariables,
  'postSignup.contractTypes.tip.subtitle': missionKeysVariables,
  'postSignup.availability.tip.subtitle': missionKeysVariables,
  'post_signup.form.skills.description': skillKeysVariables,
  'post_signup.form.skills_required.description': skillKeysVariables,
  'offline.apply.modal.title.subtitle': missionKeysVariables,
  'offline.apply.modal.title': missionKeysVariables,
  'offline.apply.modal.form.message.helper': [
    clientKeysVariables,
    missionKeysVariables,
  ],
  'offline.apply.modal.form.attachment.helper': clientKeysVariables,
  'offline.apply.modal.form.accept.label': otherKeysVariables,
  'offline_mission.modal.applied.partner.title': missionKeysVariables,
  'notifications.recommendation_platform_published.title': otherKeysVariables,
  'notifications.proposal_review_client_expert.title': clientKeysVariables,
  'notifications.proposal_review_client_expert.text': [
    clientKeysVariables,
    otherKeysVariables,
    missionKeysVariables,
  ],
  'notifications.proposal_review_client_client.text': missionKeysVariables,
  'notifications.proposal_review_admin.text': missionKeysVariables,
  'notifications.proposal_refused.text': missionKeysVariables,
  'notifications.proposal_archived.text': [
    expertKeysVariables,
    missionKeysVariables,
  ],
  'notifications.proposal_accepted.text': missionKeysVariables,
  'notifications.mission_updated.text': missionKeysVariables,
  'notifications.mission_transferred_expert.text': missionKeysVariables,
  'notifications.mission_transferred_client.title': missionKeysVariables,
  'notifications.mission_transferred_client.text': missionKeysVariables,
  'notifications.mission_sign_nda.text': [
    expertKeysVariables,
    missionKeysVariables,
  ],
  'notifications.mission_published.title': missionKeysVariables,
  'notifications.mission_published.text': missionKeysVariables,
  'notifications.mission_invitation.title': clientKeysVariables,
  'notifications.mission_invitation.text': missionKeysVariables,
  'notifications.mission_invitation_refused.text': [
    expertKeysVariables,
    missionKeysVariables,
  ],
  'notifications.mission_candidate.text': missionKeysVariables,
  'notifications.mission_archived_client.text': missionKeysVariables,
  'notifications.matching_mission.text': missionKeysVariables,
  'notifications.contract_modification_client_reject.text': clientKeysVariables,
  'notifications.contract_modification_client_accept.text': clientKeysVariables,
  'notifications.contract_modification_admin_reject.text': otherKeysVariables,
  'notifications.contract_modification_admin_accept_expert.text': otherKeysVariables,
  'notifications.contract_delivery_x_days_ago.text': missionKeysVariables,
  'notifications.contract_delivery_two_days_ago.text': missionKeysVariables,
  'notifications.contract_delivery_today.text': missionKeysVariables,
  'notifications.contract_delivery_in_two_days.text': missionKeysVariables,
  'notifications.contract_delivery_in_seven_days.text': missionKeysVariables,
  'notifications.contract_delivery_client_reject.text': clientKeysVariables,
  'notifications.contract_delivery_client_accept.text': clientKeysVariables,
  'notifications.contract_delivery_admin_reject.text': otherKeysVariables,
  'notifications.contract_delivery_admin_accept_expert.text': otherKeysVariables,
  'notifications.contract_creation_client_reject.text': clientKeysVariables,
  'notifications.contract_creation_client_accept.text': clientKeysVariables,
  'notifications.contract_creation_admin_reject.text': otherKeysVariables,
  'notifications.contract_creation_admin_accept_expert.text': otherKeysVariables,
  'notifications.contract_cancelation_client_reject.text': clientKeysVariables,
  'notifications.contract_cancelation_client_accept.text': clientKeysVariables,
  'notifications.contract_cancelation_admin_reject.text': otherKeysVariables,
  'notifications.contract_cancelation_admin_accept_expert.text': otherKeysVariables,
  'notifications.company_invitation.title': otherKeysVariables,
  'notifications.mission_invite_to_create_proposal.text': [
    missionKeysVariables,
    clientKeysVariables,
    proposalKeysVariables,
  ],
  'notifications.mission_invite_to_create_proposal.title': proposalKeysVariables,
  'myMissions.title': missionKeysVariables,
  'myMissions.noResults.action.label': missionKeysVariables,
  'myMissions.noResults': missionKeysVariables,
  'myMissionMatches.subtitle_plural': expertKeysVariables,
  'myMissionMatches.subtitle': expertKeysVariables,
  'myMissionMatches.empty': expertKeysVariables,
  'myMissionInvites.title': missionKeysVariables,
  'myMissionInvites.subtitle': expertKeysVariables,
  'myMissionInvites.empty': expertKeysVariables,
  'myMissionBoard.modal.archive.title': missionKeysVariables,
  'myMissionBoard.modal.archive.kind.no_news_from_client': clientKeysVariables,
  'myMissionBoard.modal.archive.kind.cancelled_by_client': clientKeysVariables,
  'myMissionBoard.menu.edit': missionKeysVariables,
  'myMissionBoard.menu.duplicate': missionKeysVariables,
  'myMissionBoard.menu.archive': missionKeysVariables,
  'myMissionBoard.lock_for_review_mission.tooltip.title': missionKeysVariables,
  'myMissionBoard.info2.review': [otherKeysVariables, missionKeysVariables],
  'myApplications.invitations.title': clientKeysVariables,
  'modalities.vip.tooltip.title': missionKeysVariables,
  'modalities.validated_at.x_days': missionKeysVariables,
  'modalities.validated_at.today': missionKeysVariables,
  'modalities.validated_at.one_day': missionKeysVariables,
  'modalities.validated_at': missionKeysVariables,
  'modalities.tenant_offer.tooltip.title': otherKeysVariables,
  'modalities.remuneration.nda': missionKeysVariables,
  'missions.too_much_results': missionKeysVariables,
  'missions.results_plural': missionKeysVariables,
  'missions.results': missionKeysVariables,
  'missions.noResults': missionKeysVariables,
  'missions.no_access_matching.results': missionKeysVariables,
  'missions.matches.search.message.label': missionKeysVariables,
  'missions.matches.results_plural': missionKeysVariables,
  'missions.matches.results': missionKeysVariables,
  'missions.matches.noResults': missionKeysVariables,
  'missionCreator.salaryConfidentiality.label': missionKeysVariables,
  'missionCreator.modalities.title': missionKeysVariables,
  'missionCreator.modal.vip.text': missionKeysVariables,
  'missionCreator.modal.vip_nda.text': missionKeysVariables,
  'missionCreator.modal.nda.text': missionKeysVariables,
  'missionCreator.location.title': missionKeysVariables,
  'missionCreator.goals.title.job': missionKeysVariables,
  'missionCreator.goals.tasks.label': expertKeysVariables,
  'missionCreator.experience.title': expertKeysVariables,
  'missionCreator.endDate.approximative.label': missionKeysVariables,
  'missionCreator.contractType.label': expertKeysVariables,
  'missionCreator.confidentiality.cantUpdate': missionKeysVariables,
  'mission.transferred.client': missionKeysVariables,
  'mission.section.tenant_offer.tag.text': [
    otherKeysVariables,
    missionKeysVariables,
  ],
  'mission.section.tenant_offer.content': [
    otherKeysVariables,
    skillKeysVariables,
  ],
  'mission.section.partner_offer.tag.text': missionKeysVariables,
  'mission.section.mission.title': missionKeysVariables,
  'mission.section.mission.projectDesc.title': missionKeysVariables,
  'mission.section.engineer.skills.uimm.subtitle': [
    missionKeysVariables,
    skillKeysVariables,
  ],
  'mission.section.engineer.skills.title': skillKeysVariables,
  'mission.section.engineer.optional_skills.title': skillKeysVariables,
  'mission.section.engineer.skills.subtitle': skillKeysVariables,
  'mission.remuneration.estimated_hourly_rate': missionKeysVariables,
  'mission.relevanceScore.message.information': missionKeysVariables,
  'mission.nda.tooltip.title': expertKeysVariables,
  'mission.modal.apply.message.helper': [
    missionKeysVariables,
    clientKeysVariables,
  ],
  'mission.modal.apply.candidate.label': missionKeysVariables,
  'mission.modal.applied.pricing2': missionKeysVariables,
  'mission.form.skills.description': skillKeysVariables,
  'mission.form.skills_required.description': [
    skillKeysVariables,
    expertKeysVariables,
  ],
  'mission.form.skills_required.count.helper_plural': skillKeysVariables,
  'mission.form.skills_required.count.helper': skillKeysVariables,
  'mission.confidentiality.logo': clientKeysVariables,
  'mission.blacklist': missionKeysVariables,
  'mission.apply': missionKeysVariables,
  'mission_view.archived.helper.button': missionKeysVariables,
  'mission_view.archived.helper': missionKeysVariables,
  'milestone.technical_assistance.footer.update': clientKeysVariables,
  'milestone.technical_assistance.footer.create': clientKeysVariables,
  'milestone.indemnity.title': otherKeysVariables,
  'milestone.forfeit.footer.update': clientKeysVariables,
  'milestone.forfeit.footer.create': clientKeysVariables,
  'invoices.message.text.middle.expert': otherKeysVariables,
  'invoices.message.link.applications': missionKeysVariables,
  'invoice.name.expert': clientKeysVariables,
  'header.search.experts.all_result.label': expertKeysVariables,
  'header.search.all_result.label': missionKeysVariables,
  'header.nav.signup.client': missionKeysVariables,
  'header.nav.clients': clientKeysVariables,
  'header.nav.experts': expertKeysVariables,
  'header.nav.myMissions': missionKeysVariables,
  'header.nav.newMission': missionKeysVariables,
  'fields.validators.tag_max_length': skillKeysVariables,
  'fields.validators.skills.tooShort': skillKeysVariables,
  'experts.results': expertKeysVariables,
  'experts.results_plural': expertKeysVariables,
  'experts.too_much_results': expertKeysVariables,
  'fields.blacklist.reason.bad_skills': [
    missionKeysVariables,
    skillKeysVariables,
  ],
  'fields.kinds.client': clientKeysVariables,
  'fields.kinds.client.signup': clientKeysVariables,
  'fields.kinds.expert': expertKeysVariables,
  'fields.kinds.expert.signup': expertKeysVariables,
  'fields.kinds.mixed.signup': [expertKeysVariables, clientKeysVariables],
  'fields.nda.modal.subtitle': missionKeysVariables,
  'fields.validators.invalid_tags.one': skillKeysVariables,
  'fields.validators.invalid_tags.other': skillKeysVariables,
  'experts.noResults': expertKeysVariables,
  'experts.filters.name_anonymous.title': expertKeysVariables,
  'experts.filters.experiences_company_name.title': expertKeysVariables,
  'expert.collaborate': missionKeysVariables,
  'conversations.metas.mission.title': missionKeysVariables,
  'conversations.metas.mission.status.review.tooltip.title': missionKeysVariables,
  'conversations.action.mission': missionKeysVariables,
  'conversations.action.create_proposal': proposalKeysVariables,
  'conversations.system_message.link.proposal': proposalKeysVariables,
  'conversation.modal.invite_to_create_proposal.title': proposalKeysVariables,
  'conversation.modal.invite_to_create_proposal.subtitle': [
    expertKeysVariables,
    proposalKeysVariables,
  ],
  'conversations.action.invite_to_create_proposal': proposalKeysVariables,
  'contract.milestone.technical_assistance.subtitle.deliver': clientKeysVariables,
  'contract.milestone.subtitle.update_moderation': expertKeysVariables,
  'contract.milestone.subtitle.deliver.moderation': expertKeysVariables,
  'contract.milestone.subtitle.deliver': clientKeysVariables,
  'contract.milestone.subtitle.create_moderation': expertKeysVariables,
  'contract.milestone.subtitle.cancel_moderation': expertKeysVariables,
  'contract.milestone.modification.deliver': expertKeysVariables,
  'contract.milestone.message.expert.rejected': clientKeysVariables,
  'contract.milestone.message.expert.modification_review_client': clientKeysVariables,
  'contract.milestone.message.expert.modification_review_admin': otherKeysVariables,
  'contract.milestone.message.expert.delivery_review_client': clientKeysVariables,
  'contract.milestone.message.expert.delivery_review_admin': otherKeysVariables,
  'contract.milestone.message.expert.creation_review_client': clientKeysVariables,
  'blacklist.modal.reason': missionKeysVariables,
  'blacklist.modal.subtitle': missionKeysVariables,
  'blacklist.modal.title': missionKeysVariables,
  'bookmark.empty': expertKeysVariables,
  'bookmark.empty.cta': expertKeysVariables,
  'bookmark.subtitle': expertKeysVariables,
  'bookmarks.card.experts': expertKeysVariables,
  'bookmarks.empty': expertKeysVariables,
  'bookmarks.modal.add.addToLists.empty': expertKeysVariables,
  'bookmarks.modal.add.subtitle': expertKeysVariables,
  'bookmarks.modal.remove.title': expertKeysVariables,
  'candidacy.message.text.mandatory.document': missionKeysVariables,
  'candidacy.modal.archived.archived_text.title': clientKeysVariables,
  'client.collaborate': clientKeysVariables,
  'company.modal.apply.candidate.remuneration.tjm.helper': missionKeysVariables,
  'company.modal.apply.remuneration.helper': clientKeysVariables,
  'company.modal.apply.remuneration.tjm.helper': missionKeysVariables,
  'company.modal.certification.skills.label': skillKeysVariables,
  'company.modal.converse.label': expertKeysVariables,
  'company.modal.invite.empty': missionKeysVariables,
  'company.modal.invite.has_already_candidate': expertKeysVariables,
  'company.modal.invite.has_blacklisted': expertKeysVariables,
  'company.modal.invite.list.subtitle': [
    missionKeysVariables,
    expertKeysVariables,
  ],
  'company.modal.invite.subtitle': [missionKeysVariables, expertKeysVariables],
  'company.modal.invite.title': missionKeysVariables,
  'company.modal.modalities.company.title': companyKeysVariables,
  'company.section.created_at.tooltip.title': expertKeysVariables,
  'company.section.last_signin_at.tooltip.title': expertKeysVariables,
  'company.section.skills.placeholder.cta': skillKeysVariables,
  'company.section.skills.placeholder.text': skillKeysVariables,
  'company.section.skills.technical.title': skillKeysVariables,
  'company.section.sync.description': missionKeysVariables,
  'company.section.updated_at.tooltip.title': expertKeysVariables,
  'contract.milestone.confirm.label.deliver': clientKeysVariables,
  'contract.milestone.message.client.cancellation_review': expertKeysVariables,
  'contract.milestone.message.client.creation_review': expertKeysVariables,
  'contract.milestone.message.client.delivery_review': expertKeysVariables,
  'contract.milestone.message.client.modification_review': expertKeysVariables,
  'contract.milestone.message.expert.cancellation_review_admin': otherKeysVariables,
  'contract.milestone.message.expert.cancellation_review_client': clientKeysVariables,
  'contract.milestone.message.expert.creation_review_admin': otherKeysVariables,
  'missionCreator.endDate.label': contractKeysVariables,
  'missionCreator.endDate.label.job': contractKeysVariables,
  'missionCreator.endDate.title': contractKeysVariables,
  'missionCreator.goals.deliverables.label': contractKeysVariables,
  'missionCreator.goals.title': contractKeysVariables,
  'search.suggestions.filter.mission': missionKeysVariables,
  'search.suggestions.filter.company': expertKeysVariables,
  'search.list.filtered.title': [missionKeysVariables, expertKeysVariables],
  'myMissionBoard.menu.see': missionKeysVariables,
  'auth.signup.subtitle.client': [expertKeysVariables, skillKeysVariables],
  'auth.signup.subtitle.expert': missionKeysVariables,
  'auth.signup.subtitle.mixed': [
    missionKeysVariables,
    expertKeysVariables,
    skillKeysVariables,
  ],
  'candidacy.message.create.title': applicationKeysVariables,
  'candidacy.modal.archived.archived_kind.title': applicationKeysVariables,
  'company.section.candidacies_many': applicationKeysVariables,
  'company.section.candidacies_one': applicationKeysVariables,
  'company.section.candidacies.tooltip.title': applicationKeysVariables,
  'expert.filters.skills.title': skillKeysVariables,
  'experts.filters.companyType.title': expertKeysVariables,
  'experts.filters.skills.title': skillKeysVariables,
  'extension.with_user_kind_validation_modal.content': applicationKeysVariables,
  'fields.candidacy.choice.format.placeholder': applicationKeysVariables,
  'flash.error.mission.cannot_archive': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'header.nav.applications': applicationKeysVariables,
  'header.nav.myApplications': applicationKeysVariables,
  invitation_message_link_applications: applicationKeysVariables,
  'mission.card.title.expert_interested_at': applicationKeysVariables,
  'mission.form.skills.title': skillKeysVariables,
  'mission.modal.already.applied.title': applicationKeysVariables,
  'mission.modal.applied.ok': applicationKeysVariables,
  'mission.modal.applied.pricing': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'mission.modal.applied.title': applicationKeysVariables,
  'mission.modal.apply.message.label': [
    clientKeysVariables,
    applicationKeysVariables,
  ],
  'mission.modal.apply.title': [missionKeysVariables, applicationKeysVariables],
  'missionCreator.billings.applicants_number.label': [
    expertKeysVariables,
    missionKeysVariables,
  ],
  'missionCreator.confidentiality.vip': [
    expertKeysVariables,
    missionKeysVariables,
  ],
  'missionCreator.context.name.label': missionKeysVariables,
  'missionCreator.context.name.placeholder': missionKeysVariables,
  'missionCreator.context.projectDesc.label': missionKeysVariables,
  'missionCreator.context.title': missionKeysVariables,
  'missionCreator.endDate.title.job': contractKeysVariables,
  'missionCreator.experience.helper': expertKeysVariables,
  'missionCreator.experience.label': expertKeysVariables,
  'missionCreator.job.label': expertKeysVariables,
  'missionCreator.limitDate.title': applicationKeysVariables,
  'missionCreator.location.title.job': missionKeysVariables,
  'missionCreator.mission.title': missionKeysVariables,
  'missionCreator.mobility.label': missionKeysVariables,
  'missionCreator.mobility.label.job': missionKeysVariables,
  'missionCreator.startDate.label': expertKeysVariables,
  'missionCreator.startDate.title': missionKeysVariables,
  'missionCreator.startDate.title.job': missionKeysVariables,
  'missionCreator.summary.label': missionKeysVariables,
  'missionCreator.workRate.label': missionKeysVariables,
  'modalities.limitDate.precise': applicationKeysVariables,
  'modalities.limitdate.precise.tooltip.title': applicationKeysVariables,
  'modalities.limitDate.tooltip.title': applicationKeysVariables,
  'myApplications.myApplications.title': applicationKeysVariables,
  'myApplications.noResults': applicationKeysVariables,
  'myApplications.title': applicationKeysVariables,
  'myMissionBoard.card.menu.archive': applicationKeysVariables,
  'myMissionBoard.card.menu.move': applicationKeysVariables,
  'myMissionBoard.card.menu.title': applicationKeysVariables,
  'myMissionBoard.info.archived': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'myMissionBoard.info.review': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'myMissionBoard.info.text': [missionKeysVariables, applicationKeysVariables],
  'myMissionBoard.modal.archive_candidacy.subtitle': applicationKeysVariables,
  'myMissionBoard.modal.archive_candidacy.title': applicationKeysVariables,
  'myMissionBoard.modal.archive.card': applicationKeysVariables,
  'myMissionBoard.modal.archive.mission': applicationKeysVariables,
  'myMissionBoard.card.menu.proposal_create': proposalKeysVariables,
  'notifications.candidacy_accepted.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.candidacy_accepted.title': applicationKeysVariables,
  'notifications.candidacy_archived_mission_owner.text': [
    missionKeysVariables,
    applicationKeysVariables,
    expertKeysVariables,
  ],
  'notifications.candidacy_archived_mission_owner.title': applicationKeysVariables,
  'notifications.candidacy_archived.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.candidacy_archived.title': applicationKeysVariables,
  'notifications.candidacy_move_step_four.text': missionKeysVariables,
  'notifications.candidacy_move_step_four.title': applicationKeysVariables,
  'notifications.candidacy_move_step_one.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.candidacy_move_step_one.title': applicationKeysVariables,
  'notifications.candidacy_move_step_three.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.candidacy_move_step_three.title': applicationKeysVariables,
  'notifications.candidacy_move_step_two.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.candidacy_move_step_two.title': applicationKeysVariables,
  'notifications.mission_archived_expert.text': [
    missionKeysVariables,
    applicationKeysVariables,
  ],
  'notifications.mission_candidate.title': applicationKeysVariables,
  'notifications.clientpool_expert_added.text': [
    clientKeysVariables,
    expertKeysVariables,
  ],
  'offline_mission.modal.applied.partner.subtitle': applicationKeysVariables,
  'offline_mission.modal.applied.title': applicationKeysVariables,
  'offline.apply.modal.button.ok.text': applicationKeysVariables,
  'offline.apply.modal.form.message.label': [
    clientKeysVariables,
    applicationKeysVariables,
  ],
  'postSignup.phone.tip.subtitle': [
    clientKeysVariables,
    missionKeysVariables,
    otherKeysVariables,
  ],
  'postSignup.skills.tip.subtitle2': [skillKeysVariables, missionKeysVariables],
  'postSignup.you.tip.subtitle': otherKeysVariables,
  'profile.expert.candidacy.title': applicationKeysVariables,
  'proposal.section.candidacy.title': applicationKeysVariables,
  'relevance_score_information.modal.description': clientKeysVariables,
  'settings.notifications.projects.candidacy': applicationKeysVariables,
  'skills.rating.tooltip.title': [clientKeysVariables, skillKeysVariables],
  'tags.rating.tooltip.title': [clientKeysVariables, skillKeysVariables],
  'profile.form.skills_personal.description': skillKeysVariables,
  'company.modal.skills.title': skillKeysVariables,
  'tags.list.label': skillKeysVariables,
  'company.section.individual_certification_skills.placeholder.text': skillKeysVariables,
  'company.section.individual_certification_skills.placeholder.cta': skillKeysVariables,
  'company.modal.individual_certification_skills.title': skillKeysVariables,
  'company.section.skills.personal.title': skillKeysVariables,
  'company.modal.form.individual_certification_skills.title': skillKeysVariables,
  'mission.section.mission.individual_certification_skills.title': skillKeysVariables,
  'company.section.individual_certification_skills.title': skillKeysVariables,
  'mission.form.individual_certification_skills.title': skillKeysVariables,
  'tab.contract.expert': expertKeysVariables,
  'tab.contract.client': clientKeysVariables,
  'mission.message.disclaimer.text': [
    clientKeysVariables,
    applicationKeysVariables,
  ],
  'client.first_system_message.title': applicationKeysVariables,
  'tab.fields.missionStatus.all': missionKeysVariables,
  'invite_to_create_proposal.system_message.title': proposalKeysVariables,
  'invite_to_create_proposal.system_message.text': proposalKeysVariables,
  'system.message.invite_to_create_proposal_link': proposalKeysVariables,
  'mission.card.create_proposal': proposalKeysVariables,
  'invite_to_create_proposal.text': proposalKeysVariables,
  'header.nav.clientPool': expertKeysVariables,
  'clientPool.title': expertKeysVariables,
  'client_pool.empty': expertKeysVariables,
  'clientPool.tooltip.remove.title': expertKeysVariables,
  'bookmark.tooltip.added.title': expertKeysVariables,
  'bookmark.tooltip.added_without_favorites.title': expertKeysVariables,
  'header.menu.client_pools': clientKeysVariables,
  'clientPools.title': clientKeysVariables,
  'client_pools.helper.text': clientKeysVariables,
  'company.section.client_missions.title': [
    clientKeysVariables,
    missionKeysVariables,
  ],
  'client_pools.empty': clientKeysVariables,
  'resource.companies.client_pool_authorization.subtitle': expertKeysVariables,
  'company.modal.expert_document.title': expertKeysVariables,
  'clientpool.expert.add': expertKeysVariables,
  'add_expert_pool.modal.title': expertKeysVariables,
  'add_expert_pool.modal.modalities.title': expertKeysVariables,
  'myMissionBoard.experts.add_to_pool': expertKeysVariables,
};
