/* eslint-disable no-underscore-dangle */

import React from 'react';
import pp from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { length } from '@/helpers/validation';
import { ReactComponent as Trash } from '@/images/trash.svg';
import { RequiredField, Field, RfText, RfArea } from '@/containers/ReduxForm';
import Action from '../../Action';

function MilestoneDeliverables({ fields, change }) {
  const { t } = useTranslation();

  function handleActionClick(entity, index) {
    change(`${fields.name}[${index}]`, {
      ...entity,
      _destroy: !entity._destroy,
    });
  }

  return (
    <>
      {fields.map((field, i) => {
        const entity = fields.get(i);
        if (entity._destroy) return null;
        return (
          <div className="Deliverable" key={field}>
            <button type="button" onClick={() => handleActionClick(entity, i)}>
              <Trash />
            </button>
            <RequiredField
              component={RfText}
              name={`${field}.title`}
              label={t('proposal.modal.milestone.deliverables.title.label')}
              className="m-b-m"
            />
            <Field
              component={RfArea}
              name={`${field}.description`}
              label={t(
                'proposal.modal.milestone.deliverables.description.label'
              )}
              placeholder={t(
                'proposal.modal.milestone.deliverables.description.placeholder'
              )}
              validate={[length({ max: 10000 })]}
              max={10000}
            />
          </div>
        );
      })}
      <Action onClick={() => fields.push({})}>
        {t('proposal.modal.milestone.deliverables.add')}
      </Action>
    </>
  );
}

MilestoneDeliverables.propTypes = {
  fields: pp.objectOf(pp.any).isRequired,
  change: pp.func.isRequired,
};

export default MilestoneDeliverables;
