import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

function MenuItem({ children, className }) {
  return <li className={cx('Menu__item', className)}>{children}</li>;
}

MenuItem.propTypes = {
  children: pp.node.isRequired,
  className: pp.string,
};

MenuItem.defaultProps = {
  className: null,
};

export default MenuItem;
