import React from 'react';
import { string, bool, number } from 'prop-types';
import { lengthSkills } from '@/helpers/validation';
import { CREATE } from '@/constants/modes';
import { RfTags } from '@/containers/ReduxForm';
import TagsContext from '@/components/Tags/TagsContext';
import SectionField from '@/components/SectionField';
import FormSection from '@/components/FormSection';
import './styles.scss';

/**
 * IndividualCertificationSkillsSection
 *
 *    - Display @/components/Tags components
 *
 * Often used for :
 *     - Section in Profile Feature
 *     - Section in Mission Feature
 *     - Section in Post Signup Feature
 */
const IndividualCertificationSkillsSection = ({
  mode,
  withCreatable,
  inlineDisplay,
  kind,
  minSkillsLength,
  title,
  required,
}) => (
  <div className="Individual_certification_skills">
    <TagsContext.Provider
      value={{
        mode,
        withCreatable,
        inlineDisplay,
        kind,
        max: minSkillsLength,
        highlightMaxValues: 0,
        tagColor: 'light-secondary',
      }}
    >
      <FormSection>
        <SectionField
          id="individual_certification_skills"
          name="individual_certification_skills"
          component={RfTags}
          title={title}
          validate={[lengthSkills({ min: required ? minSkillsLength : 0 })]}
        />
      </FormSection>
    </TagsContext.Provider>
  </div>
);

IndividualCertificationSkillsSection.propTypes = {
  mode: string,
  withCreatable: bool.isRequired,
  inlineDisplay: bool.isRequired,
  kind: string.isRequired,
  minSkillsLength: number,
  title: string,
  required: bool,
};

IndividualCertificationSkillsSection.defaultProps = {
  mode: CREATE,
  minSkillsLength: 0,
  title: '',
  required: false,
};

export default IndividualCertificationSkillsSection;
