import React from 'react';
import pp from 'prop-types';
import { length } from '@/helpers/validation';
import { RequiredField, RfText, RfSelect } from '@/containers/ReduxForm';
import BoardModal from '../../BoardModal';

function CreateListModal({ t, listsLength, ...otherProps }) {
  return (
    <BoardModal
      size="medium"
      name="Board-CreateList"
      title={t('myMissionBoard.modal.create.title')}
      form="board_createList"
      {...otherProps}
    >
      <RequiredField
        id="name"
        name="name"
        label={t('myMissionBoard.modal.create.name.label')}
        component={RfText}
        className="m-b-m"
        validate={[length({ max: 35 })]}
      />
      {/* TODO: default value */}
      <RequiredField
        id="position"
        name="position"
        label={t('myMissionBoard.modal.create.position.label')}
        options={Array.from({ length: listsLength + 1 }).map((o, i) => ({
          label: i + 1,
          value: i + 1,
        }))}
        component={RfSelect}
      />
    </BoardModal>
  );
}

CreateListModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  listsLength: pp.number.isRequired,
};

export default CreateListModal;
