import reduxForm from '@/helpers/enhancers/reduxForm';
import { compose } from 'recompose';
import MilestoneModal from './MilestoneModal';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

export default compose(
  reduxForm({
    form: 'contract_milestone',
  }),
  connect((state) => ({
    formValues: getFormValues('contract_milestone')(state),
  }))
)(MilestoneModal);
