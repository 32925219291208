import React from 'react';
import TagListModal from '@/scenes/Profile/components/modals/TagListModal';
import sectors from '@/constants/fields/sectors';
import useTranslation from '@/hooks/useTranslation';
import { sortTranslatedLabel as sort } from '@/helpers/sortTranslatedString';
import { maxLengthValidator } from '@/helpers/company';
import config from '@/_config';

export default (props) => {
  const { t } = useTranslation();

  const sectorsSorted = config.sectors.sorting
    ? sort(sectors, t)
    : sectors.map((sector) => ({ ...sector, label: t(sector.label) }));

  return (
    <TagListModal
      formName="company_sectors"
      modalTitle="company.modal.sectors.title"
      sectionTitle="company.modal.sectors.section.title"
      tags={sectorsSorted}
      fieldProps={{
        name: 'sectors',
        label: 'company.modal.sectors.label',
        validate: maxLengthValidator(
          config.validation.expertProfile.maxSectorsLength,
          'sectors.error.too_many'
        ),
      }}
      {...props}
    />
  );
};
