import { getExpertsRoute, getMissionsMatchingRoute } from '@/helpers/router';

export default {
  client: [
    {
      id: 'r_experts',
      label: 'header.nav.experts',
      pathname: getExpertsRoute(),
    },
  ],
  expert: [
    {
      id: 'r_missions',
      label: 'header.nav.missions',
      pathname: getMissionsMatchingRoute(),
    },
  ],
};
