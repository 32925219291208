import React from 'react';
import { RequiredField, RfNumber } from '@/containers/ReduxForm';
import { bool, string } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get } from 'lodash';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import useTranslation from '@/hooks/useTranslation';
import config from '@/_config';

const HoursWorkedInput = ({ context, contract, withInformation }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-f jc-sb ai-c m-t-s">
        <p className="m-r-m">
          {t(`milestone.technical_assistance.${context}.hours_worked.label`)}
        </p>
        <RequiredField
          id="units_worked"
          name="units_worked"
          inline
          component={RfNumber}
          emptyInitialValue
          min={config.milestone.hoursWorked.min}
          max={config.milestone.hoursWorked.max}
          step={config.milestone.hoursWorked.step}
        />
      </div>
      {withInformation && (
        <div className="m-b-m">
          <p>
            {t(
              'milestone.technical_assistance.hours_worked.mission_work_hours_info',
              {
                workHours: get(
                  contract,
                  'candidacy.proposal.quotation.work_hours'
                ),
              }
            )}
          </p>
        </div>
      )}
    </>
  );
};

HoursWorkedInput.propTypes = {
  context: string.isRequired,
  contract: propType(contractFragment),
  withInformation: bool,
};

HoursWorkedInput.defaultProps = {
  withInformation: true,
};

export default HoursWorkedInput;
