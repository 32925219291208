
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"documentFileFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"DocumentFile"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"issuing_agency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"issue_date"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"document_id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"description"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"created_at"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"attachments"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"attachmentFragment"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"refused_reason"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"status"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":235}};
    doc.loc.source = {"body":"#import \"../Attachment/fragment.gql\"\n\nfragment documentFileFragment on DocumentFile {\n  id\n  issuing_agency\n  issue_date\n  document_id\n  description\n  created_at\n  attachments {\n    ...attachmentFragment\n  }\n  refused_reason\n  status\n}","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../Attachment/fragment.gql").definitions));


      module.exports = doc;
    
