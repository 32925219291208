import { ReactComponent as Pen } from '@/images/pen.svg';
import { ReactComponent as Preview } from '@/images/preview.svg';
import { ReactComponent as Chart } from '@/images/chart-bar-arrow.svg';
import { ReactComponent as Check } from '@/images/check-double.svg';
import { ReactComponent as Archive } from '@/images/archive.svg';
import { ReactComponent as ButtonPause } from '@/images/button-pause.svg';

export const Draft = {
  label: 'fields.missionStatus.draft',
  value: 'draft',
  id: 'draft',
  icon: Pen,
};

export const Review = {
  label: 'fields.missionStatus.review',
  value: 'review',
  id: 'review',
  icon: Pen,
};

export const Published = {
  label: 'fields.missionStatus.published',
  value: 'published',
  id: 'published',
  icon: Preview,
  count: 'client_pending_actions_missions_count',
};

export const WIP = {
  label: 'fields.missionStatus.wip',
  value: 'wip',
  id: 'wip',
  icon: Chart,
};

export const Finished = {
  label: 'fields.missionStatus.finished',
  value: 'finished',
  id: 'finished',
  icon: Check,
};

export const Archived = {
  label: 'fields.missionStatus.archived',
  value: 'archived',
  id: 'archived',
  icon: Archive,
};

export const STAND_BY = {
  label: 'fields.missionStatus.stand_by',
  value: 'stand_by',
  id: 'stand_by',
  icon: ButtonPause,
};

export default [Draft, Review, Published, WIP, Finished, Archived, STAND_BY];
