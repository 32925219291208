import React from 'react';
import { propType } from 'graphql-anywhere';
import { arrayOf, string } from 'prop-types';
import attachmentFragment from '@/api/Attachment/fragment';
import File from '@/components/File';
import './styles.scss';

const AttachmentDropdown = ({ textButton, attachments }) => (
  <div className="AttachmentDropdown">
    <button type="button" className="AttachmentDropdown__button">
      {textButton}
    </button>
    <div className="AttachmentDropdown__content">
      {attachments.map((attachment, index) => (
        <File
          key={attachment.file_uuid}
          file={{ ...attachment, status: 'read' }}
          index={index}
          asset={false}
        />
      ))}
    </div>
  </div>
);

AttachmentDropdown.propTypes = {
  textButton: string.isRequired,
  attachments: arrayOf(propType(attachmentFragment)),
};

AttachmentDropdown.defaultProps = {
  attachments: null,
};

export default AttachmentDropdown;
