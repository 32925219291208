import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import * as Experience from '@/api/Company/Experience/mutations';
import ExperienceModal from './ExperienceModal';

export default compose(
  graphql(Experience.createExperience, { name: 'create' }),
  graphql(Experience.updateExperience, { name: 'update' }),
  graphql(Experience.deleteExperience, { name: 'delete' }),
  translate()
)(ExperienceModal);
