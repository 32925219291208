import React from 'react';
import { objectOf, any } from 'prop-types';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import Tag from '@/components/Tag';
import Files from '@/components/Files';
import { OPTIONAL, UNLIMITED } from '@/constants/document';
import './styles.scss';

const FileHeader = ({ document }) => {
  const { t } = useTranslation();
  const name = get(document, 'name');
  const kind = get(document, 'kind.name');
  const description = get(document, 'description');
  const validityPeriod = get(document, 'validity_period');
  const condition = get(document, 'condition');

  const attachments = get(document, 'attachments', []);
  const unlimitedValidityPeriod = validityPeriod === UNLIMITED;

  return (
    <div className="FileHeader">
      <h2 className="m-b-m">
        {t('settings.documents.modal.add_file.document')}
      </h2>
      <div className="FileHeader__info p-b-m m-b-m">
        <div className="d-f ai-c m-b-m">
          {name && <h3 className="m-r-s">{name}</h3>}
          {kind && (
            <span>
              <Tag>{kind}</Tag>
            </span>
          )}
        </div>
        <div className="d-f ai-c m-b-s flex-wrap">
          {validityPeriod && (
            <span className="m-b-s">
              <Tag variants={[unlimitedValidityPeriod ? 'info' : 'alert']}>
                {unlimitedValidityPeriod ? (
                  <>{t(`documents.validity_period.${validityPeriod}`)}</>
                ) : (
                  <>
                    {t(`documents.validity_period.${validityPeriod}`, {
                      duration: get(document, 'duration'),
                    })}
                  </>
                )}
              </Tag>
            </span>
          )}
          {condition && (
            <span className="m-b-s">
              <Tag variants={[validityPeriod === OPTIONAL ? 'info' : 'alert']}>
                {t(`documents.condition.${condition}`)}
              </Tag>
            </span>
          )}
        </div>
        <div>
          {description && (
            <>
              <div className="m-b-xs fw-bold">
                {t('settings.documents.modal.description')}
              </div>
              <div>{description}</div>
            </>
          )}
        </div>
        <div>
          {attachments.length > 0 && (
            <>
              <div className="m-b-xs">
                {t('settings.documents.modal.attachments')}
              </div>
              <Files files={attachments} />
            </>
          )}
        </div>
      </div>
      <h2 className="m-v-m">{t('settings.documents.modal.add_file.file')}</h2>
    </div>
  );
};

FileHeader.propTypes = {
  document: objectOf(any).isRequired,
};

export default FileHeader;
