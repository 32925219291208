import React from 'react';
import { node, string } from 'prop-types';
import FormSection from '@/components/FormSection';

const CardChoiceSection = ({ children, title }) => (
  <FormSection title={title}>{children}</FormSection>
);

CardChoiceSection.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
};

export default CardChoiceSection;
