import { curry } from 'lodash';

export function generateQuery(obj) {
  return Object.keys(obj)
    .reduce((a, k) => {
      a.push(`${k}=${encodeURIComponent(obj[k])}`);
      return a;
    }, [])
    .join('&');
}

export const queryParams = curry((src, name) => {
  const match = RegExp(`[?&]?${name}=([^&]*)`).exec(src);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
});
