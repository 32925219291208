import React from 'react';
import { any, func, objectOf } from 'prop-types';
import { propType } from 'graphql-anywhere';
import userFragment from '@/api/User/fragment';
import useTranslation from '@/hooks/useTranslation';
import Button from '@/components/Button';
import { ReactComponent as Search } from '@/images/search.svg';
import User from '@/models/User';
import MediaQuery from 'react-responsive';
import { BREAKPOINTS } from '@/constants/screen';
import { compact, first, isEmpty, isNull } from 'lodash';
import { EXPERTS, MISSIONS } from '@/constants/router_base';
import config from '@/_config';

const SearchButton = ({
  cProps,
  onSubmitSearch,
  // eslint-disable-next-line react/prop-types
  onSubmitAllResult,
  currentUser,
}) => {
  const { t } = useTranslation();
  const user = new User(currentUser);

  const translationsAllResult = () => {
    // return array with transation if pathname contain /mission, /experts, ...
    const translation = compact(
      [MISSIONS, EXPERTS].map((route) => {
        if (document.location.pathname.includes(route)) {
          return `header.search${
            isNull(currentUser) ? `.${route.slice(1).toLowerCase()}` : ''
          }.all_result.label`;
        }
        return null;
      })
    );

    // return uniq translation according to pathname
    if (!isEmpty(first(translation))) return first(translation);

    return `header.search.all_result.label`;
  };

  return (
    <MediaQuery maxWidth={BREAKPOINTS.laptop}>
      {(mobile) => (
        <>
          <Button
            className="Button__search"
            onClick={() => onSubmitSearch(cProps)}
          >
            <Search />
          </Button>
          {!mobile &&
            config.search.showAllResultsButton(user.get('kind')) && (
              <Button
                className={user.isMixed() ? 'Button--hide-laptop' : ''}
                variants={['outline']}
                onClick={() => onSubmitAllResult(cProps)}
              >
                {t(translationsAllResult())}
              </Button>
            )}
        </>
      )}
    </MediaQuery>
  );
};

SearchButton.propTypes = {
  currentUser: propType(userFragment),
  cProps: objectOf(any),
  onSubmitSearch: func,
  // eslint-disable-next-line react/no-unused-prop-types
  onSubmitAllResulting: func,
};

SearchButton.defaultProps = {
  currentUser: null,
  cProps: null,
  onSubmitSearch: null,
  onSubmitAllResulting: null,
};

export default SearchButton;
