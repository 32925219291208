import {
  LocationField,
  LocationFields,
  TextField,
  NumberField,
  PhoneField,
  SelectField,
  TagsSuggestionsField,
  TagsField,
  ChoicesField,
  SliderField,
  CheckboxField,
  LabelledCheckboxField,
  ToggleField,
  LabelledToggleField,
  DateField,
  AutoCompleteField,
  AreaField,
  FileField,
  ImageField,
  FilesField,
  JobField,
  PasswordField,
  EditorField,
  SirenField,
} from '@/components/Fields';
import plugReduxForm from './lib/plugReduxForm';

export * from 'redux-form';
export { default as RequiredField } from './RequiredField';
export { default as FileField } from './FileField';
export { default as RfHelper } from './RfHelper';

export const RfText = plugReduxForm(TextField);
export const RfLocation = plugReduxForm(LocationField);
export const RfNumber = plugReduxForm(NumberField);
export const RfPhone = plugReduxForm(PhoneField);
export const RfSelect = plugReduxForm(SelectField);
export const RfTagsSuggestions = plugReduxForm(TagsSuggestionsField);
export const RfTags = plugReduxForm(TagsField);
export const RfChoices = plugReduxForm(ChoicesField);
export const RfSlider = plugReduxForm(SliderField);
export const RfCheckbox = plugReduxForm(CheckboxField);
export const RfLabelledCheckbox = plugReduxForm(LabelledCheckboxField);
export const RfToggle = plugReduxForm(ToggleField);
export const RfLabelledToggle = plugReduxForm(LabelledToggleField);
export const RfDate = plugReduxForm(DateField);
export const RfAutoComplete = plugReduxForm(AutoCompleteField);
export const RfArea = plugReduxForm(AreaField);
export const RfFile = plugReduxForm(FileField);
export const RfImage = plugReduxForm(ImageField);
export const RfFiles = plugReduxForm(FilesField);
export const RfJob = plugReduxForm(JobField);
export const RfPassword = plugReduxForm(PasswordField);
export const RfLocations = LocationFields;
export const RfEditor = plugReduxForm(EditorField);
export const RfSiren = plugReduxForm(SirenField);
