import axios from 'axios';
import client, { authClient } from '@/helpers/apollo';
import { createAttachment } from '@/api/Attachment/mutations';

export default {
  Mutation: {
    uploadAttachment: async (
      _,
      { attachment, kind, onUploadProgress, offline = false }
    ) => {
      const clientHttp = offline ? authClient : client;
      const {
        data: {
          createAttachment: { id, upload_url },
        },
      } = await clientHttp.mutate({
        mutation: createAttachment,
        variables: {
          ...attachment,
          kind,
        },
      });

      const attachmentWithId = {
        ...attachment,
        id,
      };

      await axios.put(upload_url, attachment.file, {
        onUploadProgress,
        headers: {
          'Content-Type': attachment.file.type,
        },
      });

      return {
        ...attachmentWithId,
        __typename: 'Attachment',
      };
    },
  },
};
