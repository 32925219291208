import React from 'react';
import { func } from 'prop-types';
import { Field } from 'redux-form';
import { date } from '@/helpers/validation';
import { today } from '@/helpers/date';
import {
  RequiredField,
  RfText,
  RfDate,
  RfFiles,
  RfTags,
  RfSelect,
} from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE_CERTIFICATION } from '@/constants/forms';
import TagsContext from '@/components/Tags/TagsContext';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { getlimitedList } from '@/helpers/limitedList';
import { ENTITLED } from '@/constants/fields';
import config from '@/_config';

const CertificationModal = ({ t, ...props }) => (
  <ProfileModal
    title={t('company.modal.certification.title')}
    form={PROFILE_CERTIFICATION}
    formatValues={({ skills, attachments, ...values }) => ({
      ...values,
      attachment_ids: attachments.map((a) => a.id),
      skills: formatTags(skills),
    })}
    {...props}
  >
    <FormSection>
      <RequiredField
        id="entitled"
        name="entitled"
        label={t('company.modal.certification.name.label')}
        className="m-b-m"
        component={
          config.company.certificationEntitledLimitedList ? RfSelect : RfText
        }
        {...config.company.certificationEntitledLimitedList && {
          options: getlimitedList(t, ENTITLED, true)(),
        }}
      />
      <RequiredField
        id="name"
        name="name"
        label={t('company.modal.certification.org.label')}
        className="m-b-m"
        component={RfText}
      />
      <RequiredField
        id="date"
        name="date"
        label={t('company.modal.certification.date.label')}
        className="mobileL--one-whole one-half m-b-m"
        component={RfDate}
        picker={false}
        validate={[
          date({
            '<=': today,
          }),
        ]}
      />
      <TagsContext.Provider
        value={{
          mode: props.mode,
          withRating: hasRating(),
        }}
      >
        <Field
          id="certification-skills"
          name="skills"
          component={RfTags}
          description="company.modal.certification.skills.label"
          {...getSkillsValidationAndExtraProps()}
        />
      </TagsContext.Provider>
      <Field
        component={RfFiles}
        multiple
        id="attachments"
        name="attachments"
        label={t('company.modal.certification.justificatory.label')}
        text={t('company.modal.certification.justificatory.text')}
        infoText={t('company.modal.certification.justificatory.infoText')}
        rejectText={t('company.modal.certification.justificatory.rejectText')}
        t={t}
      />
    </FormSection>
  </ProfileModal>
);

CertificationModal.propTypes = {
  t: func.isRequired,
};

export default CertificationModal;
