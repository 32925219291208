import Identity from './Identity';
import About from './About';
import Talents from './Talents';
import Platform from './Platform';
import Companies from './Companies';

export default [
  { id: 'identity', component: Identity },
  { id: 'about', component: About },
  { id: 'companies', component: Companies },
  { id: 'talents', component: Talents },
  { id: 'platform', component: Platform },
];
