import React from 'react';
import pp from 'prop-types';
import Field from '../Field';
import DatePickerInput from './DatePickerInput';
import DateInput from './DateInput';

const DateField = ({ picker, label, ...props }) => {
  if (picker) {
    return (
      <Field
        {...props}
        render={({ getInputProps }) => (
          <DatePickerInput {...getInputProps({ label })} />
        )}
      />
    );
  }

  return (
    <Field
      {...props}
      label={label}
      render={({ getInputProps }) => <DateInput {...getInputProps()} />}
    />
  );
};

DateField.propTypes = {
  picker: pp.bool,
};

DateField.defaultProps = {
  picker: true,
};

export default DateField;
