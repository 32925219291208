import { compose } from 'recompose';
import { connect } from 'react-redux';
import { closeModal } from '@/redux/modules/modal';
import BoardSettings from './BoardSettings';

export default compose(
  connect(
    null,
    { closeModal }
  )
)(BoardSettings);
