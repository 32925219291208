import {
  Automative,
  Aeronautics,
  Space,
  Defense,
  Railway,
  Telecoms,
  Energy,
  SI,
  OilAndGas,
  Naval,
  MachineOrHeavyIndustry,
  Digital,
  BankInsurance,
  Infrastructure,
  BioEngineering,
} from './sectors_default';

export default [
  Automative,
  Aeronautics,
  Space,
  Defense,
  Railway,
  Telecoms,
  Energy,
  SI,
  OilAndGas,
  Naval,
  MachineOrHeavyIndustry,
  Digital,
  BankInsurance,
  Infrastructure,
  BioEngineering,
];
