import React from 'react';
import { func, string } from 'prop-types';
import { getDocuments } from '@/api/Document/queries';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import Pagination from '@/components/Pagination';
import DocumentContent from '@/components/DocumentContent';
import DocumentHeader from '@/components/DocumentHeader';
import './styles.scss';

function ExpertDocumentModal({ close, companyId }) {
  const { t } = useTranslation();

  return (
    <Modal
      name="ExpertDocument"
      close={close}
      onCancel={close}
      title={t('company.modal.expert_document.title')}
      hideValidate
    >
      <div className="Documents DocumentsModal">
        <DocumentHeader />
        <Pagination
          query={getDocuments}
          variables={{
            id: companyId,
          }}
          limit={10}
          itemsPath="company.documents.items"
          itemsPathCount="company.documents_count"
          noResultsText={t('settings.documents.no_items')}
          renderItem={(document) => <DocumentContent document={document} />}
        />
      </div>
    </Modal>
  );
}

ExpertDocumentModal.propTypes = {
  close: func.isRequired,
  companyId: string.isRequired,
};

export default ExpertDocumentModal;
