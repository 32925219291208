import { get } from 'lodash';
import { FACET, NESTED } from '@/components/Matching/Filters/presets';
import { handler as facetItemsHandler } from '@/redux/modules/matching/helpers/handlers/facet/items';
import { handler as nestedItemsHandler } from '@/redux/modules/matching/helpers/handlers/nested/items';

/**
 * FilterItemsFactory
 * Dispatch to proper filter type
 * @param items
 * @param filter
 * @param selectedFilters
 * @returns {*}
 */
export function factory(items, filter, selectedFilters) {
  switch (get(filter, 'kind')) {
    case FACET:
      return facetItemsHandler(items, get(filter, 'name'), selectedFilters);
    case NESTED:
      return nestedItemsHandler(get(filter, 'items', []), selectedFilters);
    default:
      return items;
  }
}
