import React from 'react';
import { func, number, string } from 'prop-types';
import cx from 'classnames';
import useTranslation from '@/hooks/useTranslation';
import { Field, RfText } from '@/containers/ReduxForm';
import { ReactComponent as Swap } from '@/images/swap-horizontal.svg';
import './styles.scss';

function MilestonePricing({ className, change, name, totalAmount }) {
  const { t } = useTranslation();

  function handlePart(e) {
    const { value } = e.target;
    const nextAmount = (totalAmount * (value / 100)).toFixed(2);
    change(
      'proposal_milestone_forfeit',
      `${name}.amount`,
      value ? nextAmount : null
    );
  }

  function handleAmount(e) {
    const { value } = e.target;
    const nextPart = ((value / totalAmount) * 100).toFixed(2);
    change(
      'proposal_milestone_forfeit',
      `${name}.part`,
      value ? nextPart : null
    );
  }

  return (
    <div className={cx('MilestonePricing', className)}>
      <Field
        id="part"
        name={`${name}.part`}
        component={RfText}
        type="number"
        min={0}
        max={100}
        onChange={handlePart}
        label={t('proposal.modal.milestone.pricing.part.label')}
        icon="%"
      />
      <Swap />
      <Field
        id="amount"
        name={`${name}.amount`}
        component={RfText}
        type="number"
        min={0}
        max={totalAmount}
        onChange={handleAmount}
        label={t('proposal.modal.milestone.pricing.amount.label')}
        icon={t('currency.symbol')}
      />
    </div>
  );
}

MilestonePricing.propTypes = {
  className: string.isRequired,
  name: string.isRequired,
  change: func.isRequired,
  totalAmount: number.isRequired,
};

export default MilestonePricing;
