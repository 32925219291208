import { compose } from 'recompose';
import { connect } from 'react-redux';
import translate from '@/helpers/enhancers/translate';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { openModal } from '@/redux/modules/modal';
import OfflineApplyModal from './OfflineApplyModal';

export default compose(
  reduxForm({
    form: 'mission_offline_apply',
    initialValues: {
      attachment: [],
    },
  }),
  translate(),
  connect(
    null,
    { openModal }
  )
)(OfflineApplyModal);
