import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Email } from '@/images/email.svg';
import CandidateAndFreelanceListContents from '@/components/Header/ShowCaseBar/components/CandidateAndFreelanceListContents';
import CompaniesListContents from '@/components/Header/ShowCaseBar/components/CompaniesListContents';
import ResourceListContents from '@/components/Header/ShowCaseBar/components/ResourceListContents';
import ShowCaseLink from './ShowCaseLink';
import './styles.scss';

function ShowCaseLinks() {
  const { t } = useTranslation();

  return (
    <ul className="ShowCaseLinks__links">
      <ShowCaseLink
        component={CompaniesListContents}
        label="showcasebar.link.companies"
      />
      <ShowCaseLink
        component={CandidateAndFreelanceListContents}
        label="showcasebar.link.candidate_and_freelance"
      />
      <ShowCaseLink
        component={ResourceListContents}
        label="showcasebar.link.resource"
      />
      <li className="ShowCaseLinks__link--contact">
        <a
          href="https://www.kicklox.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="ShowCaseLinks__link--contact__icon">
            <Email />
          </span>
          {t('showcasebar.link.contact')}
        </a>
      </li>
    </ul>
  );
}

export default ShowCaseLinks;
