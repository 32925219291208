import {
  getMissionRoute,
  getClientsRoute,
  getClientRoute,
  getExpertsRoute,
  getExpertRoute,
  getMissionsRoute,
} from '@/helpers/router';
import { CLIENT, EXPERT } from '@/constants/userKind';
import {
  FILTER_LIST_CLIENT,
  FILTER_LIST_EXPERT,
  FILTER_LIST_MISSION,
  FILTER_LIST_TAG,
} from '@/components/Header/SearchBar/constants/lists';

export const getFilteredLists = (list, filterList) => [
  filterList(
    list,
    ({ leader_kind }) => leader_kind === EXPERT,
    FILTER_LIST_EXPERT
  ),
  filterList(
    list,
    ({ leader_kind }) => leader_kind === CLIENT,
    FILTER_LIST_CLIENT
  ),
];

export const buildSuggestionsList = (list, hits, addLinkToHit) => ({
  [FILTER_LIST_TAG]: {
    ...list,
    hits,
  },
  [FILTER_LIST_MISSION]: {
    ...list,
    sectionLink: getMissionsRoute(),
    sectionName: 'header.nav.missions',
    hits: hits.map(addLinkToHit(getMissionRoute)),
  },
  [FILTER_LIST_CLIENT]: {
    ...list,
    sectionLink: getClientsRoute(),
    sectionName: 'header.nav.clients',
    hits: hits.map(addLinkToHit(getClientRoute)),
  },
  [FILTER_LIST_EXPERT]: {
    ...list,
    sectionLink: getExpertsRoute(),
    sectionName: 'header.nav.experts',
    hits: hits.map(addLinkToHit(getExpertRoute)),
  },
});

export const suggestionFilters = [];
