import { compose } from 'recompose';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import plugReduxForm from '@/containers/ReduxForm/lib/plugReduxForm';
import toField from '@/components/Fields/toField';
import MilestonePricing from './MilestonePricing';

export default compose(
  plugReduxForm,
  toField,
  connect(
    null,
    { change }
  )
)(MilestonePricing);
