import { compose } from 'recompose';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import {
  createProposal,
  createMilestone,
  updateMilestone,
} from '@/api/Proposal/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import MilestoneTechnicalAssistanceModal from './MilestoneTechnicalAssistanceModal';

export default compose(
  graphql(createProposal, { name: 'createProposal' }),
  graphql(createMilestone, { name: 'createMilestone' }),
  graphql(updateMilestone, { name: 'updateMilestone' }),
  reduxForm({
    form: 'proposal_milestone_technical_assistance',
    initialValues: {
      pricing: { part: null, amount: null },
      deliverables_attributes: [],
    },
  }),
  connect((state) => ({
    formValues: getFormValues('proposal')(state),
  })),
  withRouter
)(MilestoneTechnicalAssistanceModal);
