import React from 'react';
import pp from 'prop-types';

export default (Field) => {
  const RfField = ({
    meta: { error, touched, dirty, form },
    input,
    ...props
  }) => (
    <Field
      error={error}
      touched={touched}
      dirty={dirty}
      form={form}
      {...props}
      {...input}
    />
  );
  RfField.propTypes = {
    meta: pp.objectOf(pp.any).isRequired,
    input: pp.objectOf(pp.any).isRequired,
  };
  return RfField;
};
