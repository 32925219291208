import React from 'react';
import { func } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { Field, RfSelect, RfHelper } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { REWARDS } from '@/constants/fields';
import { rewardsListOptions } from '@/constants/fields/rewards';

function RewardsModal({ ...props }) {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal.rewards.title')}
      form="rewards"
      {...props}
    >
      <RfHelper values={['rewards']}>
        {({ rewards }) => (
          <FormSection>
            <Field
              component={RfSelect}
              name="rewards"
              label={t('company.modal.rewards.name.label')}
              placeholder={t('company.modal.rewards.name.placeholder')}
              options={rewardsListOptions(t)}
              className="m-b-m"
              value={rewards}
              multi
              withIcon
              fieldName={REWARDS}
            />
          </FormSection>
        )}
      </RfHelper>
    </ProfileModal>
  );
}

RewardsModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
};

export default RewardsModal;
