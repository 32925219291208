export const MODERATION_FIELD_APPROVE_VALUE = 'approve';
export const MODERATION_FIELD_DISAPPROVE_VALUE = 'disapprove';

export const MODERATION = [
  {
    label: `fields.approbation.approve`,
    value: MODERATION_FIELD_APPROVE_VALUE,
    id: 'approve',
  },
  {
    label: `fields.approbation.disapprove`,
    value: MODERATION_FIELD_DISAPPROVE_VALUE,
    id: 'disapprove',
  },
];
