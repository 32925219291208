import React, { useState, useContext } from 'react';
import { string } from 'prop-types';
import ReactPhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import fr from 'react-phone-number-input/locale/fr.json';
import en from 'react-phone-number-input/locale/en.json';
import i18n from '@/i18n';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import config from '@/_config';
import 'react-phone-number-input/style.css';
import './styles.scss';

const localeMap = {
  fr,
  en,
};

const PhoneInput = (props) => {
  // `value` will be the parsed phone number in E.164 format.
  // Example: "+12133734253".
  const [value, setValue] = useState(() => {
    if (props.value) {
      return props.value;
    }
    return null;
  });
  const userContext = useContext(UserContext);
  const user = new User(userContext);

  // User language or browser language
  const lang = user.get('language_alpha2') || i18n.language;

  return (
    <div className="PhoneInput__wrapper">
      <span className="Field__label Field__label--outside">{props.label}</span>
      <ReactPhoneInput
        international
        defaultCountry={config.global.defaultCountry}
        value={value}
        onChange={setValue}
        flags={flags}
        labels={localeMap[lang]}
        {...props}
      />
    </div>
  );
};

PhoneInput.propTypes = {
  className: string,
  label: string,
};

PhoneInput.defaultProps = {
  className: null,
  label: '',
};

export default PhoneInput;
