import { get, isUndefined } from 'lodash';
import config from '@/_config';

export default function defineAbilityForProposal(can) {
  can(
    'edit_proposal',
    'Proposal',
    (proposal) =>
      proposal.isDraft() ||
      proposal.isReviewAdmin() ||
      proposal.isRequestForUpdate() ||
      proposal.isReviewClient()
  );

  can('save_proposal', 'Proposal', (proposal) => proposal.isDraft());

  can(
    'download_proposal',
    'Proposal',
    (proposal) =>
      proposal.isReviewClient() ||
      proposal.isAccepted() ||
      proposal.isRequestForUpdate()
  );
  can(
    'see_proposal_view',
    'Proposal',
    (proposal) => !(proposal.isReviewAdmin() || proposal.isArchived())
  );

  // The payment period field can only be modified if the discount and the payment_period respect one of this combination,
  // otherwise it means the values had been modified from the BO and are decorrelated
  can('edit_payment_period', 'Proposal', (proposal) => {
    const paymentPeriod = proposal.get('payment_period');
    const discount = proposal.get('discount');

    if (
      isUndefined(paymentPeriod) ||
      isUndefined(discount) ||
      get(
        config.proposal.paymentPeriodChoices[paymentPeriod],
        'coefficient'
      ) === discount
    ) {
      return true;
    }
    return false;
  });
}
