import React from 'react';
import Modal from '@/components/Modal';
import useTranslation from '@/hooks/useTranslation';
import { propType } from 'graphql-anywhere';
import { string, node, func, bool, objectOf, any } from 'prop-types';
import { CREATE } from '@/components/Contract/constants/modalMaps';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import './styles.scss';

function MilestoneModal({
  id,
  children,
  handleSubmit,
  close,
  submitButtonLabel,
  title,
  context,
  onSubmit,
  submitting,
  subtitle,
  milestone,
  contract,
  formValues,
  change,
  userKindContext,
}) {
  const { t } = useTranslation();

  return (
    <Modal
      id={id}
      name="contract_milestone"
      className={`ContractMilestoneModal--${context}`}
      size="large"
      close={close}
      onOk={(args) => handleSubmit((values) => onSubmit(values, close))(args)}
      disableActions={submitting}
      closable={!submitting}
      onCancel={close}
      title={t(title)}
      subtitle={t(subtitle)}
      okText={t(submitButtonLabel)}
    >
      {React.cloneElement(children, {
        context,
        milestone,
        contract,
        formValues,
        change,
        userKindContext,
      })}
    </Modal>
  );
}

MilestoneModal.propTypes = {
  id: string.isRequired,
  children: node.isRequired,
  close: func.isRequired,
  title: string,
  subtitle: string,
  context: string,
  handleSubmit: func.isRequired,
  submitButtonLabel: string,
  onSubmit: func.isRequired,
  submitting: bool.isRequired,
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  formValues: objectOf(any),
  change: func.isRequired,
  userKindContext: string.isRequired,
};

MilestoneModal.defaultProps = {
  title: null,
  subtitle: null,
  context: CREATE,
  submitButtonLabel: 'milestone.modal.submit',
  formValues: {},
};

export default MilestoneModal;
