import React from 'react';
import { string, func, number, bool } from 'prop-types';
import TagRemoveButton from '@/components/Tags/components/TagRemoveButton';
import TagContainer from '@/components/Tags/components/TagContainer';
import TagWrapper from '@/components/Tags/components/TagWrapper';
import './styles.scss';

function TagInline({
  name,
  index,
  withIcon,
  fieldName,
  handleRemove,
  disabledVariant,
  required,
  hasError,
}) {
  return (
    <TagContainer classnames="d-f">
      <div className="jc-c">
        <TagWrapper
          name={name}
          index={index}
          required={required}
          hasError={hasError}
          withIcon={withIcon}
          fieldName={fieldName}
          disabledVariant={disabledVariant}
        />
      </div>
      <TagRemoveButton handleRemove={handleRemove} classes="TagRemoveButton" />
    </TagContainer>
  );
}

TagInline.propTypes = {
  handleRemove: func.isRequired,
  hasError: bool,
  index: number,
  name: string.isRequired,
  required: bool,
  withIcon: bool,
  fieldName: string,
  disabledVariant: bool,
};

TagInline.defaultProps = {
  hasError: false,
  index: null,
  required: false,
  withIcon: false,
  fieldName: null,
  disabledVariant: false,
};

export default TagInline;
