import React from 'react';
import { propType } from 'graphql-anywhere';
import { any, objectOf, string } from 'prop-types';
import { isDate, get } from 'lodash';
import { Query } from 'react-apollo';
import useTranslation from '@/hooks/useTranslation';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import {
  RequiredField,
  Field,
  RfDate,
  RfHelper,
  RfLabelledCheckbox,
  RfArea,
  RfFiles,
  FieldArray,
} from '@/containers/ReduxForm';
import { acceptance, date } from '@/helpers/validation';
import {
  today,
  endOfMonthFromBeginDate,
  startOfMonthFromBeginDate,
} from '@/helpers/date';
import MilestoneInformation from '@/components/Contract/MilestoneCard/MilestoneInformation';
import MilestonePriceAndDate from '@/components/Contract/MilestoneCard/MilestonePriceAndDate';
import MilestoneDescriptionAndDeliverables from '@/components/Contract/MilestoneCard/MilestoneDescriptionAndDeliverables';
import MilestoneCharges from '@/components/Contract/MilestoneCard/MilestoneCharges';
import MilestoneStatusMessage from '@/components/Contract/MilestoneCard/MilestoneStatusMessage';
import { getStatusProperties } from '@/components/Contract/helpers/status';
import { DELIVER } from '@/components/Contract/constants/modalMaps';
import priceFormat from '@/helpers/priceFormat';
import { getMonthLabel } from '@/helpers/milestone';
import { capitalize } from '@/helpers/string';
import ChargeUnits from '@/components/ChargeUnits';
import FormSection from '@/components/FormSection';
import { getChargeUnits } from '@/api/Proposal/queries';
import {
  totalCharges,
  modifiedChargesId as modifiedChargesIdFunc,
} from '@/helpers/contractMilestone';
import DaysWorkedInput from '@/components/Input/DaysWorkedInput';
import HoursWorkedInput from '@/components/Input/HoursWorkedInput';
import { HOUR, UNIT } from '@/constants/costPerKind';
import getPricingUnitKindLabel from '@/helpers/pricingUnitKindLabel';
import config from '@/_config';
import './styles.scss';

// eslint-disable-next-line react/prop-types
const DatesInput = ({ milestone }) => {
  const { t } = useTranslation();
  const beginDate =
    milestone && milestone.begin_date
      ? startOfMonthFromBeginDate(milestone.begin_date)
      : today;
  const additionnalMilestone = milestone.title.match(/month_(\d+)/);
  return (
    <div className="grid">
      <div className="grid__item mobileL--one-whole one-half m-b-s">
        <RequiredField
          component={RfDate}
          id="begin_date"
          name="begin_date"
          label={t('milestone.technical_assistance.beginDate.label')}
          placeholder={t('milestone.technical_assistance.date.placeholder')}
          autoComplete="off"
          dayPickerProps={{
            disabledDays: {
              before: beginDate,
              after: endOfMonthFromBeginDate(beginDate),
            },
          }}
          validate={[
            date({
              '>=': beginDate,
              '<=': endOfMonthFromBeginDate(beginDate),
              format: date,
            }),
          ]}
        />
      </div>
      <div className="grid__item mobileL--one-whole one-half m-b-s">
        <RfHelper values={['begin_date']}>
          {({ begin_date }) => (
            <RequiredField
              component={RfDate}
              id="end_date"
              name="end_date"
              label={t('milestone.technical_assistance.endDate.label')}
              placeholder={t('milestone.technical_assistance.date.placeholder')}
              autoComplete="off"
              dayPickerProps={{
                disabledDays: {
                  before: isDate(begin_date) && begin_date,
                  ...(additionnalMilestone && {
                    after: endOfMonthFromBeginDate(begin_date),
                  }),
                },
              }}
              validate={[
                date({
                  '>=': begin_date || today,
                  ...(additionnalMilestone && {
                    '<=': endOfMonthFromBeginDate(begin_date),
                  }),
                  format: date,
                }),
              ]}
            />
          )}
        </RfHelper>
      </div>
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const AdditionalPricing = ({ change }) => {
  const { t } = useTranslation();
  return (
    <Query query={getChargeUnits}>
      {({ data, loading }) => {
        const chargeUnits = get(data, 'charge_units');

        if (loading || !chargeUnits) return null;
        return (
          <div className="Additional__pricing">
            <FormSection
              title={t(
                'milestone.technical_assistance.additional_pricing.title'
              )}
            >
              <FieldArray
                id="charges"
                name="charges"
                component={ChargeUnits}
                chargeUnits={chargeUnits}
                change={change}
                displayOnMount={false}
                required={false}
              />
            </FormSection>
          </div>
        );
      }}
    </Query>
  );
};

function DeliverATForm({
  context,
  milestone,
  contract,
  formValues,
  change,
  userKindContext,
}) {
  const { t } = useTranslation();
  const { end_date, status, units_worked, charges } = milestone;
  const { message, primary, secondary, tertiary } = getStatusProperties(
    status,
    userKindContext
  );

  const newTimeWorked = get(formValues, 'units_worked');

  const newCharges = get(formValues, 'charges');
  const { costPerUnit } = contract;
  const withTimeWorkedModification = units_worked !== newTimeWorked;

  const unit = get(contract, 'proposal.quotation.unit');

  const formattedTitle = capitalize(getMonthLabel(milestone.get('title')));

  // Array of charges id
  const initialChargesId = charges.reduce(
    (acc, charge) => [...acc, charge.id],
    []
  );

  // Array of charges id modified or deleted
  const modifiedChargesId = modifiedChargesIdFunc(charges, newCharges);

  // Array of charges that has to be displayed as modified or new
  const newChargesToDisplay = newCharges.reduce((acc, charge) => {
    const isDestroyed = Object.prototype.hasOwnProperty.call(
      charge,
      '_destroy'
    );

    // If the current charge id is included in the modified one and has not been destroyed OR
    // if the the current charge is a new one and has not been destroyed,
    // it has to be displayed in the modified section
    if (
      (modifiedChargesId.includes(charge.id) && !isDestroyed) ||
      (!initialChargesId.includes(charge.id) && !isDestroyed)
    ) {
      return [...acc, charge];
    }
    return [...acc];
  }, []);

  // Compute of the new price after modification
  const newPrice = newTimeWorked * costPerUnit + totalCharges(newCharges);

  // To know if charges or days_worked fields had been modified
  const withModification =
    withTimeWorkedModification ||
    newChargesToDisplay.length > 0 ||
    modifiedChargesId.length > 0;

  function getTimeWorkedComponent(currentContext) {
    switch (config.proposal.costPer) {
      case UNIT: {
        return <DaysWorkedInput context={currentContext} />;
      }
      case HOUR: {
        return (
          <HoursWorkedInput context={currentContext} contract={contract} />
        );
      }
      default:
        return null;
    }
  }

  return (
    <>
      <p className="fw-bold m-b-m">
        {t('contract.milestone.technical_assistance.subtitle.deliver')}
      </p>
      <DatesInput milestone={milestone} />
      {getTimeWorkedComponent(context)}
      <AdditionalPricing change={change} />
      <div className="MilestoneCardModal">
        <div className="MilestoneCardModal__header">
          <span>{t('contract.milestone.title')}</span>
          <span>{t('contract.milestone.unit')}</span>
          <span>{t('contract.milestone.amount')}</span>
          <span>{t('contract.milestone.cost_per_unit')}</span>
          <span>{t('contract.milestone.total_duty')}</span>
        </div>
        <div className={`MilestoneCardModal__information--${context}`}>
          <MilestoneInformation
            contract={contract}
            milestone={milestone}
            costPerUnit={costPerUnit}
            withModification={withTimeWorkedModification}
          />
        </div>
        <MilestoneCharges
          charges={milestone.get('charges')}
          primary={primary}
          modifiedChargesId={modifiedChargesId}
          classes={{
            root: `MilestoneCardModal__information--${context}`,
            title: 'MilestoneCard___title',
          }}
        />
        {withModification ? (
          <>
            <div className="MilestoneCardModal__status-message">
              <div className="MilestoneCardModal__modification-message">
                {t(
                  'contract.milestone.technical_assistance.modification.deliver'
                )}
              </div>
            </div>
            {withTimeWorkedModification && (
              <div className="MilestoneCardModal__information--modification">
                <span>{formattedTitle}</span>
                <span>{getPricingUnitKindLabel(t, unit)}</span>
                <span>
                  {priceFormat(newTimeWorked, { minDigits: 2, maxDigits: 2 })}
                </span>
                <span>
                  {priceFormat(costPerUnit, { minDigits: 2, maxDigits: 2 })}
                  <span className="p-l-xxs">{t('currency.symbol')}</span>
                </span>
                <span>
                  {priceFormat(newTimeWorked * costPerUnit, {
                    minDigits: 2,
                    maxDigits: 2,
                  })}
                  <span className="p-l-xxs">{t('currency.symbol')}</span>
                </span>
              </div>
            )}
            <MilestoneCharges
              charges={newChargesToDisplay}
              primary={primary}
              classes={{
                root: 'MilestoneCardModal__information--modification',
                title: 'MilestoneCard___title',
              }}
            />
          </>
        ) : (
          <div className="MilestoneCardModal__status-message">
            <MilestoneStatusMessage
              secondary={secondary}
              primary={primary}
              message={message}
              tertiary={tertiary}
              endDate={end_date}
            />
          </div>
        )}
        <div className={`MilestoneCardModal__price--${context}`}>
          <MilestonePriceAndDate
            milestone={milestone}
            contract={contract}
            classes={{ priceAndDate: 'MilestoneCard__price-and-date' }}
            withModification={withModification}
            newPrice={newPrice}
          />
        </div>
        <div className="MilestoneCardModal__deliverables">
          <MilestoneDescriptionAndDeliverables milestone={milestone} />
        </div>
      </div>
      <p className="fw-bold">
        {t('contract.milestone.attachment.label.deliver')}
      </p>
      <Field
        component={RfFiles}
        multiple
        id="attachments"
        name="attachments"
        text={t('contract.milestone.attachment.text.deliver')}
        infoText={t('contract.milestone.attachment.infoText.deliver')}
        rejectText={t(
          'contract.milestone.attachment.infoText.rejectText.deliver'
        )}
        t={t}
      />
      <p className="fw-bold">{t('contract.milestone.comment.label.deliver')}</p>
      <Field
        name="comment"
        component={RfArea}
        placeholder={t('contract.milestone.comment.placeholder.deliver')}
        className="m-b-s"
      />
      <RequiredField
        name="confirm"
        type="checkbox"
        component={RfLabelledCheckbox}
        label={t('contract.milestone.confirm.label.deliver')}
        validate={[acceptance()]}
      />
    </>
  );
}

DeliverATForm.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  context: string,
  formValues: objectOf(any),
  userKindContext: string.isRequired,
};

DeliverATForm.defaultProps = {
  context: DELIVER,
  formValues: {},
};

export default DeliverATForm;
