import React from 'react';
import { array, bool, func, shape } from 'prop-types';
import { first, isEmpty } from 'lodash';
import ProfileModal from '../../ProfileModal';
import './styles.scss';
import CvParsing from '@/components/CvParsing';
import { handleVariables } from '@/components/CvParsing/helpers';

const CvModal = ({ t, setSubmitFailed, ...props }) => {
  const { values } = props;
  const getFormValues = ({ cv, settings, linkedin_vanity_name }) =>
    handleVariables(
      first(cv) ? first(cv).id : '',
      settings,
      linkedin_vanity_name
    );

  const settingsValidator = (settingsValue) =>
    !isEmpty(values.cv) &&
    (!settingsValue || (settingsValue && !settingsValue.length))
      ? t('company.modal.cv.settings.error')
      : undefined;

  const { currentUser } = props;
  return (
    <ProfileModal
      title={t('company.modal.cv.title')}
      form="company_cv"
      formatValues={getFormValues}
      refetchQueries={['getCvScrapStatus', 'getCompany']}
      submitting={isEmpty(values.cv) && isEmpty(values.linkedin_vanity_name)}
      {...props}
    >
      <CvParsing validator={settingsValidator} currentUser={currentUser} />
    </ProfileModal>
  );
};

CvModal.propTypes = {
  t: func.isRequired,
  values: shape({
    cv: array,
    update_profile: bool,
    display_cv_on_profile: bool,
  }),
};

CvModal.defaultProps = {
  values: {},
};

export default CvModal;
