import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { string } from 'prop-types';
import { RequiredField, RfChoices } from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import FormSection from '@/components/FormSection';
import searchStatuses from '@/constants/fields/searchStatus';

function SearchStatus({ name, label }) {
  const { t } = useTranslation();

  return (
    <FormSection title={t('fields.searchStatus.title')}>
      <RequiredField
        type="radioGroup"
        name={name}
        component={RfChoices}
        checkbox={LabelledRadio}
        label={label}
        cbProps={searchStatuses.map((status) => ({
          ...status,
          label: t(status.label),
        }))}
        withSeparators={false}
      />
    </FormSection>
  );
}

SearchStatus.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
};

export default SearchStatus;
