/**
 * Handle selected facet filter
 * @param selected
 * @param selectedFilters
 * @param value
 * @param name
 * @returns {*}
 */
export function handler(selected, selectedFilters, value, name) {
  return {
    [name]: selected
      ? [
          ...selectedFilters.filter(
            (selectedFilter) => selectedFilter !== value
          ),
        ]
      : [...selectedFilters, value],
  };
}
