import { get } from 'lodash';

export default class Entity {
  // When you create a model do not forget to implement this method below
  // static get modelName() {}
  // See https://casl.js.org/v4/en/guide/subject-type-detection
  // (Section : The example above won't work in production if you use minimization)

  constructor(attrs) {
    Object.assign(this, attrs);
  }

  get(key, defaultValue) {
    return get(this, key, defaultValue);
  }

  set(key, value) {
    this[key] = value;
  }
}
