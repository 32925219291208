import React from 'react';
import pp from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfArea } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';

function RequestUpdateModal({
  close,
  handleSubmit,
  submitting,
  requestForUpdateProposal,
  showFlash,
  proposalId,
}) {
  const { t } = useTranslation();

  async function onSubmit({ message }) {
    await requestForUpdateProposal({
      variables: { id: proposalId, message },
      refetchQueries: [`getProposal`],
      awaitRefetchQueries: true,
    });
    close();
    showFlash({
      kind: 'success',
      text: 'proposal.modal.requestUpdate.success.text',
    });
  }

  return (
    <Modal
      name="ProposalRequestUpdate"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('proposal.modal.requestUpdate.title')}
      subtitle={t('proposal.modal.requestUpdate.subtitle')}
      okText={t('proposal.modal.requestUpdate.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <RequiredField
        id="message"
        name="message"
        component={RfArea}
        placeholder={t('proposal.modal.requestUpdate.placeholder')}
      />
    </Modal>
  );
}

RequestUpdateModal.propTypes = {
  close: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  requestForUpdateProposal: pp.func.isRequired,
  showFlash: pp.func.isRequired,
  proposalId: pp.string.isRequired,
};

export default RequestUpdateModal;
