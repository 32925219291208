import React from 'react';
import cx from 'classnames';
import pp from 'prop-types';
import './styles.scss';

const ProgressBar = ({ progress, children, className }) => (
  <div className={cx('ProgressBar', className)}>
    <div className="ProgressBar__progress" style={{ width: `${progress}%` }}>
      {children && <span className="ProgressBar__content">{children}</span>}
    </div>
    {children && <span className="ProgressBar__content">{children}</span>}
  </div>
);

ProgressBar.propTypes = {
  children: pp.node,
  progress: pp.number,
  className: pp.string,
};

ProgressBar.defaultProps = {
  children: null,
  className: null,
  progress: 0,
};

export default ProgressBar;
