export const capitalize = (str = '') =>
  `${str.charAt(0).toUpperCase()}${str.slice(1)}`;

export function lowerWithoutAccent(value) {
  if (typeof value === 'string')
    return value
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  return value;
}
