import Tooltip from '@/components/Tooltip';
import React, { useContext } from 'react';
import { string, number, bool } from 'prop-types';
import Tag from '@/components/Tag';
import TagsContext from '@/components/Tags/TagsContext';
import { getAssetImagePath, getFolderName } from '@/helpers/assets';
import './styles.scss';

// TODO maybe this component should be remove by using only Tag (if Tag handle tooltip)
function TagWrapper({
  name,
  withIcon,
  fieldName,
  disabledVariant,
  length,
  required,
  index,
  hasError,
}) {
  const tagsContext = useContext(TagsContext);
  const color = tagsContext.tagColor;

  function handleVariants() {
    // if tagColor is set, it will override highlight behaviour
    if (hasError) return ['mred'];

    if (disabledVariant || (!required && color)) return [];

    if (index < tagsContext.highlightMaxValues) {
      return ['secondary'];
    }
    return [];
  }
  const TagLabel = () => (
    <span className="">
      {withIcon && (
        <img
          src={getAssetImagePath(getFolderName(fieldName), name)}
          alt="logo"
          className="TagWrapper__Tag-label--icon"
        />
      )}
      {name}
    </span>
  );

  return (
    <Tag
      variants={handleVariants()}
      className={color ? `TagWrapper__Tag--${color}` : ''}
    >
      {name.length > length ? (
        <Tooltip title={name}>
          <TagLabel />
        </Tooltip>
      ) : (
        <TagLabel />
      )}
    </Tag>
  );
}

TagWrapper.propTypes = {
  name: string.isRequired,
  length: number,
  required: bool,
  index: number,
  hasError: bool,
  withIcon: bool,
  fieldName: string,
  disabledVariant: bool,
};

TagWrapper.defaultProps = {
  length: 31,
  required: false,
  index: null,
  hasError: false,
  withIcon: false,
  fieldName: null,
  disabledVariant: false,
};

export default TagWrapper;
