import React from 'react';
import { func, string } from 'prop-types';
import { Mutation } from 'react-apollo';
import {
  RequiredField,
  Field,
  RfArea,
  RfChoices,
} from '@/containers/ReduxForm';
import { archiveBoardCard } from '@/api/MyMission/mutations';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import BoardModal from '../../BoardModal';

const radioProps = [
  {
    id: 'skills',
    value: 'skills',
    label: 'myMissionBoard.modal.archive_candidacy.kind.skills',
  },
  {
    id: 'exp_time',
    value: 'exp_time',
    label: 'myMissionBoard.modal.archive_candidacy.kind.exp_time',
  },
  {
    id: 'location',
    value: 'location',
    label: 'myMissionBoard.modal.archive_candidacy.kind.location',
  },
  {
    id: 'availability',
    value: 'availability',
    label: 'myMissionBoard.modal.archive_candidacy.kind.availability',
  },
  {
    id: 'culture_mindset',
    value: 'culture_mindset',
    label: 'myMissionBoard.modal.archive_candidacy.kind.culture_mindset',
  },
  {
    id: 'salary',
    value: 'salary',
    label: 'myMissionBoard.modal.archive_candidacy.kind.salary',
  },
];

function ArchiveCardModal({ t, id, ...otherProps }) {
  return (
    <Mutation mutation={archiveBoardCard} variables={{ id }}>
      {(mutate) => (
        <BoardModal
          size="medium"
          name="Board-ArchiveMission"
          title={t('myMissionBoard.modal.archive_candidacy.title')}
          subtitle={t('myMissionBoard.modal.archive_candidacy.subtitle')}
          form="board_archiveMisison"
          mutate={mutate}
          {...otherProps}
        >
          <RequiredField
            id="archived_kind"
            name="archived_kind"
            component={RfChoices}
            checkbox={LabelledCheckbox}
            cbProps={radioProps.map((p) => ({
              ...p,
              label: t(p.label),
            }))}
            className="m-b-m"
          />
          <Field
            id="archived_text"
            name="archived_text"
            label={t('myMissionBoard.modal.archive_candidacy.text.label')}
            placeholder={t('myMissionBoard.modal.archive.text.placeholder')}
            component={RfArea}
          />
        </BoardModal>
      )}
    </Mutation>
  );
}

ArchiveCardModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  id: string.isRequired,
};

export default ArchiveCardModal;
