/* eslint-disable react/no-danger */

import React from 'react';
import { node } from 'prop-types';
import './styles.scss';

function RichText({ children }) {
  return (
    <p className="RichText" dangerouslySetInnerHTML={{ __html: children }} />
  );
}

RichText.propTypes = {
  children: node.isRequired,
};

export default RichText;
