const UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

const prettyBytes = (num) => {
  if (!Number.isFinite(num)) {
    throw new TypeError(`Expected a finite number, got ${typeof num}: ${num}`);
  }

  if (!num) {
    return `${num} B`;
  }

  const neg = num < 0;
  const absNum = Math.abs(num);
  const exponent = Math.min(
    Math.floor(Math.log(absNum) / Math.log(1000)),
    UNITS.length - 1
  );
  const numStr = Number((absNum / 1000 ** exponent).toPrecision(3));
  const unit = UNITS[exponent];

  return `${neg ? '-' : ''}${numStr} ${unit}`;
};

export default prettyBytes;
