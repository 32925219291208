import React, { useState } from 'react';
import { range } from 'lodash';
import { string, func, number, bool } from 'prop-types';
import { ratingMaxValues } from '@/components/Tags/helpers/handler';
import config from '@/_config';
import { ratingLetters } from '@/constants/ratingLetters';
import './styles.scss';
import Button from '@/components/Button';

function TagRating({ id, name, rating, onChange, hasError }) {
  const ratings = range(1, ratingMaxValues + 1);
  const [hoveredRating, setHoveredRating] = useState(false);

  if (!name) return null;

  function handleClick(key) {
    const ratingValue = key === rating ? null : key;
    if (!ratingValue) setHoveredRating(null);
    onChange(id, name, ratingValue);
  }

  function handleMouseEnter(key) {
    setHoveredRating(key);
  }

  function handleMouseLeave() {
    setHoveredRating(null);
  }

  /**
   * Return a variant, corresponding to the rating key state:
   * When a rating value is clicked, all value between 0 and the selected variant is empty (or "mred" if hasError)
   * When a rating value has already been chosen and user is hovering rating values,
   * all rating values between the current rating and the hovered are "outline-light"
   *
   * @param key
   * @returns {string[]}
   */
  function handleVariants(key) {
    if (rating && hoveredRating && hoveredRating < key && key <= rating)
      return ['outline-light'];
    if (key <= rating || key <= hoveredRating)
      return hasError ? ['mred'] : [''];
    return ['outline'];
  }

  return (
    <div className="p-all-s d-f TagRating">
      {ratings.map((key) => (
        <div
          key={key}
          className="TagRating__item"
          onMouseEnter={() => handleMouseEnter(key)}
          onMouseLeave={handleMouseLeave}
        >
          <Button
            className="TagRating__button"
            variants={handleVariants(key)}
            onClick={() => handleClick(key)}
          >
            {config.skills.withRatingLetters ? ratingLetters[key] : key}
          </Button>
        </div>
      ))}
    </div>
  );
}

TagRating.propTypes = {
  onChange: func.isRequired,
  id: string,
  name: string.isRequired,
  rating: number,
  hasError: bool,
};

TagRating.defaultProps = {
  id: null,
  rating: null,
  hasError: false,
};

export default TagRating;
