import React from 'react';
import pp from 'prop-types';
import { Mutation } from 'react-apollo';
import { deleteBoardList } from '@/api/MyMission/mutations';
import Alert from '@/components/Alert';

function DeleteListModal({ t, close, id }) {
  return (
    <Mutation mutation={deleteBoardList} variables={{ id }}>
      {(mutate, { loading }) => (
        <Alert
          type="warning"
          close={close}
          onOk={async () => {
            await mutate({
              refetchQueries: [`getMyMission`],
              awaitRefetchQueries: true,
            });
            close();
          }}
          onCancel={close}
          disableActions={loading}
          closable={!loading}
          text={t('myMissionBoard.modal.delete.list')}
        />
      )}
    </Mutation>
  );
}

DeleteListModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  id: pp.string.isRequired,
};

export default DeleteListModal;
