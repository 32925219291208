import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  string,
  oneOfType,
} from 'prop-types';
import Downshift from 'downshift';
import { Manager, Reference, Popper } from 'react-popper';
import Menu from './Menu';
import withMultiple from './withMultiple';
import './styles.scss';

const MultipleDownshift = withMultiple(Downshift);

class Dropdown extends React.Component {
  popperScheduleUpdate = _.noop;

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.popperScheduleUpdate();
    }
  }

  handleInputValueChange = (...args) => {
    if (this.props.onInputValueChange) {
      this.props.onInputValueChange(...args);
    }
  };

  handleStateChange = (changes, utils) => {
    this.popperScheduleUpdate();
    if (this.props.typeAhead && changes.inputValue) {
      utils.setHighlightedIndex(0);
    }
    this.props.onStateChange(changes, utils);
  };

  renderDropdown = ({ getRootProps, isOpen, ...childProps }) => {
    const { className } = this.props;
    return (
      <div className={cx('Dropdown', className)}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>{this.props.children(childProps)}</div>
            )}
          </Reference>
          {isOpen && (
            <Popper placement="bottom">
              {({ ref, style, scheduleUpdate, placement }) => {
                this.popperScheduleUpdate = scheduleUpdate;
                return this.renderMenu(childProps, ref, style, placement);
              }}
            </Popper>
          )}
        </Manager>
      </div>
    );
  };

  renderMenu = (childProps, ref, style, placement) => {
    const { getMenuProps } = childProps;
    const renderOptions = this.props.renderOptions
      ? this.props.renderOptions
      : this.renderOptions;

    return (
      <div
        {...getMenuProps({ ref })}
        style={style}
        className={cx('Dropdown__menu', `Dropdown__menu--${placement}`)}
      >
        {renderOptions(childProps)}
      </div>
    );
  };

  renderNoResults = () => {
    if (this.props.typeAhead) return null;
    return (
      <span className="Dropdown__placeholder">
        {this.props.t('fields.autoComplete.noResults')}
      </span>
    );
  };

  renderOptions = (childProps) => {
    const { options, filterOptions, multi, fieldName, withIcon } = this.props;
    const filteredOptions = filterOptions
      ? options.filter((o) => filterOptions(o, childProps))
      : options;
    return filteredOptions.length ? (
      <Menu
        isOpen
        options={filteredOptions}
        multi={multi}
        fieldName={fieldName}
        withIcon={withIcon}
        {...childProps}
      />
    ) : (
      this.renderNoResults()
    );
  };

  render() {
    const { className, onInputValueChange, ...otherProps } = this.props;
    const Wrapper = this.props.multi ? MultipleDownshift : Downshift;
    return (
      <Wrapper
        {...otherProps}
        onInputValueChange={this.handleInputValueChange}
        onStateChange={this.handleStateChange}
      >
        {this.renderDropdown}
      </Wrapper>
    );
  }
}

Dropdown.propTypes = {
  t: func.isRequired,
  options: arrayOf(oneOfType([object, string])),
  onInputValueChange: func,
  className: string,
  children: func.isRequired,
  filterOptions: func,
  itemToString: func,
  multi: bool,
  typeAhead: bool,
  renderOptions: oneOfType([func, bool]),
  onStateChange: func,
  defaultHighlightedIndex: number,
  fieldName: string.isRequired,
};

Dropdown.defaultProps = {
  className: null,
  options: [],
  filterOptions: _.stubTrue,
  itemToString: _.identity,
  multi: false,
  typeAhead: false,
  renderOptions: false,
  onInputValueChange: _.noop,
  onStateChange: _.noop,
  defaultHighlightedIndex: 0,
};

export default Dropdown;
