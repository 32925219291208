import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import translate from '@/helpers/enhancers/translate';
import * as Project from '@/api/Company/Project/mutations';
import ProjectModal from './ProjectModal';

export default compose(
  graphql(Project.createProject, { name: 'create' }),
  graphql(Project.updateProject, { name: 'update' }),
  graphql(Project.deleteProject, { name: 'delete' }),
  translate()
)(ProjectModal);
