export const FREELANCE = 'freelance';
export const RECRUITMENT_CANDIDATE = 'recruitment_candidate';
export const CITIZEN = 'citizen';
export const PATIENT = 'patient';
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';
export const INDEPENDENT_EXPERT = 'independent_expert';
export const UNKNOWN = 'unknown';

export const SOLO = 'solo';
