import { withStateHandlers } from 'recompose';
import DumbDrawer from './DumbDrawer';

export default withStateHandlers(
  () => ({
    isOpen: false,
  }),
  {
    open: () => () => ({
      isOpen: true,
    }),
    close: () => () => ({
      isOpen: false,
    }),
  }
)(DumbDrawer);
