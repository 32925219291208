import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import * as Language from '@/api/Company/Language/mutations';
import LanguageModal from './LanguageModal';

export default compose(
  graphql(Language.createLanguage, { name: 'create' }),
  graphql(Language.updateLanguage, { name: 'update' }),
  graphql(Language.deleteLanguage, { name: 'delete' }),
  translate()
)(LanguageModal);
