import React from 'react';
import client from '@/helpers/apollo';
import { isObject } from 'lodash';
import { bool, string } from 'prop-types';
import getTags from '@/api/Tag/queries';
import debouncePromise from '@/helpers/debouncePromise';
import withAync from '@/components/Dropdown/withAsync';
import { AutoComplete } from '@/components/Fields/AutoCompleteField';

const AsyncAutocomplete = withAync(AutoComplete);

function JobInput({ withAsync, name, placeholder, ...props }) {
  const loadTags = debouncePromise(
    (query) =>
      new Promise((resolve, reject) =>
        client
          .query({
            query: getTags,
            variables: { query, kind: 'job', limit: 10 },
          })
          .then(({ data }) => resolve(data.tags.map((skill) => skill.name)))
          .catch(reject)
      ),
    100
  );

  return (
    withAsync && (
      <AsyncAutocomplete
        typeAhead
        loadOptions={loadTags}
        minChars={2}
        {...props}
        {...isObject(props.value) && { value: '' }}
      />
    )
  );
}

JobInput.propTypes = {
  withAsync: bool,
  name: string.isRequired,
  placeholder: string,
};

JobInput.defaultProps = {
  withAsync: true,
  placeholder: '',
};

export default JobInput;
