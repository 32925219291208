import { connect } from 'react-redux';
import { EXPERT } from '@/constants/contractTypes';
import { get, isString } from 'lodash';
import { getFormValues } from 'redux-form';
import IndividualCertificationSkillsSection from './IndividualCertificationSkillsSection';

export default connect((state, props) => {
  // Either get contract type from form (mission or post-signup) or from props (profile)
  const contractTypes =
    get(getFormValues(props.form)(state), 'contract_type') ||
    get(getFormValues(props.form)(state), 'company_contract_types') ||
    get(props, 'contractTypes') ||
    [];

  return {
    // isString test is necessary, because for mission form, contractType is a string
    required: isString(contractTypes)
      ? contractTypes === EXPERT
      : contractTypes.includes(EXPERT),
  };
})(IndividualCertificationSkillsSection);
