import React from 'react';
import Delete from '@/images/delete.svg';

const Clear = (props) => (
  <button type="button" {...props} className="Select__clear">
    <img src={Delete} alt="" />
  </button>
);

export default Clear;
