import React from 'react';
import { func } from 'prop-types';
import sections from './sections';
import './styles.scss';

function Footer({ t }) {
  return (
    <footer className="Footer">
      <div className="Footer__inner content-wrapper-l">
        {sections.map(({ id, component: S }) => <S key={id} t={t} />)}
      </div>
    </footer>
  );
}

Footer.propTypes = {
  t: func.isRequired,
};

export default Footer;
