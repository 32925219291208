import React, { useState } from 'react';
import { bool, string, node, func } from 'prop-types';
import cx from 'classnames';
import { ReactComponent as Close } from '@/images/delete.svg';
import './styles.scss';

function InformationMessage({
  className,
  dismissible,
  title,
  text,
  action,
  children,
  handleClose,
}) {
  const [isOpen, setIsOpen] = useState(true);
  if (!isOpen) return null;

  const handleClick = () => {
    setIsOpen(false);
    handleClose();
  };

  return (
    <div
      className={cx(
        'InformationMessage',
        { 'InformationMessage--dismissible': dismissible },
        className
      )}
    >
      {dismissible && (
        <button
          type="button"
          onClick={() => handleClick()}
          className="InformationMessage__dismiss"
        >
          <Close />
        </button>
      )}
      <div className="InformationMessage__inner">
        {children}
        {title && <h1 className="InformationMessage__title">{title}</h1>}
        {text && <p className="InformationMessage__content">{text}</p>}
        {action && <div className="InformationMessage__action">{action}</div>}
      </div>
    </div>
  );
}

InformationMessage.propTypes = {
  className: string,
  dismissible: bool,
  title: string,
  text: string,
  action: node,
  handleClose: func,
  children: node,
};

InformationMessage.defaultProps = {
  className: null,
  dismissible: false,
  title: null,
  action: null,
  text: null,
  handleClose: Function.prototype,
  children: null,
};

export default InformationMessage;
