import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import uuid from 'uuid/v1';
import './styles.scss';

class Field extends React.Component {
  getInputProps = (inputProps) => (props) => ({ ...inputProps, ...props });

  renderHelper = (helperText) => (
    <div className={`${this.props.baseClass}__helper`}>{helperText}</div>
  );

  render() {
    const {
      inline,
      helper,
      baseClass,
      error,
      className,
      touched,
      label,
      dirty,
      render,
      ...restProps
    } = this.props;

    if (!restProps.id) restProps.id = uuid();

    const showError = (dirty || touched) && error;
    const fieldClasses = cx(
      baseClass,
      {
        [`${baseClass}--inline`]: inline,
        [`${baseClass}--error`]: showError,
        [`${baseClass}--has-helper`]: helper,
        [`${baseClass}--disabled`]: this.props.disabled, // pas de destructuration pour la laisser dans restProps
        [`${baseClass}--required`]: this.props.required, // pas de destructuration pour la laisser dans restProps
      },
      className
    );

    restProps.className = cx('Field__input', restProps.inputClassName);
    delete restProps.inputClassName;

    return (
      <div className={fieldClasses}>
        {(label || helper) && (
          <div className={`${this.props.baseClass}__meta`}>
            {label && (
              <label
                className={`${this.props.baseClass}__label`}
                htmlFor={restProps.id}
              >
                <p>{label}</p>
              </label>
            )}

            {helper && (
              <div className={`${this.props.baseClass}__helper`}>{helper}</div>
            )}
          </div>
        )}

        <div className="Field__wrapper">
          {this.props.render({
            getInputProps: this.getInputProps(restProps),
          })}

          {showError && (
            <div className={`${this.props.baseClass}__error`}>{error}</div>
          )}
        </div>
      </div>
    );
  }
}

Field.propTypes = {
  inline: pp.bool,
  baseClass: pp.string,
  helper: pp.string,
  touched: pp.bool,
  dirty: pp.bool,
  className: pp.string,
  disabled: pp.bool,
  error: pp.oneOfType([pp.string, pp.node]),
  label: pp.string,
  id: pp.string,
  required: pp.bool,
  render: pp.func.isRequired,
};

Field.defaultProps = {
  inline: false,
  baseClass: 'Field',
  helper: null,
  touched: false,
  dirty: false,
  className: null,
  disabled: false,
  error: null,
  label: null,
  id: null,
  required: false,
};

export default Field;
