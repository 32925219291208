import React from 'react';
import { get } from 'lodash';
import MilestoneForfeitForm from '@/components/Contract/forms/MilestoneForfeitForm';
import MilestoneATForm from '@/components/Contract/forms/MilestoneATForm';
import ModerationForm from '@/components/Contract/forms/ModerationForm';
import {
  acceptOrRejectMilestone,
  cancelMilestone,
  createOrUpdateTechnicalAssistance,
  createOrUpdateForfeit,
  deliverForfeitMilestone,
  deliverTechnicalAssistanceMilestone,
} from '@/api/Contract/mutations';
import CancellationForm from '@/components/Contract/forms/CancellationForm';
import DeliverATForm from '@/components/Contract/forms/DeliverATForm';
import DeliverForfeitForm from '@/components/Contract/forms/DeliverForfeitForm';
import DeliverModerationForm from '@/components/Contract/forms/DeliverModerationForm';

export const CREATE_MODERATION = 'create_moderation';
export const CREATE = 'create';

export const UPDATE_MODERATION = 'update_moderation';
export const UPDATE = 'update';

export const CANCEL_MODERATION = 'cancel_moderation';
export const CANCEL = 'cancel';

export const DELIVER = 'deliver';
export const DELIVERY_MODERATION = 'delivery_moderation';

export const milestoneFormMap = {
  forfeit: {
    child: <MilestoneForfeitForm />,
    mutation: createOrUpdateForfeit,
  },
  technical_assistance: {
    child: <MilestoneATForm />,
    mutation: createOrUpdateTechnicalAssistance,
  },
};

export const deliverMap = {
  forfeit: {
    child: <DeliverForfeitForm />,
    mutation: deliverForfeitMilestone,
  },
  technical_assistance: {
    child: <DeliverATForm />,
    mutation: deliverTechnicalAssistanceMilestone,
  },
};

const propsMap = {
  [CREATE]: {
    id: CREATE,
    submitButtonLabel: 'contract.milestone.submit.create',
    title: 'contract.milestone.title.create',
    context: CREATE,
  },
  [CREATE_MODERATION]: {
    id: CREATE_MODERATION,
    submitButtonLabel: 'contract.milestone.submit.create_moderation',
    title: 'contract.milestone.title.create_moderation',
    subtitle: 'contract.milestone.subtitle.create_moderation',
    child: <ModerationForm />,
    context: CREATE_MODERATION,
    mutation: acceptOrRejectMilestone,
  },
  [UPDATE]: {
    id: UPDATE,
    submitButtonLabel: 'contract.milestone.submit.update',
    title: 'contract.milestone.title.update',
    footerLabel: 'milestone.',
    context: UPDATE,
  },
  [UPDATE_MODERATION]: {
    id: UPDATE_MODERATION,
    submitButtonLabel: 'contract.milestone.submit.create_moderation',
    title: 'contract.milestone.title.update_moderation',
    subtitle: 'contract.milestone.subtitle.update_moderation',
    child: <ModerationForm />,
    context: UPDATE_MODERATION,
    mutation: acceptOrRejectMilestone,
  },
  [CANCEL]: {
    id: CANCEL,
    submitButtonLabel: 'contract.milestone.submit.cancel',
    title: 'contract.milestone.title.cancel',
    subtitle: 'contract.milestone.subtitle.cancel',
    child: <CancellationForm />,
    context: CANCEL,
    mutation: cancelMilestone,
  },
  [CANCEL_MODERATION]: {
    id: CANCEL_MODERATION,
    submitButtonLabel: 'contract.milestone.submit.cancel_moderation',
    title: 'contract.milestone.title.cancel_moderation',
    subtitle: 'contract.milestone.subtitle.cancel_moderation',
    child: <ModerationForm />,
    context: CANCEL_MODERATION,
    mutation: acceptOrRejectMilestone,
  },
  [DELIVER]: {
    id: DELIVER,
    submitButtonLabel: 'contract.milestone.submit.deliver',
    title: 'contract.milestone.title.deliver',
    subtitle: 'contract.milestone.subtitle.deliver',
    context: DELIVER,
  },
  [DELIVERY_MODERATION]: {
    id: CREATE_MODERATION,
    submitButtonLabel: 'contract.milestone.submit.delivery_moderation',
    title: 'contract.milestone.title.delivery_moderation',
    child: <DeliverModerationForm />,
    context: DELIVERY_MODERATION,
    mutation: acceptOrRejectMilestone,
  },
};

export const map = {
  client: {
    create_moderation: get(propsMap, CREATE_MODERATION),
    cancel_moderation: get(propsMap, CANCEL_MODERATION),
    update_moderation: get(propsMap, UPDATE_MODERATION),
    delivery_moderation: get(propsMap, DELIVERY_MODERATION),
  },
  expert: {
    create: get(propsMap, CREATE),
    cancel: get(propsMap, CANCEL),
    update: get(propsMap, UPDATE),
    deliver: get(propsMap, DELIVER),
  },
};
