import moment from 'moment';
import { PRECISE } from '@/constants/availabilities';

/**
 * Check if limited_at date has passed from today
 * @param limited
 * @param limited_at
 * @returns {boolean}
 */
export const limitDateHasPassed = (limited, limited_at) => {
  const diffFromToday = moment(limited_at).diff(moment(), 'days', true);

  return limited === PRECISE && diffFromToday <= 0;
};
