import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import { filter, findIndex, noop } from 'lodash';
import { propType } from 'graphql-anywhere';
import { Portal } from 'react-portal';
import attachmentFragment from '@/api/Attachment/fragment';
import { FileDropzone, FileInput } from '@/components/Fields/FileField';
import Files from '@/components/Files';

class FilesInput extends React.Component {
  state = {
    files: this.props.value,
  };

  static getDerivedStateFromProps(props, state) {
    return {
      files: filter(
        state.files,
        ({ file_uuid, ...file }) =>
          findIndex(props.value, { file_uuid }) + 1 ||
          'percentCompleted' in file
      ),
    };
  }

  handleUploadProgress = (file, percentCompleted) => {
    if (percentCompleted === 0) {
      this.setState(({ files }) => ({
        files: [...files, { ...file, percentCompleted }],
      }));
    } else {
      this.setState(({ files }) => ({
        files: files.map((f) => {
          if (f.file_uuid !== file.file_uuid) return f;
          return { ...file, percentCompleted };
        }),
      }));
    }
  };

  handleDeleteClick = (index) => {
    // eslint-disable-next-line
    const newFiles = this.state.files.filter((f, i) => i !== index);
    this.setState({ files: newFiles });
    this.props.onChange(newFiles);
  };

  render() {
    const {
      portalSelector,
      reset,
      toggleReset,
      drop,
      maxSize,
      minSize,
      ...otherProps
    } = this.props;
    const { files } = this.state;
    const { idContainer } = otherProps;

    if (reset) {
      this.setState({ files: [] });
      this.props.onChange([]);
      toggleReset();
    }

    const nextFiles = files.map((file) => {
      let status;
      if ('percentCompleted' in file) {
        status = file.percentCompleted === 100 ? 'edit' : 'upload';
      } else {
        status = 'edit';
      }
      return { ...file, status };
    });

    return (
      <div className="FilesInput" id={idContainer}>
        {portalSelector ? (
          <Portal node={document && document.querySelector(portalSelector)}>
            <Files files={nextFiles} onActionClick={this.handleDeleteClick} />
          </Portal>
        ) : (
          <Files files={nextFiles} onActionClick={this.handleDeleteClick} />
        )}

        {drop ? (
          <FileDropzone
            {...otherProps}
            onUploadProgress={this.handleUploadProgress}
          />
        ) : (
          <FileInput
            {...otherProps}
            onUploadProgress={this.handleUploadProgress}
          />
        )}
      </div>
    );
  }
}

FilesInput.propTypes = {
  id: string,
  value: arrayOf(propType(attachmentFragment)),
  onChange: func,
  drop: bool,
  portalSelector: string,
  reset: bool,
  toggleReset: func,
};

FilesInput.defaultProps = {
  id: '',
  value: [],
  onChange: noop,
  drop: true,
  portalSelector: null,
  reset: false,
  toggleReset: noop,
};

export default FilesInput;
