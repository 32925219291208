import { chargeKeyToCheck, QUANTITY, COST_PER_UNIT } from '@/constants/charges';

// This function compute the sum of all the milestones' charges
export const totalCharges = (charges = []) =>
  charges.reduce((acc, charge) => {
    if (
      !Object.prototype.hasOwnProperty.call(charge, '_destroy') &&
      charge.quantity &&
      charge.cost_per_unit
    ) {
      return acc + charge.quantity * charge.cost_per_unit;
    }
    return acc;
  }, 0);

export const totalCostHtWithCharges = (milestones) => {
  let totalCostHt = 0;
  milestones.forEach((m) => {
    totalCostHt += m.price + totalCharges(m.charges);
  });
  return totalCostHt;
};

// Array of charges id modified or deleted
export const modifiedChargesId = (oldCharges, newCharges) =>
  oldCharges.reduce((acc, currentCharge, index) => {
    // To know if all keys to check are equal between the charge in the milestone and the charge in the form values
    const isEqual = chargeKeyToCheck.every((key) => {
      // Quantity and cost_per_unit fields are strings in the front form, but numbers in the database...
      if (key === COST_PER_UNIT || key === QUANTITY) {
        const newChargesValueParsed = parseFloat(newCharges[index][key]);
        return currentCharge[key] === newChargesValueParsed;
      }
      return currentCharge[key] === newCharges[index][key];
    });
    const isDestroyed = Object.prototype.hasOwnProperty.call(
      newCharges[index],
      '_destroy'
    );

    // If a charge has been modified or deleted, it is added to the array
    return [...acc, ...(!isEqual || isDestroyed ? [currentCharge.id] : [])];
  }, []);
