import React from 'react';
import {
  func,
  string,
  oneOfType,
  objectOf,
  number,
  node,
  any,
} from 'prop-types';
import onClickOutside from 'react-onclickoutside';

class Menu extends React.Component {
  handleClickOutside = () => {
    this.props.close();
  };

  render() {
    const {
      innerRef,
      placement,
      style,
      spacing,
      innerSpacing,
      maxHeight,
      children,
      overflowY,
    } = this.props;

    return (
      <div
        className="Popover__menu"
        ref={innerRef}
        style={{ ...style, padding: spacing }}
        data-placement={placement}
      >
        <div
          className="Popover__menu-inner"
          style={{ padding: innerSpacing, maxHeight, overflowY }}
        >
          {children}
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  close: func.isRequired,
  innerRef: func.isRequired,
  placement: string,
  style: objectOf(any).isRequired,
  spacing: oneOfType([number, string]).isRequired,
  innerSpacing: oneOfType([number, string]).isRequired,
  maxHeight: oneOfType([number, string]),
  children: node.isRequired,
  overflowY: string,
};

Menu.defaultProps = {
  placement: 'bottom',
  maxHeight: 'inherit',
  overflowY: 'inherit',
};

export default onClickOutside(Menu);
