import React from 'react';
import { string } from 'prop-types';
import { backofficeUrl } from '@/constants/environment';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

const AdminBanner = ({ email }) => {
  const { t } = useTranslation();
  return (
    <div className="Admin__banner">
      <div className="Admin__banner__message">
        {t('admin.connected_as_user.message')}
        <span className="fw-bold">{email}</span>
      </div>
      <a
        className="Admin__banner__link"
        href={backofficeUrl}
        target="_blank"
        rel="noreferrer noopener"
      >
        {t('admin.connected_as_user.link')}
      </a>
    </div>
  );
};

AdminBanner.propTypes = {
  email: string,
};

AdminBanner.defaultProps = {
  email: '',
};

export default AdminBanner;
