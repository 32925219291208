import Entity from './Entity';
import { FORFEIT } from '@/constants/quotationKind';
import { DELIVERY_WITH_UPDATE_REVIEW_CLIENT } from '@/components/Contract/constants/statusesProperties';
import priceFormat from '@/helpers/priceFormat';
import { HOUR } from '@/constants/costPerKind';
import config from '@/_config';

class ContractMilestone extends Entity {
  amount(contractKind) {
    if (contractKind === FORFEIT) {
      return (
        this.get('duty_ratio') &&
        `${(this.get('duty_ratio') * 100).toFixed(2)}%`
      );
    }
    return priceFormat(this.get('units_worked'), {
      minDigits: 2,
      maxDigits: 2,
    });
  }

  static unit(contractKind) {
    if (contractKind === FORFEIT) {
      return 'contract.milestone.units.forfeit';
    }

    return config.proposal.costPer === HOUR
      ? 'contract.milestone.units.hours'
      : 'contract.milestone.units.days';
  }

  static get modelName() {
    return 'ContractMilestone';
  }

  isDeliveryWithUpdateReviewClient() {
    return this.get('status') === DELIVERY_WITH_UPDATE_REVIEW_CLIENT;
  }
}

export default ContractMilestone;
