import pp from 'prop-types';
import _ from 'lodash';

function DrawerScreen({ children, ...props }) {
  return _.isFunction(children) ? children(props) : children;
}

DrawerScreen.propTypes = {
  children: pp.oneOfType([pp.node, pp.func]),
  name: pp.string.isRequired,
};

DrawerScreen.defaultProps = {
  children: null,
};

export default DrawerScreen;
