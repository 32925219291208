import React from 'react';
import { propType } from 'graphql-anywhere';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfLabelledCheckbox } from '@/containers/ReduxForm';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import MilestoneDescriptionAndDeliverables from '@/components/Contract/MilestoneCard/MilestoneDescriptionAndDeliverables';
import MilestonePriceAndDate from '@/components/Contract/MilestoneCard/MilestonePriceAndDate';
import MilestoneInformation from '@/components/Contract/MilestoneCard/MilestoneInformation';
import './styles.scss';
import { acceptance } from '@/helpers/validation';

function CancellationForm({ context, milestone, contract }) {
  const { t } = useTranslation();

  return (
    <>
      <p className="fw-bold">{t('contract.milestone.warning.cancel')}</p>
      <div className="MilestoneCardModal">
        <div className="MilestoneCardModal__header">
          <span>{t('contract.milestone.title')}</span>
          <span>{t('contract.milestone.unit')}</span>
          <span>{t('contract.milestone.amount')}</span>
          <span>{t('contract.milestone.cost_per_unit')}</span>
          <span>{t('contract.milestone.total_duty')}</span>
        </div>
        <div className={`MilestoneCardModal__information--${context}`}>
          <MilestoneInformation
            contract={contract}
            milestone={milestone}
            costPerUnit={contract.costPerUnit}
          />
        </div>
        <div className={`MilestoneCardModal__price--${context}`}>
          <MilestonePriceAndDate
            milestone={milestone}
            contract={contract}
            classes={{ priceAndDate: 'MilestoneCard__price-and-date' }}
          />
        </div>
        <div className="MilestoneCardModal__deliverables">
          <MilestoneDescriptionAndDeliverables milestone={milestone} />
        </div>
      </div>
      <RequiredField
        name="confirm"
        type="checkbox"
        component={RfLabelledCheckbox}
        label={t('contract.milestone.confirm.label.cancel')}
        validate={[acceptance()]}
      />
    </>
  );
}

CancellationForm.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
};

export default CancellationForm;
