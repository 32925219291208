import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

const resizableClassNames = {
  both: 'TextArea--resize',
  x: 'TextArea--resizeX',
  y: 'TextArea--resizeY',
};

function TextArea({ resizable, className, value, max, ...otherProps }) {
  const { t } = useTranslation();
  return (
    <>
      <textarea
        value={value}
        className={cx('TextArea', className, resizableClassNames[resizable])}
        {...otherProps}
      />
      {max && (
        <span className="TextArea__count">
          {t('fields.validators.count', { count: value.length, max })}
        </span>
      )}
    </>
  );
}

TextArea.propTypes = {
  className: pp.string,
  resizable: pp.oneOf(['none', 'x', 'y', 'both']),
  value: pp.string,
  max: pp.number,
};

TextArea.defaultProps = {
  className: null,
  resizable: 'none',
  value: '',
  max: null,
};

export default TextArea;
