import config from '@/_config';

export default {
  expert: {
    label: 'fields.kinds.expert',
    value: 'expert',
    id: 'expert',
  },
  client: {
    label: 'fields.kinds.client',
    value: 'client',
    id: 'client',
  },
  ...(config.global.signUpMixed && {
    mixed: {
      label: 'fields.kinds.mixed',
      value: 'mixed',
      id: 'mixed',
    },
  }),
};
