import React from 'react';
import pp from 'prop-types';
import { Mutation } from 'react-apollo';
import { deleteQuote } from '@/api/Proposal/mutations';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';

function BillingModal({ close, quoteId, selectedBilling, onOkCallback }) {
  const { t } = useTranslation();
  return (
    <Mutation mutation={deleteQuote} variables={{ id: quoteId }}>
      {(mutate, { loading }) => (
        <Modal
          close={close}
          onOk={async () => {
            await mutate({
              refetchQueries: ['getProposal'],
              awaitRefetchQueries: true,
            });
            onOkCallback();
            close();
          }}
          onCancel={close}
          name="Billing"
          size="medium"
          title={t('proposal.modal.billing.title')}
          subtitle={t(`proposal.modal.billing.subtitle.${selectedBilling}`)}
          disableActions={loading}
          closable={!loading}
        />
      )}
    </Mutation>
  );
}

BillingModal.propTypes = {
  close: pp.func.isRequired,
  onOkCallback: pp.func,
  quoteId: pp.string.isRequired,
  selectedBilling: pp.string.isRequired,
};

BillingModal.defaultProps = {
  onOkCallback: Function.prototype,
};

export default BillingModal;
