import React from 'react';
import { func } from 'prop-types';
import config from '@/_config';
import goals from '@/constants/fields/goals';
import { RequiredField, RfChoices, RfHelper } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import { getCompanyType, isSolo } from '@/helpers/company';
import {
  FULLTIME_CONTRACT,
  INTERNSHIP,
  MISSION_OR_FULLTIME,
  MISSIONS,
  FIXED_TERM_CONTRACT,
  THESIS,
} from '@/constants/contractTypes';
import { PROFILE_MODALITIES } from '@/constants/forms';
import { get } from 'lodash';

function ContractTypeSection({ t, change }) {
  const handleChange = (kind) => (e, value) => {
    const recruitmentCandidate = getCompanyType('recruitment_candidate');
    const freelance = getCompanyType('freelance');

    if (
      [FULLTIME_CONTRACT, INTERNSHIP, FIXED_TERM_CONTRACT, THESIS].includes(
        value
      )
    ) {
      change(PROFILE_MODALITIES, 'kind', recruitmentCandidate.value);
    } else if (
      [MISSION_OR_FULLTIME, MISSIONS].includes(value) &&
      isSolo(kind)
    ) {
      change(PROFILE_MODALITIES, 'kind', freelance.value);
    }
  };

  function isContractTypeOptionDisabled(contractTypes, companyType) {
    return contractTypes === MISSIONS && !isSolo(companyType);
  }
  return (
    <RfHelper values={['contract_types', 'kind']}>
      {({ contract_types, kind }) => (
        <FormSection
          title={t('company.modal.modalities.goals.title', {
            companyName: config.displayName,
          })}
        >
          <RequiredField
            type="radioGroup"
            name="contract_types"
            component={RfChoices}
            checkbox={LabelledRadio}
            label={t('company.modal.modalities.contractTypes.label')}
            cbProps={goals
              .filter((goal) => get(goal, 'choiceVisibility', true) === true)
              .map((goal) => ({
                ...goal,
                label: t(goal.label),
                icon: goal.icon,
                disabled: isContractTypeOptionDisabled(contract_types, kind),
              }))}
            withSeparators={false}
            onChange={handleChange(kind)}
          />
        </FormSection>
      )}
    </RfHelper>
  );
}

ContractTypeSection.propTypes = {
  t: func.isRequired,
  change: func.isRequired,
};

export default ContractTypeSection;
