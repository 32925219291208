import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import RCSlider, { Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { KEYCODES } from '@/constants/keyCodes';
import './styles.scss';

class Slider extends React.Component {
  static propTypes = {
    className: pp.string,
    value: pp.arrayOf(pp.number),
    domain: pp.arrayOf(pp.number),
    count: pp.number,
    disabled: pp.bool,
    onChange: pp.func,
    onKeyDown: pp.func,
    step: pp.number,
    onUpdate: pp.func,
    mode: pp.number,
  };

  static defaultProps = {
    className: null,
    value: [50],
    domain: [1, 100],
    count: null,
    disabled: false,
    onChange: _.noop,
    onKeyDown: _.noop,
    onUpdate: _.noop,
    step: 1,
    mode: 3,
  };

  handleChange = _.debounce((values) => {
    this.props.onChange(values);
  }, 300);

  handleKeyDown = (handleIndex) => (e) => {
    const { onUpdate, disabled, step, value, domain } = this.props;
    if (disabled) return;
    // if (value[handleIndex] === domain[handleIndex]) return;
    if (e.keyCode === KEYCODES.arrowLeft || e.keyCode === KEYCODES.arrowRight) {
      const direction = e.keyCode - 38;
      const nextValue = value[handleIndex] + step * direction;

      if (_.first(domain) > nextValue || _.last(domain) < nextValue) return;
      const newValues = [
        ...value.slice(0, handleIndex),
        nextValue,
        ...value.slice(handleIndex + 1),
      ];
      onUpdate(newValues);

      this.handleChange(newValues);
    }
  };

  handleDisabled = (getPropsFn, ...fnArgs) => {
    const { onMouseDown, onTouchStart, ...otherProps } = getPropsFn(...fnArgs);
    const handlers = this.props.disabled
      ? {
          onMouseDown: _.noop,
          onTouchStart: _.noop,
        }
      : {
          onMouseDown,
          onTouchStart,
        };
    return { ...otherProps, ...handlers };
  };

  render() {
    const {
      className,
      value: values,
      domain: [min, max],
      count,
      disabled,
      ...otherProps
    } = this.props;

    return (
      <RCSlider
        {...otherProps}
        values={values}
        domain={[min, max]}
        className={cx(
          'Slider',
          {
            'Slider--disabled': disabled,
            'Slider--has-ticks': count,
          },
          className
        )}
      >
        <Rail>
          {({ getRailProps }) => (
            <div
              {...this.handleDisabled(getRailProps)}
              className="Slider__rail"
            />
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map((handle, i) => (
                <div
                  {...this.handleDisabled(getHandleProps, handle.id)}
                  onKeyDown={this.handleKeyDown(i)}
                  tabIndex="0"
                  role="slider"
                  aria-valuemin={min}
                  aria-valuemax={max}
                  aria-valuenow={handle.value}
                  aria-disabled={disabled}
                  style={{ left: `${handle.percent}%` }}
                  className="Slider__handle"
                  key={handle.id}
                />
              ))}
            </div>
          )}
        </Handles>

        <Tracks left={values.length === 1} right={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <div
                  {...this.handleDisabled(getTrackProps)}
                  style={{
                    left: `${source.percent}%`,
                    width: `${target.percent - source.percent}%`,
                  }}
                  className="Slider__track"
                  key={id}
                />
              ))}
            </div>
          )}
        </Tracks>
        {count ? (
          <Ticks count={count}>
            {({ ticks, emitTouch, emitMouse }) => (
              <div>
                {ticks.map((tick) => (
                  <div key={tick.id}>
                    <div
                      className="Slider__tick"
                      style={{
                        left: `${tick.percent}%`,
                      }}
                    />
                    <div
                      className="Slider__tickText"
                      role="button"
                      tabIndex={-1}
                      onClick={(e) => {
                        emitMouse(e);
                        emitTouch(e);
                      }}
                      style={{
                        marginLeft: `${-(100 / count) / 2}%`,
                        width: `${100 / count}%`,
                        left: `${tick.percent}%`,
                      }}
                    >
                      {tick.value}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </Ticks>
        ) : (
          _.noop
        )}
      </RCSlider>
    );
  }
}

export default Slider;
