import _ from 'lodash';
import attachmentResolvers from './Attachment/resolvers';
import bookmarkResolvers from './Bookmark/resolvers';
import companyResolvers from './Company/resolvers';
import missionResolvers from './Mission/resolvers';
import userResolvers from './User/resolvers';
import contractResolvers from './Contract/resolvers';
import collaboratorResolvers from './Company/Collaborator/resolvers';

const resolvers = _.merge(
  attachmentResolvers,
  bookmarkResolvers,
  companyResolvers,
  missionResolvers,
  userResolvers,
  contractResolvers,
  collaboratorResolvers
);

export default resolvers;
