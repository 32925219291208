import { createElement } from 'react';
import {
  buildSuggestionsList,
  getFilteredLists,
} from '@/components/Header/SearchBar/helpers/suggestions';

const filterList = (companyList, condition, index) => ({
  index,
  hits: companyList.hits.filter((hit) => condition(hit)),
});

const splitCompany = (lists) => {
  const companyListIndex = lists.findIndex((l) => l.index === 'Company');
  if (!~companyListIndex) return lists;

  const list = lists[companyListIndex];
  const listsFiltered = getFilteredLists(list, filterList);
  return [
    ...lists.slice(0, companyListIndex),
    ...listsFiltered,
    ...lists.slice(companyListIndex + 1),
  ];
};

const addLinkToHit = (cb) => (hit) => ({ ...hit, link: cb(hit.objectID) });

const getSuggestionsLists = (lists) =>
  splitCompany(lists).map(
    ({ hits = [], ...list }) =>
      buildSuggestionsList(list, hits, addLinkToHit)[list.index]
  );

export default (Comp) => ({ hits, ...props }) =>
  createElement(Comp, {
    ...props,
    suggestions: getSuggestionsLists(hits),
  });
