import React from 'react';
import pp from 'prop-types';
import Field from '../Field';
import LocationInput from './LocationInput';

const LocationField = ({ label, ...props }) => (
  <Field
    {...props}
    render={({ getInputProps }) => (
      <LocationInput {...getInputProps({ label })} />
    )}
  />
);

LocationField.propTypes = {
  label: pp.string,
};

LocationField.defaultProps = {
  label: null,
};

export default LocationField;
