import React, { useContext } from 'react';
import { get, isNull } from 'lodash';
import { any, func, objectOf } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import RichText from '@/components/RichText';
import Files from '@/components/Files';
import Img from '@/components/Img';
import TagsView from '@/components/Tags/components/TagsView';
import TagsContext from '@/components/Tags/TagsContext';
import { ReactComponent as Phone } from '@/images/phone.svg';
import { ReactComponent as Email } from '@/images/email.svg';
import { ReactComponent as Briefcase } from '@/images/briefcase.svg';
import config from '@/_config';
import UserContext from '@/permissions/contexts/UserContext';
import AbilityContext from '@/permissions/contexts/AbilityContext';
import UserModel from '@/models/User';
import './styles.scss';

const ViewCollaboratorsModal = ({ close, ...props }) => {
  const { t } = useTranslation();
  const { initialValues } = props;
  const { email, phone, description, equipments } = initialValues;
  const job_name = get(initialValues, 'job.name');
  const files = [...get(initialValues, 'attachments', [])];

  const firstName = get(initialValues, 'first_name', '');
  const lastName = get(initialValues, 'last_name', '');
  const name = `${firstName}${!isNull(lastName) ? ` ${lastName}` : ''}`;

  const onSiteLocations = get(initialValues, 'on_site_locations', []);
  const expTime = get(initialValues, 'exp_time');

  const skills = get(initialValues, 'skills', []);
  const personalSkills = get(initialValues, 'personal_skills', []);
  const hasSkills = skills.length > 0;
  const hasPersonalSkills = personalSkills.length > 0;

  const ability = useContext(AbilityContext);
  const contextUser = useContext(UserContext);
  const user = new UserModel(contextUser);

  return (
    <Modal
      name="Collaborators"
      close={close}
      onCancel={close}
      hideActions
      className="content-wrapper"
    >
      <div className="Collaborator">
        <div className="Collaborator__info">
          <div className="Collaborator__heading">
            <Img
              src={get(initialValues, 'avatar.read_url', null)}
              alt={name}
              className="Collaborator__avatar"
            />
            <div>
              <h3 className="m-b-xs">{name}</h3>
              <h3 className="m-b-xs primary">{job_name}</h3>
              {email &&
                ability.can('view_admin_section', user) && (
                  <div className="Collaborator__text Collaborator__text--picto m-b-xs">
                    <Email />
                    <span className="p-l-s grey">{email}</span>
                  </div>
                )}
              {phone &&
                ability.can('view_admin_section', user) && (
                  <div className="Collaborator__text Collaborator__text--picto m-b-xs">
                    <Phone />
                    <span className="p-l-s grey">{phone}</span>
                  </div>
                )}
              {expTime && (
                <div className="Collaborator__text Collaborator__text--picto m-b-m">
                  <Briefcase />
                  <span className="p-l-s grey">
                    {t('modalities.experience.expert', {
                      count: expTime,
                    })}
                  </span>
                </div>
              )}
              {onSiteLocations.length > 0 && (
                <>
                  <h3 className="m-b-xs">
                    {t('company.modal.collaborators.on_site_locations')}
                  </h3>

                  <ul>
                    {onSiteLocations.map((location) => (
                      <li className="fs-small" key={location.address}>
                        {location.address}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
          {(hasSkills || hasPersonalSkills) && (
            <div className="Collaborator__skills">
              {hasSkills && (
                <div className="m-b-m">
                  <h3 className="m-b-xs">
                    {t('company.section.skills.technical.title')}
                  </h3>
                  <TagsContext.Provider
                    value={{
                      withRating: true,
                    }}
                  >
                    <TagsView
                      tags={skills}
                      idKey="id"
                      labelKey="name"
                      highlight
                    />
                  </TagsContext.Provider>
                </div>
              )}
              {hasPersonalSkills && (
                <div className="m-b-m">
                  <h3 className="m-b-xs">
                    {t('company.section.skills.personal.title')}
                  </h3>
                  <TagsContext.Provider
                    value={{
                      withRating: true,
                    }}
                  >
                    <TagsView
                      tags={personalSkills}
                      idKey="id"
                      labelKey="name"
                      highlight
                    />
                  </TagsContext.Provider>
                </div>
              )}
            </div>
          )}
        </div>
        <div className="Collaborator__footer">
          {description && (
            <div className="m-b-m">
              <h3 className="m-b-xs">
                {t('company.modal.collaborator.description.label')}
              </h3>
              <RichText>{description}</RichText>
            </div>
          )}
          {config.company.showEquipments &&
            equipments.length > 0 && (
              <div className="m-b-m">
                <h3 className="m-b-xs">
                  {t('company.section.equipments.title')}
                </h3>
                <TagsContext.Provider
                  value={{
                    withRating: true,
                  }}
                >
                  <TagsView
                    tags={equipments}
                    idKey="id"
                    labelKey="name"
                    highlight
                  />
                </TagsContext.Provider>
              </div>
            )}
          {files.length > 0 && (
            <>
              <h3 className="m-b-xs">
                {t('company.modal.collaborator.attachments.label')}
              </h3>
              <Files files={files} />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

ViewCollaboratorsModal.propTypes = {
  close: func.isRequired,
  initialValues: objectOf(any),
};

ViewCollaboratorsModal.defaultProps = {
  initialValues: {},
};

export default ViewCollaboratorsModal;
