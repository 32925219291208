import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import Modal from '@/components/Modal';

class BoardModal extends React.Component {
  submitFn = async (values) => {
    const { formatValues } = this.props;
    await this.props.mutate({
      refetchQueries: [`getMyMission`],
      awaitRefetchQueries: true,
      variables: formatValues(values),
    });
    this.props.close();
  };

  render() {
    const {
      t,
      children,
      close,
      handleSubmit,
      formatValues,
      submitting,
      ...otherProps
    } = this.props;
    return (
      <Modal
        {...otherProps}
        close={close}
        onCancel={close}
        onOk={handleSubmit(this.submitFn)}
        disableActions={submitting}
        closable={!submitting}
      >
        {children}
      </Modal>
    );
  }
}

BoardModal.propTypes = {
  closeModal: pp.func.isRequired,
  children: pp.node.isRequired,
  close: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  formatValues: pp.func,
};

BoardModal.defaultProps = {
  formatValues: _.identity,
};

export default BoardModal;
