import React from 'react';
import { propType } from 'graphql-anywhere';
import { Redirect } from 'react-router-dom';
import { parse, stringify } from 'query-string';
import { get } from 'lodash';
import { getLoginRoute } from '@/helpers/router';
import userFragment from '@/api/User/fragment';
import Route from './Route';

function PrivateRoute({ currentUser, restricted, ...otherProps }) {
  const { location } = otherProps;

  // Get token and scope variables from url query, in the case of SSO login
  // Through HTTP redirect
  const { token, scopes, error } = parse(get(location, 'search', ''));

  if (!get(currentUser, 'id')) {
    const queryString = stringify({
      token,
      scopes,
    });

    const errorQueryString = stringify({
      error,
    });

    return (
      <Redirect
        to={{
          pathname: getLoginRoute(),
          state: { from: location.pathname, search: location.search },
          ...(token && scopes && { search: queryString }),
          ...(error && { search: errorQueryString }),
        }}
      />
    );
  }

  return <Route {...otherProps} />;
}

PrivateRoute.propTypes = {
  currentUser: propType(userFragment),
};

PrivateRoute.defaultProps = {
  currentUser: null,
};

export default PrivateRoute;
