import { get } from 'lodash';
import { DISTANCE, LOCATION } from '@/components/Matching/Filters/presets';

/**
 * Factory for nested filters item to be more atomic and avoiding specific condition inside handler
 * Some of the components mounted by nested filter might need specific logical attention
 * Implements in case statements specifics logical
 * @param item
 * @param selectedFilters
 * @returns {{meta: *, value: undefined}|{value: *}}
 */
function factory(item, selectedFilters) {
  const key = get(item, 'key');
  const selected = get(selectedFilters, key);
  switch (key) {
    case LOCATION:
      return {
        value: get(selectedFilters, `${key}.description`), // get description for sending only value
        meta: get(selectedFilters, key), // get all meta to feed properly localization component
        selected,
      };
    case DISTANCE:
      return {
        value: get(selectedFilters, key, 0), // slider can not be init to null value, it must be an integer
        selected,
      };
    default:
      return {
        value: get(selectedFilters, key),
        selected,
      };
  }
}

/**
 * HandleNestedFilterItems
 * Return formatted items for NESTED type filter
 * @param items
 * @param selectedFilters
 * @returns {*}
 */
export function handler(items, selectedFilters) {
  return items.map((item) => ({
    ...item,
    ...factory(item, selectedFilters),
  }));
}
