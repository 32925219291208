import React from 'react';
import { string, objectOf, any, bool } from 'prop-types';
import { findIndex, get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import { getMissionsListForInvitation } from '@/api/MissionsList/queries';
import { ReactComponent as Plus } from '@/images/plus.svg';
import { ReactComponent as CheckDouble } from '@/images/check-double.svg';
import InfiniteScroll from '@/components/InfiniteScroll';
import Button from '@/components/Button';
import Mission from '@/models/Mission';
import './styles.scss';

function InvitationsField({
  fields,
  id: candidate_company_id,
  list_id,
  inviteList,
  ability,
}) {
  const { t } = useTranslation();

  return (
    <div className="InvitationsField__wrapper">
      <InfiniteScroll
        className="InvitationsField"
        query={getMissionsListForInvitation}
        variables={{ status: ['published'], candidate_company_id, list_id }}
        useWindow={false}
        itemsPath="current_user.company.missions"
        renderItem={(mission) => {
          const currentMission = new Mission(mission);
          const invited = currentMission.hasBeenInvited() && !inviteList;
          const index = findIndex(fields.getAll(), (id) => id === mission.id);
          const checked = index !== -1;

          const { missionSubText, missionSubTextClass } = (() => {
            if (currentMission.hasCandidated())
              return {
                missionSubText: t('company.modal.invite.has_already_candidate'),
                missionSubTextClass: 'dsecondary',
              };
            if (currentMission.hasBlacklisted())
              return {
                missionSubText: t('company.modal.invite.has_blacklisted'),
                missionSubTextClass: 'red',
              };
            if (currentMission.hasBeenInvited() && inviteList)
              return {
                missionSubText: t('company.modal.invite.list.has_been_invited'),
                missionSubTextClass: 'dsecondary',
              };
            return {
              missionSubText: mission.context,
              missionSubTextClass: 'grey',
            };
          })();

          return (
            <>
              <div>
                <h2>{get(mission, 'job.name')}</h2>
                <h3 className={missionSubTextClass}>{missionSubText}</h3>
              </div>
              {ability.can('invite', currentMission) && (
                <Button
                  onClick={() => {
                    if (invited) return;
                    if (checked) fields.remove(index);
                    else fields.push(mission.id);
                  }}
                  className="InvitationsField__action"
                  variants={['small', checked ? 'secondary' : 'grey']}
                  icon={checked || invited ? CheckDouble : Plus}
                  disabled={invited}
                />
              )}
            </>
          );
        }}
        noResultsText="company.modal.invite.empty"
      />
    </div>
  );
}

InvitationsField.propTypes = {
  fields: objectOf(any).isRequired,
  id: string.isRequired,
  list_id: string.isRequired,
  inviteList: bool.isRequired,
  ability: objectOf(any).isRequired,
};

export default InvitationsField;
