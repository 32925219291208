import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import SearchStatus from '@/components/SearchStatus';

function SearchStatusSection() {
  const { t } = useTranslation();

  return (
    <SearchStatus
      name="search_status"
      label={t('company.modal.modalities.searchStatus.label')}
    />
  );
}

export default SearchStatusSection;
