import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { updateInformations } from '@/api/Company/mutations';
import CollaboratorNumberModal from './CollaboratorNumberModal';
import translate from '@/helpers/enhancers/translate';

export default compose(
  graphql(updateInformations, { name: 'update' }),
  translate()
)(CollaboratorNumberModal);
