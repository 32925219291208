import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import * as Degree from '@/api/Company/Degree/mutations';
import translate from '@/helpers/enhancers/translate';
import DegreeModal from './DegreeModal';

export default compose(
  graphql(Degree.updateDegree, { name: 'update' }),
  graphql(Degree.createDegree, { name: 'create' }),
  graphql(Degree.deleteDegree, { name: 'delete' }),
  translate()
)(DegreeModal);
