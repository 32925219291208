import { combineReducers } from 'redux';
import flash from './flash';
import form from './form';
import modal from './modal';
import relevanceScore from './relevanceScore';
import storage from './storage';
import matching from './matching';

const rootReducer = combineReducers({
  flash,
  form,
  modal,
  relevanceScore,
  storage,
  matching,
});

export default rootReducer;
