import React from 'react';
import { string, node, func } from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from '@/images/chevron-left.svg';
import './styles.scss';

const Page = ({
  name,
  children,
  className,
  title,
  leftHeader,
  rightHeader,
  backLink,
  backText,
  headerClassName,
  subtitle,
  bulkActions,
}) => (
  <div
    role="main"
    className={cx('Page', { [`Page--${name}`]: name })}
    data-testid={`page-${name}`}
  >
    {backLink &&
      backText && (
        <Link to={backLink} className="Page__back">
          <ChevronLeft />
          <span>{backText}</span>
        </Link>
      )}

    <div className={cx('Page__inner', className)}>
      {title && (
        <h1 className="Page__title">
          {title}
          {bulkActions && <div className="m-t-s"> {bulkActions()} </div>}
        </h1>
      )}

      {subtitle && <h3 className="Page__subtitle">{subtitle}</h3>}

      {(leftHeader || rightHeader) && (
        <div className={cx('Page__header', headerClassName)}>
          <div className="Page__header-left">{leftHeader}</div>
          {rightHeader && (
            <div className="Page__header-right">{rightHeader}</div>
          )}
        </div>
      )}

      {children}
    </div>
  </div>
);

Page.propTypes = {
  name: string.isRequired,
  children: node.isRequired,
  className: string,
  title: string,
  leftHeader: node,
  rightHeader: node,
  backLink: string,
  backText: string,
  headerClassName: string,
  subtitle: string,
  bulkActions: func,
};

Page.defaultProps = {
  className: null,
  title: null,
  leftHeader: null,
  rightHeader: null,
  backLink: null,
  backText: null,
  headerClassName: '',
  subtitle: null,
  bulkActions: null,
};

export default Page;
