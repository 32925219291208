import { createSelector } from 'reselect';
import storageKeys from '@/redux/modules/storage/keys';

const storageSelector = (state) => state.storage;

export const getSearchHistory = createSelector(
  storageSelector,
  (storage) => storage[storageKeys.searchHistory]
);

export const getGuestSearchCount = createSelector(
  storageSelector,
  (storage) => storage[storageKeys.guestSearchCount]
);

export const getSelectedSuggestionFilter = createSelector(
  storageSelector,
  (storage) => storage[storageKeys.selectedSuggestionFilter]
);
