import React, { useCallback, useEffect, useState } from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  node,
  objectOf,
  oneOfType,
  string,
  number,
} from 'prop-types';
import cx from 'classnames';
import { noop, get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import {
  CHECKED,
  INDETERMINATE,
  UNCHECKED,
} from '@/constants/fields/checkBox.js';
import {
  SUB_CATEGORIES,
  COMPANY_SUB_CATEGORIES,
} from '@/constants/fields/subCategory.js';
import { MISSION } from '@/constants/forms.js';
import Tooltip from '@/components/Tooltip';
import { ReactComponent as Star } from '@/images/star.svg';
import { ReactComponent as StarOutlined } from '@/images/star-outlined.svg';
import './styles.scss';

function Choices(props) {
  const {
    type,
    name,
    className,
    checkbox,
    cbProps,
    renderForValue,
    selectAll,
    value,
    defaultValue,
    onChange,
    onFocus,
    onBlur,
    canChange,
    resetValueOnClick,
    loading,
    canAddFavorite,
    favoriteLimit,
    favoriteCount,
    form,
  } = props;
  const { t } = useTranslation();
  const CheckboxComponent = checkbox;

  const subCategoryContext = [COMPANY_SUB_CATEGORIES, SUB_CATEGORIES].includes(
    name
  );

  // An array of all checkboxes values
  // const checkboxValues = cbProps.reduce((acc, cb) => [...acc, cb.value], []);
  const checkboxValues = cbProps.reduce((acc, cb) => {
    const newValue = subCategoryContext
      ? { favorite: false, id: cb.value }
      : cb.value;
    return [...acc, newValue];
  }, []);

  const MAX_ITEMS = checkboxValues.length;
  const RADIO_GROUP = 'radioGroup';

  // This object contains all values that are not part of checkboxValues
  // In some case "value" can contains values from another field with the same name
  // In a form, all the fields that share the same name, share also the same "value" attribute
  // That's why "value" can contains values that are not in this field cbProps prop
  const otherSelectedValues = (() => {
    if (selectAll) {
      return value.filter(
        (v) =>
          subCategoryContext
            ? !checkboxValues.some((cb) => cb.id === v.id) // If subCategoryContext is true, v is expected to be an object with an id property
            : !checkboxValues.some((cb) => cb.id === v) // Check if the id of v is not included in the checkboxValues array
      );
    }
    return [];
  })();

  // Selected items count for this field
  const selectedItemCount = value.length - otherSelectedValues.length;

  // get the state of the selectAll checkbox
  const getSelectAllState = useCallback(
    (count) => {
      if (count === 0) return UNCHECKED;
      if (count === MAX_ITEMS) return CHECKED;
      return INDETERMINATE;
    },
    [selectedItemCount, MAX_ITEMS]
  );

  const [selectAllValue, setSelectAllValue] = useState(() =>
    getSelectAllState(selectedItemCount)
  );

  useEffect(
    () => {
      if (selectAll) {
        setSelectAllValue(getSelectAllState(selectedItemCount));
      }
    },
    [selectAll, selectedItemCount, getSelectAllState]
  );

  const handleSelectAll = () => {
    // If at least one item is selected
    if (selectedItemCount > 0) {
      // then unselect all currently selected items
      // Which mean return only other selected values
      return onChange(otherSelectedValues);
    }
    // otherwise return all otherSelectedValues + all possibly selectable values for this field
    return onChange([...otherSelectedValues, ...checkboxValues]);
  };

  const handleChange = (inputType) => (e) => {
    const cb = e.target;
    const newValue = (() => {
      if (inputType === RADIO_GROUP) {
        // if value hasn't changed and resetValueOnClick is true then reset value
        return resetValueOnClick && value === cb.value ? '' : cb.value;
      }

      const newItem = subCategoryContext
        ? { favorite: false, id: cb.value }
        : cb.value;

      return cb.checked // If item is checked
        ? [...value, newItem] // Then add the value to selected items
        : value.filter((x) => {
            const id = subCategoryContext ? x.id : x;
            return id !== cb.value;
          }); // Else Remove the value to selected items
    })();

    return onChange(newValue);
  };

  // Add value when favorite is clicked to true
  const addValue = (id) => {
    const newItem = { favorite: true, id };
    return onChange([...value, newItem]);
  };

  // Toggle favorite to false
  const handleFavorite = (id) => {
    const updatedValues = value.map(
      (item) => (item.id === id ? { ...item, favorite: !item.favorite } : item)
    );

    return onChange(updatedValues);
  };

  const getFavoriteValueById = (id) => {
    // Find the item with the selected id
    const selectedItem = value.find((item) => item.id === id);

    // Check if the item was found and return the favorite value
    return get(selectedItem, 'favorite', false);
  };

  const isChecked = (inputType, current) => {
    if (inputType === RADIO_GROUP)
      return (
        (!value && current.value === defaultValue) || current.value === value
      );
    return subCategoryContext
      ? value.some((selected) => selected.id === current.id)
      : value.includes(current.value);
  };

  // This two callback need to be bind to this component context (redux form)
  const handleFocus = () => onFocus();
  const handleBlur = () => onBlur();

  // Return favorite tooltip message
  const getTooltipMessage = (favorite, favoriteToggle) => {
    if (!favoriteToggle)
      return `category.favorite.disabled.${
        favoriteLimit === 1 ? 'singular' : 'plural'
      }.text`;
    const keyContext = form === MISSION ? 'mission' : 'company';
    return favorite
      ? `category.favorite.${keyContext}.delete.text`
      : `category.favorite.${keyContext}.text`;
  };

  return (
    <div className={cx('Choices', className)}>
      {selectAll && (
        <div className="Choice--sticky">
          <CheckboxComponent
            id="select_all" // CheckboxComponent "id" props is required
            label={t('fields.categories.select_all')}
            onChange={handleSelectAll}
            checked={selectAllValue === CHECKED}
            indeterminate={selectAllValue === INDETERMINATE}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={loading}
          />
        </div>
      )}
      {cbProps.map((current) => {
        const checked = isChecked(type, current);
        const innerChild = checked && renderForValue[current.value];
        const isFavorite = subCategoryContext
          ? checked && getFavoriteValueById(current.value)
          : false;

        // Disable toggle if favoriteLimit is reached or item is already a favorite
        const activateFavoriteToggle =
          (canAddFavorite && favoriteCount <= favoriteLimit) || isFavorite;

        return (
          <React.Fragment key={`cb_${name}_${current.value}`}>
            {subCategoryContext ? (
              <div className="Choice--star">
                <CheckboxComponent
                  {...current}
                  name={name}
                  checked={checked}
                  onChange={canChange && handleChange(type)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={loading}
                />
                {innerChild && (
                  <div className="Choices__inner">{innerChild}</div>
                )}
                <Tooltip
                  text={t(
                    getTooltipMessage(isFavorite, activateFavoriteToggle)
                  )}
                  placement="left-start"
                >
                  <button
                    type="button"
                    onClick={() =>
                      checked
                        ? handleFavorite(current.value)
                        : addValue(current.value)
                    }
                    disabled={!activateFavoriteToggle}
                    className={`Favorite__button ${
                      !activateFavoriteToggle
                        ? 'Favorite__button--disabled'
                        : ''
                    }`}
                  >
                    {isFavorite ? <Star /> : <StarOutlined />}
                  </button>
                </Tooltip>
              </div>
            ) : (
              <>
                <CheckboxComponent
                  {...current}
                  name={name}
                  checked={checked}
                  onChange={canChange && handleChange(type)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  disabled={loading}
                  favoriteLimit={favoriteLimit}
                />
                {innerChild && (
                  <div className="Choices__inner">{innerChild}</div>
                )}
              </>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

Choices.propTypes = {
  cbProps: arrayOf(any),
  value: oneOfType([arrayOf(string), string]),
  defaultValue: string,
  name: string,
  onBlur: func,
  onFocus: func,
  onChange: func,
  checkbox: func.isRequired,
  className: string,
  type: string,
  renderForValue: objectOf(node),
  canChange: bool,
  resetValueOnClick: bool,
  selectAll: bool,
  loading: bool,
  canAddFavorite: bool,
  favoriteLimit: number,
  favoriteCount: number,
  form: string.isRequired,
};

Choices.defaultProps = {
  cbProps: [],
  value: [],
  defaultValue: '',
  name: '',
  onBlur: noop,
  onFocus: noop,
  onChange: noop,
  className: '',
  type: 'checkbox',
  renderForValue: {},
  canChange: true,
  resetValueOnClick: true,
  selectAll: false,
  loading: false,
  canAddFavorite: false,
  favoriteLimit: 3,
  favoriteCount: 0,
};
export default Choices;
