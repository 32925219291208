import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import translate from '@/helpers/enhancers/translate';
import { updateInformations } from '@/api/Company/mutations';
import ModalitiesModal from './ModalitiesModal';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';

export default compose(
  withCurrentUser,
  graphql(updateInformations, { name: 'update' }),
  connect(),
  translate()
)(ModalitiesModal);
