import { ReactComponent as WindowDev } from '@/images/window-dev.svg';
import { ReactComponent as Chart } from '@/images/p-chart.svg';
import { ReactComponent as Digital } from '@/images/digital.svg';
import { ReactComponent as NewConstruction } from '@/images/new-construction.svg';
import { ReactComponent as Robot } from '@/images/robot.svg';
import { ReactComponent as ElectronicCircuit } from '@/images/electronic-circuit.svg';
import { ReactComponent as Factory } from '@/images/factory.svg';
import { ReactComponent as Crane } from '@/images/crane.svg';
import { ReactComponent as Molecule } from '@/images/molecule.svg';
import { ReactComponent as ConfigurationTool } from '@/images/configuration-tool.svg';
import { ReactComponent as GraphicsTablet } from '@/images/graphics-tablet.svg';
import { ReactComponent as WebDesign } from '@/images/web-design.svg';
import { ReactComponent as Purchase } from '@/images/purchase.svg';
import { ReactComponent as Finance } from '@/images/finance.svg';
import { ReactComponent as Business } from '@/images/business.svg';
import { ReactComponent as Advice } from '@/images/advice.svg';
import { ReactComponent as TwoPeople } from '@/images/two-people.svg';
import { ReactComponent as ChemistryBis } from '@/images/chemistry-bis.svg';
import { ReactComponent as Image } from '@/images/image.svg';
import { ReactComponent as Meeting } from '@/images/meeting.svg';
import { ReactComponent as Intelligence } from '@/images/intelligence.svg';
import { ReactComponent as Computer } from '@/images/computer-science.svg';
import { ReactComponent as Tools } from '@/images/tools.svg';
import { ReactComponent as SensorSpeed } from '@/images/sensor-speed.svg';
import { ReactComponent as HealthcareIcon } from '@/images/healthcare.svg';
import { ReactComponent as BatteryLevel } from '@/images/battery-level.svg';
import { ReactComponent as Industry } from '@/images/industry.svg';
import { ReactComponent as Signal } from '@/images/chart.svg';
import { ReactComponent as People } from '@/images/people.svg';
import { ReactComponent as MaterialsIcon } from '@/images/materials.svg';
import { ReactComponent as BiologyIcon } from '@/images/biology.svg';
import { ReactComponent as HumanInterface } from '@/images/human-interface.svg';
import { ReactComponent as Cybersecurity } from '@/images/cybersecurity.svg';
import { ReactComponent as ITOperations } from '@/images/it-operation.svg';
import { ReactComponent as Analyst } from '@/images/analyst.svg';
import { ReactComponent as Blockchain } from '@/images/blockchain.svg';
import { ReactComponent as Cloud } from '@/images/cloud.svg';
import { ReactComponent as Ellipsis } from '@/images/ellipsis.svg';
import { ReactComponent as Calculator } from '@/images/calculator.svg';
import { ReactComponent as FolderBookmark } from '@/images/folder-bookmark.svg';
import { ReactComponent as BankStatement } from '@/images/bank-statement.svg';
import { ReactComponent as Survey } from '@/images/survey.svg';
import { ReactComponent as Scale } from '@/images/scale.svg';
import { ReactComponent as Loan } from '@/images/loan.svg';
import { ReactComponent as Payee } from '@/images/payee.svg';
import { ReactComponent as JointAccount } from '@/images/joint-account.svg';

export const iconsMap = {
  accounting: Calculator,
  administrative: FolderBookmark,
  administrative_and_financial_management: BankStatement,
  audit_and_controlling: Survey,
  business_law: Scale,
  development: WindowDev,
  data: Chart,
  it: Digital,
  infrastructure: NewConstruction,
  mechanical_physical: Robot,
  employment_law_payroll_and_human_resources: Payee,
  electronics: ElectronicCircuit,
  industry: Factory,
  civil_engineering: Crane,
  chemistry_materials: Molecule,
  general_engineering: ConfigurationTool,
  project_management: GraphicsTablet,
  design: WebDesign,
  finance: Finance,
  purchase: Purchase,
  audit_finance_insurance: Finance,
  business: Business,
  consulting: Meeting,
  dual_competency_financial_management_administration: Loan,
  marketing_communication: TwoPeople,
  r_d_chemistry: ChemistryBis,
  r_d_biological_engineering: BiologyIcon,
  r_d_electronics: ElectronicCircuit,
  r_d_digital_networks: Digital,
  r_d_image_analysis: Image,
  r_d_artificial_intelligence: Intelligence,
  r_d_molecular_and_cellular_biology: Molecule,
  r_d_software_technologies: Computer,
  r_d_mechanics: Tools,
  r_d_measurement_control_technologies: SensorSpeed,
  r_d_optics: HealthcareIcon,
  r_d_power_engineering: BatteryLevel,
  r_d_robotics: Robot,
  r_d_signal_processing: Signal,
  r_d_materials: MaterialsIcon,
  r_d_human_computer_interface: HumanInterface,
  customer_relation: Advice,
  web_development: WindowDev,
  manufacturing: Industry,
  mergers_acquisitions: JointAccount,
  project_management_agility: GraphicsTablet,
  support: People,
  cybersecurity: Cybersecurity,
  it_operations: ITOperations,
  analyst_cross_functional: Analyst,
  big_data_ai_ml: Intelligence,
  business_technology: HumanInterface,
  engineering: ConfigurationTool,
  hardware: ElectronicCircuit,
  it_support: Meeting,
  network_and_information_security: Cybersecurity,
  product_design_ui_uix: WebDesign,
  programming_and_development: WindowDev,
  quality_assurance: Finance,
  system_administration: Computer,
  software_qa: Computer,
  cloud_devops: Cloud,
  cybersecurity_networking: Cybersecurity,
  erp_crm: HumanInterface,
  it_project_management: ITOperations,
  blockchain: Blockchain,
  business_intelligence_analytics: Business,
  system_administration_it_support: Digital,
  robotics_iot: Robot,
  other: Ellipsis,
  product_design_ui_ux: WebDesign,
  business_it: Digital,
  business_marketing: TwoPeople,
};
