import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import './styles.scss';

class Choice extends React.Component {
  constructor(props) {
    super(props);
    this.previouslyChecked = !!(props.checked || props.defaultChecked);
    this.state = {
      checked: !!(props.checked || props.defaultChecked),
      hasFocus: false,
    };
  }

  static getDerivedStateFromProps(props) {
    if ('checked' in props) {
      return { checked: !!props.checked };
    }
    return null;
  }

  handleClick = (event) => {
    const checkbox = this.input;
    if (event.target !== checkbox && !this.moved) {
      this.previouslyChecked = checkbox.checked;
      event.preventDefault();
      checkbox.focus();
      checkbox.click();
      return;
    }

    const checked =
      this.props.checked != null ? this.props.checked : checkbox.checked;

    this.setState({ checked });
  };

  handleFocus = (event) => {
    this.props.onFocus(event);
    this.setState({ hasFocus: true });
  };

  handleBlur = (event) => {
    this.props.onBlur(event);
    this.setState({ hasFocus: false });
  };

  renderChildren = (children, checked, focused, disabled, readOnly, toggle) => {
    if (typeof children === 'function') {
      return children({ checked, focused, disabled, readOnly, toggle });
    }
    return children;
  };

  render() {
    const {
      className,
      style,
      children,
      defaultChecked,
      ...inputProps
    } = this.props;

    return (
      <div className={cx('Choice', className)} style={style}>
        {this.renderChildren(
          children,
          this.state.checked,
          this.state.hasFocus,
          this.props.disabled,
          this.props.readOnly,
          this.handleClick
        )}
        <input
          ref={(ref) => {
            this.input = ref;
          }}
          {...inputProps}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
      </div>
    );
  }
}

Choice.displayName = 'Choice';

Choice.propTypes = {
  checked: pp.bool,
  disabled: pp.bool,
  readOnly: pp.bool,
  children: pp.oneOfType([pp.node, pp.func]),
  defaultChecked: pp.bool,
  onChange: pp.func,
  onFocus: pp.func,
  onBlur: pp.func,
  className: pp.string,
  id: pp.string,
  name: pp.string,
  value: pp.oneOfType([pp.string, pp.bool]),
  type: pp.string,
  style: pp.objectOf(pp.string),
};

Choice.defaultProps = {
  checked: null,
  disabled: false,
  readOnly: false,
  defaultChecked: null,
  onChange: _.noop,
  onFocus: _.noop,
  onBlur: _.noop,
  className: null,
  name: null,
  id: null,
  type: 'checkbox',
  value: 'on',
  children: '',
  style: null,
};

export default Choice;
