import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { updateBoardList } from '@/api/MyMission/mutations';
import translate from '@/helpers/enhancers/translate';
import UpdateListModal from './UpdateListModal';

export default compose(
  graphql(updateBoardList),
  translate()
)(UpdateListModal);
