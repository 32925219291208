import React, { useState } from 'react';
import {
  func,
  bool,
  string,
  objectOf,
  any,
  arrayOf,
  shape,
  number,
} from 'prop-types';
import { first, get } from 'lodash';
import { propType } from 'graphql-anywhere';
import { length, acceptance, presence, date } from '@/helpers/validation';
import { isSolo } from '@/helpers/company';
import userFragment from '@/api/User/fragment';
import {
  RequiredField,
  RfArea,
  RfLabelledCheckbox,
  FileField,
  RfFiles,
  RfSelect,
  RfHelper,
  RfDate,
} from '@/containers/ReduxForm';
import config from '@/_config';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';
import AdditionalCandidate from './components/AdditionalCandidate';
import { EMPLOYEE } from '@/constants/outsourcings';
import {
  MISSIONS,
  FULLTIME_CONTRACT,
  MISSION_OR_FULLTIME,
  FIXED_TERM_CONTRACT,
} from '@/constants/contractTypes';
import CandidateCard from '@/components/CandidateCard/CandidateCard';
import InformationMessage from '@/components/InformationMessage';
import { ALL_EXTENSION } from '@/constants/file';
import HourlyRateSection from '../HourlyRateSection';
import SalarySection from '../SalarySection';
import { translateArrayLabel } from '@/helpers/i18n';
import { today, oneYearFromToday, numberDateDiff } from '@/helpers/date';
import { PRECISE } from '@/constants/availabilities';
import {
  lightAvailabilities,
  fullAvailabilities,
} from '@/constants/fields/availabilities';
import './styles.scss';

function ApplyModal({
  t,
  close,
  handleSubmit,
  submitting,
  applyToMission,
  missionId,
  missionContractTypes,
  onSend,
  currentUser,
  change,
  collaborators,
  formValues,
  jobName,
  missionDataForHourlyRate,
}) {
  const [candidates, setCandidates] = useState([]);

  const solo = isSolo(get(currentUser, 'company.kind'));

  // Display if availability_manually_updated_at > 1 month
  const displayAvailability =
    numberDateDiff(
      new Date(get(currentUser, 'company.availability_manually_updated_at')),
      new Date()
    ) > 1;

  const contractTypesApplyWithSalary = [
    MISSIONS,
    MISSION_OR_FULLTIME,
    FULLTIME_CONTRACT,
    FIXED_TERM_CONTRACT,
  ];
  const displayInfoMessage = () =>
    !!contractTypesApplyWithSalary.find((value) =>
      missionContractTypes.includes(value)
    );

  async function onSubmit({
    message,
    attachment,
    daily_rate,
    salary,
    hourly_rate,
    contract_type: contractTypeApplyChoice,
    availability,
    available_at,
  }) {
    await applyToMission({
      refetchQueries: [`getMission`],
      variables: {
        id: missionId,
        message,
        candidates: candidates.map((candidate) => ({
          daily_rate: parseInt(candidate.daily_rate, 10),
          collaborator_id: candidate.collaborator.id,
          outsourcing: candidate.outsourcing,
          attachment_ids: candidate.attachments.map(
            (attachment_candidate) => attachment_candidate.id
          ),
        })),
        attachment_id: attachment ? get(first(attachment), 'id') : null,
        daily_rate:
          contractTypeApplyChoice === MISSIONS ||
          missionContractTypes.includes(MISSIONS) ||
          config.company.contractTypeIsAlwaysMissions
            ? parseInt(daily_rate, 10)
            : null,
        salary:
          (contractTypeApplyChoice === FULLTIME_CONTRACT || // contract type selected from apply form
          missionContractTypes.includes(FULLTIME_CONTRACT) || // contract type from mission
            missionContractTypes.includes(FIXED_TERM_CONTRACT)) && // contract type from mission
          !config.company.contractTypeIsAlwaysMissions
            ? parseInt(salary, 10)
            : null,
        hourly_rate: parseInt(hourly_rate, 10),
        availability,
        available_at: availability === PRECISE ? available_at : null,
      },
    });
    close();
    onSend();
  }

  function onDeleteCandidate(index) {
    // delete candidate + reindex map
    setCandidates(
      candidates
        .filter((candidate) => candidate.index !== index)
        .map((candidate, index_candidate) => ({
          ...candidate,
          index: parseInt(index_candidate, 10) + 1,
        }))
    );
  }

  function onAddCandidate(action) {
    if (action === 'add') {
      const candidate_collaborator_id = get(
        formValues,
        'candidate_collaborator_id'
      );
      const candidate = first(
        collaborators.filter(
          (collaborator) => collaborator.id === candidate_collaborator_id
        )
      );

      setCandidates([
        ...candidates,
        {
          // index candidate
          index: candidates.length + 1,
          collaborator: {
            id: get(formValues, 'candidate_collaborator_id'),
            first_name: get(candidate, 'first_name'),
            last_name: get(candidate, 'last_name'),
            job_name: get(candidate, 'job.name'),
            avatar: get(candidate, 'avatar'),
          },
          daily_rate: get(formValues, 'candidate_daily_rate'),
          outsourcing: get(formValues, 'candidate_outsourcing'),
          attachments: get(formValues, 'candidate_attachments', []) || [],
        },
      ]);
    }
    change('candidate_attachments', []);
    change('candidate_outsourcing', EMPLOYEE);
    change('candidate_collaborator_id', '');
    change('candidate_daily_rate', null);
  }
  const getPriceSection = () => {
    if (config.company.showHourlyRate)
      return (
        <HourlyRateSection
          currentUser={currentUser}
          missionDataForHourlyRate={missionDataForHourlyRate}
        />
      );
    if (config.mission.applyWithSalary && solo) {
      return (
        <SalarySection
          solo={solo}
          missionContractTypes={missionContractTypes}
        />
      );
    }
    return null;
  };
  return (
    <Modal
      name="Apply"
      className="ApplyModal"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('mission.modal.apply.title', { jobName })}
      okText={t('mission.modal.apply.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection>
        {config.mission.applyWithSalary &&
          solo &&
          displayInfoMessage() && (
            <div className="m-b-m">
              <InformationMessage>
                <p>{t('company.modal.apply.remuneration.helper')}</p>
              </InformationMessage>
            </div>
          )}
        {!solo &&
          (missionContractTypes.includes(MISSIONS) ||
            config.company.contractTypeIsAlwaysMissions) && (
            <p className="m-b-m">
              <InformationMessage>
                {t('mission.modal.apply.candidate.label')}
              </InformationMessage>
            </p>
          )}
        <div className="p-b-s">
          <RequiredField
            name="message"
            component={RfArea}
            label={t('mission.modal.apply.message.label')}
            helper={solo && t('mission.modal.apply.message.helper')}
            validate={[length({ max: 500 })]}
            max={500}
          />
        </div>
        {config.mission.applyWithAttachment &&
          solo && (
            <div className="m-t-m">
              <FileField
                component={RfFiles}
                id="attachment"
                name="attachment"
                attachmentKind="attachment"
                text={t('mission.modal.apply.attachment.text')}
                infoText={t('mission.modal.apply.attachment.infoText')}
                rejectText={t(
                  'mission.modal.apply.attachment.infoText.rejectText'
                )}
                maxFiles={1}
                maxSize={config.global.maxSizeAttachment}
                multiple
                accept={ALL_EXTENSION}
                t={t}
              />
            </div>
          )}
        {getPriceSection()}
        {solo &&
          displayAvailability && (
            <div className="grid m-t-m">
              <div className="grid__item large--one-whole one-half">
                <RequiredField
                  component={RfSelect}
                  id="availability"
                  name="availability"
                  label={t('company.modal.modalities.availability.label')}
                  placeholder={t(
                    'company.modal.modalities.availability.placeholder'
                  )}
                  options={translateArrayLabel(
                    t,
                    config.company.contractTypeIsAlwaysMissions
                      ? lightAvailabilities
                      : fullAvailabilities
                  )}
                />
              </div>
              <RfHelper values={['availability']}>
                {({ availability }) => {
                  if (availability !== PRECISE) return null;
                  return (
                    <div className="grid__item large--one-whole one-half">
                      <RequiredField
                        component={RfDate}
                        name="available_at"
                        className="AvailableAt"
                        label={t(
                          'company.modal.modalities.availability.availableAt.label'
                        )}
                        dayPickerProps={{
                          disabledDays: {
                            before: today,
                            after: oneYearFromToday,
                          },
                        }}
                        validate={[
                          presence(),
                          date({
                            '>=': today,
                            '<=': oneYearFromToday,
                            format: date,
                          }),
                        ]}
                      />
                    </div>
                  );
                }}
              </RfHelper>
            </div>
          )}
      </FormSection>
      {!solo &&
        config.company.showCollaborators &&
        (missionContractTypes.includes(MISSIONS) ||
          config.company.contractTypeIsAlwaysMissions) && (
          <FormSection title={t('mission.modal.apply.candidate')}>
            <AdditionalCandidate
              candidates={candidates}
              collaborators={collaborators}
              onAddCandidate={onAddCandidate}
              formValues={formValues}
              t={t}
              className="m-l-s p-b-l"
            />
            <div className="grid">
              {candidates.map((candidate) => (
                <div key={candidate.index} className="grid__item m-b-m">
                  <CandidateCard
                    candidate={candidate}
                    onDelete={onDeleteCandidate}
                  />
                </div>
              ))}
            </div>
          </FormSection>
        )}
      <FormSection title={t('mission.modal.apply.confirm')}>
        <RequiredField
          name="confirm"
          type="checkbox"
          component={RfLabelledCheckbox}
          label={t('mission.modal.apply.confirm.label')}
          inputClassName="ApplyModal__confirm"
          validate={[acceptance()]}
        />
      </FormSection>
    </Modal>
  );
}

ApplyModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  applyToMission: func.isRequired,
  missionId: string.isRequired,
  missionContractTypes: arrayOf(string).isRequired,
  jobName: string.isRequired,
  onSend: func.isRequired,
  currentUser: propType(userFragment).isRequired,
  change: func.isRequired,
  collaborators: objectOf(any).isRequired,
  formValues: objectOf(any).isRequired,
  missionDataForHourlyRate: shape({
    workDays: number,
    workHours: number,
    languages: arrayOf(string),
    billing: string,
    beginAt: string,
    endAt: string,
    endMonths: string,
  }).isRequired,
};

export default ApplyModal;
