import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import {
  FieldArray,
  RequiredField,
  RfArea,
  RfDate,
  RfHelper,
  RfText,
} from '@/containers/ReduxForm';
import MilestoneDeliverables from '@/components/Contract/forms/MilestoneDeliverables';
import { today } from '@/helpers/date';
import { date } from '@/helpers/validation';
import { Trans } from 'react-i18next';
import { string } from 'prop-types';
import { CREATE } from '@/components/Contract/constants/modalMaps';
import { clientKeysVariables } from '@/helpers/contextKeys';
import { isDate } from 'lodash';

function MilestoneForfeitForm({ context }) {
  const { t } = useTranslation();
  const footerKey = `milestone.forfeit.footer.${context}`;

  return (
    <>
      <p className="fw-bold m-b-m">{t('milestone.forfeit.subtitle.create')}</p>
      <RequiredField
        component={RfText}
        id="title"
        name="title"
        label={t('milestone.forfeit.name')}
        className="m-b-m"
      />
      <div className="grid">
        <div className="grid__item mobileL--one-whole one-half">
          <RequiredField
            component={RfDate}
            name="begin_date"
            label={t('milestone.forfeit.beginDate.label')}
            placeholder={t('milestone.forfeit.date.placeholder')}
            className="m-b-m"
            autoComplete="off"
            validate={[
              date({
                format: date,
              }),
            ]}
          />
        </div>
        <div className="grid__item mobileL--one-whole one-half">
          <RfHelper values={['begin_date']}>
            {({ begin_date }) => (
              <>
                <RequiredField
                  component={RfDate}
                  name="end_date"
                  label={t('milestone.forfeit.endDate.label')}
                  placeholder={t('milestone.forfeit.date.placeholder')}
                  autoComplete="off"
                  dayPickerProps={{
                    disabledDays: {
                      before: isDate(begin_date) && begin_date,
                    },
                  }}
                  validate={[
                    date({
                      '>=': begin_date || today,
                      format: date,
                    }),
                  ]}
                />
              </>
            )}
          </RfHelper>
        </div>
      </div>
      <p className="fw-bold">{t('milestone.forfeit.description.label')}</p>
      <RequiredField
        id="description"
        name="description"
        component={RfArea}
        placeholder={t('milestone.forfeit.description.placeholder')}
      />
      <p className="fw-bold">{t('milestone.forfeit.pricing.title')}</p>
      <RequiredField
        component={RfText}
        id="price"
        name="price"
        type="number"
        min={0}
        label={t('milestone.forfeit.amount', {
          currency: t('currency.symbol'),
        })}
        className="m-b-m"
      />
      <p className="fw-bold">{t('milestone.forfeit.deliverables.title')}</p>
      <FieldArray
        id="deliverables_attributes"
        name="deliverables_attributes"
        label={t('milestone.forfeit.deliverables.name')}
        className="m-b-m"
        component={MilestoneDeliverables}
      />
      <p>
        <Trans
          i18nKey={footerKey}
          values={{
            message: t(footerKey),
            ...clientKeysVariables(t),
          }}
          components={[<span className="fw-bold">message</span>]}
        />
      </p>
    </>
  );
}

MilestoneForfeitForm.propTypes = {
  context: string,
};

MilestoneForfeitForm.defaultProps = {
  context: CREATE,
};

export default MilestoneForfeitForm;
