import React, { useContext } from 'react';
import { get } from 'lodash';
import { getConversationsRoute } from '@/helpers/router';
import { ReactComponent as Email } from '@/images/email.svg';
import Badge from '@/components/Badge';
import Tooltip from '@/components/Tooltip';
import HeaderLink from './Link';
import CountersContext from '@/contexts/CountersContext';

function Conversations() {
  const countersContext = useContext(CountersContext);
  return (
    <HeaderLink to={getConversationsRoute()} exact={false}>
      <Tooltip title="header.nav.conversations.tooltip.title">
        <Badge count={get(countersContext, 'unread_channels_count', 0)}>
          <Email />
        </Badge>
      </Tooltip>
    </HeaderLink>
  );
}

export default Conversations;
