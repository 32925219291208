import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

function List({ t, className, items }) {
  return (
    <ul className={cx('Footer__list', className)}>
      {items.map((item) => (
        <li
          className={cx('Footer__list-item', `Footer__list-item--${item.id}`)}
          key={item.id}
        >
          <a href={item.href} target={item.target}>
            {item.icon && <item.icon />}
            {item.label && <span>{t(item.label)}</span>}
          </a>
        </li>
      ))}
    </ul>
  );
}

List.propTypes = {
  t: pp.func.isRequired,
  className: pp.string,
  items: pp.arrayOf(
    pp.shape({
      id: pp.string.isRequired,
      label: pp.string,
      icon: pp.func,
      href: pp.string.isRequired,
      target: pp.string,
    })
  ),
};

List.defaultProps = {
  className: null,
  items: [],
};

export default List;
