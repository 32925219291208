import TagListModal from '@/scenes/Profile/components/modals/TagListModal';
import expertises from '@/constants/fields/expertises';
import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { sortTranslatedLabel as sort } from '@/helpers/sortTranslatedString';

export default (props) => {
  const { t } = useTranslation();

  return (
    <TagListModal
      formName="company_expertises"
      modalTitle="company.modal.expertises.title"
      sectionTitle="company.modal.expertises.section.title"
      tags={sort(expertises, t)}
      fieldProps={{
        name: 'expertises',
        label: 'company.modal.expertises.label',
      }}
      required
      {...props}
    />
  );
};
