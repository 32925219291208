import { get, isEmpty } from 'lodash';
import client from '@/helpers/apollo';
import { fileToAttachment } from '@/helpers/file';
import { uploadAttachment } from '@/api/Attachment/mutations';
import {
  createCollaborator,
  updateCollaborator,
} from '@/api/Company/Collaborator/mutations';
import { CREATE } from '@/constants/modes';

export default {
  Mutation: {
    createOrUpdateCollaborator: async (__, { action, ...values }) => {
      const { avatar, ...restValues } = values;
      const mutationToCall =
        action === CREATE ? createCollaborator : updateCollaborator;
      const avatarUpdated = isEmpty(get(avatar, 'updated'));
      if (!avatarUpdated) {
        const attachment = fileToAttachment(get(avatar, 'updated'));
        const {
          data: { uploadAttachment: uploadRes },
        } = await client.mutate({
          mutation: uploadAttachment,
          variables: {
            attachment,
            kind: 'avatar',
          },
        });
        return client.mutate({
          mutation: mutationToCall,
          variables: {
            ...restValues,
            avatar_id: get(uploadRes, 'id'),
          },
        });
      }
      return client.mutate({
        mutation: mutationToCall,
        variables: {
          ...restValues,
          avatar_id: get(avatar, 'original.id'),
        },
      });
    },
  },
};
