import { includes } from 'lodash';

/**
 * HandleFacetFilterItems
 * Return formatted items for FACET type filter
 * @param items
 * @param filterName
 * @param selectedFilters
 * @returns {*}
 */
export function handler(items, filterName, selectedFilters) {
  return items.filter((i) => i.docCount > 0).map((item) => ({
    key: item.key,
    label: `fields.${filterName}.${item.key}`,
    value: [item.key],
    count: item.docCount,
    selected: includes(selectedFilters[filterName], item.key),
  }));
}
