import React from 'react';
import _ from 'lodash';
import pp from 'prop-types';
import { Trans } from 'react-i18next';
import translate from '@/helpers/enhancers/translate';
import {
  clientKeysVariables,
  expertKeysVariables,
  missionKeysVariables,
} from '@/helpers/contextKeys';

const withLabels = (Slider) => {
  class SliderWithLabels extends React.Component {
    static propTypes = {
      value: pp.arrayOf(pp.number),
      domain: pp.arrayOf(pp.number),
      valueKey: pp.oneOfType([pp.string, pp.func]).isRequired,
    };

    static defaultProps = {
      value: [50],
      domain: [1, 100],
    };

    state = {
      lower: _.first(this.props.value),
      upper: this.props.value.length > 1 ? _.last(this.props.value) : null,
    };

    handleUpdate = (value) => {
      this.setState({
        lower: _.first(value),
        upper: value.length > 1 ? _.last(value) : null,
      });
    };

    render() {
      const { upper, lower } = this.state;
      const { t, valueKey, ...restProps } = this.props;
      return (
        <>
          <Slider
            {...restProps}
            onUpdate={this.handleUpdate}
            value={upper !== null ? [lower, upper] : [lower]}
          />
          <p className="Slider__value">
            <Trans
              t={t}
              i18nKey={
                _.isFunction(valueKey) ? valueKey([lower, upper]) : valueKey
              }
              values={{
                count: lower,
                min: lower,
                max: upper,
                currency: t('currency.symbol'),
                ...clientKeysVariables(t),
                ...expertKeysVariables(t),
                ...missionKeysVariables(t),
              }}
              components={[<span>amount</span>]}
            />
          </p>
        </>
      );
    }
  }

  return translate()(SliderWithLabels);
};

export default withLabels;
