import React from 'react';
import { oneOf, bool, func, string, oneOfType, number } from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import uuid from 'uuid/v4';
import { Manager, Reference, Popper } from 'react-popper';
import { KEYCODES } from '@/constants/keyCodes';
import Menu from './Menu';
import './styles.scss';

class Popover extends React.Component {
  state = {
    current: null,
  };

  // Hack: generate a unique className for each popover allows
  // us to not worry about outside/reference clicks
  uniqueClassname = `Popover--${uuid().slice(0, 6)}`;

  popperScheduleUpdate = _.noop;

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isOpen !== this.props.isOpen ||
      prevState.current !== this.state.current
    ) {
      this.popperScheduleUpdate();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  handleOpen = () => {
    this.props.onOpen();
    this.props.open();
  };

  handleClose = () => {
    this.setState({ current: null });
    this.props.onClose();
    this.props.close();
  };

  handleKeydown = (e) => {
    const { isOpen } = this.props;
    if (e.keyCode === KEYCODES.escape && isOpen) {
      this.handleClose();
    }
  };

  render() {
    const {
      mode,
      isOpen,
      className,
      withArrow,
      renderReference,
      renderPopper,
      spacing,
      innerSpacing,
      maxHeight,
      overflowY,
      ...otherProps
    } = this.props;

    const childProps = {
      isOpen,
      close: this.handleClose,
      open: this.handleOpen,
      current: this.state.current,
    };

    return (
      <div
        className={cx(
          'Popover',
          { 'Popover--open': isOpen },
          { 'Popover--withArrow': withArrow },
          this.uniqueClassname,
          className
        )}
        {...mode === 'hover' && {
          onMouseEnter: this.handleOpen,
          onMouseLeave: this.handleClose,
        }}
      >
        <Manager>
          <Reference>
            {({ ref }) =>
              renderReference({
                ...childProps,
                ref,
                getButtonProps: ({
                  current,
                  className: buttonClassName,
                  ...buttonProps
                }) => ({
                  ...buttonProps,
                  className: cx('Popover__reference', buttonClassName),
                  onClick: (e) => {
                    if (mode === 'hover') return null;

                    if (e) {
                      e.stopPropagation();
                      e.preventDefault();
                    }

                    if (current) {
                      this.setState({ current });

                      // Handle current switch
                      if (
                        this.state.current &&
                        current !== this.state.current
                      ) {
                        return null;
                      }
                    }

                    return isOpen ? this.handleClose(e) : this.handleOpen(e);
                  },
                }),
              })
            }
          </Reference>
          {isOpen && (
            <Popper {...otherProps}>
              {({ ref, style, placement, arrowProps, scheduleUpdate }) => {
                this.popperScheduleUpdate = scheduleUpdate;
                return (
                  <Menu
                    outsideClickIgnoreClass={this.uniqueClassname}
                    innerRef={ref}
                    style={style}
                    spacing={spacing}
                    innerSpacing={innerSpacing}
                    placement={placement}
                    maxHeight={maxHeight}
                    overflowY={overflowY}
                    close={this.handleClose}
                  >
                    {renderPopper({ ...childProps, placement })}
                    {withArrow && (
                      <div
                        className="Popover__arrow"
                        data-placement={placement}
                        style={arrowProps.style}
                        ref={arrowProps.ref}
                      />
                    )}
                  </Menu>
                );
              }}
            </Popper>
          )}
        </Manager>
      </div>
    );
  }
}

Popover.propTypes = {
  mode: oneOf(['click', 'hover']),
  isOpen: bool.isRequired,
  open: func.isRequired,
  close: func.isRequired,
  className: string,
  renderReference: func.isRequired,
  renderPopper: func.isRequired,
  withArrow: bool,
  onOpen: func,
  onClose: func,
  spacing: oneOfType([number, string]),
  innerSpacing: oneOfType([number, string]),
  maxHeight: oneOfType([number, string]),
  overflowY: string,
};

Popover.defaultProps = {
  mode: 'click',
  className: null,
  withArrow: false,
  onOpen: _.noop,
  onClose: _.noop,
  spacing: 12,
  innerSpacing: '6px 0',
  maxHeight: 'inherit',
  overflowY: 'inherit',
};

export default Popover;
