import { compose } from 'recompose';
import { connect } from 'react-redux';
import { closeModal } from '@/redux/modules/modal';
import InformationModal from './InformationModal';

export default compose(
  connect(
    null,
    { closeModal }
  )
)(InformationModal);
