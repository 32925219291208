export const TRACK_PAGE = 'tracking/TRACK_PAGE';
export const TRACK_EVENT = 'tracking/TRACK_EVENT';

export const trackPage = (pageName) => ({
  type: TRACK_PAGE,
  pageName,
});

export const trackEvent = (eventName, properties) => ({
  type: TRACK_EVENT,
  eventName,
  properties,
});
