export const rewardsListOptions = (t) => [
  {
    label: t('resource.rewards.happy_at_work.label'),
    id: 'happy_at_work',
    value: 'happy_at_work',
  },
  {
    label: t('resource.rewards.b_corp.label'),
    id: 'b_corp',
    value: 'b_corp',
  },
  {
    label: t('resource.rewards.great_place_to_work.label'),
    id: 'great_place_to_work',
    value: 'great_place_to_work',
  },
  {
    label: t('resource.rewards.top_employer.label'),
    id: 'top_employer',
    value: 'top_employer',
  },
  {
    label: t('resource.rewards.lucie_26000.label'),
    id: 'lucie_26000',
    value: 'lucie_26000',
  },
  {
    label: t('resource.rewards.figure_charts.label'),
    id: 'figure_charts',
    value: 'figure_charts',
  },
  {
    label: t('resource.rewards.marcom.label'),
    id: 'marcom',
    value: 'marcom',
  },
  {
    label: t('resource.rewards.rse_certification.label'),
    id: 'rse_certification',
    value: 'rse_certification',
  },
  {
    label: t('resource.rewards.linkedin_top_companies.label'),
    id: 'linkedin_top_companies',
    value: 'linkedin_top_companies',
  },
];
