import React from 'react';
import { get, isNull } from 'lodash';
import { propType } from 'graphql-anywhere';
import { func, objectOf, string, any } from 'prop-types';
import collaboratorFragment from '@/api/Company/Collaborator/fragment';
import useTranslation from '@/hooks/useTranslation';
import Img from '@/components/Img';
import { ReactComponent as Eye } from '@/images/preview.svg';
import { ReactComponent as BankPig } from '@/images/bank-pig.svg';
import { ReactComponent as UserCard } from '@/images/user-card.svg';
import { ReactComponent as Delete } from '@/images/trash.svg';
import { ReactComponent as Attachment } from '@/images/attachment.svg';
import Tooltip from '@/components/Tooltip';
import AttachmentDropdown from '@/components/AttachmentDropdown';
import Modality from '@/components/Modalities/Modality';
import './styles.scss';

const CandidateCard = ({
  onDelete,
  candidate,
  className,
  openModal,
  action,
}) => {
  const { t } = useTranslation();
  const index = get(candidate, 'index');

  const firstName = get(candidate, 'collaborator.first_name', '');
  const lastName = get(candidate, 'collaborator.last_name', '');
  const name = `${firstName}${!isNull(lastName) ? ` ${lastName}` : ''}`;

  const avatarUrl = get(candidate, 'collaborator.avatar.read_url');
  const jobName = get(candidate, 'collaborator.job_name', '');
  const dailyRate = get(candidate, 'daily_rate', null);
  const outsourcing = get(candidate, 'outsourcing', null);
  const attachments = get(candidate, 'attachments', []);

  return (
    <div className={`CandidateCard ${className}`}>
      <div className="CandidateCard__heading">
        <Modality className="StatusPill">
          {t('profil.expert.candidacy.candidate')}
          {index}
        </Modality>
        <div className="CandidateCard__heading-top">
          <Img
            src={avatarUrl}
            alt={name}
            className="CandidateCard__avatar m-b-xs"
          />
          {action.delete && (
            <button type="button" onClick={() => onDelete(index)}>
              <Tooltip title="company.action.tooltip.delete.title">
                <Delete />
              </Tooltip>
            </button>
          )}
          {action.view && (
            <button type="button" onClick={() => openModal()}>
              <Tooltip title="company.action.tooltip.see_candidate.title">
                <Eye />
              </Tooltip>
            </button>
          )}
        </div>
        {name && <h3 className="m-b-xs">{name}</h3>}
        {jobName && <h3 className="m-b-xs primary">{jobName}</h3>}
      </div>
      <div className="CandidateCard__info">
        {dailyRate && (
          <div className="CandidateCard__text CandidateCard__text--picto m-b-xs">
            <Tooltip title="company.collaborator.candidate.daily_rate.tooltip.title">
              <BankPig />
            </Tooltip>
            <span className="p-l-s grey">
              {t('price.currency.symbol', {
                price: dailyRate,
                currency: t('currency.symbol'),
              })}
            </span>
          </div>
        )}
        <div className="CandidateCard__text CandidateCard__text--picto m-b-xs">
          <Tooltip title="company.collaborator.candidate.outsourcing.tooltip.title">
            <UserCard />
          </Tooltip>
          <span className="p-l-s grey">
            {t(`fields.outsourcingtypes.${outsourcing}`)}
          </span>
        </div>
        {attachments.length > 0 && (
          <div className="CandidateCard__text CandidateCard__text--picto m-b-xs">
            <AttachmentDropdown
              textButton={
                <div className="Button--link m-b-xs">
                  <Attachment />
                  <span className="p-l-s">
                    {t(
                      `company.modal.collaborator.candidate.attachments.button.label`,
                      { count: attachments.length }
                    )}
                  </span>
                </div>
              }
              attachments={attachments}
            />
          </div>
        )}
      </div>
    </div>
  );
};

CandidateCard.fragment = collaboratorFragment;

CandidateCard.propTypes = {
  onDelete: func,
  candidate: propType(CandidateCard.fragment).isRequired,
  className: string,
  openModal: func,
  action: objectOf(any),
};

CandidateCard.defaultProps = {
  className: '',
  onDelete: () => null,
  openModal: () => null,
  action: { view: false, delete: true },
};

export default CandidateCard;
