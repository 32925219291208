import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import createBlackList from '@/api/BlackList/mutations.gql';
import reduxForm from '@/helpers/enhancers/reduxForm';
import BlacklistModal from './BlacklistModal';
import { connect } from 'react-redux';
import { showFlash } from '@/redux/modules/flash';
import { BLACKLIST } from '@/constants/forms';

export default compose(
  graphql(createBlackList, { name: 'createBlackList' }),
  reduxForm({
    form: BLACKLIST,
  }),
  connect(
    null,
    { showFlash }
  )
)(BlacklistModal);
