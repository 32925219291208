import React, { useState, useEffect } from 'react';
import { objectOf, any, string, func, bool } from 'prop-types';
import { get, isEmpty, noop } from 'lodash';
import cx from 'classnames';
import Downshift from 'downshift';
import axios from 'axios';
import { Manager, Reference, Popper } from 'react-popper';
import TextInput from '@/components/Fields/TextField/TextInput';
import LoadingCircle from '@/components/LoadingCircle';
import useTranslation from '@/hooks/useTranslation';
import { VALUES_TO_EXCLUDE, NO_VALUE_CHARACTER } from '@/constants/apiGouv';
import './styles.scss';

const SirenInput = ({
  value,
  inputProps,
  handleChange,
  onChange,
  data,
  setDisableValidation,
  disableValidation,
  loadingSubmit,
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentInputValue, setCurrentInputValue] = useState(value);

  const token = get(
    data,
    'current_user.company.legal_information.sirene_api_token'
  );

  const stringContainsOnlyNumbers = (str) => {
    const regex = /^[0-9]+$/;
    return regex.test(str);
  };

  const fetchData = async (searchTerm) => {
    const sirenSearch = stringContainsOnlyNumbers(searchTerm);

    // If searchTerm contains only numbers the query targets siret
    // otherwise socialReason
    const urlSuffix = sirenSearch
      ? `siren:${searchTerm}*`
      : `raisonSociale:${searchTerm}`;
    try {
      setIsLoading(true);
      const apiUrl = `${
        process.env.REACT_APP_API_GOUV_SIRENE_INDEX_SIRET_URL
      }?q=${urlSuffix}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      setOptions(response.data.etablissements);
      setIsLoading(false);
    } catch (error) {
      setOptions([]);
      setIsLoading(false);
    }
  };

  const cleanValue = (item, key, replaceBy = '', className = '') =>
    VALUES_TO_EXCLUDE.includes(get(item, key)) ? (
      replaceBy
    ) : (
      <span className={className}> {get(item, key)} </span>
    );

  function renderSocialReason(item) {
    if (get(item, 'uniteLegale.denominationUniteLegale')) {
      return (
        <div className="fw-bold m-r-s m-b-xs">
          {VALUES_TO_EXCLUDE.includes(
            get(item, 'uniteLegale.denominationUniteLegale')
          )
            ? NO_VALUE_CHARACTER
            : get(item, 'uniteLegale.denominationUniteLegale')}
        </div>
      );
    }
    return (
      <div className="d-f ai-c m-r-s m-b-xs">
        <span className="fw-bold m-r-xs">
          {cleanValue(item, 'uniteLegale.nomUniteLegale', NO_VALUE_CHARACTER)}
        </span>
        <span className="fw-bold">
          {cleanValue(
            item,
            'uniteLegale.prenomUsuelUniteLegale',
            NO_VALUE_CHARACTER
          )}
        </span>
      </div>
    );
  }

  function renderResults(items, itemProps) {
    if (isEmpty(items)) {
      return <li>{t('siren_input.no_result')}</li>;
    }

    return (
      <>
        {items.filter((item) => item.etablissementSiege).map((item, index) => (
          <li
            {...itemProps({
              key: item.id,
              index,
              item,
            })}
          >
            {renderSocialReason(item)}
            <div className="m-b-xs">{item.siret}</div>
            <div>
              {cleanValue(
                item,
                'adresseEtablissement.codePostalEtablissement',
                '',
                'fs-xsmall m-r-xs'
              )}
              {cleanValue(
                item,
                'adresseEtablissement.libelleCommuneEtablissement',
                '',
                'fw-bold fs-xsmall'
              )}
            </div>
          </li>
        ))}
      </>
    );
  }

  const changeFormValue = () => {
    onChange(currentInputValue);
  };

  useEffect(
    () => {
      if (currentInputValue !== '') {
        changeFormValue();
      }
    },
    // eslint-disable-next-line
    [currentInputValue]
  );

  useEffect(
    () => {
      // Fetch options when at least three characters are typed, with valid token
      if (token && currentInputValue.length > 2) {
        fetchData(currentInputValue);
      }
    },
    [currentInputValue, token]
  );

  return (
    <div className="SirenInput">
      <Downshift
        onChange={(selectedItem) => {
          // active validate button if item is selected
          if (setDisableValidation) setDisableValidation(false);
          setCurrentInputValue(get(selectedItem, 'siren'));
        }}
        inputValue={currentInputValue}
        itemToString={(item) => (item ? get(item, 'siren') : '')}
      >
        {({ getInputProps, getItemProps, isOpen }) => (
          <div>
            <TextInput
              {...inputProps}
              {...getInputProps({
                onChange: (e) => {
                  // disable validate button until an other selection of item
                  if (setDisableValidation) setDisableValidation(true);
                  // handleChange modify state in parent Component where the RfSiren is used
                  handleChange();
                  setCurrentInputValue(e.target.value);
                },
              })}
            />
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div ref={ref}>
                    {(disableValidation || isOpen) &&
                      // on display drownshift if form is submiting
                      !loadingSubmit &&
                      currentInputValue.length > 2 && (
                        <Popper placement="bottom">
                          {({ ref: popperRef, placement }) => (
                            <ul
                              ref={popperRef}
                              data-placement={placement}
                              className={cx('SirenInput__menu', {
                                'SirenInput__menu--bottom':
                                  placement === 'bottom',
                                'SirenInput__menu--top':
                                  placement === 'top' && !isEmpty(options),
                                'SirenInput__menu--top--noResult':
                                  placement === 'top' && isEmpty(options),
                              })}
                            >
                              {isLoading ? (
                                <li className="SirenInput__menu__placeholder">
                                  <LoadingCircle />
                                </li>
                              ) : (
                                <>{renderResults(options, getItemProps)}</>
                              )}
                            </ul>
                          )}
                        </Popper>
                      )}
                  </div>
                )}
              </Reference>
            </Manager>
          </div>
        )}
      </Downshift>
    </div>
  );
};

SirenInput.propTypes = {
  value: string,
  inputProps: objectOf(any),
  handleChange: func,
  onChange: func.isRequired,
  disableValidation: bool,
  setDisableValidation: func,
  loadingSubmit: bool,
};

SirenInput.defaultProps = {
  value: '',
  inputProps: {},
  handleChange: noop,
  disableValidation: undefined,
  setDisableValidation: noop,
  loadingSubmit: false,
};

export default SirenInput;
