import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import { updateSubCategories } from '@/api/Company/mutations';
import CategoriesModal from './CategoriesModal';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { PRODUCT } from '@/constants/contractTypes';

export default compose(
  connect((state, props) => {
    const contractTypes = get(props, 'contractTypes', []) || [];
    return {
      required: contractTypes.includes(PRODUCT) && contractTypes.length === 1,
    };
  }),
  graphql(updateSubCategories, { name: 'update' }),
  translate()
)(CategoriesModal);
