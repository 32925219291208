import React from 'react';
import { LANDING } from '@/constants/router';
import useTranslation from '@/hooks/useTranslation';
import { string } from 'prop-types';

function LinkToWebsite({ className }) {
  const { t } = useTranslation();

  return (
    <a href={LANDING} className={`primary ${className} p-l-s`}>
      {t('header.nav.engineer')}
    </a>
  );
}

LinkToWebsite.propTypes = {
  className: string,
};

LinkToWebsite.defaultProps = {
  className: null,
};

export default LinkToWebsite;
