import reduxForm from '@/helpers/enhancers/reduxForm';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { createDocumentFile } from '@/api/Document/mutations';
import AddFileModal from './AddFileModal';

export default compose(
  graphql(createDocumentFile, { name: 'createDocumentFile' }),
  reduxForm({
    form: 'add_file',
    initialValues: {},
  })
)(AddFileModal);
