import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import { length } from '@/helpers/validation';
import { RequiredField, FieldArray, RfText } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import CompaniesField from './CompaniesField';

function BookmarkEditModal({
  t,
  close,
  handleSubmit,
  submitting,
  dispatch,
  updateBookmark,
  id,
  refetchQueries,
}) {
  async function onSubmit({ name, favorites_entities }) {
    await updateBookmark({
      variables: {
        id,
        name,
        favorites_entities_attributes: _.map(favorites_entities, (e) => ({
          ..._.omit(e, ['entity', '__typename']),
          entity_type: e.entity.__typename, // eslint-disable-line
          entity_id: e.entity.id,
        })),
      },
      refetchQueries,
      awaitRefetchQueries: true,
    });
    close();
  }

  return (
    <Modal
      name="BookmarkEdit"
      size="medium"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('bookmarks.modal.edit.title')}
      disableActions={submitting}
      closable={!submitting}
    >
      <RequiredField
        component={RfText}
        name="name"
        label={t('bookmarks.modal.edit.label')}
        validate={[length({ max: 100 })]}
        className="m-b-xl"
      />
      <FieldArray
        name="favorites_entities"
        component={CompaniesField}
        dispatch={dispatch}
        rerenderOnEveryChange
      />
    </Modal>
  );
}

BookmarkEditModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  dispatch: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  updateBookmark: pp.func.isRequired,
  id: pp.string.isRequired,
  refetchQueries: pp.arrayOf(pp.string).isRequired,
};

export default BookmarkEditModal;
