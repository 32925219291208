import React, { useEffect } from 'react';
import { string, bool, arrayOf, func, shape, number } from 'prop-types';
import { filter, isNull } from 'lodash';
import { Field, RfChoices } from '@/containers/ReduxForm';
import BaseChoice from '@/components/Choice/Base';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import Popover from '@/components/Popover';
import Drawer from '@/components/Drawer';
import Button from '@/components/Button';
import Badge from '@/components/Badge';
import { sortTranslatedCategories as sort } from '@/helpers/sortTranslatedString';
import config from '@/_config';

function CategoryChoice({
  t,
  mobile,
  fieldName,
  selectedCategories,
  selectedSubcategories,
  // Checkbox props
  value,
  label,
  subCategories,
  checked,
  onFocus,
  onBlur,
  onChange,
  categoryName,
  favoriteLimit,
  ...props
}) {
  const isSelected = selectedCategories.includes(value);
  const selectedSub = filter(subCategories, (subCat) =>
    selectedSubcategories.some((selected) => selected.id === subCat.id)
  );
  const favoriteCount = selectedSubcategories.filter(
    (subCat) => subCat.favorite
  ).length;

  useEffect(
    () => {
      if (isSelected && selectedSub.length) return;
      if (!isSelected && !selectedSub.length) return;

      const event = { target: { value, checked: !!selectedSub.length } };
      onChange(event);
    },
    [selectedSub.length] // eslint-disable-line
  );

  function renderReference(referenceProps) {
    return (
      <div className="CategoryChoice">
        <Badge count={selectedSub.length} />
        <BaseChoice
          {...props}
          {...referenceProps}
          value={value}
          label={label}
          checked={checked}
        />
      </div>
    );
  }

  const canAddFavorite = () => {
    const { maxCategoriesWithFavorite } = config.validation.general;
    if (
      isNull(maxCategoriesWithFavorite) ||
      selectedCategories.length < maxCategoriesWithFavorite
    ) {
      return favoriteCount < favoriteLimit;
    }
    if (selectedCategories.length === maxCategoriesWithFavorite) {
      return isSelected && favoriteCount < favoriteLimit;
    }

    return false;
  };

  function renderPopper() {
    return (
      <Field
        id={fieldName}
        name={fieldName}
        component={RfChoices}
        checkbox={LabelledCheckbox}
        cbProps={sort(
          `fields.categories.${categoryName}`,
          subCategories,
          t
        ).map((p) => ({
          ...p,
          value: p.id,
        }))}
        selectAll
        canAddFavorite={canAddFavorite()}
        favoriteCount={favoriteCount}
        favoriteLimit={favoriteLimit}
      />
    );
  }

  return (
    <div className="grid__item mobile--one-half tablet--one-third laptop--one-quarter large--one-third one-third">
      {mobile ? (
        <Drawer
          renderReference={({ open }) => renderReference({ onClick: open })}
        >
          <Drawer.Screen
            name="default"
            header={({ className }) => <h1 className={className}>{label}</h1>}
            footer={({ close }) => (
              <Button onClick={close}>{t('filters.apply')}</Button>
            )}
          >
            {renderPopper()}
          </Drawer.Screen>
        </Drawer>
      ) : (
        <Popover
          placement="right-start"
          spacing={0}
          innerSpacing="0px 24px 12px 24px"
          maxHeight="400px"
          overflowY="scroll"
          renderReference={({ ref, getButtonProps }) =>
            renderReference(
              getButtonProps({
                innerRef: ref,
              })
            )
          }
          renderPopper={renderPopper}
        />
      )}
    </div>
  );
}

CategoryChoice.propTypes = {
  t: func.isRequired,
  mobile: bool.isRequired,
  selectedCategories: arrayOf(string).isRequired,
  selectedSubcategories: arrayOf(string).isRequired,
  value: string.isRequired,
  label: string.isRequired,
  categoryName: string.isRequired,
  checked: bool.isRequired,
  onFocus: func.isRequired,
  onBlur: func.isRequired,
  onChange: func.isRequired,
  subCategories: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  fieldName: string.isRequired,
  favoriteLimit: number,
};

CategoryChoice.defaultProps = {
  favoriteLimit: 3,
};

export default CategoryChoice;
