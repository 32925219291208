import React from 'react';
import useNativeSearch from './useNativeSearch';
import MenuItem from './MenuItem';

function Menu(props) {
  useNativeSearch(props);

  const {
    highlightedIndex,
    selectedItem,
    selectedItems,
    getItemProps,
    options,
    itemToString,
    multi,
    fieldName,
    withIcon,
  } = props;

  const isSelected = (item) =>
    multi
      ? !!selectedItems.find((i) => itemToString(i) === itemToString(item))
      : itemToString(selectedItem) === itemToString(item);

  return options.map((item, i) => (
    <MenuItem
      {...getItemProps({
        item,
        key: `key_${itemToString(item)}`,
        selected: isSelected(item),
        highlighted: i === highlightedIndex,
        label: itemToString(item),
        fieldName,
        withIcon,
        multi,
      })}
    />
  ));
}

export default Menu;
