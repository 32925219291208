import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import * as Certification from '@/api/Company/Certification/mutations';
import translate from '@/helpers/enhancers/translate';
import CertificationModal from './CertificationModal';

export default compose(
  graphql(Certification.createCertification, { name: 'create' }),
  graphql(Certification.updateCertification, { name: 'update' }),
  graphql(Certification.deleteCertification, { name: 'delete' }),
  translate()
)(CertificationModal);
