import React from 'react';
import pp from 'prop-types';
import { length } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfArea } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';

function RecommendModal({
  close,
  handleSubmit,
  submitting,
  createRecommendation,
  id,
}) {
  const { t } = useTranslation();

  async function onSubmit({ comment }) {
    await createRecommendation({
      variables: { id, comment },
      refetchQueries: ['getCompany'],
      awaitRefetchQueries: true,
    });
    close();
  }

  return (
    <Modal
      name="CompanyRecommend"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('company.modal.recommend.title')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection>
        <RequiredField
          component={RfArea}
          name="comment"
          label={t('company.modal.recommend.label')}
          validate={[length({ max: 500 })]}
          max={500}
        />
      </FormSection>
    </Modal>
  );
}

RecommendModal.propTypes = {
  close: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  createRecommendation: pp.func.isRequired,
  id: pp.string.isRequired,
};

export default RecommendModal;
