import React, { useState } from 'react';
import { any, func, objectOf, string } from 'prop-types';
import Modal from '@/components/Modal';
import { RequiredField, RfPhone } from '@/containers/ReduxForm';
import { phone as phoneValidator } from '@/helpers/validation';
import Button from '@/components/Button';
import client from '@/helpers/apollo';
import { get, pick, isEmpty } from 'lodash';
import { ReactComponent as Updated } from '@/images/updated.svg';
import './styles.scss';
import useTranslation from '@/hooks/useTranslation';
import moment from 'moment';

function NdaSignatureModal({
  close,
  handleSubmit,
  updateUser,
  currentUser,
  signESignatureProcedure,
  missionId,
}) {
  const { t } = useTranslation();
  const [updatedAt, setUpdatedAt] = useState('');

  function updateDate() {
    setUpdatedAt(moment().format('LT'));
  }

  // mise à jour du numéro de téléphone dans la modal car numéro indispensable pour la signature du nda.
  // ajout de l'heure de mise à jour du téléphone
  async function onSubmitPhone({ phone }) {
    const variables = {
      ...pick(currentUser, [
        'first_name',
        'last_name',
        'email',
        'language_alpha2',
      ]),
      phone,
    };
    const {
      data: { updateUser: updatedUser },
    } = await updateUser({ variables });

    client.cache.writeData({ data: { currentUser: updatedUser } });
    updateDate();
  }

  async function onSubmit() {
    const response = await signESignatureProcedure({
      variables: {
        id: missionId,
      },
    });
    const url = get(response, 'data.signESignatureProcedure.url');
    window.open(url);
  }

  return (
    <Modal
      size="large"
      close={close}
      onOk={handleSubmit(onSubmit)}
      okText={t('fields.nda.modal.validate_button')}
      onCancel={close}
      name="NdaSignature"
      title={t('fields.nda.modal.title')}
      subtitle={t('fields.nda.modal.subtitle')}
    >
      <p className="fw-bold p-b-m">{t('fields.nda.modal.phone')}</p>
      <form
        className="jc-sb d-f Modal__nda"
        onSubmit={handleSubmit(onSubmitPhone)}
      >
        <span className="d-f">
          <RequiredField
            id="phone"
            name="phone"
            autoComplete="tel"
            component={RfPhone}
            label={t('nda.informations.phone.label')}
            validate={[phoneValidator()]}
            className="m-b-m"
          />
        </span>
        <span className="Modal__update-section d-f">
          <Button
            type="submit"
            className="Phone__form-submit d-f"
            variants={['outline']}
          >
            {t('nda.phone.submit')}
          </Button>
          <div className="Phone__form-updated d-f">
            {!isEmpty(updatedAt) && (
              <p>
                <Updated className="Phone__form-check" />{' '}
                {`${t('nda.updated.phone')} ${updatedAt}`}
              </p>
            )}
          </div>
        </span>
      </form>
    </Modal>
  );
}

NdaSignatureModal.propTypes = {
  missionId: string.isRequired,
  close: func.isRequired,
  handleSubmit: func.isRequired,
  updateUser: func.isRequired,
  currentUser: objectOf(any).isRequired,
  signESignatureProcedure: func.isRequired,
};

export default NdaSignatureModal;
