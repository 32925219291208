import React from 'react';
import { func } from 'prop-types';
import { propType } from 'graphql-anywhere';
import userFragment from '@/api/User/fragment';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import Img from '@/components/Img';
import { getUserAvatar } from '@/helpers/avatar';
import GuestNav from './GuestNav';
import InnerNav from './InnerNav';
import Menu from '../Menu';
import NewMission from '../NewMission';
import Documents from '../Documents';
import Conversations from '../Conversations';
import Notifications from '../Notifications';
import config from '@/_config';

const DesktopNav = ({ t, currentUser, ...otherProps }) => {
  if (!currentUser) {
    return <GuestNav t={t} />;
  }

  return (
    <>
      <nav className="Header__nav">
        <NewMission t={t} userKind={currentUser.kind} />
        <InnerNav
          t={t}
          items={config.header[currentUser.kind]}
          {...otherProps}
        />
        {config.settings.showDocuments && <Documents />}
        {config.settings.showConversations && <Conversations />}
        <Notifications />
      </nav>
      <Popover
        placement="bottom-end"
        className="Header__avatar"
        renderReference={({ ref, getButtonProps }) => (
          <Tooltip placement="bottom-end" title="header.nav.menu.tooltip.title">
            <button type="button" {...getButtonProps({ ref })}>
              <Img
                src={getUserAvatar(currentUser)}
                alt={`${currentUser.first_name} ${currentUser.last_name}`}
              />
            </button>
          </Tooltip>
        )}
        renderPopper={() => (
          <Menu
            desktop
            companyKind={currentUser.company.kind}
            userKind={currentUser.kind}
            t={t}
          />
        )}
      />
    </>
  );
};

DesktopNav.propTypes = {
  t: func.isRequired,
  currentUser: propType(userFragment),
};

DesktopNav.defaultProps = {
  currentUser: null,
};

export default DesktopNav;
