import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import {
  Field,
  RequiredField,
  RfArea,
  RfDate,
  RfFiles,
  RfHelper,
  RfLabelledCheckbox,
  RfText,
} from '@/containers/ReduxForm';
import { acceptance, date } from '@/helpers/validation';
import MilestoneInformation from '@/components/Contract/MilestoneCard/MilestoneInformation';
import MilestonePriceAndDate from '@/components/Contract/MilestoneCard/MilestonePriceAndDate';
import MilestoneDescriptionAndDeliverables from '@/components/Contract/MilestoneCard/MilestoneDescriptionAndDeliverables';
import { propType } from 'graphql-anywhere';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import MilestoneStatusMessage from '@/components/Contract/MilestoneCard/MilestoneStatusMessage';
import { getStatusProperties } from '@/components/Contract/helpers/status';
import { any, objectOf, string } from 'prop-types';
import { DELIVER } from '@/components/Contract/constants/modalMaps';
import ContractMilestone from '@/models/ContractMilestone';
import priceFormat from '@/helpers/priceFormat';
import './styles.scss';
import { isDate } from 'lodash';

function DeliverForfeitForm({
  contract,
  milestone,
  context,
  formValues,
  userKindContext,
}) {
  const { t } = useTranslation();
  const { end_date, status, price } = milestone;
  const { message, primary, secondary, tertiary } = getStatusProperties(
    status,
    userKindContext
  );
  const { costPerUnit } = contract;
  const newPrice = parseFloat(formValues.price);
  const withModification = price !== newPrice;
  const unit = ContractMilestone.unit(contract.kind);

  return (
    <>
      <p className="fw-bold m-b-m">{t('milestone.forfeit.subtitle.create')}</p>
      <RequiredField
        component={RfText}
        id="title"
        name="title"
        label={t('milestone.forfeit.name')}
        className="m-b-m"
      />
      <div className="grid">
        <div className="grid__item mobileL--one-whole one-half">
          <RequiredField
            component={RfDate}
            name="begin_date"
            label={t('milestone.forfeit.beginDate.label')}
            placeholder={t('milestone.forfeit.date.placeholder')}
            className="m-b-m"
            autoComplete="off"
            validate={[
              date({
                format: date,
              }),
            ]}
          />
        </div>
        <div className="grid__item mobileL--one-whole one-half">
          <RfHelper values={['begin_date']}>
            {({ begin_date }) => (
              <>
                <RequiredField
                  component={RfDate}
                  name="end_date"
                  label={t('milestone.forfeit.endDate.label')}
                  placeholder={t('milestone.forfeit.date.placeholder')}
                  autoComplete="off"
                  dayPickerProps={{
                    disabledDays: {
                      before: isDate(begin_date) && begin_date,
                    },
                  }}
                  validate={[
                    date({
                      '>=': begin_date,
                      format: date,
                    }),
                  ]}
                />
              </>
            )}
          </RfHelper>
        </div>
      </div>
      <div className="d-f jc-sb">
        <p>{t('contract.milestone.price.label.deliver')}</p>
        <RequiredField
          component={RfText}
          id="price"
          name="price"
          type="number"
          min={0}
          className="MilestoneDeliver__price m-b-m"
          icon={t('currency.symbol')}
        />
      </div>
      <div className="MilestoneCardModal">
        <div className="MilestoneCardModal__header">
          <span>{t('contract.milestone.title')}</span>
          <span>{t('contract.milestone.unit')}</span>
          <span>{t('contract.milestone.amount')}</span>
          <span>{t('contract.milestone.cost_per_unit')}</span>
          <span>{t('contract.milestone.total_duty')}</span>
        </div>
        <div className={`MilestoneCardModal__information--${context}`}>
          <MilestoneInformation
            contract={contract}
            milestone={milestone}
            costPerUnit={costPerUnit}
            withModification={withModification}
          />
        </div>
        {withModification ? (
          <>
            <div className="MilestoneCardModal__status-message">
              <div className="MilestoneCardModal__modification-message">
                {t(
                  'contract.milestone.technical_assistance.modification.deliver'
                )}
              </div>
            </div>
            <div className="MilestoneCardModal__information--modification">
              <span />
              <span>{t(unit)}</span>
              <span>-</span>
              <span>-</span>
              <span>
                {priceFormat(newPrice, { minDigits: 2, maxDigits: 2 })}
                <span className="p-l-xxs">{t('currency.symbol')}</span>
              </span>
            </div>
          </>
        ) : (
          <div className="MilestoneCardModal__status-message">
            <MilestoneStatusMessage
              secondary={secondary}
              primary={primary}
              message={message}
              tertiary={tertiary}
              endDate={end_date}
            />
          </div>
        )}
        <div className={`MilestoneCardModal__price--${context}`}>
          <MilestonePriceAndDate
            milestone={milestone}
            contract={contract}
            classes={{ priceAndDate: 'MilestoneCard__price-and-date' }}
            withModification={withModification}
            newPrice={newPrice}
          />
        </div>
        <div className="MilestoneCardModal__deliverables">
          <MilestoneDescriptionAndDeliverables milestone={milestone} />
        </div>
      </div>
      <p className="fw-bold">
        {t('contract.milestone.attachment.label.deliver')}
      </p>
      <Field
        component={RfFiles}
        multiple
        id="attachments"
        name="attachments"
        text={t('contract.milestone.attachment.text.deliver')}
        infoText={t('contract.milestone.attachment.infoText.deliver')}
        rejectText={t(
          'contract.milestone.attachment.infoText.rejectText.deliver'
        )}
        t={t}
      />
      <p className="fw-bold">{t('contract.milestone.comment.label.deliver')}</p>
      <Field
        name="comment"
        component={RfArea}
        placeholder={t('contract.milestone.comment.placeholder.deliver')}
        className="m-b-s"
      />
      <RequiredField
        name="confirm"
        type="checkbox"
        component={RfLabelledCheckbox}
        label={t('contract.milestone.confirm.label.deliver')}
        validate={[acceptance()]}
      />
    </>
  );
}

DeliverForfeitForm.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  context: string,
  userKind: string,
  formValues: objectOf(any),
  userKindContext: string.isRequired,
};

DeliverForfeitForm.defaultProps = {
  context: DELIVER,
  userKind: null,
  formValues: {},
};

export default DeliverForfeitForm;
