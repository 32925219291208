import _ from 'lodash';

const OPEN_MODAL = 'modal/OPEN';
const CLOSE_MODAL = 'modal/CLOSE';

const initialState = {
  stack: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      if (_.get(state, 'stack[0].modalId') === action.modalId) return state;
      return {
        stack: [
          ...state.stack,
          {
            modalId: action.modalId,
            modalProps: action.modalProps,
          },
        ],
      };
    }
    case CLOSE_MODAL:
      return {
        stack: state.stack.filter(({ modalId }) => modalId !== action.modalId),
      };
    default:
      return state;
  }
};

export const openModal = (modalId, modalProps) => ({
  type: OPEN_MODAL,
  modalId,
  modalProps,
});

export const closeModal = (modalId) => ({
  type: CLOSE_MODAL,
  modalId,
});
