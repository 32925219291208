import React from 'react';
import { string } from 'prop-types';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useTranslation from '@/hooks/useTranslation';
import {
  expertKeysVariables,
  clientKeysVariables,
} from '@/helpers/contextKeys';

function MilestoneStatusMessage({
  primary,
  secondary,
  tertiary,
  message,
  endDate,
}) {
  const { t } = useTranslation();
  const daysBeforeMilestoneEnd = Math.ceil(
    moment(endDate).diff(moment(), 'days', true)
  );

  // Exception for closure message function
  const messageKey =
    typeof message === 'function'
      ? message({ days: daysBeforeMilestoneEnd })
      : message;

  return (
    <div className={`MilestoneCard__status-message bgc-${secondary}`}>
      <Trans
        i18nKey={messageKey}
        values={{
          days: daysBeforeMilestoneEnd,
          ...expertKeysVariables(t),
          ...clientKeysVariables(t),
        }}
        components={[
          <span className={`${tertiary || primary} fw-bold`}>message</span>,
        ]}
      />
    </div>
  );
}

MilestoneStatusMessage.propTypes = {
  primary: string.isRequired,
  secondary: string.isRequired,
  tertiary: string.isRequired,
  message: string.isRequired,
  endDate: string.isRequired,
};

export default MilestoneStatusMessage;
