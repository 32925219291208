import React from 'react';
import {
  any,
  arrayOf,
  bool,
  func,
  node,
  objectOf,
  oneOfType,
  string,
} from 'prop-types';
import cx from 'classnames';
import Dropzone from 'react-dropzone';
import { fileToAttachment } from '@/helpers/file';
import '../styles.scss';
import { noop } from 'lodash';

class FileInput extends React.Component {
  onFileDrop = (files) => {
    this.handleFileChange(files);
  };

  onFileChange = (evt) => {
    const fileList = Array.from(evt.target.files);
    evt.preventDefault();
    fileList.forEach((file) => {
      file.preview = window.URL.createObjectURL(file); // eslint-disable-line no-param-reassign
    });
    this.handleFileChange(fileList);
  };

  handleFileChange = async (files) => {
    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        const attachment = fileToAttachment(file);

        this.props.onUploadProgress(attachment, 0);

        const {
          data: { uploadAttachment: uploadedFile },
        } = await this.props.mutate({
          variables: {
            attachment,
            kind: this.props.attachmentKind,
            onUploadProgress: (progressEvent) => {
              this.props.onUploadProgress(
                attachment,
                Math.min(
                  Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                  ),
                  99
                )
              );
            },
          },
        });

        setTimeout(() => {
          this.props.onUploadProgress(uploadedFile, 100);
        }, 300);

        return uploadedFile;
      })
    );

    if (this.props.multiple) {
      this.props.onChange([...this.props.value, ...uploadedFiles]);
    } else {
      this.props.onChange(uploadedFiles);
    }
  };

  render() {
    const {
      className,
      children,
      id,
      accept,
      multiple,
      minFiles,
      maxFiles,
      minSize,
      maxSize,
      disableClick,
      drop,
      value,
      onChange,
      mutate,
      attachmentKind,
      onUploadProgress,
      t,
      ...otherProps
    } = this.props;

    if (drop && typeof children !== 'function') {
      console.error('Invalid prop `children`, expected `function`'); // eslint-disable-line no-console
      return null;
    }
    if (drop) {
      return (
        <Dropzone
          {...otherProps}
          className={cx('FileInput', className)}
          activeClassName={cx('FileInput--active', `${className}--active`)}
          rejectClassName={cx('FileInput--reject', `${className}--reject`)}
          accept={accept.length ? accept : null}
          multiple={multiple}
          disableClick={disableClick}
          onDrop={this.onFileDrop}
          minSize={minSize}
          maxSize={maxSize}
        >
          {children}
        </Dropzone>
      );
    }

    return (
      <div className={cx('FileInput', className)}>
        <label htmlFor={id}>{children}</label>
        <input
          {...otherProps}
          type="file"
          id={id}
          accept={Array.isArray(accept) ? accept.join(', ') : accept}
          multiple={multiple}
          onChange={this.onFileChange}
        />
      </div>
    );
  }
}

FileInput.propTypes = {
  mutate: func.isRequired,
  className: string,
  children: oneOfType([node, func]).isRequired,
  id: string.isRequired,
  onChange: func,
  accept: oneOfType([string, arrayOf(string)]),
  multiple: bool,
  disableClick: bool,
  drop: bool,
  value: oneOfType([arrayOf(objectOf(any)), objectOf(any)]),
  attachmentKind: string,
  onUploadProgress: func,
};

FileInput.defaultProps = {
  className: null,
  onChange: noop,
  accept: [],
  multiple: false,
  disableClick: false,
  drop: false,
  value: null,
  attachmentKind: 'attachment',
  onUploadProgress: noop,
};

export default FileInput;
