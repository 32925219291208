import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import Dropdown from '@/components/Dropdown';
import { TextInput } from '@/components/Fields/TextField';

class AutoComplete extends React.Component {
  state = {
    value: this.props.value,
  };

  /* N'est appelée que dans le cas du TypeAhead */
  handleStateChange = (changes) => {
    if ('selectedItem' in changes) {
      this.setState({ value: changes.selectedItem });
    } else if ('inputValue' in changes) {
      this.setState({ value: changes.inputValue });
    }
    if (this.props.onChange) {
      /* Un typeAhead ne peut gerer que des string */
      this.props.onChange(changes.inputValue);
    }
  };

  filterFn = (option, { itemToString }) => itemToString(option).toLowerCase();

  render() {
    const {
      inputProps,
      typeAhead,
      onFocus,
      onBlur,
      value,
      disabled,
      ...props
    } = this.props;

    const ddProps = typeAhead
      ? {
          ...props,
          onStateChange: this.handleStateChange,
          selectedItem: this.state.value,
        }
      : { ...props, selectedItem: value };

    return (
      <Dropdown
        {...ddProps}
        filterOptions={this.filterFn}
        typeAhead={typeAhead}
      >
        {({ getInputProps, clearSelection }) => (
          <TextInput
            {...getInputProps({
              ...inputProps,
              onFocus,
              onBlur,
              onClear: () => {
                clearSelection();
                if (inputProps.onClear) inputProps.onClear();
              },
              disabled,
            })}
          />
        )}
      </Dropdown>
    );
  }
}

AutoComplete.propTypes = {
  inputProps: pp.objectOf(pp.any),
  typeAhead: pp.bool,
  onChange: pp.func,
  value: pp.oneOfType([pp.string, pp.object]),
  itemToString: pp.func,
  onBlur: pp.func,
  onFocus: pp.func,
  disabled: pp.bool,
};

AutoComplete.defaultProps = {
  inputProps: {},
  typeAhead: false,
  onChange: _.noop,
  onBlur: _.noop,
  onFocus: _.noop,
  value: '',
  itemToString: _.identity,
  disabled: false,
};

export default AutoComplete;
