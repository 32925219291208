import React from 'react';
import { arrayOf, bool, func, string } from 'prop-types';
import cx from 'classnames';
import { noop } from 'lodash';
import { propType } from 'graphql-anywhere';
import attachmentFragment from '@/api/Attachment/fragment';
import File from '@/components/File';
import './styles.scss';

function Files({ className, files, onActionClick, assets }) {
  if (!files.length) return null;
  return (
    <div className={cx('Files', className)}>
      {files.map((file, index) => (
        <File
          key={file.file_uuid}
          file={file}
          index={index}
          onActionClick={onActionClick}
          asset={assets}
        />
      ))}
    </div>
  );
}

Files.propTypes = {
  className: string,
  files: arrayOf(propType(attachmentFragment)),
  onActionClick: func,
  assets: bool,
};

Files.defaultProps = {
  className: null,
  files: [],
  onActionClick: noop,
  assets: false,
};

export default Files;
