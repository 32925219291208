/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { objectOf, any, func, arrayOf, string, bool } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Trash } from '@/images/trash.svg';
import { RequiredField, Field, RfText, RfSelect } from '@/containers/ReduxForm';
import Action from '@/components/ProposalView/Action/index';
import { hasValidCharge } from '@/helpers/charges';
import './styles.scss';

function ChargeUnits({
  fields,
  change,
  chargeUnits,
  displayOnMount,
  required,
}) {
  const { t } = useTranslation();
  const [displayEmptyMessage, setDisplayEmptyMessage] = useState(false);

  useEffect(
    () => {
      if (!fields.length && displayOnMount) fields.push({});
      setDisplayEmptyMessage(!hasValidCharge(fields.getAll()));
    },
    [fields, displayOnMount]
  );

  function handleActionClick(entity, index) {
    change(`${fields.name}[${index}]`, {
      ...entity,
      _destroy: !entity._destroy,
    });
  }

  return (
    <>
      {fields.map((field, i) => {
        const entity = fields.get(i);
        if (entity._destroy) return null;
        return (
          <div className="ChargeUnits" key={field}>
            <RequiredField
              id="title"
              name={`${field}.title`}
              component={RfText}
              label={t('proposal.modal.quote.pricing.name.label')}
            />
            <Field
              component={RfSelect}
              options={chargeUnits.map((unit, index) => ({
                id: index,
                value: unit,
                label: t(`proposal.modal.quote.pricing.unit_${unit}`),
              }))}
              value="square_meters"
              name={`${field}.unit`}
              placeholder={t('proposal.section.quote.pricing.unit.placeholder')}
            />
            <RequiredField
              id="quantity"
              name={`${field}.quantity`}
              component={RfText}
              type="number"
              min={0}
              step={0.5}
              label={t('proposal.modal.quote.pricing.quantity.label')}
            />
            <RequiredField
              id="cost_per_unit"
              name={`${field}.cost_per_unit`}
              component={RfText}
              type="number"
              min={0}
              label={t('proposal.modal.quote.pricing.cost.label')}
              icon={t('currency.symbol')}
            />
            <button
              className="ChargeUnits__action"
              type="button"
              onClick={() => handleActionClick(entity, i)}
            >
              <Trash />
            </button>
          </div>
        );
      })}
      {displayEmptyMessage &&
        required && (
          <div className="red fs-small">
            {t('proposal.modal.quote.pricing.error')}
          </div>
        )}
      <Action onClick={() => fields.push({})}>
        {t('proposal.modal.quote.pricing.add')}
      </Action>
    </>
  );
}

ChargeUnits.propTypes = {
  fields: objectOf(any).isRequired,
  change: func.isRequired,
  chargeUnits: arrayOf(string),
  displayOnMount: bool,
  required: bool,
};

ChargeUnits.defaultProps = {
  chargeUnits: [],
  displayOnMount: true,
  required: true,
};

export default ChargeUnits;
