import React from 'react';
import client from '@/helpers/apollo';
import getTags from '@/api/Tag/queries';
import debouncePromise from '@/helpers/debouncePromise';
import { SuggestionsInput } from '@/components/Fields/SuggestionsField';
import useTranslation from '@/hooks/useTranslation';
import { number, string } from 'prop-types';
import { SKILL } from '@/constants/tagKind';

function TagsSuggestionsInput({ kind, minCharsSuggestion, ...props }) {
  const { t } = useTranslation();
  const loadTags = debouncePromise(
    (query) =>
      new Promise((resolve, reject) =>
        client
          .query({
            query: getTags,
            variables: { query, kind, limit: 10 },
          })
          .then(({ data }) => resolve(data.tags))
          .catch(reject)
      ),
    250
  );

  return (
    <SuggestionsInput
      loadOptions={loadTags}
      minChars={minCharsSuggestion}
      itemToString={(i) => (i ? i.name : '')}
      t={t}
      {...props}
    />
  );
}

TagsSuggestionsInput.propTypes = {
  kind: string,
  minCharsSuggestion: number,
};

TagsSuggestionsInput.defaultProps = {
  kind: SKILL,
  minCharsSuggestion: 2,
};

export default TagsSuggestionsInput;
