import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { registerUser } from '@/api/User/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import SignupModal from './SignupModal';
import { CLIENT } from '@/constants/userKind';

export default compose(
  graphql(registerUser, { name: 'registerUser' }),
  reduxForm({
    form: 'signup_modal',
    initialValues: {
      kind: CLIENT,
    },
  })
)(SignupModal);
