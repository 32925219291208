import React from 'react';
import ProfileModal from '../../ProfileModal';
import { formatTags } from '@/components/Tags/helpers/handler';
import { PROFILE_COMPANY_VALUES } from '@/constants/forms';
import CompanyValuesSection from '@/components/Sections/CompanyValuesSection';
import { COMPANY_VALUES } from '@/constants/tagKind';
import useTranslation from '@/hooks/useTranslation';

const CompanyValuesModal = ({ contractTypes, ...props }) => {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal.company_value.title')}
      form={PROFILE_COMPANY_VALUES}
      formatValues={({ company_value_skills }) => ({
        company_value_skills: formatTags(company_value_skills),
      })}
      initialValues={{
        company_value_skills: [],
      }}
      overlayClassName="Modal__overlay Individual__certification__skills__modal"
      {...props}
    >
      <div>
        <CompanyValuesSection
          mode={props.mode}
          kind={COMPANY_VALUES}
          withCreatable
          withRating={false}
        />
      </div>
    </ProfileModal>
  );
};

export default CompanyValuesModal;
