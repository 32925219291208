import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

function Menu({ children, className, separators }) {
  return (
    <ul className={cx('Menu', { 'Menu--separators': separators }, className)}>
      {children}
    </ul>
  );
}

Menu.propTypes = {
  children: pp.node.isRequired,
  className: pp.string,
  separators: pp.bool,
};

Menu.defaultProps = {
  className: null,
  separators: false,
};

export default Menu;
