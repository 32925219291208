import { sendProposal, userAbility } from './user.common';

export default function defineAbilityForUser(can) {
  userAbility(can);
  can(
    'send_proposal',
    'User',
    (user) =>
      !(user.isMissionOrFulltimeContractType() || user.isMissionContractType())
        ? true
        : sendProposal(user)
  );
}
