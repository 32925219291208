const TOGGLE = 'relevanceScore/TOGGLE';
const CLOSE = 'relevanceScore/CLOSE';

const initialState = {
  toggled: false,
  closed: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE:
      return { ...state, toggled: !state.toggled };
    case CLOSE:
      return { ...state, closed: true };
    default:
      return state;
  }
}

export const toggle = () => ({ type: TOGGLE });

export const close = () => ({ type: CLOSE });
