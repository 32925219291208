import React from 'react';
import {
  arrayOf,
  any,
  func,
  objectOf,
  string,
  bool,
  object,
  shape,
} from 'prop-types';
import Downshift from 'downshift';
import cx from 'classnames';
import { get, isString, isNull, find, first, isObject } from 'lodash';
import { propType } from 'graphql-anywhere';
import userFragment from '@/api/User/fragment';
import { generateQuery, queryParams } from '@/helpers/query';
import Button from '@/components/Button';
import {
  focus,
  handleChange,
  handleInputBlur,
  handleInputFocus,
  handleInputKeydown,
  handleOuterClick,
  handleStateChange,
  handleRenderControl,
  handleRenderRefinements,
  handleRenderHistory,
  handleRenderSuggestionsList,
  handleRenderDropdown,
} from '@/components/Header/SearchBar/helpers/searchBarHandler';
import { indexes } from '@/constants/algolia';
import { isMixed } from '@/selectors/user';
import { suggestionFilters } from '@/components/Header/SearchBar/helpers/suggestions';
import refinements from '@/components/Header/SearchBar/refinements';
import { KEYCODES } from '@/constants/keyCodes';
import { MISSIONS } from '@/constants/router_base';
import '@/components/Header/SearchBar/styles.scss';

class SearchBarMixed extends React.Component {
  input = React.createRef();

  constructor(props, context) {
    super(props, context);
    const { location, noSuggestion } = props;
    this.state = {
      value: queryParams(location.search, 'query') || '',
      displayHome: true,
      active: false,
      filteredSuggestions: !noSuggestion ? this.filterSuggestions() : null,
    };
  }

  componentDidMount() {
    const { autoFocus, currentUser, setStorageValue, location } = this.props;
    const pathname = get(location, 'pathname').replace('/', '');

    if (autoFocus) focus(this.input);

    // Set suggestion filter on component mount corresponding with pathname
    // so it override redux store value
    if (isMixed(currentUser)) {
      // If pathname does not match the filter, we fallback to the default filter
      const filter =
        find(suggestionFilters, { path: pathname }) ||
        find(suggestionFilters, { isDefault: true });

      setStorageValue('selectedSuggestionFilter', filter);
    }
  }

  filterSuggestions = () => {
    const { suggestions, selectedSuggestionFilter } = this.props;
    return suggestions.filter(
      ({ index }) =>
        index === indexes.tags ||
        index === get(selectedSuggestionFilter, 'index')
    );
  };

  handleFilterClick = (cProps, selectedSuggestionFilter) => {
    const { suggestions, setStorageValue } = this.props;
    const { index: indexSelected } = selectedSuggestionFilter;

    setStorageValue('selectedSuggestionFilter', selectedSuggestionFilter);

    this.setState({
      filteredSuggestions: suggestions.filter(
        ({ index }) => index === indexSelected || index === indexes.tags
      ),
    });
  };

  handleInputFocus = ({ openMenu }) => {
    handleInputFocus(openMenu, this.setState.bind(this));
  };

  handleInputBlur = () => {
    handleInputBlur(this.setState.bind(this));
  };

  handleOuterClick = () => {
    const { history } = this.props;
    handleOuterClick(history, this.setState.bind(this));
  };

  handleInputChange = (e) => {
    const { value } = e.currentTarget;
    const { refine, noSuggestion } = this.props;
    if (refine !== undefined) refine(value);
    this.setState({
      displayHome: !value.trim(),
      filteredSuggestions: !noSuggestion ? this.filterSuggestions() : null,
    });
  };

  handleInputKeydown = (e, { closeMenu, highlightedIndex }) => {
    const { value } = e.target;
    const { keyCode } = e;
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      selectedSuggestionFilter,
      createHistory,
    } = this.props;
    handleInputKeydown(
      getSearchQueryURI(
        generateQuery({ query: value }),
        get(selectedSuggestionFilter, 'path')
      ),
      keyCode,
      value,
      closeMenu,
      highlightedIndex,
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      this.input,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory
    );
  };

  handleSubmit = (
    value_submited,
    closeMenu,
    highlightedIndex,
    baseURI = null
  ) => {
    // baseURI can be define per default, button "see all projects"

    // value_submited can be an array
    const value_parsed = isObject(value_submited)
      ? value_submited.name
      : value_submited;

    // value can be null
    const value = value_parsed === null ? '' : value_parsed;

    this.setState({ active: true });
    const keyCode = KEYCODES.enter;
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      selectedSuggestionFilter,
      createHistory,
    } = this.props;
    handleInputKeydown(
      getSearchQueryURI(
        generateQuery({ query: value }),
        baseURI === null ? get(selectedSuggestionFilter, 'path') : baseURI
      ),
      keyCode,
      value,
      closeMenu,
      highlightedIndex,
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      this.input,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory
    );
  };

  handleSubmitSearch = ({ closeMenu, highlightedIndex }) => {
    this.handleSubmit(this.state.value, closeMenu, highlightedIndex);
  };

  onSubmitAllResult = ({ closeMenu, highlightedIndex }) => {
    this.handleSubmit('', closeMenu, highlightedIndex, MISSIONS.substring(1));
  };

  handleChange = (item, cProps) => {
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      selectedSuggestionFilter,
      createHistory,
    } = this.props;
    const searchConfig = {
      pathname:
        item && item.link
          ? item.link
          : getSearchQueryURI(
              generateQuery({ query: cProps.itemToString(item) }),
              get(selectedSuggestionFilter, 'path')
            ),
    };
    handleChange(
      item,
      cProps,
      currentUser,
      getSearchQueryURI,
      guestSearchCount,
      this.input,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      searchConfig,
      createHistory
    );
  };

  handleStateChange = (changes) => {
    const { noSuggestion } = this.props;
    this.setState({
      filteredSuggestions: !noSuggestion ? this.filterSuggestions() : null,
    });
    handleStateChange(changes, this.setState.bind(this));
  };

  renderControl = (cProps) => {
    const { active } = this.state;
    const {
      t,
      currentUser,
      location,
      selectedSuggestionFilter,
      noSuggestion,
    } = this.props;
    const { inputValue } = cProps;
    const pathname = get(location, 'pathname');

    const currentIndex = currentUser
      ? find(refinements[currentUser.kind], { pathname }) || null
      : null;

    const resource = isMixed(currentUser)
      ? get(selectedSuggestionFilter, 'key')
      : get(currentIndex, 'label');

    const label =
      inputValue && !active && !noSuggestion
        ? `${inputValue} · ${t(resource)}`
        : inputValue;

    return handleRenderControl(
      cProps,
      active,
      t,
      currentUser,
      location,
      this.input,
      this.handleInputFocus,
      this.handleInputBlur,
      this.handleInputChange,
      this.handleInputKeydown,
      this.handleSubmitSearch,
      this.onSubmitAllResult,
      label
    );
  };

  renderRefinements = (userRefinements, { inputValue }) => {
    const { t } = this.props;
    return handleRenderRefinements(userRefinements, inputValue, t);
  };

  renderHistory = (userHistory, cProps) => {
    const { t } = this.props;
    return handleRenderHistory(userHistory, cProps, t);
  };

  renderSuggestionsLists = (cProps) => {
    const {
      t,
      suggestions,
      currentUser,
      selectedSuggestionFilter,
    } = this.props;
    const { filteredSuggestions } = this.state;
    const resource = isMixed(currentUser)
      ? get(selectedSuggestionFilter, 'key')
      : get(first(refinements[currentUser.kind]), 'label');

    return handleRenderSuggestionsList(
      isMixed(currentUser) ? filteredSuggestions : suggestions,
      t,
      cProps,
      resource
    );
  };

  isSuggestionFilterSelected = (selectedSuggestionFilter, index, isDefault) => {
    if (!selectedSuggestionFilter) return isDefault;
    return selectedSuggestionFilter.index === index;
  };

  renderDropdown = (cProps) => {
    const {
      searchHistory,
      t,
      currentUser,
      selectedSuggestionFilter,
    } = this.props;
    const { displayHome } = this.state;

    const children = (
      <div>
        {!displayHome && (
          <div>
            {isMixed(currentUser) && (
              <div className="d-f jc-sb">
                {suggestionFilters.map(({ key, isDefault, index, path }) => (
                  <Button
                    key={key}
                    onClick={() =>
                      this.handleFilterClick(cProps, { key, index, path })
                    }
                    variants={
                      !this.isSuggestionFilterSelected(
                        selectedSuggestionFilter,
                        index,
                        isDefault
                      )
                        ? ['outline']
                        : []
                    }
                    className="w-50 m-all-xs"
                  >
                    {t(key)}
                  </Button>
                ))}
              </div>
            )}
            <div>{this.renderSuggestionsLists(cProps)}</div>
          </div>
        )}
      </div>
    );

    return handleRenderDropdown(
      cProps,
      searchHistory,
      t,
      displayHome,
      this.renderRefinements,
      this.renderHistory,
      children
    );
  };

  render() {
    const { className, noSuggestion } = this.props;
    const { value } = this.state;
    return (
      <Downshift
        selectedItem={value}
        options={this.props.hits}
        onStateChange={this.handleStateChange}
        onChange={this.handleChange}
        onOuterClick={this.handleOuterClick}
        itemToString={(item) =>
          // eslint-disable-next-line
          isString(item) ? item : isNull(item) ? '' : item.name || item.context
        }
      >
        {(cProps) => (
          <div className={cx('SearchBar', className)}>
            {this.renderControl(cProps)}
            {!noSuggestion && this.renderDropdown(cProps)}
          </div>
        )}
      </Downshift>
    );
  }
}

SearchBarMixed.propTypes = {
  t: func.isRequired,
  refine: func.isRequired,
  hits: arrayOf(object).isRequired,
  suggestions: arrayOf(arrayOf(object)).isRequired,
  history: objectOf(any).isRequired,
  location: objectOf(any).isRequired,
  searchHistory: arrayOf(any),
  createHistory: func.isRequired,
  setStorageValue: func.isRequired,
  className: string,
  autoFocus: bool,
  currentUser: propType(userFragment),
  close: func,
  getSearchQueryURI: func,
  guestSearchCount: func,
  openModal: func,
  selectedSuggestionFilter: objectOf(
    shape({
      index: string.isRequired,
      key: string.isRequired,
      path: string.isRequired,
    })
  ).isRequired,
  noSuggestion: bool,
};

SearchBarMixed.defaultProps = {
  searchHistory: [],
  getSearchQueryURI: Function.prototype,
  guestSearchCount: Function.prototype,
  openModal: Function.prototype,
  close: Function.prototype,
  className: null,
  autoFocus: false,
  currentUser: null,
  noSuggestion: false,
};

export default SearchBarMixed;
