import React, { useEffect, useState } from 'react';
import { string, bool, number, func, oneOfType } from 'prop-types';
import cx from 'classnames';
import { isNil, noop, isInteger } from 'lodash';
import { KEYCODES } from '@/constants/keyCodes';
import { ReactComponent as Plus } from '@/images/plus.svg';
import { ReactComponent as Minus } from '@/images/minus.svg';
import './styles.scss';

function NumberInput({
  className,
  min,
  max,
  value,
  onChange,
  onKeyDown,
  emptyInitialValue,
  step,
  changeState,
  setError,
  ...restProps
}) {
  const withFloat = Number(step) === step && step % 1 !== 0;
  const [emptyValue, setEmptyValue] = useState(false);

  useEffect(
    () => {
      setEmptyValue(
        withFloat
          ? emptyInitialValue && !value
          : emptyInitialValue && !isInteger(value)
      );
    },
    [value, emptyInitialValue, withFloat]
  );

  const setValue = (newValue) => {
    const integer = +newValue;
    if (integer < min || integer > max) return;
    onChange(integer);
    if (changeState) {
      changeState(integer);
    }
  };

  const handleButtonClick = (increment) => () => {
    if (setError) setError(false);
    if (emptyValue) {
      setEmptyValue(false);
    }
    if (value === '') {
      return setValue(min);
    }
    return setValue(value + increment);
  };

  const handleChange = (e) => {
    setValue(e.currentTarget.value);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === KEYCODES.enter) {
      e.preventDefault();
    } else if (
      e.keyCode === KEYCODES.arrowUp ||
      e.keyCode === KEYCODES.arrowDown
    ) {
      e.preventDefault();
      setValue(value + (39 - e.keyCode));
    }
    onKeyDown(e);
  };

  const handleValue = () => {
    if (emptyValue) {
      return '';
    }
    if (withFloat) {
      return value;
    }
    return parseInt(Math.min(max, Math.max(min, value)), 10);
  };

  return (
    <div className={cx('NumberInput', className)}>
      <button
        className="NumberInput__btn"
        type="button"
        disabled={isNil(value) || value === min}
        onClick={handleButtonClick(step * -1)}
      >
        <Minus />
      </button>
      <span className="NumberInput__pusher">
        {value}
        <input
          {...restProps}
          className="NumberInput__control"
          type="number"
          onKeyDown={handleKeyDown}
          value={handleValue()}
          onChange={handleChange}
          onBlur={handleChange}
          min={min}
          max={max}
          placeholder="-"
          step={step}
        />
      </span>
      <button
        className="NumberInput__btn"
        type="button"
        disabled={value === max}
        onClick={handleButtonClick(step)}
      >
        <Plus />
      </button>
    </div>
  );
}

NumberInput.propTypes = {
  className: string,
  value: oneOfType([number, string]),
  onChange: func,
  onKeyDown: func,
  min: number,
  max: number,
  emptyInitialValue: bool,
  step: number,
  changeState: func,
  setError: func,
};

NumberInput.defaultProps = {
  className: null,
  onChange: noop,
  onKeyDown: noop,
  min: 0,
  max: 100,
  value: null,
  emptyInitialValue: false,
  step: 1,
  changeState: noop,
  setError: false,
};

export default NumberInput;
