import { itemsValidator } from '@/helpers/validation';
import config from '@/_config/config';

/**
 * Helper function to merge validate and itemValidate props (to avoid redundancy)
 * and pass some need props
 *
 * @param validate
 * @returns {{validate: *[], props: {itemValidate: any[], invalidCharacters: RegExp, disableCopyPaste: boolean}}}
 */
export function getSkillsValidationAndExtraProps(validate = []) {
  const maxLength = (value) =>
    value.name.length <= config.skills.name.maxLength;
  const invalidCharacter = (value) =>
    !value.name.match(config.skills.name.invalidCharactersRegex);

  const itemsValidate = [
    itemsValidator({
      message: 'fields.validators.invalid_tags',
      itemValidator: invalidCharacter,
    }),
    itemsValidator({
      messageValues: {
        max: config.skills.name.maxLength,
      },
      message: 'fields.validators.tag_max_length',
      itemValidator: maxLength,
    }),
  ];

  return {
    validate: [...validate, ...itemsValidate],
    props: {
      itemValidate: [maxLength, invalidCharacter],
      invalidCharacters: config.skills.name.invalidCharactersRegex,
      disableCopyPaste: true,
    },
  };
}
