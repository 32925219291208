import i18n from '@/i18n';
import { get } from 'lodash';

/**
 * Sort by key only for array of object
 * @param collection
 * @param key
 * @returns {*}
 */
const sortAlphabetically = (collection, key) =>
  collection.sort((a, b) => {
    if (key) {
      return get(a, key, '').localeCompare(get(b, key, ''), i18n.language);
    }
    return a.localeCompare(b, i18n.language);
  });

/**
 *
 * @param translationKey
 * @param collection
 * @param t
 * @returns {*}
 */
export const sortTranslatedCategories = (translationKey, collection, t) =>
  sortAlphabetically(
    collection.map((item) => ({
      ...item,
      label: t(`${translationKey}.${item.name}`),
    })),
    'label'
  );

/**
 *
 * @param collection
 * @param t
 * @returns {*}
 */
export const sortTranslatedLabel = (collection, t) =>
  sortAlphabetically(
    collection.map((item) => ({ ...item, label: t(item.label) })),
    'label'
  );
