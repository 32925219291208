export default [
  {
    label: 'fields.languages.levels.beginner',
    value: 'beginner',
  },
  {
    label: 'fields.languages.levels.elementary',
    value: 'elementary',
  },
  {
    label: 'fields.languages.levels.limited',
    value: 'limited',
  },
  {
    label: 'fields.languages.levels.confirmed',
    value: 'confirmed',
  },
  {
    label: 'fields.languages.levels.advanced',
    value: 'advanced',
  },
  {
    label: 'fields.languages.levels.bilingual',
    value: 'bilingual',
  },
];
