import { useTranslation } from 'react-i18next';
import { contextKeys } from '@/helpers/contextKeys';
import useI18nHelp from '@/hooks/useI18nHelp';
import { identity } from 'lodash';

function useCustomTranslation(...args) {
  const [i18nHelp] = useI18nHelp(false);
  const { t, i18n } = useTranslation(...args);

  const tWithVariablesFn = (key, options) => {
    const selectedKeysFns = contextKeys[key];

    // If there are multiple functions, apply each one and merge the results
    if (Array.isArray(selectedKeysFns)) {
      const translatedKeys = selectedKeysFns.reduce((acc, fn) => {
        const keys = fn(t);
        return { ...acc, ...keys };
      }, {});
      return t(key, { ...options, ...translatedKeys });
    }

    // If there's a single function, apply it
    if (typeof selectedKeysFns === 'function') {
      const translatedKeys = selectedKeysFns(t);
      return t(key, { ...options, ...translatedKeys });
    }

    return t(key, options);
  };

  const tFn = i18nHelp ? identity : tWithVariablesFn;

  return { t: tFn, i18n };
}

export default useCustomTranslation;
