import React from 'react';
import { bool, objectOf, any, func } from 'prop-types';
import Modal from '@/components/Modal';
import { getMissionsRoute } from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';

function OfflineApplySuccessModal({ partnerOffer, isModal, history, close }) {
  const { t } = useTranslation();

  const handleOk = () => {
    close();
    if (isModal) return history.goBack();
    return history.push(getMissionsRoute());
  };

  return (
    <Modal
      name="Offline_apply_success"
      className="OfflineApplyModal"
      onOk={handleOk}
      okText={t('offline_mission.modal.applied.button.ok.text')}
      closable={false}
      title={t(
        `offline_mission.modal.applied.${partnerOffer ? 'partner.' : ''}title`
      )}
    >
      <p>
        {t(
          `offline_mission.modal.applied.${
            partnerOffer ? 'partner.' : ''
          }subtitle`
        )}
      </p>
    </Modal>
  );
}

OfflineApplySuccessModal.propTypes = {
  partnerOffer: bool.isRequired,
  isModal: bool.isRequired,
  history: objectOf(any).isRequired,
  close: func.isRequired,
};

export default OfflineApplySuccessModal;
