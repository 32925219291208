import React from 'react';
import { bool, string, func, any, objectOf, node, oneOf } from 'prop-types';
import { noop } from 'lodash';
import cx from 'classnames';
import ReactModal from 'react-modal';
import { ReactComponent as Close } from '@/images/delete.svg';
import Button from '@/components/Button';
import './styles.scss';

const Modal = ({
  t,
  children,
  className,
  name,
  title,
  subtitle,
  closable,
  shouldCloseOnEsc,
  shouldCloseOnOverlayClick,
  close,
  cancelText,
  okText,
  onCancel,
  onOk,
  onRequestClose,
  leftAction,
  fullScreen,
  hideActions,
  disableActions,
  disableOk,
  size,
  customValidateButton,
  customFixedActions,
  validateButtonClassName,
  hideClose,
  overlayClassName,
  topActions,
  hideValidate,
}) => {
  // This allow to trigger a callback before the modal to close
  const closeWithCallback = (...args) => {
    onRequestClose(...args);
    close(...args);
  };

  return (
    <ReactModal
      isOpen
      contentLabel={title}
      onRequestClose={closeWithCallback}
      shouldCloseOnEsc={closable && shouldCloseOnEsc}
      shouldCloseOnOverlayClick={closable && shouldCloseOnOverlayClick}
      className={cx('Modal', {
        [`Modal--${size}`]: true,
        [`Modal--${name}`]: name,
        'Modal--fullScreen': fullScreen,
      })}
      overlayClassName={overlayClassName}
      bodyOpenClassName="no-scroll"
    >
      {closable &&
        !hideClose && (
          <button
            type="button"
            className="Modal__close"
            onClick={closeWithCallback}
          >
            <Close />
          </button>
        )}

      {topActions && <div className="Modal__topActions">{topActions}</div>}

      <div
        className={cx('Modal__innerContainer', {
          [`Modal__innerContainer--${size}`]: true,
        })}
      >
        {(title || subtitle) && (
          <div className={cx('Modal__heading', className)}>
            {title && <h1>{title}</h1>}
            {subtitle && <p>{subtitle}</p>}
          </div>
        )}

        <div className={cx('Modal__content', className)}>{children}</div>

        {(leftAction || !hideActions) && (
          <footer className={cx('Modal__footer', className)}>
            {leftAction && (
              <div className="Modal__footerLeft">{leftAction}</div>
            )}

            {!hideActions && (
              <div className="Modal__footerRight">
                {closable && (
                  <Button
                    variants={['link']}
                    onClick={(e) => onCancel(e, { close })}
                    disabled={disableActions}
                    className="Modal__cancel m-r-s"
                  >
                    {t(cancelText)}
                  </Button>
                )}
                {/* TO DO.
                  These two options (button props and customValidateButton) may not exist together.
                  A choice has to be done, because we can have to the same thing with the two options.
                */}
                {!customValidateButton ? (
                  <>
                    {!hideValidate && (
                      <Button
                        onClick={(e) => onOk(e, { close })}
                        disabled={disableOk || disableActions}
                        className={validateButtonClassName}
                      >
                        {t(okText)}
                      </Button>
                    )}
                  </>
                ) : (
                  customValidateButton
                )}
              </div>
            )}
          </footer>
        )}
      </div>
      {customFixedActions && (
        <div
          className={cx('Modal__fixed-footer', {
            [`Modal--${size}`]: true,
          })}
        >
          {customFixedActions}
        </div>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  t: func.isRequired,
  children: node,
  className: string,
  name: string,
  title: string,
  subtitle: string,
  closable: bool,
  shouldCloseOnEsc: bool,
  shouldCloseOnOverlayClick: bool,
  close: func.isRequired,
  cancelText: string,
  okText: string,
  onCancel: func,
  onOk: func,
  onRequestClose: func,
  leftAction: node,
  fullScreen: bool,
  hideActions: bool,
  disableActions: bool,
  disableOk: bool,
  size: oneOf(['small', 'small-m', 'medium', 'large']),
  customValidateButton: objectOf(any),
  customFixedActions: objectOf(any),
  validateButtonClassName: string,
  hideClose: bool,
  overlayClassName: string,
  topActions: func,
  hideValidate: bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  name: null,
  title: null,
  subtitle: null,
  closable: true,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
  cancelText: 'modal.cancel',
  okText: 'modal.ok',
  onCancel: noop,
  onOk: noop,
  onRequestClose: noop,
  leftAction: null,
  fullScreen: false,
  hideActions: false,
  disableActions: false,
  disableOk: false,
  size: 'large',
  customValidateButton: null,
  customFixedActions: null,
  validateButtonClassName: null,
  hideClose: false,
  overlayClassName: 'Modal__overlay',
  topActions: noop,
  hideValidate: false,
};

export default Modal;
