import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import cx from 'classnames';
import { ReactComponent as Check } from '@/images/check-double.svg';
import { getAssetImagePath, getFolderName } from '@/helpers/assets';

const MenuItem = ({
  multi,
  selected,
  highlighted,
  label,
  fieldName,
  withIcon,
  ...props
}) => (
  <div
    {...props}
    className={cx({
      'Dropdown__menu-item': true,
      'Dropdown__menu-item--selected': selected,
      'Dropdown__menu-item--highlighted': highlighted,
    })}
  >
    <div>
      {withIcon && (
        <img
          src={getAssetImagePath(getFolderName(fieldName), label)}
          alt="logo"
          className="Dropdown__menu-item--icon p-r-s"
        />
      )}
      {label}
    </div>
    {multi && selected && <Check />}
  </div>
);

MenuItem.propTypes = {
  selected: bool,
  highlighted: bool.isRequired,
  label: oneOfType([string, number]).isRequired,
  multi: bool.isRequired,
  withIcon: bool,
};

MenuItem.defaultProps = {
  selected: false,
  withIcon: false,
};
export default MenuItem;
