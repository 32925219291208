import React from 'react';
import pp from 'prop-types';
import { NavLink } from 'react-router-dom';
import { generateQuery } from '@/helpers/query';
import Filter from '@/components/Filter';

const Refinement = ({ label, pathname, inputValue }) =>
  pathname ? (
    <NavLink
      className="SearchBar__refinement"
      to={{
        pathname,
        ...(inputValue && { search: generateQuery({ query: inputValue }) }),
      }}
    >
      <Filter>{label}</Filter>
    </NavLink>
  ) : (
    <button type="button" className="SearchBar__refinement">
      <Filter>{label}</Filter>
    </button>
  );

Refinement.propTypes = {
  label: pp.string.isRequired,
  pathname: pp.string,
  inputValue: pp.string.isRequired,
};

Refinement.defaultProps = {
  pathname: null,
};

export default Refinement;
