import React from 'react';
import { func, any, objectOf, string } from 'prop-types';
import { presence } from '@/helpers/validation';
import {
  RfHelper,
  Field,
  RequiredField,
  RfSelect,
  RfText,
  RfChoices,
} from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import FormSection from '@/components/FormSection';
import { getCompanyTypes, isSolo } from '@/helpers/company';
import { isContractTypeMissions } from '@/selectors/contractTypes';
import { CLIENT, EXPERT, MIXED } from '@/constants/userKind';
import displayEntitySection from '@/helpers/handleEntitySection';
import { FREELANCE, UNKNOWN } from '@/constants/companyKind';
import { MISSION_OR_FULLTIME, MISSIONS } from '@/constants/contractTypes';

const nameChoiceRadios = [
  {
    id: 'same-as-name',
    value: 'same_as_user_name',
    label: 'company.modal.modalities.company.nameChoice.sameAsName',
  },
  {
    id: 'different-from-name',
    value: 'different_from_user_name',
    label: 'company.modal.modalities.company.nameChoice.differentFromName',
  },
  {
    id: 'portage',
    value: 'portage',
    label: 'company.modal.modalities.company.nameChoice.portage',
  },
  {
    id: 'none',
    value: 'none',
    label: 'company.modal.modalities.company.nameChoice.none',
  },
];

function CompanySection({ t, leader, companyKind }) {
  // if contract type is a mission or leader is a client display main company
  // types if not display solo company
  function formatOptions(contract_types) {
    // if contract type is "mission" or if leader is "client",
    // we display only company types from the same types of its company types
    // ex: if my companyType's type is solo, then i can change only for solo company types
    // ex: if my companyType's type is not solo(collective), then i can change only for collectives company types
    // if contract type is different to "mission" and leader is not a "client",
    // we only display solo company types
    const unknownClientType = leader.kind === CLIENT && companyKind === UNKNOWN;

    const solo =
      isContractTypeMissions(contract_types) ||
      (leader.kind === CLIENT && companyKind !== UNKNOWN)
        ? isSolo(companyKind)
        : !unknownClientType;
    return getCompanyTypes(leader.kind, {
      selectable: true,
      solo,
    }).map((company) => ({
      ...company,
      label: t(company.label),
    }));
  }

  return (
    <RfHelper values={['contract_types', 'kind', 'name_choice']}>
      {({ contract_types, kind, name_choice }) => {
        if (
          !displayEntitySection(contract_types) &&
          [EXPERT, MIXED].includes(leader.kind)
        )
          return null;
        return (
          <FormSection title={t('company.modal.modalities.company.title')}>
            <div className="grid">
              <div className="grid__item tablet--one-whole one-half">
                <RequiredField
                  component={RfSelect}
                  searchable={false}
                  placeholder={t(
                    'company.modal.modalities.company.kind.placeholder'
                  )}
                  className="m-b-s"
                  name="kind"
                  disabled={
                    kind === FREELANCE &&
                    [MISSIONS, MISSION_OR_FULLTIME].includes(contract_types)
                  }
                  options={formatOptions(contract_types)}
                  id="companyType"
                />
              </div>
              {kind === FREELANCE && (
                <div className="grid__item tablet--one-whole one-half">
                  <Field
                    name="name"
                    label={t('company.modal.modalities.company.name.label')}
                    className="m-b-s"
                    component={RfText}
                    disabled={
                      kind === FREELANCE &&
                      ['same_as_user_name', 'portage', 'none'].includes(
                        name_choice
                      )
                    }
                    required={
                      kind !== FREELANCE ||
                      !['same_as_user_name', 'portage', 'none'].includes(
                        name_choice
                      )
                    }
                    validate={[
                      presence({
                        if: (values) =>
                          values.kind !== FREELANCE ||
                          !['same_as_user_name', 'portage', 'none'].includes(
                            values.name_choice
                          ),
                      }),
                    ]}
                    autoComplete="organization"
                  />
                </div>
              )}
              {kind === FREELANCE && (
                <div className="grid__item one-whole">
                  <RequiredField
                    component={RfChoices}
                    checkbox={LabelledRadio}
                    name="name_choice"
                    type="radioGroup"
                    cbProps={nameChoiceRadios.map((company) => ({
                      ...company,
                      label: t(company.label),
                    }))}
                    withSeparators={false}
                  />
                </div>
              )}
            </div>
          </FormSection>
        );
      }}
    </RfHelper>
  );
}

CompanySection.propTypes = {
  t: func.isRequired,
  leader: objectOf(any).isRequired,
  companyKind: string.isRequired,
};

export default CompanySection;
