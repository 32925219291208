import { formValueSelector } from 'redux-form';
import { isEmpty, partialRight, first, map, castArray } from 'lodash';
import { formatTags } from '@/components/Tags/helpers/handler';
import { getCompanyType } from '@/helpers/company';

export const postSignupSelector = formValueSelector('post-signup');
export const signupFieldSelector = (field) =>
  partialRight(postSignupSelector, field);

export const getExperience = signupFieldSelector('company_exp_time');

export const formatCompany = (
  {
    company_kind_main,
    company_kind_other,
    company_skills,
    company_personal_skills,
    company_location_place,
    company_on_site_locations,
    company_work_days,
    company_modalities,
    company_contract_types,
    ...otherValues
  },
  userKind
) => {
  const company_kind =
    company_kind_main === 'other' ? company_kind_other : company_kind_main;
  const recruitmentCandidate = getCompanyType('recruitment_candidate');
  return {
    kind: userKind,
    ...otherValues,
    company_kind: isEmpty(company_kind)
      ? recruitmentCandidate.value
      : company_kind,
    company_skills: company_skills ? formatTags(company_skills) : [],
    company_personal_skills: company_personal_skills
      ? formatTags(company_personal_skills)
      : [],
    company_location_place: company_location_place
      ? company_location_place.address
      : '',
    company_modalities:
      company_modalities.length === 0 ? null : company_modalities,
    company_on_site_locations: map(
      company_on_site_locations.filter((l) => l),
      'address'
    ),
    company_work_days: first(company_work_days),
    company_contract_types: castArray(company_contract_types),
  };
};
