import React, { useEffect } from 'react';
import { arrayOf, objectOf, shape, string, any, func } from 'prop-types';
import ReactModal from 'react-modal';
import AbilityContext from '@/permissions/contexts/AbilityContext';
import ability from '@/permissions/abilities';
import Alert from '@/components/Alert';
import config from '@/_config';
import SignupModal from '@/components/SignupModal';
import * as Profile from '@/scenes/Profile/components/modals';
import * as Company from '@/components/CompanyView/modals';
import * as MyMissionBoard from '@/scenes/MyMissionBoard/components/modals';
import * as Bookmarks from '@/scenes/Bookmarks/components/modals';
import InviteToCreateProposalModal from '@/scenes/Conversations/components/Conversation/InviteToCreateProposalModal';
import * as Proposal from '@/components/ProposalView/modals';
import MissionApplyModal from '@/scenes/Mission/components/ApplyModal';
import MissionOfflineApplyModal from '@/scenes/Mission/components/OfflineApplyModal';
import MissionOfflineApplySuccessModal from '@/scenes/Mission/components/OfflineApplySuccessModal';
import AccountAvatarModal from '@/scenes/Settings/components/Account/AvatarModal';
import CgusModal from '@/components/CgusModal';
import NdaSignatureModal from '@/scenes/Mission/components/MissionView/sections/MissionSection/NdaSignatureModal';
import RelevanceScoreInformationModal from '@/scenes/Conversations/components/RelevanceScoreInformation/components/RelevanceScoreInformationModal';
import Modal from '@/components/Modal';
import BlacklistModal from '@/components/Blacklist';
import ContractTypesModal from '@/scenes/Mission/components/ContractTypesModal';
import MilestoneModal from '@/components/Contract/MilestoneModal';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import CustomInformationModal from '@/scenes/Mission/components/CustomInformationModal';
import * as Documents from '@/scenes/Settings/components/Documents/modals';
import * as CompanySettings from '@/scenes/Settings/components/Company/modals';
import AddExpertPoolModal from '@/scenes/ClientPool/components/AddExpertPoolModal';

const MODAL_COMPONENTS = {
  alert: Alert,
  cgus: CgusModal,
  information: Modal,

  signup: SignupModal,

  nda_signature: NdaSignatureModal,

  // PROFILE
  picture: Profile.Picture,
  cv: Profile.Cv,
  cv_delete: Profile.CvDelete,
  summary: Profile.Summary,
  interviews_conduct: Profile.InterviewsConduct,
  join_us: Profile.JoinUs,
  platform_recommendation: Profile.PlatformRecommendation,
  skills: Profile.Skills,
  sectors: Profile.Sector,
  expertises: Profile.Expertise,
  individual_certification_skills: Profile.IndividualCertificationSkills,
  company_value_skills: Profile.CompanyValues,
  keywords: Profile.KeyWordsModal,
  categories: Profile.Categories,
  degree: Profile.Degree,
  certification: Profile.Certification,
  equipment: Profile.Equipment,
  experience: Profile.Experience,
  project: Profile.Project,
  language: Profile.Language,
  modalities: Profile.Modalities,
  collaborator_number: Profile.CollaboratorNumber,
  collaborators: Profile.Collaborators,
  view_collaborators: Profile.ViewCollaborators,
  tool: Profile.Tools,
  rewards: Profile.Rewards,

  // COMPANY
  company_recommend: Company.Recommend,
  company_collaborate: Company.Collaborate,
  company_converse: Company.Converse,
  company_expert_document: Company.ExpertDocument,
  company_add_expert_pool: AddExpertPoolModal,

  // MISSION BOARD
  myMission_board_createList: MyMissionBoard.CreateList,
  myMission_board_updateList: MyMissionBoard.UpdateList,
  myMission_board_deleteList: MyMissionBoard.DeleteList,
  myMission_board_archiveCard: MyMissionBoard.ArchiveCard,
  myMission_board_archiveMission: MyMissionBoard.ArchiveMission,
  myMission_board_delete_draft_mission: MyMissionBoard.DeleteDraftMission,
  myMission_board_information: MyMissionBoard.Information,

  // MISSION
  mission_apply: MissionApplyModal,
  mission_offline_apply: MissionOfflineApplyModal,
  mission_offline_apply_success: MissionOfflineApplySuccessModal,
  mission_contract_types: ContractTypesModal,
  mission_custom_information: CustomInformationModal,

  // PROPOSAL
  proposal_milestone_forfeit: Proposal.MilestoneForfeit,
  proposal_milestone_technical_assistance:
    Proposal.MilestoneTechnicalAssistance,
  proposal_billing: Proposal.Billing,
  proposal_quote: Proposal.Quote,
  proposal_accept: Proposal.Accept,
  proposal_refuse: Proposal.Refuse,
  proposal_request_update: Proposal.RequestUpdate,
  proposal_archive: Proposal.Archive,
  proposal_publish: Proposal.Publish,

  // CONVERSATIONS
  invite_to_create_proposal: InviteToCreateProposalModal,

  // BOOKMARKS
  bookmark_add: Bookmarks.BookmarkAdd,
  bookmark_edit: Bookmarks.BookmarkEdit,
  bookmark_create: Bookmarks.BookmarkCreate,
  bookmark_delete: Bookmarks.BookmarkDelete,
  bookmark_remove: Bookmarks.BookmarkRemove,

  account_avatar: AccountAvatarModal,

  // BLACKLIST
  blacklist: BlacklistModal,

  // CONVERSATION
  conversation_relevance_score_information: RelevanceScoreInformationModal,

  // CONTRACT
  contract_milestone: MilestoneModal,

  // SETTINGS
  add_file: Documents.AddFile,
  show_file: Documents.ShowFile,
  change_siren: CompanySettings.ChangeSiren,
};

function ModalManager({ stack, closeModal, currentUser }) {
  useEffect(() => {
    ReactModal.setAppElement('#root');
  }, []);

  const handleCloseModal = (modalId) => {
    closeModal(modalId);
  };

  return stack.map(({ modalId, modalProps }) => {
    if (!modalId) return null;
    const SpecificModal = MODAL_COMPONENTS[modalId];
    if (!SpecificModal) return null;

    const user = new User(currentUser);

    return (
      <UserContext.Provider value={user}>
        <AbilityContext.Provider value={ability(user, config)}>
          <SpecificModal
            {...modalProps}
            modalId={modalId}
            close={() => handleCloseModal(modalId)}
            key={modalId}
          />
        </AbilityContext.Provider>
      </UserContext.Provider>
    );
  });
}

ModalManager.propTypes = {
  stack: arrayOf(
    shape({
      modalId: string,
      modalProps: objectOf(any),
    })
  ).isRequired,
  closeModal: func.isRequired,
};

export default ModalManager;
