import _ from 'lodash';
import { compose, mapProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { connect } from 'react-redux';
import translate from '@/helpers/enhancers/translate';
import { openModal, closeModal } from '@/redux/modules/modal';
import ProfileModal from './ProfileModal';

export default compose(
  reduxForm({
    enableReinitialize: true,
  }),
  connect(
    null,
    { openModal, closeModal }
  ),
  translate(),
  withRouter,
  mapProps(({ initialValues, ...props }) => ({
    ...props,
    id: _.get(initialValues, 'id'),
  }))
)(ProfileModal);
