import React from 'react';
import { func, bool, string, arrayOf, objectOf, any } from 'prop-types';
import { first, get } from 'lodash';
import axios from 'axios';
import { Trans } from 'react-i18next';
import MediaQuery from 'react-responsive';
import uuid from 'uuid/v1';
import {
  length,
  phone as phoneValidation,
  email as emailValidation,
  acceptance,
  required,
  presence,
  date,
} from '@/helpers/validation';
import {
  RequiredField,
  RfArea,
  RfText,
  RfPhone,
  RfLabelledCheckbox,
  FileField,
  RfFiles,
  RfLocation,
  RfSelect,
  RfHelper,
  RfDate,
  RfNumber,
} from '@/containers/ReduxForm';
import config from '@/_config';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';
import {
  MISSIONS,
  MISSION_OR_FULLTIME,
  FULLTIME_CONTRACT,
} from '@/constants/contractTypes';
import { BREAKPOINTS } from '@/constants/screen';
import { ALL_EXTENSION } from '@/constants/file';
import logo from '@/images/logo-big.png';
import { api, graphqlAccessToken } from '@/constants/environment';
import { getHelpRoute } from '@/helpers/router';
import SalarySection, {
  DailyRateComponent,
  SalaryComponent,
} from '../SalarySection';
import { authClient } from '@/helpers/apollo';
import { createEventMission } from '@/api/Mission/mutations';
import { otherKeysVariables } from '@/helpers/contextKeys';
import { HTTP_200, HTTP_208 } from '@/constants/httpStatus';
import { queryParams } from '@/helpers/query';
import {
  lightAvailabilities,
  fullAvailabilities,
} from '@/constants/fields/availabilities';
import { translateArrayLabel } from '@/helpers/i18n';
import { today, oneYearFromToday } from '@/helpers/date';
import { PRECISE } from '@/constants/availabilities';
import { OFFLINE } from '@/constants/source';
import './styles.scss';

function OfflineApplyModal({
  t,
  close,
  handleSubmit,
  submitting,
  missionId,
  missionContractTypes,
  onSend,
  partnerOffer,
  partnerUrl,
  openModal,
  isModal,
  history,
}) {
  async function onSubmit({
    attachment,
    daily_rate,
    salary,
    first_name,
    last_name,
    email,
    location_place,
    phone,
    message,
    contract_type,
    job_name,
    availability,
    exp_time,
    available_at,
  }) {
    const search = get(history, 'location.search');
    const partner = queryParams(search, 'partner');
    const sponsored = queryParams(search, 'sponsored');

    const res = await axios.post(
      `//${api}/candidacies/offline-create`,
      {
        source: OFFLINE,
        mission_id: missionId,
        first_name,
        last_name,
        email,
        location_place: location_place.address,
        phone,
        job_name,
        request_text: message,
        salary,
        daily_rate,
        attachment_id: attachment ? get(first(attachment), 'id') : null,
        contract_types:
          first(missionContractTypes) === MISSION_OR_FULLTIME
            ? contract_type
            : first(missionContractTypes),
        availability,
        exp_time,
        ...(available_at && { available_at }),
        ...(partner && { partner }),
        ...(sponsored && { sponsored }),
      },
      {
        headers: {
          Authorization: `Bearer ${graphqlAccessToken}`,
        },
      }
    );
    if (res.status === HTTP_200 || res.status === HTTP_208) {
      close();
      openModal('mission_offline_apply_success', {
        partnerOffer,
        isModal,
        history,
      });
    }
    onSend(res.status);
    if (partnerOffer) {
      const { analytics } = window;
      authClient.mutate({
        mutation: createEventMission,
        variables: {
          id: missionId,
          anonymous_id: analytics.user().anonymousId(),
          ...(partner && { partner }),
          ...(sponsored && { sponsored }),
        },
      });
      window.open(partnerUrl);
    }
  }

  const getSalaryComponent = () => {
    switch (first(missionContractTypes)) {
      case MISSION_OR_FULLTIME:
        return <SalarySection missionContractTypes={missionContractTypes} />;
      case MISSIONS:
        return <DailyRateComponent />;
      case FULLTIME_CONTRACT:
        return <SalaryComponent />;
      default:
        return null;
    }
  };

  const hasRemunerationAccordingToContract = () =>
    [MISSIONS, MISSION_OR_FULLTIME, FULLTIME_CONTRACT].includes(
      first(missionContractTypes)
    );

  const generatedAutocomplete = uuid();

  return (
    <Modal
      name="OfflineApply"
      className="OfflineApplyModal"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      okText={t('offline.apply.modal.button.ok.text')}
      disableActions={submitting}
      closable={!submitting}
    >
      <div className="OfflineApplyModal__header">
        <img className="OfflineApplyModal__logo" src={logo} alt="Logo" />
        <h2 className="OfflineApplyModal__title">
          {t('offline.apply.modal.title')}
        </h2>
        <span className="tc d-b p-b-m m-t-m">
          {t(`offline.apply.modal.title.subtitle`)}
        </span>
      </div>
      <FormSection title={t('offline.apply.modal.form.title')}>
        <div className="grid">
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="first_name"
              component={RfText}
              label={t('offline.apply.modal.form.first_name.label')}
              validate={[length({ min: 2 })]}
              max={500}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="last_name"
              component={RfText}
              label={t('offline.apply.modal.form.last_name.label')}
              validate={[length({ min: 2 })]}
              max={500}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              component={RfPhone}
              id="phone"
              name="phone"
              autoComplete="tel"
              label={t('offline.apply.modal.form.phone.label')}
              validate={[phoneValidation()]}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="email"
              type="email"
              component={RfText}
              validate={[emailValidation()]}
              label={t('offline.apply.modal.form.email.label')}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              id="job_name"
              name="job_name"
              label={t('company.modal.modalities.job.title')}
              className="m-b-l"
              component={RfText}
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              id="location_place"
              name="location_place"
              component={RfLocation}
              autoComplete={generatedAutocomplete}
              searchOptions={{ types: ['(cities)'] }}
              inputProps={{
                label: t('offline.apply.modal.form.location.label'),
                placeholder: t('locations.input.placeholder'),
              }}
              className="m-b-l"
              withDetails
            />
          </div>
          <MediaQuery maxWidth={BREAKPOINTS.laptop}>
            {(mobile) => (
              <>
                {mobile && (
                  <div className="grid__item large--one-whole one-half">
                    <RequiredField
                      component={RfNumber}
                      className="Signup__experience m-b-m"
                      name="exp_time"
                      max={config.company.experienceTime.max}
                      min={config.company.experienceTime.min}
                      emptyInitialValue
                      step={1}
                      label={t('offline.apply.modal.form.experience.label')}
                    />
                  </div>
                )}
                <div className="grid__item large--one-whole one-half">
                  <RequiredField
                    component={RfSelect}
                    id="availability"
                    name="availability"
                    label={t('company.modal.modalities.availability.label')}
                    placeholder={t(
                      'company.modal.modalities.availability.placeholder'
                    )}
                    options={translateArrayLabel(
                      t,
                      config.company.contractTypeIsAlwaysMissions
                        ? lightAvailabilities
                        : fullAvailabilities
                    )}
                  />
                </div>
                {!mobile && (
                  <div className="grid__item large--one-whole one-half">
                    <RequiredField
                      component={RfNumber}
                      className="Signup__experience"
                      name="exp_time"
                      max={config.company.experienceTime.max}
                      min={config.company.experienceTime.min}
                      emptyInitialValue
                      step={1}
                      label={t('offline.apply.modal.form.experience.label')}
                    />
                  </div>
                )}
                <RfHelper values={['availability']}>
                  {({ availability }) => {
                    if (availability !== PRECISE) return null;
                    return (
                      <div className="grid__item large--one-whole one-half">
                        <RequiredField
                          component={RfDate}
                          name="available_at"
                          className="AvailableAt"
                          label={t(
                            'company.modal.modalities.availability.availableAt.label'
                          )}
                          dayPickerProps={{
                            disabledDays: {
                              before: today,
                              after: oneYearFromToday,
                            },
                          }}
                          validate={[
                            presence(),
                            date({
                              '>=': today,
                              '<=': oneYearFromToday,
                              format: date,
                            }),
                          ]}
                        />
                      </div>
                    );
                  }}
                </RfHelper>
              </>
            )}
          </MediaQuery>
        </div>
        <div className="m-t-m">
          <FileField
            component={RfFiles}
            id="attachment"
            name="attachment"
            attachmentKind="cv"
            helper={t('offline.apply.modal.form.attachment.helper')}
            text={t('offline.apply.modal.form.attachment.text')}
            infoText={t('mission.modal.apply.attachment.infoText')}
            rejectText={t('mission.modal.apply.attachment.infoText.rejectText')}
            maxFiles={1}
            maxSize={config.global.maxSizeAttachment}
            multiple
            accept={ALL_EXTENSION}
            t={t}
            validate={[required()]}
            offline
          />
        </div>
        {!partnerOffer && (
          <>
            <div className="m-t-m">
              <RequiredField
                name="message"
                component={RfArea}
                label={t('offline.apply.modal.form.message.label')}
                helper={t('offline.apply.modal.form.message.helper')}
                validate={[length({ max: 500 })]}
                max={500}
              />
            </div>
            {hasRemunerationAccordingToContract() && (
              <div className="m-t-m">{getSalaryComponent()}</div>
            )}
          </>
        )}
        <div className="m-t-m">
          <RequiredField
            id="accept_terms"
            name="accept_terms"
            type="checkbox"
            component={RfLabelledCheckbox}
            componentLabel={
              <Trans
                i18nKey="offline.apply.modal.form.accept.label"
                values={{
                  link: getHelpRoute(),
                  text: t('cgu.label'),
                  ...otherKeysVariables(t),
                }}
                // eslint-disable-next-line
                components={{ a: <a />, u: <u /> }}
              />
            }
            validate={[acceptance()]}
            className="m-t-xl"
          />
        </div>
      </FormSection>
    </Modal>
  );
}

OfflineApplyModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  missionId: string.isRequired,
  missionContractTypes: arrayOf(string).isRequired,
  onSend: func.isRequired,
  partnerOffer: bool.isRequired,
  partnerUrl: string,
  openModal: func.isRequired,
  history: objectOf(any).isRequired,
  isModal: bool.isRequired,
};

OfflineApplyModal.defaultProps = {
  partnerUrl: null,
};

export default OfflineApplyModal;
