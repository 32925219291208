import pp from 'prop-types';
import _ from 'lodash';
import { formValues } from 'redux-form';

const getNames = (props) => {
  const values = props.values || [];
  return props.if ? [props.if, ...values] : values;
};

const Helper = ({ children, ...props }) => {
  const values = _.pick(props, getNames(props));

  if (props.if) {
    const ifValue = values[props.if];
    const valueIsDefined = _.isArray(ifValue) ? !_.isEmpty(ifValue) : !!ifValue;
    if (props.not && valueIsDefined) return null;
    if (!props.not && !valueIsDefined) return null;
  }

  return _.isFunction(children) ? children(values) : children;
};

Helper.propTypes = {
  not: pp.bool,
  if: pp.string,
  values: pp.arrayOf(pp.string),
  children: pp.oneOfType([pp.node, pp.func]).isRequired,
};

Helper.defaultProps = {
  not: false,
  if: null,
  values: [],
};

export default formValues((props) => _.keyBy(getNames(props)))(Helper);
