import React from 'react';
import { func } from 'prop-types';
import { ReactComponent as Arrow } from '@/images/down-arrow.svg';
import { ReactComponent as Email } from '@/images/email.svg';
import Popover from '@/components/Popover';
import CompaniesListContents from './components/CompaniesListContents';
import CandidateAndFreelanceListContents from './components/CandidateAndFreelanceListContents';
import ResourceListContents from './components/ResourceListContents';
import LinkToWebsite from '../Nav/LinkToWebsite';
import SignupClient from '@/components/Header/Nav/SignupClient';
import './styles.scss';

const ShowCaseBar = ({ t, openModal }) => {
  function PopoverComponent(RenderComponent, labelSuffix, className = '') {
    return (
      <Popover
        mode="hover"
        innerSpacing={0}
        className={className}
        placement="bottom-start"
        renderReference={({ ref, getButtonProps }) => (
          <span
            className="ShowCaseBar__link-dropdown"
            {...getButtonProps({ ref })}
          >
            {t(`showcasebar.link.${labelSuffix}`)}
            <span className="ShowCaseBar__link-dropdown__icon">
              <Arrow />
            </span>
          </span>
        )}
        renderPopper={() => (
          <RenderComponent className="p-t-s p-r-m p-b-s p-l-s" />
        )}
      />
    );
  }

  return (
    <div className="ShowCaseBar">
      <div className="ShowCaseBar__side m-l-m">
        {PopoverComponent(CompaniesListContents, 'companies')}
        {PopoverComponent(
          CandidateAndFreelanceListContents,
          'candidate_and_freelance',
          'm-l-s'
        )}
        {PopoverComponent(ResourceListContents, 'resource', 'm-l-s')}
        <span className="ShowCaseBar__link-dropdown m-l-s fs-small fw-bold">
          <a
            href="https://www.kicklox.com/contact/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="ShowCaseBar__link-dropdown__icon">
              <Email />
            </span>
            {t('showcasebar.link.contact')}
          </a>
        </span>
      </div>
      <div className="ShowCaseBar__side">
        <SignupClient
          t={t}
          className="ShowCaseBar__button-submit-offer"
          openModal={openModal}
        />
        <LinkToWebsite className="ShowCaseBar__link-website p-l-m m-r-m fs-small fw-bold" />
      </div>
    </div>
  );
};

ShowCaseBar.propTypes = {
  t: func.isRequired,
  openModal: func.isRequired,
};

export default ShowCaseBar;
