import React from 'react';
import { isEmpty, isDate, reject, isNil, get } from 'lodash';
import { isValidIBAN, isValidBIC } from 'ibantools';
import Validators, * as validate from 'redux-form-validators';
import translate from '@/helpers/enhancers/translate';
import { formatDate, parseDate, parseDateFormat } from '@/helpers/date';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { TEAMS } from '@/constants/missionKind';

Validators.formatMessage = (message) => {
  const Error = ({ t, id, values }) => {
    const translation = t(id, { ...values, returnObjects: true });

    if (typeof translation === 'object') {
      switch (values.count) {
        case 0:
          return translation.zero;
        case 1:
          return translation.one;
        default:
          return translation.other;
      }
    }
    return translation;
  };
  const TranslatedError = translate()(Error);
  return <TranslatedError {...message} />;
};

export const required = validate.addValidator({
  validator(opts, value) {
    if (isEmpty(value) && !isDate(value)) {
      return {
        id: 'fields.validators.required',
      };
    }
    return undefined;
  },
});

export const picture = validate.addValidator({
  defaultMessage: 'fields.validators.required',
  validator(options, value) {
    return !!value.original;
  },
});

export const presence = (args) =>
  validate.presence({
    message: {
      presence: 'fields.validators.required',
    },
    ...args,
  });

export const acceptance = (args) =>
  validate.acceptance({
    message: 'fields.validators.required',
    ...args,
  });

export const length = (args) =>
  validate.length({
    message: {
      tooLong: 'fields.validators.length.tooLong',
      tooShort: 'fields.validators.length.tooShort',
    },
    ...args,
  });

export const lengthSelect = (args) =>
  validate.length({
    message: {
      tooLong: 'fields.validators.select.length.tooLong',
      tooShort: 'fields.validators.select.length.tooShort',
    },
    ...args,
  });

export const itemsValidator = validate.addValidator({
  validator({ messageValues, message, itemValidator }, values) {
    const errors = (values || []).filter((value) => !itemValidator(value));
    const isNotValid = !!errors.length;

    if (isNotValid) {
      return {
        id: message,
        values: {
          ...messageValues,
          count: errors.length,
        },
      };
    }
    return true;
  },
});

export const email = (args) =>
  validate.email({
    message: {
      email: 'fields.validators.email.invalid',
    },
    ...args,
  });

// Specific validator for email field that can be empty
export const emailOrEmptyEmail = validate.addValidator({
  defaultMessage: 'fields.validators.email.invalid',
  validator(options, value) {
    const emailPattern = /\S+@\S+\.\S+/;
    return emailPattern.test(value) || isEmpty(value);
  },
});

export const iban = validate.addValidator({
  defaultMessage: 'fields.validators.iban.invalid',
  validator(options, value) {
    return isValidIBAN(value);
  },
});

export const bic = validate.addValidator({
  defaultMessage: 'fields.validators.bic.invalid',
  validator(options, value) {
    return isValidBIC(value);
  },
});

export const linkedinUrl = validate.addValidator({
  defaultMessage: 'fields.validators.linkedin_url.invalid',
  validator(options, value) {
    const linkedinPattern = new RegExp(
      'http(s)?://([w]+.)?linkedin.com/in/[A-z0-9_-]+/?'
    );
    return linkedinPattern.test(value);
  },
});

export const date = validate.addValidator({
  validator(opts, value) {
    const parsedDate = parseDate(value);
    const parsedDateFormat = parseDateFormat(value);
    const granularity = get(opts, 'granularity');

    if (value && !parsedDate.isValid()) {
      return {
        id: 'fields.validators.date.invalid',
      };
    }
    if (
      '!=' in opts &&
      !opts['!=']
        .map((item) => new Date(item).getTime())
        .every((item) => item !== parsedDate.toDate().getTime())
    ) {
      return {
        id: 'fields.validators.date.notEqual',
        values: {
          dates: opts['!=']
            .map((item) => formatDate(item, 'DD/MM/YY'))
            .join(', '),
        },
      };
    }
    if ('>=' in opts && parsedDate.isBefore(opts['>='], granularity)) {
      return {
        id: 'fields.validators.date.greaterEqual',
        values: { date: formatDate(opts['>=']) },
      };
    }
    if ('>' in opts && parsedDate.isSameOrBefore(opts['>'], granularity)) {
      return {
        id: 'fields.validators.date.greater',
        values: { date: formatDate(opts['>']) },
      };
    }
    if ('<=' in opts && parsedDate.isAfter(opts['<='], granularity)) {
      return {
        id: 'fields.validators.date.lowerEqual',
        values: { date: formatDate(opts['<=']) },
      };
    }
    if ('<' in opts && parsedDate.isSameOrAfter(opts['<'], granularity)) {
      return {
        id: 'fields.validators.date.lower',
        values: { date: formatDate(opts['<']) },
      };
    }
    if ('format' in opts && !parsedDateFormat.isValid()) {
      return {
        id: 'fields.validators.date.invalid',
        values: { date: formatDate() },
      };
    }
    return undefined;
  },
});

export const lengthSkills = (args) =>
  length({
    min: 5,
    message: {
      tooShort: 'fields.validators.skills.tooShort',
      tooLong: 'fields.validators.skills.tooLong',
    },
    ...args,
  });

export const validateLocations = validate.addValidator({
  defaultMessage: 'Letters only',
  validator(options, value) {
    return !!reject(value, isNil).length;
  },
});

export const phone = validate.addValidator({
  validator: (options, value) => {
    if (value !== '' && !isValidPhoneNumber(value)) {
      return {
        id: 'fields.validators.phone.invalid',
      };
    }
    return undefined;
  },
});

// Specific validator for phone field that can be empty
export const phoneOrEmptyPhone = validate.addValidator({
  validator: (options, value) => {
    if (isEmpty(value)) return true;
    if (value !== '' && !isValidPhoneNumber(value)) {
      return {
        id: 'fields.validators.phone.invalid',
      };
    }
    return undefined;
  },
});

export { default as file } from './filesValidator';

export const url = (args) =>
  validate.url({
    message: 'fields.validators.url.invalid',
    ...args,
  });

export const lengthHTML = validate.addValidator({
  validator: ({ min, max }, value) => {
    const element = document.createElement('div');
    element.innerHTML = value;

    if (max !== null && element.innerText.length > max) {
      return { id: 'fields.validators.length.tooLong', values: { count: max } };
    }

    if (min !== null && element.innerText.length < min) {
      return {
        id: 'fields.validators.length.tooShort',
        values: { count: min },
      };
    }

    return undefined;
  },
});

export const minOrMaxValue = validate.addValidator({
  validator: ({ min, max }, value) => {
    if (max !== null && value > max) {
      return { id: 'fields.validators.value.tooHigh', values: { count: max } };
    }

    if (min !== null && value < min) {
      return {
        id: 'fields.validators.value.tooSmall',
        values: { count: min },
      };
    }

    return undefined;
  },
});

export const milestonePricing = validate.addValidator({
  defaultMessage: 'fields.validators.required',
  validator(options, value) {
    return !!value.part || !!value.amount;
  },
});

export const milestonesTotal = validate.addValidator({
  validator({ kind, remaining_duty, total_duty }) {
    if (!kind) return undefined;
    if (kind === 'technical_assistance') return undefined;

    const remainingPart = (remaining_duty * 100) / total_duty;

    if (remainingPart > 0) {
      return {
        id: 'fields.validators.milestones.total.notEnough',
      };
    }

    if (remainingPart < 0) {
      return {
        id: 'fields.validators.milestones.total.tooMuch',
      };
    }

    return undefined;
  },
});

/**
 * Max value length validator
 *
 * If fieldName attribute is set, then validation is done on the value of the specified field
 * This is mainly use for category / subcategory field, where the max validation is not on category
 * field it self, but on subcategories
 *
 * @type {Validator}
 */
export const max = validate.addValidator({
  validator({ fieldName, max: maxValue }, fieldValue, allValues) {
    const value = fieldName ? allValues[fieldName] : fieldValue;
    return maxValue && value && value.length <= maxValue;
  },
});

/**
 * Min favorite value length validator
 *
 *
 * @type {Validator}
 */
export const minFavoriteLength = validate.addValidator({
  validator({ fieldName, min: minValue }, fieldValue, allValues) {
    const value = allValues[fieldName];
    const favorites = value.filter((subCat) => subCat.favorite);
    if (get(allValues, 'kind') !== TEAMS) return true;
    return favorites.length >= minValue;
  },
});

export const modifyRange = validate.addValidator({
  validator: ({ min: minRange, max: maxRange }, value) => {
    if (
      minRange !== null &&
      value[0] === minRange &&
      (maxRange !== null && value[1] === maxRange)
    ) {
      return { id: 'fields.validators.value.range.pristine' };
    }

    return undefined;
  },
});
