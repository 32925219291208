import React from 'react';
import pp from 'prop-types';
import { length } from '@/helpers/validation';
import { ReactComponent as Plus } from '@/images/plus.svg';
import { RequiredField, RfText } from '@/containers/ReduxForm';
import Button from '@/components/Button';
import './styles.scss';

function NewList({
  t,
  id,
  handleSubmit,
  reset,
  invalid,
  submitting,
  createBookmark,
}) {
  async function onSubmit({ name }) {
    if (!name) return;
    await createBookmark({
      variables: {
        name,
        favorites_entities_attributes: [
          { entity_type: 'Company', entity_id: id },
        ],
      },
      refetchQueries: ['getBookmarks', 'getFavoritesEntities'],
      awaitRefetchQueries: true,
    });
    reset();
  }

  return (
    <div className="NewList">
      <h2 className="NewList__title">
        {t('bookmarks.modal.add.newList.title')}
      </h2>
      <div className="NewList__content">
        <RequiredField
          component={RfText}
          name="name"
          label={t('bookmarks.modal.add.newList.label')}
          validate={[length({ max: 100 })]}
          className="NewList__name"
        />
        <Button
          icon={Plus}
          onClick={handleSubmit(onSubmit)}
          disabled={invalid}
          loading={submitting}
          variants={['small', 'secondary']}
          className="NewList__action"
        />
      </div>
    </div>
  );
}

NewList.propTypes = {
  t: pp.func.isRequired,
  id: pp.string.isRequired,
  handleSubmit: pp.func.isRequired,
  reset: pp.func.isRequired,
  invalid: pp.bool.isRequired,
  submitting: pp.bool.isRequired,
  createBookmark: pp.func.isRequired,
};

export default NewList;
