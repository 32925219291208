import React from 'react';
import { bool, node, string } from 'prop-types';
import cx from 'classnames';
import './styles.scss';

const Filter = ({ children, className, active }) => (
  <div className={cx('Filter', { 'Filter--active': active }, className)}>
    {children}
  </div>
);

Filter.propTypes = {
  children: node.isRequired,
  active: bool.node,
  className: string,
};

Filter.defaultProps = {
  active: false,
  className: null,
};

export default Filter;
