import reduxForm from '@/helpers/enhancers/reduxForm';
import { compose } from 'recompose';
import NdaSignatureModal from './NdaSignatureModal';
import { updateUser } from '@/api/User/mutations';
import { signESignatureProcedure } from '@/api/Mission/mutations';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import { graphql } from 'react-apollo';

export default compose(
  withCurrentUser,
  graphql(updateUser, { name: 'updateUser' }),
  graphql(signESignatureProcedure, { name: 'signESignatureProcedure' }),
  reduxForm({
    form: 'nda_signature',
  })
)(NdaSignatureModal);
