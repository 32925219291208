import { FREELANCE } from '@/constants/companyKind';
import config from '@/_config';

export default (company_contract_types, company_kind_main) => {
  const contractTypes = company_contract_types || [];

  if (config.company.showExpTimeForMixed) return false;

  return (
    !!(
      contractTypes.length === 1 &&
      contractTypes.includes('missions') &&
      company_kind_main !== FREELANCE
    ) ||
    !config.company.experienceTimeRestriction(
      (company_kind_main || '').toLowerCase()
    )
  );
};
