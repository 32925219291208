import { compose } from 'recompose';
import { connect } from 'react-redux';
import { openModal, closeModal } from '@/redux/modules/modal';
import DocumentContent from './DocumentContent';

export default compose(
  connect(
    null,
    { openModal, closeModal }
  )
)(DocumentContent);
