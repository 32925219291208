import React, { useState } from 'react';
import { func, number, string, objectOf, any, oneOfType } from 'prop-types';
import { noop } from 'lodash';
import LoadingCircle from '@/components/LoadingCircle';
import { lowerWithoutAccent } from '@/helpers/string';
import useTranslation from '@/hooks/useTranslation';
import { getlimitedList } from '@/helpers/limitedList';

const withLimitedList = (Downshift) => {
  const LimitedListDownshift = (props) => {
    const { t } = useTranslation();
    const { selectedItems } = props;
    const limitedList = getlimitedList(t, props.name)();
    const limitedListWithoutItemsSelected = limitedList.filter(
      (item) =>
        !selectedItems
          .map((selecteditem) => selecteditem.name)
          .includes(item.name)
    );
    const [options, setOptions] = useState(limitedListWithoutItemsSelected);
    const [loading, setLoading] = useState(false);

    const handleValueChange = (query, helpers) => {
      if (!query) {
        setOptions(limitedListWithoutItemsSelected);
        setLoading(false);
        helpers.closeMenu();
        return;
      }

      setLoading(true);

      setTimeout(() => {
        setOptions(
          limitedListWithoutItemsSelected.filter((element) =>
            lowerWithoutAccent(element.name).includes(lowerWithoutAccent(query))
          )
        );
        setLoading(false);
      }, 500);
      props.onInputValueChange(query, helpers);
    };

    const renderLoading = () => () => (
      <span className="Dropdown__placeholder">
        <LoadingCircle />
      </span>
    );

    return (
      <Downshift
        {...props}
        filterOptions={null}
        onInputValueChange={handleValueChange}
        options={options}
        renderOptions={loading ? renderLoading() : false}
      />
    );
  };

  LimitedListDownshift.propTypes = {
    loadOptions: func.isRequired,
    minChars: number,
    onInputValueChange: func,
    value: oneOfType([string, objectOf(any)]),
  };

  LimitedListDownshift.defaultProps = {
    minChars: 1,
    onInputValueChange: noop,
    value: '',
  };

  return LimitedListDownshift;
};

export default withLimitedList;
