import React from 'react';
import cx from 'classnames';
import Choice from '../index';
import './styles.scss';

const RadioEntry = (props) => (
  <Choice {...props} type="radio">
    {({ checked, disabled, focused, toggle }) => {
      const classes = cx('Radio', {
        'Radio--checked': checked,
        'Radio--focus': focused,
        'Radio--disabled': disabled,
      });
      return (
        <div className={classes} onClick={toggle} role="button" tabIndex="-1" />
      );
    }}
  </Choice>
);

export default RadioEntry;
