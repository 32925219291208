import React from 'react';
import { string, bool, objectOf, any, func } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get, map, first } from 'lodash';
import { getProposalRoute } from '@/helpers/router';
import { date } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import {
  RfHelper,
  RequiredField,
  RfDate,
  RfText,
  RfLabelledCheckbox,
  Field,
} from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import FormSection from '@/components/FormSection';
import ForfeitSections from './ForfeitSections';
import ATSections from './ATSections';
import {
  getChargeUnits,
  getTechnicalAssistanceUnits,
} from '@/api/Proposal/queries';
import { scrollBottom } from '@/helpers/scroll';
import { hasValidCharge } from '@/helpers/charges';
import { FORFEIT, TECHNICAL_ASSISTANCE } from '@/constants/quotationKind';
import Message from '@/components/Message';
import './styles.scss';

// AT Milestone
function QuoteModal({
  close,
  change,
  handleSubmit,
  submitting,
  history,
  formValues,
  kind,
  edit,
  mission,
  company,
  proposalId,
  quotationId,
  currentUserCandidaciesWithoutProposal,
  createProposal,
  createQuote,
  updateQuote,
  chargeUnits,
  technicalAssistanceUnits,
}) {
  const { t } = useTranslation();

  const missionId = get(mission, 'id');

  // eslint-disable-next-line consistent-return
  async function onSubmit({
    quantity,
    work_hours,
    cost_per_unit,
    unit,
    charges_attributes,
    vat,
    ...values
  }) {
    const formattedValues = {
      ...values,
      quantity: quantity ? first(quantity) : null,
      work_hours: work_hours ? first(work_hours) : null,
      unit: unit || null,
      cost_per_unit: cost_per_unit ? +cost_per_unit : null,
      charges_attributes: charges_attributes
        ? charges_attributes.map((a) => ({
            ...a,
            cost_per_unit: +a.cost_per_unit,
            quantity: +a.quantity,
          }))
        : null,
      vat: parseInt(vat, 10),
    };
    if (
      kind === FORFEIT &&
      !hasValidCharge(formattedValues.charges_attributes)
    ) {
      return null;
    }

    if (!proposalId) {
      const {
        details,
        goals,
        deliverables,
        attachments,
        comment,
        payment_period,
        candidacy_id,
      } = formValues;
      const { data } = await createProposal({
        variables: {
          mission_id: missionId,
          details,
          goals,
          deliverables,
          comment,
          attachment_ids: map(attachments, 'id'),
          payment_period,
          candidacy_id:
            currentUserCandidaciesWithoutProposal.length === 1
              ? first(currentUserCandidaciesWithoutProposal).id
              : candidacy_id,
        },
      });
      const { id } = data.createProposal;
      await createQuote({
        variables: {
          proposal_id: id,
          mission_id: missionId,
          kind,
          ...formattedValues,
        },
      });
      close();
      history.replace(getProposalRoute(id));
      scrollBottom();
    } else {
      if (edit) {
        await updateQuote({
          variables: {
            id: quotationId,
            ...formattedValues,
          },
          refetchQueries: ['getProposal'],
          awaitRefetchQueries: true,
        });
      } else {
        await createQuote({
          variables: {
            proposal_id: proposalId,
            mission_id: missionId,
            kind,
            ...formattedValues,
          },
          refetchQueries: ['getProposal'],
          awaitRefetchQueries: true,
        });
      }
      close();
    }
  }

  const OtherSections = {
    forfeit: ForfeitSections,
    technical_assistance: ATSections,
  }[kind];

  return (
    <Modal
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      name="Quote"
      title={t(
        edit ? 'proposal.modal.quote.title.edit' : 'proposal.modal.quote.title'
      )}
      okText={t(`proposal.modal.quote.${kind}.ok`)}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection title={t('proposal.modal.quote.dates.title')}>
        <div className="grid">
          <RequiredField
            id="begin_date"
            name="begin_date"
            component={RfDate}
            label={t('proposal.modal.quote.dates.begin.label')}
            placeholder={t('fields.date.format.placeholder')}
            className="grid__item mobileL--one-whole one-half"
            autoComplete="off"
            validate={[
              date({
                format: date,
              }),
            ]}
          />
          <RfHelper values={['begin_at']}>
            {({ begin_date }) => (
              <RequiredField
                id="end_date"
                name="end_date"
                component={RfDate}
                label={t('proposal.modal.quote.dates.end.label')}
                placeholder={t('fields.date.format.placeholder')}
                className="grid__item mobileL--one-whole one-half"
                autoComplete="off"
                dayPickerProps={{
                  disabledDays: { before: begin_date },
                }}
                validate={[
                  date({
                    format: date,
                  }),
                ]}
              />
            )}
          </RfHelper>
        </div>
      </FormSection>
      {chargeUnits &&
        technicalAssistanceUnits && (
          <OtherSections
            change={change}
            t={t}
            mission={mission}
            company={company}
            chargeUnits={get(chargeUnits, 'charge_units')}
            technicalAssistanceUnits={get(
              technicalAssistanceUnits,
              'technical_assistance_units'
            )}
          />
        )}
      <FormSection title={t('proposal.modal.quote.vat.title')}>
        <p className="m-b-m">{t('proposal.modal.quota.vat.text')}</p>
        <div className="grid">
          <div className="grid__item tablet--one-whole one-half">
            <RequiredField
              id="vat"
              name="vat"
              component={RfText}
              type="number"
              min={0}
              max={100}
              label={t('proposal.modal.quote.vat.label')}
              placeholder={t('admin.billing.legalTax.taxRate.placeholder')}
              icon="%"
              className="m-b-m"
            />
          </div>
          <Field
            id="fill_vat_legal_information"
            name="fill_vat_legal_information"
            type="checkbox"
            component={RfLabelledCheckbox}
            label={t('proposal.modal.quote.change_vat')}
            className="grid__item mobileL--one-whole one-half"
          />
        </div>
      </FormSection>
      {edit &&
        kind === TECHNICAL_ASSISTANCE && (
          <Message
            key="PaymentPeriod"
            type="danger"
            dismissible
            title={t(`proposal.modal.quote.message.title`)}
            text={t(`proposal.modal.quote.message.text`)}
            className="m-t-m bgc-lred"
          />
        )}
    </Modal>
  );
}

QuoteModal.propTypes = {
  close: func.isRequired,
  change: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  history: objectOf(any).isRequired,
  formValues: objectOf(any),
  edit: bool,
  kind: string.isRequired,
  proposalId: string,
  mission: objectOf(any),
  quotationId: string,
  currentUserCandidaciesWithoutProposal: objectOf(any),
  createProposal: func.isRequired,
  createQuote: func.isRequired,
  updateQuote: func.isRequired,
  chargeUnits: propType(getChargeUnits).isRequired,
  technicalAssistanceUnits: propType(getTechnicalAssistanceUnits).isRequired,
  company: objectOf(any),
};

QuoteModal.defaultProps = {
  edit: false,
  proposalId: null,
  mission: null,
  quotationId: null,
  currentUserCandidaciesWithoutProposal: [],
  formValues: {},
  company: null,
};

export default QuoteModal;
