import React from 'react';
import { ReactComponent as Down } from '@/images/chevron-down.svg';

const Toggle = (props) => (
  <button type="button" {...props} className="Select__toggle">
    <Down />
  </button>
);

export default Toggle;
