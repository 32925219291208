import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { getCompanyRoute } from '@/helpers/router';
import Img from '@/components/Img';
import './styles.scss';

function CompanyDigest({ className, company, redirectOnClick }) {
  const { id, leader, name, logo, job } = company;
  const classNames = cx('CompanyDigest', className);

  function renderCompany() {
    return (
      <>
        <figure>
          <Img src={get(logo, 'read_url')} alt={name} />
        </figure>
        <div className="CompanyDigest__inner">
          <h4>{name}</h4>
          <h5>{get(job, 'name')}</h5>
        </div>
      </>
    );
  }

  if (redirectOnClick && leader.kind === 'expert') {
    return (
      <Link
        className={classNames}
        to={{
          pathname: getCompanyRoute(id, leader.kind),
          state: { modal: true },
        }}
      >
        {renderCompany()}
      </Link>
    );
  }

  return <div className={classNames}>{renderCompany()}</div>;
}

CompanyDigest.propTypes = {
  className: pp.string,
  company: pp.objectOf(pp.any).isRequired,
  redirectOnClick: pp.bool,
};

CompanyDigest.defaultProps = {
  className: null,
  redirectOnClick: true,
};

export default CompanyDigest;
