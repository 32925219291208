import config from '@/_config';
import { isArray } from 'lodash';

export const indexes = {
  tags: 'Tag',
  companies: 'Company',
  missions: 'Mission',
};

export const tenantFilter = `tenant:${config.algoliaPrefix}`;
const companyFilter = `${tenantFilter} AND (user_filled: true OR cv_uploaded: true) AND blocked: false`;

export function defaultFilters() {
  return {
    get(obj, prop) {
      return obj[prop];
    },
    get missions() {
      return `${tenantFilter} AND status:published`;
    },
    tags: tenantFilter,
    experts: `${companyFilter} AND (leader_kind:expert OR leader_kind:mixed)`,
    clients: `${companyFilter} AND (leader_kind:client OR leader_kind:mixed)`,
    companies: companyFilter,
  };
}

// This function is necessary to use the MultiChoices filter with include and exclude items
// the normal behaviour of a refinementList is item1 OR item2 0R item3...
// with this function the behaviour of the multiChoices if (item1 OR item2) AND (!item3)
// this code is inspired from this algolia topic
export function getNewRequests(requests) {
  const newRequest = requests;

  newRequest.map((request) => {
    const { facetFilters } = request.params;
    const newFacetFilters = [];
    if (!facetFilters) {
      return null;
    }
    facetFilters.map((facetFilter) => {
      if (isArray(facetFilter) && facetFilter.length > 0) {
        const negFilters = [];
        const posFilters = [];
        facetFilter.map(
          (currentFacetFilter) =>
            currentFacetFilter.indexOf(':-') > -1
              ? newFacetFilters.push([currentFacetFilter])
              : posFilters.push(currentFacetFilter)
        );
        if (negFilters.length > 0) {
          return newFacetFilters.push(negFilters);
        }
        if (posFilters.length > 0) {
          return newFacetFilters.push(posFilters);
        }
      }
      return newFacetFilters.push(facetFilter);
    });
    request.params.facetFilters = newFacetFilters;
    return request.params.facetFilters;
  });

  return newRequest;
}
