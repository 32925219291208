/* eslint-disable import/export */
import { get, isEmpty } from 'lodash';
import { defaultFilters as getCommonFilters } from './common';

export * from './common';

export function defaultFilters(user) {
  const commonFilters = getCommonFilters();

  return {
    ...commonFilters,
    ...(isEmpty(user) && {
      missions: `${commonFilters.missions} AND nda:false`,
    }),
    experts: get(user, 'administrator')
      ? `${commonFilters.experts}`
      : `${commonFilters.experts} AND is_registered: true`,
  };
}
