import React from 'react';
import { func, string, bool } from 'prop-types';
import { Link } from 'react-router-dom';
import {
  getMissionRoute,
  getNewMissionRoute,
  getMyMissionEditRoute,
  getMyMissionNdaRoute,
} from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Plus } from '@/images/add.svg';
import { ReactComponent as Archive } from '@/images/archive.svg';
import { ReactComponent as Pen } from '@/images/pen.svg';
import { ReactComponent as Eye } from '@/images/preview.svg';
import { ReactComponent as Nda } from '@/images/pdf-approval.svg';
import { Menu, MenuItem } from '@/components/Menu';

function BoardMenu({ close, id, status, onArchiveMission, nda, closeModal }) {
  const { t } = useTranslation();

  // FIXME: policies
  const archived = status === 'archived';
  return (
    <Menu>
      {!archived && (
        <>
          <MenuItem>
            <Link
              onClick={() => {
                close();
                closeModal('myMission_board_information');
              }}
              to={{
                pathname: getMissionRoute(id),
                state: { modal: true },
              }}
            >
              <Eye />
              <span>{t('myMissionBoard.menu.see')}</span>
            </Link>
          </MenuItem>
          <MenuItem>
            <Link
              onClick={() => {
                close();
                closeModal('myMission_board_information');
              }}
              to={getMyMissionEditRoute(id)}
            >
              <Pen />
              <span>{t('myMissionBoard.menu.edit')}</span>
            </Link>
          </MenuItem>
          <MenuItem>
            <button
              type="button"
              onClick={() => {
                onArchiveMission(id);
                close();
              }}
            >
              <Archive />
              <span>{t('myMissionBoard.menu.archive')}</span>
            </button>
          </MenuItem>
        </>
      )}
      <MenuItem>
        <Link
          onClick={() => {
            close();
            closeModal('myMission_board_information');
          }}
          to={getNewMissionRoute(id)}
        >
          <Plus />
          <span>{t('myMissionBoard.menu.duplicate')}</span>
        </Link>
      </MenuItem>
      {nda && (
        <MenuItem>
          <Link
            onClick={() => {
              close();
              closeModal('myMission_board_information');
            }}
            to={getMyMissionNdaRoute(id)}
          >
            <Nda />
            <span>{t('myMissionBoard.menu.nda')}</span>
          </Link>
        </MenuItem>
      )}
    </Menu>
  );
}

BoardMenu.propTypes = {
  close: func.isRequired,
  id: string.isRequired,
  status: string.isRequired,
  onArchiveMission: func.isRequired,
  nda: bool.isRequired,
  closeModal: func.isRequired,
};

export default BoardMenu;
