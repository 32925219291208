import { get, isNull, isUndefined, first } from 'lodash';
import { TECHNICAL_ASSISTANCE } from '@/constants/quotationKind';
import { FR } from '@/constants/locales';

const CATEGORIES_WITH_INCREASE = [
  'corporate_m_a_private_equity',
  'taxation',
  'banking_and_finance',
  'competition_trade_and_economic_law',
  'arbitration_and_alternative_dispute_resolution',
  'health_pharma_and_biotechnology',
];

const PRACTICE_CULTURE_WITH_15_INCREASE = [
  'anglo_saxon_firms',
  'french_international_business_law_firm',
];

const PRACTICE_CULTURE_WITH_10_INCREASE = [
  'big_4_and_similar',
  'specialized_law_firm',
  'full_service_law_firm_over_50',
];

const MISSION_WORK_HOURS_LIMIT = 7;
const MISSION_WORK_DAYS_LIMIT = 3;

const HIGH_MATCHING_SCORE = 0.9;
const LOW_MATCHING_SCORE = 0.7;

export const BASE_HOURLY_RATE = 70;

// Helper function to know the number of similar string between two arrays
// (useful here to fin the similar languages between the company and the mission)
function countCommonValues(arr1, arr2, excludedValue) {
  return arr1.filter((value) => value !== excludedValue && arr2.includes(value))
    .length;
}

// Function to compute duration in months between two dates
function computeMonthDuration(beginDate, endDate) {
  const monthDuration =
    Math.abs(endDate.getMonth() - beginDate.getMonth()) +
    12 * (endDate.getFullYear() - beginDate.getFullYear());
  return monthDuration;
}

// Function to check if the mission duration is above one month
// when the result is exactly one month it returns false
function missionDurationAboveOneMonth(date1, date2) {
  if (isNull(date1) || isNull(date2)) return null;
  const begin = new Date(date1);
  const end = new Date(date2);
  const monthDuration = computeMonthDuration(begin, end);
  const dayDifference = end.getDate() - begin.getDate();
  if (monthDuration > 0 && dayDifference > 0) {
    return true;
  }
  return false;
}

export const hourlyRateSimulator = (args) => {
  const expTime = get(args, 'expTime');
  const minExpTime = get(args, 'minExpTime');
  const maxExpTime = get(args, 'maxExpTime');
  const practiceCulture = get(args, 'practiceCulture');
  const locationRegion = get(args, 'locationRegion');
  const categoryIds = get(args, 'categoryIds', []);
  const allCategories = get(args, 'categories', []);
  const languages = get(args, 'languages', []).map((l) => get(l, 'alpha2'));

  const missionWorkHours = get(args, 'missionData.workHours', null);
  const missionWorkDays = get(args, 'missionData.workDays', null);
  const missionBillings = get(args, 'missionData.billing');
  const missionLanguages = get(args, 'missionData.languages', []).map((l) =>
    get(l, 'alpha2')
  );
  const missionBeginAt = get(args, 'missionData.beginAt');
  const missionEndAt = get(args, 'missionData.endAt');
  // end_months field can be filled inside the mission form, but if the end nd begin are defined, the end_months is not used
  const missionEndMonths =
    missionBeginAt && missionEndAt ? null : get(args, 'missionData.endMonths');

  const missionCreateWorkHours = get(args, 'workHours', null);
  const missionCreateWorkDays = get(args, 'workDays', null);
  const missionCreateBillings = get(args, 'billing');
  const missionCreateBeginAt = get(args, 'beginAt');
  const missionBegin = get(args, 'begin');
  const missionCreateEndAt = get(args, 'endAt');
  const missionEnd = get(args, 'end');
  // end_months field can be filled inside the mission form, but if the end nd begin are defined, the end_months is not used
  const missionCreateEndMonths =
    missionEnd === 'approximative' ? first(get(args, 'endMonths')) : null;

  // If MissionData is undefined, the matching variator is not used,
  // else the matching is taken from the missionData or set by default to 0.1
  const missionMatchingScore = isUndefined(get(args, 'missionData'))
    ? null
    : get(args, 'missionData.matchingScore', 0);

  const missionCreateLanguages = get(args, 'languages', []);

  const contractsCount = get(args, 'contractsCount');

  let estimatedHourlyRate = BASE_HOURLY_RATE;

  const roundToHalfTen = (num) => Math.ceil(num / 5) * 5;

  // expert finished contracts count
  let contractPercentage = 1;
  if (contractsCount > 5 && contractsCount <= 10) {
    contractPercentage += 0.05;
  } else if (contractsCount > 10 && contractsCount <= 20) {
    contractPercentage += 0.1;
  } else if (contractsCount > 20) {
    contractPercentage += 0.2;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * contractPercentage
  );

  let expTimePercentage = 1;
  if (expTime >= 2 && expTime <= 5) {
    expTimePercentage += 0.1;
  } else if (expTime >= 6 && expTime <= 10) {
    expTimePercentage += 0.2;
  } else if (expTime > 10) {
    expTimePercentage += 0.4;
  }
  estimatedHourlyRate = roundToHalfTen(estimatedHourlyRate * expTimePercentage);

  // mission creation experience time
  let missionsExpTimePercentage = 1;
  if (
    !(minExpTime === 0 && maxExpTime === 30) &&
    maxExpTime >= 2 &&
    maxExpTime <= 5
  ) {
    missionsExpTimePercentage += 0.1;
  } else if (maxExpTime >= 6 && maxExpTime <= 10) {
    missionsExpTimePercentage += 0.2;
  } else if (!(minExpTime === 0 && maxExpTime === 30) && maxExpTime > 10) {
    missionsExpTimePercentage += 0.4;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * missionsExpTimePercentage
  );

  // Optimized to convert the practiceCulture array into a Set for faster lookup
  let practiceCulturePercentage = 1;
  const practiceCultureSet = new Set(practiceCulture);
  if (
    PRACTICE_CULTURE_WITH_15_INCREASE.some((value) =>
      practiceCultureSet.has(value)
    )
  ) {
    practiceCulturePercentage += 0.15;
  } else if (
    PRACTICE_CULTURE_WITH_10_INCREASE.some((value) =>
      practiceCultureSet.has(value)
    )
  ) {
    practiceCulturePercentage += 0.1;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * practiceCulturePercentage
  );

  let locationPercentage = 1;
  if (locationRegion === 'Île-de-France') {
    locationPercentage += 0.05;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * locationPercentage
  );

  // Check if one of the categoryIds is contained in CATEGORIES_WITH_INCREASE
  let categoryPercentage = 1;
  const isCategoryMatched = categoryIds.some((categoryId) => {
    const category = allCategories.find((c) => get(c, 'id') === categoryId);
    return CATEGORIES_WITH_INCREASE.includes(get(category, 'name'));
  });

  if (isCategoryMatched) {
    categoryPercentage += 0.2;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * categoryPercentage
  );

  // Languages variator
  let languagePercentage = 1;
  const similarLanguagesCount = countCommonValues(
    languages,
    missionLanguages,
    'fr'
  );

  if (similarLanguagesCount === 1) {
    languagePercentage += 0.05;
  }

  if (similarLanguagesCount >= 2) {
    languagePercentage += 0.1;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * languagePercentage
  );

  let missionLanguagePercentage = 1;
  if (missionCreateLanguages.filter((value) => value !== FR).length === 1) {
    missionLanguagePercentage += 0.05;
  }

  if (missionCreateLanguages.filter((value) => value !== FR).length >= 2) {
    missionLanguagePercentage += 0.1;
  }
  estimatedHourlyRate = roundToHalfTen(
    estimatedHourlyRate * missionLanguagePercentage
  );

  // Mission duration variator
  if (missionBillings === TECHNICAL_ASSISTANCE) {
    let missionDurationPercentage = 1;
    if (missionWorkHours >= MISSION_WORK_HOURS_LIMIT) {
      missionDurationPercentage *= 0.75;
    }

    if (missionWorkDays > MISSION_WORK_DAYS_LIMIT) {
      missionDurationPercentage *= 0.85;
    }

    if (
      missionEndMonths > 1 ||
      missionDurationAboveOneMonth(missionBeginAt, missionEndAt)
    ) {
      missionDurationPercentage *= 0.9;
    }
    estimatedHourlyRate = roundToHalfTen(
      estimatedHourlyRate * missionDurationPercentage
    );
  }

  // Mission creation duration variator
  if (missionCreateBillings === TECHNICAL_ASSISTANCE) {
    let missionCreateDurationPercentage = 1;
    if (missionCreateWorkHours >= MISSION_WORK_HOURS_LIMIT) {
      missionCreateDurationPercentage *= 0.75;
    }

    if (missionCreateWorkDays > MISSION_WORK_DAYS_LIMIT) {
      missionCreateDurationPercentage *= 0.85;
    }

    if (
      (missionCreateEndMonths > 1 && missionEnd === 'approximative') ||
      (missionDurationAboveOneMonth(missionCreateBeginAt, missionCreateEndAt) &&
        missionBegin === 'precise' &&
        missionEnd === 'precise')
    ) {
      missionCreateDurationPercentage *= 0.9;
    }
    estimatedHourlyRate = roundToHalfTen(
      estimatedHourlyRate * missionCreateDurationPercentage
    );
  }

  // Matching variator
  if (!isNull(missionMatchingScore)) {
    let matchingPercentage = 1;
    if (missionMatchingScore >= HIGH_MATCHING_SCORE) {
      matchingPercentage += 0.1;
    }

    if (
      missionMatchingScore >= LOW_MATCHING_SCORE &&
      missionMatchingScore < HIGH_MATCHING_SCORE
    ) {
      matchingPercentage += 0.05;
    }

    if (missionMatchingScore < LOW_MATCHING_SCORE) {
      matchingPercentage -= 0.2;
    }
    estimatedHourlyRate = roundToHalfTen(
      estimatedHourlyRate * matchingPercentage
    );
  }

  return estimatedHourlyRate > 70 ? estimatedHourlyRate : 70;
};
