import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import { animated } from 'react-spring';
import { ReactComponent as Warning } from '@/images/warning.svg';
import { ReactComponent as Check } from '@/images/check.svg';
import { ReactComponent as Close } from '@/images/delete.svg';
import './styles.scss';

const icons = {
  success: Check,
  error: Warning,
};

class Flash extends React.Component {
  render() {
    const {
      t,
      hide,
      flash: { kind, title, text },
      style,
    } = this.props;
    return (
      <animated.div className={cx('Flash', `Flash--${kind}`)} style={style}>
        <div className="Flash__inner">
          <div className="Flash__icon">{kind in icons && icons[kind]()}</div>
          <div className="Flash__message">
            <h1 className="Flash__title">
              {title ? t(title) : t(`flash.${kind}.title`)}
            </h1>
            <h2 className="Flash__content">{t(text)}</h2>
          </div>
          <button className="Flash__hide" type="button" onClick={hide}>
            <Close />
          </button>
        </div>
      </animated.div>
    );
  }
}

Flash.propTypes = {
  t: pp.func.isRequired,
  hide: pp.func.isRequired,
  flash: pp.shape({
    id: pp.string.isRequired,
    kind: pp.string.isRequired,
    text: pp.string.isRequired,
  }).isRequired,
  style: pp.objectOf(pp.any).isRequired,
};

export default Flash;
