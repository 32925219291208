import React from 'react';
import pp from 'prop-types';
import Field from '../Field';
import JobInput from './JobInput';

const JobField = ({ label, ...props }) => (
  <Field
    {...props}
    render={({ getInputProps }) => <JobInput {...getInputProps({ label })} />}
  />
);

JobField.propTypes = {
  label: pp.string,
};

JobField.defaultProps = {
  label: null,
};

export default JobField;
