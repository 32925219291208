import React from 'react';
import { RequiredField, RfNumber } from '@/containers/ReduxForm';
import { string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';

const DaysWorkedInput = ({ context }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="d-f jc-sb ai-c m-v-s">
        <p className="m-r-m">
          {t(`milestone.technical_assistance.${context}.days_worked.label`)}
        </p>
        <RequiredField
          id="units_worked"
          name="units_worked"
          inline
          component={RfNumber}
          emptyInitialValue
          min={0}
          max={31}
          step={0.5}
        />
      </div>
    </>
  );
};

DaysWorkedInput.propTypes = {
  context: string.isRequired,
};

export default DaysWorkedInput;
