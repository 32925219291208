import React, { useContext } from 'react';
import pp from 'prop-types';
import { get } from 'lodash';

import { getNotificationsRoute } from '@/helpers/router';
import { ReactComponent as Bell } from '@/images/bell.svg';
import Badge from '@/components/Badge';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import HeaderLink from '../Link';
import NotificationsPopper from './NotificationsPopper';
import './styles.scss';
import CountersContext from '@/contexts/CountersContext';

function NotificationsIcon(props) {
  const countersContext = useContext(CountersContext);
  return (
    <HeaderLink to={getNotificationsRoute()} {...props}>
      <Tooltip title="header.nav.notifications.tooltip.title">
        <Badge count={get(countersContext, 'unviewed_notifications_count', 0)}>
          <Bell />
        </Badge>
      </Tooltip>
    </HeaderLink>
  );
}

function Notifications({ mobile }) {
  if (mobile) {
    return <NotificationsIcon />;
  }

  return (
    <Popover
      innerSpacing={0}
      placement="bottom-end"
      className="p-all-s"
      renderReference={({ ref, getButtonProps }) => (
        <NotificationsIcon {...getButtonProps({ innerRef: ref })} />
      )}
      renderPopper={(args) => <NotificationsPopper {...args} />}
    />
  );
}

Notifications.propTypes = {
  mobile: pp.bool,
};

Notifications.defaultProps = {
  mobile: false,
};

export default Notifications;
