/* eslint-disable import/export */
import { formatMission as format } from '@/selectors/missionsCreator/common';
import contractTypeHandler from './helpers/contractTypeHandler';

export * from './common';

export const formatMission = ({
  salary,
  contract_type,
  daily_rate,
  billings,
  budget,
  ...otherValues
}) => ({
  ...format({ ...otherValues, contract_type }),
  ...contractTypeHandler(contract_type, salary, daily_rate, billings, budget),
});
