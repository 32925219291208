export function scrollTop(behavior = 'auto') {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior,
  });
}

export function scrollBottom() {
  window.scrollTo(0, document.body.scrollHeight);
}

export const scrollToElement = (element) => {
  element.scrollIntoView({
    behavior: 'smooth',
  });
};
