import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import { updateCompanyLogo } from '@/api/Company/mutations';
import { createAttachment } from '@/api/Attachment/mutations';
import PictureModal from './PictureModal';

export default compose(
  graphql(updateCompanyLogo, { name: 'update' }),
  graphql(createAttachment, { name: 'createAttachment' }),
  translate()
)(PictureModal);
