import React, { useEffect } from 'react';
import pp from 'prop-types';
import Section from '../Section';
import config from '@/_config';
import { FACEBOOK, TWITTER, LINKEDIN, YOUTUBE } from '@/constants/router';
import Logo from '@/images/logo.png';
import { ReactComponent as Facebook } from '@/images/logo-facebook.svg';
import { ReactComponent as Twitter } from '@/images/logo-twitter.svg';
import { ReactComponent as Linkedin } from '@/images/logo-linkedin.svg';
import { ReactComponent as Youtube } from '@/images/logo-youtube.svg';
import List from '../List';

const links = [
  {
    id: 'facebook',
    icon: Facebook,
    href: FACEBOOK,
    target: '_blank',
  },
  {
    id: 'twitter',
    icon: Twitter,
    href: TWITTER,
    target: '_blank',
  },
  {
    id: 'linkedin',
    icon: Linkedin,
    href: LINKEDIN,
    target: '_blank',
  },
  {
    id: 'youtube',
    icon: Youtube,
    href: YOUTUBE,
    target: '_blank',
  },
];

function Identity({ t }) {
  useEffect(() => {
    // Load the LinkedIn script asynchronously
    const script = document.createElement('script');
    script.src = 'https://platform.linkedin.com/in.js';
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => {
      // Once the script is loaded, initialize the LinkedIn plugin
      window.IN.init();
    };

    // Add the script to the document
    document.body.appendChild(script);
  }, []);

  return (
    <Section name="Identity">
      <img className="Footer__logo" src={Logo} alt={config.displayName} />
      <div className="IN-widget p-b-m">
        <p>{t('footer.linkedin.widget')}</p>
        <script
          type="IN/FollowCompany"
          data-id="9485372"
          data-counter="bottom"
        />
      </div>
      <address className="Footer__location">{t('footer.address')}</address>
      <a className="Footer__phone" href={`tel:${t('footer.phone')}`}>
        {t('footer.phone')}
      </a>
      <List className="Footer__social" items={links} t={t} />
    </Section>
  );
}

Identity.propTypes = {
  t: pp.func.isRequired,
};

export default Identity;
