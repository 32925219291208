import React from 'react';
import { string, number } from 'prop-types';
import { Trans } from 'react-i18next';
import useTranslation from '@/hooks/useTranslation';
import {
  clientKeysVariables,
  expertKeysVariables,
  missionKeysVariables,
} from '@/helpers/contextKeys';

function Result({ className, i18nKey, count, noResultKeyLabel }) {
  const { t } = useTranslation();
  return (
    <h2 className={className}>
      <Trans
        t={t}
        i18nKey={i18nKey}
        count={count}
        values={{
          count,
          ...clientKeysVariables(t),
          ...expertKeysVariables(t),
          ...missionKeysVariables(t),
        }}
        components={[<span>count</span>]}
      />
      {noResultKeyLabel && (
        <div className="d-f jc-c ai-c">
          <Trans
            t={t}
            i18nKey={noResultKeyLabel}
            values={{
              ...clientKeysVariables(t),
              ...expertKeysVariables(t),
              ...missionKeysVariables(t),
            }}
          />
        </div>
      )}
    </h2>
  );
}

Result.propTypes = {
  className: string,
  i18nKey: string.isRequired,
  count: number.isRequired,
  noResultKeyLabel: string,
};

Result.defaultProps = {
  className: null,
  noResultKeyLabel: '',
};

export default Result;
