import React from 'react';
import pp from 'prop-types';
import { lengthHTML } from '@/helpers/validation';
import { RequiredField, RfEditor } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';

const SummaryModal = ({ t, kind, leader, ...props }) => (
  <ProfileModal
    title={t('company.modal.summary.title')}
    form="company_summary"
    {...props}
  >
    <FormSection>
      <RequiredField
        component={RfEditor}
        name="about"
        label={t('company.modal.summary.label')}
        validate={[lengthHTML({ max: 10000 })]}
        max={10000}
      />
    </FormSection>
  </ProfileModal>
);

SummaryModal.propTypes = {
  t: pp.func.isRequired,
  kind: pp.string.isRequired,
  leader: pp.objectOf(pp.any).isRequired,
};

export default SummaryModal;
