import { RED, PURPLE, BLUE, GREEN, GREY, HGREY } from './colors';

// Milestone statuses
export const CREATED = 'created';
export const CANCELED = 'canceled';
export const STARTED = 'started';
export const DELAYED = 'delayed';
export const REJECTED = 'rejected';
export const DELIVERED = 'delivered';
const DELIVERY_REVIEW_ADMIN = 'delivery_review_admin';
const DELIVERY_WITH_UPDATE_REVIEW_ADMIN = 'delivery_with_update_review_admin';
export const DELIVERY_REVIEW_CLIENT = 'delivery_review_client';
export const DELIVERY_WITH_UPDATE_REVIEW_CLIENT =
  'delivery_with_update_review_client';
const CREATION_REVIEW_ADMIN = 'creation_review_admin';
export const CREATION_REVIEW_CLIENT = 'creation_review_client';
const MODIFICATION_REVIEW_ADMIN = 'modification_review_admin';
const CANCELLATION_REVIEW_ADMIN = 'cancelation_review_admin';
export const MODIFICATION_REVIEW_CLIENT = 'modification_review_client';
export const CANCELLATION_REVIEW_CLIENT = 'cancelation_review_client';

// Group reviews statuses for contract card message
export const reviewStatuses = [
  DELIVERY_REVIEW_ADMIN,
  DELIVERY_WITH_UPDATE_REVIEW_ADMIN,
  DELIVERY_REVIEW_CLIENT,
  DELIVERY_WITH_UPDATE_REVIEW_CLIENT,
  CREATION_REVIEW_ADMIN,
  CREATION_REVIEW_CLIENT,
  MODIFICATION_REVIEW_ADMIN,
  CANCELLATION_REVIEW_ADMIN,
  MODIFICATION_REVIEW_CLIENT,
  CANCELLATION_REVIEW_CLIENT,
];

// Group review client statuses for contract card message
export const reviewClientStatuses = [
  CREATION_REVIEW_CLIENT,
  MODIFICATION_REVIEW_CLIENT,
  CANCELLATION_REVIEW_CLIENT,
];

// Group milestone to deliver for contract card message
export const toDeliverStatuses = [STARTED, DELAYED, REJECTED];

// Group milestone in delivery review for contract card message
export const reviewDeliveryStatuses = [
  DELIVERY_REVIEW_CLIENT,
  DELIVERY_WITH_UPDATE_REVIEW_CLIENT,
];

/**
 * Properties for milestone status
 *
 * Allowed attributes are:
 *
 * color (BLUE | PURPLE | RED | GREEN): Color of a milestone.
 * Primary and secondary attribute are automatically defined with medium and light version of color
 * (see getStatusProperties function)
 * Example: if color = "blue",then primary ="mblue" and secondary="lblue"
 *
 * primary (string): Override primary attribute created by color, useful
 * when primary is not necessary a medium color (see getStatusProperties function)
 *
 * secondary (string): Override secondary attribute created by color, useful
 * when secondary is not necessary a medium color (see getStatusProperties function)
 *
 * tertiary (string): Special attribute to override milestone message color (only used for CANCELED status)
 *
 * label (string): Milestone Action / Status Label key (for translation). Full translation key is created
 * by getStatusProperties function
 *
 * message (string): Milestone Message key (for translation) or closure. Full translation key is created
 * by getStatusProperties function, or prefix is passed to closure
 * Example for closure:
 * {
 *   ...
 *   message: (prefix) => (values) => {
 *     if(values.foo)
 *     return `${prefix}.${message}`
 *     return `${prefix}.foo`
 *   }
 *   ...
 * }
 *
 * hasAction (boolean): if true, then milestone has an action button, if false it's only a label.
 *
 * hasSecondaryActions (boolean): if true, then milestone has a secondary action button update and cancel
 *
 * if attributes are different for expert or client
 * attributes must be nested in separate expert and client objects:
 *
 * Example:
 *
 * {
 *   expert: {
 *     color: RED,
 *     label: 'waiting',
 *     message: 'cancellation_review_client',
 *   },
 *   client: {
 *     color: RED,
 *     label: 'validate',
 *     hasAction: true,
 *     message: 'cancellation_review',
 *   },
 * }
 *
 */
export const statusesProperties = {
  [CANCELED]: {
    color: GREY,
    primary: HGREY,
    tertiary: 'mred',
    label: 'canceled',
    message: 'canceled',
  },
  [CANCELLATION_REVIEW_ADMIN]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'cancellation_review_admin',
    },
  },
  [CANCELLATION_REVIEW_CLIENT]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'cancellation_review_client',
    },
    client: {
      color: RED,
      label: 'validate',
      hasAction: true,
      message: 'cancellation_review',
    },
  },
  [CREATED]: {
    expert: {
      color: PURPLE,
      hasSecondaryActions: true,
      label: 'created',
      message: 'created',
    },
    client: {
      color: PURPLE,
      label: 'created',
      message: 'created',
    },
  },
  [CREATION_REVIEW_ADMIN]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'creation_review_admin',
    },
  },
  [CREATION_REVIEW_CLIENT]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'creation_review_client',
    },
    client: {
      color: RED,
      hasAction: true,
      label: 'validate',
      message: 'creation_review',
    },
  },
  [DELAYED]: {
    expert: {
      color: BLUE,
      hasAction: true,
      hasSecondaryActions: true,
      label: 'deliver',
      message: 'delayed',
    },
    client: {
      color: PURPLE,
      label: 'started',
      message: 'started',
    },
  },
  [DELIVERED]: {
    expert: {
      color: GREEN,
      hasAction: true,
      label: 'go_to_invoice',
      message: 'delivered',
    },
    client: {
      color: GREEN,
      hasAction: true,
      label: 'go_to_invoice',
      message: 'delivered',
    },
  },
  [DELIVERY_REVIEW_ADMIN]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'delivery_review_admin',
    },
    client: {
      color: PURPLE,
      label: 'started',
      message: 'started',
    },
  },
  [DELIVERY_REVIEW_CLIENT]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'delivery_review_client',
    },
    client: {
      color: BLUE,
      hasAction: true,
      label: 'review',
      message: 'delivery_review',
    },
  },
  [DELIVERY_WITH_UPDATE_REVIEW_ADMIN]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'delivery_review_admin',
    },
    client: {
      color: PURPLE,
      label: 'started',
      message: 'started',
    },
  },
  [DELIVERY_WITH_UPDATE_REVIEW_CLIENT]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'delivery_review_client',
    },
    client: {
      color: BLUE,
      hasAction: true,
      label: 'review',
      message: 'delivery_review',
    },
  },
  [MODIFICATION_REVIEW_ADMIN]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'modification_review_admin',
    },
  },
  [MODIFICATION_REVIEW_CLIENT]: {
    expert: {
      color: RED,
      label: 'waiting',
      message: 'modification_review_client',
    },
    client: {
      color: RED,
      hasAction: true,
      label: 'validate',
      message: 'modification_review',
    },
  },
  [REJECTED]: {
    expert: {
      color: BLUE,
      hasAction: true,
      hasSecondaryActions: true,
      label: 'deliver',
      message: 'rejected',
    },
    client: {
      color: PURPLE,
      label: 'rejected',
      message: 'rejected',
    },
  },
  [STARTED]: {
    expert: {
      color: BLUE,
      hasAction: true,
      hasSecondaryActions: true,
      label: 'deliver',
      message: (prefix) => (values) => {
        const { days } = values;
        if (days < 7) return `${prefix}.started`;
        return `${prefix.replace('expert', 'client')}.started`;
      },
    },
    client: {
      color: PURPLE,
      label: 'started',
      message: 'started',
    },
  },
};

export const colorsLegend = [
  {
    color: PURPLE,
    label: 'created',
  },
  {
    color: BLUE,
    label: 'started',
  },
  {
    color: RED,
    label: 'review',
  },
  {
    color: GREEN,
    label: 'delivered',
  },
  {
    color: GREY,
    label: 'cancelled',
  },
];
