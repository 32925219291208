import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';
import { get, values } from 'lodash';
import { func, objectOf, any } from 'prop-types';
import icons from './icons';
import config from '@/_config';
import { ReactComponent as Arrow } from '@/images/arrow-right.svg';
import './styles.scss';
import { getProfileRoute } from '@/helpers/router';
import useRouter from '@/hooks/useRouter';

function RelevanceScoreInformationModal({ close, relevanceScore }) {
  const { t } = useTranslation();
  // scoringStates has nothing to do with react state
  const scoringStates = values(get(relevanceScore, 'states', {}));
  const currentStep = get(relevanceScore, 'current_step', {});
  const { history } = useRouter();

  function renderIcon(stepName) {
    const icon = get(icons, stepName, null);
    return icon ? icon(stepName) : null;
  }

  function handleCompleteProfile() {
    history.push(getProfileRoute(true));
    close();
  }

  return (
    <Modal
      className="RelevanceScoreInformationModal"
      name="conversation_relevance_score_information"
      close={close}
      onCancel={close}
      title={t('relevance_score_information.modal.title')}
      okText={t('relevance_score_information.modal.complete_profile')}
      onOk={handleCompleteProfile}
    >
      <p>
        {t('relevance_score_information.modal.description', {
          score: get(currentStep, 'name', null),
        })}
      </p>
      <p className="fw-bold">
        {t('relevance_score_information.modal.explanation', {
          nextStepScore: get(
            scoringStates,
            config.conversation.relevanceScore.maxScore,
            null
          ),
        })}
      </p>
      <div className="d-f jc-sa m-t-m">
        {scoringStates.map((stepName, key) => (
          <div
            key={stepName}
            className={`RelevanceScoreInformationModal__step${
              key <= get(currentStep, 'max') ? '--active' : ''
            }`}
          >
            {config.conversation.relevanceScore.icon && (
              <span className="RelevanceScoreInformationModal__step-icon m-r-s">
                {renderIcon(stepName)}
              </span>
            )}
            <span className="m-r-m RelevanceScoreInformationModal__step-name">
              {stepName}
            </span>
            {key < scoringStates.length - 1 && (
              <span>
                <Arrow />
              </span>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
}

RelevanceScoreInformationModal.propTypes = {
  close: func.isRequired,
  relevanceScore: objectOf(any),
};

RelevanceScoreInformationModal.defaultProps = {
  relevanceScore: {},
};

export default RelevanceScoreInformationModal;
