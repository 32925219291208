export const isStaging =
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'staging';

export const isProduction = process.env.REACT_APP_ENV === 'production';

export const api = process.env.REACT_APP_API;
export const backofficeUrl = process.env.REACT_APP_BACKOFFICE_URL;
export const graphqlAccessToken = process.env.REACT_APP_GRAPHQL_ACCESS_TOKEN;
export const authPrefix = process.env.REACT_APP_AUTH_PREFIX;
export const tenant = process.env.REACT_APP_TENANT;
export const name = process.env.REACT_APP_NAME;
