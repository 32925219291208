import BaseChoice from '@/components/Choice/Base';
import SectionField from '@/components/SectionField';
import React from 'react';
import { RfChoices } from '@/containers/ReduxForm';

const CardChoice = (props) => (
  <div className="grid__item mobile--one-half tablet--one-third laptop--one-quarter large--one-third one-third">
    <BaseChoice {...props} />
  </div>
);

export default (props) => (
  <SectionField
    {...props}
    component={RfChoices}
    checkbox={CardChoice}
    inputClassName="grid grid--vgutter"
    className="m-b-m"
  />
);
