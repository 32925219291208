export const ACTIVE = 'active';
export const OPEN = 'open';
export const UNAVAILABLE = 'unavailable';

export default [
  {
    id: ACTIVE,
    value: ACTIVE,
    label: 'fields.searchStatus.active',
  },
  {
    id: OPEN,
    value: OPEN,
    label: 'fields.searchStatus.open',
  },
  {
    id: UNAVAILABLE,
    value: UNAVAILABLE,
    label: 'fields.searchStatus.unavailable',
  },
];
