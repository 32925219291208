import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { inviteToMissions } from '@/api/Invitation/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { showFlash } from '@/redux/modules/flash';
import CollaborateModal from './CollaborateModal';

export default compose(
  graphql(inviteToMissions, { name: 'inviteToMissions' }),
  reduxForm({
    form: 'collaborate_invite',
    initialValues: { invitationables: [] },
  }),
  connect(
    null,
    { showFlash }
  )
)(CollaborateModal);
