import { isArray } from 'lodash';
import {
  FULLTIME_CONTRACT,
  MISSIONS,
  MISSION_OR_FULLTIME,
  INTERNSHIP,
  APPRENTICESHIP,
  FIXED_TERM_CONTRACT,
  THESIS,
} from '@/constants/contractTypes';
import config from '@/_config';

export const isContractTypeMissions = (contractType) => {
  if (config.company.contractTypeIsAlwaysMissions) return true;
  return isArray(contractType)
    ? contractType.includes(MISSIONS)
    : contractType === MISSIONS;
};
export const isContractTypeMissionOrFulltime = (contractType) =>
  isArray(contractType)
    ? contractType.includes(MISSION_OR_FULLTIME)
    : contractType === MISSION_OR_FULLTIME;
export const isContractTypeFullTime = (contractType) =>
  isArray(contractType)
    ? contractType.includes(FULLTIME_CONTRACT)
    : contractType === FULLTIME_CONTRACT;
export const isContractTypeInternship = (contractType) =>
  isArray(contractType)
    ? contractType.includes(INTERNSHIP)
    : contractType === INTERNSHIP;
export const isContractTypeApprenticeship = (contractType) =>
  isArray(contractType)
    ? contractType.includes(APPRENTICESHIP)
    : contractType === APPRENTICESHIP;
export const isContractTypeFixedTermContract = (contractType) =>
  isArray(contractType)
    ? contractType.includes(FIXED_TERM_CONTRACT)
    : contractType === FIXED_TERM_CONTRACT;
export const isContractTypeThesis = (contractType) =>
  isArray(contractType)
    ? contractType.includes(THESIS)
    : contractType === THESIS;
