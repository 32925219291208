import { compose } from 'recompose';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getStoredUser } from '@/api/User/queries';
import { acceptCgus } from '@/api/Cgu/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import CgusModal from './CgusModal';
import withCurrentUser from '../../helpers/enhancers/withCurrentUser';

export default compose(
  withCurrentUser,
  graphql(acceptCgus, {
    name: 'acceptCgus',
    options: {
      update: (cache) => {
        const { currentUser } = cache.readQuery({
          query: getStoredUser,
          fetchPolicy: 'cache-first',
        });

        cache.writeData({
          data: { currentUser: { ...currentUser, accepted_latest_cgu: true } },
        });
      },
    },
  }),
  reduxForm({
    form: 'cgus',
    initialValues: {
      accept_terms: false,
    },
  }),
  connect((state) => ({
    formValues: getFormValues('cgus')(state),
  })),
  withApollo,
  withRouter
)(CgusModal);
