import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import translate from '@/helpers/enhancers/translate';
import { updateAbout } from '@/api/Company/mutations';
import SummaryModal from './SummaryModal';

export default compose(
  graphql(updateAbout, { name: 'update' }),
  translate()
)(SummaryModal);
