import { first, map, get } from 'lodash';
import { PRECISE } from '@/constants/availabilities';

const socialNetworksNames = [
  'linkedin',
  'facebook',
  'instagram',
  'twitter',
  'web_site',
];

export const formatModalities = ({
  location_place,
  on_site_locations,
  work_days,
  contract_types,
  available_at,
  availability,
  social_networks,
  ...values
}) => {
  const socialNetworks = socialNetworksNames.map((name) => {
    const existingNetwork = social_networks.find(
      (social_network) => social_network.name === name
    );
    return {
      id: get(existingNetwork, 'id'),
      name,
      url: get(values, name),
    };
  });
  return {
    ...values,
    location_place: get(location_place, 'address'),
    on_site_locations: map(on_site_locations.filter((l) => l), 'address'),
    work_days: first(work_days),
    contract_types: [contract_types],
    available_at: availability === PRECISE ? available_at : null,
    availability,
    social_networks: socialNetworks,
  };
};
