import React from 'react';
import { func, bool, string } from 'prop-types';
import { RequiredField, RfChoices } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import billings from '@/constants/fields/billings';
import goals from '@/constants/fields/goals';
import useTranslation from '@/hooks/useTranslation';
import { MISSION_OR_FULLTIME, MISSIONS } from '@/constants/contractTypes';

function ContractTypesModal({
  close,
  submitting,
  handleSubmit,
  companyId,
  update,
  showFlash,
  handleOnSuccess,
}) {
  const { t } = useTranslation();

  async function onSubmit(variables) {
    await update({
      variables: {
        entity_id: companyId,
        ...variables,
      },
    });
    close();
    handleOnSuccess();
    showFlash({
      kind: 'success',
      text: 'mission.modal.searchStatus.success.text',
    });
  }

  const goalsFiltered = goals.filter(
    (goal) => goal.id === MISSION_OR_FULLTIME || goal.id === MISSIONS
  );

  return (
    <Modal
      name="Mission_contract_types"
      size="large"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('mission.modal.searchStatus.title')}
      disableActions={submitting}
      closable={!submitting}
      okText={t('mission.modal.searchStatus.send')}
    >
      <div className="m-b-m">
        <RequiredField
          type="radioGroup"
          name="contract_types"
          component={RfChoices}
          checkbox={LabelledRadio}
          label={t('mission.modal.searchStatus.label')}
          cbProps={goalsFiltered.map((goal) => ({
            ...goal,
            label: t(goal.label),
            icon: goal.icon,
          }))}
          withSeparators={false}
        />
      </div>

      <RequiredField
        component={RfChoices}
        checkbox={LabelledCheckbox}
        name="billings"
        label={t('company.modal.modalities.bill.label')}
        helper={t('company.modal.modalities.bill.helper')}
        cbProps={billings.map((billing) => ({
          ...billing,
          label: t(billing.label),
        }))}
        withSeparators={false}
      />
    </Modal>
  );
}

ContractTypesModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  update: func.isRequired,
  companyId: string.isRequired,
  handleOnSuccess: func.isRequired,
  showFlash: func.isRequired,
};

export default ContractTypesModal;
