import { useMemo } from 'react';
import _ from 'lodash';
import languages from '@/constants/fields/languages';
import useTranslation from '@/hooks/useTranslation';

const preferredList = [
  'fr',
  'en',
  'es',
  'de',
  'nl',
  'pt',
  'it',
  'cs',
  'ro',
  'zh',
  'ru',
  'ja',
].reverse();

const sortByLabels = (a, b) => (_.deburr(a.label) > _.deburr(b.label) ? 1 : -1);

function useLanguages(preferred = preferredList) {
  const { t } = useTranslation();
  const langs = useMemo(
    () =>
      languages
        .map((l) => ({
          ...l,
          label: t(l.label),
        }))
        .sort((a, b) => {
          const aIndex = preferred.indexOf(a.value);
          const bIndex = preferred.indexOf(b.value);
          return ~aIndex || ~bIndex ? bIndex - aIndex : sortByLabels(a, b);
        }),
    [] // eslint-disable-line react-hooks/reactive-deps
  );
  return langs;
}

export default useLanguages;
