import React, { useState } from 'react';
import useTranslation from '@/hooks/useTranslation';
import { func, string } from 'prop-types';
import { ReactComponent as ArrowRight } from '@/images/right-arrow.svg';
import { ReactComponent as ArrowDown } from '@/images/down-arrow.svg';

function ShowCaseLink({ component: Component, label }) {
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(false);

  return (
    <li className="ShowCaseLinks__link">
      <button
        className="ShowCaseLinks__link--accordion"
        onClick={() => setIsSelected(!isSelected)}
        type="button"
      >
        <span>{t(label)}</span>
        <span>{isSelected ? <ArrowDown /> : <ArrowRight />}</span>
      </button>
      {isSelected && (
        <div>
          <Component t={t} className="p-t-s p-b-s" />
        </div>
      )}
    </li>
  );
}

ShowCaseLink.propTypes = {
  component: func.isRequired,
  label: string.isRequired,
};

export default ShowCaseLink;
