import React from 'react';
import { func, string } from 'prop-types';
import { Mutation } from 'react-apollo';
import { getBookmarks } from '@/api/Bookmark/queries';
import { toggleBookmark } from '@/api/Bookmark/mutations';
import { ReactComponent as Plus } from '@/images/plus.svg';
import { ReactComponent as CheckDouble } from '@/images/check-double.svg';
import InfiniteScroll from '@/components/InfiniteScroll';
import Button from '@/components/Button';
import { LIST_NAME_MANUAL } from '@/constants/list';
import './styles.scss';

function AddToLists({ t, id }) {
  return (
    <div className="AddToLists__wrapper">
      <InfiniteScroll
        className="AddToLists"
        query={getBookmarks}
        variables={{
          entity_id: id,
          entity_type: 'Company',
          kind: [LIST_NAME_MANUAL],
        }}
        useWindow={false}
        itemsPath="current_user.company.lists"
        renderItem={(list) => (
          <>
            <div>
              <h2>{list.name}</h2>
              <h3>
                {t('bookmarks.modal.add.addToLists.experts', {
                  count: list.favorites_entities.length,
                })}
              </h3>
            </div>
            <Mutation
              mutation={toggleBookmark}
              variables={{
                companyId: id,
                list,
              }}
            >
              {(mutate, { loading }) => (
                <Button
                  onClick={async () => {
                    if (loading) return;
                    await mutate({
                      refetchQueries: ['getBookmarks', 'getFavoritesEntities'],
                      awaitRefetchQueries: true,
                    });
                  }}
                  className="AddToLists__action"
                  variants={['small', list.included ? 'secondary' : 'grey']}
                  icon={list.included ? CheckDouble : Plus}
                  loading={loading}
                />
              )}
            </Mutation>
          </>
        )}
        noResultsText="bookmarks.modal.add.addToLists.empty"
      />
    </div>
  );
}

AddToLists.propTypes = {
  t: func.isRequired,
  id: string.isRequired,
};

export default AddToLists;
