export const SKILLS_NAME = 'skills_name';
export const COMPANY_SKILLS_NAME = 'company_skills_name';
export const PERSONAL_SKILLS_NAME = 'personal_skills_name';
export const COMPANY_PERSONAL_SKILLS_NAME = 'company_personal_skills_name';
export const OPTIONAL_SKILLS_NAME = 'optional_skills_name';
export const INDIVIDUAL_CERTIFICATION_SKILLS_NAME =
  'individual_certification_skills_name';
export const JOB_NAME = 'job_name';
export const ENTITLED = 'entitled';
export const COMPANY_JOB_NAME = 'company_job_name';
export const NAME = 'name';
export const LEGAL_FORM = 'legal_form';
export const EQUIPMENTS_NAME = 'equipments_name';
export const EQUIPMENTS = 'equipments';
export const BAR_CITY = 'bar_city';
export const COMPANY_VALUES_SKILLS = 'company_value_skills';
export const PROFILE_COMPANY_VALUES_SKILLS = 'profile_company_value_skills';
export const OPTIONAL_SKILLS = 'optional_skills';
export const TOOLS = 'tools';
export const COMPANY_TOOLS = 'company_tools';
export const REWARDS = 'rewards';
export const SECTOR = 'sector';
