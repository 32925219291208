import React from 'react';
import { node } from 'prop-types';
import Sentry from '@/helpers/sentry';
import Error from '@/components/Error';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    if (/^Loading [a-zA-Z]* chunk (\d)+ failed\./.test(error.message)) {
      window.location.reload();
    }

    Sentry.captureException(error, { extra: info });
  }

  render() {
    if (this.state.hasError) {
      return <Error statusCode={500} message="Oops !" />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
};

export default ErrorBoundary;
