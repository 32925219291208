import React from 'react';
import ProfileModal from '../../ProfileModal';
import { formatTags } from '@/components/Tags/helpers/handler';
import { PROFILE_KEYWORDS } from '@/constants/forms';
import { SKILL } from '@/constants/tagKind';
import useTranslation from '@/hooks/useTranslation';
import KeyWordsSection from '@/components/Sections/KeyWordsSection';

const KeyWordModal = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <ProfileModal
      title={t('company.modal.keywords.title')}
      form={PROFILE_KEYWORDS}
      formatValues={({ keywords }) => ({
        skills: formatTags(keywords),
        personal_skills: [],
      })}
      initialValues={{
        keywords: [],
      }}
      {...props}
    >
      <KeyWordsSection
        mode={props.mode}
        inlineDisplay
        kind={SKILL}
        withRating={false}
        title="company.modal.form.keywords.title"
      />
    </ProfileModal>
  );
};

export default KeyWordModal;
