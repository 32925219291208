import {
  isContractTypeFullTime,
  isContractTypeInternship,
  isContractTypeMissionOrFulltime,
  isContractTypeMissions,
  isContractTypeFixedTermContract,
  isContractTypeThesis,
  isContractTypeApprenticeship,
} from '@/selectors/contractTypes';
import { FORFEIT, TECHNICAL_ASSISTANCE, BPU } from '@/constants/quotationKind';

export default (contractTypes, salary, dailyRate, billings, budget) => {
  const fulltime = isContractTypeFullTime(contractTypes);
  const missionOrFulltime = isContractTypeMissionOrFulltime(contractTypes);
  const mission = isContractTypeMissions(contractTypes);

  return {
    salary:
      fulltime ||
      isContractTypeInternship(contractTypes) ||
      missionOrFulltime ||
      isContractTypeFixedTermContract(contractTypes) ||
      isContractTypeThesis(contractTypes) ||
      isContractTypeApprenticeship(contractTypes)
        ? salary
        : null,
    daily_rate:
      billings === TECHNICAL_ASSISTANCE && (missionOrFulltime || mission)
        ? dailyRate
        : null,
    budget:
      [FORFEIT, BPU].includes(billings) && (missionOrFulltime || mission)
        ? budget
        : null,
    billings: missionOrFulltime || mission ? billings : null,
    contract_type: contractTypes,
  };
};
