import { get } from 'lodash';

/**
 * Each equipment is associated to a quantity (named rating to follow the skills pattern)
 * This function loop inside the equipments and initiate keys('rating_id')
 * in the redux-form
 * @param equipments - Current equipments
 * @returns {}
 */
export function setEquipmentsInitialValues(equipments = []) {
  return equipments.reduce(
    (acc, currentEquipment) => ({
      ...acc,
      [`rating_${get(currentEquipment, 'id')}`]: get(
        currentEquipment,
        'rating'
      ),
    }),
    {}
  );
}
