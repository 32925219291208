import React from 'react';
import { func } from 'prop-types';
import {
  LANDING_CLIENT_BLOG,
  LANDING_MYKICKLOX,
  LANDING_SALARY_PORTAGE,
  FULLTIME_CONTRACT,
  MISSIONS_CONTRACT,
} from '@/constants/router';
import Section from '../Section';
import List from '../List';
import { MISSIONS } from '@/constants/router_base';

const links = [
  {
    id: 'fulltime_contract',
    label: 'footer.discover.link.fulltime_contract',
    href: FULLTIME_CONTRACT,
  },
  {
    id: 'missions_contract',
    label: 'footer.discover.link.missions_contract',
    href: MISSIONS_CONTRACT,
  },
  {
    id: 'mykicklox',
    label: 'footer.discover.link.mykicklox',
    href: LANDING_MYKICKLOX,
    target: '_blank',
  },
  {
    id: 'salary_portage',
    label: 'footer.discover.link.salary_portage',
    href: LANDING_SALARY_PORTAGE,
    target: '_blank',
  },
  {
    id: 'missions',
    label: 'footer.discover.link.missions',
    href: MISSIONS,
  },
  {
    id: 'client_blog',
    label: 'footer.discover.link.client_blog',
    href: LANDING_CLIENT_BLOG,
    target: '_blank',
  },
];

function Talents({ t }) {
  return (
    <Section name="Discover" title={t('footer.talents.title')}>
      <List items={links} t={t} />
    </Section>
  );
}

Talents.propTypes = {
  t: func.isRequired,
};

export default Talents;
