import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import { Mutation } from 'react-apollo';
import { deleteBookmark } from '@/api/Bookmark/mutations';
import Alert from '@/components/Alert';

function BookmarkDeleteModal({ t, close, id, refetchQueries, onOk }) {
  return (
    <Mutation mutation={deleteBookmark} variables={{ id }}>
      {(mutate, { loading }) => (
        <Alert
          type="warning"
          close={close}
          onOk={async () => {
            await mutate({
              refetchQueries,
              awaitRefetchQueries: true,
            });
            close();
            onOk();
          }}
          onCancel={close}
          disableActions={loading}
          closable={!loading}
          text={t('bookmarks.modal.delete.title')}
        />
      )}
    </Mutation>
  );
}

BookmarkDeleteModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  id: pp.string.isRequired,
  refetchQueries: pp.arrayOf(pp.string),
  onOk: pp.func,
};

BookmarkDeleteModal.defaultProps = {
  refetchQueries: [],
  onOk: _.noop,
};

export default BookmarkDeleteModal;
