import React, { useEffect, useRef, useState } from 'react';
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types';
import Downshift from 'downshift';
import cx from 'classnames';
import { find, get, isNull, isObject, isString } from 'lodash';
import { propType } from 'graphql-anywhere';
import { KEYCODES } from '@/constants/keyCodes';
import userFragment from '@/api/User/fragment';
import { generateQuery, queryParams } from '@/helpers/query';
import {
  focus,
  handleChange,
  handleInputBlur,
  handleInputFocus,
  handleInputKeydown,
  handleOuterClick,
  handleStateChange,
  handleRenderControl,
  handleRenderRefinements,
  handleRenderHistory,
  handleRenderSuggestionsList,
  handleRenderDropdown,
} from '@/components/Header/SearchBar/helpers/searchBarHandler';
import refinements from '@/components/Header/SearchBar/refinements';
import './styles.scss';

const SearchBar = (props) => {
  const inputRef = useRef(null);

  const { location, autoFocus, history, refine } = props;
  const [state, setState] = useState({
    value: queryParams(props.location.search, 'query') || '',
    displayHome: true,
    active: false,
  });

  useEffect(
    () => {
      if (autoFocus) focus(inputRef);
    },
    [autoFocus]
  );

  const searchBarhandleInputFocus = ({ openMenu }) => {
    handleInputFocus(openMenu, setState);
  };

  const searchBarhandleInputBlur = () => {
    handleInputBlur(setState);
  };

  const searchHandleOuterClick = () => {
    handleOuterClick(history, setState);
  };

  const handleInputChange = (e) => {
    const { value } = e.currentTarget;
    if (refine !== undefined) refine(value);
    setState((prevState) => ({
      ...prevState,
      displayHome: !value.trim(),
    }));
  };

  const searchHandleInputKeydown = (e, { closeMenu, highlightedIndex }) => {
    const { value } = e.target;
    const { keyCode } = e;
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory,
    } = props;

    handleInputKeydown(
      getSearchQueryURI(generateQuery({ query: value })),
      keyCode,
      value,
      closeMenu,
      highlightedIndex,
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      inputRef,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory
    );
  };

  const searchHandleChange = (item, cProps) => {
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory,
    } = props;
    const searchConfig = {
      pathname:
        item && item.link
          ? item.link
          : getSearchQueryURI(
              generateQuery({ query: cProps.itemToString(item) })
            ),
    };
    handleChange(
      item,
      cProps,
      currentUser,
      getSearchQueryURI,
      guestSearchCount,
      inputRef,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      searchConfig,
      createHistory
    );
  };

  const searchHandleStateChange = (changes) => {
    handleStateChange(changes, setState);
  };

  const handleSubmit = (value_submited, closeMenu, highlightedIndex) => {
    const value = isObject(value_submited)
      ? value_submited.name
      : value_submited;
    setState((prevState) => ({ ...prevState, active: true }));
    const keyCode = KEYCODES.enter;
    const {
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory,
    } = props;

    handleInputKeydown(
      getSearchQueryURI(generateQuery({ query: value })),
      keyCode,
      value,
      closeMenu,
      highlightedIndex,
      getSearchQueryURI,
      currentUser,
      guestSearchCount,
      inputRef,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue,
      createHistory
    );
  };

  const handleSubmitSearch = ({ closeMenu, highlightedIndex }) => {
    handleSubmit(state.value, closeMenu, highlightedIndex);
  };

  const onSubmitAllResult = ({ closeMenu, highlightedIndex }) => {
    handleSubmit('', closeMenu, highlightedIndex);
  };

  const renderControl = (cProps) => {
    const { active } = state;
    const { t, currentUser, noSuggestion } = props;
    const { inputValue } = cProps;
    const pathname = get(location, 'pathname');

    const currentIndex = currentUser
      ? find(refinements[currentUser.kind], { pathname }) || null
      : null;

    const label =
      inputValue && !active && currentIndex && !noSuggestion
        ? `${inputValue} · ${t(currentIndex.label)}`
        : inputValue;

    return handleRenderControl(
      cProps,
      active,
      t,
      currentUser,
      location,
      inputRef,
      searchBarhandleInputFocus,
      searchBarhandleInputBlur,
      handleInputChange,
      searchHandleInputKeydown,
      handleSubmitSearch,
      onSubmitAllResult,
      label
    );
  };

  const renderRefinements = (userRefinements, { inputValue }) => {
    const { t } = props;
    return handleRenderRefinements(userRefinements, inputValue, t);
  };

  const renderHistory = (userHistory, cProps) => {
    const { t } = props;
    return handleRenderHistory(userHistory, cProps, t);
  };

  const renderSuggestionsLists = (cProps) => {
    const { t, suggestions } = props;
    return handleRenderSuggestionsList(suggestions, t, cProps);
  };

  const renderDropdown = (cProps) => {
    const { searchHistory, t } = props;
    const { displayHome } = state;
    const children = <div>{renderSuggestionsLists(cProps)}</div>;
    return handleRenderDropdown(
      cProps,
      searchHistory,
      t,
      displayHome,
      renderRefinements,
      renderHistory,
      children
    );
  };

  const { className, noSuggestion } = props;
  const { value } = state;

  return (
    <Downshift
      selectedItem={value}
      options={props.hits}
      onStateChange={searchHandleStateChange}
      onChange={searchHandleChange}
      onOuterClick={searchHandleOuterClick}
      itemToString={(item) =>
        // eslint-disable-next-line
        isString(item) ? item : isNull(item) ? '' : item.name || item.context
      }
    >
      {(cProps) => (
        <div className={cx('SearchBar', className)}>
          {renderControl(cProps)}
          {!noSuggestion && renderDropdown(cProps)}
        </div>
      )}
    </Downshift>
  );
};

SearchBar.propTypes = {
  t: func.isRequired,
  refine: func.isRequired,
  hits: arrayOf(object).isRequired,
  suggestions: arrayOf(arrayOf(object)).isRequired,
  history: objectOf(any).isRequired,
  location: objectOf(any).isRequired,
  searchHistory: arrayOf(any),
  createHistory: func.isRequired,
  setStorageValue: func.isRequired,
  className: string,
  autoFocus: bool,
  currentUser: propType(userFragment),
  close: func,
  getSearchQueryURI: func,
  guestSearchCount: func,
  openModal: func,
  noSuggestion: bool,
};

SearchBar.defaultProps = {
  searchHistory: [],
  getSearchQueryURI: Function.prototype,
  guestSearchCount: Function.prototype,
  openModal: Function.prototype,
  close: Function.prototype,
  className: null,
  autoFocus: false,
  currentUser: null,
  noSuggestion: false,
};

export default SearchBar;
