import React from 'react';
import { func } from 'prop-types';
import {
  lightAvailabilities,
  fullAvailabilities,
} from '@/constants/fields/availabilities';
import { presence, date } from '@/helpers/validation';
import { today, oneYearFromToday } from '@/helpers/date';
import {
  RequiredField,
  RfChoices,
  RfDate,
  RfHelper,
} from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import FormSection from '@/components/FormSection';
import { translateArrayLabel } from '@/helpers/i18n';
import { UNAVAILABLE } from '@/constants/fields/searchStatus';
import config from '@/_config';

const AvailabilitySection = ({ t }) => {
  const handleChoices = () => {
    if (config.company.contractTypeIsAlwaysMissions) return lightAvailabilities;
    return fullAvailabilities;
  };

  return (
    <RfHelper values={['search_status']}>
      {({ search_status }) => {
        if (search_status === UNAVAILABLE) return null;
        return (
          <FormSection title={t('company.modal.modalities.availability.title')}>
            <RequiredField
              type="radioGroup"
              name="availability"
              component={RfChoices}
              checkbox={LabelledRadio}
              label={t('company.modal.modalities.availability.label')}
              cbProps={translateArrayLabel(t, handleChoices())}
              withSeparators={false}
              renderForValue={{
                precise: (
                  <RequiredField
                    component={RfDate}
                    name="available_at"
                    className="tablet--one-whole one-half"
                    inputProps={{
                      label: t(
                        'company.modal.modalities.availability.availableAt.label'
                      ),
                      placeholder: t('fields.date.format.placeholder'),
                    }}
                    dayPickerProps={{
                      disabledDays: {
                        before: today,
                        after: oneYearFromToday,
                      },
                    }}
                    validate={[
                      presence(),
                      date({
                        '>=': today,
                        '<=': oneYearFromToday,
                        format: date,
                      }),
                    ]}
                  />
                ),
              }}
            />
          </FormSection>
        );
      }}
    </RfHelper>
  );
};

AvailabilitySection.propTypes = {
  t: func.isRequired,
};

export default AvailabilitySection;
