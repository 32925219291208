export const FULLTIME_CONTRACT = 'fulltime_contract';
export const INTERNSHIP = 'internship';
export const APPRENTICESHIP = 'apprenticeship';
export const MISSIONS = 'missions';
export const MISSION_OR_FULLTIME = 'missions_or_fulltime_contract';
export const EXPERT = 'expert';
export const SERVICE = 'service';
export const PRODUCT = 'product';
export const FIXED_TERM_CONTRACT = 'fixed_term_contract';
export const THESIS = 'thesis';
