import config from '@/_config';

export function handleVariables(cv_id, settings, linkedin_vanity_name) {
  if (config.company.extendedCvUpload && settings) {
    return {
      cv_id,
      update_profile: settings.includes('update_profile'),
      display_cv_on_profile: settings.includes('display_cv_on_profile'),
      linkedin_vanity_name,
    };
  }
  if (config.company.updateProfileCvUpload && settings) {
    return {
      cv_id,
      update_profile: settings.includes('update_profile'),
      display_cv_on_profile: false,
      linkedin_vanity_name,
    };
  }
  return {
    cv_id,
    update_profile: true,
    display_cv_on_profile: false,
    linkedin_vanity_name,
  };
}

export function onError(showFlash, callback) {
  showFlash({
    kind: 'error',
    text: 'postSignup.cvParsing.error',
  });
  setTimeout(() => callback(), 2000);
}
