import React, { useState } from 'react';
import FormSection from '@/components/FormSection';
import Modal from '@/components/Modal';
import uuid from 'uuid/v1';
import { any, bool, func, objectOf, string } from 'prop-types';
import {
  RequiredField,
  Field,
  RfText,
  RfPhone,
  RfChoices,
  RfSelect,
  RfHelper,
  RfJob,
  RfLocation,
  RfSlider,
  RfLabelledToggle,
} from '@/containers/ReduxForm';
import {
  length,
  presence,
  modifyRange,
  phone as phoneValidation,
  email as emailValidation,
} from '@/helpers/validation';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import goals from '@/constants/fields/goals';
import { first, get, isEmpty, last } from 'lodash';
import { MISSIONS } from '@/constants/contractTypes';
import { isContractTypeMissions } from '@/selectors/contractTypes';
import { getCompanyTypes } from '@/helpers/company';
import { translateArrayLabel } from '@/helpers/i18n';
import config from '@/_config';
import CvParsing from '@/components/CvParsing/CvParsing';
import { ADD_TO_CLIENT_POOL } from '@/constants/source';

const generatedAutocomplete = uuid();

const AddExpertPoolModal = ({
  t,
  close,
  handleSubmit,
  currentUser,
  addToClientPool,
  mission_id,
  send_invitation,
  showFlash,
  ...props
}) => {
  const { values: formValues } = props;
  const [submitting, setSubmitting] = useState(false);

  async function onSubmit({
    first_name,
    last_name,
    phone,
    email,
    contract_type,
    company_kind_main,
    company_name,
    location_place,
    job_name,
    daily_rate,
    salary,
    invitation_to_register,
    cv,
    linkedin_vanity_name,
  }) {
    setSubmitting(true);
    const { data } = await addToClientPool({
      variables: {
        first_name,
        last_name,
        phone,
        email,
        contract_types: [contract_type],
        kind: company_kind_main,
        name: company_name,
        location_place: get(location_place, 'address'),
        job_name,
        daily_rate_min: first(daily_rate),
        daily_rate_max: last(daily_rate),
        salary_min: first(salary),
        salary_max: last(salary),
        invitation_to_register_from_client_pool: invitation_to_register,
        attachment_id: get(first(cv), 'id'),
        source: ADD_TO_CLIENT_POOL,
        linkedin_vanity_name,
        send_invitation,
        mission_id,
      },
      refetchQueries: ['getBookmark'],
    });

    if (data) {
      close();
      showFlash({
        kind: 'success',
        text: `add_expert_pool.modal.${
          get(data, 'addToClientPoolCompany.is_user_already_created')
            ? 'with_user_already_created.'
            : ''
        }success.text`,
      });
    }
  }

  function formatOptions(company_contract_types) {
    const options = { selectable: true };

    if (!isContractTypeMissions(company_contract_types)) {
      options.solo = true;
    }
    const mainCompanyTypes = [...getCompanyTypes(null, options)];
    return translateArrayLabel(t, mainCompanyTypes);
  }

  const settingsValidator = (settingsValue) =>
    !isEmpty(formValues.cv) &&
    (!settingsValue || (settingsValue && !settingsValue.length))
      ? t('company.modal.cv.settings.error')
      : undefined;

  return (
    <Modal
      name="AddExpertPool"
      className="AddExportPoolModal"
      title={t('add_expert_pool.modal.title')}
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      okText={t('add_expert_pool.modal.button.ok.text')}
      disableActions={submitting}
      closable={!submitting}
    >
      <FormSection title={t('add_expert_pool.modal.modalities.title')}>
        <div className="grid">
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="first_name"
              component={RfText}
              label={t('offline.apply.modal.form.first_name.label')}
              validate={[length({ min: 2 })]}
              max={500}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="last_name"
              component={RfText}
              label={t('offline.apply.modal.form.last_name.label')}
              validate={[length({ min: 2 })]}
              max={500}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <Field
              component={RfPhone}
              id="phone"
              name="phone"
              autoComplete="tel"
              label={t('offline.apply.modal.form.phone.label')}
              validate={[phoneValidation()]}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              name="email"
              type="email"
              component={RfText}
              validate={[emailValidation()]}
              label={t('offline.apply.modal.form.email.label')}
              className="m-b-l"
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <Field
              id="location_place"
              name="location_place"
              component={RfLocation}
              autoComplete={generatedAutocomplete}
              searchOptions={{ types: ['(cities)'] }}
              inputProps={{
                label: t(
                  `add_expert_pool.modal.modalities.location_place.label`
                ),
                placeholder: t('locations.input.placeholder'),
              }}
              withDetails
            />
          </div>
          <div className="grid__item large--one-whole one-half">
            <Field
              component={RfJob}
              name="job_name"
              inputProps={{
                label: t('add_expert_pool.modal.modalities.job_name.label'),
                placeholder: t('postSignup.job.placeholder'),
                autoComplete: 'organization-title',
              }}
            />
          </div>
        </div>
      </FormSection>
      <FormSection
        title={t('add_expert_pool.modal.modalities.contract_type.title')}
      >
        <RequiredField
          type="radioGroup"
          name="contract_type"
          component={RfChoices}
          checkbox={LabelledRadio}
          label={t(
            'add_expert_pool.modal.modalities.modalities.contract_type.label'
          )}
          cbProps={goals
            .filter((goal) => get(goal, 'choiceVisibility', true) === true)
            .map((goal) => ({
              ...goal,
              label: t(goal.label),
              icon: goal.icon,
            }))}
          withSeparators={false}
        />
      </FormSection>
      <FormSection
        title={t('add_expert_pool.modal.modalities.company_type.title')}
      >
        <div className="grid">
          <div className="grid__item large--one-whole one-half">
            <RequiredField
              component={RfSelect}
              searchable={false}
              label={t('add_expert_pool.modal.modalities.company_type.label')}
              placeholder={t(
                'add_expert_pool.modal.modalities.company_type.placeholder'
              )}
              name="company_kind_main"
              options={formatOptions(MISSIONS)}
              id="companyType"
              data-testid="companyType"
            />
          </div>
          {!config.company.expertCompanyKindIsSolo && (
            <RfHelper values={['company_kind_main', 'company_name_choice']}>
              {({ company_kind_main }) => (
                <div className="grid__item large--one-whole one-half m-t-l">
                  <Field
                    name="company_name"
                    label={t(
                      'add_expert_pool.modal.modalities.companyName.label'
                    )}
                    component={RfText}
                    disabled={company_kind_main === 'freelance'}
                    required={company_kind_main !== 'freelance'}
                    validate={[
                      presence({
                        if: (values) =>
                          values.company_kind_main !== 'freelance',
                      }),
                    ]}
                  />
                </div>
              )}
            </RfHelper>
          )}
        </div>
      </FormSection>
      <FormSection
        title={t('add_expert_pool.modal.modalities.remuneration.title')}
      >
        <RequiredField
          component={RfSlider}
          name="daily_rate"
          key="daily_rate"
          className="m-b-l"
          domain={[config.company.dailyRate.min, config.company.dailyRate.max]}
          step={10}
          label={t('add_expert_pool.modal.modalities.remuneration.tjm.label')}
          helper={t('add_expert_pool.modal.modalities.remuneration.tjm.helper')}
          // eslint-disable-next-line no-unused-vars
          valueKey={([_, max]) => {
            if (max < config.company.dailyRate.max) {
              return 'company.modal.modalities.remuneration.daily_rate.value';
            }
            return 'company.modal.modalities.remuneration.daily_rate.value.max';
          }}
          validate={[
            modifyRange({
              min: config.company.dailyRate.min,
              max: config.company.dailyRate.max,
            }),
          ]}
        />
        <RequiredField
          component={RfSlider}
          name="salary"
          domain={[config.company.salary.min, config.company.salary.max]}
          step={config.company.salary.step}
          label={t(
            'add_expert_pool.modal.modalities.remuneration.salary.label'
          )}
          helper={t(
            'add_expert_pool.modal.modalities.remuneration.salary.helper'
          )}
          valueKey="company.modal.modalities.remuneration.salary.value"
          validate={[
            modifyRange({
              min: config.company.salary.min,
              max: config.company.salary.max,
            }),
          ]}
        />
      </FormSection>
      <FormSection title={t('add_expert_pool.modal.modalities.cv.title')}>
        <CvParsing
          validator={settingsValidator}
          currentUser={currentUser}
          showWarningText={false}
          cvDescriptionTranslation="add_expert_pool.modal.modalities.cv.description"
          showUpdateProfileCheckbox={false}
          showLinkedinTitle="add_expert_pool.modal.modalities.linkedin.title"
          mission_id={mission_id}
        />
      </FormSection>
      <Field
        component={RfLabelledToggle}
        name="invitation_to_register"
        type="checkbox"
        label={t(
          `add_expert_pool.modal.modalities.invitation_to_register.title`
        )}
        className="m-l-l"
      />
    </Modal>
  );
};

AddExpertPoolModal.propTypes = {
  addToClientPool: func.isRequired,
  currentUser: objectOf(any).isRequired,
  t: func.isRequired,
  close: func.isRequired,
  handleSubmit: func.isRequired,
  values: objectOf(any).isRequired,
  mission_id: string,
  send_invitation: bool,
  showFlash: func.isRequired,
};

AddExpertPoolModal.defaultProps = {
  mission_id: null,
  send_invitation: false,
};

export default AddExpertPoolModal;
