import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfArea, RfChoices } from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import { MODERATION } from '@/components/Contract/constants/moderationFields';
import { string } from 'prop-types';
import { CREATE } from '@/components/Contract/constants/modalMaps';

function ModerationForm({ context }) {
  const { t } = useTranslation();

  return (
    <div>
      <p className="fw-bold">{t('milestone.approbation.acceptance')}</p>
      <RequiredField
        component={RfChoices}
        checkbox={LabelledRadio}
        name="approbation"
        type="radioGroup"
        cbProps={MODERATION.map((p) => ({
          ...p,
          label: t(`${p.label}.${context}`),
        }))}
        renderForValue={{
          disapprove: (
            <RequiredField
              name="refusal_reason"
              component={RfArea}
              label={t('milestone.refusal.placeholder')}
            />
          ),
        }}
      />
    </div>
  );
}

ModerationForm.propTypes = {
  context: string,
};

ModerationForm.defaultProps = {
  context: CREATE,
};

export default ModerationForm;
