export const KEYCODES = {
  enter: 13,
  escape: 27,
  arrowLeft: 37,
  arrowUp: 38,
  arrowRight: 39,
  arrowDown: 40,
  keyK: 75,
  keyM: 77,
};
