import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from 'react-apollo';
import apolloClient from '@/helpers/apollo';
import store from '@/redux/store';
import i18n from '@/i18n';

const render = (Component) => {
  ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <Component />
        </I18nextProvider>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

export default render;
