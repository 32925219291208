import React, { useState } from 'react';
import cx from 'classnames';
import { any, func, number, objectOf, string, oneOf } from 'prop-types';
import { get } from 'lodash';
import { Query } from 'react-apollo';
import { scrollTop } from '@/helpers/scroll';
import { pagination } from '@/helpers/pagination';
import LoadingCircle from '@/components/LoadingCircle';
import Result from '@/components/InfiniteScroll/Result';
import getCounter from '@/helpers/collections';
import './styles.scss';

function Pagination({
  query,
  queryParams,
  variables,
  offset,
  limit,
  itemsPath,
  renderItem,
  noResultsText,
  onFinish,
  layout,
}) {
  const [currentPage, setCurrentPage] = useState(0);

  function handleClick(fetchMore, index) {
    setCurrentPage(index);
    return fetchMore({
      variables: {
        offset: index * limit,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const result = { ...fetchMoreResult };
        return result;
      },
    });
  }

  const loader = (
    <div className="Pagination__loader" key="loader">
      <LoadingCircle />
    </div>
  );

  return (
    <div className={`Pagination__container--${layout}`}>
      {query && (
        <Query
          query={query}
          variables={{
            offset,
            limit,
            ...variables,
            ...queryParams,
          }}
        >
          {({ data, loading, fetchMore }) => {
            const items = get(data, itemsPath, []);
            const empty = !items.length;
            if (loading) return loader;
            if (onFinish && !loading) onFinish(data);
            const itemsCount = getCounter(data)[itemsPath];
            const nbItems = items.length;
            const nbPages = Math.ceil(itemsCount / limit);
            return (
              <>
                {empty && (
                  <Result
                    className="Pagination__result"
                    i18nKey={noResultsText}
                    count={nbItems}
                  />
                )}

                {!empty && (
                  <>
                    <ul className={`Pagination__results--${layout}`}>
                      {items.map((item) => (
                        <li
                          className={`Pagination__results__item--${layout}`}
                          key={item.id}
                        >
                          {renderItem(item, data)}
                        </li>
                      ))}
                    </ul>
                    {nbPages !== 1 && (
                      <div className="Pagination">
                        <ul className="Pagination__list">
                          {pagination(currentPage, nbPages).map(
                            (page, index) => (
                              <li
                                key={page}
                                className={cx('Pagination__list-item', {
                                  'Pagination__list-item--selected':
                                    currentPage === index,
                                })}
                              >
                                {page === '...' ? (
                                  <span>{page}</span>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => {
                                      scrollTop();
                                      handleClick(fetchMore, index);
                                    }}
                                  >
                                    {page}
                                  </button>
                                )}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                )}
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
}

Pagination.propTypes = {
  query: objectOf(any),
  queryParams: objectOf(any),
  variables: objectOf(any),
  offset: number,
  limit: number,
  itemsPath: string.isRequired,
  renderItem: func.isRequired,
  noResultsText: string.isRequired,
  onFinish: func,
  layout: oneOf(['list', 'grid']),
};

Pagination.defaultProps = {
  query: null,
  queryParams: {},
  variables: null,
  offset: 0,
  limit: 10,
  onFinish: null,
  layout: 'list',
};

export default Pagination;
