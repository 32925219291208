import React, { useState } from 'react';
import { get } from 'lodash';
import { objectOf, any } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { formatDate } from '@/helpers/date';
import Files from '@/components/Files';
import { ReactComponent as BracketUp } from '@/images/chevron-up.svg';
import './styles.scss';

function FileCard({ file }) {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const status = get(file, 'status', 'pending');
  const description = get(file, 'description');
  const refusedReason = get(file, 'refused_reason');

  function handleCollapse() {
    setIsCollapsed((prevState) => !prevState);
  }

  return (
    <div className="FileCard" key={file.id}>
      <div className="FileCard__top">
        <div>{formatDate(get(file, 'created_at'))}</div>
        <div>
          <span className={`FileCard__status FileCard__status--${status}`}>
            {t(`settings.documents.file.status.${status}`)}
          </span>
        </div>
        <div>{get(file, 'issuing_agency')}</div>
        <div
          className={`FileCard__toggle-button ${isCollapsed ? 'active' : ''}`}
          onClick={handleCollapse}
          role="button"
          tabIndex="0"
        >
          <BracketUp />
        </div>
      </div>
      {isCollapsed && (
        <div className="FileCard__bottom">
          {refusedReason && (
            <>
              <div className="m-b-xs fw-bold mred">
                {t('settings.documents.file.show_file.refused_reason.label')}
              </div>
              <div className="m-b-m mred">{refusedReason}</div>
            </>
          )}
          <div className="m-b-xs fw-bold">
            {t('settings.documents.file.show_file.issue_date.label')}
          </div>
          <div className="m-b-m">{formatDate(get(file, 'issue_date'))}</div>
          {get(file, 'attachments') && (
            <div className="m-b-s">
              <div className="m-b-xs fw-bold">
                {t('company.modal.collaborator.candidate.attachments.label')}
              </div>
              <Files files={get(file, 'attachments')} />
            </div>
          )}
          {description && (
            <>
              <div className="m-b-xs fw-bold">
                {t('settings.document.description.label')}
              </div>
              <div>{description}</div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

FileCard.propTypes = {
  file: objectOf(any).isRequired,
};

export default FileCard;
