import React from 'react';
import pp from 'prop-types';
import Field from '../Field';
import PasswordInput from './PasswordInput';

const PasswordField = ({ label, ...props }) => (
  <Field
    {...props}
    render={({ getInputProps }) => (
      <PasswordInput {...getInputProps({ label })} />
    )}
  />
);

PasswordField.propTypes = {
  label: pp.string,
};

PasswordField.defaultProps = {
  label: null,
};

export default PasswordField;
