import React, { useContext } from 'react';
import NavCounter from '@/components/NavCounter';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import { CLIENT, EXPERT, MIXED } from '@/constants/userKind';

function ContractCount() {
  const userContext = useContext(UserContext);
  const user = new User(userContext);

  const countMap = {
    [EXPERT]: 'expert_pending_actions_contracts_count',
    [CLIENT]: 'client_pending_actions_contracts_count',
    [MIXED]: [
      'expert_pending_actions_contracts_count',
      'client_pending_actions_contracts_count',
    ],
  };

  return <NavCounter name={countMap[user.get('kind')]} />;
}

export default ContractCount;
