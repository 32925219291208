import _ from 'lodash';

const getCustomFieldValue = (customFields = [], key) =>
  _.get(_.find(customFields, { key }), 'value');

export const getBusinessEntity = (customFields) =>
  getCustomFieldValue(customFields, 'business_entity');

export const getLegalEntity = (customFields) =>
  getCustomFieldValue(customFields, 'legal_entity');

// EGIS
export const getArea = (customFields) =>
  getCustomFieldValue(customFields, 'area');

export const getVisitDate = (customFields) =>
  getCustomFieldValue(customFields, 'visit_date');
