import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import './styles.scss';

function DocumentHeader() {
  const { t } = useTranslation();

  return (
    <header className="DocumentHeader">
      <div>{t('settings.documents.name')}</div>
      <div>{t('settings.documents.kind_name')}</div>
      <div>{t('settings.documents.status')}</div>
    </header>
  );
}

export default DocumentHeader;
