import React, { useState, useContext, useEffect, useCallback } from 'react';
import { func, node, bool, string, arrayOf } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get, identity, isEmpty } from 'lodash';
import config from '@/_config';
import userFragment from '@/api/User/fragment';
import { queryParams } from '@/helpers/query';
import { getProfileRoute } from '@/helpers/router';
import { ReactComponent as Trash } from '@/images/trash.svg';
import Button from '@/components/Button';
import Modal from '@/components/Modal';
import { CREATE, UPDATE, DELETE } from '@/constants/modes';
import UserModel from '@/models/User';
import UserContext from '@/permissions/contexts/UserContext';

function ProfileModal(props) {
  const {
    t,
    children,
    close,
    edit,
    handleSubmit,
    deletable,
    formatValues,
    submitting,
    ...otherProps
  } = props;
  const [loadedMutation, setLoadedMutation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [values, setValues] = useState({});
  const contextUser = useContext(UserContext);
  const user = new UserModel(contextUser);

  const closeAndRedirect = () => {
    const { location, modalId } = props;
    props.close();

    const editParams = queryParams(location.search, 'edit');
    if (editParams === modalId) props.history.push(getProfileRoute());
  };

  const mutate = useCallback(async (action, variables) => {
    setLoadedMutation(false);
    const { id, refetchQueries } = props;
    if (!props[action]) {
      return null;
    }
    await props[action]({
      refetchQueries: config.global.showRelevance
        ? [...refetchQueries, 'getRelevance']
        : refetchQueries,
      awaitRefetchQueries: true,
      variables: {
        entity_id: get(user, 'company.id'),
        id,
        action,
        ...variables,
      },
    });
    return closeAndRedirect();
  });

  useEffect(
    () => {
      if (loadedMutation && !isEmpty(values)) {
        setSubmitted(true);
        mutate(edit ? UPDATE : CREATE, formatValues(values));
      }
    },
    [edit, formatValues, loadedMutation, mutate, values]
  );

  const submitFn = (valuesFn) => {
    setLoadedMutation(true);
    setValues(valuesFn);
  };

  const handleDeleteClick = () => {
    if (edit) mutate(DELETE, {});
  };

  const handleClose = () => {
    const { dirty } = props;
    if (dirty) {
      props.openModal('alert', {
        type: 'warning',
        text: t('modal.confirmation'),
        onOk: () => {
          props.closeModal('alert');
          closeAndRedirect();
        },
        onCancel: () => {
          props.closeModal('alert');
        },
      });
    } else {
      closeAndRedirect();
    }
  };

  return (
    <Modal
      {...otherProps}
      close={handleClose}
      onCancel={handleClose}
      onOk={handleSubmit(submitFn)}
      disableActions={submitting || submitted}
      closable={!submitting}
      leftAction={
        edit &&
        deletable && (
          <Button
            variants={['link']}
            icon={Trash}
            disabled={loadedMutation}
            onClick={handleDeleteClick}
          >
            {t('modal.delete')}
          </Button>
        )
      }
    >
      {children}
    </Modal>
  );
}

ProfileModal.propTypes = {
  t: func.isRequired,
  openModal: func.isRequired,
  closeModal: func.isRequired,
  children: node.isRequired,
  close: func.isRequired,
  edit: bool,
  deletable: bool,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  dirty: bool.isRequired,
  formatValues: func,
  id: string,
  modalId: string.isRequired,
  currentUser: propType(userFragment).isRequired,
  refetchQueries: arrayOf(string),
};

ProfileModal.defaultProps = {
  edit: false,
  deletable: true,
  formatValues: identity,
  id: null,
  refetchQueries: ['getCompany'],
};

export default ProfileModal;
