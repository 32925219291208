import { number, shape, string } from 'prop-types';

export const fileTypes = {
  __typename: string,
  download_url: string,
  file_uuid: string,
  id: string,
  mime_type: string,
  name: string,
  read_url: string,
  size: number,
  updated_at: string,
};

export const fileType = shape(fileTypes);
