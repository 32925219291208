import React from 'react';
import { string, bool, number } from 'prop-types';
import { lengthSkills } from '@/helpers/validation';
import { CREATE } from '@/constants/modes';
import { RfTags } from '@/containers/ReduxForm';
import TagsContext from '@/components/Tags/TagsContext';
import SectionField from '@/components/SectionField';
import FormSection from '@/components/FormSection';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';

/**
 * KeyWordsSection
 *
 *    - Display @/components/Tags components
 *
 * Often used for :
 *     - Section in Profile Feature
 *     - Section in Mission Feature
 *     - Section in Post Signup Feature
 */
const KeyWordsSection = ({
  mode,
  withRating,
  withCreatable,
  inlineDisplay,
  kind,
  minLength,
  title,
  required,
  name,
}) => (
  <TagsContext.Provider
    value={{
      mode,
      withCreatable,
      withRating,
      inlineDisplay,
      kind,
      max: minLength,
      highlightMaxValues: 0,
    }}
  >
    <FormSection>
      <SectionField
        id="keywords"
        name={name}
        component={RfTags}
        title={title}
        helperText="keywords.helper"
        required={required}
        {...getSkillsValidationAndExtraProps([
          lengthSkills({ min: required ? minLength : 0 }),
        ])}
      />
    </FormSection>
  </TagsContext.Provider>
);

KeyWordsSection.propTypes = {
  mode: string,
  withRating: bool.isRequired,
  withCreatable: bool.isRequired,
  inlineDisplay: bool.isRequired,
  kind: string.isRequired,
  minLength: number,
  title: string,
  required: bool,
  name: string,
};

KeyWordsSection.defaultProps = {
  mode: CREATE,
  minLength: 0,
  title: '',
  required: false,
  name: 'keywords',
};

export default KeyWordsSection;
