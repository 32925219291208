import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import { State } from 'react-automata';
import mediumZoom from 'medium-zoom';
import { ReactComponent as ImagePlaceholder } from '@/images/image.svg';
import './styles.scss';

class Img extends React.Component {
  image = null;

  componentDidMount() {
    if (this.props.zoomable) {
      this.zoom = mediumZoom();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.props.transition('REFRESH');
    }
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.src = '';
      this.image.onload = null;
      this.image.onerror = null;
    }
  }

  attachZoom = (image) => {
    this.zoom.attach(image);
  };

  beforeLoad = () => {
    if (!this.props.src) {
      this.props.transition('NO_FILE');
    } else {
      this.props.transition('FETCH');
    }
  };

  loadImage = () => {
    const { src, crossOrigin } = this.props;
    this.image = new Image();
    this.image.onload = () => {
      this.props.transition('SUCCESS');
    };
    this.image.onerror = () => {
      this.props.transition('FAILURE');
    };
    if (crossOrigin) {
      this.image.crossOrigin = crossOrigin;
    }
    this.image.src = src;
  };

  render() {
    const {
      children,
      src,
      alt,
      className,
      crossOrigin,
      zoomable,
      machineState,
    } = this.props;

    const loading = machineState.value === 'loading';
    return (
      <>
        <State is={['empty', 'error']}>
          <>
            <div className={cx('Img', 'Img--placeholder', className)}>
              <ImagePlaceholder />
            </div>
            {children}
          </>
        </State>
        <State is={['loading', 'fetched']}>
          <>
            <img
              src={src}
              alt={alt}
              className={cx('Img', { 'Img--loading': loading }, className)}
              crossOrigin={crossOrigin}
              {...zoomable && !loading && { ref: this.attachZoom }}
            />
            {children}
          </>
        </State>
      </>
    );
  }
}

Img.propTypes = {
  transition: pp.func.isRequired,
  children: pp.node,
  className: pp.string,
  src: pp.string,
  alt: pp.string.isRequired,
  crossOrigin: pp.string,
};

Img.defaultProps = {
  children: null,
  className: null,
  src: null,
  crossOrigin: null,
};

export default Img;
