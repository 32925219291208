import React from 'react';
import pp from 'prop-types';
import arrayMove from 'array-move';

const withMultiple = (Downshift) =>
  class MultiDownshift extends React.Component {
    static propTypes = {
      defaultSelectedItems: pp.arrayOf(pp.any),
      onSelect: pp.func,
      onChange: pp.func,
      render: pp.func,
      children: pp.func.isRequired,
    };

    static defaultProps = {
      defaultSelectedItems: [],
      onSelect: null,
      onChange: null,
      render: null,
    };

    state = { selectedItems: this.props.defaultSelectedItems || [] };

    getRemoveButtonProps = ({ onClick, item, ...props } = {}) => ({
      onClick: (e) => {
        if (onClick) onClick(e);
        e.stopPropagation();
        this.removeItem(item);
      },
      ...props,
    });

    getStateAndHelpers(downshift) {
      const { selectedItems } = this.state;
      const { getRemoveButtonProps, removeItem, swapItems } = this;
      return {
        getRemoveButtonProps,
        removeItem,
        selectedItems,
        swapItems,
        ...downshift,
      };
    }

    removeItem = (item) => {
      const { selectedItems } = this.state;
      const { itemToString } = this.props;
      const items = selectedItems.filter(
        (i) => itemToString(i) !== itemToString(item)
      );
      this.setState(
        {
          selectedItems: items,
        },
        () => {
          this.handleChange(items);
        }
      );
    };

    swapItems = (index1, index2) => {
      const { selectedItems } = this.state;
      const items = arrayMove(selectedItems, index1, index2);
      this.setState(
        {
          selectedItems: items,
        },
        () => {
          this.handleChange(items);
        }
      );
    };

    handleChange = (items, ...args) => {
      if (this.props.onChange) {
        this.props.onChange(items, ...args);
      }
    };

    handleSelect = (items, ...args) => {
      if (this.props.onSelect) {
        this.props.onSelect(items, ...args);
      }
    };

    handleSelection = (selectedItem, downshift) => {
      if (
        this.state.selectedItems
          .map(downshift.itemToString)
          .includes(downshift.itemToString(selectedItem))
      ) {
        this.removeItem(selectedItem, this.getStateAndHelpers(downshift));
      } else {
        this.addSelectedItem(selectedItem, this.getStateAndHelpers(downshift));
      }
    };

    stateReducer = (state, changes) => {
      switch (changes.type) {
        case Downshift.stateChangeTypes.clickItem:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true,
          };
        case Downshift.stateChangeTypes.keyDownEnter:
        default:
          return changes;
      }
    };

    addSelectedItem(item, ...args) {
      const { selectedItems } = this.state;
      const items = [...selectedItems, item];
      this.setState(
        {
          selectedItems: items,
        },
        () => {
          this.handleSelect(items, ...args);
          this.handleChange(items, ...args);
        }
      );
    }

    render() {
      const {
        render,
        children = render,
        defaultSelectedItems,
        ...props
      } = this.props;
      return (
        <Downshift
          {...props}
          stateReducer={this.stateReducer}
          onChange={this.handleSelection}
          selectedItem={null}
        >
          {(downshift) => children(this.getStateAndHelpers(downshift))}
        </Downshift>
      );
    }
  };

export default withMultiple;
