import { EMPLOYEE, SUBCONTRACTOR } from '@/constants/outsourcings';

const employee = {
  id: EMPLOYEE,
  value: EMPLOYEE,
  label: 'fields.outsourcingtypes.employee',
};

const subcontractor = {
  id: SUBCONTRACTOR,
  value: SUBCONTRACTOR,
  label: 'fields.outsourcingtypes.subcontractor',
};

export const outsourcings = [employee, subcontractor];
