import React, { useState } from 'react';
import { objectOf, any, func } from 'prop-types';
import { get } from 'lodash';
import i18next from 'i18next';
import useTranslation from '@/hooks/useTranslation';
import {
  RequiredField,
  Field,
  RfText,
  RfDate,
  RfFiles,
  RfArea,
} from '@/containers/ReduxForm';
import { today } from '@/helpers/date';
import { date, length } from '@/helpers/validation';
import Modal from '@/components/Modal';
import FileHeader from '@/scenes/Settings/components/Documents/modals/FileHeader';
import InformationMessage from '@/components/InformationMessage';
import './styles.scss';

const AddFileModal = ({
  document,
  handleSubmit,
  close,
  createDocumentFile,
}) => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);
  const postSignupDisplay = get(document, 'post_signup_display');

  const onSubmit = async ({ attachments: attachmentsUploaded, ...values }) => {
    setDisable(true);
    await createDocumentFile({
      variables: {
        ...values,
        document_id: get(document, 'id'),
        attachment_ids: attachmentsUploaded.map((a) => a.id),
      },
      refetchQueries: ['getDocuments'],
      awaitRefetchQueries: true,
    });
    close();
    setDisable(false);
  };

  return (
    <Modal
      name="add_file"
      form="addFile"
      close={close}
      onCancel={close}
      onOk={handleSubmit(onSubmit)}
      disableOk={disable}
    >
      <div className="AddFile">
        <FileHeader document={document} />
        <InformationMessage className="m-b-m">
          <p>
            {postSignupDisplay
              ? t('settings.documents.modal.bar.information_message')
              : t('settings.documents.modal.information_message')}
          </p>
        </InformationMessage>
        <div className="grid">
          <div className="grid__item tablet--one-whole one-half m-b-m">
            <RequiredField
              id="issuing_agency"
              name="issuing_agency"
              component={RfText}
              label={
                postSignupDisplay
                  ? t('settings.documents.bar.file.organization.label')
                  : t('settings.documents.file.organization.label')
              }
            />
          </div>
          <div className="grid__item tablet--one-whole one-half m-b-m">
            <RequiredField
              id="issue_date"
              name="issue_date"
              component={RfDate}
              label={t('settings.documents.file.issue_date.label')}
              dayPickerProps={{
                disabledDays: { after: today },
              }}
              validate={[
                date({
                  '<=': today,
                }),
              ]}
              locale={i18next.language}
            />
          </div>
          <div className="grid__item one-whole m-b-m">
            <div className="m-b-s">
              {t('settings.documents.modal.attachments')}
            </div>
            <RequiredField
              component={RfFiles}
              multiple
              id="attachments"
              name="attachments"
              text={t('contract.milestone.attachment.text.deliver')}
              infoText={t('contract.milestone.attachment.infoText.deliver')}
              rejectText={t(
                'contract.milestone.attachment.infoText.rejectText.deliver'
              )}
              t={t}
            />
          </div>
          <div className="grid__item one-whole m-b-m">
            <Field
              component={RfArea}
              name="description"
              label={t('settings.document.description.label')}
              validate={[length({ max: 10000 })]}
              max={10000}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AddFileModal.propTypes = {
  document: objectOf(any).isRequired,
  close: func.isRequired,
  handleSubmit: func.isRequired,
  createDocumentFile: func.isRequired,
};

export default AddFileModal;
