import {
  isContractTypeFullTime,
  isContractTypeMissionOrFulltime,
  isContractTypeMissions,
  isContractTypeInternship,
  isContractTypeFixedTermContract,
  isContractTypeThesis,
  isContractTypeApprenticeship,
} from '@/selectors/contractTypes';

export default (
  contractTypes,
  salary,
  dailyRate,
  billings,
  salaryConfidentiality,
  context
) => {
  const fulltime = isContractTypeFullTime(contractTypes);
  const missionOrFulltime = isContractTypeMissionOrFulltime(contractTypes);
  const mission = isContractTypeMissions(contractTypes);
  const internship = isContractTypeInternship(contractTypes);
  const fixedTermContract = isContractTypeFixedTermContract(contractTypes);
  const apprenticeship = isContractTypeApprenticeship(contractTypes);
  const thesis = isContractTypeThesis(contractTypes);
  const prefix = context ? `${context}_` : '';

  return {
    [`${prefix}salary`]:
      fulltime ||
      missionOrFulltime ||
      apprenticeship ||
      internship ||
      fixedTermContract ||
      thesis
        ? salary
        : null,
    [`${prefix}salary_confidentiality`]:
      fulltime || missionOrFulltime ? salaryConfidentiality : false,
    [`${prefix}daily_rate`]: missionOrFulltime || mission ? dailyRate : null,
    [`${prefix}billings`]: missionOrFulltime || mission ? billings : null,
    [`${prefix}contract_types`]: [contractTypes],
  };
};
