import React from 'react';
import _ from 'lodash';
import pp from 'prop-types';
import Slider from './Slider';
import withLabels from './withLabels';
import toField from '../toField';

const LabelledSlider = withLabels(Slider);

// Trigger focus / blur on drag / drop
// Wrap with labels if needed
const SliderField = ({ onFocus, onBlur, ...props }) => {
  const SliderComponent = props.valueKey ? LabelledSlider : Slider;
  return (
    <SliderComponent
      {...props}
      onSlideStart={_.debounce(onFocus, 200)}
      onSlideEnd={_.debounce(onBlur, 200)}
    />
  );
};

SliderField.propTypes = {
  onFocus: pp.func,
  onBlur: pp.func,
};

SliderField.defaultProps = {
  onFocus: _.noop,
  onBlur: _.noop,
};

export default toField(SliderField);
