import uuid from 'uuid/v1';
import throttleAction from '@/helpers/throttle-action';

const SHOW_FLASH = 'flash/SHOW';
const HIDE_FLASH = 'flash/HIDE';

const initialState = {
  queue: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_FLASH:
      return {
        queue: [
          {
            kind: action.kind,
            title: action.title,
            text: action.text,
            id: action.id,
          },
          ...state.queue,
        ],
      };
    case HIDE_FLASH:
      return {
        queue: state.queue.filter(({ id }) => id !== action.id),
      };
    default:
      return state;
  }
};

const showFlashAction = ({ kind, title, text, id }) => ({
  type: SHOW_FLASH,
  kind,
  title,
  text,
  id,
});

const hideFlashAction = (id) => ({
  type: HIDE_FLASH,
  id,
});

const getFlashInQueue = (queue, id) => queue.find((f) => f.id === id);

export const hideFlash = hideFlashAction;

const showFlashThunk = ({ kind, title, text, duration = 5000 }) => (
  dispatch,
  getState
) => {
  const flashId = uuid();
  dispatch(showFlashAction({ kind, title, text, id: flashId }));

  if (duration) {
    setTimeout(() => {
      const { flash } = getState();
      if (getFlashInQueue(flash.queue, flashId)) dispatch(hideFlash(flashId));
    }, duration);
  }
};
export const showFlash = throttleAction(showFlashThunk, 700);
