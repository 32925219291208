import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { get } from 'lodash';
import { func, bool } from 'prop-types';
import { getChargeUnits } from '@/api/Proposal/queries';
import FormSection from '@/components/FormSection';
import ChargeUnits from '@/components/ChargeUnits';
import { FieldArray } from '@/containers/ReduxForm';
import { Query } from 'react-apollo';

const Charges = ({ change, displayOnMount }) => {
  const { t } = useTranslation();
  return (
    <Query query={getChargeUnits}>
      {({ data, loading }) => {
        const chargeUnits = get(data, 'charge_units');

        if (loading || !chargeUnits) return null;
        return (
          <div className="Additional__pricing">
            <FormSection
              title={t(
                'milestone.technical_assistance.additional_pricing.title'
              )}
            >
              <FieldArray
                id="charges"
                name="charges"
                component={ChargeUnits}
                chargeUnits={chargeUnits}
                change={change}
                displayOnMount={displayOnMount}
                required={false}
              />
            </FormSection>
          </div>
        );
      }}
    </Query>
  );
};

Charges.propTypes = {
  change: func.isRequired,
  displayOnMount: bool,
};

Charges.defaultProps = {
  displayOnMount: false,
};

export default Charges;
