import React from 'react';
import { objectOf, any, func } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Trash } from '@/images/trash.svg';
import { RequiredField, Field, RfText, RfArea } from '@/containers/ReduxForm';
import './styles.scss';
import { ReactComponent as Plus } from '@/images/plus.svg';

function MilestoneDeliverables({ fields }) {
  const { t } = useTranslation();

  function deleteField(index) {
    fields.remove(index);
  }

  return (
    <>
      {fields.map((field, i) => (
        <div className="Deliverables" key={field}>
          <button type="button" onClick={() => deleteField(i)}>
            <Trash />
          </button>
          <RequiredField
            component={RfText}
            name={`${field}.title`}
            label={t('milestone.forfeit.deliverables.title.label')}
            className="m-b-m"
          />
          <Field
            component={RfArea}
            name={`${field}.description`}
            label={t('milestone.forfeit.deliverables.description.label')}
            placeholder={t(
              'milestone.forfeit.deliverables.description.placeholder'
            )}
          />
        </div>
      ))}
      <button
        type="button"
        onClick={() => fields.push({})}
        className="Deliverables__action"
        disabled={fields.length === 5}
      >
        <Plus />
        {t('proposal.modal.milestone.deliverables.add')}
      </button>
    </>
  );
}

MilestoneDeliverables.propTypes = {
  fields: objectOf(any).isRequired,
  change: func.isRequired,
};

export default MilestoneDeliverables;
