import React, { useState } from 'react';
import { func, bool, objectOf, any, string } from 'prop-types';
import { scrollTop } from '@/helpers/scroll';
import useTranslation from '@/hooks/useTranslation';
import Alert from '@/components/Alert';
import { DRAFT } from '@/constants/proposalStatuses';

function PublishModal({
  close,
  dirty,
  variables,
  updateProposal,
  publishProposal,
  handleSubmit,
  onOk,
  status,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const mutationOptions = {
    refetchQueries: [`getProposal`],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setLoading(false);
    },
  };

  async function updateProposalMutation(options) {
    return updateProposal({
      variables,
      ...options,
    });
  }

  const success = () => {
    close();
    onOk();
    scrollTop('smooth');
  };

  async function onSubmit() {
    setLoading(true);
    if (status === DRAFT) {
      if (dirty) {
        await updateProposalMutation();
      }
      await publishProposal({
        variables: { id: variables.id },
        ...mutationOptions,
      });
      success();
      return;
    }
    await updateProposalMutation(mutationOptions);
    success();
  }

  return (
    <Alert
      type="confirm"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      text={t('proposal.modal.publish.text')}
      disableActions={loading}
    />
  );
}

PublishModal.propTypes = {
  close: func.isRequired,
  onOk: func.isRequired,
  dirty: bool.isRequired,
  variables: objectOf(any).isRequired,
  updateProposal: func.isRequired,
  publishProposal: func.isRequired,
  handleSubmit: func.isRequired,
  status: string.isRequired,
};

export default PublishModal;
