import React from 'react';
import { func, string } from 'prop-types';
import { ReactComponent as Burger } from '@/images/burger.svg';
import Drawer from '@/components/Drawer';
import Menu from '../Menu';
import HeaderLink from '../Link';
import LinkList from '../LinkList';
import config from '@/_config';

function MenuDrawer({ t, userKind }) {
  return (
    <Drawer
      width={255}
      placement="right"
      className="Header__drawer"
      renderReference={({ open }) => (
        <HeaderLink onClick={open}>
          <Burger />
        </HeaderLink>
      )}
    >
      <Drawer.Screen name="default">
        {({ close }) => (
          <>
            <LinkList
              t={t}
              onItemClick={close}
              items={config.header[userKind]}
            />
            <Menu userKind={userKind} t={t} onItemClick={close} />
          </>
        )}
      </Drawer.Screen>
    </Drawer>
  );
}

MenuDrawer.propTypes = {
  t: func.isRequired,
  userKind: string.isRequired,
};

MenuDrawer.defaultProps = {};

export default MenuDrawer;
