import React from 'react';
import pp from 'prop-types';
import { noop, uniqWith, reverse } from 'lodash';
import uuid from 'uuid/v1';

const withCreatable = (Downshift) => {
  class CreatableDownshift extends React.Component {
    state = {
      newOption: null,
    };

    getOptions = () => {
      const { newOption } = this.state;
      const { options } = this.props;
      const defaultComparator = (val1, val2) =>
        val1.name.toLowerCase() === val2.name.toLowerCase();

      if (!newOption || !newOption.name) return options;

      return reverse(
        uniqWith(
          reverse([newOption, ...options]),
          this.props.inputValueComparator || defaultComparator
        )
      );
    };

    handleValueChange = (value, helpers) => {
      const newOption = {
        id: uuid(),
        name: value.trim(),
      };
      this.setState({ newOption });
      this.props.onInputValueChange(value, helpers);
    };

    render() {
      return (
        <Downshift
          {...this.props}
          onInputValueChange={this.handleValueChange}
          options={this.getOptions()}
        />
      );
    }
  }

  CreatableDownshift.propTypes = {
    inputValueComparator: pp.func,
    onInputValueChange: pp.func,
    options: pp.arrayOf(pp.oneOfType([pp.object, pp.string])),
  };

  CreatableDownshift.defaultProps = {
    inputValueComparator: noop,
    onInputValueChange: noop,
    options: [],
  };

  return CreatableDownshift;
};

export default withCreatable;
