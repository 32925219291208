import React from 'react';
import { func, bool, string } from 'prop-types';
import { RequiredField, RfArea, RfChoices } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import { BLACKLIST_REASONS } from '@/scenes/Mission/constants/blackListReasonFields';
import useTranslation from '@/hooks/useTranslation';

function BlackListModal({
  close,
  submitting,
  handleSubmit,
  createBlackList,
  blacklisted_id,
  blacklisted_type,
  showFlash,
  handleOnSuccess,
}) {
  const { t } = useTranslation();

  async function onSubmit({ reason, text }) {
    await createBlackList({
      variables: {
        blacklisted_id,
        blacklisted_type,
        reason,
        text,
      },
    });
    close();
    handleOnSuccess();
    showFlash({
      kind: 'success',
      text: 'blacklist.modal.success.text',
    });
  }

  return (
    <Modal
      name="BlackList"
      size="large"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('blacklist.modal.title')}
      subtitle={t('blacklist.modal.subtitle')}
      disableActions={submitting}
      closable={!submitting}
      okText={t('blacklist.modal.send')}
    >
      <p className="fw-bold">{t('blacklist.modal.reason')}</p>
      <RequiredField
        id="reason"
        name="reason"
        component={RfChoices}
        checkbox={LabelledRadio}
        type="radioGroup"
        title={t('blacklist.modal.reason')}
        cbProps={BLACKLIST_REASONS.map((reason) => ({
          ...reason,
          label: t(`${reason.label}`),
        }))}
        renderForValue={{
          other: (
            <RequiredField
              id="text"
              name="text"
              component={RfArea}
              label={t('blacklist.modal.other_reason.label')}
              placeholder={t('blacklist.modal.other_reason.placeholder')}
            />
          ),
        }}
      />
    </Modal>
  );
}

BlackListModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  createBlackList: func.isRequired,
  blacklisted_id: string.isRequired,
  blacklisted_type: string.isRequired,
  handleOnSuccess: func.isRequired,
  showFlash: func.isRequired,
};

export default BlackListModal;
