import React from 'react';
import { string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import '../styles.scss';

const ResourceListContents = ({ className }) => {
  const { t } = useTranslation();
  return (
    <ul className={`ShowCaseBar__listlink-dropdown ${className}`}>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-01.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/blog-client/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.resource_company.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.resource_company.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-01.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/blog-talent/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.resource_candidate.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.resource_candidate.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-01.svg"
              width="45px"
              height="45px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/blog-platform/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.resource_platform.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.resource_platform.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
    </ul>
  );
};

ResourceListContents.propTypes = {
  className: string,
};

ResourceListContents.defaultProps = {
  className: null,
};

export default ResourceListContents;
