import React from 'react';
import { string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import '../styles.scss';

const CandidateAndFreelanceListContents = ({ className }) => {
  const { t } = useTranslation();
  return (
    <ul className={`ShowCaseBar__listlink-dropdown ${className}`}>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-05.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/offres/?_offres_=fulltime_contract"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.offer_fulltime_contract.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.offer_fulltime_contract.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-05.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/offres/?_offres_=missions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.offer_missions.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.offer_missions.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-02.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/mykicklox-offre-services-freelance/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.offer_services_freelance.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.offer_services_freelance.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-04.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/portage-salarial-kicklox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.portal_salarial.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.portal_salarial.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div className="ShowCaseBar__sublink-dropdown__container">
          <div>
            <img
              src="https://www.kicklox.com/wp-content/uploads/2020/04/website-icons-faq-03.svg"
              width="40px"
              height="40px"
              alt=""
            />
          </div>
          <div>
            <a
              href="https://www.kicklox.com/offres/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="ShowCaseBar__sublink-dropdown__main">
                {t('showcasebar.sublink.offers.main')}
              </p>
              <p className="ShowCaseBar__sublink-dropdown__subtitle">
                {t('showcasebar.sublink.offers.subtitle')}
              </p>
            </a>
          </div>
        </div>
      </li>
    </ul>
  );
};

CandidateAndFreelanceListContents.propTypes = {
  className: string,
};

CandidateAndFreelanceListContents.defaultProps = {
  className: null,
};

export default CandidateAndFreelanceListContents;
