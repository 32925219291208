import { get, toLower, mapKeys, snakeCase } from 'lodash';
import camelize from 'camelize';
import Mission from '@/models/Mission';
import { factory as filterItemsFactory } from '@/redux/modules/matching/helpers/factories/items';
import { factory as selectedFilterFactory } from '@/redux/modules/matching/helpers/factories/filters';
import { resolver as queryParamatersResolver } from '@/redux/modules/matching/helpers/resolvers/queryParameters';

/**
 * Handle Matching
 * Set all matching structure needed for UX filtering interactions
 * @param action
 * @param state
 * @returns {}
 */
export function handleMatching(action, state) {
  const { payload } = action;
  const results = get(payload, 'results');
  const kind = get(payload, 'kind', Mission.modelName);
  const facetFilters = get(results, 'filters', []) || [];
  const filters = get(payload, 'filters', []) || [];
  const facetFiltersCamelized = camelize(facetFilters);
  const selectedFilters = state.filters.selected;

  return {
    ...state,
    displayFilters: true,
    kind,
    count: get(results, 'count', 0),
    results: get(results, 'items', []) || [],
    filters: {
      selected: { ...selectedFilters },
      presets: filters.map((filter) => {
        const filterName = get(filter, 'name');
        const facetFilter = get(facetFiltersCamelized, filterName);
        const items = get(facetFilter, 'buckets', []) || [];

        return {
          ...filter,
          name: filterName,
          kind: filter.kind,
          Component: filter.Component,
          title: `${toLower(kind)}.${filter.title}`,
          items: filterItemsFactory(items, filter, selectedFilters),
        };
      }),
    },
  };
}

/**
 * Handle Active Filter
 * Set active flag inside filter preset
 * @param action
 * @param state
 * @returns {}
 */
export function handleActiveFilter(action, state) {
  const { name, value, selected, key, kind } = action.payload;
  const meta = get(action.payload, 'meta', {});
  const selectedFilters = get(state.filters.selected, name, []) || [];

  const presets = get(state, 'filters.presets', []).map(
    (filter) =>
      filter.name === name
        ? {
            ...filter,
            items: filter.items.map(
              (item) =>
                item.key === key
                  ? { ...item, selected: !item.selected, value, meta }
                  : item
            ),
          }
        : filter
  );

  return {
    ...state,
    filters: {
      selected: {
        ...state.filters.selected,
        ...selectedFilterFactory(
          kind,
          selected,
          selectedFilters,
          value,
          name,
          key,
          meta
        ),
      },
      presets,
    },
  };
}

/**
 * HandleQueryParameters
 * @param state
 * @returns {}
 */
export function handleQueryParameters(state) {
  const selectedFilters = get(state, 'filters.selected', {});
  return {
    ...state,
    displayFilters: false,
    reset: true,
    results: [],
    queryParameters: {
      ...mapKeys(selectedFilters, (_, key) => snakeCase(key)),
      ...queryParamatersResolver(selectedFilters),
    },
  };
}

export function handleResetMatching(initialState) {
  return {
    ...initialState,
    reset: false,
  };
}
