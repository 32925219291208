import React from 'react';
import { any, func, objectOf } from 'prop-types';
import { get } from 'lodash';
import config from '@/_config';
import { RequiredField, RfNumber } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { PROFILE_COLLABORATOR } from '@/constants/forms';
import './styles.scss';

const CollaboratorNumberModal = ({ t, currentUser, ...props }) => {
  const { initialValues } = props;

  const formatValues = ({
    employees_number,
    subcontractors_number,
    ...values
  }) => ({
    employees_number,
    subcontractors_number,
    ...values,
  });

  const getInitialValues = () => ({
    employees_number: get(initialValues, 'employeesNumber'),
    subcontractors_number: get(initialValues, 'subcontractorsNumber'),
  });

  return (
    <ProfileModal
      name="CollaboratorNumbers"
      title={t('company.modal.collaborator.number.title')}
      form={PROFILE_COLLABORATOR}
      formatValues={formatValues}
      edit
      deletable={false}
      {...props}
      initialValues={getInitialValues()}
    >
      <FormSection>
        <RequiredField
          inline
          component={RfNumber}
          name="employees_number"
          className="CollaboratorNumbers__employees"
          max={config.company.experienceTime.max}
          min={config.company.experienceTime.min}
          step={1}
          label={t('company.modal.collaborator.employees_number.label')}
          helper={t('company.modal.collaborator.employees_number.helper')}
        />

        <RequiredField
          inline
          component={RfNumber}
          name="subcontractors_number"
          className="CollaboratorNumbers__subcontractors"
          max={config.company.experienceTime.max}
          min={config.company.experienceTime.min}
          step={1}
          label={t('company.modal.collaborator.subcontractors_number.label')}
          helper={t('company.modal.collaborator.subcontractors_number.helper')}
        />
      </FormSection>
    </ProfileModal>
  );
};

CollaboratorNumberModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  initialValues: objectOf(any),
};

CollaboratorNumberModal.defaultProps = {
  initialValues: {},
};

export default CollaboratorNumberModal;
