import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import { file } from '@/helpers/validation';
import { Field, RequiredField } from '@/containers/ReduxForm';

const FileField = ({ required, validate, ...props }) => {
  const { accept, minFiles, maxFiles, minSize, maxSize } = props;
  const FieldComponent = required ? RequiredField : Field;
  return (
    <FieldComponent
      required={required}
      validate={[
        file({
          accept,
          minFiles,
          maxFiles,
          minSize,
          maxSize,
        }),
        ..._.castArray(validate),
      ]}
      {...props}
    />
  );
};

FileField.propTypes = {
  required: pp.bool,
  validate: pp.oneOfType([pp.arrayOf(pp.func), pp.func]),
  accept: pp.oneOfType([pp.arrayOf(pp.string), pp.string]),
  minFiles: pp.number,
  maxFiles: pp.number,
  minSize: pp.number,
  maxSize: pp.number,
};

FileField.defaultProps = {
  required: false,
  accept: [],
  validate: [],
  minFiles: 0,
  maxFiles: Infinity,
  minSize: 0,
  maxSize: Infinity,
};

export default FileField;
