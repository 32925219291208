import React from 'react';
import { func, any, objectOf, string } from 'prop-types';
import { RequiredField, RfTags } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import { get } from 'lodash';
import ProfileModal from '../../ProfileModal';
import { PROFILE_TOOLS } from '@/constants/forms';
import { TOOL } from '@/constants/tagKind';
import { formatTags } from '@/components/Tags/helpers/handler';

const ToolsModal = ({ t, ...props }) => (
  <ProfileModal
    title={t('company.modal.tools.title')}
    form={PROFILE_TOOLS}
    formatValues={({ tools }) => ({
      tools: formatTags(tools),
    })}
    initialValues={{
      tools: get(props, 'tools', []),
    }}
    deletable={false}
    {...props}
  >
    <FormSection>
      <RequiredField
        id="tools"
        name="tools"
        component={RfTags}
        kind={TOOL}
        withCreatable={false}
        withIcon
        inlineDisplay
        minCharsSuggestion={1}
        description="company.modal.tools.description"
        disabledVariant
      />
    </FormSection>
  </ProfileModal>
);

ToolsModal.propTypes = {
  t: func.isRequired,
  kind: string.isRequired,
  leader: objectOf(any).isRequired,
  description: string,
};

ToolsModal.defaultProps = {
  description: 'company.modal.tools.description',
};

export default ToolsModal;
