import {
  handleActiveFilter,
  handleMatching,
  handleQueryParameters,
  handleResetMatching,
} from '@/redux/modules/matching/helpers';

const SET_MATCHING = 'matching/SET';
const SET_FILTERS = 'filters/SET';
const SET_ACTIVE_FILTER = 'filter/SET_ACTIVE';
const SET_QUERY_PARAMETERS = 'search/SET_QUERY_PARAMETERS';
const RESET_MATCHING = 'matching/RESET';

const initialState = {
  count: 0,
  reset: false,
  kind: '',
  results: [],
  displayFilters: false,
  filters: {
    selected: {},
    presets: [],
  },
  queryParameters: {
    limit: 10,
    offset: 0,
  },
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESET_MATCHING:
      return handleResetMatching(initialState);
    case SET_MATCHING:
      return handleMatching(action, state);
    case SET_QUERY_PARAMETERS:
      return handleQueryParameters(state);
    case SET_FILTERS:
      return { ...state, filters: { presets: action.payload } };
    case SET_ACTIVE_FILTER:
      return handleActiveFilter(action, state);
    default:
      return state;
  }
}

export const setMatching = (results, filters, kind) => ({
  type: SET_MATCHING,
  payload: { results, filters, kind },
});
export const setFilters = (payload) => ({ type: SET_FILTERS, payload });
export const setActiveFilter = (payload) => ({
  type: SET_ACTIVE_FILTER,
  payload,
});
export const setQueryParameters = (reset) => ({
  type: SET_QUERY_PARAMETERS,
  payload: { reset },
});
export const resetMatching = () => ({ type: RESET_MATCHING });
