import React from 'react';
import pp from 'prop-types';
import { getNewMissionRoute } from '@/helpers/router';
import { ReactComponent as Plus } from '@/images/plus.svg';
import HeaderLink from '../Link';
import './styles.scss';

function NewMission({ t, userKind }) {
  if (userKind === 'expert') return null;
  return (
    <HeaderLink to={getNewMissionRoute()} className="Header__new">
      <Plus />
      <span>{t('header.nav.newMission')}</span>
    </HeaderLink>
  );
}

NewMission.propTypes = {
  t: pp.func.isRequired,
  userKind: pp.string.isRequired,
};

export default NewMission;
