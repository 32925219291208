import Entity from './Entity';
import config from '@/_config';
import { CLIENT, EXPERT, MIXED } from '@/constants/userKind';
import { get } from 'lodash';
import { ARCHIVED } from '@/constants/missionStatuses';
import {
  MISSION_OR_FULLTIME,
  MISSIONS,
  FULLTIME_CONTRACT,
} from '@/constants/contractTypes';
import { CITIZEN, EXTERNAL, PATIENT } from '@/constants/companyKind';

class User extends Entity {
  static get modelName() {
    return 'User';
  }

  isMissionOwnedByCurrentCompany(mission) {
    if (!this.get('id')) {
      return false;
    }
    return mission.get('company.id') === this.get('company.id');
  }

  isRelevanceMaxScoreReached() {
    return (
      this.get('relevance.current_score', null) >=
      config.mission.relevanceScore.maxScore
    );
  }

  hasArchivedCandidacyForMission(mission) {
    return (
      get(
        this.get('candidacies', []).find(
          (candidacy) => mission.get('id') === get(candidacy, 'mission.id')
        ),
        'status'
      ) === ARCHIVED
    );
  }

  hasArchivedCandidaciesForMission(mission) {
    return !!this.get('candidacies', []).find(
      (candidacy) =>
        mission.get('id') === get(candidacy, 'mission.id') &&
        get(candidacy, 'status') === ARCHIVED
    );
  }

  isExpert() {
    return this.get('kind') === EXPERT;
  }

  isClient() {
    return this.get('kind') === CLIENT;
  }

  isMixed() {
    return this.get('kind') === MIXED;
  }

  isLeader() {
    return this.get('id') === this.get('company.leader.id');
  }

  isAdministrator() {
    return this.get('administrator');
  }

  isSignedInAsUser() {
    return this.get('signed_in_as_user');
  }

  isMissionOrFulltimeContractType() {
    return this.get('company.contract_types', []).includes(MISSION_OR_FULLTIME);
  }

  isMissionContractType() {
    return this.get('company.contract_types', []).includes(MISSIONS);
  }

  isFulltimeContractType() {
    return this.get('company.contract_types', []).includes(FULLTIME_CONTRACT);
  }

  isExternalKind() {
    return this.get('company.kind') === EXTERNAL;
  }

  isCitizenKind() {
    return this.get('company.kind') === CITIZEN;
  }

  isPatientKind() {
    return this.get('company.kind') === PATIENT;
  }
}

export default User;
