
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"bookmarkFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"List"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"favorites_entities"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"order_by"},"value":{"kind":"StringValue","value":"created_at","block":false}},{"kind":"Argument","name":{"kind":"Name","value":"order"},"value":{"kind":"IntValue","value":"0"}},{"kind":"Argument","name":{"kind":"Name","value":"limit"},"value":{"kind":"Variable","name":{"kind":"Name","value":"limit"}}},{"kind":"Argument","name":{"kind":"Name","value":"offset"},"value":{"kind":"Variable","name":{"kind":"Name","value":"offset"}}}],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"favoritesEntitiesFragment"},"directives":[]}]}}]}}],"loc":{"start":0,"end":370}};
    doc.loc.source = {"body":"#import \"../Attachment/fragment.gql\"\n#import \"../Tag/fragment.gql\"\n#import \"../Company/Modalities.gql\"\n#import \"../Company/Degree/fragment.gql\"\n#import \"./favorites-entities-fragment.gql\"\n\nfragment bookmarkFragment on List {\n  favorites_entities(\n    order_by: \"created_at\"\n    order: 0\n    limit: $limit\n    offset: $offset\n  ) {\n    ...favoritesEntitiesFragment\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../Attachment/fragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../Tag/fragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../Company/Modalities.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("../Company/Degree/fragment.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("./favorites-entities-fragment.gql").definitions));


      module.exports = doc;
    
