import React from 'react';
import pp from 'prop-types';
import { Mutation } from 'react-apollo';
import { removeFromBookmarks } from '@/api/Bookmark/mutations';
import Alert from '@/components/Alert';

function BookmarkRemoveModal({ t, close, id }) {
  return (
    <Mutation mutation={removeFromBookmarks} variables={{ id }}>
      {(mutate, { loading }) => (
        <Alert
          type="warning"
          close={close}
          onOk={async () => {
            await mutate({
              refetchQueries: ['getBookmark'],
              awaitRefetchQueries: true,
            });
            close();
          }}
          onCancel={close}
          disableActions={loading}
          closable={!loading}
          text={t('bookmarks.modal.remove.title')}
        />
      )}
    </Mutation>
  );
}

BookmarkRemoveModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  id: pp.string.isRequired,
};

export default BookmarkRemoveModal;
