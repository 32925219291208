import { useEffect } from 'react';
import { KEYCODES } from '@/constants/keyCodes';
import useI18nHelp from '@/hooks/useI18nHelp';
import { isStaging } from '@/constants/environment';
import useTranslation from '@/hooks/useTranslation';
import { get, noop } from 'lodash';
import { getToken } from '@/helpers/auth';

function I18nListener() {
  const [i18nHelp, setI18nHelp] = useI18nHelp(false);
  const { i18n } = useTranslation();
  const copyToClipboardAllBuildTranslations = () => {
    navigator.clipboard.writeText(
      JSON.stringify(get(i18n, 'store.data.fr.translation'))
    );
  };

  function handleKeyDown(e) {
    if (getToken() && e.metaKey) {
      switch (e.keyCode) {
        case KEYCODES.keyK:
          setI18nHelp(!i18nHelp);
          break;
        case KEYCODES.keyM:
          copyToClipboardAllBuildTranslations();
          break;
        default:
          noop();
      }
    }
  }

  useEffect(() => {
    // TODO add listener only for testing environment when it will be set [#KICKLOX-416]
    if (!isStaging) return undefined;
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  return null;
}

export default I18nListener;
