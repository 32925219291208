import { isNull, get } from 'lodash';
import { requiredLegalInformation } from '@/constants/requiredLegalInformation';
import config from '@/_config';

export function sendProposal(user) {
  const legalInformation = user.get('company.legal_information', {});
  return !requiredLegalInformation
    .map((requiredField) => !isNull(legalInformation[requiredField]))
    .includes(false);
}

export function userAbility(can) {
  can('invite', 'User', (user) => user.isLeader());

  can('send_proposal', 'User', (user) => sendProposal(user));

  can(
    'view_cv_section',
    'User',
    (user) =>
      (user.isClient() || user.isMixed()) &&
      (user.isAdministrator() ||
        user.get('company.additional_information_access', false))
  );

  can('access_search', 'User', (user) => {
    if (config.global.openOffer && user.isClient()) {
      return (
        user.isAdministrator() ||
        user.get('company.client_pool_search_access', false) ||
        user.get('company.search_access', false)
      );
    }
    if (
      config.global.expertSearchAccess &&
      (user.isExpert() || user.isMixed())
    ) {
      return (
        user.get('company.client_pool_search_access', false) ||
        user.get('company.search_access', false)
      );
    }
    return true;
  });

  can(
    ['display_message_on_proposal_view', 'access_proposal_creation'],
    'User',
    (user) =>
      user.isMissionOrFulltimeContractType() || user.isMissionContractType()
  );

  can(
    'view_admin_section',
    'User',
    (user) =>
      config.company.showAdminSection && (user.isClient() || user.isMixed())
  );

  can(['view_bo_link', 'view_is_registered', 'view_dates'], 'User', (user) =>
    user.isAdministrator()
  );

  can(
    'view_tenant_offer_information',
    'User',
    (user) =>
      config.mission.partnerOffer && (user.isExpert() || !get(user, 'id'))
  );

  can('view_matched_companies_links', 'User', (user) => {
    if (user.get('signed_in_as_user')) return true;
    return (
      user.get('company.company_matching_access') ||
      user.get('company.search_access')
    );
  });
}
