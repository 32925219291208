export const ONE_MONTH = 'one_month';
export const THREE_MONTHS = 'three_months';
export const UNAVAILABLE = 'unavailable';
export const ASAP = 'asap';
export const PRECISE = 'precise';
export const ACTIVE = 'active';

export const AVAILABILITY_UPDATED_STATUS_ACTIVE = 'active';
export const AVAILABILITY_UPDATED_STATUS_OPEN = 'open';
export const AVAILABILITY_UPDATED_STATUS_UNAVAILABLE = 'unavailable';

export const AVAILABILITY_UPDATED_STATUS_ACTIVE_NOW = 'active_now';
export const AVAILABILITY_UPDATED_STATUS_ACTIVE_AT = 'active_at';
