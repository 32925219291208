import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import i18n from '@/i18n';
import { ReactComponent as Calendar } from '@/images/calendar.svg';
import { TextInput } from '../TextField';
import './styles.scss';
import 'moment/locale/de';

class DatePickerInput extends React.Component {
  handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) return;
    this.props.onChange(day);
  };

  handleFocus = (e) => {
    if (e.target.getAttribute('aria-disabled') === 'true') return;
    this.props.onFocus();
  };

  handleBlur = (e) => {
    if (e.target.getAttribute('aria-disabled') === 'true') return;
    this.props.onBlur(this.props.value);
  };

  render() {
    const {
      dayPickerProps,
      inputProps,
      onChange,
      onBlur,
      inline,
      value,
      locale,
      ...restProps
    } = this.props;

    const pickerProps = {
      showOutsideDays: true,
      enableOutsideDaysClick: false,
      locale: locale || i18n.language,
      localeUtils: MomentLocaleUtils,
      ...dayPickerProps,
    };

    return inline ? (
      <DayPicker
        {...pickerProps}
        {...restProps}
        {...value && { initialMonth: value }}
        selectedDays={[value]}
        value={value}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onDayClick={this.handleDayClick}
      />
    ) : (
      <DayPickerInput
        component={TextInput}
        onDayChange={onChange}
        format={i18n.t('fields.date.format')}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={pickerProps}
        value={value}
        inputProps={{
          ...inputProps,
          ...restProps,
          onChange,
          icon: <Calendar />,
          onBlur: this.handleBlur,
        }}
      />
    );
  }
}

DatePickerInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  dayPickerProps: PropTypes.objectOf(PropTypes.any),
  inputProps: PropTypes.objectOf(PropTypes.any),
  inline: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
};

DatePickerInput.defaultProps = {
  onChange: _.noop,
  onFocus: _.noop,
  onBlur: _.noop,
  dayPickerProps: {},
  inputProps: {},
  inline: false,
  value: null,
};

export default DatePickerInput;
