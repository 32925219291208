import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import {
  deleteCollaborator,
  createOrUpdateCollaborator,
} from '@/api/Company/Collaborator/mutations';
import translate from '@/helpers/enhancers/translate';
import CollaboratorsModal from './CollaboratorsModal';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';

export default compose(
  withCurrentUser,
  graphql(createOrUpdateCollaborator, { name: 'create' }),
  graphql(createOrUpdateCollaborator, { name: 'update' }),
  graphql(deleteCollaborator, { name: 'delete' }),
  translate()
)(CollaboratorsModal);
