import React from 'react';
import pp from 'prop-types';
import { lengthSkills } from '@/helpers/validation';
import { RequiredField, Field, RfTags } from '@/containers/ReduxForm';
import { get } from 'lodash';
import ProfileModal from '../../ProfileModal';
import config from '@/_config';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE } from '@/constants/features';
import TagsContext from '@/components/Tags/TagsContext';
import { PROFILE_SKILLS } from '@/constants/forms';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';

const SkillsModal = ({ t, ...props }) => {
  const ComponentField = config.validation.expertProfile.minOptionalSkillsLength
    ? RequiredField
    : Field;

  return (
    <ProfileModal
      title={t('company.modal.skills.title')}
      form={PROFILE_SKILLS}
      formatValues={({ skills, personal_skills }) => ({
        skills: formatTags(skills),
        personal_skills: formatTags(personal_skills),
      })}
      initialValues={{
        skills: [],
        personal_skills: [],
      }}
      {...props}
    >
      <div>
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(PROFILE),
            max: get(config, 'validation.expertProfile.minSkillsLength'), // default is skill, feel free to create config for your own type of tag
            highlightMaxValues: get(config, 'skills.highlightMax'),
          }}
        >
          <RequiredField
            id="skills"
            name="skills"
            component={RfTags}
            description="profile.form.skills_required.description"
            helperText="profile.form.skills_required.helper"
            {...getSkillsValidationAndExtraProps([
              lengthSkills({
                min: config.validation.expertProfile.minSkillsLength,
              }),
            ])}
          />
          <ComponentField
            id="personal_skills"
            name="personal_skills"
            kind={config.skills.kindRequestTagPersonalSkill}
            component={RfTags}
            description="profile.form.skills_personal.description"
            {...getSkillsValidationAndExtraProps([
              lengthSkills({
                ...(config.validation.expertProfile.maxOptionalSkillsLength && {
                  max: config.validation.expertProfile.maxOptionalSkillsLength,
                }),
                min: 0,
              }),
            ])}
          />
        </TagsContext.Provider>
      </div>
    </ProfileModal>
  );
};

SkillsModal.propTypes = {
  t: pp.func.isRequired,
};

export default SkillsModal;
