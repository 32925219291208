import { useContext } from 'react';
import { bool, number, string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import UserModel from '@/models/User';
import UserContext from '@/permissions/contexts/UserContext';
import config from '@/_config';

const FilterCount = ({ label, count, displayCount }) => {
  const { t } = useTranslation();
  const contextUser = useContext(UserContext);
  const user = new UserModel(contextUser);
  if (!displayCount) return t(label);

  if (
    count < 1000 ||
    user.isAdministrator() ||
    user.isSignedInAsUser() ||
    !config.search.tooMuchHitsWording
  ) {
    return `${t(label)} (${count})`;
  }

  return `${t(label)} (1000+)`;
};

FilterCount.propTypes = {
  label: string.isRequired,
  count: number.isRequired,
  displayCount: bool.isRequired,
};

export default FilterCount;
