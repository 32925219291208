import _ from 'lodash';
import client from '@/helpers/apollo';
import { addToBookmarks, removeFromBookmarks } from '@/api/Bookmark/mutations';

export default {
  Mutation: {
    toggleBookmark: async (__, { companyId, list }) => {
      const { id, included, favorites_entities } = list;

      if (included) {
        const entityId = _.find(
          favorites_entities,
          (e) => e.entity.id === companyId
        ).id;

        const {
          data: { deleteFavoritesEntity },
        } = await client.mutate({
          mutation: removeFromBookmarks,
          variables: {
            id: entityId,
          },
        });

        return deleteFavoritesEntity;
      }

      const {
        data: { createFavoritesEntity },
      } = await client.mutate({
        mutation: addToBookmarks,
        variables: {
          entity_id: companyId,
          entity_type: 'Company',
          list_id: id,
        },
      });

      return createFavoritesEntity;
    },
  },
};
