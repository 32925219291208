import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { propType } from 'graphql-anywhere';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import {
  RequiredField,
  Field,
  RfLabelledCheckbox,
  RfArea,
  RfFiles,
  RfChoices,
  RfHelper,
} from '@/containers/ReduxForm';
import MilestoneInformation from '@/components/Contract/MilestoneCard/MilestoneInformation';
import MilestonePriceAndDate from '@/components/Contract/MilestoneCard/MilestonePriceAndDate';
import MilestoneDescriptionAndDeliverables from '@/components/Contract/MilestoneCard/MilestoneDescriptionAndDeliverables';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import MilestoneStatusMessage from '@/components/Contract/MilestoneCard/MilestoneStatusMessage';
import MilestoneCharges from '@/components/Contract/MilestoneCard/MilestoneCharges';
import { getStatusProperties } from '@/components/Contract/helpers/status';
import { string } from 'prop-types';
import { DELIVER } from '@/components/Contract/constants/modalMaps';
import { Trans } from 'react-i18next';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import {
  MODERATION,
  MODERATION_FIELD_APPROVE_VALUE,
} from '@/components/Contract/constants/moderationFields';
import priceFormat from '@/helpers/priceFormat';
import { acceptance } from '@/helpers/validation';
import { getMonthLabel } from '@/helpers/milestone';
import { capitalize } from '@/helpers/string';
import { expertKeysVariables } from '@/helpers/contextKeys';
import getPricingUnitKindLabel from '@/helpers/pricingUnitKindLabel';
import './styles.scss';
import { get } from 'lodash';

function DeliverModerationForm({
  context,
  milestone,
  contract,
  userKindContext,
}) {
  const { t } = useTranslation();
  const { end_date, status, units_worked, price } = milestone;
  const { message, primary, secondary, tertiary } = getStatusProperties(
    status,
    userKindContext
  );
  const withModification = milestone.isDeliveryWithUpdateReviewClient();

  const unit = get(contract, 'proposal.quotation.unit');

  const { costPerUnit } = contract;
  const formattedTitle = capitalize(getMonthLabel(milestone.get('title')));
  const oldCharges = milestone.get('parent.charges', []);
  const newCharges = milestone.get('charges', []);

  const chargesToDisplay = withModification ? oldCharges : newCharges;

  const timeWorked = units_worked;
  const technicalAssistancePrice = contract.costPerUnit * timeWorked;

  return (
    <>
      <p>
        <Trans
          i18nKey="contract.milestone.subtitle.deliver.moderation"
          values={{
            message: t('contract.milestone.subtitle.deliver.moderation'),
            ...expertKeysVariables(t),
          }}
          components={[<span className="fw-bold">message</span>]}
        />
      </p>
      <div className="MilestoneCardModal">
        <div className="MilestoneCardModal__header">
          <span>{t('contract.milestone.title')}</span>
          <span>{t('contract.milestone.unit')}</span>
          <span>{t('contract.milestone.amount')}</span>
          <span>{t('contract.milestone.cost_per_unit')}</span>
          <span>{t('contract.milestone.total_duty')}</span>
        </div>
        {withModification &&
          !contract.isForfeit() && (
            <div className="MilestoneCardModal__status-message">
              <div className="MilestoneCardModal__initial-message bgc-lblue">
                {t('contract.milestone.modification.deliver.initial_values')}
              </div>
            </div>
          )}
        <div className={`MilestoneCardModal__information--${context}`}>
          <MilestoneInformation
            contract={contract}
            milestone={milestone}
            costPerUnit={contract.costPerUnit}
            withModification={withModification}
          />
        </div>
        {chargesToDisplay.length > 0 && (
          <MilestoneCharges
            charges={chargesToDisplay}
            primary={primary}
            classes={{
              root: `MilestoneCardModal__information--${context}`,
              title: 'MilestoneCard___title',
            }}
          />
        )}
        {withModification ? (
          <>
            <div className="MilestoneCardModal__status-message">
              <div className="MilestoneCardModal__modification-message">
                {t(
                  `contract.milestone.modification.${
                    contract.isForfeit() ? 'forfeit' : 'at'
                  }.deliver`
                )}
              </div>
            </div>
            <div className="MilestoneCardModal__information--modification">
              <span>{formattedTitle}</span>
              <span>{getPricingUnitKindLabel(t, unit)}</span>
              <span>
                {contract.isForfeit()
                  ? '-'
                  : priceFormat(timeWorked, { minDigits: 2, maxDigits: 2 })}
              </span>
              <span>
                {contract.isForfeit() ? (
                  '-'
                ) : (
                  <>
                    {priceFormat(costPerUnit, { minDigits: 2, maxDigits: 2 })}
                    <span className="p-l-xxs">{t('currency.symbol')}</span>
                  </>
                )}
              </span>
              <span>
                {priceFormat(
                  contract.isForfeit() ? price : technicalAssistancePrice,
                  {
                    minDigits: 2,
                    maxDigits: 2,
                  }
                )}
                <span className="p-l-xxs">{t('currency.symbol')}</span>
              </span>
            </div>
            {newCharges.length > 0 && (
              <MilestoneCharges
                charges={newCharges}
                primary={primary}
                classes={{
                  root: 'MilestoneCardModal__information--modification',
                  title: 'MilestoneCard___title',
                }}
              />
            )}
          </>
        ) : (
          <div className="MilestoneCardModal__status-message">
            <MilestoneStatusMessage
              secondary={secondary}
              primary={primary}
              message={message}
              tertiary={tertiary}
              endDate={end_date}
            />
          </div>
        )}
        <div
          className={`MilestoneCardModal__price--${
            withModification ? 'modification' : context
          }`}
        >
          <MilestonePriceAndDate
            milestone={milestone}
            contract={contract}
            classes={{ priceAndDate: 'MilestoneCard__price-and-date' }}
          />
        </div>
        <div className="MilestoneCardModal__deliverables">
          <MilestoneDescriptionAndDeliverables milestone={milestone} />
        </div>
      </div>
      <p className="fw-bold p-t-s">
        {t('contract.milestone.attachment.label.deliver')}
      </p>
      <Field
        component={RfFiles}
        multiple
        id="attachments"
        name="attachments"
        text={t('contract.milestone.attachment.text.deliver')}
        infoText={t('contract.milestone.attachment.infoText.deliver')}
        rejectText={t(
          'contract.milestone.attachment.infoText.rejectText.deliver'
        )}
        t={t}
      />
      <p className="fw-bold p-t-s">{t('milestone.approbation.acceptance')}</p>
      <RequiredField
        component={RfChoices}
        checkbox={LabelledRadio}
        name="approbation"
        type="radioGroup"
        cbProps={MODERATION.map((p) => ({
          ...p,
          label: t(`${p.label}.${context}`),
        }))}
        renderForValue={{
          disapprove: (
            <RequiredField
              name="refusal_reason"
              component={RfArea}
              label={t('milestone.refusal.placeholder')}
            />
          ),
        }}
      />

      <RfHelper values={['approbation']}>
        {({ approbation }) =>
          approbation === MODERATION_FIELD_APPROVE_VALUE && (
            <RequiredField
              name="confirm"
              type="checkbox"
              component={RfLabelledCheckbox}
              label={t('contract.milestone.confirm.label.deliver.moderation')}
              validate={[acceptance()]}
            />
          )
        }
      </RfHelper>
    </>
  );
}

DeliverModerationForm.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  context: string,
  userKindContext: string.isRequired,
};

DeliverModerationForm.defaultProps = {
  context: DELIVER,
};

export default DeliverModerationForm;
