import React from 'react';
import LoadingCircle from '../LoadingCircle';
import './styles.scss';

const Loading = () => (
  <div className="PageLoader">
    <LoadingCircle />
  </div>
);

export default Loading;
