import React from 'react';
import { func, string, objectOf, any } from 'prop-types';
import { Mutation } from 'react-apollo';
import { deleteMission } from '@/api/Mission/mutations';
import Alert from '@/components/Alert';
import { getMyMissionsRoute } from '@/helpers/router';

function DeleteDraftMissionModal({ t, close, id, history }) {
  return (
    <Mutation mutation={deleteMission} variables={{ id }}>
      {(mutate, { loading }) => (
        <Alert
          type="warning"
          close={close}
          onOk={async () => {
            await mutate();
            close();
            history.push(getMyMissionsRoute());
          }}
          onCancel={close}
          disableActions={loading}
          closable={!loading}
          text={t('myMissionBoard.modal.delete.draft_mission')}
          okText={t('draft_mission.deletion.modal.validation_button')}
          validateButtonClassName="Button--red"
        />
      )}
    </Mutation>
  );
}

DeleteDraftMissionModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  id: string.isRequired,
  history: objectOf(any).isRequired,
};

export default DeleteDraftMissionModal;
