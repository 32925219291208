import store from '@/redux/store';
import { showFlash } from '@/redux/modules/flash';

/**
 *
 * Show flash for each error in errors
 *
 * @param {Object.<string, string>} errors
 */
export const handleRestErrors = (errors) => {
  Object.entries(errors).map((validationError) =>
    store.dispatch(
      showFlash({
        kind: 'error',
        text: validationError.join(' '),
      })
    )
  );
};
