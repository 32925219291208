import { useEffect } from 'react';

function A11yListener() {
  function handleKeyDown() {
    document.body.classList.remove('using-mouse');
  }

  function handleMouseDown() {
    document.body.classList.add('using-mouse');
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown);
    return () => document.removeEventListener('mousedown', handleMouseDown);
  });

  return null;
}

export default A11yListener;
