import { compose } from 'recompose';
import translate from '@/helpers/enhancers/translate';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { graphql } from 'react-apollo';
import AddExpertPoolModal from './AddExpertPoolModal';
import { MISSIONS } from '@/constants/contractTypes';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import { addToClientPool } from '@/api/Company/mutations';
import { showFlash } from '@/redux/modules/flash';
import config from '@/_config';

export default compose(
  graphql(addToClientPool, { name: 'addToClientPool' }),
  reduxForm({
    form: 'company_add_expert_pool',
    initialValues: {
      contract_type: MISSIONS,
      salary: [config.company.salary.min, config.company.salary.max],
      daily_rate: [config.company.dailyRate.min, config.company.dailyRate.max],
      cv: [],
      settings: ['update_profile'],
      invitation_to_register: false,
    },
  }),
  connect(
    (state) => ({
      values: getFormValues('company_add_expert_pool')(state),
    }),
    { showFlash }
  ),
  withCurrentUser,
  translate()
)(AddExpertPoolModal);
