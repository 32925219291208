import React from 'react';
import pp from 'prop-types';
import { getBookmarkRoute } from '@/helpers/router';
import { length } from '@/helpers/validation';
import { RequiredField, RfText } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';

function BookmarkCreateModal({
  t,
  close,
  handleSubmit,
  submitting,
  createBookmark,
  history,
}) {
  async function onSubmit(values) {
    const {
      data: {
        createList: { id },
      },
    } = await createBookmark({
      variables: values,
      refetchQueries: ['getBookmarks'],
      awaitRefetchQueries: true,
    });
    close();
    history.push(getBookmarkRoute(id));
  }

  return (
    <Modal
      name="BookmarkCreate"
      size="medium"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('bookmarks.modal.create.title')}
      disableActions={submitting}
      closable={!submitting}
    >
      <RequiredField
        component={RfText}
        name="name"
        label={t('bookmarks.modal.create.label')}
        validate={[length({ max: 100 })]}
        className="m-b-xl"
      />
    </Modal>
  );
}

BookmarkCreateModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  handleSubmit: pp.func.isRequired,
  submitting: pp.bool.isRequired,
  createBookmark: pp.func.isRequired,
  history: pp.objectOf(pp.any).isRequired,
};

export default BookmarkCreateModal;
