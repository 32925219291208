import React from 'react';
import pp from 'prop-types';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import Field from '..';

const LabelledCheckboxField = (
  { label, ...props } // Dans le cas du LabelledCheckboxField, c'est le LabelledCheckbox qui est responsable de son label
) => (
  <Field
    {...props}
    render={({ getInputProps }) => (
      <LabelledCheckbox {...getInputProps({ label })} />
    )}
  />
);

LabelledCheckboxField.propTypes = {
  label: pp.string,
};

LabelledCheckboxField.defaultProps = {
  label: null,
};

export default LabelledCheckboxField;
