import { connect } from 'react-redux';
import { compose } from 'recompose';
import { closeModal } from '@/redux/modules/modal';
import ModalManager from './ModalManager';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';

export default compose(
  withCurrentUser,
  connect(
    (state) => state.modal,
    { closeModal }
  )
)(ModalManager);
