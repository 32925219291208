import { branch, renderComponent } from 'recompose';
import Loading from '@/components/Loading';

const renderWhileLoading = (propName = 'data') =>
  branch(
    (props) => props[propName] && props[propName].loading,
    renderComponent(Loading)
  );

export default renderWhileLoading;
