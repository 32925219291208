import React from 'react';
import { func, objectOf, any } from 'prop-types';
import { formatModalities } from '@/selectors/profile';
import ProfileModal from '../../ProfileModal';
import getSections from './sections';
import './styles.scss';

const ModalitiesModal = ({ t, kind, currentUser, leader, ...props }) => (
  <ProfileModal
    name="Modalities"
    title={t('company.modal.modalities.title')}
    form="company_modalities"
    formatValues={formatModalities}
    {...props}
  >
    {getSections({ kind, leader }).map(
      ({ component: S, id, ...otherProps }) => (
        <S
          key={id}
          companyKind={kind}
          leader={leader}
          t={t}
          currentUser={currentUser}
          {...otherProps}
        />
      )
    )}
  </ProfileModal>
);

ModalitiesModal.propTypes = {
  t: func.isRequired,
  leader: objectOf(any).isRequired,
};

export default ModalitiesModal;
