import reduxForm from '@/helpers/enhancers/reduxForm';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { synchronizeSireneApi } from '@/api/Company/LegalInformations/mutations';
import ChangeSirenModal from './ChangeSirenModal';

export default compose(
  graphql(synchronizeSireneApi, { name: 'synchronizeSireneApi' }),
  reduxForm({
    form: 'change_siren',
    initialValues: {},
  })
)(ChangeSirenModal);
