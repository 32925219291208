import React from 'react';
import { string, bool } from 'prop-types';
import Field from '../Field';
import TextInput from './TextInput';
import Tooltip from '@/components/Tooltip';
import useTranslation from '@/hooks/useTranslation';

const TextField = (
  { label, withTooltip, ...props } // Dans le cas du TextField, c'est le textInput qui est responsable de son label
) => {
  const { t } = useTranslation();
  return (
    <div>
      <Field
        {...props}
        render={({ getInputProps }) => {
          const Component = <TextInput {...getInputProps({ label })} />;
          return (
            <div>
              {withTooltip ? (
                <Tooltip title={t('postSignup.linkedinUrl.tooltip')}>
                  {Component}
                </Tooltip>
              ) : (
                <>{Component}</>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

TextField.propTypes = {
  label: string,
  withTooltip: bool,
};

TextField.defaultProps = {
  label: null,
  withTooltip: false,
};

export default TextField;
