import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import { func, string, arrayOf } from 'prop-types';
import { Mutation } from 'react-apollo';
import { deleteAttachment } from '@/api/Attachment/mutations';
import Modal from '@/components/Modal';
import { fileType } from '@/types/file';
import './styles.scss';

function CvDeleteModal({ close, file, refetchQueries }) {
  const { t } = useTranslation();
  return (
    <Mutation mutation={deleteAttachment} variables={{ id: file.id }}>
      {(mutate, { loading }) => (
        <Modal
          name="DeleteCv"
          closable
          close={close}
          onOk={async () => {
            await mutate({
              refetchQueries,
              awaitRefetchQueries: true,
            });
            close();
          }}
          disableActions={loading}
          onCancel={close}
          title={t('company.modal.cv_delete.title')}
        >
          <div>
            {t('company.modal.cv_delete.subtitle')}{' '}
            <span className="CvDeleteModal__filename">{file.name}</span>
          </div>
          <div className="CvDeleteModal__question m-t-m">
            {t('company.modal.cv_delete.question')}
          </div>
        </Modal>
      )}
    </Mutation>
  );
}

CvDeleteModal.propTypes = {
  close: func.isRequired,
  refetchQueries: arrayOf(string),
  file: fileType.isRequired,
};

CvDeleteModal.defaultProps = {
  refetchQueries: [],
};

export default CvDeleteModal;
