import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { getMissionCompanyMatching } from '@/api/Mission/queries';
import HourlyRateSection from './HourlyRateSection';

export default compose(
  graphql(getMissionCompanyMatching, {
    options: (props) => ({
      variables: {
        id: props.mission.id,
        company_id: props.company.id,
      },
    }),
  }),
  connect((state) => ({
    formValues: getFormValues('proposal_quote')(state),
  }))
)(HourlyRateSection);
