import React from 'react';
import { func, string, bool, oneOfType, instanceOf } from 'prop-types';
import { range, noop, isNull } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import translate from '@/helpers/enhancers/translate';
import { capitalize } from '@/helpers/string';
import Select from '@/components/Fields/SelectField';
import { DAY, MONTH, YEAR } from '@/constants/dateInputFields';

const getDayOptions = (month, year) => {
  const nbOfDays = moment(`${year}-${month + 1}`, 'YYYY-MM').daysInMonth();
  return range(1, nbOfDays + 1).map((y) => ({ label: y, value: y }));
};

const monthOptions = (locale) => {
  moment.locale(locale);
  return moment.months().map((m, i) => ({
    label: capitalize(m),
    value: i,
  }));
};

const yearOptions = range(moment().year(), 1939).map((y) => ({
  label: y,
  value: y,
}));

class DateInput extends React.PureComponent {
  constructor(props) {
    super(props);
    const { value } = props;
    this.state = {
      day: value ? moment(value).date() : 1,
      month: value ? moment(value).month() - 1 : null,
      year: value ? moment(value).year() : null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.date) {
      const date = moment(props.value);
      return {
        date: props.value,
        day: date.date() || null,
        month: !Number.isNaN(date.month()) ? date.month() : null,
        year: date.year() || null,
      };
    }
    return null;
  }

  // this function is called when a field is changed. It updates the local state when a specific condition is true (example, the month is null)
  // if all the fields are filled (the condition is false) the onChange props is called with the array of values [year, month, day]
  updateDate = (value, key, condition, inputs) => {
    if (condition) {
      this.setState({ [key]: value });
      return;
    }
    this.props.onChange(moment(inputs).toDate());
  };

  handleChange = (value, field) => {
    const { month, year, day } = this.state;
    const { displayDay } = this.props;
    let condition;
    let inputArray;
    switch (field) {
      case DAY:
        this.updateDate(value, DAY, isNull(month) || isNull(year), [
          year,
          month,
          value,
        ]);
        break;
      case MONTH:
        condition = displayDay ? isNull(year) || isNull(day) : isNull(year);
        inputArray = displayDay ? [year, value, day] : [year, value];
        this.updateDate(value, MONTH, condition, inputArray);
        break;
      case YEAR:
        condition = displayDay ? isNull(month) || isNull(day) : isNull(month);
        inputArray = displayDay
          ? [value, month || 0, day]
          : [value, month || 0];
        this.updateDate(value, YEAR, condition, inputArray);
        break;
      default:
    }
  };

  handleBlur = () => {
    this.props.onBlur(this.props.value);
  };

  render() {
    const { t, className, displayDay, locale, ...otherProps } = this.props;
    const { day, month, year } = this.state;
    return (
      <div className={cx('DateInput', className)}>
        {displayDay && (
          <Select
            {...otherProps}
            name={DAY}
            placeholder={t('fields.date.day')}
            options={getDayOptions(month, year)}
            onChange={(value) => this.handleChange(value, DAY)}
            onBlur={this.handleBlur}
            value={day}
          />
        )}
        <Select
          {...otherProps}
          name={MONTH}
          placeholder={t('fields.date.month')}
          options={monthOptions(locale)}
          onChange={(value) => this.handleChange(value, MONTH)}
          onBlur={this.handleBlur}
          value={month}
        />
        <Select
          {...otherProps}
          name={YEAR}
          placeholder={t('fields.date.year')}
          options={yearOptions}
          onChange={(value) => this.handleChange(value, YEAR)}
          onBlur={this.handleBlur}
          value={year}
        />
      </div>
    );
  }
}

DateInput.propTypes = {
  t: func.isRequired,
  className: string,
  onChange: func,
  onBlur: func,
  disabled: bool,
  value: oneOfType([instanceOf(Date), string]),
  displayDay: bool,
};

DateInput.defaultProps = {
  className: null,
  onChange: noop,
  onBlur: noop,
  disabled: false,
  value: null,
  displayDay: false,
};

export default translate()(DateInput);
