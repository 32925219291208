import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import './styles.scss';

function FormSubSection({ title, children, className }) {
  return (
    <section className={cx('Form__subSection', className)}>
      {title && <h5 className="Form__subLabel">{title}</h5>}
      {children}
    </section>
  );
}

FormSubSection.propTypes = {
  title: pp.node,
  children: pp.node,
  className: pp.string,
};

FormSubSection.defaultProps = {
  title: null,
  children: null,
  className: null,
};

export default FormSubSection;
