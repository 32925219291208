import React from 'react';
import { func } from 'prop-types';
import { get } from 'lodash';
import { Field } from 'redux-form';
import {
  RequiredField,
  RfHelper,
  RfText,
  RfEditor,
  RfFiles,
  RfDate,
  RfLabelledCheckbox,
  RfTags,
} from '@/containers/ReduxForm';
import { date, presence } from '@/helpers/validation';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE_DEGREE } from '@/constants/forms';
import TagsContext from '@/components/Tags/TagsContext';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { getFormattedContentWithBrTag } from '@/helpers/formatContent';

const DegreeModal = ({ t, ...props }) => {
  const { initialValues } = props;
  const description = get(initialValues, 'description');

  return (
    <ProfileModal
      title={t('company.modal.degree.title')}
      form={PROFILE_DEGREE}
      formatValues={({ skills, attachments, current, end_at, ...values }) => ({
        ...values,
        skills: formatTags(skills),
        attachment_ids: attachments.map((a) => a.id),
        end_at: current ? null : end_at,
      })}
      {...props}
      // If the description already exists, it needs to be formatted to keep the layout
      {...description && {
        initialValues: {
          ...initialValues,
          description: getFormattedContentWithBrTag(description),
        },
      }}
    >
      <FormSection>
        <RequiredField
          id="name"
          name="name"
          label={t('company.modal.degree.institution.label')}
          className="m-b-m"
          component={RfText}
        />
        <RequiredField
          id="entitled"
          name="entitled"
          label={t('company.modal.degree.diploma.label')}
          className="m-b-m"
          component={RfText}
        />
        <div className="grid">
          <div className="grid__item laptop--one-whole one-half">
            <RequiredField
              id="begin_at"
              name="begin_at"
              label={t('fields.date.from')}
              className="m-b-s"
              component={RfDate}
              picker={false}
              validate={[date({})]}
            />
          </div>
          <div className="grid__item laptop--one-whole one-half">
            <RfHelper values={['current', 'begin_at']}>
              {({ current, begin_at }) => (
                <Field
                  id="end_at"
                  name="end_at"
                  label={t('fields.date.to')}
                  component={RfDate}
                  picker={false}
                  disabled={current}
                  validate={[
                    date({
                      if: (values) => !values.current,
                      '>': begin_at || '',
                    }),
                    presence({
                      if: (values) => !values.current,
                    }),
                  ]}
                />
              )}
            </RfHelper>
            <Field
              type="checkbox"
              id="current"
              name="current"
              component={RfLabelledCheckbox}
              label={t('company.modal.degree.current.label')}
              className="m-b-s"
            />
          </div>
        </div>
        <Field
          id="description"
          name="description"
          label={t('company.modal.degree.description.label')}
          placeholder={t('company.modal.degree.description.placeholder')}
          component={RfEditor}
          className="m-b-m"
        />
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(),
          }}
        >
          <Field
            id="degree-skills"
            name="skills"
            component={RfTags}
            description="company.modal.degree.skills.label"
            {...getSkillsValidationAndExtraProps()}
          />
        </TagsContext.Provider>
        <Field
          component={RfFiles}
          multiple
          id="attachments"
          name="attachments"
          label={t('company.modal.degree.justificatory.label')}
          text={t('company.modal.degree.justificatory.text')}
          infoText={t('company.modal.degree.justificatory.infoText')}
          rejectText={t('company.modal.degree.justificatory.rejectText')}
          t={t}
        />
      </FormSection>
    </ProfileModal>
  );
};

DegreeModal.propTypes = {
  t: func.isRequired,
};

export default DegreeModal;
