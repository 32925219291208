import React from 'react';
import { func, bool, string, objectOf, any } from 'prop-types';
import { getMyMissionBoardRoute } from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';
import {
  RequiredField,
  Field,
  RfArea,
  RfChoices,
} from '@/containers/ReduxForm';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import Modal from '@/components/Modal';

const radioProps = [
  {
    id: 'one',
    value: 'one',
    label: 'proposal.modal.refuse.kind.one',
  },
  {
    id: 'two',
    value: 'two',
    label: 'proposal.modal.refuse.kind.two',
  },
  {
    id: 'three',
    value: 'three',
    label: 'proposal.modal.refuse.kind.three',
  },
  {
    id: 'four',
    value: 'four',
    label: 'proposal.modal.refuse.kind.four',
  },
];

function RefuseModal({
  close,
  handleSubmit,
  submitting,
  refuseProposal,
  history,
  proposalId,
  missionId,
}) {
  const { t } = useTranslation();

  async function onSubmit({ kind, text }) {
    await refuseProposal({
      variables: { id: proposalId, kind, text },
    });
    close();
    history.push(getMyMissionBoardRoute(missionId));
  }

  return (
    <Modal
      name="ProposalRefuse"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('proposal.modal.refuse.title')}
      subtitle={t('proposal.modal.refuse.subtitle')}
      okText={t('proposal.modal.refuse.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <RequiredField
        id="kind"
        name="kind"
        component={RfChoices}
        type="radioGroup"
        checkbox={LabelledRadio}
        cbProps={radioProps.map((p) => ({ ...p, label: t(p.label) }))}
        className="m-b-m"
      />
      <Field
        id="text"
        name="text"
        component={RfArea}
        placeholder={t('proposal.modal.refuse.text.placeholder')}
      />
    </Modal>
  );
}

RefuseModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  refuseProposal: func.isRequired,
  history: objectOf(any).isRequired,
  proposalId: string.isRequired,
  missionId: string.isRequired,
};

export default RefuseModal;
