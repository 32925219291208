import React, { Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import 'normalize.css/normalize.css';
import '@/styles/application.scss';
import history from '@/helpers/history';
import ModalSwitch from '@/router/ModalSwitch';
import ErrorBoundary from '@/containers/ErrorBoundary';
import ModalManager from '@/components/ModalManager';
import FlashManager from '@/components/FlashManager';
import Loading from '@/components/Loading';
import A11yListener from './A11yListener';
import I18nListener from './I18nListener';
import Head from './Head';

function App() {
  return (
    <Router history={history}>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <div className="app">
            <Head />
            <Route component={ModalSwitch} />
            <ModalManager />
            <FlashManager />
            <A11yListener />
            <I18nListener />
          </div>
        </Suspense>
      </ErrorBoundary>
    </Router>
  );
}

export default App;
