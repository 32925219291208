/*
/!\ PLEASE DO NOT USE THE DEFAULT EXPORT OF THIS FILE TO GET COMPANY TYPES
    INSTEAD USE HELPER getCompanyTypes() from @/helper/company.js
*/

export const bigFourAndSimilar = {
  label: 'fields.practice_culture.big_4_and_similar',
  value: 'big_4_and_similar',
  id: 'big_4_and_similar',
  selectable: true,
  solo: true,
};

export const angloSaxonFirms = {
  label: 'fields.practice_culture.anglo_saxon_firms',
  value: 'anglo_saxon_firms',
  id: 'anglo_saxon_firms',
  selectable: true,
  solo: true,
};

export const frenchInternationalBusinessLawFirm = {
  label: 'fields.practice_culture.french_international_business_law_firm',
  value: 'french_international_business_law_firm',
  id: 'french_international_business_law_firm',
  selectable: true,
  solo: true,
};

export const fullServiceLawFirmOver50 = {
  label: 'fields.practice_culture.full_service_law_firm_over_50',
  value: 'full_service_law_firm_over_50',
  id: 'full_service_law_firm_over_50',
  selectable: true,
  solo: true,
};

export const fullServiceLawFirmUnder50 = {
  label: 'fields.practice_culture.full_service_law_firm_under_50',
  value: 'full_service_law_firm_under_50',
  id: 'full_service_law_firm_under_50',
  selectable: true,
  solo: true,
};

export const specializedLawFirm = {
  label: 'fields.practice_culture.specialized_law_firm',
  value: 'specialized_law_firm',
  id: 'specialized_law_firm',
  selectable: true,
  solo: true,
};

export const generalPracticeLawFirm = {
  label: 'fields.practice_culture.general_practice_law_firm',
  value: 'general_practice_law_firm',
  id: 'general_practice_law_firm',
  selectable: true,
  solo: true,
};

export const councilOfStateLawFirm = {
  label: 'fields.practice_culture.council_of_state_law_firm',
  value: 'council_of_state_law_firm',
  id: 'council_of_state_law_firm',
  selectable: true,
  solo: true,
};

export const individualLawPractitioner = {
  label: 'fields.practice_culture.individual_law_practitioner',
  value: 'individual_law_practitioner',
  id: 'individual_law_practitioner',
  selectable: true,
  solo: true,
};

export const legalDepartmentOfAFrenchCompany = {
  label: 'fields.practice_culture.legal_department_of_a_french_company',
  value: 'legal_department_of_a_french_company',
  id: 'legal_department_of_a_french_company',
  selectable: true,
  solo: true,
};

export const legalDepartmentOfAnInternationalCompany = {
  label: 'fields.practice_culture.legal_department_of_an_international_company',
  value: 'legal_department_of_an_international_company',
  id: 'legal_department_of_an_international_company',
  selectable: true,
  solo: true,
};

const practiceCultureChoices = {
  bigFourAndSimilar,
  angloSaxonFirms,
  frenchInternationalBusinessLawFirm,
  fullServiceLawFirmOver50,
  fullServiceLawFirmUnder50,
  specializedLawFirm,
  generalPracticeLawFirm,
  councilOfStateLawFirm,
  individualLawPractitioner,
  legalDepartmentOfAFrenchCompany,
  legalDepartmentOfAnInternationalCompany,
};

export default practiceCultureChoices;
