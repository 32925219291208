import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { refuseProposal } from '@/api/Proposal/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import RefuseModal from './RefuseModal';

export default compose(
  graphql(refuseProposal, { name: 'refuseProposal' }),
  reduxForm({
    form: 'proposal_refuse',
  }),
  withRouter
)(RefuseModal);
