import React from 'react';
import { func, string, objectOf, any } from 'prop-types';
import { Field, RfEditor } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';

const InterviewsConductModal = ({ t, kind, leader, ...props }) => (
  <ProfileModal
    title={t('company.modal.interviews_conduct.title')}
    form="company_interviews_conduct"
    {...props}
  >
    <FormSection>
      <Field
        component={RfEditor}
        name="interviews_conduct"
        label={t('company.modal.interviews_conduct.label')}
      />
    </FormSection>
  </ProfileModal>
);

InterviewsConductModal.propTypes = {
  t: func.isRequired,
  kind: string.isRequired,
  leader: objectOf(any).isRequired,
};

export default InterviewsConductModal;
