import { DAYS, HOURS, UNITS, WORK_UNITS } from '@/constants/proposalUnit';

export default function getPricingUnitKindLabel(t, unit) {
  switch (unit) {
    case DAYS:
      return t('proposal.modal.quote.pricing.unit_day');
    case UNITS:
      return t('proposal.modal.quote.pricing.unit');
    case WORK_UNITS:
      return t('proposal.modal.quote.pricing.work_unit');
    case HOURS:
      return t('proposal.modal.quote.pricing.unit_hour');
    default:
      return null;
  }
}
