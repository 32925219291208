import uuid from 'uuid/v1';
import { get, reject } from 'lodash';
import storageKeys from '@/redux/modules/storage/keys';
import { queryParams } from '@/helpers/query';
import { KEYCODES } from '@/constants/keyCodes';
import refinements from '@/components/Header/SearchBar/refinements';
import { ReactComponent as Search } from '@/images/search.svg';
import { ReactComponent as Delete } from '@/images/delete.svg';
import React from 'react';
import Refinement from '@/components/Header/SearchBar/components/Refinement';
import Suggestion from '@/components/Header/SearchBar/components/Suggestion';
import SearchButtons from '@/components/Header/SearchBar/components/SearchButtons';
import { Link } from 'react-router-dom';
import { isExpertOrMixed } from '@/helpers/user';

export const focus = (input) => {
  input.current.focus();
};

/**
 * Handle entry to history search
 * Set to storage the new key:value
 * @param entry
 * @param searchHistory
 * @param setStorageValue
 */
export const addEntryToHistory = (entry, searchHistory, setStorageValue) => {
  if (!entry || !entry.name) return;

  const newEntries = [
    { ...entry, id: uuid() },
    ...reject(searchHistory, { name: entry.name }),
  ].slice(0, 5);

  setStorageValue(storageKeys.searchHistory, newEntries);
};

/**
 * Opens menu when input is focus
 * @param openMenu
 * @param setState
 */
export const handleInputFocus = (openMenu, setState) => {
  openMenu();
  setState((prevState) => ({
    ...prevState,
    displayHome: true,
    active: true,
  }));
};

/**
 * Set active to false when focus is lost
 * @param setState
 */
export const handleInputBlur = (setState) => {
  setState((prevState) => ({
    ...prevState,
    active: false,
  }));
};

/**
 * Handle query and display when outside click is triggered
 */
export const handleOuterClick = (history, setState) => {
  const search = get(history, 'location.search');
  setState({
    value: queryParams(search, 'query') || '',
    displayHome: false,
    active: false,
  });
};

/**
 * Handling search
 * Adds entry to history
 * Handle loosing focus with blur event
 * Closes menu
 * If no user is logged, opens a modal for sign-up
 * @param pathname
 * @param historyEntry
 * @param currentUser
 * @param guestSearchCount
 * @param input
 * @param history
 * @param close
 * @param openModal
 * @param entry
 * @param searchHistory
 * @param setStorageValue
 */
export const goSearch = (
  pathname,
  historyEntry,
  currentUser,
  guestSearchCount,
  input,
  history,
  close,
  openModal,
  searchHistory,
  setStorageValue,
  entry
) => {
  if (currentUser || guestSearchCount < 15) {
    input.current.blur();
    addEntryToHistory(entry, searchHistory, setStorageValue);
    history.push(pathname);
    close();
  } else {
    openModal('signup');
  }
};

export const handleSubmit = (props) => {
  const {
    pathname,
    historyEntry,
    currentUser,
    guestSearchCount,
    input,
    history,
    close,
    openModal,
    searchHistory,
    setStorageValue,
    entry,
  } = props;
  goSearch(
    pathname,
    historyEntry,
    currentUser,
    guestSearchCount,
    input,
    history,
    close,
    openModal,
    searchHistory,
    setStorageValue,
    entry
  );
};

/**
 * Handle keyboard event (trigger search and close menu)
 * @param keyCode
 * @param value
 * @param closeMenu
 * @param highlightedIndex
 * @param getSearchQueryURI
 * @param currentUser
 * @param guestSearchCount
 * @param input
 * @param history
 * @param close
 * @param openModal
 * @param searchHistory
 * @param setStorageValue
 * @param createHistory
 */
export const handleInputKeydown = async (
  pathname,
  keyCode,
  value,
  closeMenu,
  highlightedIndex,
  getSearchQueryURI,
  currentUser,
  guestSearchCount,
  input,
  history,
  close,
  openModal,
  searchHistory,
  setStorageValue,
  createHistory
) => {
  if (keyCode === KEYCODES.enter) {
    if (highlightedIndex) return;
    closeMenu();
    goSearch(
      pathname,
      value,
      currentUser,
      guestSearchCount,
      input,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue
    );
    if (get(currentUser, 'id')) {
      await createHistory({
        variables: {
          key: 'new_search',
          attr: { value },
        },
      });
    }
  }
};

/**
 *
 * @param item
 * @param cProps
 * @param getSearchQueryURI
 * @param guestSearchCount
 * @param currentUser
 * @param input
 * @param history
 * @param close
 * @param openModal
 * @param searchHistory
 * @param setStorageValue
 * @param searchConfig
 * @param createHistory
 * @returns {null|void}
 */
export const handleChange = async (
  item,
  cProps,
  currentUser,
  getSearchQueryURI,
  guestSearchCount,
  input,
  history,
  close,
  openModal,
  searchHistory,
  setStorageValue,
  searchConfig,
  createHistory
) => {
  if (item && item.index) {
    // eslint-disable-next-line no-param-reassign
    searchConfig.historyEntry = {
      ...item,
      name: cProps.itemToString(item),
    };
  }

  if (item) {
    if (get(currentUser, 'id')) {
      await createHistory({
        variables: {
          key: 'new_search',
          attr: {
            // The hits returned by algolia have an index data, for client kind the suggestion can have an 'Expert' index (equal to the Company index)
            value:
              item.index === 'Expert' || item.index === 'Company'
                ? item.name_anonymous
                : item.name,
          },
        },
      });
    }
    return goSearch(
      searchConfig.pathname,
      searchConfig.historyEntry,
      currentUser,
      guestSearchCount,
      input,
      history,
      close,
      openModal,
      searchHistory,
      setStorageValue
    );
  }
  return null;
};

/**
 * Handle Downshift onStateChange (its internal state)
 * @param changes
 * @param setState
 */
export const handleStateChange = (changes, setState) => {
  /* eslint-disable no-prototype-builtins */
  if (changes.hasOwnProperty('selectedItem')) {
    setState((prevState) => ({ ...prevState, value: changes.selectedItem }));
  } else if (changes.hasOwnProperty('inputValue')) {
    setState((prevState) => ({ ...prevState, value: changes.inputValue }));
  }
};

/**
 * Render search bar input
 * @param cProps
 * @param active
 * @param t
 * @param currentUser
 * @param location
 * @param input
 * @param onFocus
 * @param onBlur
 * @param onChange
 * @param onKeyDown
 * @param label
 * @returns {*}
 */
export const handleRenderControl = (
  cProps,
  active,
  t,
  currentUser,
  location,
  input,
  onFocus,
  onBlur,
  onChange,
  onKeyDown,
  onSubmitSearch,
  onSubmitAllResult,
  label
) => {
  const { getInputProps, inputValue, clearSelection } = cProps;

  return (
    <div className="SearchBar__root">
      <form className="SearchBar__form">
        <label htmlFor="searchBar" className="SearchBar__wrapper">
          <Search className="SearchBar__action" />
          <input
            {...getInputProps({
              name: 'q',
              type: 'search',
              id: 'searchBar',
              className: 'SearchBar__control',
              placeholder: t('header.search.placeholder'),
              ref: input,
              onFocus: () => onFocus(cProps),
              onBlur,
              onChange,
              onKeyDown: (e) => onKeyDown(e, cProps),
              value: label,
            })}
          />
          {inputValue && (
            <button
              className="SearchBar__action"
              type="button"
              onClick={() => clearSelection()}
            >
              <Delete />
            </button>
          )}
        </label>
      </form>
      {/* TODO: Delete this helper isExpertOrMixed and use User model */}
      {(isExpertOrMixed(currentUser) || !currentUser) && (
        <div className="SearchBar__research">
          <SearchButtons
            cProps={cProps}
            onSubmitSearch={onSubmitSearch}
            onSubmitAllResult={onSubmitAllResult}
            currentUser={currentUser}
          />
        </div>
      )}
    </div>
  );
};

/**
 * Renders block such as
 * Suggestions
 * Experts
 * Missions
 * @param userRefinements
 * @param inputValue
 * @param t
 * @returns {*}
 */
export const handleRenderRefinements = (userRefinements, inputValue, t) => (
  <section className="SearchBar__section">
    <header>
      <small className="SearchBar__section-heading">
        {t('header.search.discover')}
      </small>
    </header>
    <ul className="SearchBar__refinements">
      {userRefinements.map(({ label, ...props }) => (
        <Refinement
          key={get(props, 'id')}
          label={t(label)}
          inputValue={inputValue}
          {...props}
        />
      ))}
    </ul>
  </section>
);

/**
 * Renders history list
 * @param userHistory
 * @param cProps
 * @param t
 * @returns {*}
 */
export const handleRenderHistory = (userHistory, cProps, t) => (
  <section className="SearchBar__section">
    <header>
      <small className="SearchBar__section-heading">
        {t('header.search.history')}
      </small>
    </header>
    <ul className="SearchBar__history">
      {userHistory.map((item, index) => (
        <Suggestion
          key={item.id}
          item={item}
          index={index}
          hightlight={false}
          {...cProps}
        />
      ))}
    </ul>
  </section>
);

/**
 * Render suggestions list
 * @param suggestions
 * @param t
 * @param cProps
 * @param filterName
 * @returns {[]|*[]|[{content: string}, {components: string, name: string}, {name: string, context: {Dropzone: string}, sections: [{name: string, content: string}, {name: string, content: string}, {name: string, content: string}, {name: string, content: string}, {name: string, content: string}]}]|{content: string}|[{name: string, content: string}, {name: string, content: string}, {name: string, content: string}, {name: string, content: string}, {name: string, content: string}]|{name: string, content: string}|*}
 */
export const handleRenderSuggestionsList = (
  suggestions,
  t,
  cProps,
  filterName = null
) =>
  suggestions.reduce(
    (result, { hits, index, sectionName, sectionLink }) => {
      if (!hits.length) return result;
      result.sections.push(
        <section className="SearchBar__section" key={`section_${index}`}>
          {sectionName && (
            <header>
              <small className="SearchBar__section-heading">
                {t(sectionName, { filter: t(filterName) })}
              </small>
              {sectionLink && (
                <Link
                  className="SearchBar__section-all"
                  to={sectionLink}
                  onClick={cProps.closeMenu}
                >
                  {t('header.search.seeAll')}
                </Link>
              )}
            </header>
          )}
          <ul>
            {hits.map((item) => {
              const hitIndex = result.itemIndex++; // eslint-disable-line no-plusplus, no-param-reassign
              return (
                <Suggestion
                  key={item.objectID}
                  item={{ ...item, index }}
                  index={hitIndex}
                  {...cProps}
                />
              );
            })}
          </ul>
        </section>
      );
      return result;
    },
    { sections: [], itemIndex: 0 }
  ).sections;

/**
 * Render dropdown
 * Each tenant can render more feature with children
 * @param cProps
 * @param searchHistory
 * @param t
 * @param displayHome
 * @param children
 * @param renderRefinements
 * @param renderHistory
 * @returns {null|*}
 */
export const handleRenderDropdown = (
  cProps,
  searchHistory,
  t,
  displayHome,
  renderRefinements,
  renderHistory,
  children
) => {
  const { getMenuProps, isOpen } = cProps;
  if (!isOpen) return null;

  const userRefinements = get(refinements, 'currentUser.kind', []);
  const userHistory = searchHistory.filter((entry) => !!entry.index);

  const showRefinements = userRefinements.length > 1;
  const showHistory = userHistory.length > 0;

  if (displayHome && !showRefinements && !showHistory) return null;
  return (
    <div
      {...getMenuProps({
        className: 'SearchBar__dropdown',
      })}
    >
      {displayHome &&
        showRefinements &&
        renderRefinements(userRefinements, cProps)}
      {displayHome && showHistory && renderHistory(userHistory, cProps)}
      {children && children}
    </div>
  );
};
