import React from 'react';
import { get } from 'lodash';
import ProfileModal from '../../ProfileModal';
import { Field, RfTags } from '@/containers/ReduxForm';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE } from '@/constants/features';
import TagsContext from '@/components/Tags/TagsContext';
import { EQUIPMENTS } from '@/constants/forms';
import useTranslation from '@/hooks/useTranslation';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { lengthSkills } from '@/helpers/validation';
import { setEquipmentsInitialValues } from '@/helpers/equipments';
import config from '@/_config';

const EquipmentModal = ({ ...props }) => {
  const { equipments: currentEquipments } = props;
  const { t } = useTranslation();

  const getInitialValues = () => {
    const ratingInitialValues = setEquipmentsInitialValues(currentEquipments);
    return { ...ratingInitialValues, equipments: currentEquipments };
  };

  return (
    <ProfileModal
      title={t('company.modal.equipments.title')}
      form={EQUIPMENTS}
      formatValues={({ equipments }) => ({
        equipments: formatTags(equipments),
      })}
      initialValues={getInitialValues()}
      deletable={false}
      {...props}
    >
      <div>
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(PROFILE),
            max: get(config, 'validation.expertProfile.minSkillsLength'), // default is skill, feel free to create config for your own type of tag
            highlightMaxValues: get(config, 'skills.highlightMax'),
            ratingWithNumberField: true,
          }}
        >
          <Field
            id="equipments"
            name="equipments"
            component={RfTags}
            description="profile.form.equipments_required.description"
            {...getSkillsValidationAndExtraProps([
              lengthSkills({
                min: 0,
              }),
            ])}
          />
        </TagsContext.Provider>
      </div>
    </ProfileModal>
  );
};

export default EquipmentModal;
