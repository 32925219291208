import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';

function Section({ children, name, title }) {
  return (
    <section className={cx('Footer__section', `Footer__section--${name}`)}>
      {title && <h2 className="Footer__section-title">{title}</h2>}
      {children}
    </section>
  );
}

Section.propTypes = {
  children: pp.node.isRequired,
  name: pp.string.isRequired,
  title: pp.string,
};

Section.defaultProps = {
  title: null,
};

export default Section;
