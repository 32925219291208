/* eslint-disable no-underscore-dangle */

import React from 'react';
import pp from 'prop-types';
import { change } from 'redux-form';
import { ReactComponent as Trash } from '@/images/trash.svg';
import { ReactComponent as Plus } from '@/images/plus.svg';
import CompanyDigest from '@/components/CompanyDigest';
import './styles.scss';

function CompaniesField({ fields, dispatch }) {
  function handleActionClick(entity, index) {
    dispatch(
      change('bookmark_edit', `${fields.name}[${index}]`, {
        ...entity,
        _destroy: !entity._destroy,
      })
    );
  }

  return (
    <div className="CompaniesField">
      {fields.map((field, i) => {
        const entity = fields.get(i);
        return (
          <div className="CompaniesField__item" key={field}>
            <CompanyDigest company={entity.entity} />
            <button
              className="CompaniesField__item-action"
              type="button"
              onClick={() => handleActionClick(entity, i)}
            >
              {entity._destroy ? <Plus /> : <Trash />}
            </button>
          </div>
        );
      })}
    </div>
  );
}

CompaniesField.propTypes = {
  dispatch: pp.func.isRequired,
  fields: pp.objectOf(pp.any).isRequired,
};

export default CompaniesField;
