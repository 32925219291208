import React, { useCallback, useEffect, useState, useRef } from 'react';
import { func, bool, objectOf, any } from 'prop-types';
import { get, first, isNull, debounce } from 'lodash';
import { getCgus as getCgusQuery } from '@/api/Cgu/queries';
import { acceptance } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfLabelledCheckbox } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import RichText from '@/components/RichText';
import { getLogoutRoute } from '@/helpers/router';
import Tooltip from '@/components/Tooltip';
import '@/components/TermsReader/styles.scss';
import './styles.scss';

function CgusModal({
  acceptCgus,
  client,
  close,
  currentUser,
  handleSubmit,
  submitting,
  history,
  formValues,
}) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    loading: true,
    cgus: null,
  });

  const containerRef = useRef(null);
  const scrollableRef = useRef(null);
  const [cguRead, setCguRead] = useState(false);
  const [cguValidated, setCguValidated] = useState(false);

  const getCgus = useCallback(
    async () => {
      if (currentUser) {
        const response = await client.query({
          query: getCgusQuery,
          variables: {
            limit: 1,
            status: ['online'],
          },
        });

        if (response.error) {
          close();
        } else {
          setState({
            cgus: response.data.cgus,
            loading: false,
          });

          // set cgu read at true if content is short enough to dont need to scroll
          if (
            containerRef &&
            scrollableRef &&
            get(containerRef.current, 'clientHeight') ===
              get(scrollableRef.current, 'clientHeight')
          ) {
            setCguRead(true);
          }
        }
      }
    },
    [client, close, currentUser]
  );

  // To improve perf, a debounce is used to avoid computing the scroll value for every pixel scrolled
  const debouncedHandleScroll = useRef(
    debounce(() => {
      const containerEl = containerRef.current;
      const scrollableEl = scrollableRef.current;

      // containerEl.scrollTop represent the value of pixel scrolled inside the container
      // scrollableEl.clientHeight - containerEl.clientHeight represents the height of the scrollable element
      // a 0.95 coefficient is used because the measurement may differ by a few pixels
      if (
        !cguRead &&
        containerEl &&
        containerEl.scrollTop >
          0.95 * (scrollableEl.clientHeight - containerEl.clientHeight)
      ) {
        setCguRead(true);
      }
    }, 100)
  ).current;

  const handleScroll = () => {
    debouncedHandleScroll();
  };

  useEffect(
    () => {
      getCgus();
    },
    [getCgus]
  );

  // Disable the submit button according to the accept_terms checkbox value and and cguRead state
  const disableSubmit = () => {
    if (get(formValues, 'accept_terms') && cguRead) {
      setCguValidated(true);
    } else {
      setCguValidated(false);
    }
  };

  useEffect(
    () => {
      disableSubmit();
    },
    [formValues, cguRead]
  );

  if (!currentUser) return null;

  const name = isNull(get(currentUser, 'company', null))
    ? get(currentUser, 'sign_up_company_name')
    : get(currentUser, 'company.name');

  if (state.loading) return null;

  const cgu = first(state.cgus);
  const { text, changelog } = cgu;

  if (!cgu) {
    close();
    return null;
  }

  async function onSubmit({ accept_terms }) {
    if (!accept_terms) return;
    await acceptCgus({ variables: { id: cgu.id } });
    close();
  }

  const onCancelClick = () => {
    close();
    history.push(getLogoutRoute());
  };

  function acceptTermsComponent() {
    return (
      <RequiredField
        id="accept_terms"
        name="accept_terms"
        type="checkbox"
        component={RfLabelledCheckbox}
        label={t('cgus.accept', { companyName: name })}
        validate={[acceptance()]}
        className="m-t-s"
        disabled={!cguRead}
      />
    );
  }

  return (
    <Modal
      name="CGUs"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={onCancelClick}
      title={t('cgus.title')}
      subtitle={t('cgus.subtitle')}
      disableOk={submitting || !cguValidated}
      cancelText={t('cgus.refuse')}
      cancellable
      hideClose
      overlayClassName="Modal__overlay Modal__overlay--no-padding"
      shouldCloseOnOverlayClick={false}
    >
      <div className="TermsReader TermsReader--box">
        {changelog && (
          <div className="TermsReader__changelog">
            <RichText>{changelog}</RichText>
          </div>
        )}
        <div
          className="TermsReader__text"
          ref={containerRef}
          onScroll={handleScroll}
        >
          <p
            className="RichText"
            dangerouslySetInnerHTML={{ __html: text }}
            ref={scrollableRef}
          />
        </div>
      </div>

      {cguRead ? (
        <>{acceptTermsComponent()}</>
      ) : (
        <Tooltip placement="top-start" title="cgus.validation.tooltip.title">
          {acceptTermsComponent()}
        </Tooltip>
      )}
    </Modal>
  );
}

CgusModal.propTypes = {
  acceptCgus: func.isRequired,
  client: objectOf(any).isRequired,
  close: func.isRequired,
  currentUser: objectOf(any).isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  history: objectOf(any).isRequired,
  formValues: objectOf(any).isRequired,
};

export default CgusModal;
