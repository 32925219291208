import React, { useState } from 'react';
import { func } from 'prop-types';
import { get } from 'lodash';
import { propType } from 'graphql-anywhere';
import legalInformationsFragment from '@/api/Company/LegalInformations/fragment';
import useTranslation from '@/hooks/useTranslation';
import { RequiredField, RfSiren } from '@/containers/ReduxForm';
import Modal from '@/components/Modal';
import config from '@/_config';

const ChangeSirenModal = ({
  handleSubmit,
  close,
  synchronizeSireneApi,
  legalInformation,
}) => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const onSubmit = async (values) => {
    setDisable(true);
    setLoadingSubmit(true);
    const res = await synchronizeSireneApi({
      variables: {
        id: get(legalInformation, 'id'),
        siret: get(values, 'siret'),
        asynchronous: false,
      },
    });

    // check if connection with api gouv work fine
    if (
      get(
        res,
        'data.synchronizeSireneApiLegalInformation.bar_registration_validated_data_gouv'
      )
    ) {
      window.location.reload(false);
      setLoadingSubmit(false);
      setDisable(false);
    }
  };

  return (
    <Modal
      name="change_siren"
      form="change_siren"
      close={close}
      onCancel={close}
      onOk={handleSubmit(onSubmit)}
      disableOk={disable}
      cancelText={t('settings.company.modal.change_siren.delete')}
    >
      <div>
        <h2 className="m-b-m">
          {t('settings.company.modal.change_siren.title')}
        </h2>
        <p className="m-b-s">
          {t('settings.company.modal.change_siren.information_message')}
        </p>
        {config.settings.showSirenWarningMessage && (
          <p className="m-b-s">
            {t(
              'settings.company.modal.change_siren.information_message.warning.siren'
            )}
          </p>
        )}
        <p className="m-b-l fw-bold">
          {t(
            'settings.company.modal.change_siren.information_message.warning.crush_data'
          )}
        </p>
        <div className="grid">
          <div className="grid__item tablet--one-whole one-half m-b-m">
            <RequiredField
              id="siret"
              name="siret"
              component={RfSiren}
              inputProps={{
                label: t('postSignup.registration.siren.label'),
                placeholder: t('postSignup.registration.siren.label'),
              }}
              className="m-b-m"
              // connect siren field with disable action of validate button
              // expose disable state to custom opening downshift
              setDisableValidation={setDisable}
              disableValidation={disable}
              // expose also loading to complete the behaviour of the downshift
              loadingSubmit={loadingSubmit}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

ChangeSirenModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  synchronizeSireneApi: func.isRequired,
  legalInformation: propType(legalInformationsFragment).isRequired,
};

export default ChangeSirenModal;
