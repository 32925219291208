import React from 'react';
import { func, string } from 'prop-types';
import Button from '@/components/Button';

function Signup({ t, className, openModal }) {
  function handleClick() {
    openModal('signup', {
      initialValues: {
        kind: 'expert',
      },
    });
  }

  return (
    <Button onClick={handleClick} className={className}>
      {t('header.nav.signup')}
    </Button>
  );
}

Signup.propTypes = {
  t: func.isRequired,
  className: string,
  openModal: func.isRequired,
};

Signup.defaultProps = {
  className: null,
};

export default Signup;
