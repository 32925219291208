import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { createBookmark } from '@/api/Bookmark/mutations';
import reduxForm from '@/helpers/enhancers/reduxForm';
import NewList from './NewList';

export default compose(
  graphql(createBookmark, { name: 'createBookmark' }),
  reduxForm({ form: 'bookmark_new_list' })
)(NewList);
