import {
  SKILLS_NAME,
  COMPANY_SKILLS_NAME,
  PERSONAL_SKILLS_NAME,
  COMPANY_PERSONAL_SKILLS_NAME,
  OPTIONAL_SKILLS_NAME,
  JOB_NAME,
  ENTITLED,
  COMPANY_JOB_NAME,
  NAME,
  LEGAL_FORM,
  EQUIPMENTS_NAME,
  BAR_CITY,
  INDIVIDUAL_CERTIFICATION_SKILLS_NAME,
} from '@/constants/fields';
import config from '@/_config';

const limitedListMap = {
  [SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: 'skills.list',
  },
  [COMPANY_SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: 'skills.list',
  },
  [PERSONAL_SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: 'personal.skills.list',
  },
  [COMPANY_PERSONAL_SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: 'personal.skills.list',
  },
  [OPTIONAL_SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: config.skills.optionalSkillsIsPersonalSkills
      ? 'personal.skills.list'
      : 'skills.list', // to remove, see comment in config
  },
  [INDIVIDUAL_CERTIFICATION_SKILLS_NAME]: {
    active: config.skills.limitedList,
    translation: 'skills.list',
  },
  [JOB_NAME]: {
    active: config.company.jobNameLimitedList,
    translation: 'job_name.list',
  },
  [ENTITLED]: {
    active: config.company.certificationEntitledLimitedList,
    // Make two separates lists if the job_name and certification entitled list changed,
    translation: 'certification_name.list',
  },
  [COMPANY_JOB_NAME]: {
    active: config.company.companyJobNameLimitedList,
    // Make two separates lists if the job_name and certification entitled list changed,
    translation: 'job_name.list',
  },
  [NAME]: {
    active: config.mission.nameLimitedList,
    // Make two separates lists if the job_name and certification entitled list changed,
    translation: 'job_name.list',
  },
  [LEGAL_FORM]: {
    active: true,
    // Make two separates lists if the job_name and certification entitled list changed,
    translation: 'legal_form.list',
  },
  [EQUIPMENTS_NAME]: {
    active: config.equipments.limitedList,
    translation: 'equipments.list',
  },
  [BAR_CITY]: {
    active: true,
    translation: 'bar_city.list',
  },
};

const SEPARATOR = '|';

// Return a list as array
// simpleList: ['item1', 'item2', ...]
// list: [{name: 'item1'}, {name: 'item2'}, ...]
export const getlimitedList = (t, field, simpleList = false) => {
  function GetLimitedList() {
    const list = t(limitedListMap[field].translation);
    return list.split(SEPARATOR).reduce((acc, value) => {
      if (simpleList) {
        return [...acc, { label: value, value }];
      }
      return [...acc, { name: value }];
    }, []);
  }
  return GetLimitedList;
};

export const isLimitedlistActived = (field) =>
  limitedListMap[field] ? limitedListMap[field].active : false;
