export const searchableAttributes = [
  'job',
  'experiences_job_name',
  'skills_name',
  'experiences_company_name',
  'sub_categories_name_phrase_fr',
  'sub_categories_name_phrase_en',
  'sectors',
  'location',
  'name_anonymous',
  'about_sanitized',
];
