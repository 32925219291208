import { TRACK_PAGE, TRACK_EVENT } from '@/redux/modules/tracking';

export default () => (next) => (action) => {
  if (action.type === TRACK_PAGE) {
    window.analytics.page(action.pageName);
    return next(action);
  }

  if (action.type === TRACK_EVENT) {
    window.analytics.track(action.eventName);
    return next(action);
  }

  return next(action);
};
