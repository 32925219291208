import React from 'react';
import { func, bool } from 'prop-types';
import { get } from 'lodash';
import { Field } from 'redux-form';
import uuid from 'uuid/v1';
import { date, presence } from '@/helpers/validation';
import {
  RequiredField,
  RfHelper,
  RfDate,
  RfText,
  RfLocation,
  RfLabelledCheckbox,
  RfEditor,
  RfTags,
} from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import TagsContext from '@/components/Tags/TagsContext';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { getFormattedContentWithBrTag } from '@/helpers/formatContent';

const generatedAutocomplete = uuid();

const ExperienceModal = ({ t, ...props }) => {
  const { initialValues } = props;
  const description = get(initialValues, 'description');

  return (
    <ProfileModal
      title={t('company.modal.experience.title')}
      form="company_experience"
      formatValues={({ location, current, end_at, skills, ...values }) => ({
        ...values,
        location: location && location.address,
        end_at: current ? null : end_at,
        skills: formatTags(skills),
      })}
      {...props}
      // If the description already exists, it needs to be formatted to keep the layout
      {...description && {
        initialValues: {
          ...initialValues,
          description: getFormattedContentWithBrTag(description),
        },
      }}
    >
      <FormSection>
        <RequiredField
          id="company_name"
          name="company_name"
          label={t('company.modal.experience.company.label')}
          className="m-b-m"
          component={RfText}
        />
        <RequiredField
          id="job_name"
          name="job_name"
          label={t('company.modal.experience.job.label')}
          className="m-b-m"
          component={RfText}
        />
        <Field
          id="location"
          name="location"
          className="m-b-m"
          component={RfLocation}
          autoComplete={generatedAutocomplete}
          searchOptions={{ types: ['(cities)'] }}
          inputProps={{
            label: t('company.modal.experience.location.label'),
            placeholder: t('locations.input.placeholder'),
          }}
        />
        <div className="grid">
          <div className="grid__item laptop--one-whole one-half">
            <RequiredField
              id="begin_at"
              name="begin_at"
              label={t('fields.date.from')}
              className="m-b-s"
              component={RfDate}
              picker={false}
            />
          </div>
          <div className="grid__item laptop--one-whole one-half">
            <RfHelper values={['current', 'begin_at']}>
              {({ current, begin_at }) => (
                <Field
                  id="end_at"
                  name="end_at"
                  label={t('fields.date.to')}
                  component={RfDate}
                  picker={false}
                  disabled={current}
                  validate={[
                    date({
                      if: (values) => !values.current,
                      '>': begin_at || '',
                    }),
                    presence({
                      if: (values) => !values.current,
                    }),
                  ]}
                />
              )}
            </RfHelper>
            <Field
              type="checkbox"
              id="current"
              name="current"
              component={RfLabelledCheckbox}
              label={t('company.modal.experience.current.label')}
              className="m-b-s"
            />
          </div>
        </div>
        <Field
          id="description"
          name="description"
          label={t('company.modal.experience.description.label')}
          placeholder={t('company.modal.experience.description.placeholder')}
          component={RfEditor}
        />
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(),
          }}
        >
          <Field
            id="experience-skills"
            name="skills"
            component={RfTags}
            description="company.section.experiences.technicalEnv.label"
            {...getSkillsValidationAndExtraProps()}
          />
        </TagsContext.Provider>
      </FormSection>
    </ProfileModal>
  );
};

ExperienceModal.propTypes = {
  t: func.isRequired,
  edit: bool,
};

ExperienceModal.defaultProps = {
  edit: false,
};

export default ExperienceModal;
