import React from 'react';
import { func, bool, string } from 'prop-types';
import { Field, RfArea } from '@/containers/ReduxForm';
import { length } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';

function InviteToCreateProposalModal({
  channelId,
  close,
  handleSubmit,
  submitting,
  inviteToCreateProposalMission,
  missionId,
}) {
  const { t } = useTranslation();

  async function onSubmit({ message }) {
    await inviteToCreateProposalMission({
      variables: { mission_id: missionId, channel_id: channelId, message },
      refetchQueries: [
        'getChannel',
        'getChannelAttachments',
        'getLastConversations',
        'getMessages',
      ],
    });
    close();
  }

  return (
    <Modal
      name="InviteToCreateProposal"
      size="medium"
      close={close}
      onOk={handleSubmit(onSubmit)}
      onCancel={close}
      title={t('conversation.modal.invite_to_create_proposal.title')}
      subtitle={t('conversation.modal.invite_to_create_proposal.subtitle')}
      okText={t('conversation.modal.invite_to_create_proposal.ok')}
      disableActions={submitting}
      closable={!submitting}
    >
      <Field
        name="message"
        component={RfArea}
        label={t('conversation.modal.invite_to_create_proposal.message.label')}
        validate={[length({ max: 500 })]}
        max={500}
      />
    </Modal>
  );
}

InviteToCreateProposalModal.propTypes = {
  close: func.isRequired,
  handleSubmit: func.isRequired,
  submitting: bool.isRequired,
  inviteToCreateProposalMission: func.isRequired,
  channelId: string.isRequired,
  missionId: string.isRequired,
};

export default InviteToCreateProposalModal;
