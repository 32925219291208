import { tenant } from './environment';

export const TAWK_TO_IDS = {
  expert: {
    fr: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_EXPERT_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_EXPERT_FR_WIDGET_ID`
        ],
    },
    en: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_EXPERT_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_EXPERT_EN_WIDGET_ID`
        ],
    },
  },
  client: {
    fr: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_FR_WIDGET_ID`
        ],
    },
    en: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_EN_WIDGET_ID`
        ],
    },
  },
  mixed: {
    fr: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_FR_WIDGET_ID`
        ],
    },
    en: {
      propertyId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_PROPERTY_ID`
        ],
      widgetId:
        process.env[
          `REACT_APP_TAWK_TO_${tenant.toUpperCase()}_CLIENT_MIXED_EN_WIDGET_ID`
        ],
    },
  },
};

export const DEFAULT_LANGUAGE = 'fr';
