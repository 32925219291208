import React from 'react';
import {
  RequiredField,
  Field,
  RfHelper,
  RfPassword,
  RfSelect,
  RfText,
  RfPhone,
} from '@/containers/ReduxForm';
import { EXPERT, CLIENT } from '@/constants/userKind';
import { values } from 'lodash';
import userKinds from '@/constants/fields/userKinds';
import { email, phone, length } from '@/helpers/validation';
import useTranslation from '@/hooks/useTranslation';
import SigninLinkedinButton from '@/components/SigninLinkedinButton';
import config from '@/_config/config';
import './styles.scss';

function SignupForm() {
  const { t } = useTranslation();

  const SignUpCompanyNameComponent = config.routes.signup
    .requiredCompanyNameField
    ? RequiredField
    : Field;

  return (
    <>
      <RfHelper if="kind">
        {({ kind }) => {
          if (kind === EXPERT) {
            return (
              <>
                <h2 className="SignupForm__title">{t('auth.signup.title')}</h2>
                <span className="tc d-b p-b-m">
                  {t(`auth.signup.subtitle.${kind}`)}
                </span>
                {config.global.linkedinLogin && (
                  <>
                    <SigninLinkedinButton />
                    <div className="SignupForm__separator">
                      <span>{t('auth.signup.separator')}</span>
                    </div>
                  </>
                )}
              </>
            );
          }
          return (
            <>
              <h2 className="SignupForm__title">
                {t('auth.signup.client.title')}
              </h2>
              <span className="tc d-b p-b-m">
                {t(`auth.signup.subtitle.${kind}`)}
              </span>
            </>
          );
        }}
      </RfHelper>
      <span className="tc d-b p-b-m fw-bold primary">
        {t('auth.signup.description.label')}
      </span>
      <div className="SignupForm__fields">
        <RequiredField
          component={RfSelect}
          name="kind"
          options={values(userKinds).map((c) => ({
            ...c,
            label: t(`${c.label}.signup`),
          }))}
          className="m-b-m"
        />
        <RequiredField
          name="first_name"
          component={RfText}
          label={t('signup.modal.firstName.label')}
          className="m-b-s d-b"
        />
        <RequiredField
          name="last_name"
          component={RfText}
          label={t('signup.modal.lastName.label')}
          className="m-b-s"
        />
        <RfHelper if="kind">
          {({ kind }) => {
            const displayPhoneField =
              (kind === EXPERT && config.routes.signup.expertPhoneField) ||
              (kind === CLIENT && config.routes.signup.clientPhoneField);

            return (
              <>
                {config.routes.signup.displayCompanyNameField &&
                  kind === CLIENT && (
                    <SignUpCompanyNameComponent
                      component={RfText}
                      id="sign_up_company_name"
                      name="sign_up_company_name"
                      placeholder={t('auth.signup.company_name.placeholder')}
                      label={t('auth.signup.company_name.label')}
                      className="m-b-s"
                    />
                  )}
                {displayPhoneField && (
                  <RequiredField
                    component={RfPhone}
                    id="phone"
                    name="phone"
                    autoComplete="tel"
                    label={t('auth.signup.phone.label')}
                    validate={[phone()]}
                    className="m-b-s"
                  />
                )}
              </>
            );
          }}
        </RfHelper>
        <RequiredField
          name="email"
          type="email"
          component={RfText}
          validate={[email()]}
          label={t('signup.modal.email.label')}
          className="m-b-s"
        />
        <RequiredField
          name="password"
          component={RfPassword}
          validate={[length({ min: 8 })]}
          label={t('signup.modal.password.label')}
          className="m-b-m"
        />
      </div>
    </>
  );
}

export default SignupForm;
