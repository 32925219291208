export const HOME = '/';

export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const CONFIRM_EMAIL = '/confirm-email';
export const RESEND_EMAIL_CONFIRMATION = '/resend-email-confirmation';
export const LOGOUT = '/logout';

export const SIGNUP = '/signup';
// the customer sends an invitation email to register and the invited member accepts the invitation by registering
export const ACCEPT_INVITATION = '/accept-invitation';
export const POST_SIGNUP = '/post-signup';
export const POST_SIGNUP_CV_PARSING = '/post-signup-cv-parsing';

export const PROFILE = '/profile';
export const BOOKMARKS = '/bookmarks';
export const BOOKMARK = '/bookmarks/:id';
export const SETTINGS = '/settings';
export const SETTINGS_DOCUMENTS = '/settings#documents';
export const HELP = '/terms';

export const MISSIONS = '/missions';
export const MISSIONS_MATCHING = '/matches';
export const NEW_MISSION = '/missions/new';
export const MISSION = '/missions/:id';
export const NEW_PROPOSAL = '/missions/:id/new-proposal';
export const PROPOSALS = '/proposals';
export const PROPOSAL = '/proposals/:id';

export const CLIENT_POOL = '/client-pool';
export const CLIENT_POOLS = '/client-pools';

export const INVOICES = '/invoices';

export const CONTRACTS = '/contracts';
export const CONTRACT = '/contracts/:id';

export const CLIENTS = '/clients';
export const CLIENT = '/clients/:id';

export const EXPERTS = '/experts';
export const EXPERT = '/experts/:id';

export const MY_MISSIONS = '/my-missions';
export const MY_MISSION_BOARD = '/my-missions/:id/board';
export const MY_MISSION_PROPOSALS = '/my-missions/:id/proposals';
export const MY_MISSION_MATCHES = '/my-missions/:id/matches';
export const MY_MISSION_INVITES = '/my-missions/:id/invites';
export const MY_MISSION_EDIT = '/my-missions/:id/edit';

export const MY_APPLICATIONS = '/my-applications';

export const CONVERSATIONS = '/conversations';
export const CONVERSATION = '/conversations/:id';

export const WALLET = '/wallet';

export const NOTIFICATIONS = '/notifications';

export const NDA = '/nda';
export const MY_MISSION_NDA = '/my-missions/:id/nda';

/* EXTERNAL */
export const RCPRO =
  'https://candidat.tawk.help/article/comment-fonctionne-loption-assurance-responsabilité-civile-professionnelle-kicklox-avec-axa';
