import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { createBookmark } from '@/api/Bookmark/mutations';
import translate from '@/helpers/enhancers/translate';
import reduxForm from '@/helpers/enhancers/reduxForm';
import BookmarkCreateModal from './BookmarkCreateModal';

export default compose(
  graphql(createBookmark, { name: 'createBookmark' }),
  reduxForm({ form: 'bookmark_create' }),
  translate(),
  withRouter
)(BookmarkCreateModal);
