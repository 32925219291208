import { formatCompany as format } from '@/selectors/postSignup/common';
import contractTypeHandler from '../helpers/contractTypeHandler';

export const formatCompany = (
  {
    company_salary,
    company_contract_types,
    company_salary_confidentiality,
    company_daily_rate,
    company_billings,
    ...otherValues
  },
  kind
) => ({
  ...format({ ...otherValues, company_contract_types, kind }),
  ...contractTypeHandler(
    company_contract_types,
    company_salary,
    company_daily_rate,
    company_billings,
    company_salary_confidentiality,
    'company'
  ),
});
