import React from 'react';
import { Trans } from 'react-i18next';
import { string, func } from 'prop-types';
import { propType } from 'graphql-anywhere';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import useTranslation from '@/hooks/useTranslation';
import { CREATE } from '@/components/Contract/constants/modalMaps';
import { isCreateContext } from '@/components/Contract/helpers/isCreateContext';
import Charges from '@/components/Charges';
import DaysWorkedInput from '@/components/Input/DaysWorkedInput';
import DatesInput from '@/components/Input/DatesInput';
import HoursWorkedInput from '@/components/Input/HoursWorkedInput';
import { HOUR } from '@/constants/costPerKind';
import { clientKeysVariables } from '@/helpers/contextKeys';
import config from '@/_config';

function MilestoneATForm({ context, milestone, change, contract }) {
  const { t } = useTranslation();
  const isCreationContext = isCreateContext(context);
  const TimeWorkedComponent =
    config.proposal.costPer === HOUR ? HoursWorkedInput : DaysWorkedInput;
  const timeWorkedComponentProps =
    config.proposal.costPer === HOUR ? { contract } : {};

  /* The create and update inputs are not in the same order because of UX choice */
  function renderCreateForm() {
    return (
      <>
        <TimeWorkedComponent context={context} {...timeWorkedComponentProps} />
        <DatesInput
          isCreationContext={isCreationContext}
          milestone={milestone}
        />
        <Charges change={change} />
      </>
    );
  }

  function renderUpdateForm() {
    return (
      <>
        <DatesInput
          isCreationContext={isCreationContext}
          milestone={milestone}
        />
        <TimeWorkedComponent context={context} {...timeWorkedComponentProps} />
        <Charges change={change} />
      </>
    );
  }

  return (
    <>
      {isCreationContext ? (
        <p>
          <Trans
            i18nKey="milestone.technical_assistance.subtitle.create"
            values={{
              message: t('milestone.technical_assistance.subtitle.create'),
            }}
            components={[<span className="fw-bold">message</span>]}
          />
        </p>
      ) : (
        <p className="fw-bold m-b-m">
          {t('contract.milestone.technical_assistance.subtitle.update')}
        </p>
      )}
      {isCreationContext ? renderCreateForm() : renderUpdateForm()}
      {isCreationContext ? (
        <p>{t('milestone.technical_assistance.footer.create')}</p>
      ) : (
        <p>
          <Trans
            i18nKey="milestone.technical_assistance.footer.update"
            values={{
              sentence: t('milestone.technical_assistance.footer.update'),
              ...clientKeysVariables(t),
            }}
            components={[<span className="fw-bold">sentence</span>]}
          />
        </p>
      )}
    </>
  );
}

MilestoneATForm.propTypes = {
  context: string,
  milestone: propType(contractMilestoneFragment),
  change: func.isRequired,
  contract: propType(contractFragment),
};

MilestoneATForm.defaultProps = {
  context: CREATE,
  milestone: {
    id: '',
    begin_date: '',
    units_worked: null,
    deliverables: [],
    description: null,
    duty_ratio: null,
    end_date: '',
    price: null,
    status: '',
    title: '',
    total_ttc: null,
    vat: null,
  },
};

export default MilestoneATForm;
