import { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';

function useNativeSearch({
  options,
  highlightedIndex,
  setHighlightedIndex,
  itemToString,
  isOpen,
}) {
  const [query, setQuery] = useState('');
  const [mode, setMode] = useState('APPEND');

  const findHighlightIndex = useCallback(
    (offset = 0) =>
      _.findIndex(
        options,
        (o) =>
          _
            .deburr(itemToString(o))
            .toLowerCase()
            .startsWith(query),
        offset
      ),
    [query] // eslint-disable-line react-hooks/reactive-deps
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (!isOpen) return;
      if (!e.charCode) return;

      const char = String.fromCharCode(e.charCode);

      const nextQuery = mode === 'APPEND' ? query + char : char;

      setQuery(nextQuery);
    },
    [query, isOpen, mode]
  );

  useEffect(
    () => {
      setMode('APPEND');
      const queryTimeout = setTimeout(() => {
        setMode('REPLACE');
      }, 300);
      return () => clearTimeout(queryTimeout);
    },
    [query] // eslint-disable-line react-hooks/reactive-deps
  );

  useEffect(
    () => {
      if (!query) return;

      const nextHighlight = findHighlightIndex(
        highlightedIndex === null ? 0 : highlightedIndex + 1
      );

      if (nextHighlight !== -1) {
        setHighlightedIndex(nextHighlight);
      } else {
        const firstOccurenceIndex = findHighlightIndex();
        if (firstOccurenceIndex !== -1) {
          setHighlightedIndex(firstOccurenceIndex);
        }
      }
    },
    [query] // eslint-disable-line react-hooks/reactive-deps
  );

  useEffect(
    () => {
      document.addEventListener('keypress', handleKeyPress, true);
      return () => {
        document.removeEventListener('keypress', handleKeyPress, true);
      };
    },
    [handleKeyPress]
  );
}

export default useNativeSearch;
