import React from 'react';
import pp from 'prop-types';
import { Field } from 'redux-form';
import { required } from '@/helpers/validation';

const RequiredField = ({ validate, ...props }) => (
  <Field {...props} validate={[required(), ...validate]} required />
);

RequiredField.propTypes = {
  validate: pp.arrayOf(pp.func),
};

RequiredField.defaultProps = {
  validate: [],
};

export default RequiredField;
