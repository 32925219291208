import { nth, get } from 'lodash';
import moment from 'moment';

export default function getChargeTitle(charge, t) {
  if (!charge) return null;
  const title = get(charge, 'title', '');
  const weekMatch = title.match(/week_(\d+)/);
  if (weekMatch) {
    return t('proposal.section.milestones.technical_assistance.title.week', {
      count: nth(weekMatch, 1),
    });
  }

  const monthMatch = charge.title.match(/month_(\d+)/);
  if (monthMatch) {
    return moment()
      .month(nth(monthMatch, 1) - 1)
      .format('MMMM');
  }

  return title;
}

/**
 * this function check if a chargeUnit is valid or not, when a chargeUnit is deleted and then not valid, it has a _destroy key
 *
 * @param {array} charges
 * @returns {boolean}
 */
export const hasValidCharge = (charges = []) =>
  charges.some((charge) => {
    if (!get(charge, '_destroy', false)) {
      return true;
    }
    return false;
  });
