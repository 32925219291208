import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import { isNull, first, castArray, map, partialRight, get } from 'lodash';
import { getArea, getVisitDate } from '@/selectors/customFields';
import { formatTags } from '@/components/Tags/helpers/handler';
import initialValues from '@/components/MissionForm/initialValues';
import { MISSION } from '@/constants/forms.js';
import config from '@/_config';

export const newMissionSelector = formValueSelector(MISSION);
export const newMissionFieldSelector = (field) =>
  partialRight(newMissionSelector, field);

export const wantNda = newMissionFieldSelector('nda');

export const getNdaFiles = createSelector(
  [wantNda, newMissionFieldSelector('nda_attachment')],
  (_wantNda, ndaFiles) => (_wantNda ? ndaFiles : [])
);

const customFields = ['area', 'visit_date'];

export const formatMission = ({
  contract_type,
  sector,
  work_days,
  end_months,
  skills,
  optional_skills,
  personal_skills,
  tools,
  individual_certification_skills,
  ...values
}) => ({
  ...values,
  skills: skills ? formatTags(skills) : [],
  optional_skills: optional_skills ? formatTags(optional_skills) : [],
  personal_skills: personal_skills ? formatTags(personal_skills) : [],
  tools: tools ? formatTags(tools) : [],
  individual_certification_skills: individual_certification_skills
    ? formatTags(individual_certification_skills)
    : [],
  contract_types: castArray(contract_type),
  work_days: first(work_days),
  end_months: first(end_months),
  custom_fields: customFields
    .map((key) => values[key] && { key, value: values[key] })
    .filter((v) => v),
});

export const getEditableMission = ({
  contract_types,
  begin_at,
  end_at,
  end_months,
  limited_at,
  work_days,
  location,
  budget,
  nda_attachment,
  custom_fields,
  languages,
  optional_languages,
  job,
  company_level,
  daily_rate,
  salary,
  work_hours,
  ...values
}) => {
  const visit_date = getVisitDate(custom_fields);
  return {
    ...values,
    contract_type: first(contract_types),
    begin_at: begin_at ? new Date(begin_at) : null,
    end_at: end_at ? new Date(end_at) : null,
    end_months: castArray(end_months || 0),
    limited_at: limited_at ? new Date(limited_at) : null,
    work_days: castArray(work_days),
    work_hours: work_hours ? castArray(work_hours) : null,
    location_place: location,
    budget: budget || [
      config.company.budgetRate.min,
      config.company.budgetRate.max,
    ],
    daily_rate: daily_rate || [
      config.company.dailyRate.min,
      config.company.dailyRate.max,
    ],
    salary,
    nda_attachment: isNull(nda_attachment) ? [] : [nda_attachment],
    area: getArea(custom_fields),
    visit_date: visit_date ? new Date(visit_date) : null,
    languages_alpha2: map(languages, 'alpha2'),
    optional_languages_alpha2: map(optional_languages, 'alpha2'),
    name: get(job, 'name'),
    company_level: isNull(company_level)
      ? get(initialValues, 'company_level', null)
      : company_level,
  };
};
