import React from 'react';
import { string, func } from 'prop-types';
import { ReactComponent as RemoveIcon } from '@/images/delete.svg';
import Button from '@/components/Button';

function TagRemoveButton({ classes, handleRemove }) {
  return (
    <Button
      onClick={handleRemove}
      variants={['transparent']}
      className={`TagRemoveButton ${classes}`}
    >
      <RemoveIcon />
    </Button>
  );
}

TagRemoveButton.propTypes = {
  classes: string,
  handleRemove: func.isRequired,
};

TagRemoveButton.defaultProps = {
  classes: null,
};

export default TagRemoveButton;
