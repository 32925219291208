import React from 'react';
import { string, objectOf, any, oneOfType } from 'prop-types';
import cx from 'classnames';
import Link from '@/components/Link';

const HeaderLink = ({ className, to, innerRef, ...props }) => {
  const classNames = cx('Header__link', className);
  return to ? (
    <Link
      exact
      to={to}
      activeClassName="Header__link--active"
      className={classNames}
      innerRef={innerRef}
      {...props}
    />
  ) : (
    <button type="button" className={classNames} ref={innerRef} {...props} />
  );
};

HeaderLink.propTypes = {
  className: string,
  to: oneOfType([string, objectOf(any)]),
};

HeaderLink.defaultProps = {
  className: null,
  to: null,
};

export default HeaderLink;
