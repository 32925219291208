import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop, get } from 'lodash';
import { ReactComponent as Delete } from '../../../images/delete.svg';
import './styles.scss';

class TextInput extends React.Component {
  state = {
    isFocused: false,
  };

  inputRef = React.createRef();

  componentDidMount() {
    const { autoFocus, value } = this.props;
    switch (autoFocus) {
      case 'pristine':
        return !value && this.focus();
      case true:
        return this.focus();
      case false:
      default:
        return null;
    }
  }

  getRef = () => this.props.inputRef || this.inputRef;

  focus = () => this.getRef().current.focus();

  handleFocus = (isFocused) => (e) => {
    this.setState({ isFocused });
    if (isFocused) {
      this.props.onFocus(e);
    } else {
      this.props.onBlur(e);
    }
  };

  handleClear = async () => {
    // 💩💩💩
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set;
    nativeInputValueSetter.call(this.getRef().current, '');

    const event = new Event('input', { bubbles: true });
    await this.getRef().current.dispatchEvent(event);
    this.props.onClear();
  };

  render() {
    const {
      disabled,
      required,
      value,
      label,
      placeholder,
      autoComplete,
      className,
      onChange,
      name,
      id,
      clearable,
      icon,
      onClear,
      inputRef,
      autoFocus,
      ...props
    } = this.props;

    const { isFocused } = this.state;
    const displayClear = clearable && !!value.length;
    const displayIcon = icon && !displayClear;

    // For type=number check if value is '' which means the value is empty
    // and the labelClasses cannot be set to TextInput__label--outside
    const hasValue = (() => {
      if (get(props, 'type') === 'number') {
        return value !== '';
      }
      return value;
    })();

    const labelClasses = cx('Field__label', 'TextInput__label', {
      'TextInput__label--outside': isFocused || hasValue,
    });
    return (
      <div className={cx('TextInput', className)}>
        <input
          {...props}
          onFocus={this.handleFocus(true)}
          onBlur={this.handleFocus(false)}
          onChange={onChange}
          disabled={disabled}
          required={required}
          value={value}
          id={id}
          name={name}
          className={cx('TextInput__control', {
            'TextInput__control--icon': displayClear || displayIcon,
          })}
          placeholder={!isFocused && label ? '' : placeholder}
          autoComplete={autoComplete}
          ref={this.getRef()}
        />
        {label && (
          <label
            onClick={this.handleLabelClick}
            htmlFor={id}
            className={labelClasses}
          >
            {label}
          </label>
        )}
        {displayClear && (
          <button
            type="button"
            className="TextInput__clear"
            onClick={this.handleClear}
            tabIndex={-1}
          >
            <Delete />
          </button>
        )}
        {displayIcon && <span className="TextInput__picto">{icon}</span>}
      </div>
    );
  }
}

TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  clearable: PropTypes.bool,
  icon: PropTypes.node,
};

TextInput.defaultProps = {
  type: 'text',
  value: '',
  label: null,
  placeholder: '',
  className: '',
  required: false,
  onChange: noop,
  onClear: noop,
  onFocus: noop,
  onBlur: noop,
  disabled: false,
  id: null,
  name: undefined,
  autoComplete: null,
  autoFocus: false,
  clearable: false,
  icon: null,
};

export default TextInput;
