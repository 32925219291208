import React from 'react';
import { func, arrayOf, string } from 'prop-types';
import { FieldArray } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ChargeUnits from '@/components/ChargeUnits';

function ForfeitSections({ t, change, chargeUnits }) {
  return (
    <FormSection title={t('proposal.modal.quote.pricing.title')}>
      <FieldArray
        id="charges"
        name="charges_attributes"
        component={ChargeUnits}
        chargeUnits={chargeUnits}
        change={change}
      />
    </FormSection>
  );
}

ForfeitSections.propTypes = {
  t: func.isRequired,
  change: func.isRequired,
  chargeUnits: arrayOf(string),
};

ForfeitSections.defaultProps = {
  chargeUnits: [],
};

export default ForfeitSections;
