import React from 'react';
import pp from 'prop-types';
import companySizes from '@/constants/fields/companySizes';
import { RequiredField, RfSelect } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';

function SizeSection({ t, className }) {
  return (
    <FormSection title={t('company.modal.modalities.size.title')}>
      <RequiredField
        name="size"
        component={RfSelect}
        label={t('company.modal.modalities.size.label')}
        placeholder={t('company.modal.modalities.size.placeholder')}
        options={companySizes.map((c) => ({
          ...c,
          label: t(c.label),
        }))}
        className={className}
      />
    </FormSection>
  );
}

SizeSection.propTypes = {
  t: pp.func.isRequired,
  className: pp.string,
};

SizeSection.defaultProps = {
  className: null,
};

export default SizeSection;
