import 'react-app-polyfill/ie11';
import 'core-js/es';
import './i18n';
import './helpers/sentry';
import App from './containers/App';
import render from './helpers/render';
import * as serviceWorker from './serviceWorker';

render(App);

if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App);
  });
}

serviceWorker.unregister();
