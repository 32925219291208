import { compose } from 'recompose';
import reduxForm from '@/helpers/enhancers/reduxForm';
import { connect } from 'react-redux';
import { closeModal } from '@/redux/modules/modal';
import BoardModal from './BoardModal';

export default compose(
  reduxForm(),
  connect(
    null,
    { closeModal }
  )
)(BoardModal);
