import { ReactComponent as Box } from '@/images/box.svg';
import { ReactComponent as MoneyTime } from '@/images/money-time.svg';

export default [
  {
    id: 'technical_assistance',
    value: 'technical_assistance',
    label: 'fields.billings.techAssist',
    icon: MoneyTime,
  },
  {
    id: 'forfeit',
    value: 'forfeit',
    label: 'fields.billings.forfait',
    icon: Box,
  },
];
