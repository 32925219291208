import React from 'react';
import MediaQuery from 'react-responsive';
import { get } from 'lodash';
import { BREAKPOINTS } from '@/constants/screen';
import useTranslation from '@/hooks/useTranslation';
import { ReactComponent as Wheel } from '@/images/wheel.svg';
import Popover from '@/components/Popover';
import Drawer from '@/components/Drawer';
import Tooltip from '@/components/Tooltip';
import BoardMenu from './BoardMenu';

function BoardSettings(props) {
  const { t } = useTranslation();
  return (
    <MediaQuery maxWidth={BREAKPOINTS.tablet}>
      {(matches) =>
        matches ? (
          <Drawer
            renderReference={({ open }) => (
              <button
                type="button"
                className="MyMissionBoard__heading-action m-l-s"
                onClick={open}
              >
                <Wheel />
              </button>
            )}
          >
            <Drawer.Screen
              name="default"
              header={({ className }) => (
                <h1 className={className}>{t('myMissionBoard.menu.title')}</h1>
              )}
            >
              {({ close }) => <BoardMenu close={close} {...props} />}
            </Drawer.Screen>
          </Drawer>
        ) : (
          <Popover
            placement="bottom-end"
            renderReference={({ ref, getButtonProps }) => (
              <button
                type="button"
                {...getButtonProps({
                  ref,
                  className: get(props, 'buttonClassName'),
                })}
              >
                <Tooltip
                  placement="bottom-end"
                  title="myMissionBoard.menu.tooltip.title"
                >
                  <Wheel />
                </Tooltip>
              </button>
            )}
            renderPopper={({ close }) => (
              <BoardMenu
                close={close}
                closeModal={get(props, 'closeModal')}
                {...props}
              />
            )}
          />
        )
      }
    </MediaQuery>
  );
}

export default BoardSettings;
