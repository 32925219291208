import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { connectAutoComplete } from 'react-instantsearch/connectors';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import translate from '@/helpers/enhancers/translate';
import { setStorageValue } from '@/redux/modules/storage';
import { openModal } from '@/redux/modules/modal';
import {
  getSearchHistory,
  getGuestSearchCount,
  getSelectedSuggestionFilter,
} from '@/selectors/storage';
import SearchBarMixed from './SearchBarMixed';
import transformSuggestions from '@/components/Header/SearchBar/helpers/transformSuggestions';
import { createHistory } from '@/api/History/mutations';

export default compose(
  graphql(createHistory, { name: 'createHistory' }),
  connectAutoComplete,
  withRouter,
  connect(
    (state) => ({
      searchHistory: getSearchHistory(state),
      guestSearchCount: getGuestSearchCount(state),
      selectedSuggestionFilter: getSelectedSuggestionFilter(state),
    }),
    { setStorageValue, openModal }
  ),
  translate(),
  transformSuggestions
)(SearchBarMixed);
