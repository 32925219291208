export default [
  { label: 'fields.languages.aa', value: 'aa' },
  { label: 'fields.languages.ab', value: 'ab' },
  { label: 'fields.languages.ae', value: 'ae' },
  { label: 'fields.languages.af', value: 'af' },
  { label: 'fields.languages.ak', value: 'ak' },
  { label: 'fields.languages.am', value: 'am' },
  { label: 'fields.languages.an', value: 'an' },
  { label: 'fields.languages.ar', value: 'ar' },
  { label: 'fields.languages.as', value: 'as' },
  { label: 'fields.languages.av', value: 'av' },
  { label: 'fields.languages.ay', value: 'ay' },
  { label: 'fields.languages.az', value: 'az' },
  { label: 'fields.languages.ba', value: 'ba' },
  { label: 'fields.languages.be', value: 'be' },
  { label: 'fields.languages.bg', value: 'bg' },
  { label: 'fields.languages.bh', value: 'bh' },
  { label: 'fields.languages.bi', value: 'bi' },
  { label: 'fields.languages.bm', value: 'bm' },
  { label: 'fields.languages.bn', value: 'bn' },
  { label: 'fields.languages.bo', value: 'bo' },
  { label: 'fields.languages.br', value: 'br' },
  { label: 'fields.languages.bs', value: 'bs' },
  { label: 'fields.languages.ca', value: 'ca' },
  { label: 'fields.languages.ce', value: 'ce' },
  { label: 'fields.languages.ch', value: 'ch' },
  { label: 'fields.languages.co', value: 'co' },
  { label: 'fields.languages.cr', value: 'cr' },
  { label: 'fields.languages.cs', value: 'cs' },
  { label: 'fields.languages.cu', value: 'cu' },
  { label: 'fields.languages.cv', value: 'cv' },
  { label: 'fields.languages.cy', value: 'cy' },
  { label: 'fields.languages.da', value: 'da' },
  { label: 'fields.languages.de', value: 'de' },
  { label: 'fields.languages.dv', value: 'dv' },
  { label: 'fields.languages.dz', value: 'dz' },
  { label: 'fields.languages.ee', value: 'ee' },
  { label: 'fields.languages.el', value: 'el' },
  { label: 'fields.languages.en', value: 'en' },
  { label: 'fields.languages.eo', value: 'eo' },
  { label: 'fields.languages.es', value: 'es' },
  { label: 'fields.languages.et', value: 'et' },
  { label: 'fields.languages.eu', value: 'eu' },
  { label: 'fields.languages.fa', value: 'fa' },
  { label: 'fields.languages.ff', value: 'ff' },
  { label: 'fields.languages.fi', value: 'fi' },
  { label: 'fields.languages.fj', value: 'fj' },
  { label: 'fields.languages.fo', value: 'fo' },
  { label: 'fields.languages.fr', value: 'fr' },
  { label: 'fields.languages.fy', value: 'fy' },
  { label: 'fields.languages.ga', value: 'ga' },
  { label: 'fields.languages.gd', value: 'gd' },
  { label: 'fields.languages.gl', value: 'gl' },
  { label: 'fields.languages.gn', value: 'gn' },
  { label: 'fields.languages.gu', value: 'gu' },
  { label: 'fields.languages.gv', value: 'gv' },
  { label: 'fields.languages.ha', value: 'ha' },
  { label: 'fields.languages.he', value: 'he' },
  { label: 'fields.languages.hi', value: 'hi' },
  { label: 'fields.languages.ho', value: 'ho' },
  { label: 'fields.languages.hr', value: 'hr' },
  { label: 'fields.languages.ht', value: 'ht' },
  { label: 'fields.languages.hu', value: 'hu' },
  { label: 'fields.languages.hy', value: 'hy' },
  { label: 'fields.languages.hz', value: 'hz' },
  { label: 'fields.languages.ia', value: 'ia' },
  { label: 'fields.languages.id', value: 'id' },
  { label: 'fields.languages.ie', value: 'ie' },
  { label: 'fields.languages.ig', value: 'ig' },
  { label: 'fields.languages.ii', value: 'ii' },
  { label: 'fields.languages.ik', value: 'ik' },
  { label: 'fields.languages.io', value: 'io' },
  { label: 'fields.languages.is', value: 'is' },
  { label: 'fields.languages.it', value: 'it' },
  { label: 'fields.languages.iu', value: 'iu' },
  { label: 'fields.languages.ja', value: 'ja' },
  { label: 'fields.languages.jv', value: 'jv' },
  { label: 'fields.languages.ka', value: 'ka' },
  { label: 'fields.languages.kg', value: 'kg' },
  { label: 'fields.languages.ki', value: 'ki' },
  { label: 'fields.languages.kj', value: 'kj' },
  { label: 'fields.languages.kk', value: 'kk' },
  { label: 'fields.languages.kl', value: 'kl' },
  { label: 'fields.languages.km', value: 'km' },
  { label: 'fields.languages.kn', value: 'kn' },
  { label: 'fields.languages.ko', value: 'ko' },
  { label: 'fields.languages.kr', value: 'kr' },
  { label: 'fields.languages.ks', value: 'ks' },
  { label: 'fields.languages.ku', value: 'ku' },
  { label: 'fields.languages.kv', value: 'kv' },
  { label: 'fields.languages.kw', value: 'kw' },
  { label: 'fields.languages.ky', value: 'ky' },
  { label: 'fields.languages.la', value: 'la' },
  { label: 'fields.languages.lb', value: 'lb' },
  { label: 'fields.languages.lg', value: 'lg' },
  { label: 'fields.languages.li', value: 'li' },
  { label: 'fields.languages.ln', value: 'ln' },
  { label: 'fields.languages.lo', value: 'lo' },
  { label: 'fields.languages.lt', value: 'lt' },
  { label: 'fields.languages.lu', value: 'lu' },
  { label: 'fields.languages.lv', value: 'lv' },
  { label: 'fields.languages.mg', value: 'mg' },
  { label: 'fields.languages.mh', value: 'mh' },
  { label: 'fields.languages.mi', value: 'mi' },
  { label: 'fields.languages.mk', value: 'mk' },
  { label: 'fields.languages.ml', value: 'ml' },
  { label: 'fields.languages.mn', value: 'mn' },
  { label: 'fields.languages.mr', value: 'mr' },
  { label: 'fields.languages.ms', value: 'ms' },
  { label: 'fields.languages.mt', value: 'mt' },
  { label: 'fields.languages.my', value: 'my' },
  { label: 'fields.languages.na', value: 'na' },
  { label: 'fields.languages.nb', value: 'nb' },
  { label: 'fields.languages.nd', value: 'nd' },
  { label: 'fields.languages.ne', value: 'ne' },
  { label: 'fields.languages.ng', value: 'ng' },
  { label: 'fields.languages.nl', value: 'nl' },
  { label: 'fields.languages.nn', value: 'nn' },
  { label: 'fields.languages.no', value: 'no' },
  { label: 'fields.languages.nr', value: 'nr' },
  { label: 'fields.languages.nv', value: 'nv' },
  { label: 'fields.languages.ny', value: 'ny' },
  { label: 'fields.languages.oc', value: 'oc' },
  { label: 'fields.languages.oj', value: 'oj' },
  { label: 'fields.languages.om', value: 'om' },
  { label: 'fields.languages.or', value: 'or' },
  { label: 'fields.languages.os', value: 'os' },
  { label: 'fields.languages.pa', value: 'pa' },
  { label: 'fields.languages.pi', value: 'pi' },
  { label: 'fields.languages.pl', value: 'pl' },
  { label: 'fields.languages.ps', value: 'ps' },
  { label: 'fields.languages.pt', value: 'pt' },
  { label: 'fields.languages.qu', value: 'qu' },
  { label: 'fields.languages.rm', value: 'rm' },
  { label: 'fields.languages.rn', value: 'rn' },
  { label: 'fields.languages.ro', value: 'ro' },
  { label: 'fields.languages.ru', value: 'ru' },
  { label: 'fields.languages.rw', value: 'rw' },
  { label: 'fields.languages.sa', value: 'sa' },
  { label: 'fields.languages.sc', value: 'sc' },
  { label: 'fields.languages.sd', value: 'sd' },
  { label: 'fields.languages.se', value: 'se' },
  { label: 'fields.languages.sg', value: 'sg' },
  { label: 'fields.languages.si', value: 'si' },
  { label: 'fields.languages.sk', value: 'sk' },
  { label: 'fields.languages.sl', value: 'sl' },
  { label: 'fields.languages.sm', value: 'sm' },
  { label: 'fields.languages.sn', value: 'sn' },
  { label: 'fields.languages.so', value: 'so' },
  { label: 'fields.languages.sq', value: 'sq' },
  { label: 'fields.languages.sr', value: 'sr' },
  { label: 'fields.languages.ss', value: 'ss' },
  { label: 'fields.languages.st', value: 'st' },
  { label: 'fields.languages.su', value: 'su' },
  { label: 'fields.languages.sv', value: 'sv' },
  { label: 'fields.languages.sw', value: 'sw' },
  { label: 'fields.languages.ta', value: 'ta' },
  { label: 'fields.languages.te', value: 'te' },
  { label: 'fields.languages.tg', value: 'tg' },
  { label: 'fields.languages.th', value: 'th' },
  { label: 'fields.languages.ti', value: 'ti' },
  { label: 'fields.languages.tk', value: 'tk' },
  { label: 'fields.languages.tl', value: 'tl' },
  { label: 'fields.languages.tn', value: 'tn' },
  { label: 'fields.languages.to', value: 'to' },
  { label: 'fields.languages.tr', value: 'tr' },
  { label: 'fields.languages.ts', value: 'ts' },
  { label: 'fields.languages.tt', value: 'tt' },
  { label: 'fields.languages.tw', value: 'tw' },
  { label: 'fields.languages.ty', value: 'ty' },
  { label: 'fields.languages.ug', value: 'ug' },
  { label: 'fields.languages.uk', value: 'uk' },
  { label: 'fields.languages.ur', value: 'ur' },
  { label: 'fields.languages.uz', value: 'uz' },
  { label: 'fields.languages.ve', value: 've' },
  { label: 'fields.languages.vi', value: 'vi' },
  { label: 'fields.languages.vo', value: 'vo' },
  { label: 'fields.languages.wa', value: 'wa' },
  { label: 'fields.languages.wo', value: 'wo' },
  { label: 'fields.languages.xh', value: 'xh' },
  { label: 'fields.languages.yi', value: 'yi' },
  { label: 'fields.languages.yo', value: 'yo' },
  { label: 'fields.languages.za', value: 'za' },
  { label: 'fields.languages.zh', value: 'zh' },
  { label: 'fields.languages.zu', value: 'zu' },
];
