import React from 'react';
import { objectOf, any, func } from 'prop-types';
import { get } from 'lodash';
import { propType } from 'graphql-anywhere';
import { Route as RRoute } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { initUser } from '@/api/User/mutations';
import userFragment from '@/api/User/fragment';
import { openModal } from '@/redux/modules/modal';
import { shouldRedirectToPostSignup } from '@/selectors/user';
import {
  getPostSignupCvParsingRoute,
  getPostSignupRoute,
} from '@/helpers/router';
import { isTokenExpired, handleCookie } from '@/helpers/auth';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import Error from '@/components/Error';
import Loading from '@/components/Loading';
import config from '@/_config';
import { POST_SIGNUP, POST_SIGNUP_CV_PARSING } from '@/constants/router_base';
import AbilityContext from '@/permissions/contexts/AbilityContext';
import ability from '@/permissions/abilities';
import UserContext from '@/permissions/contexts/UserContext';
import User from '@/models/User';
import { BEGINNING, NONE } from '@/constants/positions';

class Route extends React.Component {
  async componentDidMount() {
    const { search } = this.props.history.location;
    // Get token and scope variables from url query, in the case of SSO login
    const { token, scopes, error } = parse(search);
    if (error) {
      this.props.history.push(`/login?error=${error}`);
    } else if (token && scopes) {
      handleCookie(token, scopes);
    }

    const {
      data: { initUser: currentUser },
    } = await this.props.initUser();
    const { path } = this.props;
    const postSignupCvParsingShowAtPosition = get(
      config,
      'postSignupCvParsing.showAtThe',
      NONE
    );

    // Redirect if user is not filled and you are on POST_SIGNUP or POST_SIGNUP_CV_PARSING route
    // There is an exception tenant config for scrapper is false, then redirect
    if (
      shouldRedirectToPostSignup(currentUser) &&
      (path !== POST_SIGNUP_CV_PARSING ||
        postSignupCvParsingShowAtPosition !== BEGINNING) &&
      path !== POST_SIGNUP
    ) {
      if (postSignupCvParsingShowAtPosition === BEGINNING) {
        this.props.history.push(getPostSignupCvParsingRoute());
      } else {
        this.props.history.push(getPostSignupRoute());
      }
    }

    if (
      currentUser &&
      !currentUser.accepted_latest_cgu &&
      !currentUser.signed_in_as_user
    ) {
      this.props.openModal('cgus');
    }
  }

  render() {
    const {
      routeComponent,
      currentUser,
      restricted,
      searchAccess,
      ...otherProps
    } = this.props;
    const user = new User(currentUser);

    if (!isTokenExpired() && !currentUser) {
      return <Loading />;
    }

    // Logic to display the offline or normal component
    const offlineComponent = get(this.props, 'offlineComponent');
    const RouteComponent =
      offlineComponent && !user.get('id') ? offlineComponent : routeComponent;

    if (
      restricted === true ||
      (currentUser &&
        restricted &&
        !['mixed', restricted].includes(currentUser.kind)) ||
      (typeof searchAccess !== 'undefined' &&
        get(currentUser, 'administrator', false) === false &&
        (get(currentUser, 'company.search_access', false) ||
          get(currentUser, 'company.client_pool_search_access', false)) !==
          searchAccess &&
        config.search.restrictedAccess &&
        currentUser)
    ) {
      return <Error statusCode={401} message="error.401" />;
    }

    return (
      <UserContext.Provider value={user}>
        <AbilityContext.Provider value={ability(user, config)}>
          <RouteComponent currentUser={user} {...otherProps} />
        </AbilityContext.Provider>
      </UserContext.Provider>
    );
  }
}

Route.propTypes = {
  location: objectOf(any),
  history: objectOf(any).isRequired,
  currentUser: propType(userFragment),
  routeComponent: func,
  initUser: func.isRequired,
  openModal: func.isRequired,
};

Route.defaultProps = {
  location: {},
  currentUser: null,
  routeComponent: RRoute,
};

export default compose(
  withCurrentUser,
  graphql(initUser, { name: 'initUser' }),
  connect(
    null,
    { openModal }
  )
)(Route);
