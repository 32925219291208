import Entity from './Entity';
import {
  ARCHIVED,
  PUBLISHED,
  DRAFT,
  REVIEW,
} from '@/constants/missionStatuses';
import {
  MISSION_OR_FULLTIME,
  MISSIONS,
  FULLTIME_CONTRACT,
} from '@/constants/contractTypes';
import { limitDateHasPassed as limitDateHasPassedMethod } from '@/helpers/limitDate';

class Mission extends Entity {
  static get modelName() {
    return 'Mission';
  }

  candidaciesWithProposalCount() {
    return this.get('candidacies_with_proposal_count');
  }

  isArchived() {
    return this.get('status') === ARCHIVED;
  }

  isPublished() {
    return this.get('status') === PUBLISHED;
  }

  isDraft() {
    return this.get('status') === DRAFT;
  }

  isInReview() {
    return this.get('status') === REVIEW;
  }

  hasCandidaciesWithStatusWaitingForValidation() {
    return this.get('has_candidacies_with_status_waiting_for_validation');
  }

  hasCandidated() {
    return this.get('has_candidated');
  }

  hasCandidatedWithoutProposal() {
    return this.get('has_candidated_without_proposal');
  }

  hasCandidatedBetweenStepOneAndFourWithoutProposal() {
    return this.get(
      'has_candidated_between_step_one_and_four_without_proposal'
    );
  }

  hasBeenInvited() {
    return this.get('has_been_invited');
  }

  hasProposal() {
    return !!this.get('proposal').get('id');
  }

  hasSalaryConfidentiality() {
    return this.get('salary_confidentiality');
  }

  shouldSignNda() {
    return this.get('nda', false) && !this.get('isNdaSigned');
  }

  hasBlacklisted() {
    return this.get('has_blacklisted');
  }

  isMission() {
    return this.get('contract_types').includes(MISSIONS);
  }

  isMissionOrFulltime() {
    return this.get('contract_types').includes(MISSION_OR_FULLTIME);
  }

  isFulltime() {
    return this.get('contract_types').includes(FULLTIME_CONTRACT);
  }

  limitDateHasPassed() {
    const limited = this.get('limited');
    const limitedAt = this.get('limited_at');
    return limitDateHasPassedMethod(limited, limitedAt);
  }
}

export default Mission;
