import React from 'react';
import { func, objectOf, any, string } from 'prop-types';
import { Mutation } from 'react-apollo';
import { acceptProposal } from '@/api/Proposal/mutations';
import { getMyMissionBoardRoute } from '@/helpers/router';
import useTranslation from '@/hooks/useTranslation';
import Modal from '@/components/Modal';

function AcceptModal({ close, history, proposalId, missionId }) {
  const { t } = useTranslation();
  return (
    <Mutation mutation={acceptProposal} variables={{ id: proposalId }}>
      {(mutate, { loading }) => (
        <Modal
          name="ProposalAccept"
          size="medium"
          close={close}
          onOk={async () => {
            await mutate();
            close();
            history.push(getMyMissionBoardRoute(missionId));
          }}
          onCancel={close}
          title={t('proposal.modal.accept.title')}
          subtitle={t('proposal.modal.accept.subtitle')}
          okText={t('proposal.modal.accept.ok')}
          disableActions={loading}
          closable={!loading}
        />
      )}
    </Mutation>
  );
}

AcceptModal.propTypes = {
  close: func.isRequired,
  history: objectOf(any).isRequired,
  proposalId: string.isRequired,
  missionId: string.isRequired,
};

export default AcceptModal;
