export const BLACKLIST_REASONS = [
  {
    label: `fields.blacklist.reason.unavailable`,
    value: 'unavailable',
    id: 'unavailable',
  },
  {
    label: `fields.blacklist.reason.bad_sectors`,
    value: 'bad_sectors',
    id: 'bad_sectors',
  },
  {
    label: `fields.blacklist.reason.bad_skills`,
    value: 'bad_skills',
    id: 'bad_skills',
  },
  {
    label: `fields.blacklist.reason.bad_location`,
    value: 'bad_location',
    id: 'bad_location',
  },
  {
    label: `fields.blacklist.reason.other`,
    value: 'other',
    id: 'other',
  },
];
