import {
  array,
  arrayOf,
  bool,
  element,
  func,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const fieldTypes = {
  addLabel: string,
  autoComplete: string,
  cbProps: arrayOf(
    shape({
      label: string,
    })
  ),
  checkbox: oneOfType([func, element]),
  component: oneOfType([func, element]),
  'data-testid': string,
  id: string,
  inline: bool,
  inputProps: shape({
    label: string,
    'data-testid': string,
    placeholder: string,
    autoComplete: string,
  }),
  label: string,
  name: string.isRequired,
  placeholder: string,
  required: bool,
  searchOptions: object,
  useSpacer: bool,
  validate: oneOfType([func, array]),
  withSeparators: bool,
};

export const fieldType = shape(fieldTypes);
