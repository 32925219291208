import React, { useContext, useRef } from 'react';
import { string, func, number, bool } from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';
import TagRating from '@/components/Tags/components/TagRating';
import { RequiredField, RfNumber } from '@/containers/ReduxForm';
import Button from '@/components/Button';
import { SKILL } from '@/constants/dragAndDrop';
import TagsContext from '@/components/Tags/TagsContext';
import TagRemoveButton from '@/components/Tags/components/TagRemoveButton';
import TagContainer from '@/components/Tags/components/TagContainer';
import TagWrapper from '@/components/Tags/components/TagWrapper';
import { ReactComponent as DragIcon } from '@/images/burger.svg';
import './styles.scss';

function TagRow({
  id,
  name,
  rating,
  index,
  moveTag,
  handleRemove,
  handleRatingChange,
  required,
  hasError,
}) {
  const tagsContext = useContext(TagsContext);
  const handleRef = useRef(null);
  const rowRef = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: SKILL, id, index }, // TODO
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const [, drop] = useDrop({
    accept: SKILL,
    hover: (item) => {
      const hoverIndex = index;
      const dragIndex = item.index;

      if (dragIndex === hoverIndex) return;

      moveTag(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const handleNumberFieldClick = (value) => {
    handleRatingChange(id, name, value);
  };

  // Only handle is draggable
  drag(handleRef);
  // Rows are drag preview object, and it's possible to drop draggable objects on rows
  preview(drop(rowRef));

  function RatingComponent() {
    return tagsContext.ratingWithNumberField ? (
      <RequiredField
        inline
        component={RfNumber}
        emptyInitialValue
        // If the id of the tag is present, the name field is initialize with it
        // to follow the initialValues of the form declared in the modal component (ex: EquipmentModal)
        // otherwise the name of the tag is used with a replacement of special characters to avoid mistakes
        name={`rating_${id || name.replace(/[:.../]/g, '')}`}
        max={99}
        min={1}
        step={1}
        changeState={handleNumberFieldClick}
      />
    ) : (
      <TagRating
        id={id}
        name={name}
        rating={rating}
        onChange={handleRatingChange}
        hasError={hasError}
      />
    );
  }

  return (
    <TagContainer
      classnames={`d-f jc-sb ai-c bb b--lgrey ${isDragging && 'is-dragging'}`}
      tagRef={rowRef}
    >
      <div className="d-f p-t-s p-b-s">
        <div>
          <Button variants={['transparent']} innerRef={handleRef}>
            <DragIcon />
          </Button>
          <TagWrapper
            name={name}
            required={required}
            index={index}
            hasError={hasError}
          />
        </div>
        <TagRemoveButton
          handleRemove={handleRemove}
          classes="TagRemoveButton"
        />
      </div>
      {tagsContext.withRating && RatingComponent()}
    </TagContainer>
  );
}

TagRow.propTypes = {
  id: string.isRequired,
  index: number,
  moveTag: func.isRequired,
  handleRatingChange: func.isRequired,
  handleRemove: func.isRequired,
  name: string.isRequired,
  rating: number,
  required: bool,
  hasError: bool,
};

TagRow.defaultProps = {
  index: null,
  rating: null,
  required: false,
  hasError: false,
};

export default TagRow;
