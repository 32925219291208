// Documents
export const PDF = 'application/pdf';
export const DOC = 'application/msword';
export const DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const PPT = 'application/vnd.ms-powerpoint';
export const PPTX =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const XLS = 'application/vnd.ms-excel';
export const XLSX =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const TXT = 'text/plain';

export const ALL_DOCUMENT = [PDF, DOC, DOCX, PPT, PPTX, XLS, XLSX, TXT];

// Pictures
export const JPG = 'image/jpeg';
export const PNG = 'image/png';
export const GIF = 'image/gif';
export const BMP = 'image/bmp';
export const SVG = 'image/svg+xml';

export const ALL_PICTURE = [JPG, PNG, GIF, BMP, SVG];

// ALL EXTENIONS
export const ALL_EXTENSION = ALL_DOCUMENT.concat(ALL_PICTURE);

export const STATUS = {
  UPLOAD: 'upload',
  EDIT: 'edit',
  DOWNLOAD: 'download',
  READ: 'read',

  // Only available for assets
  ACCESS: 'access',
  LOCKED: 'locked',
  INACTIVE: 'inactive',
};
