import React, { useEffect, useState } from 'react';
import { any, func, objectOf, string } from 'prop-types';
import { first, get, isEmpty, isNull } from 'lodash';
import {
  Field,
  RequiredField,
  RfSelect,
  RfChoices,
  RfFiles,
  RfText,
} from '@/containers/ReduxForm';
import config from '@/_config';
import LabelledRadio from '@/components/Choice/LabelledRadio';
import Tooltip from '@/components/Tooltip';
import { ReactComponent as Attachment } from '@/images/attachment.svg';
import { outsourcings } from '@/constants/fields/outsourcings';
import { translateArrayLabel } from '@/helpers/i18n';
import { ALL_EXTENSION } from '@/constants/file';
import { scrollToElement } from '@/helpers/scroll';
import './styles.scss';

const AdditionalCandidate = ({
  collaborators,
  formValues,
  t,
  candidates,
  onAddCandidate,
  className,
}) => {
  const [resetFiles, setResetFiles] = useState(false);
  const [errorDailyRate, setErrorDailyRate] = useState(false);
  const [errorAttachments, setErrorAttachments] = useState(false);

  const candidate_daily_rate = get(formValues, 'candidate_daily_rate');
  const candidate_attachments = get(formValues, 'candidate_attachments', []);

  const MAX_FILES = 5;

  useEffect(
    () => {
      if (candidate_attachments.length <= MAX_FILES) setErrorAttachments(false);
    },
    [candidate_attachments]
  );

  const toggleResetFiles = () => {
    const toggleresetFiles = !resetFiles;
    setResetFiles(toggleresetFiles);
  };

  const addCandidate = () => {
    const dailyRateHasError =
      !candidate_daily_rate ||
      candidate_daily_rate < config.company.dailyRate.min ||
      candidate_daily_rate > config.company.dailyRate.max;
    if (!dailyRateHasError && candidate_attachments.length <= MAX_FILES) {
      onAddCandidate('add');
      toggleResetFiles();
      setErrorDailyRate(false);
    } else {
      // scroll until field
      if (candidate_attachments.length > MAX_FILES) {
        const element = document.querySelector('#candidate_attachments');
        if (!isNull(element)) scrollToElement(element);
        setErrorAttachments(true);
      }
      if (dailyRateHasError) {
        const element = document.querySelector('#candidate_daily_rate');
        if (!isNull(element)) scrollToElement(element);
        setErrorDailyRate(true);
      }
    }
  };

  const collaboratorsWithoutcandatesSelected = collaborators.filter(
    (collaborator) =>
      !candidates
        .map((candidate) => candidate.collaborator.id)
        .includes(collaborator.id)
  );

  const outsourcingsLabel = translateArrayLabel(t, outsourcings);

  const getDailyRateErrorMessage = () => {
    if (!candidate_daily_rate) {
      return <>{t('fields.validators.required')}</>;
    }
    if (candidate_daily_rate < config.company.dailyRate.min) {
      return (
        <>
          {t('fields.validators.value.tooSmall', {
            count: config.company.dailyRate.min,
          })}
        </>
      );
    }
    if (candidate_daily_rate > config.company.dailyRate.max) {
      return (
        <>
          {t('fields.validators.value.tooHigh', {
            count: config.company.dailyRate.max,
          })}
        </>
      );
    }
    return null;
  };

  return (
    <div className={className}>
      <Field
        id="candidate_collaborator_id"
        name="candidate_collaborator_id"
        component={RfSelect}
        searchable={false}
        className="AdditionalCandidate__collaborator"
        label={t('mission.modal.apply.candidate.collaborator.label')}
        helper={t('company.modal.apply.candidate.collaborator.helper')}
        placeholder={t(
          'mission.modal.apply.candidate.collaborator.placeholder'
        )}
        options={
          collaboratorsWithoutcandatesSelected
            ? collaboratorsWithoutcandatesSelected.map((collaborator) => {
                const firstName = get(collaborator, 'first_name', '');
                const lastName = get(collaborator, 'last_name', '');
                return {
                  id: collaborator.id,
                  value: collaborator.id,
                  label: `${firstName}${
                    !isNull(lastName) ? ` ${lastName}` : ''
                  }`,
                  selectable: true,
                };
              })
            : []
        }
        value={get(first(collaborators), 'id', '')}
      />
      {errorDailyRate && (
        <div className="Field__error">{getDailyRateErrorMessage()}</div>
      )}
      <span className="m-b-m" />
      {config.mission.apply.candidateWithAttachment && (
        <>
          <Field
            className="m-t-m"
            idContainer="candidate_attachments"
            name="candidate_attachments"
            component={RfFiles}
            drop
            maxSize={config.global.maxSizeAttachment}
            maxFiles={MAX_FILES}
            multiple
            accept={ALL_EXTENSION}
            text={t('company.modal.cv.dropzone.text')}
            infoText={t('company.modal.cv.dropzone.infoText')}
            rejectText={t('company.modal.cv.dropzone.rejectText')}
            reset={resetFiles}
            toggleReset={toggleResetFiles}
            label={t('company.modal.collaborator.candidate.attachments.label')}
            helper={t('company.modal.apply.candidate.attachments.tjm.helper')}
            error={errorAttachments}
          >
            <Tooltip title="conversations.new.files.tooltip.title">
              <Attachment />
            </Tooltip>
          </Field>
          {errorAttachments && (
            <div className="Field__error">
              {t('fields.validators.files.too_many_files', {
                count: 5,
              })}
            </div>
          )}
        </>
      )}
      <div className="AdditionalCandidate__files m-b-m" />
      <div className="AdditionalCandidate__dailyRate">
        <Field
          component={RfText}
          type="number"
          id="candidate_daily_rate"
          name="candidate_daily_rate"
          max={config.company.dailyRate.max}
          min={config.company.dailyRate.min}
          step={10}
          label={t('company.modal.collaborator.remuneration.tjm.label')}
          helper={t('company.modal.apply.candidate.remuneration.tjm.helper')}
          placeholder={t(
            'company.modal.collaborator.remuneration.tjm.placeholder'
          )}
          setError={setErrorDailyRate}
        />
      </div>
      <RequiredField
        type="radioGroup"
        id="candidate_outsourcing"
        name="candidate_outsourcing"
        className="m-t-m"
        component={RfChoices}
        checkbox={LabelledRadio}
        label={t('company.modal.apply.candidate.outsourcing.label')}
        helper={t('company.modal.apply.candidate.outsourcing.helper')}
        cbProps={outsourcingsLabel}
        withSeparators={false}
        defaultValue={
          !isEmpty(outsourcingsLabel) ? first(outsourcingsLabel).value : ''
        }
      />
      <button
        type="button"
        className="Button Button--blue m-t-l"
        onClick={() => addCandidate()}
      >
        {t('company.modal.apply.candidate.btn')}
      </button>
    </div>
  );
};

AdditionalCandidate.propTypes = {
  candidates: objectOf(any).isRequired,
  collaborators: objectOf(any).isRequired,
  formValues: objectOf(any).isRequired,
  t: func.isRequired,
  onAddCandidate: func.isRequired,
  className: string,
};

AdditionalCandidate.defaultProps = {
  className: '',
};

export default AdditionalCandidate;
