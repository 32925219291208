import { get } from 'lodash';
import { DISTANCE, LOCATION } from '@/components/Matching/Filters/presets';

/**
 * Add specifics logic to query parameters
 * @param selectedFilters
 * @returns {}
 */
export function resolver(selectedFilters) {
  const distance = get(selectedFilters, DISTANCE);

  return {
    [LOCATION]: get(selectedFilters, `${LOCATION}.description`), // restrict ES search on address
    [DISTANCE]: distance > 0 ? distance : 1, // ES can not perform a geo search with 0 value.
  };
}
