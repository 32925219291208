import React from 'react';
import { func, string } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import { LocationField, SliderField } from '@/components/Fields';
import { get, head, isEmpty, isNull } from 'lodash';
import {
  DISTANCE,
  LOCALIZATION,
  LOCATION,
} from '@/components/Matching/Filters/presets';

function Localization({ setActiveFilter, kind, location, distance }) {
  const { t } = useTranslation();

  return (
    <>
      <LocationField
        name={LOCATION}
        searchOptions={{ types: ['(regions)'] }}
        inputProps={{
          placeholder: t('filters.localization.location.placeholder'),
        }}
        onChange={(value) => {
          const val = get(value, 'description', null);
          setActiveFilter({
            name: LOCALIZATION,
            key: LOCATION,
            meta: value,
            value: val,
            selected: !isEmpty(val) || !isNull(val),
            kind,
          });
        }}
        value={location}
        className="m-b-m"
        withDetails
      />
      <SliderField
        name={DISTANCE}
        label={t('filters.localization.radius.label')}
        domain={[0, 100]}
        step={20}
        count={6}
        value={[distance]}
        onChange={(value) => {
          setActiveFilter({
            name: LOCALIZATION,
            key: DISTANCE,
            value: head(value),
            kind,
            selected: value > 0,
          });
        }}
        className="m-b-m"
      />
    </>
  );
}

Localization.propTypes = {
  setActiveFilter: func.isRequired,
  kind: string.isRequired,
};

export default Localization;
