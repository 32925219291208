import React from 'react';
import useTranslation from '@/hooks/useTranslation';

const translate = (...args) => (Comp) => {
  function Translate({ ...otherProps }) {
    const { t, i18n } = useTranslation(...args);
    return <Comp t={t} i18n={i18n} {...otherProps} />;
  }

  return Translate;
};

export default translate;
