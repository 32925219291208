import React from 'react';
import MediaQuery from 'react-responsive';
import {
  string,
  arrayOf,
  bool,
  oneOfType,
  array,
  func,
  number,
} from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import { RfHelper } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import categoryFragment from '@/api/Category/fragment';
import CategoriesField from '@/components/CategoriesSection/CategoriesField';
import { SUB_CATEGORIES } from '@/constants/fields/subCategory.js';
import { BREAKPOINTS } from '@/constants/screen';
import { TEAMS } from '@/constants/missionKind';
import './styles.scss';

function CategoriesSection({
  baseKey,
  baseName,
  validate,
  data,
  required,
  sectors,
  favoriteLimit,
  ...props
}) {
  const { t } = useTranslation();
  const categoriesField = `${baseName}category_ids`;
  const subcategoriesField = `${baseName}${SUB_CATEGORIES}`;

  return (
    <MediaQuery maxWidth={BREAKPOINTS.laptop}>
      {(mobile) => (
        <RfHelper values={[categoriesField, subcategoriesField, 'kind']}>
          {(values) => (
            <FormSection title={t(`${baseKey}.title`)}>
              <CategoriesField
                name={categoriesField}
                subcategoriesFieldName={subcategoriesField}
                data={data}
                baseKey={baseKey}
                mobile={mobile}
                selectedCategories={values[categoriesField]}
                selectedSubcategories={values[subcategoriesField]}
                validate={validate}
                required={required}
                sectors={sectors}
                favoriteLimit={favoriteLimit}
                {...get(values, 'kind') === TEAMS && {
                  labelSuffix: '.teams',
                }}
                {...props}
              />
            </FormSection>
          )}
        </RfHelper>
      )}
    </MediaQuery>
  );
}

CategoriesSection.propTypes = {
  baseKey: string.isRequired,
  baseName: string.isRequired,
  data: arrayOf(propType(categoryFragment)).isRequired,
  required: bool,
  validate: oneOfType([array, func]),
  favoriteLimit: number,
};

CategoriesSection.defaultProps = {
  required: false,
  validate: [],
  favoriteLimit: 3,
};

export default CategoriesSection;
