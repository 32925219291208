import React, { useContext } from 'react';
import { string, func, shape } from 'prop-types';
import TooltipLink from '@/components/Header/Nav/TooltipLink';
import UserModel from '@/models/User';
import UserContext from '@/permissions/contexts/UserContext';
import config from '@/_config';

const TrainingsLink = ({ item }) => {
  const contextUser = useContext(UserContext);
  const user = new UserModel(contextUser);
  const displayTooltip =
    user.get('relevance.current_score') < config.company.maxRelevanceScore;

  return <TooltipLink item={item} disabled={displayTooltip} />;
};

TrainingsLink.propTypes = {
  item: shape({
    id: string.isRequired,
    label: string,
    pathname: string,
    icon: func,
  }),
};

TrainingsLink.defaultProps = {
  item: {},
};

export default TrainingsLink;
