import moment from 'moment';
import i18n from '@/i18n';
import { MONTHS } from '@/constants/date';

export const today = moment()
  .startOf('day')
  .hour(12)
  .toDate();

export const todayPlusDate = (days) =>
  moment()
    .startOf('day')
    .hour(12)
    .add(days, 'days')
    .toDate();

export const oneYearFromToday = moment()
  .startOf('day')
  .hour(12)
  .add(1, 'year')
  .toDate();

export const endOfMonthFromBeginDate = (begin_date) =>
  moment(begin_date)
    .endOf('month')
    .toDate();

export const startOfMonthFromBeginDate = (begin_date) =>
  moment(begin_date)
    .startOf('month')
    .toDate();

export const formatDate = (date, format = i18n.t('fields.date.format')) =>
  date
    ? moment(date)
        .locale(i18n.language)
        .format(format)
    : '';

export const parseDateFormat = (date, format = i18n.t('fields.date.format')) =>
  moment(date, format);

export const parseDate = (date) => moment(date);

export const numberDateDiff = (beginDate, endDate, dateKind = MONTHS) =>
  moment(endDate).diff(moment(beginDate), dateKind, true);
