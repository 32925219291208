/* a cause du plugin babel, on ne peux pas extend directement sectors.js si on est sur un tenant */
import { ReactComponent as ConnectedCar } from '@/images/connected-car.svg';
import { ReactComponent as Plane } from '@/images/plane.svg';
import { ReactComponent as Spaceship } from '@/images/spaceship.svg';
import { ReactComponent as SecuritySvg } from '@/images/security.svg';
import { ReactComponent as Train } from '@/images/train.svg';
import { ReactComponent as Molecule } from '@/images/molecule.svg';
import { ReactComponent as ChartBar } from '@/images/chart-bar.svg';
import { ReactComponent as BatteryLevel } from '@/images/battery-level.svg';
import { ReactComponent as ComputerMonitor } from '@/images/computer-monitor.svg';
import { ReactComponent as Fuel } from '@/images/fuel.svg';
import { ReactComponent as Boat } from '@/images/boat.svg';
import { ReactComponent as Ellipsis } from '@/images/ellipsis.svg';
import { ReactComponent as BankPig } from '@/images/bank-pig.svg';
import { ReactComponent as DigitalIcon } from '@/images/digital.svg';
import { ReactComponent as Factory } from '@/images/factory.svg';
import { ReactComponent as BioEngineeringIcon } from '@/images/bioengineering.svg';
import { ReactComponent as World } from '@/images/world.svg';
import { ReactComponent as NewConstruction } from '@/images/new-construction.svg';
import { ReactComponent as VideoGallery } from '@/images/video-gallery.svg';
import { ReactComponent as Tablet } from '@/images/graphics-tablet.svg';
import { ReactComponent as Transform } from '@/images/transform.svg';
import { ReactComponent as Computer } from '@/images/computer-science.svg';
import { ReactComponent as EHealthIcon } from '@/images/e-health.svg';
import { ReactComponent as PrintingIcon } from '@/images/printing.svg';
import { ReactComponent as Tools } from '@/images/tools.svg';
import { ReactComponent as SecurityIcon } from '@/images/security-icon.svg';
import { ReactComponent as RetailIcon } from '@/images/retail.svg';
import { ReactComponent as IotIcon } from '@/images/iot.svg';
import { ReactComponent as FacilityManagementIcon } from '@/images/facility-management.svg';
import { ReactComponent as TwoPeople } from '@/images/two-people.svg';
import { ReactComponent as Graduation } from '@/images/graduation.svg';
import { ReactComponent as Wheat } from '@/images/wheat.svg';
import { ReactComponent as SensorSpeed } from '@/images/sensor-speed.svg';
import { ReactComponent as IndustryIcon } from '@/images/industry.svg';
import { ReactComponent as HealthcareIcon } from '@/images/healthcare.svg';
import { ReactComponent as CosmeticIcon } from '@/images/cosmetic.svg';
import { ReactComponent as MaterialsIcon } from '@/images/materials.svg';
import { ReactComponent as BiologyIcon } from '@/images/biology.svg';
import { ReactComponent as ElevatorIcon } from '@/images/elevator.svg';
import { ReactComponent as Thesis } from '@/images/thesis.svg';
import { ReactComponent as PublicIcon } from '@/images/public.svg';
import { ReactComponent as InsuranceIcon } from '@/images/insurance.svg';
import { ReactComponent as TechnologyIcon } from '@/images/technology.svg';
import { ReactComponent as NonProfitIcon } from '@/images/nonprofit.svg';
import { ReactComponent as LogisticsIcon } from '@/images/logistics.svg';
import { ReactComponent as ServicesIcon } from '@/images/services.svg';
import { ReactComponent as TourismIcon } from '@/images/tourism.svg';
import { ReactComponent as GamingIcon } from '@/images/gaming.svg';
import { ReactComponent as BlockchainIcon } from '@/images/blockchain.svg';
import { ReactComponent as PharmaceuticalsIcon } from '@/images/pharmaceuticals.svg';
import { ReactComponent as ECommerceIcon } from '@/images/ecommerce.svg';
import { ReactComponent as FoodsIcon } from '@/images/foods.svg';
import { ReactComponent as GovernmentIcon } from '@/images/government.svg';
import { ReactComponent as PropertyIcon } from '@/images/property.svg';
import { ReactComponent as AssociationsIcon } from '@/images/hands-heart.svg';
import { ReactComponent as DistributionIcon } from '@/images/shopping-cart-2.svg';
import { ReactComponent as LeaderIcon } from '@/images/leader.svg';
import { ReactComponent as InvestmentIcon } from '@/images/investment.svg';
import { ReactComponent as StockIcon } from '@/images/stock.svg';
import { ReactComponent as EntertainmentIcon } from '@/images/entertainment.svg';
import { ReactComponent as EstateIcon } from '@/images/estate.svg';
import { ReactComponent as InstitutionalIcon } from '@/images/institutional.svg';
import { ReactComponent as LiberalIcon } from '@/images/liberal.svg';
import { ReactComponent as ArtisanIcon } from '@/images/artisan.svg';
import { ReactComponent as PublicFigureIcon } from '@/images/public-figure.svg';
import { ReactComponent as FamilyIcon } from '@/images/family.svg';
import { ReactComponent as SurveyIcon } from '@/images/survey.svg';
import { ReactComponent as OfficeIcon } from '@/images/office.svg';
import { ReactComponent as CalculatorIcon } from '@/images/calculator.svg';
import { ReactComponent as HotelBellIcon } from '@/images/hotel-bell.svg';
import { ReactComponent as ScaleIcon } from '@/images/scale.svg';
import { ReactComponent as AIGeneratedIcon } from '@/images/ai-generated-img.svg';
import { ReactComponent as LipGlossIcon } from '@/images/lip-gloss.svg';
import { ReactComponent as TeamIcon } from '@/images/team.svg';
import { ReactComponent as PresentationIcon } from '@/images/presentation.svg';
import { ReactComponent as MedicineIcon } from '@/images/medicine.svg';
import { ReactComponent as UsersWMIcon } from '@/images/users-wm.svg';
import { ReactComponent as CurrencyExchangeIcon } from '@/images/currency-exchange-2.svg';
import { ReactComponent as CompassIcon } from '@/images/compass.svg';

export const MultiTechnicalMaintenanceAndFinishing = {
  value: 'multi_technical_maintenance_and_finishing',
  id: 'multi_technical_maintenance_and_finishing',
  label: 'fields.sectors.multi_technical_maintenance_and_finishing',
  icon: CompassIcon,
  'data-testid': 'multi_technical_maintenance_and_finishing',
};

export const AssociationsFoundations = {
  value: 'associations_fondations',
  id: 'associations_fondations',
  label: 'fields.sectors.associations_fondations',
  icon: AssociationsIcon,
  'data-testid': 'associations_fondations',
};

export const Automative = {
  value: 'automative',
  id: 'automative',
  label: 'fields.sectors.automative',
  icon: ConnectedCar,
  'data-testid': 'automative',
};

export const Aeronautics = {
  value: 'aeronautics',
  id: 'aeronautics',
  label: 'fields.sectors.aeronautics',
  icon: Plane,
  'data-testid': 'aeronautics',
};

export const AuditConsulting = {
  value: 'audit_consulting_accounting_expertise',
  id: 'audit_consulting_accounting_expertise',
  label: 'fields.sectors.audit_consulting_accounting_expertise',
  icon: SurveyIcon,
  'data-testid': 'audit_consulting_accounting_expertise',
};

export const Space = {
  value: 'space',
  id: 'space',
  label: 'fields.sectors.space',
  icon: Spaceship,
  'data-testid': 'space',
};

export const Defense = {
  value: 'defense',
  id: 'defense',
  label: 'fields.sectors.defense',
  icon: SecuritySvg,
  'data-testid': 'defense',
};

export const Distribution = {
  value: 'distribution',
  id: 'distribution',
  label: 'fields.sectors.distribution',
  icon: DistributionIcon,
  'data-testid': 'distribution',
};

export const Railway = {
  value: 'railway',
  id: 'railway',
  label: 'fields.sectors.railway',
  icon: Train,
  'data-testid': 'railway',
};

export const LifeSciences = {
  value: 'life_sciences',
  id: 'life_sciences',
  label: 'fields.sectors.life_sciences',
  icon: Molecule,
  'data-testid': 'life_sciences',
};

export const Associative = {
  value: 'associative_sector',
  id: 'associative_sector',
  label: 'fields.sectors.associative_sector',
  icon: UsersWMIcon,
};

export const Telecoms = {
  value: 'telecoms',
  id: 'telecoms',
  label: 'fields.sectors.telecoms',
  icon: ChartBar,
  'data-testid': 'telecoms',
};

export const Energy = {
  value: 'energy',
  id: 'energy',
  label: 'fields.sectors.energy',
  icon: BatteryLevel,
  'data-testid': 'energy',
};

export const Industry = {
  value: 'industry',
  id: 'industry',
  label: 'fields.sectors.industry',
  icon: IndustryIcon,
  'data-testid': 'industry',
};

export const SI = {
  value: 'services_and_information_systems',
  id: 'services_and_information_systems',
  label: 'fields.sectors.services_and_information_systems',
  icon: ComputerMonitor,
  'data-testid': 'services_and_information_systems',
};

export const OilAndGas = {
  value: 'oil_and_gas',
  id: 'oil_and_gas',
  label: 'fields.sectors.oil_and_gas',
  icon: Fuel,
  'data-testid': 'oil_and_gas',
};

export const Naval = {
  value: 'naval',
  id: 'naval',
  label: 'fields.sectors.naval',
  icon: Boat,
  'data-testid': 'naval',
};

export const MachineOrHeavyIndustry = {
  value: 'machining_or_heavy_industry',
  id: 'machining_or_heavy_industry',
  label: 'fields.sectors.machining_or_heavy_industry',
  icon: Factory,
  'data-testid': 'machining_or_heavy_industry',
};

export const Digital = {
  value: 'digital',
  id: 'digital',
  label: 'fields.sectors.digital',
  icon: DigitalIcon,
  'data-testid': 'digital',
};

export const BankAndFinance = {
  value: 'bank_and_finance',
  id: 'bank_and_finance',
  label: 'fields.sectors.bank_and_finance',
  icon: BankPig,
  'data-testid': 'bank_and_finance',
};

export const BankFinance = {
  value: 'bank_finance',
  id: 'bank_finance',
  label: 'fields.sectors.bank_and_finance',
  icon: BankPig,
  'data-testid': 'bank_finance',
};

export const Public = {
  value: 'public',
  id: 'public',
  label: 'fields.sectors.public',
  icon: PublicIcon,
  'data-testid': 'public',
};

export const PropertyHotelerie = {
  value: 'property_hotelerie',
  id: 'property_hotelerie',
  label: 'fields.sectors.property_hotelerie',
  icon: PublicIcon,
  'data-testid': 'property_hotelerie',
};

export const BankInsurance = {
  value: 'bank_insurance',
  id: 'bank_insurance',
  label: 'fields.sectors.bank_insurance',
  icon: BankPig,
  'data-testid': 'bank-insurance',
};

export const FutureIndustry = {
  value: 'future_industry',
  id: 'future_industry',
  label: 'fields.sectors.future_industry',
  icon: Factory,
  'data-testid': 'future-industry',
};

export const FintechAccountingTech = {
  value: 'fintech_accounting_tech',
  id: 'fintech_accounting_tech',
  label: 'fields.sectors.fintech_accounting_tech',
  icon: CalculatorIcon,
};

export const BioEngineering = {
  value: 'bioengineering',
  id: 'bioengineering',
  label: 'fields.sectors.bioengineering',
  icon: BioEngineeringIcon,
  'data-testid': 'bioengineering',
};

export const Environment = {
  value: 'environment',
  id: 'environment',
  label: 'fields.sectors.environment',
  icon: World,
  'data-testid': 'environment',
};

export const Multimedia = {
  value: 'multimedia',
  id: 'multimedia',
  label: 'fields.sectors.multimedia',
  icon: VideoGallery,
  'data-testid': 'multimedia',
};

export const Infrastructure = {
  value: 'infrastructure',
  id: 'infrastructure',
  label: 'fields.sectors.infrastructure',
  icon: NewConstruction,
  'data-testid': 'infrastructure',
};

export const ComputerScienceDigital = {
  value: 'computer_science_digital',
  id: 'computer_science_digital',
  label: 'fields.sectors.computer_science_digital',
  icon: Thesis,
  'data-testid': 'computer_science_digital',
};

export const ComputingTechnology = {
  value: 'computing_technology',
  id: 'computing_technology',
  label: 'fields.sectors.computing_technology',
  icon: Computer,
  'data-testid': 'computing_technology',
};

export const ContractManagement = {
  value: 'contract_management',
  id: 'contract_management',
  label: 'fields.sectors.contract_management',
  icon: Tablet,
  'data-testid': 'contract_management',
};

export const ConstructionRealEstate = {
  value: 'construction_real_estate',
  id: 'construction_real_estate',
  label: 'fields.sectors.construction_real_estate',
  icon: OfficeIcon,
  'data-testid': 'construction_real_estate',
};

export const ArchitecturalDesign = {
  value: 'architectural_design',
  id: 'architectural_design',
  label: 'fields.sectors.architectural_design',
  icon: Transform,
  'data-testid': 'architectural_design',
};

export const Other = {
  value: 'other',
  id: 'other',
  label: 'fields.sectors.others',
  icon: Ellipsis,
  'data-testid': 'other',
};

export const Insurance = {
  value: 'insurance',
  id: 'insurance',
  label: 'fields.sectors.insurance',
  icon: InsuranceIcon,
  'data-testid': 'insurance',
};

export const AdministrativeSupervision = {
  value: 'administrative_supervision',
  id: 'administrative_supervision',
  label: 'fields.sectors.administrative_supervision',
  'data-testid': 'administrative_supervision',
};

export const LawPractice = {
  value: 'law_practice',
  id: 'law_practice',
  label: 'fields.sectors.law_practice',
  'data-testid': 'law_practice',
};

export const InvestmentBank = {
  value: 'investment_bank',
  id: 'investment_bank',
  label: 'fields.sectors.investment_bank',
  'data-testid': 'investment_bank',
};

export const LegalJustice = {
  value: 'legal_justice',
  id: 'legal_justice',
  label: 'fields.sectors.legal_justice',
  icon: ScaleIcon,
  'data-testid': 'legal_justice',
};

export const RetailBanking = {
  value: 'retail_banking',
  id: 'retail_banking',
  label: 'fields.sectors.retail_banking',
  'data-testid': 'retail_banking',
};

export const PublicBank = {
  value: 'public_bank',
  id: 'public_bank',
  label: 'fields.sectors.public_bank',
  'data-testid': 'public_bank',
};

export const DepositBank = {
  value: 'deposit_bank',
  id: 'deposit_bank',
  label: 'fields.sectors.deposit_bank',
  'data-testid': 'deposit_bank',
};

export const PrivateBank = {
  value: 'private_bank',
  id: 'private_bank',
  label: 'fields.sectors.private_bank',
  'data-testid': 'private_bank',
};

export const ExchangeRate = {
  value: 'exchange_rate',
  id: 'exchange_rate',
  label: 'fields.sectors.exchange_rate',
  'data-testid': 'exchange_rate',
};

export const ManagementConsulting = {
  value: 'management_consulting',
  id: 'management_consulting',
  label: 'fields.sectors.management_consulting',
  'data-testid': 'management_consulting',
};

export const InsuranceBroker = {
  value: 'insurance_broker',
  id: 'insurance_broker',
  label: 'fields.sectors.insurance_broker',
  'data-testid': 'insurance_broker',
};

export const CreditInstitution = {
  value: 'credit_institution',
  id: 'credit_institution',
  label: 'fields.sectors.credit_institution',
  'data-testid': 'credit_institution',
};

export const Property = {
  value: 'property',
  id: 'property',
  label: 'fields.sectors.property',
  'data-testid': 'property',
  icon: PropertyIcon,
};

export const PaymentMethod = {
  value: 'payment_method',
  id: 'payment_method',
  label: 'fields.sectors.payment_method',
  'data-testid': 'payment_method',
};

export const Health = {
  value: 'health',
  id: 'health',
  label: 'fields.sectors.health',
  'data-testid': 'health',
};

export const HealthWithIcon = {
  value: 'health',
  id: 'health',
  label: 'fields.sectors.health',
  'data-testid': 'health',
  icon: MedicineIcon,
};

export const ManagementCompany = {
  value: 'management_company',
  id: 'management_company',
  label: 'fields.sectors.management_company',
  'data-testid': 'management_company',
};

export const PropertyManagementCompany = {
  value: 'property_management_company',
  id: 'property_management_company',
  label: 'fields.sectors.property_management_company',
  'data-testid': 'property_management_company',
};

export const PrivateDebtCompany = {
  value: 'private_debt_management_company',
  id: 'private_debt_management_company',
  label: 'fields.sectors.private_debt_management_company',
  'data-testid': 'private_debt_management_company',
};

export const PrivateEquityCompany = {
  value: 'venture_capital_and_private_equity_company',
  id: 'venture_capital_and_private_equity_company',
  label: 'fields.sectors.venture_capital_and_private_equity_company',
  'data-testid': 'venture_capital_and_private_equity_company',
};

export const Services = {
  value: 'services',
  id: 'services',
  label: 'fields.sectors.services',
  'data-testid': 'services',
  icon: ServicesIcon,
};

export const IndustryAndOtherServices = {
  value: 'industry_and_other_services',
  id: 'industry_and_other_services',
  label: 'fields.sectors.industry_and_other_services',
  'data-testid': 'industry_and_other_services',
};

export const FinancesServices = {
  value: 'finances_services',
  id: 'finances_services',
  label: 'fields.sectors.finances_services',
  'data-testid': 'finances_services',
};

export const FinancesServicesWithIcon = {
  value: 'finances_services',
  id: 'finances_services',
  label: 'fields.sectors.finances_services',
  icon: CurrencyExchangeIcon,
};

export const MediaAndCom = {
  value: 'media_and_com',
  id: 'media_and_com',
  label: 'fields.sectors.media_and_com',
  'data-testid': 'media_and_com',
};

export const RetailAndConsumerGoods = {
  value: 'retail_and_consumer_goods',
  id: 'retail_and_consumer_goods',
  label: 'fields.sectors.retail_and_consumer_goods',
  'data-testid': 'retail_and_consumer_goods',
};

export const Healthcare = {
  value: 'healthcare',
  id: 'healthcare',
  label: 'fields.sectors.healthcare',
  'data-testid': 'healthcare',
  icon: HealthcareIcon,
};

export const Manufacturing = {
  value: 'manufacturing',
  id: 'manufacturing',
  label: 'fields.sectors.manufacturing',
  'data-testid': 'manufacturing',
  icon: IndustryIcon,
};

export const Government = {
  value: 'government',
  id: 'government',
  label: 'fields.sectors.government',
  'data-testid': 'government',
  icon: GovernmentIcon,
};

export const Automotive = {
  value: 'automotive',
  id: 'automotive',
  label: 'fields.sectors.automotive',
  'data-testid': 'automotive',
};

export const DefenseAndIntelligence = {
  value: 'defense_and_intelligence',
  id: 'defense_and_intelligence',
  label: 'fields.sectors.defense_and_intelligence',
  'data-testid': 'defense_and_intelligence',
};

export const Education = {
  value: 'education',
  id: 'education',
  label: 'fields.sectors.education',
  'data-testid': 'education',
  icon: Graduation,
};

export const Travel = {
  value: 'travel',
  id: 'travel',
  label: 'fields.sectors.travel',
  'data-testid': 'travel',
};

export const Transportation = {
  value: 'transportation',
  id: 'transportation',
  label: 'fields.sectors.transportation',
  'data-testid': 'transportation',
  icon: Train,
};

export const LogisticAndHospitality = {
  value: 'logistic_and_hospitality',
  id: 'logistic_and_hospitality',
  label: 'fields.sectors.logistic_and_hospitality',
  'data-testid': 'logistic_and_hospitality',
};

export const Comic = {
  value: 'comic',
  id: 'comic',
  label: 'fields.sectors.comic',
  'data-testid': 'comic',
};

export const Document = {
  value: 'document',
  id: 'document',
  label: 'fields.sectors.document',
  'data-testid': 'document',
};

export const Teaching = {
  value: 'teaching',
  id: 'teaching',
  label: 'fields.sectors.teaching',
  'data-testid': 'teaching',
};

export const Youth = {
  value: 'youth',
  id: 'youth',
  label: 'fields.sectors.youth',
  'data-testid': 'youth',
};

export const Memory = {
  value: 'memory',
  id: 'memory',
  label: 'fields.sectors.memory',
  'data-testid': 'memory',
};

export const Detective = {
  value: 'detective',
  id: 'detective',
  label: 'fields.sectors.detective',
  'data-testid': 'detective',
};

export const Thriller = {
  value: 'thriller',
  id: 'thriller',
  label: 'fields.sectors.thriller',
  'data-testid': 'thriller',
};

export const Novel = {
  value: 'novel',
  id: 'novel',
  label: 'fields.sectors.novel',
  'data-testid': 'novel',
};

export const ScienceFiction = {
  value: 'science_fiction',
  id: 'science_fiction',
  label: 'fields.sectors.science_fiction',
  'data-testid': 'science_fiction',
};

export const Tale = {
  value: 'tale',
  id: 'tale',
  label: 'fields.sectors.tale',
  'data-testid': 'tale',
};

export const Art = {
  value: 'art',
  id: 'art',
  label: 'fields.sectors.art',
  'data-testid': 'art',
};

export const LeisureCultureArts = {
  value: 'leisure_culture_arts',
  id: 'leisure_culture_arts',
  label: 'fields.sectors.leisure_culture_arts',
  icon: AIGeneratedIcon,
};

export const HumanResourcesRecruitment = {
  value: 'human_resources_recruitment',
  id: 'human_resources_recruitment',
  label: 'fields.sectors.human_resources_recruitment',
  icon: TeamIcon,
};

export const Eroticism = {
  value: 'eroticism',
  id: 'eroticism',
  label: 'fields.sectors.eroticism',
  'data-testid': 'eroticism',
};

export const Humor = {
  value: 'humor',
  id: 'humor',
  label: 'fields.sectors.humor',
  'data-testid': 'humor',
};

export const Sport = {
  value: 'sport',
  id: 'sport',
  label: 'fields.sectors.sport',
  'data-testid': 'sport',
};

export const Religion = {
  value: 'religion',
  id: 'religion',
  label: 'fields.sectors.religion',
  'data-testid': 'religion',
};

export const Science = {
  value: 'science',
  id: 'science',
  label: 'fields.sectors.science',
  'data-testid': 'science',
};

export const SocialSciences = {
  value: 'social_sciences',
  id: 'social_sciences',
  label: 'fields.sectors.social_sciences',
  'data-testid': 'social_sciences',
};

export const PersonalDevelopment = {
  value: 'personal_development',
  id: 'personal_development',
  label: 'fields.sectors.personal_development',
  'data-testid': 'personal_development',
};

export const OtherWithoutPicto = {
  value: 'other',
  id: 'other',
  label: 'fields.sectors.others',
  'data-testid': 'other',
};

export const ComputerScience = {
  value: 'computer_science',
  id: 'computer_science',
  label: 'fields.sectors.computer_science',
  icon: Computer,
  'data-testid': 'computer_science',
};

export const EHealth = {
  value: 'e_health',
  id: 'e_health',
  label: 'fields.sectors.e_health',
  icon: EHealthIcon,
  'data-testid': 'e_health',
};

export const Elevator = {
  value: 'elevator',
  id: 'elevator',
  label: 'fields.sectors.elevator',
  icon: ElevatorIcon,
  'data-testid': 'elevator',
};

export const FacilityManagement = {
  value: 'facility_management',
  id: 'facility_management',
  label: 'fields.sectors.facility_management',
  icon: FacilityManagementIcon,
  'data-testid': 'facility_management',
};

export const Iot = {
  value: 'iot',
  id: 'iot',
  label: 'fields.sectors.iot',
  icon: IotIcon,
  'data-testid': 'iot',
};

export const Printing = {
  value: 'printing',
  id: 'printing',
  label: 'fields.sectors.printing',
  icon: PrintingIcon,
  'data-testid': 'printing',
};

export const Retail = {
  value: 'retail',
  id: 'retail',
  label: 'fields.sectors.retail',
  icon: RetailIcon,
  'data-testid': 'retail',
};

export const Security = {
  value: 'security',
  id: 'security',
  label: 'fields.sectors.security',
  icon: SecurityIcon,
  'data-testid': 'security',
};

export const WorksMultiTechnical = {
  value: 'works_multi_technical',
  id: 'works_multi_technical',
  label: 'fields.sectors.works_multi_technical',
  icon: Tools,
  'data-testid': 'works_multi_technical',
};

export const AgricultureAgronomy = {
  value: 'agriculture_agronomy',
  id: 'agriculture_agronomy',
  label: 'fields.sectors.agriculture_agronomy',
  icon: Wheat,
};

export const AeronauticsAerospace = {
  value: 'aeronautics_aerospace',
  id: 'aeronautics_aerospace',
  label: 'fields.sectors.aeronautics_aerospace',
  icon: Spaceship,
};

export const ChemistryPaperTextile = {
  value: 'chemistry_paper_textile',
  id: 'chemistry_paper_textile',
  label: 'fields.sectors.chemistry_paper_textile',
  icon: Molecule,
};

export const Building = {
  value: 'building',
  id: 'building',
  label: 'fields.sectors.building',
  icon: NewConstruction,
};

export const Cosmetic = {
  value: 'cosmetic',
  id: 'cosmetic',
  label: 'fields.sectors.cosmetic',
  icon: CosmeticIcon,
};

export const InstrumentationSensor = {
  value: 'instrumentation_sensor',
  id: 'instrumentation_sensor',
  label: 'fields.sectors.instrumentation_sensor',
  icon: SensorSpeed,
};

export const EducationSportCulture = {
  value: 'education_sport_culture',
  id: 'education_sport_culture',
  label: 'fields.sectors.education_sport_culture',
  icon: Graduation,
};

export const IndustryManufacturing = {
  value: 'industry_manufacturing',
  id: 'industry_manufacturing',
  label: 'fields.sectors.industry_manufacturing',
  icon: IndustryIcon,
};

export const MarketingCommunication = {
  value: 'marketing_communication',
  id: 'marketing_communication',
  label: 'fields.sectors.marketing_communication',
  icon: TwoPeople,
};

export const BusinessServices = {
  value: 'business_services',
  id: 'business_services',
  label: 'fields.sectors.business_services',
  icon: PresentationIcon,
};

export const MaterialsCoating = {
  value: 'materials_coating',
  id: 'materials_coating',
  label: 'fields.sectors.materials_coating',
  icon: MaterialsIcon,
};

export const ResearchBiology = {
  value: 'research_biology',
  id: 'research_biology',
  label: 'fields.sectors.research_biology',
  icon: BiologyIcon,
};

export const SecurityDefense = {
  value: 'security_defense',
  id: 'security_defense',
  label: 'fields.sectors.security_defense',
  icon: SecuritySvg,
};

export const Telecom = {
  value: 'telecom',
  id: 'telecom',
  label: 'fields.sectors.telecom',
  icon: Computer,
};

export const TherapeuticsMedtech = {
  value: 'therapeutics_medtech',
  id: 'therapeutics_medtech',
  label: 'fields.sectors.therapeutics_medtech',
  icon: HealthcareIcon,
};

export const TransportMobility = {
  value: 'transport_mobility',
  id: 'transport_mobility',
  label: 'fields.sectors.transport_mobility',
  icon: Train,
};

export const Technology = {
  value: 'technology',
  id: 'technology',
  label: 'fields.sectors.technology',
  icon: TechnologyIcon,
};

export const NonProfit = {
  value: 'non_profit',
  id: 'non_profit',
  label: 'fields.sectors.non_profit',
  icon: NonProfitIcon,
};

export const TransportLogistics = {
  value: 'transport_and_logistics',
  id: 'transport_and_logistics',
  label: 'fields.sectors.transport_and_logistics',
  icon: LogisticsIcon,
};

export const EnergyUtilities = {
  value: 'energy_utilities',
  id: 'energy_utilities',
  label: 'fields.sectors.energy_utilities',
  icon: BatteryLevel,
  'data-testid': 'energy_utilities',
};

export const ProfessionalServices = {
  value: 'professional_services',
  id: 'professional_services',
  label: 'fields.sectors.professional_services',
  icon: ServicesIcon,
  'data-testid': 'professional_services',
};

export const HospitalityTourism = {
  value: 'hospitality_and_tourism',
  id: 'hospitality_and_tourism',
  label: 'fields.sectors.hospitality_and_tourism',
  icon: TourismIcon,
  'data-testid': 'hospitality_and_tourism',
};

export const Gaming = {
  value: 'gaming',
  id: 'gaming',
  label: 'fields.sectors.gaming',
  icon: GamingIcon,
  'data-testid': 'gaming',
};

export const Blockchain = {
  value: 'blockchain',
  id: 'blockchain',
  label: 'fields.sectors.blockchain',
  icon: BlockchainIcon,
  'data-testid': 'blockchain',
};

export const MediaEntertainment = {
  value: 'media_and_entertainment',
  id: 'media_and_entertainment',
  label: 'fields.sectors.media_and_entertainment',
  icon: VideoGallery,
  'data-testid': 'media_and_entertainment',
};

export const Pharmaceuticals = {
  value: 'pharmaceuticals',
  id: 'pharmaceuticals',
  label: 'fields.sectors.pharmaceuticals',
  icon: PharmaceuticalsIcon,
  'data-testid': 'pharmaceuticals',
};

export const ECommerce = {
  value: 'e_commerce',
  id: 'e_commerce',
  label: 'fields.sectors.e_commerce',
  icon: ECommerceIcon,
  'data-testid': 'e_commerce',
};

export const Agriculture = {
  value: 'agriculture',
  id: 'agriculture',
  label: 'fields.sectors.agriculture',
  icon: Wheat,
  'data-testid': 'agriculture',
};

export const SpaceDefense = {
  value: 'space_and_defence',
  id: 'space_and_defence',
  label: 'fields.sectors.space_and_defence',
  icon: SecuritySvg,
  'data-testid': 'space_and_defence',
};

export const FoodBeverage = {
  value: 'food_and_beverage',
  id: 'food_and_beverage',
  label: 'fields.sectors.food_and_beverage',
  icon: FoodsIcon,
  'data-testid': 'food_and_beverage',
};

export const LeadersShareholders = {
  value: 'leaders_and_shareholders',
  id: 'leaders_and_shareholders',
  label: 'fields.sectors.leaders_and_shareholders',
  icon: LeaderIcon,
};

export const InvestmentFunds = {
  value: 'investment_funds',
  id: 'investment_funds',
  label: 'fields.sectors.investment_funds',
  icon: InvestmentIcon,
};

export const ListedCompanies = {
  value: 'listed_companies',
  id: 'listed_companies',
  label: 'fields.sectors.listed_companies',
  icon: StockIcon,
};

export const StartupsAndInnovation = {
  value: 'startups_and_innovation',
  id: 'startups_and_innovation',
  label: 'fields.sectors.startups_and_innovation',
  icon: IotIcon,
};

export const DigitalAndEcommerce = {
  value: 'digital_and_e_commerce',
  id: 'digital_and_e_commerce',
  label: 'fields.sectors.digital_and_e_commerce',
  icon: DigitalIcon,
};

export const MediaAndCommunication = {
  value: 'media_and_communication',
  id: 'media_and_communication',
  label: 'fields.sectors.media_and_communication',
  icon: VideoGallery,
};

export const FranchiseDistributionCommerce = {
  value: 'franchise_distribution_commerce',
  id: 'franchise_distribution_commerce',
  label: 'fields.sectors.franchise_distribution_commerce',
  icon: DistributionIcon,
};

export const Entertainment = {
  value: 'entertainment',
  id: 'entertainment',
  label: 'fields.sectors.entertainment',
  icon: EntertainmentIcon,
};

export const HospitalityAndLeisureRestaurant = {
  value: 'hospitality_and_leisure_restaurant',
  id: 'hospitality_and_leisure_restaurant',
  label: 'fields.sectors.hospitality_and_leisure_restaurant',
  icon: TourismIcon,
};

export const HotelRestaurant = {
  value: 'hotel_restaurant',
  id: 'hotel_restaurant',
  label: 'fields.sectors.hotel_restaurant',
  icon: HotelBellIcon,
};

export const BankingInsuranceMutual = {
  value: 'banking_insurance_mutual',
  id: 'banking_insurance_mutual',
  label: 'fields.sectors.banking_insurance_mutual',
  icon: BankPig,
};

export const TextileAndLuxury = {
  value: 'textile_and_luxury',
  id: 'textile_and_luxury',
  label: 'fields.sectors.textile_and_luxury',
  icon: Molecule,
};

export const FashionLuxuryBeauty = {
  value: 'fashion_luxury_beauty',
  id: 'fashion_luxury_beauty',
  label: 'fields.sectors.fashion_luxury_beauty',
  icon: LipGlossIcon,
};

export const RealEstateAndDevelopment = {
  value: 'real_estate_and_development',
  id: 'real_estate_and_development',
  label: 'fields.sectors.real_estate_and_development',
  icon: EstateIcon,
};

export const Industrial = {
  value: 'industrial',
  id: 'industrial',
  label: 'fields.sectors.industrial',
  icon: IndustryIcon,
};

export const Institutional = {
  value: 'institutional',
  id: 'institutional',
  label: 'fields.sectors.institutional',
  icon: InstitutionalIcon,
};

export const NonProfitOrganizationsAndSocialEconomy = {
  value: 'non_profit_organizations_and_social_economy',
  id: 'non_profit_organizations_and_social_economy',
  label: 'fields.sectors.non_profit_organizations_and_social_economy',
  icon: NonProfitIcon,
};

export const EnergyAndEnvironment = {
  value: 'energy_and_environment',
  id: 'energy_and_environment',
  label: 'fields.sectors.energy_and_environment',
  icon: World,
};

export const LiberalProfessions = {
  value: 'liberal_professions',
  id: 'liberal_professions',
  label: 'fields.sectors.liberal_professions',
  icon: LiberalIcon,
};

export const ArtisansAndTradesPeople = {
  value: 'artisans_and_trades_people',
  id: 'artisans_and_trades_people',
  label: 'fields.sectors.artisans_and_trades_people',
  icon: ArtisanIcon,
};

export const InstitutionalAndPublicFigures = {
  value: 'institutional_and_public_figures',
  id: 'institutional_and_public_figures',
  label: 'fields.sectors.institutional_and_public_figures',
  icon: PublicFigureIcon,
};

export const IndividualsAndFamilies = {
  value: 'individuals_and_families',
  id: 'individuals_and_families',
  label: 'fields.sectors.individuals_and_families',
  icon: FamilyIcon,
};

export const MultisectoralOrNoSectorialExpertise = {
  value: 'multisectoral_or_no_sectorial_expertise',
  id: 'multisectoral_or_no_sectorial_expertise',
  label: 'fields.sectors.multisectoral_or_no_sectorial_expertise',
  icon: Ellipsis,
};
