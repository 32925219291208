import {
  CANCELED,
  CREATED,
  reviewClientStatuses,
  reviewDeliveryStatuses,
  reviewStatuses,
  statusesProperties,
  toDeliverStatuses,
} from '../constants/statusesProperties';

import { GREEN, RED, BLUE, HGREY } from '../constants/colors';
/**
 * Format status properties (see statusesProperties.js)
 *
 * @param stringStatus
 * @param kind
 * @returns {(*&{secondary: (string|string|(function(*): *)|*|string), label: string, message: string, primary: (*|string)})|null}
 */
export const getStatusProperties = (stringStatus, kind) => {
  const statusProperties = statusesProperties[stringStatus];
  if (!statusProperties) return null;

  function formatStatusWithColors(status, userKind) {
    const messagePrefix = `contract.milestone.message${
      userKind ? `.${userKind}` : ''
    }`;
    const { message, primary, color, secondary, label, hasAction } = status;

    return {
      ...status,
      primary: primary || `m${color}`,
      secondary: secondary || `l${color}`,
      message:
        typeof message === 'function'
          ? message(messagePrefix)
          : `${messagePrefix}.${message}`,
      label: `contract.milestone.${hasAction ? 'action' : 'status'}.${label}`,
    };
  }

  return formatStatusWithColors(
    statusProperties[kind] || statusProperties,
    statusProperties[kind] && kind
  );
};

/**
 * Check if two array of statuses have at least one common status.
 * @param statuses
 * @param needles
 * @returns {boolean}
 */
const containsAtLeastOne = (statuses, needles) =>
  statuses.some((s) => needles.includes(s));

/**
 * Check if statutes contains some of another statuses
 * @param statuses
 * @param needles
 */
const everyInStatuses = (statuses, needles) =>
  statuses.every((s) => needles.includes(s));

/**
 * Count the number of milestone to deliver
 * @param statuses
 * @param needles
 */
const milestoneCountForStatus = (statuses, needles) =>
  statuses.filter((s) => needles.includes(s)).length;

/**
 * Build contract map properties for displaying message into contract card
 * @param contract
 * @param statuses
 * @returns {{}}
 */
export const getContractStatusesProperties = (contract, statuses) => ({
  expert: {
    finished: {
      condition: contract.isFinished(),
      color: GREEN,
    },
    created: {
      condition: everyInStatuses(statuses, [CREATED, CANCELED]),
      color: BLUE,
    },
    pendingDelivery: {
      condition: containsAtLeastOne(statuses, toDeliverStatuses),
      color: BLUE,
      value: milestoneCountForStatus(statuses, toDeliverStatuses),
    },
    pendingValidation: {
      condition: containsAtLeastOne(statuses, reviewStatuses),
      color: RED,
    },
    running: {
      condition: true,
      color: HGREY,
    },
  },
  client: {
    finished: {
      condition: contract.isFinished(),
      color: GREEN,
    },
    created: {
      condition: everyInStatuses(statuses, [CREATED, CANCELED]),
      color: BLUE,
      display: false,
    },
    pendingDelivery: {
      condition: containsAtLeastOne(statuses, reviewDeliveryStatuses),
      color: BLUE,
      value: milestoneCountForStatus(statuses, reviewDeliveryStatuses),
    },
    pendingValidation: {
      condition: containsAtLeastOne(statuses, reviewClientStatuses),
      color: RED,
      value: milestoneCountForStatus(statuses, reviewClientStatuses),
    },
    running: {
      condition: true,
      color: HGREY,
    },
  },
});
