import React from 'react';
import { arrayOf, number, shape, string, objectOf, any } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get, first } from 'lodash';
import { getMissionCompanyMatching } from '@/api/Mission/queries';
import userFragment from '@/api/User/fragment';
import HourlyRate from '@/components/HourlyRate';

const HourlyRateSection = ({
  currentUser,
  missionDataForHourlyRate,
  data,
  formValues,
}) => {
  if (!get(data, 'mission')) return null;

  const simulatorParams = {
    expTime: get(currentUser, 'company.exp_time'),
    categoryIds: get(currentUser, 'company.categories', []).map((c) =>
      get(c, 'id')
    ),
    practiceCulture: get(currentUser, 'company.practice_culture'),
    locationRegion: get(currentUser, 'company.location.province', ''),
    languages: get(currentUser, 'company.languages'),
    // Hourly_rate value has to come from the form
    // to handle dynamic logic in the Hourlyrate component
    hourlyRate: get(formValues, 'hourly_rate'),
    contractsCount: get(currentUser, 'company.expert_contracts.count'),
    missionData: {
      ...missionDataForHourlyRate,
      // Get the matching score from the matched_companies object
      matchingScore: first(
        get(data, 'mission.matched_companies.items', []).map((c) =>
          get(c, '_score')
        )
      ),
    },
  };

  return (
    <div className="p-t-m">
      <HourlyRate
        simulatorParams={simulatorParams}
        name="hourly_rate"
        withInformationText={false}
      />
    </div>
  );
};

HourlyRateSection.propTypes = {
  currentUser: propType(userFragment).isRequired,
  missionDataForHourlyRate: shape({
    workDays: number,
    workHours: number,
    languages: arrayOf(string),
    billing: string,
    beginAt: string,
    endAt: string,
    endMonths: string,
  }).isRequired,
  data: propType(getMissionCompanyMatching).isRequired,
  formValues: objectOf(any).isRequired,
};

export default HourlyRateSection;
