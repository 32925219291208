import React from 'react';
import { any, bool, func, objectOf, oneOf, string } from 'prop-types';
import cx from 'classnames';
import { ReactComponent as Add } from '@/images/add.svg';
import { ReactComponent as Delete } from '@/images/delete.svg';
import Tooltip from '@/components/Tooltip';
import FileInput from '../FileInput';
import './styles.scss';

const wrapWithTooltip = (children, tooltipProps) => (
  <Tooltip {...tooltipProps}>{children}</Tooltip>
);

function FileDropzone({
  icon,
  text,
  infoText,
  rejectText,
  error,
  errorText,
  size,
  tooltip,
  ...otherProps
}) {
  return (
    <FileInput
      {...otherProps}
      drop
      className={cx('FileDropzone', `FileDropzone--${size}`, {
        'FileDropzone--error': error,
      })}
    >
      {({ isDragReject }) => {
        const Icon = isDragReject || error ? Delete : icon;
        const label = error ? errorText : isDragReject ? rejectText : text; // eslint-disable-line no-nested-ternary
        const jsx = (
          <>
            <Icon />
            {label && <span className="FileDropzone__label">{label}</span>}
            {infoText && <span className="FileDropzone__meta">{infoText}</span>}
          </>
        );
        return tooltip ? wrapWithTooltip(jsx, tooltip) : jsx;
      }}
    </FileInput>
  );
}

FileDropzone.propTypes = {
  icon: func,
  text: string,
  infoText: string,
  rejectText: string,
  error: bool,
  errorText: string,
  size: oneOf(['small', 'large']),
  tooltip: objectOf(any),
};

FileDropzone.defaultProps = {
  icon: Add,
  text: null,
  infoText: null,
  rejectText: null,
  error: false,
  errorText: null,
  size: 'large',
  tooltip: null,
};

export default FileDropzone;
