import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import translate from '@/helpers/enhancers/translate';
import { updateInterviewsConduct } from '@/api/Company/mutations';
import InterviewsConductModal from './InterviewsConductModal';

export default compose(
  graphql(updateInterviewsConduct, { name: 'update' }),
  translate()
)(InterviewsConductModal);
