import _ from 'lodash';
import { addValidator } from 'redux-form-validators';

const ns = 'fields.validators.files';
const errors = {
  notFiles() {
    return {
      id: `${ns}.not_files`, // Cette erreur n'est pas censée arriver
    };
  },
  notEnoughFiles(values) {
    return {
      id: `${ns}.not_enough_files`,
      values,
    };
  },
  tooManyFiles(values) {
    return {
      id: `${ns}.too_many_files`,
      values,
    };
  },
  accept(values) {
    return {
      id: `${ns}.accept`,
      values,
    };
  },
  minSize(values) {
    return {
      id: `${ns}.too_small`,
      values,
    };
  },
  maxSize(values) {
    return {
      id: `${ns}.too_large`,
      values,
    };
  },
};

const hasAllProperties = (keys) => (obj) => keys.every((k) => k in obj);

const isFile = hasAllProperties(['mime_type', 'name', 'size']);

const isFileArray = (collection) => collection.every(isFile);

const fileExt = (filename) =>
  filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 1).toLowerCase(); // eslint-disable-line

const filesValidator = (
  {
    minSize = 0,
    maxSize = Infinity,
    minFiles = 0,
    maxFiles = Infinity,
    accept = [],
  },
  value
) => {
  const filesList = _.castArray(value);
  if (!isFileArray(filesList)) {
    return errors.notFiles();
  }
  if (filesList.length < minFiles) {
    return errors.notEnoughFiles({ count: minFiles });
  }
  if (filesList.length > maxFiles) {
    return errors.tooManyFiles({ count: maxFiles });
  }

  const accepts = _.castArray(accept);

  const acceptExts = accepts.map((a) => a.split('/')[1]);
  const acceptErrors = [];
  const minSizeErrors = [];
  const maxSizeErrors = [];
  filesList.forEach((file) => {
    if (
      accepts.length &&
      !accepts.includes(file.mime_type) &&
      !acceptExts.includes(fileExt(file.name))
    ) {
      acceptErrors.push(file);
    }
    if (file.size < minSize) {
      minSizeErrors.push(file);
    }
    if (file.size > maxSize) {
      maxSizeErrors.push(file);
    }
  });

  if (acceptErrors.length) {
    return errors.accept({
      files: acceptErrors,
      count: acceptErrors.length,
      accept,
    });
  }
  if (minSizeErrors.length) {
    return errors.minSize({
      files: minSizeErrors,
      count: minSizeErrors.length,
      size: minSize,
    });
  }
  if (maxSizeErrors.length) {
    return errors.maxSize({
      files: maxSizeErrors,
      count: maxSizeErrors.length,
      size: maxSize,
    });
  }
  return undefined;
};

export default addValidator({ validator: filesValidator });
