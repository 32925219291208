import { graphql, compose } from 'react-apollo';
import translate from '@/helpers/enhancers/translate';
import { updateNotification } from '@/api/Notification/mutations';
import Notification from './Notification';

export default compose(
  translate(),
  graphql(updateNotification, {
    name: 'updateNotification',
  })
)(Notification);
