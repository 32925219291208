import React from 'react';
import { func } from 'prop-types';
import {
  RfHelper,
  RequiredField,
  RfSlider,
  RfLabelledCheckbox,
  Field,
} from '@/containers/ReduxForm';
import config from '@/_config';
import FormSection from '@/components/FormSection';
import { modifyRange } from '@/helpers/validation';
import {
  FULLTIME_CONTRACT,
  INTERNSHIP,
  MISSIONS,
  FIXED_TERM_CONTRACT,
  THESIS,
  APPRENTICESHIP,
} from '@/constants/contractTypes';

function RemunerationSection({ t }) {
  return (
    <RfHelper if="contract_types">
      {({ contract_types }) => {
        if ([APPRENTICESHIP, INTERNSHIP, THESIS].includes(contract_types))
          return null;
        return (
          <FormSection title={t('company.modal.modalities.remuneration.title')}>
            <div>
              {(contract_types.includes(MISSIONS) ||
                config.company.contractTypeIsAlwaysMissions) && (
                <RequiredField
                  component={RfSlider}
                  name="daily_rate"
                  key="daily_rate"
                  domain={[
                    config.company.dailyRate.min,
                    config.company.dailyRate.max,
                  ]}
                  step={10}
                  className="m-b-l"
                  label={t('company.modal.modalities.remuneration.tjm.label')}
                  helper={t('company.modal.modalities.remuneration.tjm.helper')}
                  // eslint-disable-next-line no-unused-vars
                  valueKey={([_, max]) => {
                    if (max < config.company.dailyRate.max) {
                      return 'company.modal.modalities.remuneration.daily_rate.value';
                    }
                    return 'company.modal.modalities.remuneration.daily_rate.value.max';
                  }}
                  validate={[
                    modifyRange({
                      min: config.company.dailyRate.min,
                      max: config.company.dailyRate.max,
                    }),
                  ]}
                />
              )}
              {(contract_types.includes(FULLTIME_CONTRACT) ||
                contract_types.includes(FIXED_TERM_CONTRACT)) &&
                !config.company.contractTypeIsAlwaysMissions && (
                  <div>
                    <RequiredField
                      component={RfSlider}
                      name="salary"
                      domain={[
                        config.company.salary.min,
                        config.company.salary.max,
                      ]}
                      step={config.company.salary.step}
                      label={t(
                        'company.modal.modalities.remuneration.salary.label'
                      )}
                      helper={t(
                        'company.modal.modalities.remuneration.salary.helper'
                      )}
                      valueKey="company.modal.modalities.remuneration.salary.value"
                      validate={[
                        modifyRange({
                          min: config.company.salary.min,
                          max: config.company.salary.max,
                        }),
                      ]}
                    />
                    {config.company.showCompanySalaryConfidentiality && (
                      <Field
                        id="salary_confidentiality"
                        name="salary_confidentiality"
                        component={RfLabelledCheckbox}
                        label={t('salary.confidentiality')}
                        type="checkbox"
                      />
                    )}
                  </div>
                )}
            </div>
          </FormSection>
        );
      }}
    </RfHelper>
  );
}

RemunerationSection.propTypes = {
  t: func.isRequired,
};

export default RemunerationSection;
