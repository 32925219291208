/**
 * Solution from https://stackoverflow.com/questions/2390038/replace-n-with-br-and-r-n-with-p-in-javascript
 *
 * This function replaces \n and \r\n in a string by <br /> tag
 * Useful when a textarea is replaced by a wysiwyg to keep the layout
 * @param rawContent content to format
 * @returns {string} Null if there is no rawContent
 */
export const getFormattedContentWithBrTag = (rawContent) => {
  if (rawContent) {
    const formattedContent = rawContent
      .replace(/\r\n/g, '<br />')
      .replace(/\n/g, '<br />');
    return formattedContent;
  }
  return null;
};
