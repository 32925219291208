import { get } from 'lodash';
import { CLIENT, EXPERT, MIXED } from '../constants/userKind';

/**
 *
 * @param user
 * @returns {boolean}
 */
export function isClient(user) {
  return get(user, 'kind', '') === CLIENT;
}

/**
 *
 * @param user
 * @returns {boolean}
 */
export function isExpertOrMixed(user) {
  const kind = get(user, 'kind', '');
  return [EXPERT, MIXED].includes(kind);
}
