import React from 'react';
import useTranslation from '@/hooks/useTranslation';
import pp from 'prop-types';
import Page from '@/components/Page';
import Button from '@/components/Button';
import './styles.scss';

const Error = ({ statusCode, message }) => {
  const { t } = useTranslation();
  return (
    <Page name="Error" className="Error">
      <big>{statusCode}</big>
      <p>{t(message)}</p>
      <Button
        onClick={() => {
          window.location.href = '/';
        }}
      >{`Retourner à l'accueil`}</Button>
    </Page>
  );
};

Error.propTypes = {
  statusCode: pp.oneOfType([pp.string, pp.number]).isRequired,
  message: pp.string,
};

Error.defaultProps = {
  message: null,
};

export default Error;
