import React from 'react';
import {
  shape,
  string,
  objectOf,
  any,
  bool,
  oneOfType,
  array,
  func,
} from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import ProfileModal from '../../ProfileModal';
import CardChoiceSection from '@/components/Sections/CardChoiceSection';
import CardChoiceField from '@/components/Fields/CardChoiceField/CardChoiceField';

function TagListModal({
  leader,
  modalTitle,
  sectionTitle,
  formName,
  fieldProps,
  tags,
  required,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <ProfileModal title={t(modalTitle)} form={formName} {...props}>
      <CardChoiceSection title={t(sectionTitle)}>
        <CardChoiceField {...fieldProps} cbProps={tags} required={required} />
      </CardChoiceSection>
    </ProfileModal>
  );
}

TagListModal.propTypes = {
  fieldProps: objectOf(
    shape({
      label: string.isRequired,
      name: string.isRequired,
      validate: oneOfType([array, func]),
    })
  ).isRequired,
  formName: string.isRequired,
  leader: objectOf(any).isRequired,
  modalTitle: string,
  sectionTitle: string,
  required: bool,
};

TagListModal.defaultProps = {
  modalTitle: '',
  sectionTitle: '',
  required: false,
};

export default TagListModal;
