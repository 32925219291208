import moment from 'moment';
import { nth } from 'lodash';

export function getMonthLabel(month) {
  const monthMatch = month.match(/month_(\d+)/);
  if (monthMatch) {
    return moment()
      .month(nth(monthMatch, 1) - 1)
      .format('MMMM');
  }
  return month;
}
