import React from 'react';
import { any, func, objectOf } from 'prop-types';
import { get, map } from 'lodash';
import { Field, FieldArray } from 'redux-form';
import config from '@/_config';
import {
  RequiredField,
  RfText,
  RfEditor,
  RfFiles,
  RfTags,
  RfNumber,
  RfImage,
  RfLocations,
  RfPhone,
} from '@/containers/ReduxForm';
import {
  emailOrEmptyEmail,
  phoneOrEmptyPhone,
  lengthSkills,
} from '@/helpers/validation';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE_COLLABORATOR } from '@/constants/forms';
import { PROFILE } from '@/constants/features';
import TagsContext from '@/components/Tags/TagsContext';
import { ReactComponent as Camera } from '@/images/camera.svg';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { getFormattedContentWithBrTag } from '@/helpers/formatContent';
import { setEquipmentsInitialValues } from '@/helpers/equipments';

const CollaboratorsModal = ({ t, currentUser, ...props }) => {
  const { initialValues } = props;
  const description = get(initialValues, 'description');
  const currentEquipments = get(initialValues, 'equipments', []);
  const avatar = get(initialValues, 'avatar', {});
  const jobName = get(initialValues, 'job.name');
  const withEquipments = config.company.showEquipments;

  const formatValues = ({
    skills,
    attachments,
    personal_skills,
    equipments,
    on_site_locations,
    ...values
  }) => ({
    ...values,
    skills: formatTags(skills),
    personal_skills: formatTags(personal_skills),
    attachment_ids: attachments.map((a) => a.id),
    company_id: get(currentUser, 'company.id'),
    equipments: formatTags(equipments),
    on_site_locations: map(on_site_locations.filter((l) => l), 'address'),
  });

  const getInitialValues = () => ({
    ...initialValues,
    ...(description && {
      description: getFormattedContentWithBrTag(description),
    }),
    ...(avatar && {
      avatar: {
        original: { ...get(initialValues, 'avatar') },
        updated: null,
      },
    }),
    ...(withEquipments && {
      ...setEquipmentsInitialValues(currentEquipments),
    }),
    equipments: currentEquipments,
    skills: get(initialValues, 'skills', []),
    personal_skills: get(initialValues, 'personal_skills', []),
    job_name: jobName,
  });

  return (
    <ProfileModal
      title={t('company.modal.collaborator.title')}
      form={PROFILE_COLLABORATOR}
      formatValues={formatValues}
      {...props}
      initialValues={getInitialValues()}
    >
      <FormSection>
        <RequiredField
          id="first_name"
          name="first_name"
          label={t('company.modal.collaborator.first_name.label')}
          className="m-b-m"
          component={RfText}
        />
        <Field
          id="last_name"
          name="last_name"
          label={t('company.modal.collaborator.last_name.label')}
          className="m-b-m"
          component={RfText}
        />
        <Field
          id="email"
          name="email"
          type="email"
          label={t('company.modal.collaborator.email.label')}
          validate={[emailOrEmptyEmail()]}
          className="m-b-m"
          component={RfText}
        />
        <Field
          id="phone"
          name="phone"
          type="phone"
          label={t('company.modal.collaborator.phone.label')}
          validate={[phoneOrEmptyPhone()]}
          className="m-b-m"
          autoComplete="tel"
          component={RfPhone}
        />
        <RequiredField
          id="job_name"
          name="job_name"
          label={t('company.modal.collaborator.job_name.label')}
          className="m-b-m"
          component={RfText}
        />
        <RequiredField
          component={RfNumber}
          name="exp_time"
          className="Modalities__experience"
          max={config.company.experienceTime.max}
          min={config.company.experienceTime.min}
          step={1}
          label={t('company.modal.collaborator.exp_time.label')}
        />
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(PROFILE),
            highlightMaxValues: get(config, 'skills.highlightMax'),
          }}
        >
          <Field
            id="collaborator-skills"
            name="skills"
            component={RfTags}
            description="company.modal.collaborator.skills.label"
            {...getSkillsValidationAndExtraProps()}
          />
          <Field
            id="personal_skills"
            name="personal_skills"
            component={RfTags}
            description="profile.form.skills_personal.description"
            {...getSkillsValidationAndExtraProps()}
          />
        </TagsContext.Provider>
        <div>
          <span className="fs-small">{t('company.modal.picture.title')}</span>
          <Field
            component={RfImage}
            id="avatar"
            name="avatar"
            className="d-f jc-c m-b-m"
            edit
            icon={Camera}
            text={t('company.modal.picture.dropzone.text')}
            infoText={t('company.modal.picture.dropzone.infoText')}
            rejectText={t('company.modal.picture.dropzone.rejectText')}
            newText={t('company.modal.picture.dropzone.newText')}
            attachmentKind="avatar"
          />
        </div>
        <Field
          id="description"
          name="description"
          label={t('company.modal.collaborator.description.label')}
          placeholder={t('company.modal.collaborator.description.placeholder')}
          component={RfEditor}
          className="m-b-m"
        />
        <FieldArray
          inline
          component={RfLocations}
          name="on_site_locations"
          className="m-b-l"
          addLabel={t('company.modal.collaborator.locations.add')}
          inputProps={{
            label: t('company.modal.collaborator.location.label'),
            placeholder: t('locations.input.placeholder'),
          }}
          useSpacer={false}
        />
        {withEquipments && (
          <TagsContext.Provider
            value={{
              mode: props.mode,
              withRating: hasRating(PROFILE),
              max: get(config, 'validation.expertProfile.minSkillsLength'), // default is skill, feel free to create config for your own type of tag
              highlightMaxValues: get(config, 'skills.highlightMax'),
              ratingWithNumberField: true,
            }}
          >
            <Field
              id="equipments"
              name="equipments"
              component={RfTags}
              description="profile.form.equipments_required.description"
              {...getSkillsValidationAndExtraProps([
                lengthSkills({
                  min: 0,
                }),
              ])}
            />
          </TagsContext.Provider>
        )}
        <Field
          component={RfFiles}
          multiple
          id="attachments"
          name="attachments"
          label={t('company.modal.collaborator.attachments.add')}
          text={t('company.modal.collaborator.attachments.text')}
          infoText={t('company.modal.collaborator.attachments.infoText')}
          rejectText={t('company.modal.collaborator.attachments.rejectText')}
          t={t}
        />
      </FormSection>
    </ProfileModal>
  );
};

CollaboratorsModal.propTypes = {
  t: func.isRequired,
  close: func.isRequired,
  initialValues: objectOf(any),
};

CollaboratorsModal.defaultProps = {
  initialValues: {},
};

export default CollaboratorsModal;
