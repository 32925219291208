export const MISSION = 'missions-creator';
export const PROFILE_SKILLS = 'company_skills';
export const PROFILE_EQUIPMENTS = 'company_equipments';
export const PROFILE_TOOLS = 'company_tools';
export const PROFILE_INDIVIDUAL_CERTIFICATION_SKILLS =
  'company_individual_certification_skills';
export const PROFILE_KEYWORDS = 'company_keywords';
export const PROFILE_PROJECT = 'company_project';
export const PROFILE_DEGREE = 'company_degree';
export const PROFILE_CERTIFICATION = 'company_certification';
export const PROFILE_MODALITIES = 'company_modalities';
export const PROFILE_ACTIVITIES = 'company_activities';
export const POST_SIGNUP = 'post-signup';
export const BLACKLIST = 'blacklist';
export const EQUIPMENTS = 'equipments';
export const PROFILE_COLLABORATOR = 'company_collaborator';
export const PROFILE_COLLABORATOR_NUMBERS = 'company_collaborator_numbers';
export const PROFILE_COMPANY_VALUES = 'company_value_skills';
