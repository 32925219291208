const scrollToError = (offset = 200) => async () => {
  await setTimeout(() => {}, 0); // 🧙🏿‍

  const firstErrorNode = document.querySelector('.Field--error');
  if (!firstErrorNode) return;

  const scrollContainer =
    document.querySelector('.Modal__overlay') || document.documentElement;

  const position =
    firstErrorNode.getBoundingClientRect().top + scrollContainer.scrollTop;

  scrollContainer.scrollTo({ top: position - offset, behavior: 'smooth' });
};

export default scrollToError;
