import React, { useRef } from 'react';
import { Trans } from 'react-i18next';
import { PDF } from '@/constants/file';
import {
  FileField,
  Field,
  RfFiles,
  RfChoices,
  RfText,
} from '@/containers/ReduxForm';
import LabelledCheckbox from '@/components/Choice/LabelledCheckbox';
import { ReactComponent as Warning } from '@/images/warning.svg';
import useTranslation from '@/hooks/useTranslation';
import { bool, func, string } from 'prop-types';
import { linkedinUrl } from '@/helpers/validation';
import {
  POST_SIGNUP_CV_PARSING,
  CLIENT_POOL,
  MY_MISSION_BOARD,
} from '@/constants/router_base';
import { isLinkedinUrl } from '@/helpers/matchRegex';
import { otherKeysVariables } from '@/helpers/contextKeys';
import { isEmpty } from 'lodash';
import config from '@/_config';

function CvParsing({
  validator,
  blockNextStep,
  setBlockNextStep,
  showWarningText,
  showLinkedinTitle,
  cvDescriptionTranslation,
  showUpdateProfileCheckbox,
  mission_id,
}) {
  const { t } = useTranslation();
  const linkedin_vanity_name_ref = useRef();

  const linked_vanity_name_value = document.getElementById(
    'linkedin_vanity_name'
  )
    ? document.getElementById('linkedin_vanity_name').value
    : '';

  if (
    setBlockNextStep && // function setBlockNextStep exist
    !isEmpty(linked_vanity_name_value) && // linkedin_vanity_name is filled
    !isLinkedinUrl(linked_vanity_name_value) // check if url done in form is not good isLinkedinUrl
  ) {
    setBlockNextStep(true);
  } else if (blockNextStep) setBlockNextStep(false);

  const getLabelLinkedin = () => {
    switch (window.location.pathname) {
      case POST_SIGNUP_CV_PARSING:
        return (
          <h2 className="m-t-l m-b-m">
            {t('postSignup.linkedInVanityName.subtitle')}
          </h2>
        );
      case CLIENT_POOL:
      case MY_MISSION_BOARD.replace(':id', mission_id):
        return (
          <h4 className="Form__label m-t-m">
            {t('add_expert_pool.modal.linkedInVanityName.subTitle')}
          </h4>
        );
      default:
        return (
          <div className="Modal__heading">
            <h2>
              {t(
                showLinkedinTitle || 'company.modal.linkedin_vanity_name.title'
              )}
            </h2>
          </div>
        );
    }
  };

  return (
    <div>
      <p className="m-b-m grey">
        <Trans
          i18nKey={
            cvDescriptionTranslation
              ? t(cvDescriptionTranslation)
              : 'company.modal.cv.description'
          }
          values={{
            how_to: t('company.modal.cv.description.how_to'),
            ...otherKeysVariables(t),
          }}
          components={[
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="CVModal__link"
              href={t('company.modal.cv.description.how_to.link')}
            >
              how to
            </a>,
          ]}
        />
      </p>

      {config.company.extendedCvUpload && (
        <span className="CVModal__setting__label d-f m-b-m">
          {t('company.modal.cv.attachment.label')}
        </span>
      )}
      <FileField
        component={RfFiles}
        id="cv"
        name="cv"
        maxFiles={1}
        maxSize={1e7}
        multiple
        accept={[PDF]}
        text={t('company.modal.cv.dropzone.text')}
        infoText={t('company.modal.cv.dropzone.infoText')}
        rejectText={t('company.modal.cv.dropzone.rejectText')}
        t={t}
        attachmentKind="cv"
      />
      {config.company.extendedCvUpload && (
        <>
          <span className="CVModal__setting__label d-f m-t-m">
            {t('company.modal.cv.settings.label')}
          </span>

          <Field
            id="settings"
            name="settings"
            type="checkbox"
            component={RfChoices}
            cbProps={[
              {
                label: t('company.modal.cv.settings.update_profile.label'),
                subLabel: t(
                  'company.modal.cv.settings.update_profile.sub_label'
                ),
                value: 'update_profile',
              },
              {
                label: t(
                  'company.modal.cv.settings.display_cv_on_profile.label'
                ),
                subLabel: t(
                  'company.modal.cv.settings.display_cv_on_profile.sub_label'
                ),
                value: 'display_cv_on_profile',
              },
            ]}
            checkbox={LabelledCheckbox}
            className="m-t-m"
            validate={validator && [validator]}
          />
        </>
      )}
      {config.company.updateProfileCvUpload &&
        showUpdateProfileCheckbox && (
          <>
            <Field
              id="settings"
              name="settings"
              type="checkbox"
              component={RfChoices}
              cbProps={[
                {
                  label: t('company.modal.cv.settings.update_profile.label'),
                  subLabel: t(
                    'company.modal.cv.settings.update_profile.sub_label'
                  ),
                  value: 'update_profile',
                },
              ]}
              checkbox={LabelledCheckbox}
              className="m-t-m"
            />
          </>
        )}
      {showWarningText && (
        <span className="CVModal__setting__warning m-t-m  m-b-l d-f">
          <span className="CVModal__setting__warning__icon">
            <Warning />
          </span>
          <span className="CVModal__setting__warning__label grey">
            {t('company.modal.cv.settings.warning')}
          </span>
        </span>
      )}
      {config.company.showLinkedinField && (
        <>
          {getLabelLinkedin()}
          <Field
            id="linkedin_vanity_name"
            name="linkedin_vanity_name"
            ref={linkedin_vanity_name_ref}
            component={RfText}
            validate={[linkedinUrl({ allowBlank: true })]}
            label={t('settings.account.informations.likedinUrl.label')}
            placeholder={t(
              'settings.account.informations.linkedinUrl.placeholder'
            )}
          />
          {showWarningText && (
            <span className="CVModal__setting__warning m-t-m d-f">
              <span className="CVModal__setting__warning__icon">
                <Warning />
              </span>
              <span className="CVModal__setting__warning__label grey">
                {t('company.modal.linkedin.settings.warning')}
              </span>
            </span>
          )}
        </>
      )}
    </div>
  );
}

CvParsing.propTypes = {
  validator: func,
  blockNextStep: bool,
  setBlockNextStep: func,
  showWarningText: bool,
  showLinkedinTitle: string,
  cvDescriptionTranslation: string,
  showUpdateProfileCheckbox: bool,
  mission_id: string,
};

CvParsing.defaultProps = {
  validator: null,
  blockNextStep: false,
  setBlockNextStep: null,
  showWarningText: true,
  showLinkedinTitle: null,
  cvDescriptionTranslation: null,
  showUpdateProfileCheckbox: true,
  mission_id: null,
};
export default CvParsing;
