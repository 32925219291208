import { get } from 'lodash';
import { string, oneOfType, array } from 'prop-types';
import React, { useContext } from 'react';
import Badge from '@/components/Badge';
import CountersContext from '@/contexts/CountersContext';

function NavCounter({ name }) {
  const countersContext = useContext(CountersContext);
  const count = (() => {
    if (typeof name === 'string') {
      return get(countersContext, name, 0);
    }

    return name.reduce(
      (acc, currentValue) => acc + get(countersContext, currentValue, 0),
      0
    );
  })();

  return <>{count > 0 && <Badge count={count} />}</>;
}

NavCounter.propTypes = {
  name: oneOfType([string, array]).isRequired,
};

export default NavCounter;
