import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { updateBookmark } from '@/api/Bookmark/mutations';
import translate from '@/helpers/enhancers/translate';
import reduxForm from '@/helpers/enhancers/reduxForm';
import BookmarkEditModal from './BookmarkEditModal';

export default compose(
  graphql(updateBookmark, { name: 'updateBookmark' }),
  reduxForm({ form: 'bookmark_edit' }),
  translate()
)(BookmarkEditModal);
