import React from 'react';
import { func } from 'prop-types';
import { get } from 'lodash';
import { Field } from 'redux-form';
import uuid from 'uuid/v1';
import { date } from '@/helpers/validation';
import {
  RequiredField,
  RfText,
  RfLocation,
  RfEditor,
  RfDate,
  RfHelper,
  RfTags,
} from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';
import { formatTags, hasRating } from '@/components/Tags/helpers/handler';
import { PROFILE_PROJECT } from '@/constants/forms';
import TagsContext from '@/components/Tags/TagsContext';
import { getSkillsValidationAndExtraProps } from '@/helpers/skills';
import { getFormattedContentWithBrTag } from '@/helpers/formatContent';

const generatedAutocomplete = uuid();

const ProjectModal = ({ t, ...props }) => {
  const { initialValues } = props;
  const description = get(initialValues, 'description');

  return (
    <ProfileModal
      title={t('company.modal.project.title')}
      form={PROFILE_PROJECT}
      formatValues={({ location, skills, ...values }) => ({
        ...values,
        location: location && location.address,
        skills: formatTags(skills),
      })}
      {...props}
      // If the description already exists, it needs to be formatted to keep the layout
      {...description && {
        initialValues: {
          ...initialValues,
          description: getFormattedContentWithBrTag(description),
        },
      }}
    >
      <FormSection>
        <RequiredField
          id="title"
          name="title"
          label={t('company.modal.project.name.label')}
          className="m-b-m"
          component={RfText}
        />
        <Field
          id="company_name"
          name="company_name"
          className="m-b-m"
          component={RfText}
          label={t('company.modal.project.company.label')}
        />
        <Field
          id="location"
          name="location"
          className="m-b-m"
          component={RfLocation}
          autoComplete={generatedAutocomplete}
          searchOptions={{ types: ['(regions)'] }}
          inputProps={{
            label: t('company.modal.project.location.label'),
            placeholder: t('locations.input.placeholder'),
          }}
        />
        <div className="grid">
          <div className="grid__item laptop--one-whole one-half">
            <RequiredField
              id="begin_at"
              name="begin_at"
              label={t('fields.date.from')}
              className="m-b-s"
              component={RfDate}
              picker={false}
            />
          </div>
          <div className="grid__item laptop--one-whole one-half">
            <RfHelper values={['begin_at']}>
              {({ begin_at }) => (
                <Field
                  id="end_at"
                  name="end_at"
                  label={t('fields.date.to')}
                  className="m-b-m"
                  component={RfDate}
                  picker={false}
                  validate={[
                    date({
                      '>': begin_at || '',
                    }),
                  ]}
                />
              )}
            </RfHelper>
          </div>
        </div>
        <Field
          id="description"
          name="description"
          label={t('company.modal.project.description.label')}
          placeholder={t('company.modal.project.description.placeholder')}
          component={RfEditor}
        />
        <TagsContext.Provider
          value={{
            mode: props.mode,
            withRating: hasRating(),
          }}
        >
          <Field
            id="project-skills"
            name="skills"
            component={RfTags}
            description="company.section.projects.technicalEnv.label"
            {...getSkillsValidationAndExtraProps()}
          />
        </TagsContext.Provider>
      </FormSection>
    </ProfileModal>
  );
};

ProjectModal.propTypes = {
  t: func.isRequired,
};

export default ProjectModal;
