import React, { useState } from 'react';
import { string, bool, node } from 'prop-types';
import cx from 'classnames';
import { ReactComponent as Close } from '@/images/delete.svg';
import './styles.scss';

function Message({ className, dismissible, title, text, action, type }) {
  const [isOpen, setIsOpen] = useState(true);
  if (!isOpen) return null;
  return (
    <div
      className={cx(
        'Message',
        { 'Message--dismissible': dismissible },
        type,
        className
      )}
    >
      {dismissible && (
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className={`Message__dismiss Message__dismiss--${type}`}
        >
          <Close />
        </button>
      )}
      <div className="Message__inner">
        {title && <h1 className="Message__title">{title}</h1>}
        <p className="Message__content">{text}</p>
        {action && <div className="Message__action">{action}</div>}
      </div>
    </div>
  );
}

Message.propTypes = {
  className: string,
  dismissible: bool,
  title: string,
  text: string.isRequired,
  action: node,
  type: string,
};

Message.defaultProps = {
  className: null,
  dismissible: false,
  title: null,
  action: null,
  type: 'info',
};

export default Message;
