import React from 'react';
import pp from 'prop-types';
import { ReactComponent as Burger } from '@/images/burger.svg';
import Drawer from '@/components/Drawer';
import HeaderLink from '../Link';
import OfflineDrawerScreen from './OfflineDrawerScreen';

const OfflineDrawer = ({ t }) => (
  <Drawer
    width={255}
    placement="right"
    className="Header__offline"
    renderReference={({ open }) => (
      <HeaderLink onClick={open}>
        <Burger />
      </HeaderLink>
    )}
  >
    <Drawer.Screen name="default">
      <OfflineDrawerScreen t={t} />
    </Drawer.Screen>
  </Drawer>
);

OfflineDrawer.propTypes = {
  t: pp.func.isRequired,
};

export default OfflineDrawer;
