import { compose } from 'recompose';
import translate from '@/helpers/enhancers/translate';
import withCurrentUser from '@/helpers/enhancers/withCurrentUser';
import Header from './Header';
import renderWhileLoading from '@/helpers/enhancers/renderWhileLoading';

export default compose(
  withCurrentUser,
  translate(),
  renderWhileLoading()
)(Header);
