/**
 * Translate label attribute of each array item
 *
 * @param t
 * @param array
 * @returns {*}
 */
export const translateArrayLabel = (t, array) =>
  array.map((item) => ({
    ...item,
    label: t(item.label),
  }));

/**
 * Return the right suffix of trad key for trad with content based on a count value
 * the binary param is used for trad with content modified only between zero value and other
 * @param {string} key
 * @param {number} count
 * @param {boolean} binary
 * @returns {string}
 */
export const tradWithCountSuffixKey = (key, count, binary = false) => {
  if (count === 0) {
    return `${key}.zero`;
  }
  if (binary && count > 0) {
    return `${key}.other`;
  }
  if (count > 0 && count < 2) {
    return `${key}.one`;
  }
  return `${key}.other`;
};
