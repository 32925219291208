import useTranslation from '@/hooks/useTranslation';
import {
  EQUIPMENTS,
  COMPANY_VALUES_SKILLS,
  PROFILE_COMPANY_VALUES_SKILLS,
  OPTIONAL_SKILLS,
  TOOLS,
} from '@/constants/fields';
import { get } from 'lodash';

const placeholderMap = {
  [EQUIPMENTS]: {
    translation: `tags.add.form.${EQUIPMENTS}.label`,
  },
  [COMPANY_VALUES_SKILLS]: {
    translation: `tags.add.form.${COMPANY_VALUES_SKILLS}.label`,
  },
  [PROFILE_COMPANY_VALUES_SKILLS]: {
    translation: `tags.add.form.${PROFILE_COMPANY_VALUES_SKILLS}.label`,
  },
  [OPTIONAL_SKILLS]: {
    translation: `tags.add.form.${OPTIONAL_SKILLS}.label`,
  },
  [TOOLS]: {
    translation: `tags.add.form.${TOOLS}.label`,
  },
};

export const placeholderField = (field, defaultPlaceHolder) => {
  function PlaceholderField() {
    const { t } = useTranslation();
    return t(get(placeholderMap, `${field}.translation`, defaultPlaceHolder));
  }
  return PlaceholderField;
};
