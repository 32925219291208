import { ASSET_FOLDERS } from '@/constants/assets';
import { ICO } from '@/constants/extensions';

// Tools images
export const getAssetImagePath = (fieldName, name, extension = ICO) =>
  `/assets/images/${fieldName}/${name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replaceAll(/ /gi, '-')
    .toLowerCase()}.${extension}`;

export const getFolderName = (fieldName) => ASSET_FOLDERS[fieldName];
