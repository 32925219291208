import { ReactComponent as Kilo } from '@/images/kilo.svg';
import { ReactComponent as Mega } from '@/images/mega.svg';
import { ReactComponent as Giga } from '@/images/giga.svg';
import { ReactComponent as Tera } from '@/images/tera.svg';

export default {
  Kilo,
  Mega,
  Giga,
  Tera,
};
