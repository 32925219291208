import React from 'react';
import pp from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import { Portal } from 'react-portal';
import { Transition, animated } from 'react-spring';
import { KEYCODES } from '@/constants/keyCodes';
import { ReactComponent as Left } from '@/images/chevron-left.svg';
import { ReactComponent as Close } from '@/images/delete.svg';
import './styles.scss';

const animationCache = {};

const initialState = {
  stack: [],
  current: 'default',
  currentProps: null,
  animateTo: 'right',
};

const drawerAnimations = {
  fill: {
    from: { transform: 'translate3d(0, 100%, 0)' },
    enter: { transform: 'translate3d(0, 0%, 0)' },
    leave: { transform: 'translate3d(0, 100%, 0)' },
  },
  left: {
    from: { transform: 'translate3d(-100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(-100%, 0, 0)' },
  },
  right: {
    from: { transform: 'translate3d(100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(100%, 0, 0)' },
  },
};

const screenAnimations = {
  left: {
    from: { transform: 'translate3d(-100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(100%, 0, 0)' },
  },
  right: {
    from: { transform: 'translate3d(100%, 0, 0)' },
    enter: { transform: 'translate3d(0%, 0, 0)' },
    leave: { transform: 'translate3d(-100%, 0, 0)' },
  },
};

class Drawer extends React.Component {
  bodyClassName = 'no-scroll';

  constructor(props) {
    super(props);
    this.state = initialState;
    this.screens = React.Children.toArray(props.children).reduce(
      (screens, { props: childProps }) => ({
        ...screens,
        [childProps.name]: {
          header: childProps.header,
          headerRightAction: childProps.headerRightAction,
          content: childProps.children,
          footer: childProps.footer,
        },
      }),
      {}
    );
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props;
    const wasOpen = prevProps.isOpen;

    if (isOpen && !wasOpen) {
      document.body.classList.add(this.bodyClassName);
    } else if (!isOpen && wasOpen) {
      document.body.classList.remove(this.bodyClassName);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);

    if (document.body.classList.contains(this.bodyClassName)) {
      document.body.classList.remove(this.bodyClassName);
    }
  }

  handleScreenChange = (screen, props) => {
    const { current, currentProps } = this.state;

    this.setState((state) => ({
      stack: [...state.stack, { key: current, props: currentProps }],
      current: screen,
      currentProps: props,
      animateTo: 'right',
    }));

    animationCache[screen] = props;
  };

  handleBackClick = () => {
    if (!this.state.stack.length) {
      this.handleClose();
    } else {
      this.setState((state) => {
        const stack = Array.from(state.stack);
        const prev = stack.pop();
        return {
          stack,
          current: prev.key,
          currentProps: prev.props,
          animateTo: 'left',
        };
      });
    }

    this.props.onBack();
  };

  handleClose = () => {
    this.props.close();
    this.props.onClose();
  };

  handleAfterClose = (isClosed) => {
    if (isClosed) {
      this.setState(initialState);
    }
  };

  handleKeydown = (e) => {
    if (e.keyCode === KEYCODES.escape && this.props.isOpen) {
      this.handleClose();
    }
  };

  renderHeader = (Header, headerRightAction) => {
    const { current } = this.state;
    const { open, close, isOpen, alwaysDisplayBack } = this.props;
    return (
      <header className="Drawer__header">
        {(alwaysDisplayBack || current !== 'default') && (
          <button
            type="button"
            onClick={this.handleBackClick}
            className="Drawer__header-back"
          >
            <Left />
          </button>
        )}

        {Header && (
          <Header
            className="Drawer__title"
            open={open}
            close={close}
            isOpen={isOpen}
            {...animationCache[current]}
          />
        )}

        {headerRightAction ? (
          headerRightAction({
            getButtonProps: ({ className, ...buttonProps }) => ({
              className: cx('Drawer__header-close', className),
              onClick: this.handleClose,
              ...buttonProps,
            }),
            goToScreen: this.handleScreenChange,
          })
        ) : (
          <button
            type="button"
            onClick={this.handleClose}
            className="Drawer__header-close"
          >
            <Close />
          </button>
        )}
      </header>
    );
  };

  renderFooter = (Footer) => {
    const { current } = this.state;
    const { open, close, isOpen } = this.props;
    if (!Footer) return null;
    return (
      <footer className="Drawer__footer">
        <Footer
          open={open}
          close={close}
          isOpen={isOpen}
          {...animationCache[current]}
        />
      </footer>
    );
  };

  renderDrawer = () => {
    const { current, animateTo } = this.state;
    const { isOpen, open, close, className, placement, width } = this.props;

    const header = _.get(
      this.screens,
      `${current}.header`,
      this.screens.default.header
    );

    const headerRightAction = _.get(
      this.screens,
      `${current}.headerRightAction`
    );

    const footer = _.get(
      this.screens,
      `${current}.footer`,
      this.screens.default.footer
    );

    return (
      <Transition
        native
        items={isOpen}
        {...drawerAnimations[placement]}
        onDestroyed={this.handleAfterClose}
      >
        {(show) => (styles) =>
          show && (
            <Portal>
              <div
                className={cx(
                  'Drawer',
                  `Drawer--${placement}`,
                  {
                    'Drawer--has-footer': !!footer,
                  },
                  className
                )}
              >
                <div
                  className="Drawer__overlay"
                  onClick={close}
                  role="button"
                  tabIndex={-1}
                />
                <animated.div
                  style={{ ...styles, ...(placement !== 'fill' && { width }) }}
                  className="Drawer__content-wrapper"
                >
                  {this.renderHeader(header, headerRightAction)}

                  <Transition
                    native
                    items={current}
                    initial={null}
                    {...screenAnimations[animateTo]}
                  >
                    {(key) => (screenStyles) => (
                      <animated.main
                        style={screenStyles}
                        className="Drawer__content"
                      >
                        {React.Children.map(
                          this.props.children,
                          (child) =>
                            child.props.name === key
                              ? React.cloneElement(child, {
                                  open,
                                  close,
                                  isOpen,
                                  goToScreen: this.handleScreenChange,
                                  ...animationCache[key],
                                })
                              : null
                        )}
                      </animated.main>
                    )}
                  </Transition>

                  {this.renderFooter(footer)}
                </animated.div>
              </div>
            </Portal>
          )}
      </Transition>
    );
  };

  render() {
    const { open, renderReference } = this.props;
    return (
      <>
        {renderReference({
          open: (e) => {
            if (e) {
              e.preventDefault();
              e.stopPropagation();
            }
            open();
          },
          goToScreen: this.handleScreenChange,
        })}
        {this.renderDrawer()}
      </>
    );
  }
}

Drawer.propTypes = {
  isOpen: pp.bool.isRequired,
  open: pp.func.isRequired,
  close: pp.func.isRequired,
  className: pp.string,
  renderHeader: pp.func,
  renderFooter: pp.func,
  onClose: pp.func,
  onBack: pp.func,
  width: pp.number,
  placement: pp.oneOf(['fill', 'left', 'right']),
};

Drawer.defaultProps = {
  className: null,
  renderHeader: null,
  renderFooter: null,
  onClose: _.noop,
  onBack: _.noop,
  width: null,
  placement: 'fill',
};

export default Drawer;
