/* eslint-disable react/button-has-type */

import React from 'react';
import {
  func,
  node,
  string,
  oneOfType,
  oneOf,
  arrayOf,
  bool,
} from 'prop-types';
import cx from 'classnames';
import LoadingCircle from '@/components/LoadingCircle';
import './styles.scss';

function Button({
  innerRef,
  children,
  type,
  icon: Icon,
  iconPosition,
  variants,
  className,
  disabled,
  loading,
  childrenClassName,
  ...buttonProps
}) {
  function renderIcon() {
    if (!Icon) return null;
    const iconClassName = cx('Button__icon', `Button__icon--${iconPosition}`);
    return Icon instanceof Function ? (
      <Icon className={iconClassName} />
    ) : (
      <img src={Icon} alt="" className={iconClassName} />
    );
  }

  return (
    <button
      ref={innerRef}
      type={type}
      className={cx(
        'Button',
        { 'Button--disabled': disabled || loading },
        { 'Button--loading': loading },
        variants.map((v) => `Button--${v}`),
        className
      )}
      disabled={disabled || loading}
      {...buttonProps}
    >
      {loading ? (
        <LoadingCircle className="Button__loader" />
      ) : (
        <>
          {iconPosition === 'left' && renderIcon()}
          {children && <span className={childrenClassName}>{children}</span>}
          {iconPosition === 'right' && renderIcon()}
        </>
      )}
    </button>
  );
}

Button.propTypes = {
  innerRef: func,
  children: node,
  icon: oneOfType([string, func]),
  iconPosition: oneOf(['left', 'right']),
  type: string,
  variants: arrayOf(string),
  className: string,
  disabled: bool,
  loading: bool,
  childrenClassName: string,
};

Button.defaultProps = {
  innerRef: null,
  children: '',
  icon: null,
  iconPosition: 'left',
  type: 'button',
  variants: [],
  className: null,
  disabled: false,
  loading: false,
  childrenClassName: '',
};

export default Button;
