import React from 'react';
import pp from 'prop-types';
import { RequiredField, RfSlider } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';

function WorkRateSection({ t }) {
  return (
    <FormSection title={t('company.modal.modalities.workRate.title')}>
      <RequiredField
        component={RfSlider}
        name="work_days"
        domain={[1, 5]}
        step={1}
        label={t('company.modal.modalities.workRate.label')}
        valueKey={([value]) =>
          value === 5
            ? 'company.modal.modalities.workRate.fullTime.value'
            : 'company.modal.modalities.workRate.partTime.value'
        }
      />
    </FormSection>
  );
}

WorkRateSection.propTypes = {
  t: pp.func.isRequired,
};

export default WorkRateSection;
