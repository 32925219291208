import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { first } from 'lodash';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { withClientState } from 'apollo-link-state';
import config from '@/_config';
import { getToken } from '@/helpers/auth';
import history from '@/helpers/history';
import Sentry from '@/helpers/sentry';
import { getHomeRoute, getLogoutRoute } from '@/helpers/router';
import resolvers from '@/api';
import store from '@/redux/store';
import { showFlash } from '../redux/modules/flash';
import i18n from '@/i18n';
import { isStaging } from '@/constants/environment';
import { setToken } from './auth';
import { UNAUTHORIZED } from '@/constants/errors';

// Default client
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: 'UNION',
          name: 'ChannelUnionType',
          possibleTypes: [
            {
              name: 'Company',
            },
            {
              name: 'Candidacy',
            },
          ],
        },
      ],
    },
  },
});

const apolloCache = new InMemoryCache({ fragmentMatcher });

const httpLink = createHttpLink({
  uri: `//${config.api}/graphql`,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  });

  return forward(operation).map((response) => {
    const {
      response: { headers },
    } = operation.getContext();
    if (headers) {
      setToken(getToken());
    }

    return response;
  });
});

const getErrorMessage = ({ message, extensions = null }) => {
  if (message === 'invalid_record' && extensions) {
    const [field, types] = first(Object.entries(extensions));
    return i18n.t(`flash.error.${first(types).error}`, { field });
  }
  const message_key = `flash.error.${message}`;
  const translated_message = i18n.t(message_key);

  if (translated_message !== message_key) {
    return translated_message;
  }

  return isStaging ? message : 'flash.generic_error';
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map((error) => {
      const { message, locations, path } = error;
      if (message === UNAUTHORIZED) {
        history.push(getHomeRoute());
        store.dispatch(
          showFlash({
            kind: 'error',
            text: getErrorMessage(error),
          })
        );
      }
      store.dispatch(
        showFlash({
          kind: 'error',
          text: getErrorMessage(error),
        })
      );

      const errorMsg = `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
        locations
      )}, Path: ${path}`;

      Sentry.captureException(errorMsg);

      // eslint-disable-next-line
      console.log(errorMsg);

      return null;
    });
  }
  if (networkError) {
    if (networkError.statusCode === 401) {
      history.push(getLogoutRoute());
    } else {
      const error = `[Network error]: ${networkError}`;

      Sentry.captureMessage(error);

      // eslint-disable-next-line
      console.log(error);
    }
  }
});

const stateLink = withClientState({
  cache: apolloCache,
  resolvers,
  defaults: {
    currentUser: null,
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'none',
  },
};

const client = new ApolloClient({
  // Warning: order matters !
  link: ApolloLink.from([stateLink, errorLink, authLink, httpLink]),
  cache: apolloCache,
  defaultOptions,
});

client.onResetStore(stateLink.writeDefaults);

// Specific client (not authenticated) for auth methods (forgot/reset password)
export const authClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    createHttpLink({
      uri: `//${config.api}/offline_graphql`,
    }),
  ]),
  cache: apolloCache,
  defaultOptions,
});

export default client;
