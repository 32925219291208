import React from 'react';
import { node, string, oneOf } from 'prop-types';
import cx from 'classnames';
import MediaQuery from 'react-responsive';
import { BREAKPOINTS } from '@/constants/screen';
import useTranslation from '@/hooks/useTranslation';
import Popover from '@/components/Popover';
import './styles.scss';

function Tooltip({
  children,
  className,
  title,
  titleArg,
  text,
  details,
  behaviour,
  titleComponent,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <MediaQuery maxWidth={BREAKPOINTS.laptop}>
      {(mobile) => {
        if (mobile && behaviour === 'hoverOnly') {
          return React.cloneElement(children, {
            className: cx(children.props.className, className),
          });
        }
        return (
          <Popover
            {...props}
            mode={mobile ? 'click' : 'hover'}
            className={cx('Tooltip', className)}
            innerSpacing={12}
            renderReference={({ ref, getButtonProps }) => (
              <div {...getButtonProps({ className: 'Tooltip__inner', ref })}>
                {children}
              </div>
            )}
            renderPopper={() => (
              <>
                {titleComponent && <>{titleComponent}</>}
                {title && <h2 className="Tooltip__title">{t(title)}</h2>}
                {text && <p className="Tooltip__content">{t(text)}</p>}
                {details && <p className="Tooltip__content">{details}</p>}
              </>
            )}
          />
        );
      }}
    </MediaQuery>
  );
}

Tooltip.propTypes = {
  children: node,
  className: node,
  behaviour: oneOf(['hoverOnly', 'mobileClick']),
  title: string,
  text: string,
  details: string,
  titleComponent: node,
};

Tooltip.defaultProps = {
  children: null,
  className: null,
  behaviour: 'hoverOnly',
  title: null,
  text: null,
  details: null,
  titleComponent: null,
};

export default Tooltip;
