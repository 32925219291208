import React from 'react';
import pp from 'prop-types';
import _ from 'lodash';
import languageLevels from '@/constants/fields/languageLevels';
import useLanguages from '@/hooks/useLanguages';
import { RequiredField, RfSelect } from '@/containers/ReduxForm';
import FormSection from '@/components/FormSection';
import ProfileModal from '../../ProfileModal';

function LanguageModal({ t, excludedLanguages, ...props }) {
  const languages = useLanguages();
  return (
    <ProfileModal
      title={t('company.modal.language.title')}
      form="company_language"
      {...props}
    >
      <FormSection>
        <RequiredField
          component={RfSelect}
          name="alpha2"
          label={t('company.modal.language.name.label')}
          placeholder={t('company.modal.language.name.placeholder')}
          options={languages.filter(
            (l) => !_.find(excludedLanguages, { alpha2: l.value })
          )}
          className="m-b-m"
        />
        <RequiredField
          component={RfSelect}
          name="level"
          label={t('company.modal.language.level.label')}
          placeholder={t('company.modal.language.level.placeholder')}
          options={languageLevels.map((level) => ({
            ...level,
            label: t(level.label),
          }))}
        />
      </FormSection>
    </ProfileModal>
  );
}

LanguageModal.propTypes = {
  t: pp.func.isRequired,
  close: pp.func.isRequired,
  excludedLanguages: pp.arrayOf(pp.objectOf(pp.any)),
};

LanguageModal.defaultProps = {
  excludedLanguages: [],
};

export default LanguageModal;
