import { isNil } from 'lodash';
import Intl from 'intl';
import i18n from '@/i18n';

const priceFormat = (value, opts = {}) =>
  isNil(value)
    ? null
    : new Intl.NumberFormat(i18n.language, {
        minimumFractionDigits: opts.minDigits,
        maximumFractionDigits: opts.maxDigits,
      }).format(value);

export default priceFormat;
